import { makeQuery } from 'shared/utilities';
import { ISurveyModel } from "shared/models";

type RequestType = {
    surveyQuestionId?: number;
    surveyId?: number;
};

type ResponseType = {
    item: ISurveyModel
};

export const surveysCancelQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/surveys/cancel', { json: payload });
}
