import { action, runInAction } from 'mobx';

import { ObjectType, QueryType } from 'shared/types';
import { StoreWithFetchItems } from 'shared/mixins/store';

type OptionsType = {
    prefetchCallback?: (params: ObjectType) => Promise<void>;
};

export default <S extends StoreWithFetchItems<any>, T extends QueryType>(
    query: T,
    Store: S,
    Model: any,
    options: OptionsType = {}
) => {
    return action(async (params: ObjectType) => {
        Store.isLoading = true;
        Store.isSuccess = true;
        Store.errorCode = 0;
        Store.errorMessage = '';
        Store.pages = 0;
        Store.total = 0;

        if (options.prefetchCallback) {
            await options.prefetchCallback(params);
        }

        const { isSuccess, data, errorCode, errorMessage } = await query(params);
        runInAction(() => {
            if (isSuccess && data) {
                const items = data.items.map((item: any) => new Model(item));
                Store.items = Store.isInfiniteLoading ? [...Store.items, ...items] : items;
                Store.pages = data.pages;
                Store.total = data.total;
            } else {
                Store.isSuccess = false;
                Store.errorCode = errorCode || 400;
                Store.errorMessage = errorMessage || 'Ошибка';
            }
            Store.isInfiniteLoading = false;
            Store.isLoading = false;
        });
    }) as any as T;
};
