import React, { FormEventHandler, useEffect } from 'react';
import { observer } from 'mobx-react';
import { OnChangeHandlerType } from 'shared/types';

import { useStore } from "shared/hooks";

import { UiButton } from "../UiButton";

import './styles.scss';

type PropsType = {
    name?: string,
    value?: string,
    placeholder?: string,
    style?: React.CSSProperties,
    onChange?: OnChangeHandlerType<string>
}

export const UiSearchInput = observer((
    {
        onChange, name = 'ui-search-input', value, style, placeholder = 'Найдите объявление'
    }: PropsType
) => {
    const store = useStore(() => ({
        value: ''
    }));
    useEffect(() => {
        store.set('value', value || '');
    }, [value, store])

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        onChange && onChange({
            name,
            value: store.value
        });
    }

    return (
        <form onSubmit={handleSubmit} className='ui-search-input' style={style}>
            <input
                type="text"
                className="ui-search-input__control"
                value={store.value || ''}
                placeholder=" "
                onChange={(e) => {
                    store.set("value", e.target.value || '');
                }}
            />
            <div className="ui-search-input__placeholder">
                {placeholder}
            </div>
            <div className="ui-search-input__button">
                <UiButton label='Найти' isSubmit/>
            </div>
        </form>
    )
});
