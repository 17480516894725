import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { Helpers } from 'shared/utilities';

import { Model } from './Model';

export interface IDocumentModel {
    id?: number;
    documentCategoryId?: number;
    sort?: number;
    fileSize?: number;
    name?: string;
    fileUrl?: string;
    updatedAt?: string;
}

const colors = {
    default: '#717171',
    csv: '#007c00',
    doc: '#0167b3',
    docx: '#0167b3',
    jpeg: '#f5ca2c',
    jpg: '#f5ca2c',
    pdf: '#cb4124',
    ppt: '#E2574C',
    pptx: '#E2574C',
    xls: '#1d6e42',
    xlsx: '#1d6e42',
};

export class DocumentModel extends Model implements IDocumentModel {
    id = 0;
    sort = 0;
    documentCategoryId = 0;
    name = '';
    fileUrl = '';
    fileSize = 0;
    updatedAt = '';

    constructor(payload: IDocumentModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            fileUrl: observable,
            fileSize: observable,
            documentCategoryId: observable,
            updatedAt: observable,
            updatedAtMoment: computed,
            update: action,
            fileExtention: computed,
        });

        this.update(payload);
    }

    get fileExtention() {
        if (!this.fileUrl) {
            return 'default';
        }
        return this.fileUrl.split('.').reverse()[0].toLowerCase();
    }

    get fileColors() {
        const extension = this.fileExtention as keyof typeof colors;
        const color = colors[extension] ? colors[extension] : colors.default;
        return {
            primary: color,
            secondary: Helpers.mutateHexColor(color, 20),
        };
    }

    get updatedAtMoment() {
        if (this.updatedAt) {
            return moment(this.updatedAt);
        }
        return moment();
    }
}
