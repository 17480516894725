import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';

import { COLORS, ICONS } from 'shared/constants';
import { OnChangeHandlerType, NullableType } from 'shared/types';
import { favoritesToggleQuery } from 'shared/queries';

import { UiIcon } from '../UiIcon';
import { UiButton } from '../UiButton';

import './styles.scss';

interface Props {
    color: string;
    value: number;
    entityType: 'News' | 'CafeMeal' | 'Vacancy';
    entityId: number;
    onChange?: OnChangeHandlerType<number>;
    buttonProps: NullableType<{ isSmall?: boolean; isTranslucent?: boolean; isOutline?: boolean }>;
}

class UiFavorite extends React.Component<Props> {
    inProgress = false;

    static defaultProps: Props = {
        color: COLORS.GRAY_4,
        value: 0,
        entityType: 'News',
        entityId: 0,
        buttonProps: null,
    };

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            inProgress: observable,
            setInProgress: action,
        });
    }

    handleToggle = async (e: SyntheticEvent) => {
        const { onChange, entityId, entityType } = this.props;

        e.preventDefault();
        e.stopPropagation();

        if (this.inProgress) {
            return;
        }

        this.setInProgress();
        const { isSuccess, data } = await favoritesToggleQuery({ entityId, entityType });
        if (isSuccess && data) {
            onChange && onChange({
                name: 'favorited',
                value: data.favorited,
            });
        }
        this.setInProgress(false);
    };

    setInProgress = (inProgress = true) => {
        this.inProgress = inProgress;
    };

    render() {
        const { color, value, buttonProps } = this.props;

        if (buttonProps) {
            return (
                <UiButton
                    {...buttonProps}
                    className='ui-favorite--button'
                    onClick={this.handleToggle}
                    label={
                        <UiIcon
                            size={16}
                            color={value ? COLORS.BROWN_1 : color}
                            icon={value ? ICONS.STAR : ICONS.STAR_OUTLINE}
                        />
                    }
                />
            );
        }
        return (
            <div className='ui-favorite' onClick={this.handleToggle}>
                <UiIcon
                    size={20}
                    color={value ? COLORS.BROWN_1 : color}
                    icon={value ? ICONS.STAR : ICONS.STAR_OUTLINE}
                />
            </div>
        );
    }
}

export default observer(UiFavorite);
