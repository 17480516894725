import { action, makeObservable, observable } from 'mobx';

import { ModelCast } from "shared/casts";

import { Model } from './Model';
import { IUserModel, UserModel } from "./UserModel";

export interface IChatRoomUserModel {
    id?: number;
    isActive?: number;
    receiveNotifications?: number;
    isFixed?: number;
    isBlocked?: number;
    userId?: number;
    user: IUserModel;
}

export class ChatRoomUserModel extends Model implements IChatRoomUserModel {
    casts = {
        user: new ModelCast(UserModel)
    }
    id = 0;
    isActive = 1;
    receiveNotifications = 1;
    isFixed = 0;
    isBlocked = 0;
    userId = 0;
    user = new UserModel();

    constructor(payload: IChatRoomUserModel) {
        super();

        makeObservable(this, {
            id: observable,
            isActive: observable,
            receiveNotifications: observable,
            isFixed: observable,
            isBlocked: observable,
            userId: observable,
            user: observable,
            update: action,
        });

        this.update(payload);
    }
}
