import React from 'react';
import { observer } from 'mobx-react';

import { OfficeCorporateTransportModel } from 'shared/models';
import { useStore } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    corporateTransport: OfficeCorporateTransportModel;
}

export const OfficeItemCorporateTransport = observer(({ corporateTransport }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false
    }))

    return (
        <div className='office-item-corporate-transport'>
            <div className="office-item-corporate-transport__header" onClick={() => store.toggle('isOpened')}>
                <div className="office-item-corporate-transport__chevron">
                    <UiIcon icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN} size={14}/>
                </div>
                {corporateTransport.color && (
                    <div className="office-item-corporate-transport__circle">
                        <UiIcon icon={ICONS.CIRCLE} color={corporateTransport.color} size={10}/>
                    </div>
                )}
                <div className="office-item-corporate-transport__name">
                    {corporateTransport.name}
                </div>
            </div>
            {store.isOpened && (
                <>
                    {corporateTransport.time && (
                        <div className="office-item-corporate-transport-field">
                            <div className="office-item-corporate-transport-field__icon">
                                <UiIcon icon={ICONS.CLOCK} color={COLORS.BLACK} size={16}/>
                            </div>
                            <div className="office-item-corporate-transport-field__value">
                                {corporateTransport.time}
                            </div>
                        </div>
                    )}
                    {corporateTransport.description && (
                        <div className="office-item-corporate-transport-field">
                            <div className="office-item-corporate-transport-field__icon">
                                <UiIcon icon={ICONS.TARGET} color={COLORS.BLACK} size={16}/>
                            </div>
                            <div className="office-item-corporate-transport-field__value">
                                {corporateTransport.description}
                            </div>
                        </div>
                    )}
                    {corporateTransport.stations && (
                        <div className="office-item-corporate-transport-stations">
                            <div className="office-item-corporate-transport-stations__label">
                                Остановки по требованию:
                            </div>
                            {corporateTransport.stationsData.map(item => (
                                <div className="office-item-corporate-transport-stations__item">{item}</div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    )
});
