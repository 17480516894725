import { Area } from "react-easy-crop/types";

const createImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = src;
    });
}

const getBase64 = async (src: string, crop: Area) => {
    const image = await createImage(src);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx || !image) {
        return null;
    }

    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0)

    const data = ctx.getImageData(crop.x, crop.y, crop.width, crop.height);
    canvas.width = crop.width;
    canvas.height = crop.height;
    ctx.putImageData(data, 0, 0)

    return canvas.toDataURL("image/jpeg")
}

function toFile(base64: string) {
    let byteString;
    if (base64.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(base64.split(',')[1]);
    else
        byteString = unescape(base64.split(',')[1]);

    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    let file = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        file[i] = byteString.charCodeAt(i);
    }
    return new Blob([file], { type: mimeString });
}

export const getCroppedFile = async (src: string, area: Area) => {
    const base64 = await getBase64(src, area);

    if (!base64) {
        return null;
    }

    return toFile(base64);
}
