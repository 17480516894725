import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";
import { useMatch } from "react-router-dom";

import { UiIcon } from 'shared/uikit';
import { ICONS, ROUTES } from 'shared/constants';
import { useBroadcast, useNavigate, useStore } from "shared/hooks";
import { ChatMessageCreatedBroadcast } from "shared/broadcasts";
import { ApplicationModule } from "shared/modules";

import './styles.scss';

type HotNotificationType = {
    id: number | string,
    title: string,
    createdAt: string,
    action?: () => void,
    subtitle?: string,
    description?: string
}

export const LayoutHotNotifications = observer(() => {
    const navigate = useNavigate();
    const chatRouteMatch = useMatch(ROUTES.CHAT())
    const store = useStore(() => ({
        items: [] as HotNotificationType[]
    }))

    const handleDelete = (id: string | number) => (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation()
        store.set("items", store.items.filter(item => item.id !== id));
    }

    useBroadcast(new ChatMessageCreatedBroadcast(({ item }) => {
        if(+(item.userId || 0) === +ApplicationModule.user.id){
            return;
        }
        if (!!chatRouteMatch?.params?.id) {
            if (+chatRouteMatch.params.id === +item.chatRoomId) {
                return;
            }
        }

        const hotNotification = {
            id: `chat-message.${item.id}`,
            title: 'Новое сообщение',
            createdAt: moment().format('HH:mm'),
            action: () => {
                navigate(ROUTES.CHAT(item.chatRoomId));
            },
            subtitle: item.user?.name,
            description: item.content
        }
        store.set("items", [...store.items, hotNotification]);
        setTimeout(() => {
            store.set("items", store.items.filter(({id}) => hotNotification.id !== id));
        }, 7000);
    }));

    const handleAction = (item: HotNotificationType) => () => {
        item.action && item.action();
        store.set("items", store.items.filter(({id}) => item.id !== id));
    }

    return (
        <div className='layout-hot-notifications'>
            {store.items.map(item => (
                <div key={item.id} className="layout-hot-notification" onClick={handleAction(item)}>
                    <div className="layout-hot-notification__close" onClick={handleDelete(item.id)}>
                        <UiIcon icon={ICONS.CLOSE} size={20}/>
                    </div>
                    <div className="layout-hot-notification__date">{item.createdAt}</div>
                    <div className="layout-hot-notification__title">{item.title}</div>
                    {item.subtitle && (
                        <div className="layout-hot-notification__subtitle">{item.subtitle}</div>
                    )}
                    {item.description && (
                        <div className="layout-hot-notification__description">{item.description}</div>
                    )}
                </div>
            ))}
        </div>
    );
})
