import React from "react";
import { observer } from "mobx-react";

import { UiIcon } from "shared/uikit";
import { IconType } from "shared/types";
import { COLORS } from "shared/constants";

import './styles.scss';

interface Props {
    icon?: IconType,
    text: string
}

export const UiSectionTitle = observer(({text, icon}: Props) => {
    return (
        <div className="ui-section-title">
            <UiIcon icon={icon} size={30} color={COLORS.BROWN_1}/>
            <span>{text}</span>
        </div>
    )
});
