import { makeQuery } from 'shared/utilities';
import { ICompanyLeaderGroupModel } from "shared/models";

type ResponseType = {
    items: ICompanyLeaderGroupModel[]
};

export const companyLeaderGroupsQuery = async function () {
    return await makeQuery<ResponseType>('/company-leader-groups');
}
