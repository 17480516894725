import { makeQuery } from 'shared/utilities';

type RequestType = {
    items: {
        id: number,
        sort: number,
        columnId: number,
        statusId: string
    }[]
}

type ResponseType = {};

export const tasksUpsertQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks/upsert', {
        prefix: 'task-tracker',
        json: request
    });
}
