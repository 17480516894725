import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useMedia, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { DocumentCategoryModel, DocumentModel } from "shared/models";
import { documentCategoriesQuery, documentsQuery } from "shared/queries";
import { UiDataBoundary, UiDocument, UiGrid, UiViewType } from "shared/uikit";
import { Storage } from "shared/utilities";
import { DocumentCategoryTypeEnum } from "shared/enums";
import { ApplicationModule } from "shared/modules";

import { DocumentCategoryItem } from "./components/DocumentCategoryItem";

import './styles.scss';
import { ROUTES } from "shared/constants";

export const DocumentCategoriesPage = observer(() => {
    const urlParams = useUrlParams({
        typeId: DocumentCategoryTypeEnum.Request
    });

    const title = useMemo(() => {
        if (urlParams.typeId === DocumentCategoryTypeEnum.RequestForm) {
            return 'Документы: бланки заявлений';
        }

        if (urlParams.typeId === DocumentCategoryTypeEnum.Regulation) {
            return 'Документы: регламенты и инструкции';
        }

        return 'Документы';
    }, [urlParams.typeId])

    useEffect(() => {
        ApplicationModule.setTitle(title);
    }, [title])

    const store = useStore(() => ({
        viewType: 'card',
        isLoading: false,
        isSuccess: false,
        items: [] as DocumentCategoryModel[],
        recentDocuments: [] as DocumentModel[],
    }));

    const fetchRecentDocuments = useCallback(async () => {
        const recent = await Storage.get<number[]>('documents.recent', []);
        if (recent.length === 0) {
            return;
        }
        const { isSuccess, data } = await documentsQuery({
            perPage: 20,
            id: recent
        });
        if (isSuccess && data) {
            store.set("recentDocuments", data.items.map(item => new DocumentModel(item)));
        }
    }, [store]);

    const fetchItems = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await documentCategoriesQuery(urlParams);
        if (isSuccess && data) {
            store.set("items", data.items.map(item => new DocumentCategoryModel(item)));
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store, urlParams]);

    const { value: columns } = useMedia({
        is320: 2,
        is768: 3,
        is1024: 3,
        is1280: 4,
        is1440: 5
    });

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    useEffect(() => {
        fetchRecentDocuments();
    }, [fetchRecentDocuments]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={title} backTo={ROUTES.DOCUMENTS()}>
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid
                        style={{maxWidth: 1152}}
                        columns={store.viewType === 'card' ? columns : 1}
                        gap={[32, 0]}
                    >
                        {store.items.map(item => (
                            <DocumentCategoryItem
                                key={item.id}
                                item={item}
                                type={store.viewType}
                            />
                        ))}
                    </UiGrid>
                </UiDataBoundary>
                {store.recentDocuments.length > 0 && (
                    <>
                        <h3 className='typography mt-16'>Недавно просмотренные</h3>
                        {store.recentDocuments.map(item => (
                            <UiDocument
                                key={item.id}
                                item={item}
                                viewType='list'
                            />
                        ))}
                    </>
                )}
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
})
