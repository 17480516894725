import React from 'react';
import { observer } from 'mobx-react';

import { ChatRoomModel } from "shared/models";
import { UiAvatar, UiNavLink, UiUserStatus } from "shared/uikit";
import { ChatRoomTypeEnum } from "shared/enums";

import './styles.scss';
import { ROUTES } from "shared/constants";

type PropsType = {
    chatRoom: ChatRoomModel | null | undefined
}

export const ChatInfo = observer(({ chatRoom }: PropsType) => {
    if (!chatRoom) {
        return null;
    }

    return (
        <div className="chat-info">
            <div className="chat-info__avatar">
                <UiAvatar
                    size={35}
                    label={chatRoom.previewName}
                    image={chatRoom.previewImage}
                />
            </div>
            <div className="chat-info__inner">
                {chatRoom.type.is(ChatRoomTypeEnum.Direct) && (
                    <UiUserStatus className="chat-info__status" value={chatRoom.user}/>
                )}
                {chatRoom.type.is(ChatRoomTypeEnum.Group) && (
                    <div className="chat-info__status">{chatRoom.usersCounterText}</div>
                )}
                <UiNavLink to={ROUTES.USER(chatRoom.user?.slug)} className="chat-info__name">{chatRoom.previewName}</UiNavLink>
            </div>
        </div>
    );
});
