import { makeQuery } from 'shared/utilities';
import { IReservationPlaceModel } from 'shared/models';

type ResponseType = {
    items: IReservationPlaceModel[];
};

export const reservationsDeleteQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/reservations/delete', { json: { id } });
}
