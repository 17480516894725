import React from 'react';
import { observer } from 'mobx-react';

import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';

type PropsType = {
    name: string,
    url: string,
    onDelete?: () => void
}

export const CTaskFile = observer(({ name, url, onDelete }: PropsType) => {
    const extension = name.split('.').slice(-1)[0].toLocaleLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'webp', 'svg'].indexOf(extension) > 0;
    const pureName = name.split('/').slice(-1)[0];

    const style = isImage ? { backgroundImage: `url("${url}")` } : {}
    return (
        <a target='_blank' href={url} className={'c-tt-task-file'} style={style} rel={'noreferrer'}>
            {!isImage && (
                <>
                    <div className="c-tt-task-file__icon">
                        <Icon extension={extension}/>
                    </div>
                    <div className="c-tt-task-file__name">
                        {pureName}
                    </div>
                </>
            )}
            {onDelete && (
                <div className="c-tt-task-file-delete">
                    <div className="c-tt-task-file-delete__close" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete();
                    }}>
                        <UiIcon color={'#fff'} icon={ICONS.CLOSE} size={14}/>
                    </div>
                    <div className="c-tt-task-file-delete__name">{pureName}</div>
                </div>
            )}
        </a>
    )
});

type IconPropsType = {
    extension: string
}

const Icon = observer(({ extension }: IconPropsType) => {
    const colors: Record<string, string> = {
        doc: '#0072FF',
        docx: '#0072FF',
        xls: '#00C650',
        xlsx: '#00C650',
        pdf: '#EB3B5A',
        zip: '#F7B731',
        rar: '#F7B731',
        txt: '#A5B1C2',
        '7z': '#F7B731',
    }
    const color = colors[extension] || '#A5B1C2';
    return (
        <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.3"
                d="M20.3861 7.29494C19.5881 7.29416 18.823 6.97211 18.2588 6.39949C17.6945 5.82688 17.3772 5.05047 17.3764 4.24068V2.75288e-07H3.92922C3.4133 -0.000194286 2.90238 0.102743 2.42567 0.302968C1.94897 0.503192 1.5158 0.796774 1.15092 1.16692C0.786042 1.53707 0.496595 1.97654 0.299116 2.46023C0.101637 2.94392 -3.56227e-08 3.46235 0 3.98591V28.014C-3.56227e-08 28.5376 0.101637 29.0561 0.299116 29.5398C0.496595 30.0235 0.786042 30.4629 1.15092 30.8331C1.5158 31.2032 1.94897 31.4968 2.42567 31.697C2.90238 31.8972 3.4133 32.0002 3.92922 32H20.0723C21.114 32 22.113 31.58 22.8496 30.8325C23.5862 30.085 24 29.0712 24 28.014V7.29494H20.3861Z"
                fill={color}
                fillOpacity="0.3"
            />
            <path
                d="M24.0004 7.2963H20.377C19.5769 7.29551 18.8098 6.9734 18.2441 6.40068C17.6783 5.82796 17.3601 5.05141 17.3594 4.24146V0L24.0004 7.2963Z"
                fill={color}/>
            <path
                d="M26.6588 13.1855H8.1532C6.84051 13.1855 5.77637 14.2508 5.77637 15.5648V21.0878C5.77637 22.4018 6.84051 23.467 8.1532 23.467H26.6588C27.9715 23.467 29.0356 22.4018 29.0356 21.0878V15.5648C29.0356 14.2508 27.9715 13.1855 26.6588 13.1855Z"
                fill={color}
            />
            <text
                x="17" y="19"
                dominantBaseline="middle"
                textAnchor="middle"
                fill='#fff'
                fontSize={8}
                fontWeight={900}
            >
                {extension.toLocaleUpperCase()}
            </text>
        </svg>
    )
})


