import classnames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { OnChangeHandlerType } from 'shared/types';

import './styles.scss';

type PropsType = {
    items?: {
        id: string | number | null;
        name: number | string;
    }[];
    value?: string | number | null;
    name?: string;
    onChange?: OnChangeHandlerType<any>;
    style?: React.CSSProperties,
    className?: string
}

export const UiTabs = observer((
    {
        items = [],
        value = null,
        name = 'tab',
        style,
        className,
        onChange,
    }: PropsType
) => {

    const handleClick = (value: any) => () => {
        onChange && onChange({ value, name });
    };

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <div className={classnames('ui-tabs', className)} style={style}>
            <div className='ui-tabs__outer'>
                <div className='ui-tabs__inner'>
                    {items.map(item => (
                        <div
                            key={item.id}
                            className={classnames('ui-tabs__item', {
                                'ui-tabs__item--active': item.id === value,
                            })}
                            onClick={handleClick(item.id)}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});
