import { makeQuery } from 'shared/utilities';
import { IVacancyModel } from 'shared/models';
import { ObjectType } from 'shared/types';

type ResponseType = {
    pages: number;
    items: IVacancyModel[];
};

export const vacanciesQuery = async function (params: ObjectType = {}) {
    return await makeQuery<ResponseType>('/vacancies', { params });
}
