import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiDataBoundary, UiNavLink, UiSlider } from "shared/uikit";
import { useMedia, useStore } from "shared/hooks";
import { EducationLinkModel } from "shared/models";
import { educationLinksQuery } from "shared/queries";

import './styles.scss';

export const PEducationLinks = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        educationLinks: [] as EducationLinkModel[]
    }));

    useEffect(() => {
        (async () => {
            const { data, isSuccess } = await educationLinksQuery();
            if (data && isSuccess) {
                store.set("educationLinks", data.items.map(item => new EducationLinkModel(item)))
            }
            store.set("isLoading", false);
        })();
    }, [store]);

    const {isMobileOrTablet} = useMedia();

    return (
        <div className="p-education-section">
            <div className="p-education-section__title">Адаптация</div>
            <div className="p-education-section__inner">
                <UiDataBoundary isLoading={store.isLoading}>
                    <UiSlider
                        slideClassName={'p-education-link-slide'}
                        items={store.educationLinks}
                        perPage={isMobileOrTablet ? 'auto' : 5}
                        gap={isMobileOrTablet ? 8 : 24}
                        slide={(educationLink: EducationLinkModel) => (
                            <UiNavLink to={educationLink.url} className="p-education-link">
                                <div className="p-education-link__name">
                                    {educationLink.name}
                                </div>
                                <div
                                    className="p-education-link__icon"
                                    style={{ backgroundImage: `url(${educationLink.icon})` }}
                                />
                            </UiNavLink>
                        )}
                    />
                </UiDataBoundary>
            </div>
        </div>
    );
});

