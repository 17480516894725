import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import './styles.scss';

type PropsType = {
    label: string;
    isInvolved?: boolean,
    isEven?: boolean,
    onClick?: () => void,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void,
}

export const UiReservationPlaceInterval = observer(({ label, isEven, isInvolved, onClick, onMouseEnter, onMouseLeave }: PropsType) => {
    const className = useMemo(() => classnames('ui-reservation-place-interval', {
        'ui-reservation-place-interval--selected': isInvolved,
        'ui-reservation-place-interval--even': isEven,
    }), [isEven, isInvolved]);

    return (
        <div className={className} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <span>{label}</span>
        </div>
    )
});
