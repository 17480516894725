import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { useNavigate, useStore, useUserWidgets } from "shared/hooks";
import { CafeCategoryModel } from "shared/models";
import { cafeMealsQuery } from "shared/queries";
import { UiDataBoundary } from "shared/uikit";
import { PermissionEnum } from "shared/enums";
import { ROUTES } from "shared/constants";

import { CafeCategory } from './components/CafeCategory';
import { Moment } from "moment";

export const CafePage = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: true,
        items: [] as CafeCategoryModel[],
    }));

    const fetchItems = useCallback(async () => {
        store.set('isLoading', true);
        const { isSuccess, data } = await cafeMealsQuery();
        if (isSuccess && data) {
            store.set("items", data.items.map(item => new CafeCategoryModel(item)))
        }
        store.set('isSuccess', isSuccess && data);
        store.set('isLoading', false);
    }, [store]);

    useEffect(() => {
        if (!ApplicationModule.user.can(PermissionEnum.WebCafe)) {
            navigate(ROUTES.HOME());
            return;
        }
        ApplicationModule.setTitle('Столовая');
    }, [navigate]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    const date = useMemo(() => {
        let date: Moment | null = null;
        store.items.forEach(cafeCategory => {
            cafeCategory.cafeMeals.forEach(cafeMeal => {
                if (!date || date.isBefore(cafeMeal.updatedAtMoment)) {
                    date = cafeMeal.updatedAtMoment.clone();
                }
            })
        })
        return date as Moment | null;
    }, [store.items]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Столовая'>
                    {date && (
                        <span>Меню на {date.format('D MMMM YYYY')}</span>
                    )}
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.items.map(category => (
                        <CafeCategory key={category.id} item={category}/>
                    ))}
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside
                widgets={useUserWidgets({
                    exclude: ['WidgetCafePreview']
                })}
            />
        </LayoutBody>

    );
});
