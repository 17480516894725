import { makeQuery } from 'shared/utilities';

type RequestType = {
    entityId: number;
    entityType: string;
}

type ResponseType = {
    liked: number;
    likesCount: number;
};

export const likesToggleQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/likes/toggle', { json: payload });
}
