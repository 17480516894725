import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';
import { IRequestTypeModel, RequestTypeModel } from "shared/models/RequestTypeModel";

export interface IRequestCategoryModel {
    id?: number;
    name?: string;
    colorMarker?: string;
    colorBackground?: string;
    requestTypes?: IRequestTypeModel[]
}

export class RequestCategoryModel extends Model implements IRequestCategoryModel {
    casts = {
        requestTypes: new ModelArrayCast(RequestTypeModel),
    };

    id = 0;
    name = '';
    colorMarker = '';
    colorBackground = '';
    requestTypes: RequestTypeModel[] = [];
    constructor(payload: IRequestCategoryModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            colorMarker: observable,
            colorBackground: observable,
            requestTypes: observable,
            update: action,
        });

        this.update(payload);
    }
}
