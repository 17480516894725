import React  from 'react';
import { observer } from 'mobx-react';

import { useDebounceValue } from "shared/hooks";

import { CalendarYearMonth } from "./components/CalendarYearMonth";

import { Store } from '../../Store';

import './styles.scss';

export const CalendarYear = observer(() => {
    const yearItems = useDebounceValue(Store.yearItems, 250);

    return (
        <div className="calendar-year">
            {yearItems.map(item => <CalendarYearMonth key={item.id} item={item}/>)}
        </div>
    );
});
