import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { ChatMessageArticleModel } from "shared/models";
import { UiIcon, UiImageThumbnail, UiNavLink } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { ThumbnailType } from "shared/types";

import './styles.scss';

type PropsType = {
    chatMessageArticle: ChatMessageArticleModel | null
}

const Article = (
    { href, name, icon, type, date, description, image }: {
        href: string,
        name: string,
        type: string,
        icon?: React.ReactNode,
        date?: string,
        description?: string,
        image?: ThumbnailType | string
    }
) => (
    <UiNavLink to={href} className='chat-message-article'>
        <div className="chat-message-article__name">{name}</div>
        <div className="chat-message-article__header">
            <div className="chat-message-article__type">
                {icon}
                <span>{type}</span>
            </div>
            <div className="chat-message-article__date">от {moment(date).format('DD.MM.YYYY')}</div>
        </div>
        {description && <div className="chat-message-article__description">{description}</div>}
        {image && <UiImageThumbnail className="chat-message-article__image" value={image}/>}
    </UiNavLink>
)

export const ChatMessageArticle = observer(({ chatMessageArticle }: PropsType) => {
    if (!chatMessageArticle) {
        return null;
    }

    const { news, survey, announcement } = chatMessageArticle;

    if (news) {
        return (
            <Article
                href={ROUTES.NEWS(news.id)}
                icon={<UiIcon icon={ICONS.NEWS} size={20} color={COLORS.BROWN_1}/>}
                name={news.name}
                type={'Новость'}
                description={news.previewText}
                image={news.previewImageThumbnail}
                date={news.createdAt}
            />
        );
    }

    if (survey) {
        return (
            <Article
                href={ROUTES.SURVEY(survey.id)}
                icon={<UiIcon icon={ICONS.SURVEY} size={20} color={COLORS.BROWN_1}/>}
                type={'Опрос'}
                name={survey.name}
                image={survey.imageThumbnail}
                date={survey.createdAt}
            />
        );
    }

    if (announcement) {
        return (
            <Article
                href={ROUTES.ANNOUNCEMENT(announcement.id)}
                icon={<UiIcon icon={ICONS.ANNOUNCEMENT} size={20} color={COLORS.BROWN_1}/>}
                name={announcement.name}
                type={'Объявление'}
                description={announcement.previewText}
                image={announcement.imageThumbnail}
                date={announcement.createdAt}
            />
        );
    }

    return null;
});
