import React from 'react';
import { observer } from 'mobx-react';
import { useMousePosition, useWindowSize } from "shared/hooks";

import { ASSETS } from '../../assets';

export const LibraryBooks = observer(() => {
    const windowSize = useWindowSize();
    const mousePosition = useMousePosition();

    const getTranslate = (treshhold = 20) => {
        const x = (treshhold * (mousePosition.x - (windowSize.width / 2)) / (windowSize.width / 2));
        const y = (treshhold * (mousePosition.y - (windowSize.height / 2)) / (windowSize.height / 2));
        return `translate(${x.toFixed(4)}px, ${y.toFixed(4)}px)`
    }

    return (

        <div>
            <div
                style={{ backgroundImage: `url(${ASSETS.hbook1})`, transform: getTranslate(30) }}
                className="p-library-background__book"
            />
            <div
                style={{ backgroundImage: `url(${ASSETS.hbook2})`, transform: getTranslate(45) }}
                className="p-library-background__book"
            />
            <div
                style={{ backgroundImage: `url(${ASSETS.hbook3})`, transform: getTranslate(10) }}
                className="p-library-background__book"
            />
            <div
                style={{ backgroundImage: `url(${ASSETS.hbook4})`, transform: getTranslate(10) }}
                className="p-library-background__book"
            />
        </div>

    )
});
