import { useLocalObservable } from "mobx-react";
import { set, toJS } from "mobx";
import { useMemo } from "react";

type StoreType<T> = T & {
    set: <R>(key: keyof T, value: R) => R,
    reset: () => void,
    toggle: (key: keyof T) => boolean,
    handleChange: <R>(data: { name: string, value: R }) => void,
}

export function useStore<T extends Record<string, any>>(initializer: () => T): StoreType<T> {
    const store = useLocalObservable(initializer);
    const initialValues = useMemo(() => toJS(store), [store]);
    return useMemo(() => {
        set(store, 'set', function <R>(key: keyof T, value: R): R {
            set(this, key, value);
            return value;
        });
        set(store, 'reset', function () {
            set(this, initialValues);
        });
        set(store, 'toggle', function (key: keyof T): boolean {
            set(this, key, !this[key]);
            return !this[key];
        });
        set(store, 'handleChange', function <R>(data: { name: string, value: R }) {
            set(store, data.name, data.value);
        });
        return store as StoreType<T>;
    }, [store, initialValues]);
}
