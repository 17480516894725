import React from 'react';
import { observer } from 'mobx-react';

import { RequestCategoryModel, RequestTypeModel } from "shared/models";

import './index.scss';
import { useStore, useValidation } from "shared/hooks";
import { UiButton, UiForm, UiFormControl, UiInput, UiModal, UiModalClose, UiModalTitle, UiSelect, UiTextarea } from "shared/uikit";
import { requestsCreateQuery } from "shared/queries";
import { toJS } from "mobx";
import { Notifier } from "shared/utilities";

type PropsType = {
    requestCategory: RequestCategoryModel,
    requestType: RequestTypeModel,
    isOpened: boolean
    onClose: () => void
}

export const PRequestsCreate = observer(({ requestType, requestCategory, isOpened, onClose }: PropsType) => {
    const store = useStore(() => ({
        isSubmitted: false,
        isSubmitting: false
    }));

    const form = useStore(() => ({
        topic: null as string | null,
        phone: '',
        email: '',
        description: '',
    }));

    const validation = useValidation(form, (rules) => ({
        topic: rules.required(),
        phone: rules.required(),
        email: rules.email(),
    }));

    const handleSubmit = async () => {
        store.set('isSubmitted', true);
        if (store.isSubmitting || !validation.isValid) {
            return
        }

        store.set('isSubmitting', true);
        await requestsCreateQuery({
            ...toJS(form),
            requestTypeId: requestType.id
        });
        store.set('isSubmitting', false);
        store.set('isSubmitted', false);
        form.reset();
        onClose();
        Notifier.alert('Новая заявка', 'Ваша заявка успешно отправлена!');
    }

    return (
        <div className={'p-requests-create'}>
            <UiModal isOpened={isOpened} onClose={onClose} className={'p-requests-create-modal'}>
                <UiModalTitle
                    before={(
                        <div
                            className="p-requests-create__marker"
                            style={{ background: requestCategory.colorMarker }}
                        />
                    )}
                    title={'Новая заявка'}
                    subtitle={requestType.name}
                />
                <UiModalClose onClick={onClose}/>
                <UiForm onSubmit={handleSubmit}>
                    <UiFormControl
                        label={'Тема обращения'}
                        isRequired
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.topic.errorMessage}
                    >
                        <UiSelect
                            onChange={form.handleChange}
                            name={'topic'}
                            placeholder={'Выберите тему обращения'}
                            value={form.topic}
                            items={requestType.topics?.map(({ name }) => ({ id: name, name: name })) ?? []}
                        />
                    </UiFormControl>
                    <UiFormControl
                        label={'Телефон'}
                        isSubmitted={store.isSubmitted}
                        isRequired
                        errorMessage={validation.phone.errorMessage}
                    >
                        <UiInput
                            value={form.phone}
                            name={'phone'}
                            onChange={form.handleChange}
                            placeholder={'+7(___) ___-__-__'}
                        />
                    </UiFormControl>
                    <UiFormControl
                        label={'Email'}
                        isSubmitted={store.isSubmitted}
                        isRequired
                        errorMessage={validation.email.errorMessage}
                    >
                        <UiInput
                            value={form.email}
                            name={'email'}
                            onChange={form.handleChange}
                            placeholder={'Введите email'}
                        />
                    </UiFormControl>
                    <UiFormControl
                        label={'Текст обращения'}
                    >
                        <UiTextarea placeholder='Опишите ваш вопрос' value={form.description} name={'description'}
                                    onChange={form.handleChange}/>
                    </UiFormControl>
                    <div className='ui-modal__actions'>
                        <UiButton label={'Отправить'} isSubmit isLoading={store.isSubmitting}/>
                    </div>
                </UiForm>
            </UiModal>
        </div>
    )
});
