import { makeAutoObservable } from 'mobx';

import { IspringCourseModel } from 'shared/models';
import { fetchItems } from 'shared/mixins/service';
import { ispringCoursesQuery } from 'shared/queries';

import Store from './Store';

class Service {
    constructor() {
        makeAutoObservable(this);
    }

    fetchItems = fetchItems(ispringCoursesQuery, Store, IspringCourseModel);
}

export default new Service();
