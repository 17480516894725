import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";
import moment from "moment";
import lodash from "lodash";

import { UiButton, UiCalendarItem, UiCard, UiIcon, UiLoadingSpinner } from 'shared/uikit';
import { useNavigate, useStore } from "shared/hooks";
import { ICONS, ROUTES } from "shared/constants";
import { CalendarItemModel } from "shared/models";
import { calendarItemQuery } from "shared/queries";
import { PermissionEnum } from "shared/enums";
import { ApplicationModule } from "shared/modules";

import './styles.scss';

export const WCalendar = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        day: moment(),
        month: moment(),
        isLoading: true,
        isSuccess: false,
        calendarItems: [] as CalendarItemModel[],
        isOpened: false,
        value: null as moment.Moment | null,
    }));

    const days = useMemo(() => {
        let date = store.month.clone().startOf('month').startOf('week');
        const to = store.month.clone().endOf('month').endOf('week');

        const days = [];
        while (date.isBefore(to)) {
            days.push(date.clone());
            date = date.add(1, 'days');
        }
        return days;
    }, [store.month])

    const handlePrev = () => {
        if (store.isLoading) {
            return;
        }
        store.set('month', store.month.subtract(1, 'month').clone());
    }

    const handleNext = () => {
        if (store.isLoading) {
            return;
        }
        store.set('month', store.month.add(1, 'month').clone());
    }

    useEffect(() => {
        store.set("isLoading", true);
        (async () => {
            const response = await calendarItemQuery({
                dateFrom: store.month.clone().startOf('month').startOf('week').format('Y-MM-DD'),
                dateTo: store.month.clone().endOf('month').endOf('week').format('Y-MM-DD'),
                withBirthdays: 0
            });

            if (response.isSuccess && response.data) {
                store.set('calendarItems', response.data.items.map(item => new CalendarItemModel(item)));
            }
            store.set("isLoading", false);
        })();
    }, [store.month, store])

    const calendarItemsByDate: Record<string, CalendarItemModel[]> = useMemo(() => {
        return lodash.groupBy(store.calendarItems, 'startDateFormat');
    }, [store.calendarItems]);

    if (!ApplicationModule.user.can(PermissionEnum.WebCalendarAndReservation)) {
        return null;
    }
    if (store.isOpened && !!store.value) {
        const date = store.value.format('Y-MM-DD');
        const calendarItems = calendarItemsByDate[date] || [];
        return (
            <UiCard className={'w-calendar-items'}>
                <div className="w-calendar-items__close" onClick={() => store.set("isOpened", false)}>
                    <UiIcon size={16} icon={ICONS.CLOSE}/>
                </div>
                <div className="w-calendar-items__header">
                    <span>{store.value.format('dd D')}</span>
                    {}
                </div>
                {calendarItems.length === 0 && (
                    <div className="w-calendar-items__empty">
                        Нет назначенных событий на этот день
                    </div>
                )}
                {calendarItems.length > 0 && (
                    <div className="w-calendar-items__items">
                        {calendarItems.map(calendarItem => (
                            <UiCalendarItem
                                key={calendarItem.id}
                                item={calendarItem}
                                withAuthor={false}
                                withTime={true}
                                onClick={() => {
                                    navigate(ROUTES.CALENDAR(`?date=${date}&id=${calendarItem.id}&changeKey=${calendarItem.changeKey}`))
                                }}
                                toLeft
                            />
                        ))}
                    </div>
                )}
                <div className="w-calendar-items__footer">
                    <UiButton
                        isLink
                        label='Перейти в календарь'
                        onClick={() => {
                            navigate(ROUTES.CALENDAR(`?date=${date}`))
                        }}
                    />
                    <div
                        className="w-calendar-items__add"
                        onClick={() => {
                            navigate(ROUTES.CALENDAR(`?date=${date}&mode=create`))
                        }}
                    />
                </div>
            </UiCard>
        )
    }

    return (
        <UiCard className={classnames('w-calendar', {
            'w-calendar--loading': store.isLoading
        })}>
            <div className="w-calendar__header">
                <div className="w-calendar__button" onClick={handlePrev}>
                    <UiIcon size={14} icon={ICONS.CHEVRON_LEFT}/>
                </div>
                <div className="w-calendar__title">
                    <span>{store.month.format('MMMM')}</span> {store.month.format('YYYY')}
                </div>
                <div className="w-calendar__button" onClick={handleNext}>
                    <UiIcon size={14} icon={ICONS.CHEVRON_RIGHT}/>
                </div>
            </div>
            <div className="w-calendar__inner">
                <div className="w-calendar-weekdays">
                    <span>Пн</span>
                    <span>Вт</span>
                    <span>Ср</span>
                    <span>Чт</span>
                    <span>Пт</span>
                    <span>Сб</span>
                    <span>Вс</span>
                </div>
                <div className="w-calendar-days">
                    {days.map(day => {
                        const date = day.format('Y-MM-DD');
                        const className = classnames('w-calendar-day', {
                            'w-calendar-day--current': day.format('MM.DD') === store.day.format('MM.DD'),
                            'w-calendar-day--outer': day.month() !== store.month.month(),
                        });
                        const calendarItems = calendarItemsByDate[date] || [];
                        return (
                            <div
                                key={day.format('Y-M-D')}
                                className={className}
                                onClick={() => {
                                    store.set("value", day.clone());
                                    store.set("isOpened", true);
                                }}
                            >
                                <span>{day.format('D')}</span>
                                <div className="w-calendar-day__dots">
                                    {calendarItems.slice(0, 3).map((calendarItem) => (
                                        <div className="w-calendar-day__dot" key={calendarItem.id}/>
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {store.isLoading && (
                    <div className="w-calendar__loading">
                        <UiLoadingSpinner/>
                    </div>
                )}
            </div>
        </UiCard>
    );
});
