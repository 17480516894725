import React from 'react';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import classnames from "classnames";

import './styles.scss';

type PropsType = {
    value?: string;
    className?: string;
}
export const UiHtml = observer(({ className, value }: PropsType) => {
    const classNames = classnames('ui-html', className);
    if (!value) {
        return null;
    }

    const valueTableFix = value.replace('<table', '<div class="ui-html__table"><table')
        .replace('</table>', '</table></div>');

    return (
        <div className={classNames}>{ReactHtmlParser(valueTableFix)}</div>
    );
})
