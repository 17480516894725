import { makeQuery } from 'shared/utilities';
import { ISurveyModel } from 'shared/models';

type RequestType = {
    page?: number,
    isAnonymous?: string | null,
    statusId?: string | null,
    orderBy?: string | null,
};

type ResponseType = {
    pages: number;
    page: number;
    items: ISurveyModel[];
};

export const surveysQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/surveys', { params });
}
