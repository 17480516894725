import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: number,
    userId: number[],
}

type ResponseType = {
};

export const chatRoomsInviteQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/chat-rooms/invite', { json: payload });
}
