import React from 'react';
import { observer } from 'mobx-react';
import Lottie from "lottie-react"

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { UiDatePicker, UiGrid, UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { useUserWidgets } from "shared/hooks";
import { WSocialNetworks } from "shared/widgets";

import loader from './loader.json';

export const TestPage = observer(() => {
    const iconNames = Object.keys(ICONS);
    return (
        <LayoutBody>
            <LayoutContent>
                <div style={{padding: 40}}>
                    <Lottie animationData={loader}></Lottie>
                </div>
                <UiDatePicker/>
                <UiGrid columns={4}>
                    {iconNames.map(iconName => {
                        const icon = (ICONS as any)[iconName];
                        return (
                            <div key={iconName} style={{ display: 'flex', marginBottom: 16 }}>
                                <UiIcon icon={icon} size={16} color={COLORS.BLACK}/><div>- {iconName}</div>
                            </div>
                        )
                    })}
                </UiGrid>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}>
                <WSocialNetworks/>
            </LayoutAside>
        </LayoutBody>
    );
});

