import { makeQuery } from 'shared/utilities';
import { IVacancyRegionModel } from 'shared/models';
import { ObjectType } from 'shared/types';

type ResponseType = {
    pages: number;
    items: IVacancyRegionModel[];
};

export const vacanciesRegionsQuery = async function (params: ObjectType = {}) {
    return await makeQuery<ResponseType>('/vacancies/regions', { params });
}
