import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { UiAvatar, UiButton, UiDataBoundary, UiGrid, UiNavLink, UiUser } from 'shared/uikit';
import { UserModel } from 'shared/models';
import { useMedia, useNavigate, useRouteParams, useStore, useUserWidgets } from "shared/hooks";
import { chatRoomsCreateQuery, usersGetQuery } from "shared/queries";
import { ROUTES } from "shared/constants";
import { ApplicationModule } from "shared/modules";

import { PProfileUnavailable } from "pages/profile/components/PProfileUnavailable";

import './styles.scss';

const userImageDefault: string = require('./assets/user.svg').default;

export const UserPage = observer(() => {
    const navigate = useNavigate();
    const { id } = useRouteParams<{ id: string | number }>();
    const store = useStore(() => ({
        user: new UserModel(),
        isSuccess: true,
        isLoading: true,
    }));

    useEffect(() => {
        ApplicationModule.setTitle('...');
        window.scrollTo(0, 0);
    }, [id]);

    useEffect(() => {
        (async () => {
            if (+store.user.id === +id || store.user.slug === id) {
                ApplicationModule.setTitle(store.user.previewName);
                return;
            }
            store.set('isLoading', true);
            store.set('isSuccess', true);
            const { isSuccess, data } = await usersGetQuery({ id });
            if (isSuccess && data) {
                store.set('user', new UserModel(data.item));
            }
            store.set('isSuccess', isSuccess && data);
            store.set('isLoading', false);
            navigate(ROUTES.USER(store.user.slug), {}, {
                replace: true,
            });
            ApplicationModule.setTitle(store.user.previewName);
        })();
    }, [id, store, navigate]);

    const { value: userFieldsColumns } = useMedia({ is320: 1, is1440: 2, is1920: 3 });

    const handleChatRoomCreate = useCallback(async () => {
        const { isSuccess, data } = await chatRoomsCreateQuery({
            userId: store.user.id,
            typeId: 'direct'
        });
        if (isSuccess && data) {
            navigate(ROUTES.CHAT(data.item.id));
        }
    }, [navigate, store]);

    const showSection = store.user.responsibilities || (store.user.management.length > 0) || (store.user.colleagues.length > 0);

    return (
        <LayoutBody>
            <LayoutContent>
                <UiDataBoundary className='p-user' isLoading={store.isLoading} isError={!store.isSuccess}>
                    <div className="p-user__aside">
                        <div className="p-user-section p-user-section--aside">
                            <div className='p-user__avatar'>
                                <UiAvatar size={268} image={store.user.imageThumbnail || userImageDefault}/>
                            </div>
                            {+store.user.id !== +ApplicationModule.user.id && (
                                <>
                                    {store.user.isAvailable && (
                                        <UiButton label='Написать сообщение' onClick={handleChatRoomCreate}/>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="p-user__main">
                        <div className="p-user-section">
                            <div className="p-user__name">{store.user.previewName}</div>
                            <div className="p-user__position">
                                {store.user.userPositionName ? `${store.user.userPositionName}, ` : ''}
                                {store.user.hiredAt ? `стаж ${moment(store.user.hiredAt).fromNow(true)}` : ''}
                            </div>
                            {store.user.departmentBranch && (
                                <div className="p-user-departments">
                                    {store.user.departmentBranch.isVisible && (
                                        <UiNavLink
                                            to={ROUTES.DEPARTMENT_BRANCH(store.user.departmentBranch.id)}
                                            className="p-user-departments__item"
                                        >
                                            {store.user.departmentBranch?.name}
                                        </UiNavLink>
                                    )}
                                    {store.user.departmentBranch?.department?.isVisible && (
                                        <UiNavLink
                                            to={ROUTES.DEPARTMENT(store.user.departmentBranch?.department?.id)}
                                            className="p-user-departments__item"
                                        >
                                            {store.user.departmentBranch?.department?.name}
                                        </UiNavLink>
                                    )}
                                </div>
                            )}
                            <UiGrid
                                columns={userFieldsColumns}
                                className="p-user-fields"
                                gap={16}
                                style={{ margin: 0, maxWidth: 800 }}
                            >
                                {store.user.phone && (
                                    <div className="p-user-field">
                                        <div className="p-user-field__name">Рабочий телефон</div>
                                        <div className="p-user-field__value">{store.user.phone}</div>
                                    </div>
                                )}
                                {store.user.mobile && (
                                    <div className="p-user-field">
                                        <div className="p-user-field__name">Мобильный телефон</div>
                                        <div className="p-user-field__value">{store.user.mobile}</div>
                                    </div>
                                )}
                                {store.user.email && (
                                    <div className="p-user-field">
                                        <div className="p-user-field__name">Е-mail</div>
                                        <a
                                            href={`mailto:${store.user.email}`}
                                            className="p-user-field__value p-user-field__value--email"
                                        >
                                            {store.user.email}
                                        </a>
                                    </div>
                                )}
                            </UiGrid>
                            <PProfileUnavailable user={store.user}/>
                        </div>
                        {store.user.birthdayAt && (
                            <div className="p-user-section">
                                <div className="p-user-section__title">Дата рождения</div>
                                <div className="p-user-section__description">{moment(store.user.birthdayAt).format('D MMMM')}</div>
                            </div>
                        )}
                        {showSection && (
                            <div className="p-user-section">
                                {store.user.responsibilities && (
                                    <>
                                        <div className="p-user-section__title">Должностные обязанности:</div>
                                        <div className="p-user__responsibilities">{store.user.responsibilities}</div>
                                    </>
                                )}
                                {store.user.management.length > 0 && (
                                    <>
                                        <div className="p-user-section__title">Руководитель:</div>
                                        <div className="p-user-employees">
                                            {store.user.management.map(user => (
                                                <UiUser key={user.id} user={user} isManagement isFramed/>
                                            ))}
                                        </div>
                                    </>
                                )}
                                {store.user.colleagues.length > 0 && (
                                    <>
                                        <div className="p-user-section__title">Коллеги:</div>
                                        <div className="p-user-employees">
                                            {store.user.colleagues.map(user => (
                                                <UiUser key={user.id} user={user} isFramed/>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        {store.user.userPersonalFields.length > 0 && (
                            <div className="p-user-section">
                                <div className="p-user-section__title">Личная информация:</div>
                                {store.user.userPersonalFields.map(personalField => {
                                    if (!personalField.value) {
                                        return null;
                                    }
                                    return (
                                        <div key={personalField.id} className="p-user-personal-field">
                                            <div className="p-user-personal-field__name">{personalField.name}</div>
                                            <div className="p-user-personal-field__value">{personalField.value}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
})
