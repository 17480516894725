import { makeQuery } from 'shared/utilities';
import { IDepartmentModel } from 'shared/models';

type ResponseType = {
    items: IDepartmentModel[];
};

export const vacanciesDepartmentBranchesQuery = async function () {
    return await makeQuery<ResponseType>('/vacancies/department-branches');
}
