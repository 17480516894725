import React from 'react';
import { observer } from 'mobx-react';

import { EssentialModel } from 'shared/models';
import { ROUTES } from 'shared/constants';
import { UiDate, UiImageThumbnail, UiNavLink } from 'shared/uikit';

import './styles.scss';

type PropsType = {
    item: EssentialModel;
    viewType: string;
}

export const ArticleItem = observer(({ item, viewType }: PropsType) => {
    return (
        <UiNavLink to={ROUTES.ESSENTIAL(item.id)} className={`article-item article-item--${viewType}`}>
            <UiImageThumbnail className='article-item__image' value={item.previewImageThumbnail}/>
            <div className='article-item__body'>
                <div className='article-item__date'>
                    <UiDate value={item.createdAt}/>
                </div>
                <div className='article-item__name'>{item.name}</div>
                {item.previewText && (
                    <div className='article-item__description'>{item.previewText}</div>
                )}
            </div>
        </UiNavLink>
    );
})

