import React from 'react';
import { observer } from 'mobx-react';

import { ArticleModel, ITagModel } from 'shared/models';
import { ROUTES } from 'shared/constants';
import { UiDate, UiLikeAction, UiTags, UiImageThumbnail, UiNavLink, UiCommentAction } from 'shared/uikit';
import { OnChangeHandlerType } from 'shared/types';
import { useNavigate, useUrlParams } from "shared/hooks";

import './styles.scss';

type PropsType = {
    item: ArticleModel;
    viewType: string;
}

export const ArticleItem = observer(({ item, viewType }: PropsType) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        tagId: null as number | null,
        isPrivate: null as number | null
    });

    const handleLike: OnChangeHandlerType<number> = data => {
        item.update({
            liked: data.value
        })
    };

    const handleClickTag: OnChangeHandlerType<ITagModel> = data => {
        navigate(ROUTES.ARTICLES(), {
            ...urlParams,
            tagId: data.value.id
        });
    };

    return (
        <UiNavLink to={ROUTES.ARTICLE(item.id)} className={`article-item article-item--${viewType}`}>
            <UiImageThumbnail className='article-item__image' value={item.previewImageThumbnail}/>
            <div className='article-item__body'>
                <div className='article-item__date'>
                    <UiDate value={item.createdAt}/>
                </div>
                <div className='article-item__name'>{item.name}</div>
                {item.previewText && (
                    <div className='article-item__description'>{item.previewText}</div>
                )}
                <div className='article-item__footer'>
                    <div className='article-item__tags'>
                        <UiTags items={item.tags} onClick={handleClickTag}/>
                    </div>
                    <div className="article-item__actions">
                        <UiLikeAction
                            entityType='Article'
                            entityId={item.id}
                            onChange={handleLike}
                            value={item.liked} count={item.likesCount}
                        />
                        <UiCommentAction to={ROUTES.ARTICLE(item.id)} count={item.commentsCount}/>
                    </div>
                </div>
            </div>
        </UiNavLink>
    );
})

