import React from 'react';
import { observer } from 'mobx-react';

import { ApplicationModule } from 'shared/modules';
import { UiGrid, UiNavLink } from 'shared/uikit';
import { ROUTES } from "shared/constants";
import { useMedia } from "shared/hooks";

import { PProfileAvailability } from "../PProfileAvailability";

import './styles.scss';
import { PProfileUnavailable } from "pages/profile/components/PProfileUnavailable";

export const PProfileUser = observer(() => {
    const { value: userFieldsColumns } = useMedia({ is320: 1, is1440: 2, is1920: 3 });

    return (
        <div className="p-user-section">
            <PProfileAvailability/>
            {ApplicationModule.user.userPositionName && (
                <div className="p-user__position">{ApplicationModule.user.userPositionName}</div>
            )}
            {ApplicationModule.user.departmentBranch && (
                <div className="p-user-departments">
                    <UiNavLink
                        to={ROUTES.DEPARTMENT_BRANCH(ApplicationModule.user.departmentBranch.id)}
                        className="p-user-departments__item"
                    >
                        {ApplicationModule.user.departmentBranch?.name}
                    </UiNavLink>
                    <UiNavLink
                        to={ROUTES.DEPARTMENT(ApplicationModule.user.departmentBranch?.department?.id)}
                        className="p-user-departments__item"
                    >
                        {ApplicationModule.user.departmentBranch?.department?.name}
                    </UiNavLink>
                </div>
            )}
            <UiGrid
                columns={userFieldsColumns}
                className="p-user-fields"
                style={{margin: 0, maxWidth: 800}}
                gap={[0, 16]}
            >
                {ApplicationModule.user.phone && (
                    <div className="p-user-field">
                        <div className="p-user-field__name">Рабочий телефон</div>
                        <div className="p-user-field__value">{ApplicationModule.user.phone}</div>
                    </div>
                )}
                {ApplicationModule.user.mobile && (
                    <div className="p-user-field">
                        <div className="p-user-field__name">Мобильный телефон</div>
                        <div className="p-user-field__value">{ApplicationModule.user.mobile}</div>
                    </div>
                )}
                {ApplicationModule.user.email && (
                    <div className="p-user-field">
                        <div className="p-user-field__name">Е-mail</div>
                        <a href={`mailto:${ApplicationModule.user.email}`} className="p-user-field__value p-user-field__value--email">
                            {ApplicationModule.user.email}
                        </a>
                    </div>
                )}
            </UiGrid>
            <PProfileUnavailable user={ApplicationModule.user}/>
        </div>
    );
});
