import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from "shared/hooks";
import { SurveyModel } from 'shared/models';
import { UiDataBoundary, UiIcon, UiUsersStack } from 'shared/uikit';
import { surveysGetQuery } from "shared/queries";

import './styles.scss';
import { ICONS } from "shared/constants";

type PropsType = {
    surveyId: number;
    onClose: () => void
}

export const SurveyItemDetail = observer(({ surveyId, onClose }: PropsType) => {
    const store = useStore(() => ({
        survey: null as SurveyModel | null,
        isLoading: true,
        isSuccess: true
    }))

    const fetchItem = useCallback(async () => {
        store.set('isLoading', true);
        store.set('isSuccess', true);

        const response = await surveysGetQuery(surveyId);
        if (response.isSuccess && response.data) {
            store.set('survey', new SurveyModel(response.data.item));
        } else {
            store.set('isSuccess', false);
        }
        store.set('isLoading', false);
    }, [store, surveyId]);

    useEffect(() => {
        fetchItem();
    }, [fetchItem]);

    if (store.survey === null) {
        return null;
    }

    return (
        <div className='survey-item-detail'>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="survey-item-detail__title">
                    {store.survey.name}
                </div>
                <div className="survey-item-detail__close" onClick={onClose}>
                    <UiIcon size={20} icon={ICONS.CLOSE}/>
                </div>
                <div className="survey-item-detail__subtitle">
                    {store.survey.isAnonymous ? 'Анонимный опрос' : 'Публичный опрос'}
                    <div className="survey-item-detail__type">
                    </div>
                    {store.survey.createdAtMoment.format('DD.MM.YYYY')}
                    <div className="survey-item-detail__date">
                    </div>
                </div>
                <div className="survey-item-detail__count">
                    Всего проголосовало: <span>{store.survey.userSurveyQuestionAnswers.length} человек(а)</span>
                </div>
                {store.survey.surveyQuestions.map(surveyQuestion => (
                    <div key={surveyQuestion.id} className="survey-item-detail-question">
                        {surveyQuestion.name && (
                            <div className="survey-item-detail-question__name">{surveyQuestion.name}</div>
                        )}
                        <div className="survey-item-detail-question__answers">
                            {surveyQuestion.surveyQuestionAnswers.map(surveyQuestionAnswer => {
                                let percent = surveyQuestion.userSurveyQuestionAnswersCount === 0
                                    ? 0
                                    : surveyQuestionAnswer.userSurveyQuestionAnswersCount * 100 / surveyQuestion.userSurveyQuestionAnswersCount;
                                percent = Math.round(percent * 100) / 100;
                                return (
                                    <div key={surveyQuestionAnswer.id} className="survey-item-detail-question-answer">
                                        <div className="survey-item-detail-question-answer__header">
                                            <div className="survey-item-detail-question-answer__name">
                                                {surveyQuestionAnswer.name} ({surveyQuestionAnswer.userSurveyQuestionAnswersCount})
                                            </div>
                                            <div className="survey-item-detail-question-answer__percent">
                                                {percent}%
                                            </div>
                                        </div>
                                        <div className="survey-item-detail-question-answer__bar">
                                            <span style={{width: `${percent}%`}}></span>
                                        </div>
                                        {!store.survey?.isAnonymous && (
                                            <UiUsersStack value={surveyQuestionAnswer.users}/>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </UiDataBoundary>
        </div>
    )
});
