import React from 'react';
import { observer } from 'mobx-react';
import { useLocation } from "react-router-dom";

import { LayoutContent } from './components/LayoutContent';
import { LayoutContentFilters } from './components/LayoutContentFilters';
import { LayoutContentBanner } from './components/LayoutContentBanner';
import { LayoutContentPanel } from './components/LayoutContentPanel';
import { LayoutContentTitle } from './components/LayoutContentTitle';
import { LayoutAside } from './components/LayoutAside';
import { LayoutBody } from './components/LayoutBody';
import { LayoutBodyHeader } from './components/LayoutBodyHeader';
import { NotifierModal } from './components/NotifierModal';
import { LayoutMenuPrimary } from "./components/LayoutMenuPrimary";
import { LayoutMenuSecondary } from "./components/LayoutMenuSecondary";
import { LayoutHeader } from "./components/LayoutHeader";
import { LayoutHeaderMobile } from "./components/LayoutHeaderMobile";
import { LayoutHotNotifications } from "./components/LayoutHotNotifications";

import LayoutStore from './Store';
import LayoutService from './Service';

import './styles.scss';

type PropsType = {
    children?: React.ReactNode,
    excludeRoutes?: string[]
}

const Layout = observer(({ children, excludeRoutes }: PropsType) => {
    const location = useLocation();

    if (excludeRoutes?.includes(location.pathname)) {
        return (<>{children}</>);
    }
    return (
        <div className='layout'>
            <LayoutMenuPrimary/>
            <div className="layout__main">
                <LayoutHeader/>
                <LayoutHeaderMobile/>
                {children}
            </div>
            <LayoutMenuSecondary/>
            <NotifierModal/>
            <LayoutHotNotifications/>
        </div>
    );
});

export {
    Layout,
    LayoutContent,
    LayoutContentFilters,
    LayoutContentBanner,
    LayoutContentPanel,
    LayoutContentTitle,
    LayoutAside,
    LayoutBody,
    LayoutBodyHeader,
    LayoutStore,
    LayoutService,
};
