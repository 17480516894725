import React from 'react';
import { observer } from 'mobx-react';

import { UserModel } from "shared/models";
import { UiAvatar } from "shared/uikit";

import './styles.scss';

interface Props {
    value: UserModel;
    size?: number,
    fontSize?: number
}

export const UiUserPreview = observer(({ value, size = 22, fontSize = 16, }: Props) => {
    const style = { fontSize, lineHeight: `${fontSize}px` };

    return (
        <div className='ui-user-preview' style={style} title={value.name}>
            <UiAvatar label={value.name} image={value.image} size={size}/>
            <div className='ui-user-preview__name'>{value.name}</div>
        </div>
    );
});
