import { makeQuery } from 'shared/utilities';
import { IUserPersonalFieldModel } from 'shared/models';

type RequestType = IUserPersonalFieldModel;

type ResponseType = {
    items: IUserPersonalFieldModel[];
};

export const userPersonalFieldsSaveQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/user-personal-fields/save', {
        json: request
    });
}
