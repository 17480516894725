import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { GalleryFileModel, IGalleryFileModel } from 'shared/models';
import { ModelArrayCast } from "shared/casts";
import { ThumbnailType } from "shared/types";

import { Model } from './Model';

export interface IGalleryModel {
    id?: number;
    isMain?: number;
    liked?: number;
    likesCount?: number;
    commentsCount?: number;
    image?: string;
    description?: string;
    previewColor?: string;
    createdAt?: string;
    name?: string;
    filesUrls?: string[];
    imageThumbnail?: ThumbnailType,
    galleryFiles?: IGalleryFileModel[];
}

export class GalleryModel extends Model implements IGalleryModel {
    casts = {
        galleryFiles: new ModelArrayCast(GalleryFileModel),
    };

    id = 0;
    isMain = 0;
    liked = 0;
    likesCount = 0;
    commentsCount = 0;
    createdAt = '';
    name = '';
    image = '';
    description = '';
    previewColor = '';
    imageThumbnail: ThumbnailType = null;
    galleryFiles: GalleryFileModel[] = [];

    constructor(payload: IGalleryModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            isMain: observable,
            createdAt: observable,
            name: observable,
            liked: observable,
            commentsCount: observable,
            likesCount: observable,
            galleryFiles: observable,
            image: observable,
            description: observable,
            previewColor: observable,
            imageThumbnail: observable,
            hasImages: computed,
            hasVideos: computed,
            preview: computed,
            createdAtMoment: computed,
            update: action,
        });

        this.update(payload);
    }

    get hasImages() {
        return this.galleryFiles.filter(file => file.isImage).length > 0
    }

    get hasVideos() {
        return this.galleryFiles.filter(file => !file.isImage).length > 0
    }

    get preview() {
        const result = [];
        if (this.imageThumbnail) {
            result.push(this.imageThumbnail);
        }
        this.galleryFiles.filter(file => file.isImage).forEach((file) => {
            result.push(file.thumbnail);
        });
        return result.slice(0, 3);
    }

    get createdAtMoment() {
        return moment(this.createdAt);
    }
}
