import React from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS } from 'shared/constants';
import { useStore } from "shared/hooks";
import { chatMessagesCreateQuery, chatRoomsCreateQuery } from "shared/queries";
import { ChatRoomTypeEnum } from "shared/enums";

import { UiIcon } from '../UiIcon';
import { UiButton } from '../UiButton';
import { UiChatRoomSelect } from '../UiChatRoomSelect';
import { UiModal } from '../UiModal';
import UiTextarea from '../UiTextarea';

import './styles.scss';

type PropsType = {
    entityType?: string,
    entityId?: number,
    count?: number,
}

export const UiRepostAction = observer((
    {
        entityType = '',
        entityId = 0,
        count = 0,
    }: PropsType
) => {
    const store = useStore(() => ({
        isOpened: false,
        chatRoomId: null as null | number,
        userId: null as null | number,
        content: '',
        count: count
    }));

    const handleSubmit = async () => {
        if (!store.chatRoomId && !store.userId) {
            return;
        }
        let chatRoomId = store.chatRoomId;
        if (!chatRoomId && store.userId) {
            const responseChatRoomCreate = await chatRoomsCreateQuery({
                userId: store.userId,
                typeId: ChatRoomTypeEnum.Direct
            });
            if (responseChatRoomCreate.data) {
                chatRoomId = responseChatRoomCreate.data.item.id || null;
            }
        }
        if (!chatRoomId) {
            return;
        }
        store.set("isOpened", false);
        await chatMessagesCreateQuery({
            chatRoomId,
            key: `${Date.now()}-${store.chatRoomId}-repost-${entityId}`,
            content: store.content,
            [`${entityType?.toLowerCase()}_id`]: entityId
        });
        store.set("count", store.count + 1);
    }

    return (
        <>
            <div className='ui-repost-action' onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                store.set("isOpened", true)
            }}>
                <UiIcon
                    icon={ICONS.REPOST}
                    size={21}
                    color={COLORS.GRAY_4}
                />
                <div className="ui-repost-action__count">{store.count}</div>
            </div>
            {store.isOpened && (
                <UiModal
                    isOpened={store.isOpened}
                    onClose={() => store.set("isOpened", false)}
                    title={'Выберите чат'}
                >
                    <UiChatRoomSelect
                        style={{ marginBottom: 16 }}
                        value={{
                            userId: store.userId,
                            chatRoomId: store.chatRoomId
                        }}
                        name={'chatRoomId'}
                        onChange={({ value }) => {
                            const { userId, chatRoomId } = value;
                            store.set("userId", userId);
                            store.set("chatRoomId", chatRoomId);
                        }}
                    />
                    <UiTextarea
                        placeholder='Введите сообщение'
                        value={store.content}
                        name={'content'}
                        onChange={store.handleChange}
                    />
                    <div className="ui-modal__actions">
                        <UiButton isSmall label={'Отправить'} onClick={handleSubmit}/>
                    </div>
                </UiModal>
            )}
        </>
    )
})
