import { makeQuery } from 'shared/utilities';
import { ObjectType } from 'shared/types';

type ResponseType = {
    favorited: number;
};

export const favoritesToggleQuery = async function (json: ObjectType = {}) {
    return await makeQuery<ResponseType>('/favorites/toggle', { json });
}
