import { makeQuery } from 'shared/utilities';
import { IUserModel } from "shared/models";

type RequestType = {
    entityId: number;
    entityType: string;
}

type ResponseType = {
    items: IUserModel[];
};

export const likesUsersQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/likes/users', { json: payload });
}
