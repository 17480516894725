import React from 'react';
import { observer } from 'mobx-react';

import { ApplicationModule } from 'shared/modules';
import { UiButton, UiForm, UiFormControl, UiTextarea } from 'shared/uikit';
import { useStore } from "shared/hooks";
import { usersUpdateQuery } from "shared/queries";

import './styles.scss';

export const PProfileUserResponsibilities = observer(() => {
    const store = useStore(() => ({
        isLoading: false,
        showForm: false,
    }))

    const form = useStore(() => ({
        responsibilities: ''
    }))

    const handleEdit = () => {
        form.set('responsibilities', ApplicationModule.user.responsibilities);
        store.set("showForm", true);
    }

    const handleSubmit = async () => {
        if (store.isLoading) {
            return;
        }
        store.set('isLoading', true);
        await usersUpdateQuery(form);
        ApplicationModule.user.update({
            responsibilities: form.responsibilities
        });
        store.set('showForm', false);
        store.set('isLoading', false);
    }

    return (
        <div className="p-user-section">
            <div className="p-user-section__title">Должностные обязанности</div>
            {store.showForm && (
                <UiForm onSubmit={handleSubmit}>
                    <UiFormControl>
                        <UiTextarea
                            style={{ marginBottom: 16 }}
                            placeholder='Опишите свои должностные обязанности'
                            name='responsibilities'
                            value={form.responsibilities}
                            onChange={form.handleChange}
                        />
                    </UiFormControl>
                    <UiButton label='Сохранить' isSubmit isLoading={store.isLoading}/>
                </UiForm>
            )}
            {!store.showForm && (
                <>
                    <div style={{marginBottom: 16}} className="p-user__responsibilities">
                        {ApplicationModule.user.responsibilities}
                    </div>
                    <UiButton
                        label={!ApplicationModule.user.responsibilities ? 'Заполнить' : 'Изменить'}
                        isLink
                        onClick={handleEdit}
                    />
                </>
            )}
        </div>
    );
});
