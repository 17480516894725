export default new (class Window {
    private savedScrollPosition = 0;

    scrollTo = (top = 0) => {
        setTimeout(() => {
            window.scrollTo(0, top);
        }, 0);
    };

    scrollSave = () => {
        this.savedScrollPosition = window.pageYOffset;
    };

    scrollRestore = () => {
        this.scrollTo(this.savedScrollPosition);
    };
})();
