import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import classnames from "classnames";

import { COLORS, ICONS } from "shared/constants";
import { useStore } from "shared/hooks";

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    image?: string | null;
    isOpened?: boolean;
    onClose?: () => void;
    scale?: number;
}

export const UiPreviewImage = observer((
    {
        image,
        isOpened = false,
        onClose,
        scale = 1
    }: PropsType
) => {
    const store = useStore(() => ({
        isLoading: true
    }));
    useEffect(() => {
        if (!isOpened) {
            store.set("isLoading", true);
        }
    }, [isOpened, store]);

    if (!image || !isOpened) {
        return null;
    }

    const className = classnames('ui-preview-image', {
        'ui-preview-image--loading': store.isLoading
    });
    return (
        <div className={className}>
            <TransformWrapper centerOnInit initialScale={scale} minScale={scale}>
                <TransformComponent wrapperStyle={{
                    width: '100vw',
                    height: '100vh'
                }}>
                    <img
                        src={image}
                        alt=""
                        onLoad={() => {
                            store.set("isLoading", false);
                        }}
                    />
                </TransformComponent>
            </TransformWrapper>

            <div className="ui-preview-image__close" onClick={onClose}>
                <UiIcon icon={ICONS.CLOSE} size={24} color={COLORS.WHITE}/>
            </div>
        </div>
    );
})
