import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiCard, UiCardTitle, UiDataBoundary, UiIcon } from 'shared/uikit';
import { useStore } from "shared/hooks";
import { WebResourceModel } from "shared/models";
import { webResourcesQuery } from "shared/queries";
import { COLORS, ICONS } from "shared/constants";

import './styles.scss';

export const WWebResources = observer(() => {
    const store = useStore(() => ({
        items: [] as WebResourceModel[],
        isLoading: true,
        isSuccess: false,
        isOpened: false,
    }));

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', false);
            const response = await webResourcesQuery();
            if (response.isSuccess && response.data) {
                store.set('items', response.data.items.map(item => new WebResourceModel(item)));
                store.set('isSuccess', true);
            }
            store.set('isLoading', false);
        })();
    }, [store]);

    return (
        <UiCard className='w-web-resources'>
            <UiCardTitle label='Наши ресурсы' className='w-web-resources__title' collapsable={'w-web-resources'}/>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="w-web-resources__items">
                    {store.items.map(item => (
                        <a
                            key={item.id}
                            className={'w-web-resources-item'}
                            href={item.linkHref}
                            target='_blank'
                            rel="noreferrer"
                        >
                            <div className="w-web-resources-item__image" style={{ backgroundImage: `url(${item.image})` }}/>
                            <div className="w-web-resources-item__inner">
                                <div className="w-web-resources-item__name">{item.name}</div>
                                <div className="w-web-resources-item__link">
                                    {item.linkName}
                                </div>
                            </div>
                            <div className="w-web-resources-item__icon">
                                <UiIcon icon={ICONS.LINK} size={16} color={COLORS.GRAY_2}/>
                            </div>
                        </a>
                    ))}
                </div>
            </UiDataBoundary>
        </UiCard>
    );
});
