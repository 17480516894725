import { makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';
import { UserModel } from "./UserModel";

export interface ISurveyQuestionAnswerModel {
    id?: number;
    name?: string;
    isExtended?: number;
    users?: UserModel[];
    userSurveyQuestionAnswersCount?: number;
}

export class SurveyQuestionAnswerModel extends Model implements ISurveyQuestionAnswerModel {
    casts = {
        users: new ModelArrayCast(UserModel)
    };

    id = 0;
    name = '';
    isSelected = false;
    isExtended = 0;
    users: UserModel[] = [];
    userSurveyQuestionAnswersCount = 0;

    constructor(payload: ISurveyQuestionAnswerModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            users: observable,
            isExtended: observable,
            isSelected: observable,
            userSurveyQuestionAnswersCount: observable,
        });

        this.update(payload);
    }
}
