import qs from 'qs';
import { useCallback } from "react";
import { NavigateOptions, useNavigate as useNavigateReactRouter } from 'react-router-dom';

import { ObjectType } from 'shared/types';

const buildQueryString = (route = '/', queryParams = {}) => {
    const searchString = qs.stringify(queryParams, {
        skipNulls: true,
        filter: function (key, value) {
            if (key.toLowerCase().indexOf('_id') > -1 && +value === -1) {
                return;
            }
            return value;
        },
    });

    if (!searchString) {
        return route;
    }

    return `${route}?${searchString}`;
};

export const useNavigate = () => {
    const navigate = useNavigateReactRouter();
    return useCallback(
        (route: string | number | null, params: ObjectType = {}, options?: NavigateOptions, scrollTop: number | null = 0) => {
            const _route = route ? route : window.location.pathname;
            if (scrollTop !== null) {
                window.scrollTo(0, scrollTop);
            }
            if (typeof _route === 'number') {
                return navigate((_route as any), options);
            } else if (route === null) {
                return navigate(buildQueryString(window.location.pathname, params) + window.location.hash, {
                    replace: true,
                    preventScrollReset: true,
                    ...options,
                });
            } else {
                return navigate(buildQueryString(_route, params), options);
            }
        },
        [navigate]
    );
};
