import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from "moment/moment";

import { useNavigate, useUrlParams } from "shared/hooks";
import { UiAvatar, UiCheckbox, UiIcon } from "shared/uikit";
import { TaskTrackerTaskStatusEnum } from "shared/enums";
import { Notifier } from "shared/utilities";
import { ICONS } from "shared/constants";

import { tasksDeleteQuery, tasksUpdateQuery } from "shared/queries/TaskTracker";
import { CContextMenu } from "shared/components/TaskTracker";
import { TaskModel } from "shared/models/TaskTracker";

import './index.scss';

type PropsType = {
    task: TaskModel,
    onUpdate?: (task: TaskModel) => void,
    onDelete?: (task: TaskModel) => void,
}

export const CTaskArchive = observer((
    {
        task,
        onUpdate = () => {
        },
        onDelete = () => {
        }
    }: PropsType
) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({});
    const handleOpen = () => {
        navigate(null, {
            ...urlParams,
            previewTaskId: task.id
        });
    }

    const handleUpdateStatus = async (statusId: string | null) => {
        if (!statusId) {
            return;
        }
        task.update({
            statusId
        });
        const { isSuccess, data } = await tasksUpdateQuery({
            id: task.id,
            statusId,
            doneAt: statusId === TaskTrackerTaskStatusEnum.Done.id ? moment().utc().format('YYYY-MM-DD HH:mm:ss') : null
        });
        if (isSuccess && data) {
            onUpdate(new TaskModel(data.item));
        }
    }

    const handleDelete = async () => {
        const result = await Notifier.confirm(
            `Вы точно хотите удалить задачу «${task.name}»?`,
            <>Отменить это действие буде невозможно! <br/>Восстановить данные не получится!</>
        );
        if (!result) {
            return;
        }

        tasksDeleteQuery({
            id: task.id
        });

        onDelete(task);
    }

    const expiration = useMemo(() => {
        if (task.expiredAtMoment.isBefore(task.doneAtMoment)) {
            let unit = '';
            let days = task.doneAtMoment.diff(task.expiredAtMoment, 'days');
            let hours = task.doneAtMoment.diff(task.expiredAtMoment, 'hours');
            if (days > 0) {
                unit = ` +${days}дн`
            } else if (hours > 0) {
                unit = ` +${hours}ч`
            }
            return {
                color: '#EB3B5A',
                text: task.doneAtMoment.format('D MMMM, HH:mm') + ' ' + unit
            }
        }
        return {
            color: '#20BF6B',
            text: task.doneAtMoment.format('D MMMM, HH:mm')
        }
    }, [task.expiredAtMoment, task.doneAtMoment]);


    return (
        <div className={'c-tt-task-archive'}>
            <div className="c-tt-task-archive__check">
                <UiCheckbox
                    value={task.isSelected}
                    onChange={({ value }) => {
                        task.update({
                            isSelected: value
                        })
                    }}
                />
            </div>
            <div className="c-tt-task-archive__body">
                <div className="c-tt-task-archive-name">
                    <div className="c-tt-task-archive__indicator" style={{ backgroundColor: task.priority.color }}/>
                    <div className="c-tt-task-archive-name__inner" onClick={handleOpen}>
                        {task.name}
                    </div>
                </div>
                <div className="c-tt-task-archive__created">
                    {task.createdAtMoment.format('D MMMM, HH:mm')}
                </div>
                <div className="c-tt-task-archive__done" style={{ color: expiration.color }}>
                    {expiration.text}
                </div>
                {task.ownerUser ? (
                    <div className="c-tt-task-archive__user" title={task.ownerUser.name}>
                        <UiAvatar
                            size={24}
                            image={task.ownerUser.imageAvatar}
                            label={task.ownerUser.previewName}
                        />
                        <span>{task.ownerUser.previewName}</span>
                    </div>
                ) : (
                    <div/>
                )}
                {task.responsibleUser ? (
                    <div className="c-tt-task-archive__user" title={task.responsibleUser.name}>
                        <UiAvatar
                            size={24}
                            image={task.responsibleUser.imageAvatar}
                            label={task.responsibleUser.previewName}
                        />
                        <span>{task.responsibleUser.previewName}</span>
                    </div>
                ) : (
                    <div/>
                )}
                <div>
                    <CContextMenu
                        size={20}
                        items={[[
                            {
                                name: 'Вернуть на доску',
                                action: () => {
                                    handleUpdateStatus(TaskTrackerTaskStatusEnum.InWork.id);
                                    Notifier.alert(
                                        `Возвращение задачи на доску`,
                                        `Задача «${task.name}» возвращена на доску «${task.board.name}»`
                                    );
                                }
                            }, {
                                name: 'Удалить',
                                color: '#EB3B5A',
                                icon: (<UiIcon icon={ICONS.TRASH} size={18} color={'#EB3B5A'}/>),
                                action: () => handleDelete()
                            }
                        ]]}
                    />
                </div>
            </div>
        </div>
    )
});
