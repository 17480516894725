import { observer } from 'mobx-react';
import { tabs } from "pages/profile/utilities/tabs";
import React, { useEffect } from 'react';

import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useNavigate } from "shared/hooks";
import { LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiButton, UiIcon, UiTabs } from "shared/uikit";

import { PProfileSettings } from "./components/PProfileSettings";

import './styles.scss';

export const ProfilePage = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        ApplicationModule.setTitle('Личный кабинет');
    });

    return (
        <>
            <div className="p-profile-header">
                <div className="p-profile-header__upper">
                    <div className="p-profile-header__user">{ApplicationModule.user.previewName}</div>
                    <div className="p-profile-header__logout">
                        <UiButton
                            label='Выйти'
                            isLink
                            onClick={() => {
                                ApplicationModule.setAccessToken(null);
                                navigate(ROUTES.LOGIN());
                            }}
                        />
                    </div>
                </div>
                {tabs().length > 0 && (
                    <div className="p-profile-header__tabs">
                        <UiTabs
                            onChange={(data) => {
                                navigate(data.value);
                            }}
                            value={ROUTES.PROFILE()}
                            items={tabs()}
                        />
                    </div>
                )}
            </div>
            <LayoutBody title='Личный кабинет'>
                <LayoutContent>
                    <PProfileSettings/>
                </LayoutContent>
            </LayoutBody>
        </>
    );
});
