import React from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS } from "shared/constants";

import { UiIcon } from '../UiIcon';

import './styles.scss';
import classnames from "classnames";

type PropsType = {
    value?: string,
    withMobile?: boolean,
    onChange?: (data: { value: string }) => void
}

export const UiViewType = observer(({ value = 'card', onChange, withMobile }: PropsType) => {
    const classNames = classnames('ui-view-type', {
        'ui-view-type--mobile': withMobile
    });
    return (
        <div className={classNames}>
            <div className="ui-view-type__inner">
                <div className="ui-view-type__item">
                    <UiIcon
                        icon={ICONS.VIEW_TYPE_CARD}
                        size={20}
                        color={value === 'card' ? COLORS.BROWN_1 : COLORS.GRAY_3}
                        onClick={() => onChange && onChange({value: 'card'})}
                    />
                </div>
                <div className="ui-view-type__item">
                    <UiIcon
                        icon={ICONS.VIEW_TYPE_LIST}
                        size={20}
                        color={value === 'list' ? COLORS.BROWN_1 : COLORS.GRAY_3}
                        onClick={() => onChange && onChange({value: 'list'})}
                    />
                </div>
            </div>
        </div>
    )
});

