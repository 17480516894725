import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import lodash from 'lodash';

import { ObjectType, QueryResponseType, QueryConfigType } from 'shared/types';
import { ApplicationModule } from 'shared/modules';

import { transformObjectKeys } from './transformObjectKeys';
import { getBackendUrl } from './getBackendUrl';

const networkError = <T>(): QueryResponseType<T> => {
    return {
        status: 'error',
        errorMessage: 'Ошибка интернет соединения с сервером',
        errorCode: 900,
        isSuccess: false,
        data: null,
    };
};

const getUrl = (url = '/', prefix = 'v1') => {
    return getBackendUrl(`/api/${prefix}${url}`);
};

const getHeaders = (config: QueryConfigType = {}) => {
    const headers: ObjectType = {
        'Content-Type': config.formData ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
        ...config.headers,
    };

    if (ApplicationModule.isAuthorized) {
        headers['Authorization'] = `Bearer ${ApplicationModule.accessToken}`;
    }

    return headers;
};

const makeQuery = async <QueryResponseDataType>(
    url: string,
    config?: QueryConfigType
): Promise<QueryResponseType<QueryResponseDataType>> => {
    let axiosRequestConfig: AxiosRequestConfig = {
        method: 'get',
        url: getUrl(url, config?.prefix || 'v1'),
        headers: getHeaders(config ? config : {}),
    };

    if (config && config.method) {
        axiosRequestConfig.method = config.method;
    }

    if (config && config.axiosConfig) {
        axiosRequestConfig = { ...axiosRequestConfig, ...config.axiosConfig };
    }

    if (config && config.params) {
        axiosRequestConfig.params = transformObjectKeys(config.params, lodash.snakeCase);
    }

    if (config && config.json) {
        axiosRequestConfig.method = 'post';
        axiosRequestConfig.data = transformObjectKeys(config.json, lodash.snakeCase);
    }

    if (config && config.formData) {
        axiosRequestConfig.method = 'post';
        axiosRequestConfig.data = config.formData;
    }

    if (config && config.onUploadProgress !== null) {
        axiosRequestConfig.onUploadProgress = function (progressEvent: ProgressEvent) {
            config.onUploadProgress && config.onUploadProgress(
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
            );
        };
    }

    try {
        const response = await axios(axiosRequestConfig);
        return {
            isSuccess: true,
            ...transformObjectKeys(response.data, lodash.camelCase),
        } as QueryResponseType<QueryResponseDataType>;
    } catch (error: AxiosError | any) {
        if (axios.isAxiosError(error) && !!error.response && !!error.response.data) {
            if (error.response.status === 401 && config?.forceLogout !== false) {
                ApplicationModule.setAccessToken(null);
            }
            return {
                isSuccess: false,
                ...transformObjectKeys(error.response.data, lodash.camelCase),
            } as QueryResponseType<QueryResponseDataType>;
        }
        return networkError<QueryResponseDataType>();
    }
};

export default makeQuery;
