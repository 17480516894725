import { makeQuery } from 'shared/utilities';
import { IFaqCategoryModel } from 'shared/models';

type RequestType = {};

type ResponseType = {
    items: IFaqCategoryModel[];
};

export const faqCategoriesQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/faq-categories', { params });
}
