import React, { useCallback, useMemo, useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { set, toJS } from "mobx";
import { Moment } from "moment";
import classnames from "classnames";

import { CalendarItemModel } from "shared/models";
import { useOnClickOutside } from "shared/hooks";
import { UiButton, UiCalendarItem, UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

import { Service } from "../../../../Service";

import './styles.scss';

type DayType = {
    id: string,
    moment: Moment,
    isOuter: boolean,
    isToday: boolean,
    calendarItems: CalendarItemModel[]
}
type PropsType = {
    item: DayType,
}

export const CalendarYearDay = observer(({ item }: PropsType) => {
    const ref = useRef(null);
    const store = useLocalObservable(() => ({
        tooltip: {
            isVisible: false,
            style: {
                left: '',
                top: '',
                bottom: '',
                right: ''
            },
        },
        set: set
    }));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const bound = event.currentTarget.getBoundingClientRect();

        const isLeft = windowWidth / 1.6 - bound.left > 0;
        const isTop = windowHeight / 1.6 - bound.top > 0;
        store.set(store.tooltip.style, 'top', isTop ? '100%' : 'auto');
        store.set(store.tooltip.style, 'bottom', !isTop ? '100%' : 'auto');
        store.set(store.tooltip.style, 'left', isLeft ? '0' : 'auto');
        store.set(store.tooltip.style, 'right', !isLeft ? '0' : 'auto');
        store.set(store.tooltip, 'isVisible', true);
    };

    const handlePreview = (calendarItem: CalendarItemModel) => {
        Service.getCalendarItem(calendarItem);
        store.set(store.tooltip, 'isVisible', false);
    };

    const handleCreate = useCallback(() => {
        Service.showEditModal(item.moment.format());
        store.set(store.tooltip, 'isVisible', false);
    }, [store, item]);

    const handleClose = useCallback(() => {
        store.set(store.tooltip, 'isVisible', false);
    }, [store]);

    useOnClickOutside(ref, () => {
        store.set(store.tooltip, 'isVisible', false);
    });

    const className = useMemo(() => classnames('calendar-year-day', {
        'calendar-year-day--outer': item.isOuter,
        'calendar-year-day--items': item.calendarItems.length > 0,
    }), [item.isOuter, item.calendarItems.length]);

    return (
        <div key={item.id} className={className} ref={ref}>
            <div className="calendar-year-day__inner" onClick={handleClick}>
                {item.moment.date()}
            </div>
            {store.tooltip.isVisible && (
                <div className='calendar-year-day-tooltip' style={toJS(store.tooltip.style)}>
                    <div className="calendar-year-day-tooltip__actions">
                        <div className="calendar-year-day-tooltip__title">
                            {item.moment.format('DD MMMM, ddd')}
                        </div>
                        <div className="calendar-year-day-tooltip__close" onClick={handleClose}>
                            <UiIcon color={COLORS.BLACK} icon={ICONS.CLOSE} size={19}/>
                        </div>
                        <div className="calendar-year-day-tooltip__items">
                            {item.calendarItems.map(calendarItem => (
                                <UiCalendarItem
                                    key={calendarItem.id}
                                    item={calendarItem}
                                    onClick={() => handlePreview(calendarItem)}
                                />
                            ))}
                        </div>
                        <div className="calendar-year-day-tooltip__actions">
                            <UiButton
                                isLink
                                onClick={handleCreate}
                                label={'Добавить событие'}
                                iconAfter={<UiIcon color={COLORS.BROWN_1} icon={ICONS.PLUS} size={12}/>}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
});
