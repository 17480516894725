import classnames from "classnames";
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import { useStore, useResizeObserver } from "shared/hooks";

import './styles.scss';

type PropsType = {
    style?: React.CSSProperties;
    className?: string,
    maxHeight?: number | string;
    children?: React.ReactNode,
    isDisabled?: boolean,
    updateKey?: string | number;
    scrollbarGap?: [number, number];
    customRef?: (ref: React.RefObject<HTMLDivElement>) => void
}

export const UiScrollbar = observer((
    {
        maxHeight,
        className,
        children,
        style,
        isDisabled = false,
        updateKey = 1,
        scrollbarGap = [5, 5],
        customRef
    }: PropsType,
) => {
    const store = useStore(() => ({
        timestamp: Date.now(),
        gap: [0, 0]
    }));
    const refOuter = useRef<HTMLDivElement>(null);
    const { ref: refInner, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        setTimeout(() => {
            if (!store.timestamp || updateKey === undefined) {
                store.set("gap", [0, 0]);
            } else if (!refOuter.current || height < 0) { // using height is a hack
                store.set("gap", [0, 0]);
            } else if (refOuter.current.scrollHeight > refOuter.current.clientHeight) {
                store.set("gap", scrollbarGap);
            } else {
                store.set("gap", [0, 0]);
            }
        }, 175)
    }, [store, scrollbarGap, height, store.timestamp, updateKey]);

    useEffect(() => {
        if(!customRef || !refOuter || !refOuter.current){
            return;
        }
        customRef(refOuter);
    }, [customRef, refOuter]);

    return (
        <div
            className={classnames('ui-scrollbar', className)}
            style={{ paddingRight: store.gap[1] }}
            onMouseEnter={() => store.set("timestamp", Date.now())}
        >
            <div className="ui-scrollbar__outer" ref={refOuter} style={isDisabled ? {...style} : { maxHeight, ...style }}>
                <div ref={refInner} className="ui-scrollbar__inner" style={{ paddingRight: store.gap[0] }}>
                    {children}
                </div>
            </div>
        </div>
    )
});
