import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { ROUTES } from 'shared/constants';
import { useNavigate, useRouteParams, useStore, useUserWidgets } from "shared/hooks";

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { AnnouncementModel } from 'shared/models';
import { ApplicationModule } from 'shared/modules';
import { announcementsGetQuery, chatRoomsCreateQuery } from "shared/queries";
import { UiAvatar, UiBackButton, UiButton, UiDataBoundary, UiDate, UiGrid, UiHtml, UiLightbox, UiLikeAction, UiNavLink, UiRepostAction, UiSlider, UiTag } from 'shared/uikit';

import './styles.scss';

export const AnnouncementPage = observer(() => {
    const navigate = useNavigate();
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        announcement: new AnnouncementModel(),
        isSuccess: true,
        isLoading: true,
    }));

    const lightbox = useStore(() => ({
        isOpened: false,
        index: 0
    }));

    const fetchItem = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await announcementsGetQuery(routeParams.id);
        if (isSuccess && data) {
            store.set('announcement', new AnnouncementModel(data.item));
            ApplicationModule.setTitle(data.item.name);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store, routeParams.id]);

    useEffect(() => {
        ApplicationModule.setTitle('...');
    }, [])

    useEffect(() => {
        fetchItem();
    }, [fetchItem]);

    const handleChatRoomCreate = async () => {
        if (!store.announcement.user) {
            return;
        }
        const { isSuccess, data } = await chatRoomsCreateQuery({
            userId: store.announcement.user.id,
            typeId: 'direct'
        });
        if (isSuccess && data) {
            navigate(ROUTES.CHAT(data.item.id));
        }
    };

    return (
        <LayoutBody>
            <LayoutContent>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid style={{ display: "block", maxWidth: 800 }}>
                        <div className="detail-page__back">
                            <UiBackButton label='Назад ко всем объявлениям' to={ROUTES.ANNOUNCEMENTS()}/>
                        </div>
                        <div className="detail-page__header">
                            <UiDate value={store.announcement.createdAt}/>
                            <UiTag
                                value={store.announcement.announcementCategory}
                                onClick={() => {
                                    navigate(ROUTES.ANNOUNCEMENTS(), {
                                        announcementCategoryId: store.announcement.announcementCategoryId
                                    })
                                }}
                            />
                            <UiTag
                                value={store.announcement.tag}
                                onClick={() => {
                                    navigate(ROUTES.ANNOUNCEMENTS(), {
                                        typeId: store.announcement.typeId
                                    })
                                }}
                            />
                        </div>
                        <div className='p-announcement typography'>
                            <h1>{store.announcement.name}</h1>
                            {store.announcement.subtitle && (
                                <div className="detail-page__subtitle">{store.announcement.subtitle}</div>
                            )}
                            <UiHtml
                                className={'p-announcement__detail-text'}
                                value={store.announcement.detailText}
                            />
                        </div>
                        <UiSlider
                            withDots
                            items={store.announcement.imagesThumbnails}
                            slide={(item: string, index) => (
                                <div className='p-announcement-slide' onClick={() => {
                                    lightbox.set('index', index);
                                    lightbox.set('isOpened', true);
                                }}>
                                    <div className="p-announcement-slide__image" style={{ backgroundImage: `url(${item})` }}/>
                                </div>
                            )}
                        />
                        <UiLightbox
                            items={store.announcement.images}
                            index={lightbox.index}
                            isOpened={lightbox.isOpened}
                            onClose={() => lightbox.set('isOpened', false)}
                        />
                        {store.announcement.user && (
                            <UiNavLink to={ROUTES.USER(store.announcement.user.slug)} className="p-announcement-user">
                                <UiAvatar
                                    size={48}
                                    image={store.announcement.user.imageAvatar}
                                    label={store.announcement.user.previewName}
                                />
                                <div className="p-announcement-user__inner">
                                    <div className="p-announcement-user__name">
                                        {store.announcement.user.previewName}
                                    </div>
                                    <div className="p-announcement-user__position">
                                        {store.announcement.user.userPositionName}
                                    </div>
                                </div>
                            </UiNavLink>
                        )}
                        {store.announcement.user && store.announcement.user?.id !== ApplicationModule.user.id && (
                            <div className="p-announcement__reply">
                                <UiButton label={'Ответить на объявление'} onClick={handleChatRoomCreate}/>
                            </div>
                        )}
                        <div className="detail-page__actions">
                            <UiLikeAction
                                entityType='Announcement'
                                entityId={store.announcement.id}
                                value={store.announcement.liked}
                                count={store.announcement.likesCount}
                            />
                            <UiRepostAction
                                entityType='Announcement'
                                entityId={store.announcement.id}
                                count={store.announcement.chatMessageArticlesCount}
                            />
                        </div>
                    </UiGrid>
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
})
