import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IRequestTypeModel {
    id?: number;
    name?: string;
    hint?: string;
    topics?: { name: string }[];
}

export class RequestTypeModel extends Model implements IRequestTypeModel {
    id = 0;
    name = '';
    hint = '';
    topics: { name: string }[] = [];

    constructor(payload: IRequestTypeModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            hint: observable,
            topics: observable,
            update: action,
        });

        this.update(payload);
    }
}
