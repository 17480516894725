import { makeQuery } from 'shared/utilities';
import { IDocumentModel } from 'shared/models';

type RequestType = {
    documentCategoryId?: number;
    page?: number,
    perPage?: number,
    id?: number[],
    query?: string,
};

type ResponseType = {
    items: IDocumentModel[];
    page: number;
    pages: number
};

export const documentsQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/documents', { params });
}
