import { makeAutoObservable } from "mobx";

import { ApplicationSettingsModel, IUserModel, UserModel } from "shared/models";
import { Storage } from "shared/utilities";
import { applicationSettingsQuery, usersProfileQuery } from "shared/queries";

import { BroadcastModule } from "./BroadcastModule";

class ApplicationModuleClass {
    accessToken: string | null = null;
    user: UserModel = new UserModel();
    applicationSettings: ApplicationSettingsModel = new ApplicationSettingsModel();
    title = 'Главная';

    constructor() {
        makeAutoObservable(this);
    }

    get isAuthorized() {
        return !!this.accessToken;
    }

    getApplicationSettings = async () => {
        const { isSuccess, data } = await applicationSettingsQuery();
        if (isSuccess && data) {
            this.applicationSettings.update(data.item);
        }
    }

    initialize = async () => {
        await this.getApplicationSettings();

        const accessToken = await Storage.get('accessToken', null);

        if (!accessToken) {
            return false;
        }
        const { isSuccess, data } = await usersProfileQuery(accessToken);
        if (!isSuccess || !data) {
            return false;
        }
        this.setUser(data.item);
        this.setAccessToken(accessToken);
        return true;
    }

    logout = () => {
        this.setAccessToken(null);
    }

    setAccessToken = (accessToken: string | null) => {
        this.accessToken = accessToken;
        Storage.set('accessToken', accessToken);

        if (accessToken) {
            BroadcastModule.connect(this.user.id, accessToken);
        } else {
            BroadcastModule.disconnect();
        }
    }

    setUser = (user: IUserModel) => {
        this.user.update(user);
    }

    setTitle = (title: string | null | undefined = '') => {
        if (!title) {
            this.title = 'Корп. портал';
            document.title = `Корп. портал | Togas`;
            return;
        }
        this.title = title;
        document.title = `${title} | Togas`;
    }
}

export const ApplicationModule = new ApplicationModuleClass();
