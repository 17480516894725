import { makeQuery } from 'shared/utilities';
import { ICafeCategoryModel } from 'shared/models';

type ResponseType = {
    pages: number;
    items: ICafeCategoryModel[];
};

export const cafeMealsQuery = async function () {
    return await makeQuery<ResponseType>('/cafe-meals');
}
