import { makeQuery } from 'shared/utilities';
import { IColumnModel } from "shared/models/TaskTracker";

type RequestType = {
    id: number | null
}

type ResponseType = {
    item: IColumnModel,
};

export const columnsGetQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/columns/get', {
        prefix: 'task-tracker',
        params: request
    });
}
