import { action, computed, makeObservable, observable } from 'mobx';

import { ModelArrayCast, ModelCast } from "shared/casts";

import { Model } from './Model';
import { UserModel } from "./UserModel";
import { ChatMessageModel } from "./ChatMessageModel";
import { ChatRoomUserModel } from "shared/models/ChatRoomUserModel";
import { ApplicationModule } from "shared/modules";
import { ChatRoomTypeEnum } from "shared/enums";

export interface IChatRoomModel {
    id?: number;
    typeId?: string;
    userId?: number | null;
    name?: string;
    image?: string | null;
    chatMessageLast?: ChatMessageModel | null;
    chatMessagesUnreadCount?: number;
    chatRoomUsers?: IChatRoomModel[];
}

export class ChatRoomModel extends Model implements IChatRoomModel {
    casts = {
        users: new ModelArrayCast(UserModel),
        chatRoomUsers: new ModelArrayCast(ChatRoomUserModel),
        user: new ModelCast(UserModel),
        chatMessageLast: new ModelCast(ChatMessageModel),
    }

    id = 0;
    userId = null;
    typeId = 'direct';
    name = 'direct';
    image = null;
    chatMessageLast: ChatMessageModel | null = null;
    chatMessagesUnreadCount = 0;
    chatRoomUsers: ChatRoomUserModel[] = [];

    constructor(payload: IChatRoomModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            typeId: observable,
            userId: observable,
            name: observable,
            image: observable,
            chatMessageLast: observable,
            chatMessagesUnreadCount: observable,
            chatRoomUsers: observable,
            chatRoomUser: computed,
            users: computed,
            user: computed,
            sort: computed,
            type: computed,
            previewName: computed,
            previewImage: computed,
            usersCounterText: computed,
            update: action,
        });

        this.update(payload);
    }

    get type() {
        return ChatRoomTypeEnum.fromValue(this.typeId);
    }

    get previewName() {
        if (this.type.is(ChatRoomTypeEnum.Direct)) {
            return this.user?.previewName || '';
        }
        return this.name;
    }

    get previewImage() {
        if (this.type.is(ChatRoomTypeEnum.Direct)) {
            return this.user?.image;
        }
        return this.image;
    }

    get usersCounterText() {
        const usersCount = this.users.length;
        if (usersCount > 4 && usersCount < 21) {
            return `${usersCount} участников`;
        } else {
            const usersSubcounter = this.users.length % 10;
            if (usersSubcounter === 0) {
                return `${usersCount} участников`;
            } else if (usersCount > 1 && usersCount < 5) {
                return `${usersCount} участника`;
            } else if (usersCount > 4 && usersCount < 10) {
                return `${usersCount} участников`;
            }
        }
        return `${usersCount} участник`;
    }

    get sort() {
        let weight = this.chatMessageLast?.id || 0;
        if (this.chatRoomUser?.isFixed) {
            weight += 10000000;
        }
        return weight;
    }

    get users() {
        return this.chatRoomUsers.slice().map(chatRoomUsers => chatRoomUsers.user);
    }

    get user() {
        return this.users.find(user => +user.id !== +ApplicationModule.user.id) || null;
    }

    get chatRoomUser() {
        return this.chatRoomUsers.find(chatRoomUsers => +chatRoomUsers.userId === +ApplicationModule.user.id) || null;
    }
}
