import React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import { UiLikeAction } from 'shared/uikit';
import { COLORS } from 'shared/constants';
import { OnChangeHandlerType } from 'shared/types';
import { CafeMealModel } from 'shared/models';

import './styles.scss';

type PropsType = {
    cafeMeal: CafeMealModel;
}
export const CafeMeal = observer(({cafeMeal}: PropsType) => {
    const handleLike: OnChangeHandlerType<number> = event => {
        runInAction(() => {
            cafeMeal.liked = event.value;
        });
    };
    return (
        <div className='cafe-meal'>
            <div className='cafe-meal__header'>
                <div className='cafe-meal__name'>{cafeMeal.name}</div>
                <div className="cafe-meal__dots"/>
                <div className='cafe-meal__price'>{cafeMeal.price} ₽</div>
            </div>
            <div className='cafe-meal__footer'>
                {(cafeMeal.description || cafeMeal.weight) && (
                    <div className='cafe-meal__description'>{cafeMeal.description || ''} {cafeMeal.weight} г.</div>
                )}
                <div className="cafe-meal__like">
                    <UiLikeAction
                        value={cafeMeal.liked}
                        color={COLORS.GRAY_4}
                        entityType={'CafeMeal'}
                        entityId={cafeMeal.id}
                        count={cafeMeal.likesCount}
                        onChange={handleLike}
                    />
                </div>
            </div>
        </div>
    );
});
