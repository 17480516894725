import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment/moment";

import { Model } from './Model';

export interface ICafeMealModel {
    id?: number;
    cafeCategoryId?: number;
    name?: string;
    description?: string;
    day?: number;
    weight?: number;
    price?: number;
    sort?: number;
    likesCount?: number;
    liked?: number;
    updatedAt?: string;
}

export class CafeMealModel extends Model implements ICafeMealModel {
    id = 0;
    cafeCategoryId = 0;
    name = '';
    description = '';
    updatedAt = '';
    day = 0;
    weight = 0;
    price = 0;
    sort = 0;
    likesCount = 0;
    liked = 0;

    constructor(payload: ICafeMealModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            cafeCategoryId: observable,
            name: observable,
            description: observable,
            day: observable,
            weight: observable,
            price: observable,
            updatedAt: observable,
            sort: observable,
            liked: observable,
            likesCount: observable,
            updatedAtMoment: computed,
            update: action,
        });

        this.update(payload);
    }

    get updatedAtMoment() {
        if (!this.updatedAt) {
            return moment();
        }
        return moment(this.updatedAt);
    }
}
