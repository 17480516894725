import React from 'react';
import { observer } from 'mobx-react';
import { computed, makeObservable } from 'mobx';

interface Props {
    value: string | number | null;
    name: any;
    className?: string;
    style?: React.CSSProperties;
    isDisabled: boolean;
    children?: React.ReactNode
}

class UiTab extends React.Component<Props> {
    static defaultProps: Props = {
        value: 'tab',
        name: 'tab',
        isDisabled: false,
    };

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            isVisible: computed,
            style: computed,
        });
    }

    get isVisible() {
        const { name, value, isDisabled } = this.props;

        if (isDisabled) {
            return true;
        }

        return name === value;
    }

    get style() {
        const { style } = this.props;

        if (!this.isVisible) {
            return {
                display: 'none',
            };
        }

        return style;
    }

    render() {
        const { children, style, className } = this.props;

        if (className || style || !this.isVisible) {
            return (
                <div className={`ui-tab ${className}`} style={this.style}>
                    {children}
                </div>
            );
        }

        return children;
    }
}

export default observer(UiTab);
