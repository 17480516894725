import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";

import { ApplicationModule } from 'shared/modules';
import { UiAvatar, UiButton, UiModal } from 'shared/uikit';
import { useStore } from "shared/hooks";
import { usersImageUploadQuery } from "shared/queries";

import { getCroppedFile } from "./utilities/getCroppedFile";

import './index.scss';

const userImageDefault: string = require('./assets/user.svg');

export const PProfileImage = observer(() => {
    const store = useStore(() => ({
        isLoading: false,
        isCropLoading: false,
        image: null as string | null,
        crop: { x: 0, y: 0 },
        zoom: 1,
        area: {} as Area,
        isOpened: false,
    }));

    const ref = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        ref?.current?.click()
    }
    const handleSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!event?.target?.files || event.target.files.length < 1) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.addEventListener("load", () => {
            store.set('image', reader.result);
            store.set("isCropLoading", false);
            store.set("isOpened", true);
        });
    };

    const handleSubmit = async () => {
        if (!store.image || store.isCropLoading) {
            return;
        }
        store.set('isCropLoading', true);
        const file = await getCroppedFile(store.image, store.area);
        store.set('isCropLoading', false);
        if (!file) {
            return;
        }
        store.set("isOpened", false);
        store.set("isLoading", true);
        const { isSuccess, data } = await usersImageUploadQuery(file);
        if (isSuccess && data) {
            ApplicationModule.user.update(data.item);
        }
        store.set("isLoading", false);
    }

    return (
        <div className="p-user-section p-user-section--aside">
            <div className='p-user__avatar'>
                <UiAvatar size={268} image={ApplicationModule.user.imageThumbnail || userImageDefault}/>
            </div>
            <UiButton label='Изменить фото' isOutline onClick={handleClick} isLoading={store.isLoading}/>
            <input
                ref={ref}
                style={{ display: "none" }}
                type="file"
                onChange={handleSelectImage}
                accept="image/*"
                value=''
            />
            <UiModal isOpened={store.isOpened} title='Изменить фото' onClose={() => store.set("isOpened", false)}>
                <div className="p-profile-image__crop">
                    {store.isOpened && (
                        <Cropper
                            image={(store.image || undefined) as string | undefined}
                            zoomWithScroll
                            cropShape={"round"}
                            crop={store.crop}
                            zoom={store.zoom}
                            aspect={1}
                            onCropChange={(crop) => {
                                store.set('crop', crop)
                            }}
                            onZoomChange={(zoom) => {
                                store.set("zoom", zoom);
                            }}
                            onCropComplete={(_, area) => {
                                store.set("area", area);
                            }}
                        />
                    )}
                </div>
                <div className="ui-modal__actions">
                    <UiButton
                        label='Сохранить'
                        isSmall
                        onClick={handleSubmit}
                        style={{ width: "auto" }}
                        isLoading={store.isCropLoading}
                    />
                    <UiButton
                        label='Отмена'
                        isSmall
                        isOutline
                        style={{ width: "auto" }}
                        onClick={() => store.set("isOpened", false)}
                    />
                </div>
            </UiModal>
        </div>
    );
});
