import { VacancyModel } from 'shared/models';
import { StoreWithFetchItem } from 'shared/mixins/store';
import { makeObservable, observable } from 'mobx';

class Store extends StoreWithFetchItem<VacancyModel> {
    tabs = [
        {
            id: 'content',
            name: 'Вакансия',
        },
        {
            id: 'aside',
            name: 'Другие вакансии',
        },
    ];

    tabId: string = 'content';

    item = new VacancyModel();

    modalIsOpened = false;

    constructor() {
        super();
        makeObservable(this, {
            tabs: observable,
            tabId: observable,
            modalIsOpened: observable,
        });
    }
}

export default new Store();
