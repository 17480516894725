import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import FsLightbox from 'fslightbox-react';

type PropsType = {
    items?: string[];
    isOpened?: boolean;
    index?: number,
    onClose?: () => void;
}

export const UiLightbox = observer((
    {
        items = [],
        isOpened = false,
        onClose,
        index = 0,
    }: PropsType
) => {
    const types = useMemo(() => items?.map(item => {
        if (
            item.toLowerCase().includes('jpeg') ||
            item.toLowerCase().includes('jpg') ||
            item.toLowerCase().includes('jfif') ||
            item.toLowerCase().includes('pjp') ||
            item.toLowerCase().includes('svg') ||
            item.toLowerCase().includes('webp') ||
            item.toLowerCase().includes('pjpeg') ||
            item.toLowerCase().includes('png') ||
            item.toLowerCase().includes('avif') ||
            item.toLowerCase().includes('gif') ||
            item.toLowerCase().includes('bmp')
        ) {
            return 'image';
        }

        if (
            item.toLowerCase().includes('mp4') ||
            item.toLowerCase().includes('webm') ||
            item.toLowerCase().includes('wmv') ||
            item.toLowerCase().includes('avi') ||
            item.toLowerCase().includes('mkv') ||
            item.toLowerCase().includes('mov')
        ) {
            return 'video';
        }

        return null;
    }), [items]);

    if (!isOpened || !items) {
        return null;
    }

    return (
        <FsLightbox
            onInit={() => {
                setTimeout(() => {
                    let video: any = document.getElementsByClassName('fslightbox-video');
                    if(!video || !video[0]){
                        return;
                    }
                    video = video[0];
                    video?.play();
                    if (video?.style?.width) {
                        const width = +(video.style.width.slice(0, -2));
                        const newWidth = width + 0.1;
                        video.style.width = `${newWidth}px`
                    }
                }, 300);
            }}
            toggler
            openOnMount
            onClose={onClose}
            types={types}
            sources={items}
            sourceIndex={index}
        />
    );
})
