import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { LayoutModule } from "shared/modules";
import { useMedia, useStore } from "shared/hooks";
import { UiIcon, UiTabs } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    children?: React.ReactNode,
    tabs?: boolean,
    header?: boolean,
    widgets?: {
        id: string,
        name: string,
        Widget: any
    }[]
}

export const LayoutAside = observer(({ widgets, children, tabs = true, header = true }: PropsType) => {
    const store = useStore(() => ({
        widgetId: widgets?.length && widgets.length > 0 ? widgets[0].id : null,
    }));
    const { is320, is768, is1024 } = useMedia();
    const isMobileMode = is1024 || is768 || is320;

    useEffect(() => {
        LayoutModule.aside.setOpened(!isMobileMode);
    }, [isMobileMode, store])

    const className = classnames('layout-aside', {
        'layout-aside--opened': LayoutModule.aside.isOpened
    });

    if (!isMobileMode) {
        return (
            <div className={className}>
                {widgets?.map(({ id, Widget }) => (
                    <Widget key={id}/>
                ))}
                {children}
            </div>
        );
    }

    const Widget = widgets?.find(widget => widget.id === store.widgetId)?.Widget;

    return (
        <div className={className}>
            {header && (
                <div className="layout-aside__header">
                    <div className="layout-aside__close" onClick={() => LayoutModule.aside.setOpened(false)}>
                        <UiIcon icon={ICONS.ARROW_LEFT_THIN} size={24} color={COLORS.BLACK}/>
                    </div>
                    <div className="layout-aside__title">Виджеты</div>
                </div>
            )}
            {tabs && (
                <div className="layout-aside__tabs">
                    <UiTabs
                        items={widgets}
                        value={store.widgetId}
                        onChange={({ value }) => store.set("widgetId", value)}
                    />
                </div>
            )}
            <div className="layout-aside__inner">
                {Widget && <Widget/>}
                {children}
            </div>
        </div>
    );
})
