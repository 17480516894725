import moment from "moment";
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { ModelArrayCast, ModelCast } from "shared/casts";

import { Model } from './Model';
import { UserModel } from "./UserModel";
import { ReservationModel } from "./ReservationModel";
import { CalendarItemEventTypeEnum } from "shared/enums";

export interface ICalendarItemModel {
    id?: string;
    changeKey?: string;
    subject?: string;
    body?: string;
    typeId?: string;
    eventTypeId?: string | null;
    timezone?: string | null;
    start?: string | null;
    end?: string | null;
    isAllDayEvent?: boolean | number;
    reservationId?: number | null;
    reservation?: ReservationModel | null;
    organizer?: UserModel;
    attendees?: UserModel[];
}

export class CalendarItemModel extends Model implements ICalendarItemModel {
    casts = {
        reservation: new ModelCast(ReservationModel),
        organizer: new ModelCast(UserModel),
        attendees: new ModelArrayCast(UserModel)
    };

    id = '';
    changeKey = '';
    subject = '';
    body = '';
    typeId = 'outlook';
    timezone = '';
    start = '';
    end = '';
    eventTypeId = CalendarItemEventTypeEnum.Meeting;
    isAllDayEvent = false;
    reminderMinutesBeforeStart = 0;
    reservationId = null;
    reservation = null as ReservationModel | null;
    organizer = new UserModel();
    attendees = [] as UserModel[];
    permissions = {
        canEdit: false,
        canDelete: false,
    }

    constructor(payload: ICalendarItemModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            typeId: observable,
            changeKey: observable,
            subject: observable,
            timezone: observable,
            body: observable,
            attendees: observable,
            start: observable,
            reminderMinutesBeforeStart: observable,
            end: observable,
            organizer: observable,
            reservation: observable,
            permissions: observable,
            isAllDayEvent: observable,
            eventTypeId: observable,
            utc: computed,
            colors: computed,
            startMoment: computed,
            endMoment: computed,
            timeLength: computed,
            update: action
        });

        this.update(payload);
    }

    get colors() {
        if (this.eventTypeId === CalendarItemEventTypeEnum.Reminder) {
            return {
                color: '#0090FF',
                background: '#CCE9FF',
                borderColor: '#0090FF',
            }
        }

        if (this.eventTypeId === CalendarItemEventTypeEnum.Task) {
            return {
                color: '#DB5AEE',
                background: '#F8DEFC',
                borderColor: '#DB5AEE',
            }
        }

        if (this.eventTypeId === CalendarItemEventTypeEnum.Education) {
            return {
                color: '#CF7F07',
                background: '#FEF1DE',
                borderColor: '#F9B959',
            }
        }

        return {
            color: '#118861',
            background: '#D1F7EA',
            borderColor: '#1AD598',
        }
    }

    get utc() {
        if (!this.timezone) {
            return this.timezone;
        }
        return this.timezone.replace('(UTC', '').split(')')[0]
    }

    get startMoment() {
        if (!this.start) {
            return moment();
        }

        return moment(this.start);
    }

    get endMoment() {
        if (!this.start) {
            return moment();
        }
        return moment(this.end);
    }

    get startDateFormat() {
        return this.startMoment.format('Y-MM-DD');
    }

    get startTimeFormat() {
        return this.startMoment.format('HH:mm');
    }

    get name() {
        let name = this.subject;
        if (!name) {
            name = this.organizer.name || this.organizer.email;
        }
        return name;
    }

    get namePreview() {
        if (this.isAllDayEvent) {
            return this.name;
        }
        return `${this.name}`;
    }

    get time() {
        if (this.isAllDayEvent) {
            return 'Весь день';
        }
        if (this.startTime === this.endTime) {
            return this.startTime;
        }
        return `${this.startTime} - ${this.endTime}`;
    }

    get startTime() {
        return this.startMoment.format('HH:mm');
    }

    get startTimeValue() {
        return this.startMoment.hours() * 60 + this.startMoment.minutes();
    }

    get endTime() {
        return this.endMoment.format('HH:mm');
    }

    get endTimeValue() {
        return this.endMoment.hours() * 60 + this.endMoment.minutes() - 1; //-1 fix, in case of 09-10 & 10-11, shouldn't intersect
    }

    get timeLength(){
        return this.endTimeValue - this.startTimeValue + 1;
    }

    set startTime(value) {
        runInAction(() => {
            const [hours, minutes] = value.split(':');
            this.start = this.startMoment.clone().set('hours', +hours).set('minutes', +minutes).format();
            if (this.startMoment.isAfter(this.endMoment)) {
                this.end = this.startMoment.clone().set('hours', +hours).set('minutes', +minutes).format();
            }
        });
    }

    set endTime(value) {
        runInAction(() => {
            const [hours, minutes] = value.split(':');
            this.end = this.endMoment.clone().set('hours', +hours).set('minutes', +minutes).format();
            if (this.endMoment.isBefore(this.startMoment)) {
                this.start = this.endMoment.clone().set('hours', +hours).set('minutes', +minutes).format();
            }
        });
    }
}
