import classnames from "classnames";
import { observer } from 'mobx-react';
import React from 'react';

import { UserDashboardWidgetDictionaryItemModel } from "shared/models";
import { OnChangeHandlerType } from "shared/types";

import './styles.scss';

type PropsType = {
    items: UserDashboardWidgetDictionaryItemModel[],
    className?: string,
    value: number | string | null,
    name: string,
    onChange: OnChangeHandlerType<number | string | null>
}

export const PPDWidgetGroups = observer(({ items, className, name, value, onChange }: PropsType) => {
    if (items.length === 0) {
        return null;
    }

    return (
        <div className={classnames("ppd-widget-groups", className)}>
            {items.map(group => (
                <div
                    key={group.id}
                    onClick={() => onChange({ name, value: group.id })}
                    className={classnames('ppd-widget-groups__item', {
                        'ppd-widget-groups__item--active': group.id === value
                    })}
                >
                    <span>{group.name}</span>
                </div>
            ))}
        </div>
    );
});
