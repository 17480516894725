import { useEffect } from "react";

let handlers: Record<string, any[]> = {};
export const useEvent = function <T = any>(name: string, handler?: (data: T) => void) {
    useEffect(() => {
        if (!handler) {
            return;
        }
        if (!handlers[name]) {
            handlers[name] = [];
        }
        handlers[name].push(handler);
        return () => {
            handlers[name] = handlers[name].filter(h => h !== handler);
        }
    }, [handler, name]);

    return (value: T) => {
        handlers[name]?.forEach(handle => handle(value));
    }
}

