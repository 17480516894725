import { makeAutoObservable } from 'mobx';

import { VacancyModel } from 'shared/models';
import { fetchItem } from 'shared/mixins/service';
import { vacanciesGetQuery } from 'shared/queries';

import Store from './Store';

class Service {
    constructor() {
        makeAutoObservable(this);
    }

    fetchItem = fetchItem(vacanciesGetQuery, Store, VacancyModel);
}

export default new Service();
