import { makeQuery } from 'shared/utilities';
import { INotificationModel } from "shared/models";

type RequestType = {
    page?: number;
    perPage?: number;
    filter?: string;
    moduleId?: string
};

type ResponseType = {
    pages: number;
    items: INotificationModel[];
};

export const notificationsQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/notifications', { params });
}
