import { makeQuery } from 'shared/utilities';

type RequestType = {
    email?: string,
    phone?: string,
    comment?: string,
    module?: string,
    educationLessonId?: number,
};

type ResponseType = {};

export const educationLessonApplicationsCreateQuery = async function (data: RequestType) {
    return await makeQuery<ResponseType>('/education-lesson-applications/create', { json: data });
}
