import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';
import { IDepartmentBranchModel, DepartmentBranchModel } from "./DepartmentBranchModel";

export interface IDepartmentGroupModel {
    id?: number;
    name?: string;
    departmentBranches?: IDepartmentBranchModel[];
}

export class DepartmentGroupModel extends Model implements IDepartmentGroupModel {
    id = 0;
    name = '';
    departmentBranches = [] as DepartmentBranchModel[];

    constructor(payload: IDepartmentGroupModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            departmentBranches: observable,
            update: action,
        });

        this.update(payload);
    }
}
