import { observer } from 'mobx-react';
import React from 'react';

import './styles.scss';

type PropsType = {
    progress?: number | null;
    color: string
    size?: number
    fontSize?: number
}

const Progress = ({ size, progress, color }: { size: number, progress: number, color: string }) => {
    const strokeWidth = 7;
    const radius = (size - strokeWidth) / 2;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * progress) / 100;

    return (
        <circle
            className={`ppd-widget-item-value-progress__circle ppd-widget-color--${color}`}
            fill='transparent'
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            strokeLinecap='round'
            strokeLinejoin='round'
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            style={{
                transition: '0.25s',
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
            }}
        />
    )
}

export const PPDWidgetItemValueProgress = observer(({ progress, color, size = 120, fontSize }: PropsType) => {
    if (progress === undefined || progress === null) {
        return null;
    }

    const strokeWidth = 7;
    const radius = (size - strokeWidth) / 2;

    return (
        <div className='ppd-widget-item-value-progress' style={{ width: size, height: size }}>
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <circle
                    fill='transparent'
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    stroke={'#17191c33'}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <Progress
                    progress={progress >= 100 ? 100 : progress}
                    size={size}
                    color={progress >= 100 ? 'force-green' : color}
                />
                {progress > 100 && (
                    <Progress progress={progress % 100 === 0 ? 100 : progress % 100} size={size} color={'force-darkgreen'}/>
                )}
            </svg>
            <div className='ppd-widget-item-value-progress__value' style={{ fontSize }}>{progress}%</div>
        </div>
    );
});
