import { makeQuery } from 'shared/utilities';
import { IColumnModel } from "shared/models/TaskTracker";

type RequestType = IColumnModel;

type ResponseType = {
    item: IColumnModel
};

export const columnsSaveQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/columns/save', {
        prefix: 'task-tracker',
        json: request
    });
}
