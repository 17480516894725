import { makeQuery } from 'shared/utilities';
import { ICommentModel } from 'shared/models';

type RequestType = {
    entityType?: string,
    entityId?: number,
};

type ResponseType = {
    items: ICommentModel[];
};

export const commentsQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/comments', { params });
}
