import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { computed, makeObservable } from 'mobx';

import { OnChangeHandlerType } from 'shared/types';

import './styles.scss';

interface Props {
    onChange: OnChangeHandlerType<string>;
    value: string;
    type: string;
    name: string;
    icon: React.ReactNode | null,
    isValid?: boolean | string;
    isError?: boolean;
    placeholder: string;
    isPlaceholderFluid: boolean;
    style: React.CSSProperties;
}

class UiInput extends React.Component<Props> {
    static TYPE = {
        TEXT: 'text',
        EMAIL: 'email',
        PASSWORD: 'password',
    };

    static defaultProps: Props = {
        onChange: () => {},
        value: '',
        name: '',
        type: 'text',
        icon: null,
        isValid: true,
        placeholder: '',
        isPlaceholderFluid: false,
        style: {},
    };

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            className: computed,
        });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange, name } = this.props;

        onChange({
            name,
            value: event.target.value,
        });
    };

    get className() {
        const { value, isPlaceholderFluid, isValid, isError, icon } = this.props;

        return classnames('ui-input', {
            'ui-input--icon': !!icon,
            'ui-input--notempty': !!value,
            'ui-input--error': isValid !== true || isError,
            'ui-input--fluid-placeholder': isPlaceholderFluid,
        });
    }

    render() {
        const { value, name, placeholder, type, style, icon } = this.props;

        return (
            <div className={this.className} style={style}>
                {!!icon && (
                    <div className="ui-input__icon">{icon}</div>
                )}
                <input
                    name={name}
                    type={type}
                    className='ui-input__control'
                    value={value || ''}
                    onChange={this.handleChange}
                    data-cy={`ui-input-${name}`}
                />
                {!!placeholder && (
                    <div className='ui-input__placeholder'>
                        <span>{placeholder}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default observer(UiInput);
