import { createEnum } from './createEnum';

export const TaskTrackerTaskStatusEnum = createEnum({
    All: {
        id: null,
        name: 'Все задачи',
        singular: 'Все задачи',
        color: undefined,
    },
    InWork: {
        id: 'in_work',
        name: 'В работе',
        singular: 'В работе',
        color: '#20BF6B',
    },
    AlmostExpired: {
        id: 'almost_expired',
        name: 'Почти просрочены',
        singular: 'Почти просрочена',
        color: '#F7B731',
    },
    Expired: {
        id: 'expired',
        name: 'Просрочены',
        singular: 'Просрочена',
        color: '#EB3B5A',
    },
    Delayed: {
        id: 'delayed',
        name: 'Отложены',
        singular: 'Отложена',
        color: '#2D98DA',
    },
    Done: {
        id: 'done',
        name: 'Завершены',
        singular: 'Завершена',
        color: '#A5B1C2',
    },
    Archive: {
        id: 'archive',
        name: 'В архиве',
        singular: 'В архиве',
        color: '#A5B1C2',
    },
} as const, {
    id: null,
    name: 'Статус не определён',
    singular: 'Статус не определён',
    color: '#907C4B',
});

