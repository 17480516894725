import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import Picker, { IEmojiData } from "emoji-picker-react";

import { useOnClickOutside, useStore } from "shared/hooks";
import { OnChangeHandlerType } from "shared/types";
import { COLORS, ICONS } from 'shared/constants';

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    value?: string,
    name?: string
    onChange?: OnChangeHandlerType<string>;
}

export const UiEmojis = observer(({ onChange, value, name = 'ui-emoji' }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false,
    }));

    const emojisRef = useRef(null);
    useOnClickOutside(emojisRef, () => {
        store.set('isOpened', false);
    })

    const handleClickEmoji = (_event: React.MouseEvent, { emoji }: IEmojiData) => {
        onChange && onChange({
            name,
            value: value + emoji
        });
    };

    return (
        <div className='ui-emojis' ref={emojisRef} onClick={() => store.set("isOpened", true)}>
            <UiIcon size={24} color={COLORS.GRAY_3} icon={ICONS.CHAT_EMOJI}/>
            {store.isOpened && (
                <div className={'ui-emojis__outer'}>
                    <Picker
                        disableSearchBar
                        onEmojiClick={handleClickEmoji}
                        native={true}
                        groupNames={{
                            smileys_people: 'Люди и смайлики',
                            animals_nature: 'Животные и природа',
                            food_drink: 'Еда и напитки',
                            travel_places: 'Путешествия',
                            activities: 'Активности',
                            objects: 'Вещи',
                            symbols: 'Символика',
                            flags: 'Флаги',
                            recently_used: 'Часто используемые',
                        }}
                    />
                </div>
            )}
        </div>
    )
});
