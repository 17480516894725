export const ROUTES = {
    ROOT: () => `/`,
    ANNOUNCEMENTS: () => `/announcements`,
    ANNOUNCEMENT: (id: number | string = ':id') => `/announcements/${id}`,
    ESSENTIALS: () => `/essentials`,
    ESSENTIAL: (id: number | string = ':id') => `/essentials/${id}`,
    ARTICLE: (id: number | string = ':id') => `/articles/${id}`,
    ARTICLES: () => `/articles`,
    BOOKS: () => `/books`,
    LIFE: () => `/life`,
    GALLERY: (id: number | string = ':id') => `/gallery/${id}`,
    OFFICES: () => `/offices`,
    CAFE: () => `/cafe`,
    CALENDAR: (params = '') => `/calendar${params}`,
    CHATS: () => `/chat`,
    CHAT: (id: number | string = ':id') => `/chat/${id}`,
    COMPANY: () => `/company`,
    REQUESTS: () => `/requests`,
    EDUCATION: () => `/education`,
    EDUCATION_LESSONS: (params = '') => `/education-lessons${params}`,
    DEPARTMENT: (id: number | string = ':departmentId') => `/department/${id}`,
    DEPARTMENT_BRANCH: (id: number | string = ':departmentBranchId') => `/department-branch/${id}`,
    IN_DEVELOPMENT: () => `/in-development`,
    COMPANY_STRUCTURE: () => `/company-structure`,
    DOCUMENT_CATEGORY: (id: number | string = ':id') => `/document-category/${id}`,
    DOCUMENT_CATEGORIES: () => `/document-categories`,
    DOCUMENTS: () => `/documents`,
    FAQ: () => `/faq`,
    HOME: () => `/home`,
    ISPRING_COURSES: () => `/ispring/courses`,
    LIBRARY: () => `/library`,
    LOGIN: () => `/login`,
    NEWS: (id: number | string = ':id') => `/news/${id}`,
    NOTIFICATIONS: () => `/notifications`,
    PROFILE: () => `/profile`,
    PROFILE_REPORTS: () => `/profile/reports`,
    PROFILE_DASHBOARD: () => `/profile/dashboard`,
    RESERVATIONS: () => `/reservations`,
    TASK_TRACKER: (id: number | string = ':id') => `/task-tracker/${id}`,
    TASK_TRACKER_ARCHIVE: () => `/task-tracker-archive`,
    SEARCH: (params = '') => `/search${params}`,
    SURVEY: (id: number | string = ':id') => `/survey/${id}`,
    SURVEYS: () => `/surveys`,
    TEST: () => `/test`,
    TEST_ID: (id: number | string = ':id') => `/test/${id}`,
    VACANCIES: () => `/vacancies`,
    HISTORY: () => `/history`,
    VALUES: () => `/values`,
    VACANCY: (id: number | string = ':id') => `/vacancies/${id}`,
    USER: (id: string | number = ':id') => `/user/${id}`,
};
