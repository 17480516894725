import classnames from "classnames";
import { observer } from 'mobx-react';
import moment from "moment";
import React, { useRef } from 'react';
import { ICONS, ROUTES } from "shared/constants";
import { useEffect, useNavigate, useStore } from "shared/hooks";

import { CommentModel } from "shared/models";
import { ApplicationModule } from "shared/modules";
import { UiAvatar, UiIcon } from "shared/uikit";
import { Notifier } from "shared/utilities";
import { Store } from "../../Store";

import { CCommentSave } from "../CCommentSave";

import './index.scss';

type PropsType = {
    comment: CommentModel,
}

export const CComment = observer(({ comment }: PropsType) => {
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const store = useStore(() => ({
        isCreating: false,
        isEditing: false,
    }));
    const comments = Store.commentsByCommentId[comment.id] ?? [];

    const classNames = classnames('c-comment', {
        'c-comment--sub': comment.commentId
    });

    useEffect(() => {
        if (window.location.hash === `#comment_id=${comment.id}` && ref.current) {
            ref.current.scrollIntoView({ block: "start" });
        }
    }, [comment, ref]);

    const handleDelete = async () => {
        const isConfirmed = await Notifier.confirm('Подтверждение', 'Подтвердите удаление комментария');
        if (!isConfirmed) {
            return;
        }
        Store.deleteComment(comment.id)
    }

    const isCurrentUser = ApplicationModule.user.id === comment.user.id;
    const canDelete = isCurrentUser && comments.length === 0;
    return (
        <div ref={ref} className={classNames}>
            <div className="c-comment__image">
                <UiAvatar
                    onClick={() => {
                        navigate(ROUTES.USER(comment.user.id));
                    }}
                    size={comment.commentId ? 24 : 40}
                    image={comment.user.imageAvatar}
                />
            </div>
            <div className="c-comment__inner">
                <div className="c-comment__header">
                    <div className="c-comment__user" onClick={() => {
                        navigate(ROUTES.USER(comment.user.id));
                    }}>
                        {comment.user.name}
                    </div>
                    <div className="c-comment__date">
                        {moment(comment.createdAt).fromNow()}
                        {(comment.createdAt !== comment.updatedAt) && ` (отредактирован)`}
                    </div>
                    <div className="c-comment__actions">
                        {isCurrentUser && (
                            <div className="c-comment__action" onClick={() => store.set("isEditing", !store.isEditing)}>
                                <UiIcon size={13} icon={ICONS.EDIT} color={'#B3B3B3'}/>
                            </div>
                        )}
                        {canDelete && (
                            <div className="c-comment__action" onClick={handleDelete}>
                                <UiIcon size={18} icon={ICONS.CLOSE} color={'#B3B3B3'}/>
                            </div>
                        )}
                    </div>
                </div>
                {store.isEditing && (
                    <div className="c-comment__value">
                        <CCommentSave
                            id={comment.id}
                            commentId={comment.commentId}
                            value={comment.value}
                            onSubmit={() => store.set("isEditing", false)}
                        />
                    </div>
                )}
                {!store.isEditing && (
                    <div className="c-comment__value" dangerouslySetInnerHTML={{ __html: comment.value }}/>
                )}
                <div className="c-comment__footer">
                    <div className="c-comment__respond" onClick={() => store.set("isCreating", !store.isCreating)}>
                        Ответить
                    </div>
                </div>
                {store.isCreating && (
                    <div className="c-comment__create">
                        <CCommentSave
                            commentId={comment.id}
                            onSubmit={() => store.set("isCreating", false)}
                        />
                    </div>
                )}
                {comments.length > 0 && (
                    <div className="c-comment__comments">
                        {comments.map(comment => (
                            <CComment key={comment.id} comment={comment}/>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
})

