import { action, computed, makeObservable, observable } from 'mobx';

import { ROUTES } from 'shared/constants';

import { Model } from './Model';
import { TagModel } from './TagModel';

export interface ISearchIndexModel {
    id?: number;
    createdAt?: string;
    name?: string;
    previewImageUrl?: string;
    previewText?: string;
    indexableId?: number;
    typeId?: string;
}

export class SearchIndexModel extends Model implements ISearchIndexModel {
    id = 0;
    createdAt = '';
    name = '';
    previewImageUrl = '';
    previewText = '';
    indexableId = 0;
    typeId: 'news' | 'vacancy' | 'article' | 'announcement' | 'user' | 'document' | 'department' | 'departmentbranch' = 'news';

    constructor(payload: ISearchIndexModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            name: observable,
            previewImageUrl: observable,
            previewText: observable,
            indexableId: observable,
            typeId: observable,
            update: action,
            href: computed,
        });

        this.update(payload);
    }

    get tag() {
        switch (this.typeId) {
            case 'news':
                return new TagModel({ id: 0, name: 'Новости' });
            case 'vacancy':
                return new TagModel({ id: 0, name: 'Вакансии' });
            case 'article':
                return new TagModel({ id: 0, name: 'Статьи' });
            case 'announcement':
                return new TagModel({ id: 0, name: 'Объявления' });
            case 'user':
                return new TagModel({ id: 0, name: 'Сотрудники' });
        }
        return new TagModel();
    }

    get href() {
        switch (this.typeId) {
            case 'news':
                return ROUTES.NEWS(this.indexableId);
            case 'vacancy':
                return ROUTES.VACANCY(this.indexableId);
            case 'article':
                return ROUTES.ARTICLE(this.indexableId);
            case 'announcement':
                return ROUTES.ANNOUNCEMENT(this.indexableId);
            case 'department':
                return ROUTES.DEPARTMENT(this.indexableId);
            case 'departmentbranch':
                return ROUTES.DEPARTMENT_BRANCH(this.indexableId);
            case 'document':
                return this.previewImageUrl;
            case 'user':
                return ROUTES.USER(this.indexableId);
        }
        return '/';
    }
}
