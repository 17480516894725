import { action, makeObservable, observable } from 'mobx';
import moment from "moment";
import { TagModel } from 'shared/models';
import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';

export interface IEducationLessonModel {
    id?: number;
    eventAt?: string;
    name?: string;
    typeId?: string;
    imageThumbnail?: string;
    descriptionPreview?: string;
    description?: string;
    ispringUrl?: string;
    tags?: TagModel[];
    isOnline?: boolean,
    isOffline?: boolean,
    isIspring?: boolean,
    modules?: { name: string }[];
}

export class EducationLessonModel extends Model implements IEducationLessonModel {
    casts = {
        tags: new ModelArrayCast(TagModel),
    };

    id = 0;
    eventAt = '';
    name = '';
    imageThumbnail = '';
    typeId = '';
    descriptionPreview = '';
    description = '';
    ispringUrl = '';
    tags: TagModel[] = [];
    isOnline = false;
    isOffline = false;
    isIspring = false;
    modules: { name: string }[] = [];


    constructor(payload: IEducationLessonModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            eventAt: observable,
            name: observable,
            imageThumbnail: observable,
            descriptionPreview: observable,
            description: observable,
            tags: observable,
            isOnline: observable,
            typeId: observable,
            isOffline: observable,
            isIspring: observable,
            modules: observable,
            ispringUrl: observable,
            update: action,
        });
        this.update(payload);
    }

    get eventAtMoment() {
        if (!this.eventAt) {
            return moment();
        }
        return moment(this.eventAt);
    }
}
