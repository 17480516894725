import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiCheckboxSwitch, UiTextarea } from "shared/uikit";
import { useStore } from "shared/hooks";
import { userPersonalFieldsSaveQuery } from "shared/queries";
import { UserPersonalFieldModel } from "shared/models";

import './styles.scss';

type PropsType = {
    item: UserPersonalFieldModel
}
export const PProfileUserPersonalField = observer(({ item }: PropsType) => {
    const store = useStore(() => ({
        isEditMode: false,
        isSubmitting: false,
        isSuccess: false,
        items: [] as UserPersonalFieldModel[],
        errorMessage: ''
    }))

    const handleSubmit = async () => {
        if (store.isSubmitting) {
            return;
        }
        store.set('isSubmitting', true);
        await userPersonalFieldsSaveQuery(item);
        store.set('isEditMode', false);
        store.set('isSubmitting', false);
    }

    if (!store.isEditMode) {
        return (
            <div key={item.id} className="p-user-personal-field">
                <div className="p-user-personal-field__name">{item.name}</div>
                <div className="p-user-personal-field__value">{item.value || 'Не заполнено'}</div>
                <div className="p-profile-personal-field__footer">
                    <UiButton
                        isLink
                        label={item.value ? 'Редактировать' : 'Заполнить'}
                        onClick={() => store.set('isEditMode', true)}
                    />
                </div>
            </div>
        )
    }
    return (
        <div key={item.id} className="p-profile-personal-field">
            <div className="p-profile-personal-field__header">
                <div className="p-profile-personal-field__name">{item.name}</div>
                <UiCheckboxSwitch
                    style={{
                        width: "auto"
                    }}
                    label={item.valueDefault}
                    value={item.userUserPersonalField.showValueDefault}
                    onChange={({ value }) => {
                        item.userUserPersonalField.update({
                            showValueDefault: value
                        })
                    }}
                />
            </div>
            <UiTextarea
                placeholder={item.placeholder}
                value={item.userUserPersonalField.value}
                onChange={({ value }) => {
                    item.userUserPersonalField.update({
                        value
                    })
                }}
            />
            <div className="p-profile-personal-field__footer">
                <UiButton
                    isLink
                    label='Сохранить'
                    onClick={handleSubmit}
                    isLoading={store.isSubmitting}
                />
            </div>
        </div>
    );
});
