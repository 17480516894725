import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import TimeAgo from 'javascript-time-ago'
import ru from 'javascript-time-ago/locale/ru'
import moment from "moment";

import { UserModel } from "shared/models";
import { useBroadcast, useStore } from "shared/hooks";
import { UserRecentActivityUpdatedBroadcast } from "shared/broadcasts";
import { COLORS } from "shared/constants";

TimeAgo.addDefaultLocale(ru);

type PropsType = {
    value?: UserModel | null;
    className?: string;
    children?: (data: { isOnline: boolean, status: string }) => React.ReactNode;
}

export const UiUserStatus = observer(({ value, children, className }: PropsType) => {
    const store = useStore(() => ({
        moment: moment(),
    }));

    const broadcast = useMemo(() => new UserRecentActivityUpdatedBroadcast(({ id, recentActivityAt }) => {
        if (!value) {
            return;
        }
        if (+id === +value.id) {
            value.update({ recentActivityAt });
        }
    }), [value]);
    useBroadcast(broadcast);

    useEffect(() => {
        const interval = setInterval(() => {
            store.set("moment", moment());
        }, 60000);
        return () => clearInterval(interval)
    }, [store]);

    const isOnline = !!(value && store.moment.diff(value?.recentActivityAtMoment, 'minutes') < 5);
    const status = useMemo(() => {
        if (!value) {
            return '';
        }

        if (!value.recentActivityAt) {
            return 'Никогда не был(а) в сети';
        }
        if (isOnline) {
            return 'В сети';
        }
        const timeAgo = new TimeAgo('ru-RU')
        return 'Был(а) в сети ' + timeAgo.format(Date.parse(value?.recentActivityAt));
    }, [isOnline, value]);

    if (children) {
        return (<>{children({ isOnline, status })}</>);
    }

    if (className) {
        return (
            <div className={className}>
                <span style={isOnline ? {color: COLORS.GREEN} : {}}>
                    {status}
                </span>
            </div>
        )
    }

    return (<>{status}</>);
});
