import { makeQuery } from 'shared/utilities';
import { IReservationPlaceModel, UserModel } from 'shared/models';

type RequestType = {
    id: number;
    name: string;
    description?: string;
    reservationPlaceId: number;
    startAt: string;
    endAt: string;
    users?: UserModel[];
};

type ResponseType = {
    items: IReservationPlaceModel[];
};

export const reservationsUpdateQuery = async function (data: RequestType) {
    return await makeQuery<ResponseType>('/reservations/update', { json: data });
}
