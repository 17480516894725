import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { useAction, useUrlParams, useNavigate, useStore, useUserWidgets } from 'shared/hooks';
import { UiDataBoundary, UiPagination, UiList, UiDropdownPicker, UiIcon, UiButton, UiFilter } from 'shared/uikit';
import { OnChangeEventType } from 'shared/types';
import { ApplicationModule } from 'shared/modules';
import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ICONS, ROUTES } from 'shared/constants';
import { vacanciesDepartmentBranchesQuery } from 'shared/queries';
import { DepartmentBranchModel } from "shared/models";

import VacancyItem from './components/VacancyItem';

import Store from './Store';
import Service from './Service';

import './styles.scss';

export const VacanciesPage = observer(() => {
    const store = useStore(() => ({
        departmentBranches: [] as DepartmentBranchModel[],
    }))
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
        vacancyRegionIds: [] as number[],
        departmentBranchId: null as number | null,
    });

    const fetchDepartmentBranches = useCallback(async () => {
        const { isSuccess, data } = await vacanciesDepartmentBranchesQuery();
        if (isSuccess && data) {
            store.set("departmentBranches", data.items.map(item => new DepartmentBranchModel(item)));
        }
    }, [store]);

    useEffect(() => {
        fetchDepartmentBranches();
    }, [fetchDepartmentBranches]);

    useEffect(() => {
        ApplicationModule.setTitle('Вакансии');
    });

    const handleChangePage = ({ value }: OnChangeEventType, isInfinite: boolean) => {
        Store.isInfiniteLoading = isInfinite;
        navigate(ROUTES.VACANCIES(), { ...urlParams, page: value });
    };

    const handleChangeVacancyRegionIds = useAction(({ value }: OnChangeEventType) => {
        Store.pages = 0;
        navigate(ROUTES.VACANCIES(), {
            ...urlParams,
            page: 1,
            vacancyRegionIds: value,
        });
    }, [urlParams]);

    const handleClear = () => {
        navigate(ROUTES.VACANCIES());
    };

    useEffect(() => {
        Service.fetchItems(urlParams);
    }, [urlParams]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Вакансии'>
                    <UiFilter
                        value={urlParams.departmentBranchId}
                        items={store.departmentBranches}
                        onChange={({ value }) => {
                            navigate(ROUTES.VACANCIES(), {
                                page: 1,
                                departmentBranchId: value,
                            });
                        }}
                    />
                    <UiDropdownPicker
                        style={{marginLeft: 8}}
                        icon={<UiIcon icon={ICONS.LOCATION} size={16}/>}
                        label='Регион'
                        placeholder='Выберите регион'
                        name='vacancyRegionIds'
                        value={urlParams.vacancyRegionIds}
                        items={Store.vacancyRegions}
                        onChange={handleChangeVacancyRegionIds}
                    />
                    <UiButton style={{marginLeft: 16}} label='Сбросить' isLink onClick={handleClear}/>
                </LayoutContentTitle>
                <UiDataBoundary
                    className='vacancies-page__list'
                    isLoading={Store.isLoading && !Store.isInfiniteLoading}
                    isError={!Store.isSuccess}
                    onAction={() => Service.fetchItems(urlParams)}
                >
                    <UiList items={Store.items} component={VacancyItem}/>
                </UiDataBoundary>
                <UiPagination
                    page={urlParams.page}
                    pages={Store.pages}
                    onChange={handleChangePage}
                />
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
});
