import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import lodash from "lodash";
import validator from "validator";
import { set } from "mobx";
import classnames from "classnames";

import { UserModel } from "shared/models";
import { UiAvatar, UiIcon, UiLoadingSpinner, UiScrollbar, UiUserBadge } from "shared/uikit";
import { OnChangeHandlerType } from "shared/types";
import { ICONS } from "shared/constants";
import { useAction, useDebounce } from "shared/hooks";
import { usersQuery } from "shared/queries";

import './styles.scss';

type PropsType = {
    label?: string;
    name?: string;
    limit?: number;
    canSubmitEmail?: boolean;
    isPanel?: boolean;
    onChange?: OnChangeHandlerType<UserModel[]>;
    value?: UserModel[];
}

// TODO store replace to useStore
export const    UiUsersSearch = observer((
    {
        value = [],
        label = 'Добавить участников',
        onChange,
        name = 'users',
        limit = 40,
        canSubmitEmail = false,
        isPanel = false,
    }: PropsType
) => {
    const store = useLocalObservable(() => ({
        isLoading: false,
        users: [] as UserModel[],
        query: '',
        set: set
    }));

    const handleChangeQuery = useAction((e: React.ChangeEvent<HTMLInputElement>) => {
        store.query = e.target.value;
    });

    const search = useDebounce(async () => {
        const response = await usersQuery({
            query: store.query,
            limit
        });
        if (response.isSuccess && response.data) {
            store.set(store, 'users', response.data.items.map(user => new UserModel(user)));
        }
        store.set(store, 'isLoading', false);
    }, 500);

    const handleAdd = useCallback((user: UserModel) => {
        store.set(store, "query", '');
        onChange && onChange({
            name,
            value: lodash.uniqBy([...value, user], 'id')
        });
    }, [value, onChange, name, store]);

    const handleSubmit = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode !== 13 || !canSubmitEmail || !validator.isEmail(store.query)) {
            return;
        }
        handleAdd(new UserModel({
            email: store.query,
            name: store.query
        }))
        store.set(store, 'query', '');
    }, [canSubmitEmail, store, handleAdd])

    const handleRemove = useCallback((user: UserModel) => {
        onChange && onChange({
            name,
            value: value?.filter(item => item.id !== user.id)
        });
    }, [name, value, onChange]);

    useEffect(() => {
        store.set(store, 'isLoading', true);
        search();
    }, [store, search, store.query])

    const classNames = useMemo(() => classnames('ui-users-search', {
        'ui-users-search--panel': isPanel
    }), [isPanel]);

    return (
        <div className={classNames}>
            {label && (
                <div className="ui-users-search__header">
                    <UiIcon icon={ICONS.USERS_ADD} size={20}/>
                    <div className="ui-users-search__label">
                        {label}
                    </div>
                </div>
            )}
            <div className="ui-users-search__users">
                {value?.map(user => (
                    <UiUserBadge
                        key={user.id}
                        value={user}
                        style={{
                            maxWidth: 200
                        }}
                        isSmall
                        onDelete={() => handleRemove(user)}
                    />
                ))}
                <input
                    className="ui-users-search__query"
                    type="text"
                    placeholder='Поиск по ФИО'
                    onChange={handleChangeQuery}
                    value={store.query}
                    onKeyDown={handleSubmit}
                />
            </div>
            <div className="ui-users-search__items">
                {store.isLoading && (
                    <div className="ui-users-search__loading">
                        <UiLoadingSpinner/>
                    </div>
                )}
                {store.users.length === 0 && !store.isLoading && (
                    <div className="ui-users-search__notfound">Пользователи не найдены</div>
                )}
                <UiScrollbar maxHeight={250} isDisabled={store.users.length < 6}>
                    {store.users.length > 0 && !store.isLoading && store.users.map((user) => (
                        <div key={user.id} title={user.name} className="ui-users-search__item" onClick={() => handleAdd(user)}>
                            <UiAvatar size={24} label={user.previewName} image={user.image}/>
                            <span>{user.name}</span>
                        </div>
                    ))}
                </UiScrollbar>
            </div>
        </div>
    );
});
