import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UiButton, UiCheckbox, UiIcon, UiNavLink } from "shared/uikit";
import { COLORS, ICONS, ROUTES, SEARCH_TYPES } from "shared/constants";
import { useDebounce, useNavigate, useOnClickOutside, useStore } from "shared/hooks";
import { SearchIndexModel } from "shared/models";
import { searchHintsQuery } from "shared/queries";

import './styles.scss';

export const LayoutHeaderSearch = observer(() => {
    const store = useStore(() => ({
        isOpened: false,
        query: '',
        isFocused: false,
        searchIndexes: [] as SearchIndexModel[],
        typeId: null as string | null,
        hints: [] as string[]
    }));
    const ref = useRef(null);
    const navigate = useNavigate();

    useOnClickOutside(ref, () => {
        store.set("isOpened", false);
    });

    const handleSearchHints = useDebounce(async () => {
        if (!store.query || store.query.length < 3) {
            store.set("searchIndexes", []);
            return;
        }
        const { isSuccess, data } = await searchHintsQuery({ query: store.query });
        if (isSuccess && data) {
            store.set('searchIndexes', data.items)
        }
    }, 1000);

    const className = classnames('layout-header-search', {
        'layout-header-search--opened': store.isOpened
    });

    const handleSubmit = () => {
        if (!store.query) {
            return;
        }
        navigate(ROUTES.SEARCH(), {
            query: store.query,
            typeId: store.typeId
        });
        store.set("isOpened", false);
        store.set("query", '');
        store.set("typeId", null);
    }

    return (
        <div className={className} ref={ref}>
            <div className="layout-header-search__inner">
                <div className="layout-header-search__icon" onClick={handleSubmit}>
                    <UiIcon icon={ICONS.SEARCH} size={20} color={COLORS.GRAY_1}/>
                </div>
                <input
                    onKeyPress={(e) => e.charCode === 13 ? handleSubmit() : null}
                    className="layout-header-search__control"
                    placeholder='Поиск по порталу'
                    onChange={(e) => {
                        store.set("query", e.target.value || '');
                        handleSearchHints();
                    }}
                    onFocus={() => store.set("isFocused", true)}
                    onBlur={() => {
                        setTimeout(() => store.set("isFocused", false), 200)
                    }}
                    value={store.query}
                />
                <div
                    className="layout-header-search__icon layout-header-search__icon--filter"
                    onClick={() => store.set("isOpened", true)}
                >
                    <UiIcon icon={ICONS.FILTER} size={20} color={COLORS.GRAY_1}/>
                </div>
                {(store.isFocused && !store.isOpened && !!store.searchIndexes.length) && (
                    <div className="layout-header-search-hints">
                        {store.searchIndexes.map(searchIndex => (
                            <UiNavLink
                                key={searchIndex.id}
                                to={ROUTES.SEARCH(`?query=${searchIndex.name}`)}
                                className="layout-header-search-hints__item"
                            >
                                {searchIndex.name}
                            </UiNavLink>
                        ))}
                    </div>
                )}
                <div className="layout-header-search__outer">
                    <div className="layout-header-search__items">
                        {SEARCH_TYPES.map(type => (
                            <div className="layout-header-search__item" key={type.id}>
                                <UiCheckbox
                                    label={type.name}
                                    onChange={({ value }) => {
                                        store.set("typeId", value ? type.id : null)
                                    }}
                                    value={+(store.typeId === type.id)}
                                />
                            </div>
                        ))}
                    </div>
                    <UiButton
                        label='Искать'
                        isTiny
                        isDisabled={!store.query}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    )
});
