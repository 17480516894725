import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
    UiButton,
    UiHtml,
    UiIcon,
    UiIconBadge,
    UiLoadingSpinner,
    UiModal, UiModalClose,
    UiModalTitle,
    UiReservation,
    UiUserBadge
} from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { Notifier } from "shared/utilities";
import { calendarItemDeleteQuery } from "shared/queries";
import { useMedia, useUrlParams } from "shared/hooks";

import { Store } from "../../Store";
import { Service } from "../../Service";

import './styles.scss';

export const CalendarItemPreview = observer(() => {
    const { calendarItem } = Store;

    const urlParams = useUrlParams<{ id: string, changeKey: string }>({
        id: '',
        changeKey: ''
    });

    useEffect(() => {
        if (!!urlParams.id && !!urlParams.changeKey) {
            Service.getCalendarItem(urlParams)
        }
    }, [urlParams, urlParams.id, urlParams.changeKey])

    const handleClose = useCallback(() => {
        Store.set(Store.modals.preview, 'isOpened', false);
    }, []);

    const { isMobile } = useMedia();

    const handleEdit = useCallback(() => {
        Store.set(Store.modals.preview, 'isOpened', false);
        Store.set(Store.modals.edit, 'isOpened', true);
    }, []);

    const handleDelete = useCallback(async () => {
        if (!calendarItem) {
            return;
        }
        const isConfirmed = await Notifier.confirm('Удаление мероприятия', 'Подтвердите удаление мероприятия');
        if (isConfirmed) {
            Store.set(Store.modals.preview, 'isOpened', false);
            await calendarItemDeleteQuery({
                id: calendarItem.id,
                changeKey: calendarItem.changeKey
            });

            Store.set(Store, '_calendarItems', Store.calendarItems.filter(item => item.id !== calendarItem.id));
        }
    }, [calendarItem]);

    if (!calendarItem) {
        return (
            <UiModal isAside isOpened={Store.modals.preview.isOpened} onClose={handleClose}>
                <UiLoadingSpinner style={{ paddingTop: isMobile ? 0 : 'calc((100vh - 100px) / 2)' }}/>
            </UiModal>
        );
    }

    return (
        <UiModal
            isAside
            isOpened={Store.modals.preview.isOpened}
            onClose={handleClose}
            className="calendar-item-preview"
            title={<UiModalTitle title={'Событие/Встреча'} style={{ backgroundColor: 'transparent' }}/>}
        >
            <UiModalClose onClick={handleClose}/>
            <div className='calendar-item-preview__subject'>{calendarItem.name}</div>
            {calendarItem.body && (<UiHtml className='calendar-item-preview__body' value={calendarItem.body}/>)}
            <div className="calendar-item-preview__date">
                <UiIconBadge icon={ICONS.CALENDAR} label={calendarItem.startMoment.format('D MMMM Y')}/>
                <UiIconBadge icon={ICONS.CLOCK} label={calendarItem.time}/>
            </div>
            <div className="calendar-item-preview__delimiter"/>
            <div className="calendar-item-preview-users">
                <div className="calendar-item-preview-users__label">Постановщик:</div>
                <div className="calendar-item-preview-users__inner">
                    <UiUserBadge isLight value={calendarItem.organizer}/>
                </div>
            </div>
            {calendarItem.attendees.length > 0 && (
                <div className="calendar-item-preview-users">
                    <div className="calendar-item-preview-users__label">Участники:</div>
                    <div className="calendar-item-preview-users__inner">
                        {calendarItem.attendees.map(attendee => (
                            <UiUserBadge
                                key={attendee.email}
                                isLight
                                value={attendee}
                            />
                        ))}
                    </div>
                </div>
            )}
            {calendarItem.reservation && (
                <div className="calendar-item-preview__reservation">
                    <UiReservation item={calendarItem.reservation}/>
                </div>
            )}
            {(calendarItem.permissions.canEdit || calendarItem.permissions.canDelete) && (
                <div className="calendar-item-preview__actions">
                    {calendarItem.permissions.canEdit && (
                        <UiButton
                            isTransparent
                            isSmall
                            isLight
                            label='Изменить'
                            onClick={handleEdit}
                            iconBefore={<UiIcon icon={ICONS.EDIT} color={COLORS.BLACK} size={16}/>}
                        />
                    )}
                    {calendarItem.permissions.canDelete && (
                        <UiButton
                            style={{ marginLeft: 'auto' }}
                            isSecondary
                            isSmall
                            isLight
                            label='Удалить'
                            onClick={handleDelete}
                            iconBefore={<UiIcon icon={ICONS.TRASH} color={COLORS.WHITE} size={16}/>}
                        />
                    )}
                </div>
            )}
        </UiModal>
    );
});
