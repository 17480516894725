import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiCard, UiCardTitle, UiDataBoundary } from 'shared/uikit';
import { useStore } from "shared/hooks";
import { SocialNetworkModel } from "shared/models";
import { socialNetworksQuery } from "shared/queries";

import './styles.scss';

export const WSocialNetworks = observer(() => {
    const store = useStore(() => ({
        items: [] as SocialNetworkModel[],
        isLoading: true,
        isSuccess: false,
        isOpened: false,
    }));

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', false);
            const response = await socialNetworksQuery();
            if (response.isSuccess && response.data) {
                store.set('items', response.data.items.map(item => new SocialNetworkModel(item)));
                store.set('isSuccess', true);
            }
            store.set('isLoading', false);
        })();
    }, [store]);

    return (
        <UiCard className='w-social-networks'>
            <UiCardTitle label='Наши социальные сети' collapsable={'soc-networks1'}/>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="w-social-networks__items">
                    {store.items.map(item => (
                        <a
                            key={item.id}
                            className={'w-social-networks__item'}
                            href={item.link}
                            target='_blank'
                            style={{ backgroundImage: `url(${item.image})` }}
                            rel="noreferrer"
                        />
                    ))}
                </div>
            </UiDataBoundary>
        </UiCard>
    );
});
