import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import BeatLoader from 'react-spinners/BeatLoader';

import { COLORS } from 'shared/constants';

import './styles.scss';

type PropsType = {
    label?: React.ReactNode;
    onClick?: (event: React.SyntheticEvent) => void;
    isSubmit?: boolean;
    isLarge?: boolean;
    isSmall?: boolean;
    isTiny?: boolean;
    isLoading?: boolean;
    isOutline?: boolean;
    isTranslucent?: boolean;
    isLight?: boolean;
    isDanger?: boolean;
    isDisabled?: boolean;
    isTransparent?: boolean;
    isSecondary?: boolean;
    isLink?: boolean;
    className?: string;
    iconBefore?: React.ReactNode | null;
    iconAfter?: React.ReactNode | null;
    style?: React.CSSProperties;
    children?: React.ReactNode
}

export const UiButton = observer((
    {
        label = 'Кнопка',
        isLarge = false,
        isSubmit = false,
        isSmall = false,
        className = '',
        isTiny = false,
        isOutline = false,
        isTranslucent = false,
        isLight = false,
        isDanger = false,
        isTransparent = false,
        isSecondary = false,
        isLoading = false,
        isDisabled = false,
        isLink = false,
        iconBefore = null,
        iconAfter = null,
        style = {},
        onClick,
        children
    }: PropsType
) => {
    const classNames = useMemo(() => classnames('ui-button', className, {
        'ui-button--disabled': isDisabled,
        'ui-button--large': isLarge,
        'ui-button--small': isSmall,
        'ui-button--tiny': isTiny,
        'ui-button--outline': isOutline,
        'ui-button--link': isLink,
        'ui-button--danger': isDanger,
        'ui-button--translucent': isTranslucent,
        'ui-button--light': isLight,
        'ui-button--transparent': isTransparent,
        'ui-button--secondary': isSecondary,
        'ui-button--width': style?.width,
    }), [isLarge, isDanger, isSmall, isTiny, isOutline, isLink, isTranslucent, className, style, isLight, isTransparent, isSecondary, isDisabled]);

    const content = useMemo(() => {
        if (isLoading) {
            return <BeatLoader color={isOutline ? COLORS.BLACK : COLORS.WHITE} size={10}/>;
        }
        return (
            <>
                {iconBefore && <span className='ui-button-icon--before'>{iconBefore}</span>}
                <span>{label}</span>
                {iconAfter && <span className='ui-button-icon--after'>{iconAfter}</span>}
            </>
        );
    }, [label, iconAfter, iconBefore, isLoading, isOutline]);

    return (
        <button
            onClick={isDisabled ? undefined : onClick}
            className={classNames} style={style}
            type={isSubmit ? 'submit' : 'button'}
        >
            {content}
            {children}
        </button>
    );
})
