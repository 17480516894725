import classnames from "classnames";
import { observer } from 'mobx-react';
import { PPDWidgetItemValueProgress } from "pages/profile-dashboard/components/PPDWidget/components/PPDWidgetItemValueProgress";
import React from 'react';

import compareDown from "../../assets/compare-down.svg";
import compareUp from "../../assets/compare-up.svg";

import './styles.scss';

type PropsType = {
    typeId?: string,
    color?: string,
    value?: number | string | null,
    fontSize?: number | [number, number],
    iconSize?: [number, number],
    progressSize?: number | null,
}

export const PPDWidgetItemValue = observer((
    {
        typeId = 'base',
        color = 'base',
        value = 0,
        fontSize: _fontSize = 32,
        iconSize = [32, 14],
        progressSize = null
    }: PropsType
) => {
    const fontSize: [number, number] = Array.isArray(_fontSize) ? _fontSize : [_fontSize, _fontSize];
    if (value === null || value === undefined) {
        return null;
    }

    if (typeId === 'base') {
        if (progressSize !== null) {
            return (
                <PPDWidgetItemValueProgress fontSize={fontSize[0]} color={color} progress={+value} size={progressSize}/>
            )
        }
        return (
            <div
                style={{ fontSize: fontSize[0] }}
                className={classnames("ppd-widget-item-value", `ppd-widget-item-value--${typeId}`, `ppd-widget-color--${color}`, `ppd-widget-color--force-${color}`)}
            >
                {value}%
            </div>
        )
    }

    const isPositive = +value >= 0;
    return (
        <div
            style={{ fontSize: fontSize[1], lineHeight: `${fontSize[1]}px` }}
            className={classnames("ppd-widget-item-value", `ppd-widget-item-value--${typeId}`, {
                'ppd-widget-item-value--positive': isPositive
            })}
        >
            <div
                style={{
                    width: iconSize[0],
                    minWidth: iconSize[0],
                    height: iconSize[0],
                }}
                className="ppd-widget-item-value__icon"
            >
                <img
                    style={{
                        width: iconSize[1],
                        minWidth: iconSize[1],
                        height: iconSize[1],
                    }}
                    alt={''}
                    src={+value >= 0 ? compareUp : compareDown}
                />
            </div>
            <span>{+value > 0 ? '+' : ''}{value}%</span>
        </div>
    );
});
