import { makeQuery } from 'shared/utilities';
import { IChatMessageAttachmentModel } from "shared/models";

type RequestType = {
    typeId?: string,
    chatRoomId: number,
};

type ResponseType = {
    items: IChatMessageAttachmentModel[];
};

export const chatMessageAttachmentsQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/chat-message-attachments', { params });
}
