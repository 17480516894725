import React from 'react';
import { observer } from 'mobx-react';
import { GalleryFileModel } from 'shared/models';
import { UiImageThumbnail, UiIcon } from 'shared/uikit';
import { ICONS, COLORS } from 'shared/constants';

import './styles.scss';

type PropsType = {
    galleryFile: GalleryFileModel,
    onClick?: () => void
}

export const GalleryFileGridCell = observer(({ galleryFile, onClick }: PropsType) => {
    return (
        <div className='gallery-file-grid-cell' onClick={onClick}>
            {galleryFile.isImage && (
                <UiImageThumbnail className="gallery-file-grid-cell__image" value={galleryFile.thumbnail}/>
            )}
            {!galleryFile.isImage && (
                <div className="gallery-file-grid-cell-video">
                    <video preload='metadata' src={galleryFile.url + '#t=5'}></video>
                    <div className="gallery-file-grid-cell-video__icon">
                        <UiIcon icon={ICONS.VIDEOS} size={88} color={COLORS.WHITE}/>
                    </div>
                </div>
            )}
        </div>
    );
})
