import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';
import classnames from "classnames";

type PropsType = {
    isLoading?: boolean;
    isFill?: boolean;
    className?: string;
    title?: string | null;
    children?: React.ReactNode,
    flexStart?: boolean
}

export const LayoutBody = observer(({ children, flexStart = true, className }: PropsType) => {
    const classNames = classnames('layout-body', className);
    return (
        <div className={classNames} style={flexStart ? { alignItems: 'flex-start' } : {}}>
            {children}
        </div>
    );
})
