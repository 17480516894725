import classnames from "classnames";
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { CalendarItemModel } from "shared/models";
import { UiCalendarItem } from "shared/uikit";

import { Service } from "../../Service";
import { Store } from '../../Store';

import './styles.scss';

type CalendarWeekDayItemType = {
    count: number,
    position: number,
    calendarItem: CalendarItemModel,
    style: React.CSSProperties
}

const times = [
    // '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00',
    '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00',
    '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
    '21:00', '22:00',
    // '23:00',
];

const timeFromHour = 7;

export const CalendarWeek = observer(() => {
    const dayHeight = 1200;
    const hourHeight = 50;

    const timeToTop = (value: number) => (value - timeFromHour * 60) * hourHeight / 60 + 1;
    const timeToHeight = (value: number) => (value) * hourHeight / 60 - 3;
    const itemHeight = (time: number, top: number) => {
        let height = timeToHeight(time);
        const offsetTop = height + top;
        if (offsetTop >= dayHeight) {
            height = height - (offsetTop - dayHeight);
        }

        if (height < 25) {
            height = 25;
        }
        return height
    }
    const positionToLeft = (position: number, count: number) => {
        return `calc(4px + ${position}px + calc((100% - ${7 + count}px) / ${count}) * ${position})`;
    }
    const positionToWidth = (count: number) => {
        return `calc((100% - ${7 + count}px) / ${count})`;
    }

    const getCalendarItemsInRange = (items: CalendarWeekDayItemType[], item: CalendarWeekDayItemType, includeCurrent = false) => {
        return items.filter(({ calendarItem }) => {
            if (calendarItem.id === item.calendarItem.id && !includeCurrent) {
                return false;
            }
            if (item.calendarItem.startTimeValue <= calendarItem.startTimeValue && calendarItem.startTimeValue <= item.calendarItem.endTimeValue) {
                return true;
            }
            if (item.calendarItem.startTimeValue <= calendarItem.endTimeValue && calendarItem.endTimeValue <= item.calendarItem.endTimeValue) {
                return true;
            }
            if (calendarItem.startTimeValue <= item.calendarItem.startTimeValue && item.calendarItem.startTimeValue <= calendarItem.endTimeValue) {
                return true;
            }
            if (calendarItem.startTimeValue <= item.calendarItem.endTimeValue && item.calendarItem.endTimeValue <= calendarItem.endTimeValue) {
                return true;
            }
            return false;
        });
    }

    const getFreePosition = (positions: number[]) => {
        let position = -1;
        let _positions = positions.filter(p => p > -1).sort((a, b) => a - b);

        for (let i = 0; i < _positions.length; i++) {
            if (_positions[i] > position + 1) {
                return position + 1;
            }

            if (_positions[i] > position) {
                position = _positions[i];
            }
        }

        return position + 1;
    }

    const handleDay = (calendarItems: CalendarItemModel[]): CalendarWeekDayItemType[] => {
        let items: CalendarWeekDayItemType[] = [];

        calendarItems.forEach((calendarItem) => {
            const top = timeToTop(calendarItem.startTimeValue);
            const calendarWeekDayItem: CalendarWeekDayItemType = {
                count: 1,
                position: -1,
                calendarItem,
                style: {
                    position: 'absolute',
                    height: itemHeight(calendarItem.endTimeValue - calendarItem.startTimeValue, top),
                    top,
                    left: 0,
                    width: '100%',
                    display: top >= 0 ? 'block' : 'none'
                }
            };

            items.push(calendarWeekDayItem);
        });

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const calendarItemsInRange = getCalendarItemsInRange(items, item);
            if (calendarItemsInRange.length === 0) {
                items[i].position = 0;
                items[i].style.width = positionToWidth(1);
                items[i].style.left = positionToLeft(0, 1);
                continue;
            }

            items[i].position = getFreePosition(calendarItemsInRange.map(cItem => cItem.position));
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const calendarItemsInRange = getCalendarItemsInRange(items, item, true);

            items[i].count = Math.max(0, ...calendarItemsInRange.map(cItem => cItem.position)) + 1;
            items[i].style.width = positionToWidth(items[i].count);
            items[i].style.left = positionToLeft(items[i].position, items[i].count);
        }

        return items;
    };

    const timelineTop = useMemo(() => {
        const now = moment();

        const top = timeToTop(now.hours() * 60 + now.minutes());
        return top > 0 ? top : 0;
    }, []);

    return (
        <div className={classnames("calendar-week", {
            "calendar-week--5": !Store.isFullWeek
        })}>
            <div className="calendar-week__inner">
                <div className="calendar-week-header">
                    <div className="calendar-week-cell calendar-week-cell--aside"/>
                    {Store.weekItems.map((item) => (
                        <div
                            key={item.id}
                            className="calendar-week-cell"
                            onDoubleClick={() => Service.showEditModal(item.moment.format())}
                        >
                            <div className="calendar-week-cell__date">
                                <span>{item.moment.format('ddd')}</span>
                                <span>{item.moment.format('D')}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="calendar-week-all-day">
                    <div className="calendar-week-cell calendar-week-cell--aside">Весь день</div>
                    {Store.weekItems.map((item) => (
                        <div
                            key={item.id}
                            className="calendar-week-cell"
                            onDoubleClick={() => Service.showEditModal(item.moment.format())}
                        >
                            {item.calendarItems.filter(calendarItem => calendarItem.isAllDayEvent).map(calendarItem => (
                                <div
                                    key={calendarItem.id}
                                    className="calendar-week-all-day__item"
                                    onClick={() => Service.getCalendarItem(calendarItem)}
                                >
                                    {calendarItem.name}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="calendar-week-days">
                    <div className="calendar-week-day">
                        {times.map(time => (
                            <div key={time} className="calendar-week-cell calendar-week-cell--aside">{time}</div>
                        ))}
                    </div>
                    {Store.weekItems.map((item, index) => {
                        return (
                            <div key={item.id} className="calendar-week-day">
                                {times.map(time => (
                                    <div
                                        key={item.id + time}
                                        className="calendar-week-cell"
                                        onDoubleClick={() => Service.showEditModal(item.moment.format(), time)}
                                    />
                                ))}
                                {handleDay(item.calendarItems.filter(calendarItem => !calendarItem.isAllDayEvent)).map(item => {
                                    return (
                                        <UiCalendarItem
                                            key={item.calendarItem.id}
                                            style={item.style}
                                            onClick={() => Service.getCalendarItem(item.calendarItem)}
                                            item={item.calendarItem}
                                            toLeft={index > 4}
                                        />
                                    )
                                })}
                            </div>
                        )
                    })}
                    <div className="calendar-week-timeline" style={{ top: timelineTop }}/>
                </div>
            </div>
        </div>
    );
});
