import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutContent, LayoutBody, LayoutAside, LayoutContentTitle } from 'shared/layout';
import { officesQuery } from "shared/queries";
import { useStore, useUserWidgets } from "shared/hooks";
import { OfficeModel } from "shared/models";
import { UiDataBoundary} from "shared/uikit";
import { ApplicationModule } from "shared/modules";

import { OfficeItem } from "./components/OfficeItem";

import './styles.scss';
import { ROUTES } from "shared/constants";

export const OfficesPage = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        pages: 1,
        viewType: 'card',
        offices: [] as OfficeModel[],
    }));

    useEffect(() => {
        ApplicationModule.setTitle('Офисы');

        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', true);
            const { data, isSuccess } = await officesQuery();
            if (isSuccess && data) {
                store.set('offices', data.items.map(item => new OfficeModel(item)));
            } else {
                store.set('isSuccess', false);
            }
            store.set('isLoading', false);
        })();
    }, [store]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Офисы' backTo={ROUTES.COMPANY()}/>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.offices.map(office => <OfficeItem key={office.id} office={office}/>)}
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    )
});
