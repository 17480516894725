import { makeQuery } from 'shared/utilities';
import { NullableType } from "shared/types";

type ResponseType = {};

type ProgressCallbackType = NullableType<(progress: number) => void>

export const chatMessageAttachmentsCreateQuery = async function (chatRoomId: number, files: File[], onUploadProgress: ProgressCallbackType = null) {
    const formData = new FormData();
    formData.append('chat_room_id', `${chatRoomId}`);
    files.forEach((file, index) => {
        formData.append(`${index}`, file);
    })
    return await makeQuery<ResponseType>('/chat-message-attachments/create', { formData, onUploadProgress });
}
