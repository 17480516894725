import { observer } from 'mobx-react';
import moment from "moment";
import React, { useEffect } from 'react';
import { ROUTES } from "shared/constants";
import { CalendarItemTypeEnum, PermissionEnum } from "shared/enums";

import { useHandler, useNavigate, useUrlParams } from "shared/hooks";
import { LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from "shared/modules";
import { UiCheckboxSwitch, UiDataBoundary, UiDatePagination, UiFilter, UiTabs } from "shared/uikit";
import { Storage } from "shared/utilities";

import { CalendarItemEdit } from "./components/CalendarItemEdit";
import { CalendarItemPreview } from "./components/CalendarItemPreview";
import { CalendarMonth } from "./components/CalendarMonth";
import { CalendarWeek } from "./components/CalendarWeek";
import { CalendarYear } from "./components/CalendarYear";
import { Service } from "./Service";

import { Store } from "./Store";

import './styles.scss';

type UrlParamsType = {
    date: string,
    type: "week" | "month" | "year";
    calendarItemType: string;
}

const calendarItemsTypes = [
    { id: CalendarItemTypeEnum.Birthday, name: 'Дни рождения' },
]

export const CalendarPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams<UrlParamsType>({
        date: moment().format('Y-MM-DD'),
        type: "week",
        calendarItemType: CalendarItemTypeEnum.Outlook
    });

    useEffect(() => {
        if (!ApplicationModule.user.can(PermissionEnum.WebCalendarAndReservation)) {
            navigate(ROUTES.HOME());
            return;
        }
        ApplicationModule.setTitle('Календарь');
    }, [navigate]);

    useEffect(() => {
        Store.set(Store, 'calendarItemType', urlParams.calendarItemType);
    }, [urlParams.calendarItemType]);

    useEffect(() => {
        if (!ApplicationModule.user.outlookLogin) {
            return;
        }
        Store.set(Store, 'type', urlParams.type);
        Store.set(Store, 'date', moment(urlParams.date))
        Service.getCalendarItems();
    }, [urlParams.date, urlParams.type])

    const handleChange = useHandler<string>(({ name, value }) => {
        navigate(ROUTES.CALENDAR(), {
            ...urlParams,
            mode: null,
            id: null,
            changeKey: null,
            [name]: value
        })
    }, [urlParams]);

    if (!ApplicationModule.user.outlookLogin) {
        return (
            <LayoutBody>
                <LayoutContent>
                    <LayoutContentTitle title='Календарь'/>
                    <UiDataBoundary
                        isError
                        errorMessage={'Подключите календарь Outlook в личном кабинете'}
                        actionText={'Перейти в личный кабинет'}
                        onAction={() => navigate(ROUTES.PROFILE())}
                    />
                </LayoutContent>
            </LayoutBody>
        )
    }

    return (
        <LayoutBody className={'calendar-page'}>
            <LayoutContent>
                <LayoutContentTitle title='Календарь' widgets={false}>
                    <UiCheckboxSwitch
                        value={Store.isFullWeek}
                        label='Выходные дни'
                        name='isFullWeek'
                        onChange={(data) => {
                            Store.set(Store, 'isFullWeek', data.value);
                            Storage.set('Calendar.isFullWeek', data.value);
                        }}
                    />
                    <UiFilter
                        defaultItem={{
                            id: CalendarItemTypeEnum.Outlook,
                            name: 'Календарь Outlook'
                        }}
                        items={calendarItemsTypes}
                        value={urlParams.calendarItemType}
                        onChange={({ value }) => {
                            navigate(ROUTES.CALENDAR(), {
                                ...urlParams,
                                calendarItemType: value
                            })
                        }}
                    />
                </LayoutContentTitle>
                <div className="calendar-page__header">
                    <UiTabs
                        onChange={handleChange}
                        value={urlParams.type}
                        items={Store.types}
                        name='type'
                    />
                    <UiDatePagination
                        onChange={handleChange}
                        name='date'
                        type={urlParams.type}
                        value={urlParams.date}
                    />
                </div>
                {Store.type === "week" && <CalendarWeek/>}
                {Store.type === "month" && <CalendarMonth/>}
                {Store.type === "year" && <CalendarYear/>}
                <CalendarItemPreview/>
                <CalendarItemEdit/>
            </LayoutContent>
        </LayoutBody>

    );
});
