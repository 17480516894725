import React, { useRef } from 'react';
import { observer } from 'mobx-react';

import {
    UiAvatar,
    UiButton,
    UiForm,
    UiFormControl,
    UiIcon,
    UiModal,
    UiModalTitle,
    UiScrollbar,
    UiUserSelect
} from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { useOnClickOutside, useRouteParams, useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { Notifier } from "shared/utilities";
import { spaceOwnersSaveQuery, spaceUsersDeleteQuery, spaceUsersInsertQuery } from "shared/queries/TaskTracker";
import { ApplicationModule } from "shared/modules";

import './index.scss';

type PropsType = {
    canEdit: boolean,
    users: UserModel[],
    owners: UserModel[],
    onChangeUsers: (users: UserModel[]) => void,
    onChangeOwners: (users: UserModel[]) => void,
}

export const CSpaceParticipants = observer(({ users, owners, onChangeUsers, onChangeOwners, canEdit }: PropsType) => {
    const ref = useRef<HTMLDivElement>(null);
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        isOpened: false,
    }));
    const usersStore = useStore(() => ({
        isCreating: false,
        isSubmitting: false,
        isConfirming: false,
        userIds: [] as number[]
    }));

    const ownersStore = useStore(() => ({
        isCreating: false,
        isSubmitting: false,
        isConfirming: false,
        userIds: [] as number[]
    }));

    useOnClickOutside(ref, () => {
        if (usersStore.isConfirming || usersStore.isCreating) {
            return;
        }
        store.set("isOpened", false);
    });

    const shown = users.slice(0, 4);
    const hidden = users.slice(4);

    const handleSubmitUsers = async () => {
        if (usersStore.isSubmitting || usersStore.userIds.length === 0) {
            return;
        }
        usersStore.set("isSubmitting", true);
        const { isSuccess, data } = await spaceUsersInsertQuery({
            items: usersStore.userIds.map((userId) => ({
                userId,
                spaceId: routeParams.id
            }))
        })
        if (isSuccess && data) {
            onChangeUsers([...users, ...data.items.map(item => new UserModel(item))]);
        }
        usersStore.set("isCreating", false);
        usersStore.set("isSubmitting", false);
        usersStore.set("userIds", []);
    }

    const handleDelete = async (user: UserModel) => {
        usersStore.set("isConfirming", true);
        const result = await Notifier.confirm(`Удаление пользователя из пространства`, `Вы точно хотите удалить участника ${user.previewName} из пространства?`, 'Удалить');
        if (result) {
            spaceUsersDeleteQuery({
                spaceId: routeParams.id,
                userId: user.id
            });
            onChangeUsers(users.filter(item => item.id !== user.id));
        }
        usersStore.set("isConfirming", false);
    }

    const handleEditOwners = () => {
        ownersStore.set("userIds", owners.map(owner => +owner.id));
        ownersStore.set("isCreating", true);
    }

    const isAdmin = ApplicationModule.user.isAdmin;

    const handleSubmitOwners = async () => {
        if (ownersStore.isSubmitting) {
            return;
        }
        ownersStore.set("isSubmitting", true);
        const { isSuccess, data } = await spaceOwnersSaveQuery({
            spaceId: routeParams.id,
            items: ownersStore.userIds.map((userId) => ({
                userId,
                spaceId: routeParams.id
            }))
        })
        if (isSuccess && data) {
            onChangeOwners(data.items.map(item => new UserModel(item)));
        }
        ownersStore.set("isCreating", false);
        ownersStore.set("isSubmitting", false);
        ownersStore.set("userIds", []);
    }

    return (
        <div className={'c-tt-space-participants'} ref={ref}>
            <div
                className="c-tt-space-participants__inner"
                onClick={() => store.set("isOpened", !store.isOpened)}
            >
                {hidden.length > 0 && (
                    <div className="c-tt-space-participants__count">+{hidden.length}</div>
                )}
                {shown.map(user => (
                    <UiAvatar key={user.id} size={40} image={user.imageAvatar} label={user.previewName}/>
                ))}
                {(shown.length === 0 && hidden.length === 0) && (
                    <div className="c-tt-space-participants__count">
                        <UiIcon icon={ICONS.PLUS} size={14} color={COLORS.WHITE}/>
                    </div>
                )}
                {owners.map(user => (
                    <div key={user.id} className="c-tt-space-participants__owner">
                        <UiAvatar key={user.id} size={40} image={user.imageAvatar} label={user.previewName}/>
                        <UiIcon icon={ICONS.STAR} size={20} color={'#FED330'}/>
                    </div>
                ))}
            </div>
            {store.isOpened && (
                <div className="c-tt-space-participants__outer">
                    {canEdit && (
                        <div className="c-tt-space-participants__add">
                            <UiButton
                                onClick={() => usersStore.set('isCreating', true)}
                                isSmall
                                label={'Добавить новых участников'}
                                iconAfter={<UiIcon icon={ICONS.PLUS} size={12} color={COLORS.WHITE}/>}
                            />
                        </div>
                    )}
                    {(isAdmin || owners.length > 0) && (
                        <div className="c-tt-space-participants-items">
                            <div className="c-tt-space-participants-items__title">
                                Ответственные в пространстве:
                            </div>
                            {(owners.length === 0 && isAdmin) && (
                                <div
                                    className="c-tt-space-participants-items__add"
                                    onClick={handleEditOwners}
                                >
                                    Добавить
                                </div>
                            )}
                            {owners.length > 0 && owners.map(item => {
                                return (
                                    <div
                                        key={item.id}
                                        className='c-tt-space-participants-owner'
                                    >
                                        <UiAvatar size={32} image={item.imageAvatar} label={item.previewName}/>
                                        <div className="c-tt-space-participants-owner__inner">
                                            <div className="c-tt-space-participants-owner__name">
                                                {item.previewName}
                                            </div>
                                            {isAdmin && (
                                                <div
                                                    className="c-tt-space-participants-owner__edit"
                                                    onClick={handleEditOwners}
                                                >
                                                    Изменить
                                                </div>
                                            )}
                                        </div>
                                        <UiIcon icon={ICONS.STAR} size={20} color={'#FED330'}/>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {users.length > 0 && (
                        <div className="c-tt-space-participants-items">
                            <div className="c-tt-space-participants-items__title">
                                Участники:
                            </div>
                            <UiScrollbar maxHeight={300}>
                                {users.map(item => {
                                    return (
                                        <div
                                            key={item.id}
                                            className='c-tt-space-participants-user'
                                        >
                                            <UiAvatar size={32} image={item.imageAvatar} label={item.previewName}/>
                                            <div className="c-tt-space-participants-user__name">{item.previewName}</div>
                                            {canEdit && (
                                                <div
                                                    className="c-tt-space-participants-user__delete"
                                                    onClick={() => handleDelete(item)}
                                                >
                                                    <UiIcon icon={ICONS.CLOSE} size={12}/>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </UiScrollbar>
                        </div>
                    )}
                </div>
            )}
            <UiModal
                styleBody={{ width: 640 }}
                isOpened={usersStore.isCreating}
                onClose={() => usersStore.set("isCreating", false)}
            >
                <UiModalTitle title={'Добавить участников в пространство'}/>
                <UiForm onSubmit={handleSubmitUsers}>
                    <UiFormControl label={'Участники'}>
                        <UiUserSelect
                            isMultiple
                            placeholder={'Начните вводить ФИО'}
                            name={'userIds'}
                            value={usersStore.userIds}
                            onChange={usersStore.handleChange}
                        />
                    </UiFormControl>
                    <div className={'ui-modal__actions'}>
                        <UiButton
                            isOutline
                            onClick={() => usersStore.set("isCreating", false)}
                            label={'Отмена'}
                        />
                        <UiButton
                            isLoading={usersStore.isSubmitting}
                            isSubmit
                            label={'Добавить'}
                        />
                    </div>
                </UiForm>
            </UiModal>
            <UiModal
                styleBody={{ width: 640 }}
                isOpened={ownersStore.isCreating}
                onClose={() => ownersStore.set("isCreating", false)}
            >
                <UiModalTitle title={'Изменить / добавить ответственного'}/>
                <UiForm onSubmit={handleSubmitOwners}>
                    <UiFormControl label={'Ответственные'}>
                        <UiUserSelect
                            isMultiple
                            placeholder={'Начните вводить ФИО'}
                            name={'userIds'}
                            value={ownersStore.userIds}
                            onChange={ownersStore.handleChange}
                        />
                    </UiFormControl>
                    <div className={'ui-modal__actions'}>
                        <UiButton
                            isOutline
                            onClick={() => ownersStore.set("isCreating", false)}
                            label={'Отмена'}
                        />
                        <UiButton
                            isLoading={ownersStore.isSubmitting}
                            isSubmit
                            label={'Сохранить'}
                        />
                    </div>
                </UiForm>
            </UiModal>
        </div>
    )
});

