import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { useNavigate, useOnClickOutside, useStore, useUrlParams } from "shared/hooks";
import { TaskTrackerTaskStatusEnum } from "shared/enums";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';

export const CFilterStatus = observer(() => {
    const ref = useRef<HTMLDivElement>(null);
    const store = useStore(() => ({
        isOpened: false
    }))
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        fStatus: null
    });

    const status = TaskTrackerTaskStatusEnum.from(urlParams.fStatus);

    useOnClickOutside(ref, () => store.set("isOpened", false));

    return (
        <div className="c-tt-filter-status" ref={ref}>
            <div className="c-tt-filter-status-control" onClick={() => store.set("isOpened", !store.isOpened)}>
                <div className="c-tt-filter-status-control__label">Фильтры:</div>
                <div className="c-tt-filter-status-control__value">
                    {status.color && (
                        <i style={{ backgroundColor: status.color }}/>
                    )}
                    <span>{status.name}</span>
                </div>
                <UiIcon size={12} icon={ICONS.CHEVRON_DOWN}/>
            </div>
            <div className={classnames('c-tt-filter-status__items', {
                'c-tt-filter-status__items--active': store.isOpened
            })}>
                {TaskTrackerTaskStatusEnum.items.map(status => (
                    <div
                        key={status.name}
                        className="c-tt-filter-status__item"
                        onClick={() => {
                            navigate(null, {
                                ...urlParams,
                                fStatus: status.id
                            });
                            store.set("isOpened", false);
                        }}
                    >
                        <i style={{ backgroundColor: status.color }}/>
                        <span>{status.name}</span>
                    </div>
                ))}
            </div>
        </div>
    )
});

