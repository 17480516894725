import { makeAutoObservable, runInAction } from "mobx";

import { notificationsQuery, notificationsUnreadCountQuery } from "shared/queries";

class NotificationsModuleClass {
    page = 1;
    filter = 'all';
    unreadCount = 0;
    items: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    fetchUnreadCount = async () => {
        const response = await notificationsUnreadCountQuery();
        runInAction(() => {
            if (response.isSuccess && response.data) {
                this.unreadCount = response.data.count;
            }
        });
    }

    fetch = async (page = 1, filter = 'all') => {
        runInAction(() => {
            this.page = page;
            this.filter = filter;
        })
        await notificationsQuery({ page, filter });
    }
}

export const NotificationsModule = new NotificationsModuleClass();
