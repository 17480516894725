import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import lodash from "lodash";

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { UiDataBoundary, UiFilter, UiGrid, UiPagination, UiViewType } from 'shared/uikit';
import { useMedia, useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { ArticleModel, TagModel } from "shared/models";
import { articlesQuery, tagsQuery } from "shared/queries";
import { ROUTES } from "shared/constants";

import { ArticleItem } from './components/ArticleItem';

import './styles.scss';

export const ArticlesPage = observer(() => {
    const navigate = useNavigate();

    const { value: columns, isMobile } = useMedia({
        is320: 1,
        is768: 2,
        is1920: 4
    });

    const urlParams = useUrlParams({
        page: 1,
        tagId: null as number | null,
        isPrivate: null as number | null
    });

    const store = useStore(() => ({
        pages: 0,
        viewType: 'card',
        isLoading: true,
        isSuccess: true,
        isInfinite: false,
        items: [] as ArticleModel[],
        tags: [] as TagModel[]
    }));

    const fetchTags = useCallback(async () => {
        const { isSuccess, data } = await tagsQuery({ has: ['articles'] });
        if (isSuccess && data) {
            store.set("tags", data.items.map(item => new TagModel(item)));
        }
    }, [store]);

    const fetchItems = useCallback(async () => {
        store.set("isLoading", !store.isInfinite);
        const { isSuccess, data } = await articlesQuery(urlParams);
        if (isSuccess && data) {
            store.set(
                "items",
                [...(store.isInfinite ? store.items : []), ...data.items.map(item => new ArticleModel(item))]
            );
            store.set("pages", data.pages);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
        store.set("isInfinite", false);
    }, [store, urlParams]);

    useEffect(() => {
        fetchTags();
    }, [fetchTags]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    useEffect(() => {
        if (isMobile) {
            store.set("viewType", 'card');
        }
    }, [isMobile, store]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Статьи' backTo={ROUTES.LIBRARY()}>
                    {(!urlParams.isPrivate || +urlParams.isPrivate === 0) && (
                        <UiFilter
                            defaultItem={{
                                id: null,
                                name: 'Все статьи'
                            }}
                            value={urlParams.tagId}
                            items={store.tags}
                            onChange={({ value }) => {
                                navigate(ROUTES.ARTICLES(), {
                                    tagId: value
                                }, { replace: true })
                            }}
                        />
                    )}
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.viewType === 'card' && (
                        <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                            {lodash.chunk(store.items, columns).map((chunk, index) => (
                                <div key={index} className='articles-page__row'>
                                    <UiGrid columns={columns} style={{ maxWidth: 1152 }}>
                                        {chunk.map(article => (
                                            <ArticleItem key={article.id} item={article} viewType={store.viewType}/>
                                        ))}
                                    </UiGrid>
                                </div>
                            ))}
                        </UiGrid>
                    )}
                    {store.viewType === 'list' && (
                        <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                            {store.items.map(article => (
                                <ArticleItem key={article.id} item={article} viewType={store.viewType}/>
                            ))}
                        </UiGrid>
                    )}
                </UiDataBoundary>
                <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                    <UiPagination
                        page={urlParams.page}
                        pages={store.pages}
                        onChange={(data, isInfinite) => {
                            store.set("isInfinite", isInfinite);
                            navigate(ROUTES.ARTICLES(), {
                                ...urlParams,
                                page: data.value
                            })
                        }}
                    />
                </UiGrid>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
});
