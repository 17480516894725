import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

interface Props {
    value: number | null;
}

class UiNotificationCount extends React.Component<Props> {
    static defaultProps: Props = {
        value: null,
    };

    render() {
        const { value } = this.props;

        if (!value) {
            return null;
        }

        return <div className='ui-notification-count'>{value}</div>;
    }
}

export default observer(UiNotificationCount);
