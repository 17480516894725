import React from 'react';
import { observer } from 'mobx-react';
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { COLORS } from 'shared/constants';

import './styles.scss';

interface Props {
    size: number;
    time: number;
    onFinish?: () => void;
}

class UiCountdown extends React.Component<Props> {
    static defaultProps: Props = {
        size: 56,
        time: 30,
    };

    currentTime = 30;
    interval: number | undefined = undefined;

    constructor(props: Props) {
        super(props);
        this.currentTime = props.time;

        makeObservable(this, {
            currentTime: observable,
            progress: computed,
        });
    }

    componentDidMount = () => {
        window.clearInterval(this.interval);
        this.interval = undefined;

        this.interval = window.setInterval(() => {
            runInAction(() => {
                this.currentTime = this.currentTime - 1;
            });
            if (this.currentTime < 1) {
                this.handleFinish();
            }
        }, 1000);
    };

    componentWillUnmount = () => {
        window.clearInterval(this.interval);
        this.interval = undefined;
    };

    get progress() {
        const { time } = this.props;
        return (this.currentTime * 100) / time;
    }

    handleFinish = () => {
        const { onFinish } = this.props;
        window.clearInterval(this.interval);
        this.interval = undefined;

        onFinish && onFinish();
    };

    render() {
        const { size } = this.props;

        const strokeWidth = 2;
        const radius = (size - strokeWidth) / 2;
        const dashArray = radius * Math.PI * 2;
        const dashOffset = dashArray - (dashArray * this.progress) / 100;

        return (
            <div className='ui-countdown' style={{ width: size, height: size }}>
                <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                    <circle
                        fill='transparent'
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`}
                        stroke={COLORS.GRAY_4}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <circle
                        fill='transparent'
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`}
                        stroke={COLORS.BROWN_1}
                        strokeLinecap='square'
                        strokeLinejoin='round'
                        transform={`rotate(-90 ${size / 2} ${size / 2})`}
                        style={{
                            strokeDasharray: dashArray,
                            strokeDashoffset: -dashOffset,
                        }}
                    />
                </svg>
                <div className='ui-countdown__label'>{this.currentTime}</div>
            </div>
        );
    }
}

export default observer(UiCountdown);
