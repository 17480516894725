import { makeObservable, observable } from 'mobx';

import { Model } from '../Model';

export interface IBoardModel {
    id?: number;
    sort?: number;
    name?: string;
    spaceId?: number;
}

export class BoardModel extends Model implements IBoardModel {
    id = 0;
    name = '';
    sort = 0;
    spaceId = 0;
    isCollapsed: false;

    constructor(payload: IBoardModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            sort: observable,
            name: observable,
            spaceId: observable,
            isCollapsed: observable
        });

        this.update(payload);
    }
}
