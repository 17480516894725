import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { UiButton, UiDataBoundary, UiUser, UiWrap } from 'shared/uikit';
import { useNavigate, useRouteParams, useStore, useUserWidgets } from "shared/hooks";
import { chatRoomsCreateQuery, departmentBranchesGetQuery, departmentsGetQuery } from "shared/queries";
import { DepartmentBranchModel, DepartmentModel } from "shared/models";
import { ROUTES } from "shared/constants";
import { Notifier } from "shared/utilities";

import './styles.scss';

export const DepartmentUnitPage = observer(() => {
    const navigate = useNavigate();

    const { departmentId, departmentBranchId } = useRouteParams<{ departmentId: number, departmentBranchId: number }>();

    const store = useStore(() => ({
        isLoading: true,
        isSuccess: true,
        title: '',
        item: null as null | DepartmentModel | DepartmentBranchModel
    }))

    useEffect(() => {
        if (departmentId) {
            store.set("title", 'Департамент');
        } else if (departmentBranchId) {
            store.set("title", 'Отдел');
        }

        (async () => {
            store.set('isLoading', true);
            if (departmentId) {
                let { data, isSuccess } = await departmentsGetQuery({
                    id: departmentId
                })
                store.set('isSuccess', data && isSuccess);
                if (data && isSuccess) {
                    store.set('item', new DepartmentModel(data.item));
                }
            } else {
                let { data, isSuccess } = await departmentBranchesGetQuery({
                    id: departmentBranchId
                })
                store.set('isSuccess', data && isSuccess);
                if (data && isSuccess) {
                    store.set('item', new DepartmentBranchModel(data.item));
                }
            }
            store.set('isLoading', false);
        })();
    }, [store, departmentId, departmentBranchId]);

    const handleChatRoomCreate = useCallback(async (userId: number) => {
        const { isSuccess, data } = await chatRoomsCreateQuery({
            userId,
            typeId: 'direct'
        });
        if (isSuccess && data) {
            navigate(ROUTES.CHAT(data.item.id));
        }
    }, [navigate]);

    const users = useMemo(() => {
        const managementIds = store.item?.management.map(user => +user.id) || [];
        return store.item?.users.filter(user => !managementIds.includes(+user.id)) || [];
    }, [store.item]);

    const backTo = useMemo(() => {
        if (departmentBranchId && store.item && store.item?.departmentId) {
            return ROUTES.COMPANY_STRUCTURE() + `#department-${store.item?.departmentId}`;
        }
        return ROUTES.COMPANY_STRUCTURE() + `#department-${store.item?.id}`;
    }, [departmentBranchId, store.item]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle
                    backTo={backTo}
                    title={store.item?.name || store.title}
                />
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiWrap>
                        {store.item?.description && (
                            <div className='p-department-unit-description'>
                                <div className="p-department-unit-description__title">Описание</div>
                                <div className="p-department-unit-description__value">{store.item.description}</div>
                            </div>
                        )}
                        <table className='ui-table ui-table--with-user'>
                            <thead>
                                <tr>
                                    <th className={'ui-table-head-cell'}>Сотрудник</th>
                                    <th className={'ui-table-head-cell'}>Мобильный телефон</th>
                                    <th className={'ui-table-head-cell'}>Внутренний</th>
                                    <th className={'ui-table-head-cell'}>Почта</th>
                                    <th className={'ui-table-head-cell'}>Действие</th>
                                </tr>
                            </thead>
                            <tbody>
                                {store.item?.management.map(user => (
                                    <tr className={'ui-table-row ui-table-row--highlight'} key={user.id}>
                                        <td className={'ui-table-cell ui-table-user'} data-name='Сотрудник'><UiUser
                                            user={user} isManagement/></td>
                                        <td className={'ui-table-cell ui-table-cell--common'}
                                            data-name='Мобильный телефон' style={{ whiteSpace: 'nowrap' }}>
                                            {user.mobile && (<b>{user.mobile}</b>)}
                                        </td>
                                        <td className={'ui-table-cell ui-table-cell--common'} data-name='Внутренний'>
                                            {user.phone && (<b>{user.phone}</b>)}
                                        </td>
                                        <td className={'ui-table-cell ui-table-cell--common'}
                                            data-name='Почта'>{user.email}</td>
                                        <td className={'ui-table-cell ui-table-actions'}>
                                            <UiButton label='Написать' isSmall
                                                      onClick={() => handleChatRoomCreate(user.id)}/>
                                        </td>
                                    </tr>
                                ))}
                                {users.map(user => (
                                    <tr key={user.id} className={'ui-table-row'}>
                                        <td className={'ui-table-cell ui-table-user'} data-name='Сотрудник'><UiUser
                                            user={user}/></td>
                                        <td className={'ui-table-cell ui-table-cell--common'}
                                            data-name='Мобильный телефон' style={{ whiteSpace: 'nowrap' }}>
                                            {user.mobile && (<b>{user.mobile}</b>)}
                                        </td>
                                        <td className={'ui-table-cell ui-table-cell--common'} data-name='Внутренний'>
                                            {user.phone && (<b>{user.phone}</b>)}
                                        </td>
                                        <td className={'ui-table-cell ui-table-cell--common'}
                                            data-name='Почта'>{user.email}</td>
                                        <td className={'ui-table-cell ui-table-actions'}>
                                            <UiButton label='Написать' isSmall onClick={async () => {
                                                if (!user.isAvailable) {
                                                    const isConfirmed = await Notifier.confirm(
                                                        user.unavailableText,
                                                        'Сотрудник не доступен и не сможет ответить вам сразу',
                                                        'Написать'
                                                    );
                                                    if (!isConfirmed) {
                                                        return;
                                                    }
                                                }
                                                handleChatRoomCreate(user.id)
                                            }}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </UiWrap>
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
})

