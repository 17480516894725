import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

type PropsType = {
    children: React.ReactNode
}

export const UiCardFooter = observer(({children}: PropsType) => {
    return (
        <div className='ui-card-footer'>{children}</div>
    )
})
