import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IEducationCardModel {
    id?: number;
    name?: string;
    url?: string;
    icon?: string;
    details?: string;
    color?: string;
}

export class EducationCardModel extends Model implements IEducationCardModel {

    id = 0;
    name = '';
    details = '';
    url = '';
    icon = '';
    color = '';

    constructor(payload: IEducationCardModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            url: observable,
            color: observable,
            details: observable,
            icon: observable,
            update: action,
        });

        this.update(payload);
    }
}
