export const ASSETS = {
    book1: require('./assets/book1.jpg'),
    book2: require('./assets/book2.jpg'),
    book3: require('./assets/book3.jpg'),
    book4: require('./assets/book4.jpg'),
    book5: require('./assets/book5.jpg'),
    book6: require('./assets/book6.jpg'),
    book7: require('./assets/book7.jpg'),
    book8: require('./assets/book8.jpg'),
    book9: require('./assets/book9.jpg'),
    book10: require('./assets/book10.jpg'),
    book11: require('./assets/book11.jpg'),
    book12: require('./assets/book12.jpg'),
    book13: require('./assets/book13.jpg'),
    book14: require('./assets/book14.jpg'),
    book15: require('./assets/book15.jpg'),
    book16: require('./assets/book16.jpg'),
    book17: require('./assets/book17.jpg'),
    book18: require('./assets/book18.jpg'),
    card1: require('./assets/card1.jpg'),
    card2: require('./assets/card2.jpg'),
    card3: require('./assets/card3.jpg'),
    hbook1: require('./assets/hbook1.png'),
    hbook2: require('./assets/hbook2.png'),
    hbook3: require('./assets/hbook3.png'),
    hbook4: require('./assets/hbook4.png'),
}
