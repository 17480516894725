import lodash from "lodash";

export const transformObjectKeys = (data: any, transformKey = lodash.camelCase) => {
    if (data === null || data === undefined) {
        return null;
    }

    let result: any;
    if (Array.isArray(data)) {
        result = data.map(item => transformObjectKeys(item, transformKey));
    } else if (typeof data === 'object') {
        result = {};
        for (const [key, value] of Object.entries(data)) {
            result[lodash.startsWith(key, '_') ? key : transformKey(key)] = transformObjectKeys(value, transformKey);
        }
    } else {
        result = data;
    }
    return result;
};
