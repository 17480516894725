import { makeQuery } from 'shared/utilities';
import { IArticleModel } from 'shared/models';

type RequestType = {
    page?: number,
    perPage?: number,
    isPrivate?: number | null,
    tagId?: number | null
};

type ResponseType = {
    items: IArticleModel[];
    page: number;
    pages: number
};

export const articlesQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/articles', { params });
}
