import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { computed, makeObservable } from 'mobx';

import { OnChangeEventType } from 'shared/types';
import { ICONS, COLORS } from 'shared/constants';
import { Resolution } from 'shared/utilities';

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    page: number;
    pages: number;
    isLoading: boolean;
    name: string;
    onChange?: (data: OnChangeEventType<number>, isInfinite: boolean) => void;
}

class UiPagination extends React.Component<PropsType> {
    static defaultProps: PropsType = {
        page: 1,
        pages: 1,
        isLoading: false,
        name: 'page',
    };

    constructor(props: PropsType) {
        super(props);

        makeObservable(this, {
            pages: computed,
            hasDoubleArrows: computed,
            rowPages: computed,
            preferredPagePosition: computed,
        });
    }

    get rowPages() {
        if (Resolution.isMobile) {
            return 3;
        }

        if (Resolution.isTablet) {
            return 5;
        }
        return 10;
    }

    get preferredPagePosition() {
        if (Resolution.isMobile) {
            return 2;
        }
        if (Resolution.isTablet) {
            return 3;
        }
        return 7;
    }

    get pages() {
        const { page, pages } = this.props;

        const result: number[] = [];

        //calculations
        let rightOffset = this.rowPages - this.preferredPagePosition;

        let rightPadding = pages - page < rightOffset ? pages - page : rightOffset;
        let leftPadding = this.rowPages - rightPadding - 1;

        let from = page - leftPadding > 0 ? page - leftPadding : 1;
        let to = page + rightPadding > this.rowPages ? page + rightPadding : this.rowPages;
        to = to > pages ? pages : to;

        for (let i = from; i <= to; i++) {
            result.push(i);
        }

        return result;
    }

    get hasDoubleArrows() {
        const { pages } = this.props;

        return !Resolution.isMobile && pages > this.rowPages;
    }

    handleChange = (value: number, isInfinite = false) => () => {
        const { onChange, pages, name, page } = this.props;

        if (+page === +value || value < 1 || value > pages) {
            return;
        }

        onChange && onChange({ name, value }, isInfinite);
    };

    getClassName = (n: number) => {
        const { page } = this.props;

        return classnames('ui-pagination__page', {
            'ui-pagination__page--active': n === page,
        });
    };

    render() {
        const { page, pages } = this.props;

        if (pages < 2) {
            return null;
        }

        return (
            <div className='ui-pagination'>
                <div className='ui-pagination__pages'>
                    {this.hasDoubleArrows && (
                        <div className='ui-pagination__page' onClick={this.handleChange(1)}>
                            <UiIcon color={COLORS.BLACK} size={16} icon={ICONS.CHEVRON_DOUBLE_LEFT}/>
                        </div>
                    )}
                    <div className='ui-pagination__page' onClick={this.handleChange(page - 1)}>
                        <UiIcon color={COLORS.BLACK} size={16} icon={ICONS.CHEVRON_LEFT}/>
                    </div>
                    {this.pages.map(n => (
                        <div key={n} className={this.getClassName(n)} onClick={this.handleChange(n)}>
                            {n}
                        </div>
                    ))}
                    <div className='ui-pagination__page' onClick={this.handleChange(page + 1)}>
                        <UiIcon color={COLORS.BLACK} size={16} icon={ICONS.CHEVRON_RIGHT}/>
                    </div>
                    {this.hasDoubleArrows && (
                        <div className='ui-pagination__page' onClick={this.handleChange(pages)}>
                            <UiIcon color={COLORS.BLACK} size={16} icon={ICONS.CHEVRON_DOUBLE_RIGHT}/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default observer(UiPagination);
