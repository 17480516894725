import { action, computed, makeObservable, observable } from 'mobx';

import { AnnouncementTypeEnum } from "shared/enums";
import { ThumbnailType } from "shared/types";

import { DepartmentModel, IDepartmentModel } from './DepartmentModel';
import { Model } from './Model';
import { IAnnouncementCategoryModel } from "shared/models/AnnouncementCategoryModel";
import { ModelCast } from "shared/casts";
import { IUserModel, UserModel } from "shared/models/UserModel";

export interface IAnnouncementModel {
    id?: number;
    createdAt?: string;
    typeId?: string;
    announcementCategoryId?: number;
    announcementCategory?: IAnnouncementCategoryModel | null
    name?: string;
    previewImageUrl?: string;
    previewImageThumbnail?: ThumbnailType;
    detailImageUrl?: string;
    previewText?: string;
    detailText?: string;
    departmentId?: number;
    department?: IDepartmentModel | null;
    user?: IUserModel | null;
    imageThumbnail?: string;
    imagesThumbnails?: string[];
    images?: string[];
    liked?: number;
    likesCount?: number;
    chatMessageArticlesCount?: number;
}

export class AnnouncementModel extends Model implements IAnnouncementModel {
    casts = {
        announcementCategory: new ModelCast(AnnouncementModel),
        user: new ModelCast(UserModel),
    }
    id = 0;
    createdAt = '';
    name = '';
    typeId = ''
    announcementCategoryId = 0;
    announcementCategory: AnnouncementModel | null = null;
    previewImageUrl = '';
    detailImageUrl = '';
    previewText = '';
    detailText = '';
    departmentId = 0;
    previewImageThumbnail: ThumbnailType = null;
    imageThumbnail: string = '';
    imagesThumbnails: string[] = [];
    images: string[] = [];
    department: DepartmentModel | null = null;
    user: UserModel | null = null;
    liked = 0;
    likesCount = 0;
    chatMessageArticlesCount = 0;

    constructor(payload: IAnnouncementModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            name: observable,
            typeId: observable,
            announcementCategoryId: observable,
            previewImageUrl: observable,
            detailImageUrl: observable,
            announcementCategory: observable,
            previewText: observable,
            detailText: observable,
            departmentId: observable,
            department: observable,
            imageThumbnail: observable,
            imagesThumbnails: observable,
            images: observable,
            previewImageThumbnail: observable,
            liked: observable,
            user: observable,
            likesCount: observable,
            chatMessageArticlesCount: observable,
            tag: computed,
            type: computed,
            update: action,
        });

        this.update(payload);
    }

    get type() {
        return AnnouncementTypeEnum.fromValue(this.typeId);
    }

    get tag() {
        return AnnouncementTypeEnum.items.find(item => item.id === this.typeId);
    }
}
