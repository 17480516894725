import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useMedia, useNavigate, useUrlParams } from "shared/hooks";
import { OnChangeEventType } from "shared/types";
import { UiDataBoundary, UiFilter, UiGrid } from "shared/uikit";
import { ApplicationModule } from "shared/modules";
import { ROUTES } from "shared/constants";

import { IspringCourseItem } from "./components/IspringCourseItem";

import Store from './Store';
import Service from './Service';

export const IspringCoursesPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
        typeId: null,
    });

    useEffect(() => {
        ApplicationModule.setTitle('Учебные курсы');
    })

    useEffect(() => {
        Service.fetchItems();
    }, [urlParams.page]);

    const handleChangeTypeId = ({ value }: OnChangeEventType) => {
        navigate(ROUTES.ISPRING_COURSES(), {
            typeId: value,
        });
    };

    const handleAction = () => {
        if (Store.errorCode === 400) {
            Service.fetchItems()
        }
        if (Store.errorCode === 403) {
            navigate(ROUTES.PROFILE());
        }
    }

    const { value: columns } = useMedia({
        is320: 1,
        is768: 2,
        is1280: 3,
        is1920: 4
    });

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={'Учебные курсы'} widgets={false}>
                    <UiFilter
                        items={Store.ispringCourseTypes}
                        value={urlParams.typeId}
                        onChange={handleChangeTypeId}
                    />
                </LayoutContentTitle>
                <UiDataBoundary
                    className='ispring-courses-page__list'
                    isLoading={Store.isLoading && !Store.isInfiniteLoading}
                    isError={!Store.isSuccess}
                    errorMessage={Store.errorMessage}
                    actionText={Store.errorCode === 403 ? 'Перейти в личный кабинет' : 'Обновить'}
                    onAction={handleAction}
                >
                    {Store.items.length === 0 && (
                        <UiGrid columns={1} style={{maxWidth: 1250}}>
                            <h3>Вам не назначено никаких курсов.</h3>
                        </UiGrid>
                    )}
                    {Store.items.length > 0 && (
                        <UiGrid columns={columns} gap={[25, 50]} style={{maxWidth: 1250}}>
                            {Store.items.filter(item => urlParams.typeId ? item.type === urlParams.typeId : true).map(item => (
                                <IspringCourseItem key={item.id} item={item}/>
                            ))}
                        </UiGrid>
                    )}
                </UiDataBoundary>
            </LayoutContent>
        </LayoutBody>
    );
});
