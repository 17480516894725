import { makeQuery } from 'shared/utilities';
import { ICalendarItemModel } from "shared/models";

type RequestType = {
    dateFrom: string,
    dateTo: string,
    withBirthdays?: number
};

type ResponseType = {
    items: ICalendarItemModel[];
};

export const calendarItemQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/calendar-item', { params });
}
