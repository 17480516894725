import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from "mobx";

import { EducationLessonModel } from "shared/models";
import { useMedia, useStore, useValidation } from "shared/hooks";
import {
    UiButton,
    UiDataBoundary,
    UiForm,
    UiFormControl,
    UiGrid,
    UiHtml,
    UiInput,
    UiModal, UiModalClose,
    UiModalTitle,
    UiTextarea
} from "shared/uikit";
import { educationLessonApplicationsCreateQuery, educationLessonsGetQuery } from "shared/queries";

import './index.scss';
import classnames from "classnames";

type PropsType = {
    id?: number | null,
    isOpened?: boolean
    onClose: () => void
}

export const CEducationLesson = observer(({ isOpened, onClose, id }: PropsType) => {
    const store = useStore(() => ({
        isLoading: false,
        isSubmitted: false,
        isApplied: false,
        isSubmitting: false,
        educationLesson: new EducationLessonModel(),
        modules: [] as string[]
    }));

    const form = useStore(() => ({
        phone: '',
        email: '',
        comment: '',
        module: ''
    }));

    const { isMobile } = useMedia();

    useEffect(() => {
        if (!id) {
            return;
        }
        store.set("isLoading", true);
        (async () => {
            const { data, isSuccess } = await educationLessonsGetQuery({ id })
            if (data && isSuccess) {
                store.set("educationLesson", new EducationLessonModel(data.item));
            }
            store.set("isLoading", false);
        })();
    }, [store, id])

    const validation = useValidation(form, (rules) => ({
        phone: rules.required(),
        email: rules.email(),
    }));

    const handleSubmit = async () => {
        store.set('isSubmitted', true);
        if (store.isSubmitting || !validation.isValid) {
            return
        }

        store.set('isSubmitting', true);
        await educationLessonApplicationsCreateQuery({
            ...toJS(form),
            educationLessonId: store.educationLesson.id
        });
        store.set('isSubmitting', false);
        store.set('isSubmitted', false);
        store.set('isApplied', true);
        form.reset();
    }

    return (
        <UiModal isOpened={isOpened} onClose={onClose} className={'c-education-lesson'}>
            <UiDataBoundary isLoading={store.isLoading}>
                <UiModalClose onClick={onClose}/>
                {!!store.educationLesson.imageThumbnail && (
                    <div
                        className="c-education-lesson__image"
                        style={{ backgroundImage: `url(${store.educationLesson.imageThumbnail})` }}
                    />
                )}
                <div className="c-education-lesson__tags">
                    {!!store.educationLesson.isOnline && (
                        <div className="c-education-lesson-tag">
                            <div className="c-education-lesson-tag__marker" style={{ background: '#0B5CFF' }}/>
                            <div className="c-education-lesson-tag__name">Онлайн</div>
                        </div>
                    )}
                    {!!store.educationLesson.isOffline && (
                        <div className="c-education-lesson-tag">
                            <div className="c-education-lesson-tag__marker" style={{ background: '#20BF6B' }}/>
                            <div className="c-education-lesson-tag__name">Очная</div>
                        </div>
                    )}
                    {!!store.educationLesson.isIspring && (
                        <div className="c-education-lesson-tag">
                            <div className="c-education-lesson-tag__marker" style={{ background: '#CF1259' }}/>
                            <div className="c-education-lesson-tag__name">ISpring</div>
                        </div>
                    )}
                    {store.educationLesson.tags.map(tag => (
                        <div key={tag.id} className="c-education-lesson-tag">
                            <div className="c-education-lesson-tag__name">{tag.name}</div>
                        </div>
                    ))}
                </div>
                <div className="c-education-lesson__name">{store.educationLesson.name}</div>
                <UiHtml className={'typography typography--light'} value={store.educationLesson.descriptionPreview}/>

                <UiForm className={'c-education-lesson-form'} onSubmit={handleSubmit}>
                    {store.isApplied && (
                        <div className="c-education-lesson-form__applied">
                            Ваша запись успешно принята!
                        </div>
                    )}
                    {!store.isApplied && (
                        <>
                            <div className="c-education-lesson-form__title">Запишитесь на мероприятие</div>
                            <UiGrid columns={isMobile ? 1 : 2} gap={16}>
                                <UiFormControl
                                    label={'Телефон'}
                                    isSubmitted={store.isSubmitted}
                                    isRequired
                                    errorMessage={validation.phone.errorMessage}
                                >
                                    <UiInput
                                        value={form.phone}
                                        name={'phone'}
                                        placeholder={'+7(___) ___-__-__'}
                                        onChange={form.handleChange}
                                    />
                                </UiFormControl>

                                <UiFormControl
                                    label={'Email'}
                                    isSubmitted={store.isSubmitted}
                                    isRequired
                                    errorMessage={validation.email.errorMessage}
                                >
                                    <UiInput
                                        value={form.email}
                                        name={'email'}
                                        placeholder={'Введите email'}
                                        onChange={form.handleChange}
                                    />
                                </UiFormControl>
                            </UiGrid>
                            <UiFormControl label={'Комментарий'}>
                                <UiTextarea
                                    value={form.comment}
                                    name={'comment'}
                                    placeholder={'Введите комментарий'}
                                    onChange={form.handleChange}
                                />
                            </UiFormControl>
                            {(!!store.educationLesson.modules && store.educationLesson.modules.length > 0) && (
                                <div className="c-education-lesson-modules">
                                    <div className="c-education-lesson-modules__title">
                                        Выберите интересующие вас разделы
                                    </div>
                                    <div className="c-education-lesson-modules__items">
                                        {store.educationLesson.modules.map((module, index) => {
                                            const isActive = store.modules.includes(module.name)
                                            const classNames = classnames('c-education-lesson-modules__item', {
                                                'c-education-lesson-modules__item--active': isActive
                                            });

                                            return (
                                                <div
                                                    key={index}
                                                    className={classNames}
                                                    onClick={() => {
                                                        if (isActive) {
                                                            store.set("modules", store.modules.filter(item => item !== module.name))
                                                        } else {
                                                            store.set("modules", [...store.modules, module.name])
                                                        }
                                                        form.set("module", store.modules.join(', '))
                                                    }}
                                                >
                                                    {module.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                            <div className="c-education-lesson-form__footer">
                                <UiButton isSubmit label={'Записаться'}/>
                            </div>
                        </>
                    )}
                </UiForm>
                {store.educationLesson.ispringUrl && (
                    <UiButton
                        className={'c-education-lesson__to-ispring'}
                        label={'Посмотреть курс в Ispring'}
                        onClick={() => {
                            window.open(store.educationLesson.ispringUrl, '_blank')?.focus();
                        }}
                    />
                )}
                <UiHtml className={'typography typography--light'} value={store.educationLesson.description}/>
            </UiDataBoundary>
        </UiModal>
    )
});
