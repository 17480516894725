import { makeQuery, transformObjectKeys } from 'shared/utilities';
import { ITaskModel } from "shared/models/TaskTracker";
import { toJS } from "mobx";
import lodash from "lodash";

type RequestType = ITaskModel & { newFiles?: File[] };

type ResponseType = {
    item: ITaskModel
};

export const tasksSaveQuery = async function (request: RequestType) {
    const { newFiles, ...task } = request;
    const formData = new FormData();
    formData.append('json', JSON.stringify(
        transformObjectKeys(toJS(task), lodash.snakeCase)
    ));

    newFiles && newFiles.forEach((file) => {
        formData.append('files[]', file);
    });

    return await makeQuery<ResponseType>('/tasks/save', {
        prefix: 'task-tracker',
        formData
    });
}
