import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';

type ResponseType = {
    items: IUserModel[];
};

export const usersNewQuery = async function () {
    return await makeQuery<ResponseType>('/users/new');
}
