import React from 'react';
import { observer } from 'mobx-react';

import { DocumentCategoryModel } from 'shared/models';
import { ROUTES } from "shared/constants";
import { UiNavLink } from "shared/uikit";

import defaultImage from 'assets/images/documents/category.svg';

import './styles.scss';

type PropsType = {
    item: DocumentCategoryModel;
    type: string
}

export const DocumentCategoryItem = observer(({item, type}: PropsType) => {

    return (
        <UiNavLink
            to={ROUTES.DOCUMENT_CATEGORY(item.id)}
            className={`document-category-item document-category-item--${type}`}
        >
            <img
                alt=''
                className="document-category-item__image"
                src={item.image ? item.image : defaultImage}
            />
            <div className="document-category-item__name">{item.name}</div>
        </UiNavLink>
    )
})
