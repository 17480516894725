import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { UiGrid, UiIcon, UiNavLink } from 'shared/uikit';
import { useMedia, useStore } from "shared/hooks";
import { DepartmentModel } from "shared/models";
import { COLORS, ICONS, ROUTES } from "shared/constants";

type PropsType = {
    department: DepartmentModel
}

export const Department = observer(({ department }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false,
    }))

    const { isMobileOrTablet } = useMedia();

    const domId = `department-${department.id}`;

    useEffect(() => {
        if (domId === window.location.hash.slice(1)) {
            store.set("isOpened", true);
            setTimeout(() => {
                document.getElementById(domId)?.scrollIntoView();
            }, 150);
        }
    }, [department, domId, store])

    const columns = useMemo(() => {
        if (isMobileOrTablet) {
            return 1;
        }
        let columns = department.departmentGroups.length;
        if (department.departmentBranches.length > 0) {
            columns += 1;
        }
        if (columns < 5) {
            columns = 5;
        }
        return columns;
    }, [isMobileOrTablet, department.departmentGroups.length, department.departmentBranches.length]);

    let content = useMemo(() => {
        if (department.departmentGroups.length > 0) {
            return (
                <UiGrid className='p-company-structure-department-groups' gap={[8, 0]} columns={columns}>
                    {department.departmentGroups.map(group => (
                        <div key={group.id} className="p-company-structure-department-groups__cell">
                            <div className="p-company-structure-department-group">{group.name}</div>
                            {group.departmentBranches.map(branch => (
                                <UiNavLink
                                    key={branch.id}
                                    to={ROUTES.DEPARTMENT_BRANCH(branch.id)}
                                    className="p-company-structure-department-branch"
                                >
                                    {branch.name}
                                </UiNavLink>
                            ))}
                        </div>
                    ))}
                    {department.departmentBranches.length > 0 && (
                        <div className="p-company-structure-department-groups__cell">
                            {department.departmentBranches.map(branch => (
                                <UiNavLink
                                    key={branch.id}
                                    to={ROUTES.DEPARTMENT_BRANCH(branch.id)}
                                    className="p-company-structure-department-branch"
                                >
                                    {branch.name}
                                </UiNavLink>
                            ))}
                        </div>
                    )}
                </UiGrid>
            )
        }
        if (department.departmentBranches.length > 0) {
            return (
                <div className="p-company-structure-department-branches">
                    {department.departmentBranches.map(branch => (
                        <div key={branch.id} className="p-company-structure-department-branches__cell">
                            <UiNavLink
                                key={branch.id}
                                to={ROUTES.DEPARTMENT_BRANCH(branch.id)}
                                className="p-company-structure-department-branch"
                            >
                                {branch.name}
                            </UiNavLink>
                        </div>
                    ))}
                </div>
            )
        }
        return null;
    }, [department, columns]);

    return (
        <div className="p-company-structure-department" id={domId}>
            <div className="p-company-structure-department__header" onClick={() => store.toggle("isOpened")}>
                <UiNavLink
                    to={ROUTES.DEPARTMENT(department.id)}
                    className="p-company-structure-department__name"
                >
                    {department.name}
                </UiNavLink>
                {!!content && (
                    <UiIcon
                        icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                        size={20}
                        color={COLORS.BLACK}
                    />
                )}
            </div>
            {store.isOpened && !!content && (
                <div className="p-company-structure-department__inner">
                    {content}
                </div>
            )}
        </div>
    );
})
