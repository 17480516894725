import { makeQuery } from 'shared/utilities';
import { IVacancyRegionModel } from 'shared/models';

type RequestType = {
    vacancyId: number;
    username: string;
    description: string;
    tpmFileId?: number;
};

type ResponseType = {
    pages: number;
    items: IVacancyRegionModel[];
};

export const vacanciesRespondCreateQuery = async function (json: RequestType) {
    return await makeQuery<ResponseType>('/vacancy-responds/create', { json });
}
