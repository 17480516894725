import { makeQuery } from 'shared/utilities';

type RequestType = {
    moduleId?: string
}

type ResponseType = {
    count: number;
};

export const notificationsUnreadCountQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/notifications/unread-count', {
        params
    });
}
