import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import './styles.scss';

type PropsType = {
    isWide?: boolean;
    isFill?: boolean;
    isLoading?: boolean;
    style?: React.CSSProperties;
    styleInner?: React.CSSProperties;
    className?: string;
    children?: React.ReactNode,
}

export const LayoutContent = observer(({ children, style, className }: PropsType) => {
    return (
        <div className={classnames('layout-content', className)} style={style}>
            {children}
        </div>
    );
})
