import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { useStore } from "shared/hooks";
import { ChatRoomModel, UserModel } from "shared/models";
import { OnChangeHandlerType } from "shared/types";
import { COLORS, ICONS } from "shared/constants";
import { chatRoomsQuery, usersQuery } from "shared/queries";
import { ChatRoomTypeEnum } from "shared/enums";

import { UiDataBoundary } from "../UiDataBoundary";
import { UiScrollbar } from "../UiScrollbar";
import { UiAvatar } from "../UiAvatar";
import { UiIcon } from "../UiIcon";

import './index.scss';

type ValueType = { userId?: number | null, chatRoomId?: number | null };
type PropsType = {
    value?: ValueType,
    onChange?: OnChangeHandlerType<ValueType>,
    name?: string;
    style?: React.CSSProperties
}

export const UiChatRoomSelect = observer(({ value = {userId: null, chatRoomId: null}, name = 'ui-chat-room-select', onChange, style }: PropsType) => {
    const store = useStore(() => ({
        search: '',
        chatRooms: [] as ChatRoomModel[],
        users: [] as UserModel[],
        isLoading: true,
        isSuccess: false
    }));

    useEffect(() => {
        const getUsers = async () => {
            const { isSuccess, data } = await usersQuery();
            if (isSuccess && data) {
                store.set("users", data.items.map(item => new UserModel(item)));
            }
        }
        const getChatRooms = async () => {
            const { isSuccess, data } = await chatRoomsQuery();
            if (isSuccess && data) {
                store.set("chatRooms", data.items.map(item => new ChatRoomModel(item)));
            }
            store.set('isSuccess', isSuccess && !!data);
        }
        (async () => {
            await getUsers();
            await getChatRooms();
            store.set('isLoading', false);
        })();
    }, [store]);

    const handleChange = (newValue: ValueType) => () => {
        onChange && onChange({
            name,
            value: {
                userId: null,
                chatRoomId: null,
                ...newValue
            },
        })
    }

    const chatRooms = store.chatRooms.filter(chatRoom => chatRoom.previewName.toLowerCase().includes(store.search.toLowerCase()));

    const directUsers = store.chatRooms
        .filter(chatRoom => chatRoom.typeId === ChatRoomTypeEnum.Direct)
        .reduce((result: number[], chatRoom) => {
            return [...result, ...chatRoom.chatRoomUsers.map(chatRoomUsers => chatRoomUsers.userId)];
        }, []);

    const users = store.users
        .filter(user => !directUsers.includes(user.id))
        .filter(user => user.previewName.toLowerCase().includes(store.search.toLowerCase()));

    return (
        <div className="ui-chat-room-select" style={style}>
            <div className="ui-chat-room-select__header">
                <div className="ui-chat-room-select__icon">
                    <UiIcon icon={ICONS.SEARCH} color={COLORS.GRAY_3} size={16}/>
                </div>
                <input
                    className="ui-chat-room-select__input"
                    placeholder=' '
                    value={store.search}
                    onChange={(e) => store.set("search", e.target.value)}
                />
                <div className="ui-chat-room-select__placeholder">Поиск</div>
            </div>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <UiScrollbar maxHeight={300}>
                    <div className="ui-chat-room-select__items">
                        {chatRooms.map(chatRoom => {
                            const classNames = classnames('ui-chat-room-select__item', {
                                'ui-chat-room-select__item--active': value.chatRoomId === chatRoom.id
                            });
                            return (
                                <div
                                    key={chatRoom.id}
                                    onClick={handleChange({ chatRoomId: chatRoom.id })}
                                    className={classNames}
                                >
                                    <UiAvatar image={chatRoom.previewImage} label={chatRoom.previewName} size={24}/>
                                    <span>{chatRoom.previewName}</span>
                                </div>
                            )
                        })}
                        {users.map(user => {
                            const classNames = classnames('ui-chat-room-select__item', {
                                'ui-chat-room-select__item--active': value.userId === user.id
                            });
                            return (
                                <div
                                    key={user.id}
                                    onClick={handleChange({ userId: user.id })}
                                    className={classNames}
                                >
                                    <UiAvatar image={user.imageAvatar} label={user.previewName} size={24}/>
                                    <span>{user.previewName}</span>
                                </div>
                            )
                        })}
                    </div>
                </UiScrollbar>
            </UiDataBoundary>
        </div>
    );
})

