import { makeAutoObservable } from "mobx";

class LayoutModuleClass {
    menu = {
        isOpened: false,
        setOpened: function (value = false) {
            this.isOpened = value;
        }
    }

    aside = {
        isOpened: false,
        setOpened: function (value = false) {
            this.isOpened = value;
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}

export const LayoutModule = new LayoutModuleClass();
