import { makeQuery } from 'shared/utilities';
import { IHistoryItemModel } from 'shared/models';

type ResponseType = {
    items: IHistoryItemModel[];
};

export const historyItemsQuery = async function () {
    return await makeQuery<ResponseType>('/history-items');
}
