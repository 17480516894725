import React, { useEffect } from 'react';
import { observer } from "mobx-react";

import { LayoutBody, LayoutContent } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiButton } from 'shared/uikit';
import { ROUTES } from 'shared/constants';
import { useNavigate } from "shared/hooks";

import { Icon } from './icon';

import './styles.scss';

export const NotfoundPage = observer(() => {
    const navigate = useNavigate();
    useEffect(() => {
        ApplicationModule.setTitle('Ошибка 404');
    });

    const handleClick = () => navigate(ROUTES.HOME());

    return (
        <LayoutBody>
            <LayoutContent isFill>
                <div className='page-not-found'>
                    <div className='page-not-found__code'>
                        <span>4</span>
                        <Icon/>
                        <span>4</span>
                    </div>
                    <div className='page-not-found__title'>Страница не найдена :(</div>
                    <div className='page-not-found__description'>
                        Данная страница удалена, либо никогда не существовала. Вы можете вернуться назад и
                        попробовать найти нужную страницу еще раз
                    </div>
                    <UiButton
                        onClick={handleClick}
                        label='Вернуться на главную'
                        style={{ width: 220 }}
                    />
                </div>
            </LayoutContent>
        </LayoutBody>

    )
})
