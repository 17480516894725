import { makeQuery } from 'shared/utilities';
import { INewsModel } from 'shared/models';

type RequestType = {
    page?: number;
    tagId?: number | null;
    excludeId?: number[];
}

type ResponseType = {
    pages: number;
    items: INewsModel[];
};

export const newsQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/news', { params });
}
