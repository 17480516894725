import { getBackendHost } from "./getBackendHost";

export const getBackendUrl = (url = '') => {
    const host = getBackendHost();
    let port = 3000;
    if (window.location.protocol === 'htts:') {
        port = 3443;
    }
    if (+window.location.port === +'55080') {
        port = 55300;
    }
    return `${window.location.protocol}//${host}:${port}${url}`;
};
