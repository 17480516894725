import classnames from "classnames";
import copyToClipBoard from "copy-to-clipboard";
import hexToRgba from "hex-to-rgba";
import { runInAction } from "mobx";
import { observer } from 'mobx-react';
import moment from "moment-timezone";
import React, { useEffect } from 'react';

import { CComments } from "shared/components";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { TaskTrackerTaskStatusEnum } from "shared/enums";
import { useMedia, useNavigate, useStore, useUrlParams } from "shared/hooks";
import { UserModel } from "shared/models";
import { TaskModel } from "shared/models/TaskTracker";
import { ApplicationModule } from "shared/modules";
import { tasksDeleteQuery, tasksGetQuery, tasksProposeAcceptQuery, tasksProposeQuery, tasksProposeRejectQuery, tasksUpdateQuery } from "shared/queries/TaskTracker";
import { OnChangeHandlerType } from "shared/types";
import { UiAvatar, UiButton, UiDataBoundary, UiDatePicker, UiFormControl, UiGrid, UiIcon, UiInput, UiModal, UiNavLink, UiTimePicker } from "shared/uikit";
import { Notifier } from "shared/utilities";

import { CTaskFile } from "../CTaskFile";

import './index.scss';

type PropsType = {
    id?: number | null,
    onUpdate?: (task: TaskModel) => void,
    onDelete?: (task: TaskModel) => void,
}

export const CTaskPreview = observer((
    {
        id,
        onUpdate = () => undefined,
        onDelete = () => undefined
    }: PropsType
) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({});
    const { is320 } = useMedia();

    const store = useStore(() => ({
        isCopied: false,
        isProposing: false,
        isLoading: true,
        task: new TaskModel(),
    }));

    const propose = useStore(() => ({
        expiredAtPropose: moment().format() as string | null,
        expiredAtProposeComment: null as string | null,
        get expiredAtProposeMoment() {
            if (!this.expiredAtPropose) {
                return moment();
            }

            return moment(this.expiredAtPropose);
        },

        get expiredAtProposeTime() {
            return this.expiredAtProposeMoment.clone().format('HH:mm');
        },

        set expiredAtProposeTime(value) {
            runInAction(() => {
                const [hours, minutes] = value.split(':');
                this.expiredAtPropose = this.expiredAtProposeMoment.clone().set('hours', +hours).set('minutes', +minutes).format();
            })
        },
    }));

    useEffect(() => {
        if (!id) {
            return;
        }
        store.set("isLoading", true);
        (async () => {
            const { isSuccess, data } = await tasksGetQuery({ id });
            if (isSuccess && data) {
                store.set('task', new TaskModel(data.item));
            }
            setTimeout(() => store.set("isLoading", false), 250);
        })();
    }, [store, id]);

    const handleClose = () => {
        navigate(null, {
            ...urlParams,
            previewTaskId: null
        }, {}, null);
    }

    const handleUpdateStatus = async (statusId: string | null) => {
        if (!statusId) {
            return;
        }
        store.task.update({
            statusId
        });
        const { isSuccess, data } = await tasksUpdateQuery({
            id: store.task.id,
            statusId,
            ...(
                statusId === TaskTrackerTaskStatusEnum.Done.id ? { doneAt: moment().utc().format('YYYY-MM-DD HH:mm:ss') } : {}
            )
        });
        if (isSuccess && data) {
            onUpdate(new TaskModel(data.item));
        }
    }

    const handleDelete = async () => {
        const result = await Notifier.confirm(
            `Вы точно хотите удалить задачу «${store.task.name}»?`,
            <>Отменить это действие буде невозможно! <br/>Восстановить данные не получится!</>
        );
        if (!result) {
            return;
        }

        tasksDeleteQuery({
            id: store.task.id
        });

        onDelete(store.task);
        handleClose();
    }

    const handleCopyLinkToSpace = () => {
        copyToClipBoard(window.location.origin + ROUTES.TASK_TRACKER(store.task.spaceId));
        store.set("isCopied", true);
        setTimeout(() => {
            store.set("isCopied", false)
        }, 2000);
    }

    const handleChange: OnChangeHandlerType<any> = (data) => {
        runInAction(() => {
            propose.set(data.name as any, data.value);
        });
    }

    const handlePropose = () => {
        propose.set("expiredAtPropose", store.task.expiredAtPropose
            ? moment(store.task.expiredAtPropose).utc().format()
            : moment().set("hours", 19).set("minutes", 0).add(1, 'day').utc().format()
        );
        propose.set("expiredAtProposeComment", store.task.expiredAtProposeComment);
        store.set("isProposing", true);
    }

    const submitPropose = async () => {
        const { isSuccess, data } = await tasksProposeQuery({
            id: store.task.id,
            expiredAtPropose: moment(propose.expiredAtPropose).utc().format(),
            expiredAtProposeComment: propose.expiredAtProposeComment
        });

        if (!isSuccess || !data) {
            return;
        }
        store.task.update(data.item);

        store.set("isProposing", false);
    }

    const cancelPropose = async () => {
        propose.set("expiredAtPropose", null);
        propose.set("expiredAtProposeComment", null);
        store.set("isProposing", false);
    }

    const handleProposeAccept = async () => {
        const { isSuccess, data } = await tasksProposeAcceptQuery({
            id: store.task.id
        });
        if (isSuccess && data) {
            store.task.update(data.item)
        }
    }

    const handleProposeReject = async () => {
        const { isSuccess, data } = await tasksProposeRejectQuery({
            id: store.task.id
        });
        if (isSuccess && data) {
            store.task.update(data.item)
        }
    }


    return (
        <UiModal
            isAside
            styleBody={{ width: 1000 }}
            isOpened={!!id}
            className={'c-tt-task-preview'}
            onClose={handleClose}
        >
            <UiDataBoundary isLoading={store.isLoading}>
                <div className="c-tt-task-preview__name">{store.task.name}</div>
                <div className="c-tt-task-preview__header">
                    <div className="c-tt-task-preview__id">
                        Задача №{store.task.id} от {store.task.createdAtMoment.format('DD.MM.YYYY')}
                    </div>
                    {store.task.board && (
                        <div className={classnames('c-tt-task-preview__board', {
                            'c-tt-task-preview__board--copied': store.isCopied
                        })} onClick={handleCopyLinkToSpace}>
                            {store.task.board.name} / {store.task.column.name}
                        </div>
                    )}
                    <div
                        className="c-tt-task-preview-priority"
                        style={{ backgroundColor: hexToRgba(store.task.priority.color, 0.2) }}
                    >
                        <i style={{ backgroundColor: store.task.priority.color }}/>
                        <span>{store.task.priority.name}</span>
                    </div>
                </div>
                <div className="c-tt-task-preview__content">
                    {(!!store.task.description || store.task.files.length > 0) && (
                        <div className="c-tt-task-preview-field">
                            <div className="c-tt-task-preview-field__header">
                                <div className="c-tt-task-preview-field__icon">
                                    <UiIcon icon={ICONS.DESCRIPTION} size={24} color={'#94979E'}/>
                                </div>
                                <div className="c-tt-task-preview-field__label">Описание</div>
                            </div>
                            <div className="c-tt-task-preview-field__inner c-tt-task-preview-field__inner--description">
                                {store.task.description && <span>{store.task.description}</span>}
                                <div className="c-tt-task-preview__files">
                                    {store.task.files.map(file => (
                                        <CTaskFile name={file} url={file}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {!!store.task.expiredAt && (
                        <div className="c-tt-task-preview-field">
                            <div className="c-tt-task-preview-field__header">
                                <div className="c-tt-task-preview-field__icon">
                                    <UiIcon icon={ICONS.ALARM} size={24} color={'#94979E'}/>
                                </div>
                                <div className="c-tt-task-preview-field__label">Крайний срок</div>
                            </div>
                            <div className="c-tt-task-preview-field__inner">
                                {store.task.expiredAtMoment.format('D MMMM YYYY, H:mm')}
                                {!!store.task.expiredAtPropose && (
                                    <span> (на согласовании: {store.task.expiredAtProposeMoment.format('D MMMM YYYY, H:mm')})</span>
                                )}
                            </div>
                            {store.task.canSave(ApplicationModule.user) && !!store.task.expiredAtPropose && (
                                <div className="c-tt-task-preview-field__actions">
                                    <UiButton
                                        label={'Отказать'}
                                        onClick={handleProposeReject}
                                        isTiny
                                        isOutline
                                    />
                                    <UiButton
                                        label={'Подтвердить'}
                                        onClick={handleProposeAccept}
                                        isTiny
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {store.isProposing && (
                        <UiGrid columns={1} gap={16} style={{ marginBottom: 16, marginLeft: 36, marginTop: -24, width: 'auto' }}>
                            <UiFormControl label={'Новый крайний срок'}>
                                <UiGrid columns={is320 ? 1 : '1fr 110px'} gap={16}>
                                    <UiDatePicker
                                        valueFormat={''}
                                        name={'expiredAtPropose'}
                                        value={propose.expiredAtPropose}
                                        onChange={handleChange}
                                    />
                                    <UiTimePicker
                                        value={propose.expiredAtProposeTime}
                                        name={'expiredAtProposeTime'}
                                        onChange={handleChange}
                                    />
                                </UiGrid>
                            </UiFormControl>
                            <UiInput
                                placeholder={'Комментарий'}
                                name={'expiredAtProposeComment'}
                                value={propose.expiredAtProposeComment || ''}
                                onChange={handleChange}
                            />
                            <div style={{ display: "flex", gap: 16 }}>
                                <UiButton label={'Отмена'} onClick={cancelPropose} isOutline isTiny/>
                                <UiButton
                                    isDisabled={!propose.expiredAtPropose || !propose.expiredAtProposeComment}
                                    label={'Предложить'}
                                    onClick={submitPropose}
                                    isTiny
                                />
                            </div>
                        </UiGrid>
                    )}
                    <div className="c-tt-task-preview-field">
                        <div className="c-tt-task-preview-field__header">
                            <div className="c-tt-task-preview-field__icon">
                                <UiIcon icon={ICONS.USERS} size={24} color={'#94979E'}/>
                            </div>
                            <div className="c-tt-task-preview-field__label">Участники</div>
                        </div>
                        <div className="c-tt-task-preview-field__inner">
                            <div className="c-tt-task-preview-field__row">
                                <label>Постановщик:</label>
                                <User user={store.task.ownerUser}/>
                            </div>
                            {store.task.responsibleUser && (
                                <div className="c-tt-task-preview-field__row">
                                    <label>Ответственный:</label>
                                    <User user={store.task.responsibleUser}/>
                                </div>
                            )}
                            {store.task.users.length > 0 && (
                                <div className="c-tt-task-preview-field__row">
                                    <label>Участники:</label>
                                    <div className="c-tt-task-preview__users">
                                        {store.task.users.map(user => (
                                            <User key={user.id} user={user}/>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="c-tt-task-preview__footer">
                        {store.task.canSave(ApplicationModule.user) && (
                            <>
                                {!store.task.status.in([TaskTrackerTaskStatusEnum.Done, TaskTrackerTaskStatusEnum.Archive]) && (
                                    <div
                                        className="c-tt-task-preview__action"
                                        onClick={() => {
                                            navigate(null, {
                                                ...urlParams,
                                                previewTaskId: null,
                                                saveTaskId: id,
                                            }, {}, null)
                                        }}
                                    >
                                        <UiIcon icon={ICONS.EDIT} size={16}/>
                                        <span>Редактировать</span>
                                    </div>
                                )}
                                {!store.task.status.is(TaskTrackerTaskStatusEnum.Done) && (
                                    <div
                                        className="c-tt-task-preview__action c-tt-task-preview__action--red"
                                        onClick={handleDelete}
                                    >
                                        <UiIcon icon={ICONS.TRASH} size={18} color={'#EB3B5A'}/>
                                        <span>Удалить</span>
                                    </div>
                                )}
                            </>
                        )}
                        {!store.task.canSave(ApplicationModule.user) && (
                            <>
                                {!store.task.status.in([TaskTrackerTaskStatusEnum.Done, TaskTrackerTaskStatusEnum.Archive]) && (
                                    <div
                                        className="c-tt-task-preview__action"
                                        onClick={handlePropose}
                                    >
                                        <UiIcon icon={ICONS.EDIT} size={16}/>
                                        <span>Предложить новый крайний срок</span>
                                    </div>
                                )}
                            </>
                        )}
                        {store.task.status.in([TaskTrackerTaskStatusEnum.Delayed]) && (
                            <div
                                className="c-tt-task-preview__action"
                                onClick={() => handleUpdateStatus(TaskTrackerTaskStatusEnum.InWork.id)}
                            >
                                <span>Взять в работу</span>
                            </div>
                        )}
                        {store.task.status.in([TaskTrackerTaskStatusEnum.InWork, TaskTrackerTaskStatusEnum.Expired, TaskTrackerTaskStatusEnum.AlmostExpired]) && (
                            <div
                                className="c-tt-task-preview__action"
                                onClick={() => handleUpdateStatus(TaskTrackerTaskStatusEnum.Delayed.id)}
                            >
                                <span>Отложить</span>
                            </div>
                        )}
                        {store.task.status.in([TaskTrackerTaskStatusEnum.InWork, TaskTrackerTaskStatusEnum.Expired, TaskTrackerTaskStatusEnum.AlmostExpired, TaskTrackerTaskStatusEnum.Delayed]) && (
                            <div
                                className="c-tt-task-preview__action"
                                onClick={() => handleUpdateStatus(TaskTrackerTaskStatusEnum.Done.id)}
                            >
                                <span>Завершить</span>
                            </div>
                        )}
                        {store.task.status.in([TaskTrackerTaskStatusEnum.Done]) && (
                            <div
                                className="c-tt-task-preview__action"
                                onClick={() => {
                                    handleUpdateStatus(TaskTrackerTaskStatusEnum.Archive.id)
                                    Notifier.alert(
                                        `Архив`,
                                        `Задача «${store.task.name}» перемещена в архив`
                                    );
                                    navigate(null, {
                                        ...urlParams,
                                        previewTaskId: null,
                                    })
                                }}
                            >
                                <span>В архив</span>
                            </div>
                        )}
                        {store.task.status.in([TaskTrackerTaskStatusEnum.Archive]) && (
                            <div
                                className="c-tt-task-preview__action"
                                onClick={() => {
                                    handleUpdateStatus(TaskTrackerTaskStatusEnum.InWork.id)
                                    Notifier.alert(
                                        `Возвращение задачи на доску`,
                                        `Задача «${store.task.name}» возвращена на доску «${store.task.board.name}»`
                                    );
                                    navigate(null, {
                                        ...urlParams,
                                        previewTaskId: null,
                                    })
                                }}
                            >
                                <span>Вернуть на доску</span>
                            </div>
                        )}
                        <div className={'c-tt-task-preview-views'}>
                            <UiIcon size={20} color={COLORS.GRAY_3} icon={ICONS.EYE}/>
                            <span>{store.task.taskViews.length}</span>
                            <div className="c-tt-task-preview-views__inner">
                                {store.task.taskViews.map(taskView => (
                                    <div key={taskView.id} className="c-tt-task-preview-views-item">
                                        <UiNavLink className={'c-tt-task-preview-views-item__user'}
                                                   to={ROUTES.USER(taskView.user.slug)}>
                                            {taskView.user.previewName}
                                        </UiNavLink>
                                        <div className="c-tt-task-preview-views-item__date">
                                            {taskView.updatedAtMoment.format('DD MMMM HH:mm')}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <CComments entityId={store.task.id} entityType={'TaskTrackerTask'}/>
            </UiDataBoundary>
        </UiModal>
    )
});

const User = ({ user }: { user: UserModel }) => {
    return (
        <UiNavLink to={ROUTES.USER(user.id)} className="c-tt-task-preview-user">
            <UiAvatar
                size={32}
                image={user.imageAvatar}
                label={user.previewName}
            />
            <span>{user.previewName}</span>
        </UiNavLink>
    );
}

