import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { Interweave } from 'interweave';
import { Url, UrlMatcher } from 'interweave-autolink';
import classnames from "classnames";
import Picker, { IEmojiData } from "emoji-picker-react";

import { ChatMessageModel } from "shared/models";
import { UiFileIcon, UiIcon, UiDropdownMenu } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { useOnClickOutside, useStore } from "shared/hooks";
import { clipboardCopy, Notifier } from "shared/utilities";
import { chatMessagesDeleteQuery } from "shared/queries";
import { ApplicationModule } from "shared/modules";

import { Store } from "../../store";

import { ChatMessageArticle } from "../ChatMessageArticle";

import './styles.scss';

type PropsType = {
    chatMessage: ChatMessageModel
}

export const ChatMessage = observer(({ chatMessage }: PropsType) => {
    const emojisContainer = useRef(null);
    const store = useStore(() => ({
        emojisIsOpened: false,
        dropdownIsOpened: false,
    }));

    const handleReply = useCallback(() => {
        Store.set("chatMessageReply", chatMessage);
    }, [chatMessage]);

    const handleEmojisToggle = useCallback(() => {
        store.set("emojisIsOpened", !store.emojisIsOpened);
    }, [store]);

    const handleDropdownToggle = useCallback((isOpened: boolean) => {
        store.set("dropdownIsOpened", isOpened);
    }, [store]);

    const handleEmojisHide = useCallback(() => {
        store.set("emojisIsOpened", false);
    }, [store]);

    const handleSelectEmoji = useCallback((_event: React.MouseEvent, { emoji }: IEmojiData) => {
        Store.set("chatMessageReply", chatMessage);
        Store.set("message", Store.message + emoji);
        store.set("emojisIsOpened", false);
        Store.input?.focus();
    }, [store, chatMessage]);

    const dropdownOptions = useMemo(() => {
        const options = [{
            id: 'copy',
            name: 'Копировать'
        }];
        if (+chatMessage.userId === +ApplicationModule.user.id) {
            options.push({
                id: 'delete',
                name: 'Удалить'
            });
        }
        return options;
    }, [chatMessage]);

    const handleSelectOption = useCallback(async (id: string | number) => {
        switch (id) {
            case 'copy':
                clipboardCopy(chatMessage.content);
                break;
            case 'delete':
                const isSuccess = await Notifier.confirm('Удаление', 'Подтвердите удаление вашего сообщения');
                if (isSuccess) {
                    chatMessage.update({ deletedAt: 'now' });
                    chatMessagesDeleteQuery(chatMessage.id);
                }
                break;
        }
    }, [chatMessage]);

    const className = useMemo(() => classnames('chat-message', `chat-message--${chatMessage.id}`, {
        'chat-message--selected': store.emojisIsOpened || store.dropdownIsOpened,
    }), [store.emojisIsOpened, store.dropdownIsOpened, chatMessage.id]);

    useOnClickOutside(emojisContainer, handleEmojisHide);

    //document.getElementsByClassName("chat-message--97")[0].scrollIntoView()
    return (
        <div className={className}>
            <div className="chat-message__inner">
                <div className="chat-message__header">
                    <div className="chat-message__time">
                        {chatMessage.createdAtMoment.format('HH:mm')}
                    </div>
                    <div className="chat-message__icon">
                        {chatMessage.status === 'pending' && (
                            <UiIcon size={16} color={COLORS.GRAY_2} icon={ICONS.CHAT_MESSAGE_PENDING}/>
                        )}
                        {chatMessage.status === 'sent' && (
                            <UiIcon size={12} color={COLORS.GRAY_2} icon={ICONS.CHAT_MESSAGE_SENT}/>
                        )}
                        {chatMessage.status === 'read' && (
                            <UiIcon size={17} color={COLORS.GREEN} icon={ICONS.CHAT_MESSAGE_READ}/>
                        )}
                    </div>
                </div>
                {chatMessage.chatMessage && (
                    <div className='chat-message-reply' title={chatMessage.chatMessage.content}>
                        <div className="chat-message-reply__user">{chatMessage.chatMessage.user.previewName}</div>
                        <div className="chat-message-reply__content">{chatMessage.chatMessage.content}</div>
                    </div>
                )}
                {chatMessage.content && (
                    <Interweave
                        tagName={'div'}
                        className='chat-message__content'
                        content={chatMessage.content}
                        matchers={[
                            new UrlMatcher('url', {}, props => <Url {...props} newWindow/>)
                        ]}
                    />
                )}
                <ChatMessageArticle chatMessageArticle={chatMessage.chatMessageArticle}/>
                {chatMessage.chatMessageAttachments.some(attachment => attachment.typeId === 'image') && (
                    <div className="chat-message__images">
                        {chatMessage.chatMessageAttachments.filter(attachment => attachment.typeId === 'image').map(attachment => (
                            <a
                                key={attachment.id}
                                target='_blank'
                                rel='noreferrer'
                                href={attachment.content}
                                className="chat-message__image"
                            >
                                {attachment.contentThumbnail ? (
                                    <picture>
                                        <source srcSet={attachment.contentThumbnail.webp || ''} type="image/webp"/>
                                        <source srcSet={attachment.contentThumbnail.origin || attachment.content}/>
                                        <img src={attachment.contentThumbnail.origin || attachment.content} alt=''/>
                                    </picture>
                                ) : (
                                    <img src={attachment.content} alt=""/>
                                )}
                            </a>
                        ))}
                    </div>
                )}
                {chatMessage.chatMessageAttachments.some(attachment => attachment.typeId === 'file') && (
                    <div className="chat-message__files">
                        {chatMessage.chatMessageAttachments.filter(attachment => attachment.typeId === 'file').map(attachment => (
                            <a
                                key={attachment.id}
                                target='_blank'
                                rel='noreferrer'
                                href={attachment.content}
                                style={{ backgroundImage: `url(${attachment.content})` }}
                                className="chat-message-file"
                            >
                                <div className="chat-message-file__icon">
                                    <UiFileIcon size={40} extension={attachment.extension}/>
                                </div>
                                <div className="chat-message-file__name">{attachment.description}</div>
                            </a>
                        ))}
                    </div>
                )}
            </div>
            <div className="chat-message-actions">
                <div className="chat-message-actions__reply" onClick={handleReply}>
                    <UiIcon
                        size={16}
                        icon={ICONS.CHAT_MESSAGE_REPLY}
                        color={COLORS.GRAY_3}
                    />
                </div>
                <div className="chat-message-actions-emojis" ref={emojisContainer}>
                    <UiIcon size={16} color={COLORS.GRAY_3} icon={ICONS.CHAT_EMOJI} onClick={handleEmojisToggle}/>
                    {store.emojisIsOpened && (
                        <div className={'chat-message-actions-emojis__inner'}>
                            <Picker
                                disableSearchBar
                                onEmojiClick={handleSelectEmoji}
                                native={true}
                                groupNames={{
                                    smileys_people: 'Люди и смайлики',
                                    animals_nature: 'Животные и природа',
                                    food_drink: 'Еда и напитки',
                                    travel_places: 'Путешествия',
                                    activities: 'Активности',
                                    objects: 'Вещи',
                                    symbols: 'Символика',
                                    flags: 'Флаги',
                                    recently_used: 'Часто используемые',
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="chat-message-actions__menu">
                    <UiDropdownMenu
                        left={-12}
                        toTop
                        toRight
                        control={<UiIcon icon={ICONS.TRIPLEDOTS} size={13}/>}
                        onSelect={handleSelectOption}
                        onToggle={handleDropdownToggle}
                        items={dropdownOptions}
                    />
                </div>
            </div>
        </div>
    );
});
