import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { GalleryFileModel } from 'shared/models';
import { UiIcon } from 'shared/uikit';
import { ICONS } from 'shared/constants';
import { fileSize } from 'shared/utilities';

import './styles.scss';

type PropsType = {
    galleryFile: GalleryFileModel,
    onClick?: () => void
}

export const GalleryFileTableRow = observer(({ galleryFile, onClick }: PropsType) => {
    return (
        <tr className='gallery-file-table-row' onClick={onClick}>
            <td>
                <div className="gallery-file-table-row__name">
                    {galleryFile.isImage && (
                        <UiIcon size={24} icon={ICONS.GALLERY_FILE_ICON_IMAGE}/>
                    )}
                    {!galleryFile.isImage && (
                        <UiIcon size={24} icon={ICONS.GALLERY_FILE_ICON_VIDEO}/>
                    )}
                    <span>{galleryFile.name}</span>
                </div>
            </td>
            <td>{moment(galleryFile.createdAt).format('DD.MM.YYYY')}</td>
            <td>{galleryFile.isImage ? 'Изображение' : 'Файл'} {galleryFile.extension.toUpperCase()}</td>
            <td>{fileSize(+galleryFile.size)}</td>
        </tr>
    );
})
