import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { COLORS, ICONS } from "shared/constants";
import { useOnClickOutside, useStore } from "shared/hooks";

import { UiIcon } from "../UiIcon";
import { UiScrollbar } from "../UiScrollbar";

import './styles.scss';

interface PropsType {
    value?: string | null;
    name?: string;
    isReadOnly?: boolean;
    items?: (items: string[]) => string[],
    onChange?: (data: {
        control?: string;
        name: string;
        value: string;
        hours: number,
        minutes: number
    }) => void,
}

const options = [
    '08:00', '08:30',
    '09:00', '09:30',
    '10:00', '10:30',
    '11:00', '11:30',
    '12:00', '12:30',
    '13:00', '13:30',
    '14:00', '14:30',
    '15:00', '15:30',
    '16:00', '16:30',
    '17:00', '17:30',
    '18:00', '18:30',
    '19:00', '19:30',
    '20:00', '20:30',
    '21:00', '21:30',
    '22:00', '22:30',
    '23:00', '23:30',
];

export const UiTimePicker = observer(({ value, onChange, name = 'ui-time-picker', items = options => options, isReadOnly = false }: PropsType) => {
    const ref = useRef(null);
    const store = useStore(() => ({
        isOpened: false,
        isSelecting: false,
        value: '',
    }));

    useEffect(() => {
        store.set('value', value);
    }, [value, store])

    const handleChangeControl = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value || '';
        value = value.replace(/[^\d]/g, "");

        if (value.length > 4) {
            value = value.slice(0, 4);
        }

        if (value.length > 2) {
            value = [value.slice(0, 2), ':', value.slice(2)].join('');
        }

        store.set('value', value);
    }, [store]);

    const handleSubmitControl = () => {
        setTimeout(() => { //fix for double submitting on selecting
            if (store.isSelecting) {
                store.set("isSelecting", false);
                return;
            }
            store.set("isOpened", false);
            let hours = +store.value.split(':')[0];
            let minutes = store.value.indexOf(':') > -1 ? +store.value.split(':')[1] : 0;

            if (hours > 23) {
                hours = 23;
            } else if (hours < 0) {
                hours = 0;
            }

            if (minutes > 59) {
                minutes = 59;
            } else if (minutes < 0) {
                minutes = 0;
            }
            let value = [hours.toString().padStart(2, '0'), ':', minutes.toString().padStart(2, '0')].join('');

            onChange && onChange({
                name,
                value,
                hours,
                minutes
            });
        }, 250);
    };

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13) {
            handleSubmitControl();
        }
        // eslint-disable-next-line
    }, []);

    const className = useMemo(() => classnames('ui-time-picker', {
        'ui-time-picker--opened': store.isOpened
    }), [store.isOpened]);

    useOnClickOutside(ref, () => {
        store.set('isOpened', false);
    });

    const handleFocus = useCallback(() => {
        store.set('isOpened', true);
    }, [store]);

    const handleChangeSelect = useCallback((value: string) => {
        store.set('isOpened', false);
        store.set('isSelecting', true);

        onChange && onChange({
            name,
            value,
            hours: parseInt(value.split(':')[0]),
            minutes: parseInt(value.split(':')[1])
        });
    }, [name, onChange, store]);

    return (
        <div className={className} ref={ref}>
            <div className="ui-time-picker__inner">
                <input
                    readOnly={isReadOnly}
                    type="text"
                    className="ui-time-picker__control"
                    value={store.value}
                    onFocus={handleFocus}
                    onChange={handleChangeControl}
                    onBlur={handleSubmitControl}
                    onKeyDown={handleKeyDown}
                />
                <div className="ui-time-picker__icon">
                    <UiIcon size={16} icon={ICONS.CLOCK} color={COLORS.GRAY_3}/>
                </div>
            </div>
            <div className="ui-time-picker__outer">
                <UiScrollbar maxHeight={250}>
                    {items(options).map(time => (
                        <div
                            key={time}
                            className="ui-time-picker__item"
                            onClick={() => handleChangeSelect(time)}
                        >
                            {time}
                        </div>
                    ))}
                </UiScrollbar>
            </div>
        </div>
    )
});
