import axios, { AxiosError } from 'axios';
import lodash from 'lodash';

import { ApplicationModule } from 'shared/modules';
import { ObjectType } from 'shared/types';
import { ITmpFileModel } from 'shared/models';

import Notifier from './Notifier';
import { getBackendUrl } from "shared/utilities/getBackendUrl";

interface IApiResponse<T = {}> {
    status: string;
    isSuccess: boolean;
    errorCode?: number;
    errorMessage?: string;
    data: T;

    [key: string]: any;
}

export default new (class Api {
    private NETWORK_ERROR: IApiResponse = {
        status: 'error',
        errorMessage: 'Ошибка интернет соединения с сервере',
        errorCode: 900,
        isSuccess: false,
        data: {},
    };

    private getHeaders = () => {
        const headers: {
            [key: string]: string;
        } = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        if (ApplicationModule.isAuthorized) {
            headers['Authorization'] = `Bearer ${ApplicationModule.accessToken}`;
        }

        return headers;
    };


    getApiUrl = (url: string) => {
        return getBackendUrl(`/api/v1${url}`);
    };

    private changeKeys = <T>(data: T, handler = lodash.camelCase): T => {
        if (data === null) {
            return null as any;
        }
        if (data === undefined) {
            return null as any;
        }

        let result: Array<any> | ObjectType | T;
        if (Array.isArray(data)) {
            result = data.map(item => this.changeKeys(item, handler));
        } else if (typeof data === 'object') {
            result = {};
            for (const [key, value] of Object.entries(data)) {
                result[handler(key)] = this.changeKeys(value, handler);
            }
        } else {
            result = data;
        }
        return result as T;
    };

    private query = async <IResponse>(
        method: 'get' | 'post',
        url: string,
        payload: ObjectType | FormData = {},
        config = {}
    ): Promise<IResponse> => {
        let response: IResponse;

        let dataOrParams;
        //check is formdata
        if (!payload.hasOwnProperty('append') && 'append' in payload) {
            dataOrParams = payload;
        } else {
            dataOrParams = this.changeKeys(payload, lodash.snakeCase);
        }

        try {
            const axiosResponse = await axios({
                method,
                headers: this.getHeaders(),
                url: this.getApiUrl(url),
                [method === 'get' ? 'params' : 'data']: dataOrParams,
                ...config,
            });
            response = this.changeKeys<IResponse>(axiosResponse.data, lodash.camelCase);
            (response as IResponse & IApiResponse).isSuccess = true;
        } catch (error: AxiosError | any) {
            if (axios.isAxiosError(error)) {
                if (!error.response || !error.response.data) {
                    return this.NETWORK_ERROR as IResponse & IApiResponse;
                }
                if (error.response.status === 401) {
                    ApplicationModule.setAccessToken(null);
                }

                if (error.response.status === 403) {
                    Notifier.alert('Ошибка', error.response.data.errorMessage);
                }

                response = this.changeKeys<IResponse & IApiResponse>(error.response.data, lodash.camelCase);
                (response as IResponse & IApiResponse).isSuccess = false;
            } else {
                return this.NETWORK_ERROR as IResponse & IApiResponse;
            }
        }

        return response;
    };

    post = async <IResponseCustom, IResponse = IApiResponse<IResponseCustom>>(
        url = '/',
        payload = {}
    ): Promise<IResponse> => {
        return this.query<IResponse>('post', url, payload);
    };

    get = async <IResponseCustom, IResponse = IApiResponse<IResponseCustom>>(
        url = '/',
        payload = {}
    ): Promise<IResponse> => {
        return this.query<IResponse>('get', url, payload);
    };

    tmpFile = async (file: File, progressCallback: (progress: number) => void = () => {}) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.query<IApiResponse<{ item: ITmpFileModel }>>('post', '/tmp-files/upload', formData, {
            onUploadProgress: function (progressEvent: ProgressEvent) {
                progressCallback(Math.floor((progressEvent.loaded / progressEvent.total) * 100));
            },
        });
    };

    getAdminPanelLink = (redirectUrl = '/admin/dashboard') => {
        return getBackendUrl(`/external/login/?redirect_url=${redirectUrl}&access_token=${ApplicationModule.accessToken}`);
    };
})();
