import { action, computed, makeObservable, observable, runInAction } from "mobx";
import lodash from "lodash";

import { CommentModel, ICommentModel } from "shared/models";
import { commentsDeleteQuery, commentsSaveQuery } from "shared/queries";

export const Store = new class {
    comments: CommentModel[] = [];
    entityId: number = 0;
    entityType: string = '';

    constructor() {
        makeObservable(this, {
            comments: observable,
            commentsByCommentId: computed,
            rootComments: computed,
            setComments: action,
        })
    }

    saveComment = async (comment: ICommentModel) => {
        const { isSuccess, data } = await commentsSaveQuery({
            ...comment,
            entityId: this.entityId,
            entityType: this.entityType,
            url: window.location.pathname + window.location.search
        });

        if (isSuccess && data) {
            runInAction(() => {
                if (!comment.id) {
                    this.comments.push(new CommentModel(data.item));
                } else {
                    this.commentsById[comment.id]?.update(data.item);
                }
            });
        }
    }

    deleteComment = (id: number) => {
        commentsDeleteQuery(id);
        runInAction(() => {
            this.comments = this.comments.filter(comment => comment.id !== id);
        });
    }

    get rootComments() {
        return this.comments.filter(comment => !comment.commentId);
    }

    get commentsById(): Record<number, CommentModel> {
        return lodash.keyBy(this.comments, 'id');
    }

    get commentsByCommentId() {
        return lodash.groupBy(this.comments, 'commentId');
    }

    setComments = (comments: ICommentModel[]) => {
        this.comments = comments.map(comment => new CommentModel(comment));
    }
}
