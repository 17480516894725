import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import { ITagModel } from 'shared/models';
import { OnChangeHandlerType } from 'shared/types';

import './styles.scss';

interface Props {
    onClick?: OnChangeHandlerType<ITagModel>;
    value: ITagModel | null;
    name: string;
}

class UiTag extends React.Component<Props> {
    static defaultProps: Props = {
        value: null,
        name: 'tag_id',
    };

    handleClick = (e: SyntheticEvent) => {
        const { onClick, value, name } = this.props;
        e.preventDefault();
        e.stopPropagation();

        (onClick && value) && onClick({ name, value });
    };

    render() {
        const { value } = this.props;

        if (!value) {
            return null;
        }

        return (
            <div className='ui-tag' onClick={this.handleClick}>
                #{value.name}
            </div>
        );
    }
}

export default observer(UiTag);
