import { makeQuery } from 'shared/utilities';

type RequestType = {
    name: string,
    description: string,
    tmpFileId?: number | null,
};

type ResponseType = {};

export const improvementProposalsCreateQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/improvement-proposals/create', { json: payload });
}
