import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";
import moment from 'moment';

import { OnChangeHandlerType } from "shared/types";
import { COLORS, ICONS } from "shared/constants";
import { useOnClickOutside, useStore } from "shared/hooks";

import { UiIcon } from "../UiIcon";
import { UiCalendar } from "../UiCalendar";

import './styles.scss';

interface PropsType {
    value?: string | null;
    name?: string;
    valueFormat?: string;
    textFormat?: string;
    withSubmit?: boolean,
    withTime?: boolean,
    onChange?: OnChangeHandlerType<string>,
}

export const UiDatePicker = observer((
    {
        value,
        onChange,
        name = 'ui-date-picker',
        textFormat = 'D MMMM YYYY',
        valueFormat = 'Y-MM-DD',
    }: PropsType
) => {
    const ref = useRef(null);
    const store = useStore(() => ({
        isOpened: false,
    }));

    const handleChange: OnChangeHandlerType<string> = (data) => {
        store.set('isOpened', false);
        onChange && onChange(data);
    };

    useOnClickOutside(ref, () => {
        store.set('isOpened', false);
    });

    const handleClick = useCallback(() => {
        store.set('isOpened', true);
    }, [store]);

    const text = useMemo(() => {
        if (!value) {
            return 'Выберите дату';
        }

        return moment(value).format(textFormat);
    }, [value, textFormat]);

    const className = useMemo(() => classnames('ui-date-picker', {
        'ui-date-picker--opened': store.isOpened
    }), [store.isOpened]);

    return (
        <div className={className} ref={ref}>
            <div className="ui-date-picker__inner">
                <input
                    type="text"
                    className="ui-date-picker__control"
                    readOnly
                    value={text}
                    onClick={handleClick}
                />
                <div className="ui-date-picker__icon">
                    <UiIcon size={16} icon={ICONS.CALENDAR} color={COLORS.GRAY_3}/>
                </div>
            </div>
            <div className="ui-date-picker__outer">
                <UiCalendar
                    value={value}
                    onChange={handleChange}
                    name={name}
                    format={valueFormat}
                />
            </div>
        </div>
    )
});
