import { makeQuery } from 'shared/utilities';
import { IReservationPlaceModel } from 'shared/models';

type ResponseType = {
    items: IReservationPlaceModel[];
};

export const reservationPlacesQuery = async function (date: string) {
    return await makeQuery<ResponseType>('/reservation-places', { params: { date } });
}
