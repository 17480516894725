import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IEducationLinkModel {
    id?: number;
    name?: string;
    url?: string;
    icon?: string;
}

export class EducationLinkModel extends Model implements IEducationLinkModel {

    id = 0;
    name = '';
    url = '';
    icon = '';

    constructor(payload: IEducationLinkModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            url: observable,
            icon: observable,
            update: action,
        });

        this.update(payload);
    }
}
