import classnames from "classnames";
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef } from "react";

import { ICONS } from "shared/constants";
import { useOnClickOutside, useStore } from "shared/hooks";
import { UserDashboardWidgetModel } from "shared/models";
import { UserDashboardWidgetDictionaryItemModel, UserDashboardWidgetItemBonusModel } from "shared/models";
import { UiIcon } from "shared/uikit";
import { currency, number } from "shared/utilities";

import { PPDWidgetItemValueProgress } from "../PPDWidgetItemValueProgress";
import { PPDWidgetGroups } from "../PPDWidgetGroups";

import './styles.scss';

import compareDown from '../../assets/compare-down.svg';
import compareUp from '../../assets/compare-up.svg';

type PropsType = {
    bonus: UserDashboardWidgetItemBonusModel | null;
}

export const PPDWidgetItemBonus = observer(({ bonus }: PropsType) => {
    const ref = useRef<HTMLDivElement>(null);
    const store = useStore(() => ({
        isOpened: false
    }));

    useOnClickOutside(ref, () => {
        store.set("isOpened", false);
    });

    if (!bonus) {
        return (
            <div className="ppd-widget-item-bonus">
                <div className="ppd-widget-item-bonus-control">
                    <div className="ppd-widget-item-bonus-control__name">Бонус не начисляется</div>
                </div>
            </div>
        )
    }

    return (
        <div
            ref={ref}
            className={classnames("ppd-widget-item-bonus", `ppd-widget-item-bonus--${bonus.statusId}`, {
                'ppd-widget-item-bonus--opened': store.isOpened
            })}
        >
            <div
                className="ppd-widget-item-bonus-control"
                onClick={() => store.set("isOpened", !store.isOpened)}
            >
                <div className="ppd-widget-item-bonus-control__name">{bonus.name}</div>
                <div className="ppd-widget-item-bonus-control__value">
                    <UiIcon
                        icon={bonus.statusId === 'received' ? ICONS.CHECK : ICONS.LOCK}
                        size={14}
                        color={bonus.statusId === 'received' ? '#20bf6b' : '#a1a4aa'}
                    />
                    <span>{currency(bonus.value)}</span>
                </div>
            </div>
            <div className="ppd-widget-item-bonus__outer">
                <div className="ppd-widget-item-bonus__items">
                    {bonus.items.map((item, index) => {
                        return (
                            <div key={index} className={classnames("ppd-widget-item-bonus-item", `ppd-widget-item-bonus-item--${item.statusId}`)}>
                                <div className="ppd-widget-item-bonus-item__name">{item.name}</div>
                                <div className="ppd-widget-item-bonus-item__value">
                                    <UiIcon
                                        icon={item.statusId === 'received' ? ICONS.CHECK : ICONS.LOCK}
                                        size={14}
                                        color={item.statusId === 'received' ? '#20bf6b' : '#a1a4aa'}
                                    />
                                    <span>{currency(item.value)}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
});
