import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { LayoutBody, LayoutContent } from "shared/layout";
import { useNavigate, useRouteParams, useStore, useUrlParams } from "shared/hooks";
import { spacesGetQuery, spacesPersonalQuery } from "shared/queries/TaskTracker";
import { BoardModel, ColumnModel, SpaceModel, TaskModel } from "shared/models/TaskTracker";
import {
    CBoard,
    CBoardSave,
    CFilterInvolving,
    CFilterSearch,
    CFilterStatus,
    CFilterView,
    CInbox,
    CNotificationsAction,
    CSpaceParticipants,
    CSpaceSave,
    CSpaceSelect,
    CTaskPreview,
    CTaskSave
} from "shared/components/TaskTracker";
import { UserModel } from "shared/models";
import { ICONS, ROUTES } from "shared/constants";
import { ApplicationModule } from "shared/modules";
import { TaskTrackerTaskStatusEnum } from "shared/enums";
import { UiIcon } from "shared/uikit";

import { personalColumns } from "./personalColumns";
import { Store } from "./Store";


import './styles.scss';

export const TaskTrackerPage = observer(() => {
    const navigate = useNavigate();
    const { user } = ApplicationModule;
    const routeParams = useRouteParams<{ id: number | string }>();
    const urlParams = useUrlParams({
        boardId: null as number | null,
        spaceId: null as number | null,

        previewTaskId: null as number | null,

        saveTaskId: null as number | string | null,
        saveSpaceId: null as number | string | null,
        saveBoardId: null as number | string | null,
        saveColumnId: null as number | string | null,

        fInvolving: null as string | null,
        fSearch: null as string | null,
        fStatus: null as string | null,
        fSpaceId: null as number | null,
    });

    const isPersonal = routeParams.id === 'personal';

    const store = useStore(() => ({
        spaces: [] as SpaceModel[],
        boards: [] as BoardModel[],
        columns: [] as ColumnModel[],
        tasks: [] as TaskModel[],
        users: [] as UserModel[],
        owners: [] as UserModel[],
        isLoading: true
    }));

    useEffect(() => {
        ApplicationModule.setTitle('Task Tracker');
        store.set("isLoading", true);
        (async () => {
            store.set('boards', []);
            store.set('columns', []);
            store.set('users', []);
            store.set('tasks', []);

            if (isPersonal) {
                ApplicationModule.setTitle('Моё пространство');
                const { data, isSuccess, } = await spacesPersonalQuery();
                if (isSuccess && data) {
                    store.set('spaces', data.spaces.map(item => new SpaceModel(item)));
                    store.set('tasks', data.tasks.map(item => new TaskModel(item)));
                    store.set('boards', data.boards.map(item => new BoardModel(item)));
                    store.set('columns', data.columns.map(item => new ColumnModel(item)));

                    Store.set('spaces', data.spaces.map(item => new SpaceModel(item)));
                }
                store.set("isLoading", false);
                return;
            } else {
                const { data, isSuccess, } = await spacesGetQuery({
                    id: routeParams.id as number
                });
                if (isSuccess && data) {
                    store.set('spaces', data.spaces.map(item => new SpaceModel(item)));
                    store.set('boards', data.boards.map(item => new BoardModel(item)));
                    store.set('columns', data.columns.map(item => new ColumnModel(item)));
                    store.set('users', data.users.map(item => new UserModel(item)));
                    store.set('owners', data.owners.map(item => new UserModel(item)));
                    store.set('tasks', data.tasks.map(item => new TaskModel(item)));

                    Store.set('spaces', data.spaces.map(item => new SpaceModel(item)));

                    ApplicationModule.setTitle(data.item.name);
                } else {
                    navigate(ROUTES.TASK_TRACKER('personal'));
                }
            }
            store.set("isLoading", false);
        })();
    }, [isPersonal, store, routeParams.id, navigate]);

    let tasks = useMemo(() => {
        return store.tasks.slice()
            .filter((task) => {
                if (urlParams.fInvolving === 'owner') {
                    return +task.ownerUserId === +user.id;
                }

                if (urlParams.fInvolving === 'responsible') {
                    return +task.responsibleUserId === +user.id;
                }

                if (urlParams.fInvolving === 'user') {
                    return task.users.map(({ id }) => id).includes(+user.id)
                }

                return true;
            }).filter(task => {
                if (!!urlParams.fStatus) {
                    if (urlParams.fStatus === TaskTrackerTaskStatusEnum.InWork.id) {
                        return task.status.in([
                            TaskTrackerTaskStatusEnum.InWork,
                            TaskTrackerTaskStatusEnum.AlmostExpired,
                            TaskTrackerTaskStatusEnum.Expired,
                        ]);
                    }
                    return task.status.id === urlParams.fStatus
                }
                return true;
            }).filter(task => {
                if (!!urlParams.fSearch) {
                    return task.search.toLocaleLowerCase().includes(urlParams.fSearch.toLocaleLowerCase());
                }
                return true;
            }).filter(task => {
                if (!!urlParams.fSpaceId) {
                    return +task.spaceId === +urlParams.fSpaceId;
                }
                return true;
            });
    }, [store.tasks, user, urlParams.fInvolving, urlParams.fSearch, urlParams.fStatus, urlParams.fSpaceId]);

    const handleSave = (property: 'columns' | 'spaces' | 'boards' | 'tasks') => (model: any) => {
        const items = (store[property] as any[]).filter((item: any) => item.id !== model.id);
        store.set(property, [...items, model]);
    }

    const handleNavigateToArchive = () => {
        navigate(ROUTES.TASK_TRACKER_ARCHIVE(), {
            spaceId: routeParams.id !== 'personal' ? routeParams.id : undefined,
            fromSpaceId: routeParams.id !== 'personal' ? routeParams.id : undefined,
        });
    }

    const sortedBoards = useMemo(() => {
        return store.boards.slice().sort((a, b) => b.id - a.id);
    }, [store.boards]);

    const isSpaceOwner = useMemo(() => {
        if (isPersonal) {
            return false;
        }
        return store.owners.some(owner => +owner.id === +ApplicationModule.user.id) || ApplicationModule.user.isAdmin;
    }, [isPersonal, store.owners])

    return (
        <LayoutBody>
            <LayoutContent>
                <div className="p-task-tracker__header">
                    <CSpaceSelect
                        items={store.spaces}
                        value={routeParams.id}
                    />
                    <CNotificationsAction/>
                    {!isPersonal && (
                        <CSpaceParticipants
                            canEdit={isSpaceOwner}
                            users={store.users}
                            owners={store.owners}
                            onChangeUsers={users => {
                                store.set("users", users)
                            }}
                            onChangeOwners={owners => {
                                store.set("owners", owners)
                            }}
                        />
                    )}
                    <div onClick={handleNavigateToArchive} className="p-task-tracker__archive">
                        <UiIcon icon={ICONS.TASK_TRACKER_ARCHIVE} size={20} color={'#999999'}/>
                    </div>
                </div>
                <div className="p-task-tracker__filters">
                    <CFilterView/>
                    <CFilterInvolving/>
                    <CFilterStatus/>
                    <CFilterSearch/>
                </div>
                <CInbox
                    columns={store.columns}
                    boards={store.boards}
                    tasks={(isPersonal ? store.tasks : store.tasks).filter(task => !task.boardId)}
                />
                <div className="p-task-tracker__boards">
                    {(isPersonal && !store.isLoading) && (
                        <CBoard
                            key={'personal'}
                            board={new BoardModel({ name: 'Моя доска' })}
                            columns={personalColumns}
                            tasks={tasks.filter(task => task.columnId)}
                            spaces={store.spaces}
                        />
                    )}
                    {!isPersonal && !store.isLoading && sortedBoards.map(board => (
                        <CBoard
                            key={board.id}
                            canEdit={isSpaceOwner}
                            board={board}
                            columns={store.columns}
                            tasks={tasks}
                            onColumnsChange={(columns) => store.set("columns", columns)}
                        />
                    ))}
                </div>
                <CTaskPreview
                    id={urlParams.previewTaskId}
                    onUpdate={(task) => {
                        store.set("tasks", [...store.tasks.filter(b => +task.id !== +b.id), task]);
                    }}
                    onDelete={(task) => {
                        store.set("tasks", store.tasks.filter(b => +task.id !== +b.id));
                    }}
                />
                <CTaskSave
                    id={urlParams.saveTaskId}
                    boardId={urlParams.boardId}
                    users={store.users}
                    onSaved={handleSave("tasks")}
                />
                <CSpaceSave
                    id={urlParams.saveSpaceId}
                    onSaved={handleSave("spaces")}
                />
                <CBoardSave
                    id={urlParams.saveBoardId}
                    spaceId={urlParams.spaceId}
                    onSaved={(board, columns) => {
                        store.set("boards", [...store.boards.filter(b => +board.id !== +b.id), board]);

                        const columnsIds = columns.map(column => column.id);
                        store.set("columns", [...store.columns.filter(column => !columnsIds.includes(column.id)), ...columns]);
                    }}
                />
            </LayoutContent>
        </LayoutBody>
    );
});

