import { Enum } from './Enum';

export class SurveyStatusEnum extends Enum {
    static Active = 'active';
    static Completed = 'completed';

    static items = [{
        id: SurveyStatusEnum.Active,
        name: 'Активные'
    }, {
        id: SurveyStatusEnum.Completed,
        name: 'Завершенные'
    }];
}

