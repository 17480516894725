import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { ModelArrayCast, ModelCast } from "shared/casts";
import { PermissionEnum } from "shared/enums";
import { userPreviewName } from "shared/utilities";
import { DepartmentBranchModel, IDepartmentBranchModel } from "./DepartmentBranchModel";

import { Model } from './Model';
import { IUserPersonalFieldModel, UserPersonalFieldModel } from "./UserPersonalFieldModel";
import { IUserPositionModel, UserPositionModel } from "./UserPositionModel";

export interface IUserModel {
    id?: number;
    login?: string;
    name?: string;
    slug?: string;
    email?: string;
    image?: string;
    imageThumbnail?: string,
    imageAvatar?: string;
    phone?: string;
    mobile?: string;
    widgets?: null | {
        id: string,
        value: number
    }[];
    ispringUserId?: string | null;
    ispringLogin?: string | null;
    outlookLogin?: string | null;
    birthdayAt?: string;
    birthdayIndex?: number;
    recentActivityAt?: string;
    hiredAt?: string | null;
    unavailableComment?: string | null,
    unavailableReason?: string | null,
    unavailableTo?: string | null,
    unavailableFrom?: string | null,
    unavailableUserId?: number | null,
    unavailableUser?: IUserModel | null,
    responsibilities?: string;
    userPositionNameOverride?: string;
    notificationUsersBirthdays?: number;
    saleStoreExternalId?: number | string | null
    saleRegionsCount?: number;
    saleStoresCount?: number;
    permissions?: string[];
    saleSeller?: { id: number } | null,
    userPosition?: IUserPositionModel | null;
    departmentBranch?: IDepartmentBranchModel | null;
    userPersonalFields?: IUserPersonalFieldModel[];
    management?: IUserModel[];
    colleagues?: IUserModel[];
}

export class UserModel extends Model implements IUserModel {
    casts = {
        userPosition: new ModelCast(UserPositionModel),
        departmentBranch: new ModelCast(DepartmentBranchModel),
        unavailableUser: new ModelCast(UserModel),
        userPersonalFields: new ModelArrayCast(UserPersonalFieldModel),
        management: new ModelArrayCast(UserModel),
        colleagues: new ModelArrayCast(UserModel),
    }
    id = 0;
    login = '';
    name = '';
    slug = '';
    email = '';
    responsibilities = '';
    birthdayAt = '';
    birthdayIndex = 0;
    saleRegionsCount = 0;
    saleStoresCount = 0;
    recentActivityAt = '';
    image = '';
    imageThumbnail = '';
    imageAvatar = '';
    phone = '';
    userPositionNameOverride = '';
    mobile = '';
    unavailableComment: string | null = null;
    unavailableReason: string | null = null;
    unavailableTo: string | null = null;
    unavailableFrom: string | null = null;
    unavailableUserId: number | null = null;
    hiredAt: string | null = null;
    widgets = null;
    saleStoreExternalId = null;
    ispringUserId = null;
    ispringLogin = null;
    outlookLogin = null;
    saleSeller = null;
    permissions: string[] = [];
    notificationUsersBirthdays = 0;
    userPersonalFields: UserPersonalFieldModel[] = [];
    management: UserModel[] = [];
    colleagues: UserModel[] = [];
    unavailableUser: UserModel | null = null;
    userPosition: UserPositionModel | null = null;
    departmentBranch: DepartmentBranchModel | null = null;

    constructor(payload: IUserModel = {}) {
        super();

        makeObservable(this, {
            login: observable,
            name: observable,
            slug: observable,
            permissions: observable,
            birthdayAt: observable,
            birthdayIndex: observable,
            saleStoreExternalId: observable,
            recentActivityAt: observable,
            hiredAt: observable,
            departmentBranch: observable,
            image: observable,
            imageThumbnail: observable,
            imageAvatar: observable,
            phone: observable,
            responsibilities: observable,
            mobile: observable,
            unavailableComment: observable,
            unavailableReason: observable,
            unavailableTo: observable,
            unavailableFrom: observable,
            unavailableUserId: observable,
            email: observable,
            widgets: observable,
            saleRegionsCount: observable,
            unavailableUser: observable,
            saleStoresCount: observable,
            ispringUserId: observable,
            ispringLogin: observable,
            outlookLogin: observable,
            userPositionNameOverride: observable,
            notificationUsersBirthdays: observable,
            userPersonalFields: observable,
            colleagues: observable,
            management: observable,
            saleSeller: observable,
            userPosition: observable,
            update: action,
            birthDayAtMoment: computed,
            recentActivityAtMoment: computed,
            previewName: computed,
            unavailableToMoment: computed,
            unavailableFromMoment: computed,
            isAvailable: computed,
            unavailableText: computed,
            isAdmin: computed,
            userPositionName: computed,
        });

        this.update(payload);
    }

    get recentActivityAtMoment() {
        return moment(this.recentActivityAt);
    }

    get birthDayAtMoment() {
        return moment(this.birthdayAt);
    }

    get unavailableToMoment() {
        if (!this.unavailableTo) {
            return moment().subtract('days', 1);
        }
        return moment(this.unavailableTo);
    }

    get unavailableFromMoment() {
        if (!this.unavailableFrom) {
            return moment().subtract('days', 1);
        }
        return moment(this.unavailableFrom);
    }

    can = (permission: string) => {
        if (this.permissions.filter(item => item === PermissionEnum.AdminPanelAdmin).length > 0) {
            return true;
        }
        return this.permissions.filter(item => item === permission || item.indexOf(permission) > -1).length > 0;
    };

    get previewName() {
        return userPreviewName(this.name);
    }

    get isAvailable() {
        const now = moment();
        if (now.isAfter(this.unavailableFromMoment) && now.isBefore(this.unavailableToMoment)) {
            return false;
        }
        return true;
    }

    get unavailableText() {
        if (this.isAvailable) {
            return '';
        }
        return `Недоступен до ${this.unavailableToMoment.format('DD MMMM')}`;
    }

    get userPositionName() {
        if (this.userPositionNameOverride) {
            return this.userPositionNameOverride;
        }
        if (this.userPosition?.name) {
            return this.userPosition.name;
        }
        return '';
    }

    get isAdmin() {
        return this.can(PermissionEnum.AdminPanelAdmin);
    }
}
