import classnames from "classnames";
import { observer } from 'mobx-react';
import React from 'react';

import { useAction } from 'shared/hooks';
import { UiButton, UiModal } from 'shared/uikit';
import { Notifier } from 'shared/utilities';

import './index.scss';

export const NotifierModal = observer(() => {
    const handleApply = useAction(() => {
        Notifier.data.isOpened = false;
        Notifier.data.resolve(true);
    });

    const handleCancel = useAction(() => {
        Notifier.data.isOpened = false;
        Notifier.data.resolve(false);
    });

    const handleClose = useAction(() => {
        if (Notifier.data.type === 'alert') {
            handleApply();
        } else {
            handleCancel();
        }
    });

    return (
        <>
            <UiModal
                style={{zIndex: 999999}}
                isOpened={Notifier.data.isOpened}
                title={Notifier.data.title}
                onClose={handleClose}
                styleBody={{ width: 450 }}
                description={Notifier.data.message}
            >
                <div className="ui-modal__actions">
                    {Notifier.data.type === 'alert' && <UiButton label={Notifier.data.buttonLabel} onClick={handleApply} isSmall/>}
                    {Notifier.data.type === 'confirm' && <UiButton label={Notifier.data.buttonLabel} onClick={handleApply} isSmall/>}
                    {Notifier.data.type === 'confirm' && (
                        <UiButton label='Отмена' onClick={handleCancel} isSmall isOutline/>
                    )}
                </div>
            </UiModal>
            <div className={classnames('u-notifier-notice', { "u-notifier-notice--visible": Notifier._notice.isOpened })}>
                <div className="u-notifier-notice__inner">
                    {Notifier._notice.message}
                </div>
            </div>
        </>

    );
});
