import React from 'react';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';

import './styles.scss';

interface Props {
    value: string;
}

@observer
class UiToc extends React.Component<Props> {
    static defaultProps: Props = {
        value: '',
    };

    render() {
        const { value } = this.props;

        if (!value) {
            return null;
        }

        return (
            <div className='ui-toc'>
                <div className='ui-toc__label'>Содержание</div>
                <div className='ui-toc__body'>{ReactHtmlParser(value)}</div>
            </div>
        );
    }
}

export default UiToc;
