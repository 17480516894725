import { computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { ModelArrayCast } from "shared/casts";
import { SurveyStatusEnum } from "shared/enums";

import { Model } from './Model';
import { SurveyQuestionModel } from "./SurveyQuestionModel";
import { UserSurveyQuestionAnswerModel } from "./UserSurveyQuestionAnswerModel";

export interface ISurveyModel {
    id?: number;
    name?: string;
    image?: string | null;
    imagesUrls?: string[];
    isAnonymous?: boolean;
    isGlobalAnswers?: boolean;
    isAbsoluteAnswers?: boolean;
    isResultsPublic?: boolean;
    createdAt?: string;
    liked?: number;
    statusId?: string;
    likesCount?: number;
    chatMessageArticlesCount?: number;
    surveyQuestions?: SurveyQuestionModel[],
    userSurveyQuestionAnswers?: UserSurveyQuestionAnswerModel[];
}

export class SurveyModel extends Model implements ISurveyModel {
    casts = {
        surveyQuestions: new ModelArrayCast(SurveyQuestionModel),
        userSurveyQuestionAnswers: new ModelArrayCast(UserSurveyQuestionAnswerModel),
    };

    id = 0;
    liked = 0;
    likesCount = 0;
    chatMessageArticlesCount = 0;
    name = '';
    statusId = '';
    image: string | null = null;
    isAnonymous = false;
    isResultsPublic = false;
    isAbsoluteAnswers = false;
    isGlobalAnswers = false;
    createdAt = '';
    imageThumbnail = {
        origin: '',
        webp: '',
    }
    imagesUrls = [];
    surveyQuestions: SurveyQuestionModel[] = [];
    userSurveyQuestionAnswers: UserSurveyQuestionAnswerModel[] = [];

    constructor(payload: ISurveyModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            liked: observable,
            likesCount: observable,
            chatMessageArticlesCount: observable,
            image: observable,
            statusId: observable,
            isAnonymous: observable,
            isGlobalAnswers: observable,
            isAbsoluteAnswers: observable,
            isResultsPublic: observable,
            imagesUrls: observable,
            createdAt: observable,
            imageThumbnail: observable,
            surveyQuestions: observable,
            createdAtMoment: computed,
            isAnswered: computed,
            isCompleted: computed,
            isActive: computed,
            isNew: computed,
            userSurveyQuestionAnswersCount: computed,
        });

        this.update(payload);
    }

    get createdAtMoment() {
        if (!this.createdAt) {
            return moment();
        }
        return moment(this.createdAt);
    }

    get isNew() {
        return this.createdAtMoment.clone().add(2, 'days').isAfter(moment());
    }

    get isActive() {
        return this.statusId === SurveyStatusEnum.Active;
    }

    get isCompleted() {
        return this.statusId === SurveyStatusEnum.Completed;
    }

    get isAnswered() {
        return this.surveyQuestions.length > 0 && this.surveyQuestions.every(surveyQuestions => !!surveyQuestions.userSurveyQuestionAnswer);
    }

    get userSurveyQuestionAnswersCount() {
        return this.surveyQuestions.reduce((count, surveyQuestion) => {
            return count + surveyQuestion.surveyQuestionAnswers.reduce((count, surveyQuestionAnswer) => {
                return count + surveyQuestionAnswer.userSurveyQuestionAnswersCount;
            }, 0)
        }, 0)
    }

    get tags() {
        if (this.isAnonymous) {
            return [{
                id: 1,
                name: 'Анонимный'
            }];
        }
        return [{
            id: 0,
            name: 'Публичный'
        }];
    }
}
