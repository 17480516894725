import { observer } from 'mobx-react';

import { tabs } from "pages/profile/utilities/tabs";
import React, { useEffect } from 'react';

import { ROUTES } from "shared/constants";
import { useNavigate } from "shared/hooks";
import { LayoutBody, LayoutContent } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiButton, UiTabs } from "shared/uikit";
import { PProfileReports } from "./components/PProfileReports";

import './styles.scss';

export const ProfileReportsPage = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        ApplicationModule.setTitle('Личный кабинет');
    });

    return (
        <>
            <div className="p-profile-header">
                <div className="p-profile-header__upper">
                    <div className="p-profile-header__user">{ApplicationModule.user.previewName}</div>
                    <div className="p-profile-header__logout">
                        <UiButton
                            label='Выйти'
                            isLink
                            onClick={() => {
                                ApplicationModule.setAccessToken(null);
                                navigate(ROUTES.LOGIN());
                            }}
                        />
                    </div>
                </div>
                {tabs().length > 0 && (
                    <div className="p-profile-header__tabs">
                        <UiTabs
                            onChange={(data) => {
                                navigate(data.value);
                            }}
                            value={ROUTES.PROFILE_REPORTS()}
                            items={tabs()}
                        />
                    </div>
                )}
            </div>
            <LayoutBody title='Личный кабинет'>
                <LayoutContent className={'p-profile__layout-content'}>
                    <PProfileReports/>
                </LayoutContent>
            </LayoutBody>
        </>
    );
});
