import React, { useRef } from 'react';
import { observer } from 'mobx-react';

import { useNavigate, useUrlParams } from "shared/hooks";
import { UiCheckbox, UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";
import { TaskModel } from "shared/models/TaskTracker";

import './index.scss';

type PropsType = {
    tasks: TaskModel[]
}
export const CListHeaderArchive = observer(({ tasks }: PropsType) => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const urlParams = useUrlParams({
        sortBy: null,
        sortType: null
    });

    const handleSort = (property: string) => () => {
        if (!urlParams.sortType || property !== urlParams.sortBy) {
            navigate(null, {
                ...urlParams,
                sortBy: property,
                sortType: 'asc'
            }, {}, null)
            return;
        }
        if (urlParams.sortType === 'asc') {
            navigate(null, {
                ...urlParams,
                sortBy: property,
                sortType: 'desc'
            }, {}, null)
            return;
        }
        navigate(null, {
            ...urlParams,
            sortBy: null,
            sortType: null
        }, {}, null);
        return;
    }

    const isSortAsc = urlParams.sortType === 'asc';

    const isChecked = tasks.every(task => task.isSelected);

    const handleCheck = () => {
        if (isChecked) {
            tasks.forEach(task => task.update({
                isSelected: 0
            }))
        } else {
            tasks.forEach(task => task.update({
                isSelected: 1
            }))
        }
    }

    return (
        <div
            ref={ref}
            className="c-tt-list-header-archive"
        >
            <div className="c-tt-list-header-archive-item">
                <UiCheckbox
                    value={+isChecked}
                    onChange={handleCheck}
                />
            </div>
            <div className="c-tt-list-header-archive-item">
                <span onClick={handleSort('name')}>Название</span>
                {(urlParams.sortBy === 'name') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-archive-item">
                <span onClick={handleSort('created_at')}>Дата создания</span>
                {(urlParams.sortBy === 'created_at') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-archive-item">
                <span onClick={handleSort('done_at')}>Дата закрытия</span>
                {(urlParams.sortBy === 'done_at') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-archive-item">
                <span onClick={handleSort('owner_user_id')}>Постановщик</span>
                {(urlParams.sortBy === 'owner_user_id') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-archive-item">
                <span onClick={handleSort('responsible_user_id')}>Ответственный</span>
                {(urlParams.sortBy === 'responsible_user_id') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
        </div>
    )
});

