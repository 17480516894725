import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: string | string[];
};

type ResponseType = {};

export const notificationsDeleteQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/notifications/delete', { json: request });
}
