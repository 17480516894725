import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { CafeMealModel } from './CafeMealModel';
import { Model } from './Model';

export interface ICafeCategoryModel {
    id?: number;
    name?: string;
    previewImage?: string;
    cafeMeals?: CafeMealModel[];
}

export class CafeCategoryModel extends Model implements ICafeCategoryModel {
    casts = {
        cafeMeals: new ModelArrayCast(CafeMealModel),
    };

    id = 0;
    name = '';
    previewImage = '';
    cafeMeals: CafeMealModel[] = [];

    constructor(payload: ICafeCategoryModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            previewImage: observable,
            cafeMeals: observable,
            update: action,
        });

        this.update(payload);
    }
}
