import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { ColumnModel } from "shared/models/TaskTracker";
import { UiButton, UiDataBoundary, UiForm, UiFormControl, UiInput, UiModal, UiModalTitle } from "shared/uikit";
import { useNavigate, useStore, useUrlParams, useValidation } from "shared/hooks";
import { columnsGetQuery, columnsSaveQuery } from "shared/queries/TaskTracker";
import { OnChangeHandlerType } from "shared/types";

import './index.scss';

type PropsType = {
    id: string | number | null,
    boardId: number | null,
    onSaved: (item: ColumnModel) => void
}

export const CColumnSave = observer(({ id, onSaved, boardId }: PropsType) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({});
    const store = useStore(() => ({
        isOpened: false,
        isLoading: true,
        isSubmitted: false,
        isSubmitting: false,
        column: new ColumnModel({ boardId, sort: 1000000 })
    }));

    useEffect(() => {
        if (!id) {
            return;
        }
        store.set("isLoading", true);

        if (id === 'new') {
            store.set("column", new ColumnModel({
                boardId: boardId || null,
                sort: 1000000
            }));
            store.set("isLoading", false);
            return;
        }

        (async () => {
            const { isSuccess, data } = await columnsGetQuery({ id: +id as number });
            if (isSuccess && data) {
                store.set('column', new ColumnModel(data.item));
            }
            store.set("isLoading", false);
        })();
    }, [store, id, boardId]);

    const validation = useValidation(store.column, (rules) => ({
        name: rules.required(),
    }));

    const handleClose = () => {
        if (store.isSubmitting) {
            return;
        }
        navigate(null, {
            ...urlParams,
            saveColumnId: null,
            boardId: null
        });

        setTimeout(() => {
            store.set('column', new ColumnModel());
            store.set('isSubmitted', false);
        }, 1000);
    }

    const handleSubmit = async () => {
        store.set("isSubmitted", true);

        if (store.isSubmitting || !validation.isValid) {
            return;
        }

        store.set("isSubmitting", true);

        const { isSuccess, data } = await columnsSaveQuery(store.column);
        if (isSuccess && data) {
            onSaved(new ColumnModel(data.item))
        }
        store.set("isSubmitting", false);
        handleClose();
    }

    const handleChange: OnChangeHandlerType<any> = (data) => {
        store.column.update({
            [data.name]: data.value
        });
    }

    return (
        <UiModal
            styleBody={{ width: 640 }}
            isOpened={id !== null && !store.isLoading}
            onClose={handleClose}
            isPortal={false}
        >
            <UiModalTitle
                title={id === 'new' ? 'Новое колонка' : (id === null ? '' : 'Редактирование колонки')}
            />
            <UiDataBoundary isLoading={store.isLoading}>
                <UiForm onSubmit={handleSubmit}>
                    <UiFormControl
                        label={'Название'}
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.name.errorMessage}
                        isRequired
                    >
                        <UiInput
                            placeholder={'Введите название колонки'}
                            name={'name'}
                            value={store.column.name}
                            onChange={handleChange}
                        />
                    </UiFormControl>
                    <div className={'ui-modal__actions'}>
                        <UiButton
                            isOutline
                            onClick={handleClose}
                            label={'Отмена'}
                        />
                        <UiButton
                            isLoading={store.isSubmitting}
                            isSubmit
                            label={'Сохранить'}
                        />
                    </div>
                </UiForm>
            </UiDataBoundary>
        </UiModal>
    )
});

