import { makeQuery } from 'shared/utilities';
import { IAnnouncementModel } from 'shared/models';

type RequestType = {
    name: string,
    detailText: string,
    images: File[],
    announcementCategoryId: null | number
}

type ResponseType = {
    item: IAnnouncementModel;
};

export const announcementsCreateQuery = async function (
    {
        images,
        name,
        detailText,
        announcementCategoryId
    }: RequestType
) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('detail_text', detailText);
    if (announcementCategoryId) {
        formData.append('announcement_category_id', announcementCategoryId.toString());
    }
    images.forEach((image) => formData.append('images[]', image));

    return await makeQuery<ResponseType>('/announcements/create', { formData });
}
