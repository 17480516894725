import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";
import { ROUTES } from "shared/constants";

import { SurveyModel } from "shared/models";
import { useStore } from "shared/hooks";
import { CSurvey } from "shared/components";
import { UiNavLink } from "shared/uikit";

import { UiSurveyQuestionAnswer } from "../UiSurveyQuestionAnswer";
import { UiModal } from "../UiModal";

import './index.scss';

type PropsType = {
    survey: SurveyModel | null,
    style?: React.CSSProperties
}

export const UiAppendedSurvey = observer(({ survey, style }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false,
    }));

    if (!survey) {
        return null;
    }

    const surveyQuestion = survey.surveyQuestions.length > 0 ? survey.surveyQuestions[0] : null;
    return (
        <div className='ui-appended-survey' style={style}>
            <div className="ui-appended-survey__date">Результаты опроса
                от {moment(survey.createdAt).format('DD.MM.YYYY')}
            </div>
            {survey.surveyQuestions.length > 0 && (
                <UiNavLink to={ROUTES.SURVEY(survey.id)} className="ui-appended-survey__name">{survey.name}</UiNavLink>
            )}
            {surveyQuestion && (
                <div className="ui-appended-survey-question">
                    <div className="ui-appended-survey-question__name">{surveyQuestion.name}</div>
                    <div className="ui-appended-survey-question__items">
                        {surveyQuestion.surveyQuestionAnswers.map(surveyQuestionAnswer => (
                            <UiSurveyQuestionAnswer
                                key={surveyQuestionAnswer.id}
                                survey={survey}
                                surveyQuestion={surveyQuestion}
                                surveyQuestionAnswer={surveyQuestionAnswer}
                            />
                        ))}
                    </div>
                </div>
            )}
            {survey.surveyQuestions.length > 1 && (
                <div className="ui-appended-survey__open" onClick={() => store.set("isOpened", true)}>Подробнее</div>
            )}
            <div className="ui-appended-survey__details">
                Всего проголосовало: <span>{surveyQuestion?.userSurveyQuestionAnswersCount || 0}</span>
            </div>
            <UiModal title={survey.name} isOpened={store.isOpened} onClose={() => store.set("isOpened", false)}>
                <CSurvey survey={survey}/>
            </UiModal>
        </div>
    )
});
