import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiForm, UiFormControl, UiInput, UiModal, UiTmpFileUpload } from "shared/uikit";
import { useRouteParams, useStore } from "shared/hooks";
import { chatRoomsUpdateQuery } from "shared/queries";
import { Service } from "pages/chat/service";
import { Store } from "pages/chat/store";

type PropsType = {
    children?: React.ReactNode,
    className?: string
}

export const ChatRoomEdit = observer((
    {
        children,
        className
    }: PropsType
) => {
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        isOpened: false,
        isSubmitting: false,
        isSubmitted: false,
        name: '',
        image: null,
    }));

    const handleSubmit = async () => {
        store.set("isSubmitted", true);
        if (!store.name.trim()) {
            return;
        }
        store.set("isSubmitting", true);
        await chatRoomsUpdateQuery({
            id: routeParams.id,
            name: store.name,
            image: store.image
        });
        await Service.fetchChatRoom(routeParams.id);
        store.set("isOpened", false);
        store.set("isSubmitting", false);
    }

    const handleOpen = () => {
        store.set("name", Store.chatRoom?.name);
        store.set("isOpened", true);
    }

    return (
        <>
            <div className={className} onClick={handleOpen}>
                {children}
            </div>
            <UiModal
                onClose={() => store.set("isOpened", false)}
                isOpened={store.isOpened}
                title='Редактирование диалога'
            >
                <UiForm onSubmit={handleSubmit}>
                    <UiFormControl
                        style={{ marginBottom: 16 }}
                    >
                        <UiFormControl
                            style={{ marginBottom: 16 }}
                            isError={store.isSubmitted && !store.name}
                            label='Название'
                        >
                            <UiInput
                                isError={store.isSubmitted && !store.name}
                                placeholder='Введите название'
                                onChange={({ value }) => store.set('name', value)}
                                value={store.name}
                            />
                        </UiFormControl>
                        <UiFormControl
                            style={{ marginBottom: 16 }}
                            isError={store.isSubmitted && !store.name}
                            label={'Обновить картинку для диалога'}
                        >
                            <UiTmpFileUpload
                                type={'image'}
                                onChange={({ value }) => store.set('image', value)}
                                value={store.image}
                            />
                        </UiFormControl>
                    </UiFormControl>
                    <div className="ui-modal__actions">
                        <UiButton
                            isLoading={store.isSubmitting}
                            isSubmit
                            label={'Сохранить'}
                        />
                        <UiButton
                            label='Отмена'
                            onClick={() => store.set("isOpened", false)}
                            isOutline
                        />
                    </div>
                </UiForm>
            </UiModal>
        </>
    );
});
