import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { set } from "mobx";
import classnames from "classnames";

import { ReservationModel } from "shared/models";
import { OnChangeHandlerType } from "shared/types";
import { COLORS, ICONS } from "shared/constants";

import { UiScrollbar } from "../UiScrollbar";
import { UiIcon } from "../UiIcon";
import { UiReservation } from "../UiReservation";
import { UiButton } from "../UiButton";

import './styles.scss';

type PropsType = {
    label?: string;
    name?: string;
    isPanel?: boolean;
    actions?: React.ReactNode,
    onChange?: OnChangeHandlerType<ReservationModel | null>;
    value?: ReservationModel | null,
    items: ReservationModel[]
}

export const UiReservations = observer((
    {
        label = 'Прикрепить бронирование',
        value = null,
        onChange,
        actions,
        name = 'reservation_id',
        items = [],
        isPanel = false,
    }: PropsType
) => {
    const store = useLocalObservable(() => ({
        set: set,
        isEdit: false,
    }));
    useEffect(() => {
        if (!value) {
            store.set(store, 'isEdit', true);
        }
    }, [store, value]);

    const handleChange = useCallback((value: ReservationModel | null) => {
        onChange && onChange({
            control: 'ui-reservations',
            name,
            value,
        })
    }, [onChange, name]);

    const classNames = useMemo(() => classnames('ui-reservations', {
        'ui-reservations--panel': isPanel
    }), [isPanel]);

    if (value) {
        return (
            <div className={classNames}>
                <UiReservation item={value}/>
                <div className="ui-reservations__actions">
                    <UiButton
                        style={{ marginLeft: 'auto' }}
                        isLink
                        isSmall
                        label='Изменить'
                        iconAfter={<UiIcon icon={ICONS.EDIT} size={12} color={COLORS.BROWN_1}/>}
                        onClick={() => handleChange(null)}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={classNames}>
            {label && (
                <div className="ui-reservations__header">
                    <UiIcon icon={ICONS.MEETING_SPACE} size={20}/>
                    <div className="ui-reservations__label">
                        {label}
                    </div>
                </div>
            )}
            {items.length > 0 && (
                <div className="ui-reservations-items">
                    <UiScrollbar>
                        <div className="ui-reservations-items__inner">
                            {items.map(item => (
                                <UiReservation
                                    key={item.id}
                                    item={item}
                                    onClick={() => handleChange(item)}
                                />
                            ))}
                        </div>
                    </UiScrollbar>
                </div>
            )}
            {actions && (
                <div className="ui-reservations__actions">
                    {actions}
                </div>
            )}
        </div>
    );
});
