import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IHistoryItemModel {
    id?: number;
    sort?: number,
    name?: string,
    description?: string,
    colorSlide?: string,
    colorText?: string,
    colorTimeline?: string,
    image?: string,
    imageThumbnail?: string,
    backgroundThumbnail?: string,
    yearSlide?: number,
    yearTimeline?: string,
}

export class HistoryItemModel extends Model implements IHistoryItemModel {
    id = 0;
    sort = 0;
    name = '';
    description = '';
    colorSlide = '';
    colorText = '';
    colorTimeline = '';
    image = '';
    backgroundThumbnail = '';
    imageThumbnail = ''
    yearSlide = 0;
    yearTimeline = '';

    constructor(payload: IHistoryItemModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            description: observable,
            colorSlide: observable,
            colorText: observable,
            colorTimeline: observable,
            image: observable,
            imageThumbnail: observable,
            backgroundThumbnail: observable,
            yearSlide: observable,
            yearTimeline: observable,
            sort: observable,
            update: action,
        });

        this.update(payload);
    }
}
