import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import './styles.scss';

type PropsType = {
    children?: React.ReactNode,
    className?: string,
    maxWidth?: string | number,
    columns?: number | number[] | string,
    gap?: number | [number, number],
    style?: React.CSSProperties,
}

export const UiGrid = observer((
    {
        children,
        className,
        columns = 1,
        maxWidth = '100%',
        gap,
        style = {}
    }: PropsType
) => {
    const styles = useMemo(() => {
        let gridGap = gap
            ? (Array.isArray(gap) ? gap : [gap, gap])
            : [0, 0];

        let gridTemplateColumns = '';
        if (typeof columns === 'string') {
            gridTemplateColumns = columns;
        } else  if (Array.isArray(columns)) {
            gridTemplateColumns = columns.map(column => `${column}fr`).join(' ');
        } else {
            gridTemplateColumns = `repeat(${columns}, minmax(0, 1fr))`;
        }

        return {
            maxWidth,
            gridTemplateColumns,
            gridColumnGap: gridGap[0],
            gridRowGap: gridGap[1],
            ...style
        };
    }, [maxWidth, columns, style, gap])

    return (
        <div className={classnames('ui-grid', className)} style={styles}>
            {children}
        </div>
    );
});
