import { useEffect } from "react";
import { BroadcastModule } from "shared/modules";

type BroadcastType = {
    event: string,
    callback: (data: any) => void
}

export const useBroadcast = function (broadcast: BroadcastType) {
    useEffect(() => {
        BroadcastModule.subscribe(broadcast);
        return () => BroadcastModule.unsubscribe(broadcast);
    }, [broadcast])
}
