import React  from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UiCalendarItem } from "shared/uikit";

import { Store } from "../../Store";
import { Service } from "../../Service";

import './styles.scss';

export const CalendarMonth = observer(() => {

    return (
        <div className={classnames("calendar-month", {
            "calendar-month--5": !Store.isFullWeek
        })}>
            <div className="calendar-month-cell calendar-month-cell--header">ПН</div>
            <div className="calendar-month-cell calendar-month-cell--header">ВТ</div>
            <div className="calendar-month-cell calendar-month-cell--header">СР</div>
            <div className="calendar-month-cell calendar-month-cell--header">ЧТ</div>
            <div className="calendar-month-cell calendar-month-cell--header">ПТ</div>
            <div className="calendar-month-cell calendar-month-cell--header">СБ</div>
            <div className="calendar-month-cell calendar-month-cell--header">ВС</div>
            {Store.monthItems.map((day, dayIndex) => {
                const className = classnames('calendar-month-cell', 'calendar-month-cell--day', {
                    'calendar-month-cell--today': day.isToday,
                    'calendar-month-cell--outer': day.isOuter,
                });

                return (
                    <div
                        key={day.id}
                        className={className}
                        onDoubleClick={() => {
                            Service.showEditModal(
                                day.moment.clone().set('hours', 8).format()
                            )
                        }}
                    >
                        <div className="calendar-month-cell__day">
                            <span>{day.moment.format('D')}</span>
                        </div>
                        {Store.calendarItemsByDate[day.id] && Store.calendarItemsByDate[day.id].map(item => (
                            <UiCalendarItem
                                key={item.id}
                                item={item}
                                onClick={() => Service.getCalendarItem(item)}
                                toLeft={dayIndex % 7 > 4}
                            />
                        ))}
                    </div>
                )
            })}
        </div>
    );
});
