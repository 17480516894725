import { makeQuery } from 'shared/utilities';
import { IValuesItemModel } from 'shared/models';

type ResponseType = {
    items: IValuesItemModel[];
};

export const valuesItemsQuery = async function () {
    return await makeQuery<ResponseType>('/values-items');
}
