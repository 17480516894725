import { BarElement, CategoryScale, Chart as ChartJS, Legend, LegendItem, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import classnames from "classnames";
import { observer } from 'mobx-react';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';

import { useMedia, useStore } from "shared/hooks";
import { salesFiltersQuery, salesQuery } from "shared/queries";
import { CollectionItemIdType, CollectionItemType } from "shared/types";
import { UiButton, UiDataBoundary, UiDatePicker, UiFormControl, UiGrid, UiSelect, UiSelectMultiple } from "shared/uikit";
import { Notifier } from "shared/utilities";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            maxHeight: 300
        },
        title: {
            display: true,
        },
    },
};

export const PProfileReports = observer(() => {
    const chartRef = useRef<ChartJS<"line", number[], string>>(null);
    const store = useStore(() => ({
        isSubmitting: false,
        isSubmitted: false,
        isSuccess: false,
        isLoading: true,
        errorMessage: '',

        isManager: false,

        from: moment().startOf('month').subtract(1, 'month').format('Y-MM-DD'),
        to: moment().startOf('month').format('Y-MM-DD'),
        dateType: 'date_day',
        groupBy: 'sale_store_id',
        saleStoreId: null as CollectionItemIdType[] | null,
        saleRegionId: null as CollectionItemIdType[] | null,
        saleProductId: null as CollectionItemIdType[] | null,
        saleSellerId: null as CollectionItemIdType[] | null,
        saleProductCategoryId: null as CollectionItemIdType[] | null,

        legend: [] as LegendItem[],
        labels: [] as string[],
        datasets: [] as { label: string, data: number[] | string[] }[],
        saleStores: [] as CollectionItemType<{ saleRegionId: number }>[],
        saleRegions: [] as CollectionItemType[],
        saleSellers: [] as CollectionItemType<{ saleStoreId: number }>[],
        saleProducts: [] as CollectionItemType<{ saleProductCategoryId: string }>[],
        saleProductCategories: [] as CollectionItemType[],
    }))

    const fetchFilters = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await salesFiltersQuery();

        if (isSuccess && data) {
            store.set("isManager", data.isManager);
            store.set("saleStores", data.saleStores);
            store.set("saleRegions", data.saleRegions);
            store.set("saleProducts", data.saleProducts);
            store.set("saleSellers", data.saleSellers);
            store.set("saleProductCategories", data.saleProductCategories);
        }
        store.set("isLoading", false);
    }, [store]);

    useEffect(() => {
        fetchFilters()
    }, [fetchFilters]);

    const saleRegionsSaleStores = useMemo(() => {
        const saleRegionId = store.saleRegionId || [];
        if (saleRegionId.length === 0) {
            return store.saleStores
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name));
        }
        return store.saleStores.slice()
            .filter(saleStore => saleRegionId.indexOf(saleStore.saleRegionId) > -1)
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [store.saleRegionId, store.saleStores]);

    const saleProducts = useMemo(() => {
        const saleProductCategoryId = store.saleProductCategoryId || [];
        if (saleProductCategoryId.length === 0) {
            return store.saleProducts;
        }
        return store.saleProducts.filter(item => saleProductCategoryId.indexOf(item.saleProductCategoryId) > -1);
    }, [store.saleProductCategoryId, store.saleProducts]);

    const storesSellers = useMemo(() => {
        const saleStoreId = store.saleStoreId || [];
        if (saleStoreId.length === 0) {
            return store.saleSellers;
        }
        return store.saleSellers.filter(seller => saleStoreId.indexOf(seller.saleStoreId) > -1);
    }, [store.saleSellers, store.saleStoreId]);

    const handleSubmit = async () => {
        store.set("isSubmitted", false);
        store.set("isSubmitting", true);

        const { isSuccess, data, errorMessage } = await salesQuery({
            from: store.from,
            to: store.to,
            groupBy: store.groupBy,
            dateType: store.dateType,
            saleRegionId: store.saleRegionId,
            saleStoreId: store.saleStoreId,
            saleSellerId: store.saleSellerId,
            saleProductId: store.saleProductId,
            saleProductCategoryId: store.saleProductCategoryId,
        });

        if (isSuccess && data) {
            const datasetsWarningCount = 30;
            if (data.datasets.length > datasetsWarningCount) {
                const warning = (
                    <>
                        Сформированный отчёт содержит {data.datasets.length} показателей.
                        <br/>
                        Отображение более {datasetsWarningCount} показателей ведёт к падению производительности и
                        информативности отчёта.
                        <br/><br/>
                        Рекомендуется изменить настройки воронки.
                        <br/><br/>
                        Вы желаете продолжить?
                    </>
                );
                const isForced = await Notifier.confirm('Предупреждение', warning);
                if (!isForced) {
                    store.set("isSubmitting", false);
                    return;
                }
            }
            store.set("labels", data.labels);
            store.set("datasets", data.datasets);
        }
        if (errorMessage) {
            store.set('errorMessage', errorMessage);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isSubmitted", true);
        store.set("isSubmitting", false);
    }

    const { value: columnsSettings } = useMedia({
        is320: 1,
        is768: 2,
        is1440: 4
    });

    const { value: columnsFilters } = useMedia({
        is320: 1,
        is768: 2,
        is1440: 3,
        is1920: 5
    });

    const htmlLegendPlugin = useMemo(() => {
        return {
            id: 'htmlLegend',
            afterUpdate(chart: ChartJS) {
                if (!chart || !chart.options?.plugins?.legend?.labels?.generateLabels) {
                    return;
                }
                store.set("legend", chart.options.plugins.legend.labels.generateLabels(chart));
            }
        }
    }, [store]);

    return (
        <div className="p-profile__sections">
            <div className="p-profile-section">
                <UiDataBoundary isLoading={store.isLoading}>
                    <div className="p-profile-section__header">
                        <UiGrid
                            columns={columnsSettings}
                            gap={12}
                            style={{ marginLeft: 0, marginBottom: 16, maxWidth: 2900 }}
                        >
                            <UiFormControl label='От'>
                                <UiDatePicker
                                    value={store.from}
                                    name={'from'}
                                    onChange={store.handleChange}
                                />
                            </UiFormControl>
                            <UiFormControl label={'До'}>
                                <UiDatePicker
                                    value={store.to}
                                    name={'to'}
                                    onChange={store.handleChange}
                                />
                            </UiFormControl>
                            <UiFormControl label='Разрез по времени'>
                                <UiSelect
                                    style={{ marginBottom: 0 }}
                                    value={store.dateType}
                                    name={'dateType'}
                                    onChange={store.handleChange}
                                    items={[
                                        { id: 'date_month', name: 'По месяцам' },
                                        { id: 'date_week', name: 'По неделям' },
                                        { id: 'date_day', name: 'По дням' }
                                    ]}
                                />
                            </UiFormControl>
                            <UiFormControl label='Группировать по'>
                                <UiSelect
                                    style={{ marginBottom: 0 }}
                                    value={store.groupBy}
                                    name={'groupBy'}
                                    onChange={store.handleChange}
                                    items={store.isManager ? [
                                        { id: 'sale_store_id', name: 'Складу/Бутику' },
                                        { id: 'sale_seller_id', name: 'Сотруднику' },
                                        { id: 'sale_region_id', name: 'Региону' },
                                        { id: 'sale_product_category_id', name: 'Категории продукта' },
                                        { id: 'sale_product_id', name: 'Продукту' },
                                        { id: 'sale_manager_id', name: 'Менеджеру' },
                                    ] : [
                                        { id: 'sale_store_id', name: 'Складу/Бутику' },
                                        { id: 'sale_seller_id', name: 'По себе' },
                                        { id: 'sale_product_category_id', name: 'Категории продукта' },
                                        { id: 'sale_product_id', name: 'Продукту' },
                                    ]}
                                />
                            </UiFormControl>
                        </UiGrid>
                        <h3 className='typography'>Воронка</h3>
                        <UiGrid
                            columns={columnsFilters}
                            gap={12}
                            style={{ marginLeft: 0, maxWidth: 2900 }}
                        >
                            {store.saleRegions.length > 1 && (
                                <UiFormControl>
                                    <UiSelectMultiple
                                        placeholder={'Регионы'}
                                        isMultiple
                                        name={'saleRegionId'}
                                        value={store.saleRegionId}
                                        items={store.saleRegions}
                                        onChange={(data) => {
                                            store.set('saleStoreId', null);
                                            store.handleChange(data);
                                        }}
                                    />
                                </UiFormControl>
                            )}
                            {(store.saleRegions.length > 0 || store.saleStores.length > 1) && (
                                <UiFormControl>
                                    <UiSelectMultiple
                                        placeholder={'Склад/Бутик'}
                                        isMultiple
                                        name={'saleStoreId'}
                                        value={store.saleStoreId}
                                        items={saleRegionsSaleStores}
                                        onChange={store.handleChange}
                                    />
                                </UiFormControl>
                            )}
                            {store.saleSellers.length > 1 && (
                                <UiFormControl>
                                    <UiSelectMultiple
                                        placeholder={'Сотрудник'}
                                        isMultiple
                                        name={'saleSellerId'}
                                        value={store.saleSellerId}
                                        items={storesSellers}
                                        onChange={store.handleChange}
                                    />
                                </UiFormControl>
                            )}
                            <UiFormControl>
                                <UiSelectMultiple
                                    isMultiple
                                    placeholder={'Категория продукта'}
                                    name={'saleProductCategoryId'}
                                    value={store.saleProductCategoryId}
                                    items={store.saleProductCategories}
                                    onChange={store.handleChange}
                                />
                            </UiFormControl>
                            <UiFormControl>
                                <UiSelectMultiple
                                    isMultiple
                                    placeholder={'Продукт'}
                                    name={'saleProductId'}
                                    value={store.saleProductId}
                                    items={saleProducts}
                                    onChange={store.handleChange}
                                />
                            </UiFormControl>
                        </UiGrid>
                        <div style={{ display: 'flex', width: '100%', marginTop: 16 }}>
                            <UiButton
                                style={{ marginLeft: 'auto' }} isSecondary
                                label={'Сформировать'}
                                isLoading={store.isSubmitting}
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                    <div className="p-profile-section__inner">
                        {(store.isSubmitting || store.isSubmitted) && (
                            <UiDataBoundary
                                isLoading={store.isSubmitting}
                                isError={!store.isSuccess}
                                errorMessage={store.errorMessage}
                            >
                                <div className="p-profile-chart">
                                    <div className="p-profile-chart__inner">
                                        <Line
                                            style={{
                                                width: '100%',
                                                height: '85vh'
                                            }}
                                            ref={chartRef}
                                            options={options}
                                            plugins={[htmlLegendPlugin]}
                                            data={{ labels: store.labels, datasets: store.datasets }}
                                        />
                                    </div>
                                    <div className="p-profile-chart__aside">
                                        {store.legend.map((legend) => {
                                            return (
                                                <div key={legend.datasetIndex}
                                                     className={classnames('p-profile-chart-legend', {
                                                         'p-profile-chart-legend--hidden': legend.hidden
                                                     })}
                                                     onClick={() => {
                                                         if (!chartRef?.current || legend.datasetIndex === undefined) {
                                                             return;
                                                         }
                                                         chartRef.current.setDatasetVisibility(legend.datasetIndex, !chartRef.current.isDatasetVisible(legend.datasetIndex))
                                                         chartRef.current.update('active')
                                                     }}
                                                     title={legend.text}
                                                >
                                                    <i style={{ backgroundColor: legend.fillStyle as string }}/>
                                                    <span>{legend.text}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </UiDataBoundary>
                        )}
                    </div>
                </UiDataBoundary>
            </div>
        </div>
    );
});
