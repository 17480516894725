import React, { useMemo } from "react";
import lodash from "lodash";

import {
    WCafePopular,
    WCafePreview,
    WCalendar,
    WEssentials,
    WImprovementProposal,
    WNewUsers,
    WUsersBirthdays,
    WWebResources
} from "shared/widgets";
import { ApplicationModule } from "shared/modules";
import { PermissionEnum } from "shared/enums";

type WidgetWithComponentType = {
    id: string,
    name: string,
    value: number,
    Widget: React.Component | React.FC
};

const widgetList: WidgetWithComponentType[] = [{
    id: 'WImprovementProposal',
    value: 1,
    name: 'Предложения по улучшению',
    Widget: WImprovementProposal,
}, {
    id: 'WEssentials',
    value: 1,
    name: 'Важная информация',
    Widget: WEssentials,
}, {
    id: 'WCalendar',
    value: 1,
    name: 'Календарь',
    Widget: WCalendar,
}, {
    id: 'WUsersBirthdays',
    name: 'Дни рождения',
    value: 1,
    Widget: WUsersBirthdays
}, {
    id: 'WidgetCafePreview',
    name: 'Столовая сегодня',
    value: 1,
    Widget: WCafePreview
}, {
    id: 'WidgetUsersNew',
    name: 'Новые сотрудники',
    value: 1,
    Widget: WNewUsers
}, {
    id: 'WidgetCafePopular',
    name: 'Популярные блюда',
    value: 1,
    Widget: WCafePopular
}, {
    id: 'WidgetCafePopular',
    name: 'Популярные блюда',
    value: 1,
    Widget: WCafePopular
}, {
    id: 'WWebResources',
    name: 'Наши ресурсы',
    value: 1,
    Widget: WWebResources
}];
const widgetListById = lodash.keyBy(widgetList, 'id');

type OptionsType = {
    withDisabled?: boolean,
    exclude?: string[],
}

export const useUserWidgets = (
    {
        withDisabled = false,
        exclude: _exclude = []
    }: OptionsType = {}
): WidgetWithComponentType[] => {
    const _userWidgets = ApplicationModule.user.widgets;

    return useMemo(() => {
        const exclude = [..._exclude];
        if (!ApplicationModule.user.can(PermissionEnum.WebCafe)) {
            exclude.push('WidgetCafePopular');
            exclude.push('WidgetCafePreview');
        }

        let widgets: WidgetWithComponentType[] = [
            widgetListById['WImprovementProposal'],
            widgetListById['WEssentials'],
        ];
        const widgetsAdded: string[] = [
            'WImprovementProposal',
            'WEssentials',
        ];

        (_userWidgets || []).forEach(({ id, value }) => {
            widgetsAdded.push(id);
            if (widgetListById[id]) {
                widgets.push({
                    id: id,
                    value: value ? +value : 0,
                    name: widgetListById[id].name,
                    Widget: widgetListById[id].Widget,
                });
            }
        });
        widgetList.forEach(widget => {
            if (widgetsAdded.indexOf(widget.id) === -1) {
                widgets.push(widget);
            }
        });

        return widgets.filter(widget => withDisabled ? true : widget.value)
            .filter(widget => exclude?.indexOf(widget.id) === -1);
    }, [withDisabled, _userWidgets, _exclude]);
}
