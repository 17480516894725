import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import {
    UiButton,
    UiCard,
    UiCardItem,
    UiCardTitle, UiDataBoundary,
    UiFormControl,
    UiIcon,
    UiInput,
    UiTextarea,
    UiTmpFileUpload,
} from 'shared/uikit';
import { COLORS, ICONS } from 'shared/constants';
import { ModalCountdown, ModalForm } from 'shared/components';

import Service from './Service';
import Store from './Store';

import './styles.scss';

export const WCafePopular = observer(() => {
    const colors = [COLORS.GOLD, COLORS.SILVER, COLORS.BRONZE];

    useEffect(() => {
        Service.getData();
    }, [])

    return (
        <>
            <UiCard className='w-cafe-popular'>
                <UiDataBoundary isLoading={Store.isLoading} isError={!Store.isSuccess}>
                    <UiCardTitle label='Популярные блюда' collapsable={'WCafePopular'} />
                    {Store.items.map((item, index) => (
                        <UiCardItem key={item.id} className='w-cafe-popular-item'>
                            <div
                                className='w-cafe-popular-item__rating'
                                style={{
                                    color: colors[index] || COLORS.BLACK,
                                }}
                            >
                                <UiIcon size={20} color={colors[index] || COLORS.BLACK} icon={ICONS.HEART} />
                                <span>{item.likesCount}</span>
                            </div>
                            <div className='w-cafe-popular-item__name'>{item.name}</div>
                        </UiCardItem>
                    ))}
                    <UiButton label='Предложить блюдо' style={{ marginTop: 25 }} onClick={Service.handleShow} />
                </UiDataBoundary>
            </UiCard>
            <ModalForm
                isOpened={Store.modal.form}
                title='Предложить блюдо'
                description='Если вы хотите поделиться идеей, которая улучшит наше меню — напишите ваши предложения в форме и мы обязательно их рассмотрим'
                onClose={Service.handleClose}
                onSubmit={Service.handleSubmit}
                onClear={Store.form.reset}
            >
                <UiFormControl
                    label='Название блюда'
                    className='mb-20'
                    errorMessage={Store.form.validations.name.errorMessage}
                >
                    <UiInput
                        placeholder='Введите название блюда'
                        name='name'
                        value={Store.form.values.name}
                        onChange={Service.handleChangeForm}
                        isValid={Store.form.validations.name.isValid}
                    />
                </UiFormControl>
                <UiFormControl
                    label='Описание'
                    className='mb-20'
                    errorMessage={Store.form.validations.description.errorMessage}
                >
                    <UiTextarea
                        placeholder='Опишите рецепт блюда, или оставьте ссылку на рецепт'
                        name='description'
                        value={Store.form.values.description}
                        onChange={Service.handleChangeForm}
                        isValid={Store.form.validations.description.isValid}
                    />
                </UiFormControl>
                <UiTmpFileUpload
                    className='mb-20'
                    name='tmpFileId'
                    value={Store.form.values.tmpFileId}
                    onChange={Service.handleChangeForm}
                />
            </ModalForm>
            <ModalCountdown
                isOpened={Store.modal.countdown}
                onFinish={Service.handleConfirm}
                onCancel={Service.handleCancel}
                title={'Ваше сообщение почти отправлено'}
                description={
                    'Если вы передумали отправлять сообщение, вы можете сделать отмену. По окончании срока таймера сообщение отправится и отмену сделать будет невозможно. Закрывая это уведомление, сообщение отправится автоматически.'
                }
            />
        </>
    );
})
