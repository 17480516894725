import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: string | string[],
    moduleId?: string
}
type ResponseType = {};

export const notificationsReadQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/notifications/read', { json: request });
}
