import { makeQuery } from 'shared/utilities';
import { IDepartmentBranchModel } from 'shared/models';

type RequestType = {
    id: number | string
}

type ResponseType = {
    item: IDepartmentBranchModel;
};

export const departmentBranchesGetQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/department-branches/get', { params });
}
