import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiAvatar, UiButton, UiCard, UiCardTitle, UiIcon, UiNavLink } from 'shared/uikit';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { usersNewQuery } from "shared/queries";

import './styles.scss';

export const WNewUsers = observer(() => {
    const store = useStore(() => ({
        items: [] as UserModel[],
        isLoading: true,
        isSuccess: false,
        isOpened: false
    }));

    const fetchItems = useCallback(async () => {
        store.set('isLoading', true);
        store.set('isSuccess', false);
        const response = await usersNewQuery();
        if (response.isSuccess && response.data) {
            store.set('items', response.data.items.map(item => new UserModel(item)));
            store.set('isSuccess', true);
        }
        store.set('isLoading', false);
    }, [store]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const handleToggle = useCallback(() => {
        store.set("isOpened", !store.isOpened);
    }, [store])

    if (store.items.length === 0 && !store.isLoading) {
        return null;
    }

    return (
        <UiCard isLoading={store.isLoading} className='w-new-users'>
            <UiCardTitle
                label='Новые сотрудники'
                collapsable={'w-new-users'}
                count={store.items.length}
            />
            <div className='w-new-users__items'>
                {store.items.slice(0, store.isOpened ? 1000 : 5).map(user => (
                    <UiNavLink to={ROUTES.USER(user.slug)} key={user.id} className='w-new-users-item'>
                        <div className="w-new-users-item__avatar">
                            <UiAvatar size={40} label={user.previewName} image={user.imageAvatar}/>
                        </div>
                        <div className='w-new-users-item__inner'>
                            <div className="w-new-users-item__info">
                                <div className="w-new-users-item__name">{user.previewName}</div>
                                <div className="w-new-users-item__description">
                                    {[user.userPositionName, user.departmentBranch?.name].filter(item => !!item).join(', ')}
                                </div>
                            </div>
                            <div className="w-new-users-item__badge">NEW</div>
                        </div>
                    </UiNavLink>
                ))}
                <span/>
            </div>
            {store.items.length > 5 && (
                <UiButton
                    isLink
                    label={store.isOpened ? 'Свернуть' : 'Развернуть'}
                    onClick={handleToggle}
                    iconAfter={
                        <UiIcon
                            icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                            color={COLORS.BROWN_1}
                            size={12}
                        />
                    }
                />
            )}
        </UiCard>
    );
});
