import React, { useEffect } from 'react';
import { observer } from "mobx-react";

import { LayoutBody, LayoutContent } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiButton } from 'shared/uikit';
import { useNavigate } from "shared/hooks";


import './styles.scss';

const image = require('./assets/image.png');
const bg = require('./assets/bg.png');

export const InDevelopmentPage = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        ApplicationModule.setTitle('Страница в разработке');
    });

    return (
        <LayoutBody>
            <LayoutContent className='p-in-development' isFill>
                <div className="p-in-development__inner">
                    <div className="p-in-development__title">
                        Страница находится в разработке
                    </div>
                    <div className="p-in-development__description">
                        Приносим свои извинения. <br/>
                        С уважением, администрация сайта.
                    </div>
                    <UiButton
                        isSecondary
                        onClick={() => navigate(-1)}
                        label='Вернуться назад'
                        style={{ width: 220 }}
                    />
                </div>
                <img src={image} alt="" className="p-in-development__image"/>
                <img className="p-in-development__background" src={bg} alt=""/>
            </LayoutContent>
        </LayoutBody>

    )
})
