import lodash from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { ModelArrayCast, ModelCast } from 'shared/casts';

import { Model } from './Model';
import { currency, number } from 'shared/utilities';

type NullableValueType = number | string | null;

export interface IUserDashboardBonusesModel {
    groups: { id: NullableValueType; name: NullableValueType }[];
    items: { groupId: NullableValueType; name: NullableValueType; value: NullableValueType }[];
}

export interface IUserDashboardWidgetItemBonusModel {
    statusId?: 'in_progress' | 'received';
    withIcon?: boolean;
    name?: string;
    value?: number;
    items: IUserDashboardWidgetItemBonusModel[];
}

export class UserDashboardWidgetItemBonusModel extends Model implements IUserDashboardWidgetItemBonusModel {
    casts = {
        items: new ModelArrayCast(UserDashboardWidgetItemBonusModel),
    };

    statusId: IUserDashboardWidgetItemBonusModel['statusId'] = 'in_progress';
    withIcon = true;
    name = '';
    value = 0;
    items: UserDashboardWidgetItemBonusModel[] = [];

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            statusId: observable,
            withIcon: observable,
            name: observable,
            value: observable,
            items: observable,

            update: action,
        });

        this.update(payload);
    }
}

export interface IUserDashboardWidgetItemModel {
    storeId?: NullableValueType;
    groupId?: NullableValueType;
    plan?: NullableValueType;
    planHint?: NullableValueType;
    fact?: NullableValueType;
    factHint?: NullableValueType;
    planIsDone?: number | boolean | null;
    value?: number;
    valueCompare?: string[][];
    valueHint?: NullableValueType;
    valueLabel?: NullableValueType;
    bonus?: IUserDashboardWidgetItemBonusModel | null;
}

export class UserDashboardWidgetItemModel extends Model implements IUserDashboardWidgetItemModel {
    casts = {
        bonus: new ModelCast(UserDashboardWidgetItemBonusModel),
    };

    storeId: NullableValueType = null;
    groupId: NullableValueType = null;
    plan: NullableValueType = null;
    planHint: NullableValueType = null;
    fact: NullableValueType = null;
    factHint: NullableValueType = null;
    planIsDone: number | boolean | null = null;
    value: number = 0;
    valueCompare: string[][] = [];
    valueHint: NullableValueType = null;
    valueLabel: NullableValueType = null;
    bonus: UserDashboardWidgetItemBonusModel | null = null;

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            storeId: observable,
            groupId: observable,
            planHint: observable,
            fact: observable,
            factHint: observable,
            planIsDone: observable,
            value: observable,
            valueHint: observable,
            valueLabel: observable,
            valueCompare: observable,
            bonus: observable,
            color: computed,
            update: action,
        });

        this.update(payload);
    }

    get color() {
        if (this.value > 100) {
            return 'darkgreen';
        }

        if (this.value === 100) {
            return 'green';
        }

        if (this.value < 50) {
            return 'red';
        }

        return 'yellow';
    }

    format(value: number | string | null, format: string = 'currency') {
        if (format === 'raw') {
            return value;
        }

        return currency(number(value), '');
    }
}

export interface IUserDashboardWidgetDictionaryItemModel {
    id?: NullableValueType;
    name?: NullableValueType;
    description?: NullableValueType;
}

export class UserDashboardWidgetDictionaryItemModel extends Model implements IUserDashboardWidgetDictionaryItemModel {
    casts = {};

    id: NullableValueType = null;
    name: NullableValueType = null;
    description: NullableValueType = null;

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            description: observable,
        });

        this.update(payload);
    }
}

export interface IUserDashboardWidgetUnitsModel {
    long?: string;
    short?: string;
}

export class UserDashboardWidgetUnitsModel extends Model implements IUserDashboardWidgetUnitsModel {
    long = '₽';
    short = '₽';

    constructor(payload: IUserDashboardWidgetUnitsModel = {}) {
        super();

        makeObservable(this, {
            long: observable,
            short: observable,
            update: action,
        });

        this.update(payload);
    }
}

export interface IUserDashboardWidgetLabelsModel {
    plan?: string;
    fact?: string;
}

export class UserDashboardWidgetLabelsModel extends Model implements IUserDashboardWidgetLabelsModel {
    plan = 'План';
    fact = 'Факт';

    constructor(payload: IUserDashboardWidgetLabelsModel = {}) {
        super();

        makeObservable(this, {
            fact: observable,
            plan: observable,
            update: action,
        });

        this.update(payload);
    }
}

export interface IUserDashboardWidgetFormatsModel {
    plan?: string;
    fact?: string;
}

export class UserDashboardWidgetFormatsModel extends Model implements IUserDashboardWidgetFormatsModel {
    plan = 'currency';
    fact = 'currency';

    constructor(payload: IUserDashboardWidgetFormatsModel = {}) {
        super();

        makeObservable(this, {
            fact: observable,
            plan: observable,
            update: action,
        });

        this.update(payload);
    }
}

export interface IUserDashboardWidgetModel {
    typeId?: 'grid' | 'base' | 'compare' | 'bonuses';
    name?: NullableValueType;
    units?: IUserDashboardWidgetUnitsModel;
    labels?: IUserDashboardWidgetLabelsModel;
    formats?: IUserDashboardWidgetFormatsModel;
    items?: IUserDashboardWidgetItemModel[];
    groups?: IUserDashboardWidgetDictionaryItemModel[];
    stores?: IUserDashboardWidgetDictionaryItemModel[];
}

export class UserDashboardWidgetModel extends Model implements IUserDashboardWidgetModel {
    casts = {
        items: new ModelArrayCast(UserDashboardWidgetItemModel),
        units: new ModelCast(UserDashboardWidgetUnitsModel),
        labels: new ModelCast(UserDashboardWidgetLabelsModel),
        formats: new ModelCast(UserDashboardWidgetFormatsModel),
        stores: new ModelArrayCast(UserDashboardWidgetDictionaryItemModel),
        groups: new ModelArrayCast(UserDashboardWidgetDictionaryItemModel),
    };

    name = '';
    typeId: IUserDashboardWidgetModel['typeId'] = 'base';
    units = new UserDashboardWidgetUnitsModel();
    labels = new UserDashboardWidgetLabelsModel();
    formats = new UserDashboardWidgetFormatsModel();
    items: UserDashboardWidgetItemModel[] = [];
    groups: UserDashboardWidgetDictionaryItemModel[] = [];
    stores: UserDashboardWidgetDictionaryItemModel[] = [];

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            name: observable,
            typeId: observable,
            units: observable,
            labels: observable,
            formats: observable,
            items: observable,
            groups: observable,
            storeById: computed,
            update: action,
        });

        this.update(payload);
    }

    get storeById() {
        return lodash.keyBy(this.stores, 'id');
    }
}

export interface IUserDashboardContentItemModel {
    bonuses?: IUserDashboardBonusesModel | null;
    widgets?: IUserDashboardWidgetModel[];
}

export class UserDashboardContentItemModel extends Model implements IUserDashboardContentItemModel {
    casts = {
        widgets: new ModelArrayCast(UserDashboardWidgetModel),
    };

    bonuses: IUserDashboardBonusesModel | null = null;
    widgets: UserDashboardWidgetModel[] = [];

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            bonuses: observable,
            widgets: observable,
            update: action,
        });

        this.update(payload);
    }
}

export interface IUserDashboardContentModel {
    month?: IUserDashboardContentItemModel | null;
    quarter?: IUserDashboardContentItemModel | null;
    year?: IUserDashboardContentItemModel | null;
    additional?: IUserDashboardContentItemModel | null;
}

export class UserDashboardContentModel extends Model implements IUserDashboardContentModel {
    casts = {
        month: new ModelCast(UserDashboardContentItemModel),
        quarter: new ModelCast(UserDashboardContentItemModel),
        year: new ModelCast(UserDashboardContentItemModel),
        additional: new ModelCast(UserDashboardContentItemModel),
    };

    hasTableView = true;
    month: UserDashboardContentItemModel | null = null;
    quarter: UserDashboardContentItemModel | null = null;
    year: UserDashboardContentItemModel | null = null;
    additional: UserDashboardContentItemModel | null = null;

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            hasTableView: observable,
            month: observable,
            quarter: observable,
            year: observable,
            additional: observable,
            tabs: computed,
            update: action,
        });

        this.update(payload);
    }

    get tabs() {
        const tabs: { id: any; name: any }[] = [];
        if (this.month) {
            tabs.push({ id: 'month', name: 'Месяц' });
        }
        if (this.quarter) {
            tabs.push({ id: 'quarter', name: 'Квартал' });
        }
        if (this.year) {
            tabs.push({ id: 'year', name: 'Год' });
        }
        if (this.additional) {
            tabs.push({ id: 'additional', name: 'Дополнительно' });
        }

        return tabs;
    }
}

export interface IUserDashboardModel {
    id?: NullableValueType;
    createdAt?: NullableValueType;
    updatedAt?: NullableValueType;
    content?: IUserDashboardContentModel;
}

export class UserDashboardModel extends Model implements IUserDashboardModel {
    casts = {
        content: new ModelCast(UserDashboardContentModel),
    };

    id = 0;
    createdAt = '';
    updatedAt = '';
    content = new UserDashboardContentModel();

    constructor(payload: IUserDashboardModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            content: observable,
            update: action,
        });

        this.update(payload);
    }
}
