import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import {
    UiAppendedNews,
    UiAppendedSurvey,
    UiBackButton,
    UiDataBoundary,
    UiDate,
    UiGrid,
    UiHtml,
    UiIcon,
    UiLikeAction,
    UiNavLink,
    UiRepostAction,
    UiTags
} from 'shared/uikit';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { NewsModel } from 'shared/models';
import { useNavigate, useRouteParams, useStore, useUserWidgets } from "shared/hooks";
import { newsGetQuery, newsOtherQuery } from "shared/queries";
import { CComments } from "shared/components";

import './styles.scss';

export const NewsPage = observer(() => {
    const navigate = useNavigate();
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        item: new NewsModel(),
        items: [] as NewsModel[],
        isSuccess: true,
        isLoading: true,
    }));

    const fetchItems = useCallback(async () => {
        const { isSuccess, data } = await newsOtherQuery({ excludeId: routeParams.id });
        if (isSuccess && data) {
            store.set('items', data.items.map(item => new NewsModel(item)));
        }
    }, [store, routeParams.id]);

    const fetchItem = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await newsGetQuery(routeParams.id);
        if (isSuccess && data) {
            store.set('item', new NewsModel(data.item));
            ApplicationModule.setTitle(data.item.name);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store, routeParams.id]);

    useEffect(() => {
        ApplicationModule.setTitle('...');
    }, [])

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    useEffect(() => {
        fetchItem();
    }, [fetchItem]);

    return (
        <LayoutBody>
            <LayoutContent>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid style={{ display: "block", maxWidth: 1024 }}>
                        <div className="detail-page__back">
                            <UiBackButton label='Назад'/>
                        </div>
                        <div className="detail-page__header">
                            {(store.item.userId && store.item.user) && (
                                <div className="detail-page__author">
                                    <UiIcon size={14} icon={ICONS.USER} color={COLORS.BROWN_1}/>
                                    <span>{store.item.user.previewName}</span>
                                </div>
                            )}
                            <UiDate value={store.item.createdAt}/>
                            <UiTags items={store.item.tags} onClick={({ value }) => {
                                navigate(ROUTES.HOME(), {
                                    tagId: value.id
                                })
                            }}/>
                        </div>
                        <div className='news-detail typography'>
                            <h1>{store.item.name}</h1>
                            {store.item.subtitle && (
                                <div className="detail-page__subtitle">{store.item.subtitle}</div>
                            )}
                            <UiAppendedNews news={store.item.news}/>
                            <UiAppendedSurvey survey={store.item.survey}/>
                            <div>
                                {store.item.detailImage && (
                                    <img
                                        className='news-detail__image'
                                        src={store.item.detailImage}
                                        alt={store.item.name}
                                    />
                                )}
                                <UiHtml value={store.item.detailText}/>
                            </div>
                        </div>
                        <div className="detail-page__actions">
                            <UiLikeAction
                                entityType='News'
                                entityId={store.item.id}
                                value={store.item.liked}
                                count={store.item.likesCount}
                            />
                            <UiRepostAction
                                entityType='News'
                                entityId={store.item.id}
                                count={store.item.chatMessageArticlesCount}
                            />
                        </div>
                        {store.items.length > 0 && (
                            <div className="detail-page-other">
                                <div className="detail-page-other__title">Читайте так же:</div>
                                <div className="detail-page-other__items">
                                    {store.items.map(item => (
                                        <UiNavLink
                                            to={ROUTES.NEWS(item.id)}
                                            key={item.id}
                                            className='detail-page-other-item'
                                        >
                                            <div className="detail-page-other-item__icon">
                                                <UiIcon icon={ICONS.CIRCLE} color={COLORS.BROWN_1} size={10}/>
                                            </div>
                                            <div className="detail-page-other-item__name">{item.name}</div>
                                        </UiNavLink>
                                    ))}
                                </div>
                            </div>
                        )}
                        <CComments entityId={store.item.id} entityType={'News'}/>
                    </UiGrid>
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
})
