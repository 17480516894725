import { makeQuery } from 'shared/utilities';
import { IFaqModel } from 'shared/models';

type RequestType = {

};

type ResponseType = {
    items: IFaqModel[];
};

export const faqQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/faq', { params });
}
