import { Enum } from './Enum';

export class IspringCourseStatusIdEnum extends Enum {
    static NotStarted = 'not_started';
    static InProgress = 'in_progress';
    static Pending = 'pending';
    static Complete = 'complete';
    static Passed = 'passed';
    static Accepted = 'accepted';
    static Incomplete = 'incomplete';
    static Failed = 'failed';
    static Declined = 'declined';
}
