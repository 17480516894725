import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';
import { ReservationModel } from "./ReservationModel";

export interface IReservationPlaceModel {
    id?: number;
    name?: string;
    description?: string;
    previewImageUrl?: string;
    reservations?: ReservationModel[];
}

export class ReservationPlaceModel extends Model implements IReservationPlaceModel {
    casts = {
        reservations: new ModelArrayCast(ReservationModel),
    };

    id = 0;
    name = '';
    description = '';
    previewImageUrl = '';
    reservations: ReservationModel[] = [];

    constructor(payload: IReservationPlaceModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            description: observable,
            previewImageUrl: observable,
            reservations: observable,
            update: action,
        });

        this.update(payload);
    }
}
