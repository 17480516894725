import { makeQuery } from 'shared/utilities';
import { ITaskModel } from "shared/models/TaskTracker";

type RequestType = {
    id: number
}

type ResponseType = {
    item: ITaskModel
};

export const tasksGetQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks/get', {
        prefix: 'task-tracker',
        params: request
    });
}
