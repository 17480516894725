import { makeQuery } from 'shared/utilities';
import { CollectionItemIdType } from "shared/types";

type RequestType = {
    from: string;
    to: string;
    dateType?: string;
    groupBy?: string;
    saleRegionId?: CollectionItemIdType[] | null;
    saleStoreId?: CollectionItemIdType[] | null;
    saleProductId?: CollectionItemIdType[] | null;
    saleSellerId?: CollectionItemIdType[] | null;
    saleProductCategoryId?: CollectionItemIdType[] | null;
}

type ResponseType = {
    labels: string[];
    datasets: {
        label: string,
        data: number[] | string[]
    }[];
};

export const salesQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/sales', { params });
}
