import { createEnum } from './createEnum';

export const TaskTrackerColumnTypeEnum = createEnum({
    New: {
        id: 'new',
        name: 'Новые',
    },
    Progress: {
        id: 'progress',
        name: 'В работе',
    },
    Done: {
        id: 'done',
        name: 'Завершенные',
    },
    Personal: {
        id: 'personal',
        name: 'Персональная',
    },
    Custom: {
        id: 'custom',
        name: 'Кастомная',
    },
} as const, {
    id: null,
    name: 'Неопредлённая',
});

