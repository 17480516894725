import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

type NullableValueType = number | string | null;

export interface IUserNotificationModel {
    id?: NullableValueType;
    userId?: number,
    usersBirthdays?: number,
    bookCreated?: number,
    articleCreated?: number,
    educationLessonCreated?: number,
    vacancyCreated?: number,
    surveyCreated?: number,
    galleryCreated?: number,
    announcementCreated?: number,
    educationLessonRecent?: number,
}

export class UserNotificationModel extends Model implements IUserNotificationModel {
    casts = {}

    id = 0;
    userId: 0;
    usersBirthdays: 0;
    bookCreated: 0;
    articleCreated: 0;
    educationLessonCreated: 0;
    vacancyCreated: 0;
    surveyCreated: 0;
    galleryCreated: 0;
    announcementCreated: 0;
    educationLessonRecent: 0;

    constructor(payload: IUserNotificationModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            userId: observable,
            usersBirthdays: observable,
            bookCreated: observable,
            articleCreated: observable,
            educationLessonCreated: observable,
            vacancyCreated: observable,
            surveyCreated: observable,
            galleryCreated: observable,
            announcementCreated: observable,
            educationLessonRecent: observable,
            update: action,
        });

        this.update(payload);
    }
}
