import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { NewsModel, TagModel } from 'shared/models';
import { UiDataBoundary, UiFilter, UiGrid, UiPagination } from 'shared/uikit';
import { ROUTES } from 'shared/constants';
import { useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { newsQuery, newsTopQuery, tagsQuery } from "shared/queries";

import { HomeBanner } from "./components/HomeBanner";
import { NewsItem } from "./components/NewsItem";

import './styles.scss';

export const HomePage = observer(() => {
    const store = useStore(() => ({
        newsTop: [] as NewsModel[],
        items: [] as NewsModel[],
        tags: [] as TagModel[],
        isLoading: true,
        isInfinite: false,
        isSuccess: false,
        tabId: 'content',
        pages: 1
    }));
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        tagId: null as number | null,
        page: 1,
    });

    const fetchItems = useCallback(async () => {
        store.set('isLoading', !store.isInfinite);

        if (store.newsTop.length === 0) {
            const { isSuccess, data } = await newsTopQuery();
            if (isSuccess && data) {
                store.set('newsTop', data.items.map(item => new NewsModel(item)));
            }
        }

        const { isSuccess, data } = await newsQuery({
            excludeId: store.newsTop.map(item => item.id),
            ...urlParams
        });

        if (isSuccess && data) {
            const items = data.items.map(item => new NewsModel(item));
            store.set('items', store.isInfinite ? [...store.items, ...items] : items);
            store.set('pages', data.pages);
        }

        store.set('isSuccess', isSuccess && data);
        store.set('isInfinite', false);
        store.set('isLoading', false);
    }, [urlParams, store]);

    const fetchTags = useCallback(async () => {
        const response = await tagsQuery({ has: ['news'] });
        if (!response.isSuccess || !response.data) {
            return;
        }
        store.set('tags', response.data.items.map(item => new TagModel(item)));
    }, [store]);

    useEffect(() => {
        ApplicationModule.setTitle('Главная');
        fetchTags();
    }, [fetchTags]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems, urlParams]);

    const handleChangePagination = ({ value }: { value: number }, isInfinite: boolean) => {
        if (isInfinite) {
            store.set('isInfinite', true);
        }
        navigate(ROUTES.HOME(), {
            ...urlParams,
            page: value
        });
    }

    return (
        <LayoutBody>
            <LayoutContent>
                <HomeBanner items={store.newsTop}/>
                <LayoutContentTitle title='Новости компании'>
                    <UiFilter
                        items={store.tags}
                        value={urlParams.tagId}
                        onChange={({ value }) => {
                            navigate(ROUTES.HOME(), {
                                tagId: value
                            })
                        }}
                    />
                </LayoutContentTitle>
                <UiGrid style={{maxWidth: 1152}}>
                    <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                        {store.items.map(news => <NewsItem key={news.id} news={news}/>)}
                    </UiDataBoundary>
                    <UiPagination
                        page={urlParams.page}
                        pages={store.pages}
                        onChange={handleChangePagination}
                    />
                </UiGrid>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
});
