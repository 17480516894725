import { makeQuery } from 'shared/utilities';
import { ICalendarItemModel } from "shared/models";

type RequestType = {
    id: string,
    changeKey: string
};

type ResponseType = {
    item: ICalendarItemModel;
};

export const calendarItemGetQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/calendar-item/get', { params });
}
