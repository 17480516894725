import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiDataBoundary, UiPreviewImage, UiNavLink, UiIcon } from 'shared/uikit';
import { LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { departmentsQuery } from "shared/queries";
import { useStore } from "shared/hooks";
import { DepartmentModel } from "shared/models";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { ApplicationModule } from "shared/modules";

import { Department } from "./components/Department";

import './styles.scss';

export const CompanyStructurePage = observer(() => {
    const store = useStore(() => ({
        isOpened: false,
        isLoading: true,
        isSuccess: true,
        departments: [] as DepartmentModel[]
    }));

    const fetchDepartments = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await departmentsQuery();
        if (isSuccess && data) {
            store.set("departments", data.items.map(item => new DepartmentModel(item)))
        }
        store.set("isLoading", false);
        store.set("isSuccess", isSuccess && data);
    }, [store])

    useEffect(() => {
        fetchDepartments();
    }, [fetchDepartments])

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Структура компании' widgets={false} backTo={ROUTES.COMPANY()}>
                    {ApplicationModule.applicationSettings.companyStructureImage && (
                        <div className="p-company-structure-image" onClick={() => store.set("isOpened", true)}>
                            <UiIcon icon={ICONS.STRUCTURE} color={COLORS.BLACK} size={26}/>
                            Структура в изображении
                        </div>
                    )}
                    <UiPreviewImage
                        scale={0.15}
                        isOpened={store.isOpened}
                        onClose={() => store.set("isOpened", false)}
                        image={ApplicationModule.applicationSettings.companyStructureImage}
                    />
                </LayoutContentTitle>
                <UiDataBoundary
                    isLoading={store.isLoading}
                    isError={!store.isSuccess}
                    className='p-company-structure-container'
                >
                    <UiNavLink to={ROUTES.USER('togas-konstantinos')} className="p-company-structure-director-card">
                        <div className="p-company-structure-director-card__logo"></div>
                        <div className="p-company-structure-director-card__title">
                            Костас Тогас
                        </div>
                        <div className="p-company-structure-director-card__subtitle">
                            Генеральный директор
                        </div>
                    </UiNavLink>
                    <div className="p-company-structure-vice-card">
                        Помощник генерального директора
                    </div>
                    <div className="p-company-structure-line-header"></div>
                    <div className="p-company-structure-departments">
                        {store.departments.map(department => <Department key={department.id} department={department}/>)}
                    </div>
                </UiDataBoundary>
            </LayoutContent>
        </LayoutBody>
    );
})
