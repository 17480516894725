import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiBackButton, UiDataBoundary, UiDate, UiGrid, UiHtml, UiIcon, UiTags, UiNavLink } from 'shared/uikit';
import { ROUTES, ICONS, COLORS } from 'shared/constants';
import { ArticleModel } from 'shared/models';
import { useNavigate, useRouteParams, useStore, useUserWidgets } from "shared/hooks";
import { articlesGetQuery, articlesOtherQuery } from "shared/queries";
import { CComments } from "shared/components";

import './styles.scss';

export const ArticlePage = observer(() => {
    const navigate = useNavigate();
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        item: new ArticleModel(),
        items: [] as ArticleModel[],
        isSuccess: true,
        isLoading: true,
    }));

    const fetchItems = useCallback(async () => {
        const { isSuccess, data } = await articlesOtherQuery({ excludeId: routeParams.id });
        if (isSuccess && data) {
            store.set('items', data.items.map(item => new ArticleModel(item)));
        }
    }, [store, routeParams.id]);

    const fetchItem = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await articlesGetQuery(routeParams.id);
        if (isSuccess && data) {
            store.set('item', new ArticleModel(data.item));
            ApplicationModule.setTitle(data.item.name);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store, routeParams.id]);

    useEffect(() => {
        ApplicationModule.setTitle('...');
    }, [])

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    useEffect(() => {
        fetchItem();
    }, [fetchItem]);

    return (
        <LayoutBody>
            <LayoutContent>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid style={{maxWidth: 800}}>
                        <div className="detail-page__back">
                            <UiBackButton label='Назад ко всем статьям'/>
                        </div>
                        <div className="detail-page__header">
                            <UiDate value={store.item.createdAt}/>
                            <UiTags items={store.item.tags} onClick={({ value }) => {
                                navigate(ROUTES.HOME(), {
                                    tagId: value.id
                                })
                            }}/>
                        </div>
                        <div className='article-detail typography'>
                            <h1>{store.item.name}</h1>
                            <div>
                                {store.item.detailImage && (
                                    <img
                                        className='article-detail__image'
                                        src={store.item.detailImage}
                                        alt={store.item.name}
                                    />
                                )}
                                <UiHtml value={store.item.detailText}/>
                            </div>
                        </div>
                        {store.items.length > 0 && (
                            <div className="detail-page-other">
                                <div className="detail-page-other__title">Читайте так же:</div>
                                <div className="detail-page-other__items">
                                    {store.items.map(item => (
                                        <UiNavLink
                                            to={ROUTES.ARTICLE(item.id)}
                                            key={item.id}
                                            className='detail-page-other-item'
                                        >
                                            <div className="detail-page-other-item__icon">
                                                <UiIcon icon={ICONS.CIRCLE} color={COLORS.BROWN_1} size={10}/>
                                            </div>
                                            <div className="detail-page-other-item__name">{item.name}</div>
                                        </UiNavLink>
                                    ))}
                                </div>
                            </div>
                        )}
                    </UiGrid>
                </UiDataBoundary>
                <CComments entityId={store.item.id} entityType={'Article'}/>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    );
})
