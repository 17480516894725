import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useStore, useUserWidgets } from "shared/hooks";
import { RequestCategoryModel } from "shared/models";
import { requestCategoriesQuery } from "shared/queries";
import { UiDataBoundary, UiGrid, UiViewType } from "shared/uikit";

import { PRequestsCategory } from "./components/PRequestsCategory";
import { ROUTES } from "shared/constants";

export const RequestsPage = observer(() => {
    const store = useStore(() => ({
        isLoading: false,
        isSuccess: false,
        requestCategories: [] as RequestCategoryModel[],
        viewType: 'card'
    }));

    const fetchItems = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await requestCategoriesQuery();
        if (isSuccess && data) {
            store.set("requestCategories", data.items.map(item => new RequestCategoryModel(item)));
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle
                    title={'Заявки Togas'}
                    subtitle={'Здесь вы можете оформить заявку с обращением в нужный вам отдел'}
                    backTo={ROUTES.DOCUMENTS()}
                >
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid columns={1} style={{ maxWidth: 1200 }}>
                        {store.requestCategories.map(requestCategory => (
                            <PRequestsCategory
                                key={requestCategory.id}
                                viewType={store.viewType}
                                requestCategory={requestCategory}
                            />
                        ))}
                    </UiGrid>
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
})
