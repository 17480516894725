import lodash from "lodash";

export const userPreviewName = (name: string = '') => {
    let previewName = name.trim().split(' ');
    const middleName = previewName.length > 0 ? previewName.splice(-1)[0] : '';

    const isRealMiddlename = lodash.endsWith(middleName, 'ич')
        || lodash.endsWith(middleName, 'чна')
        || lodash.endsWith(middleName, 'вна');

    if (isRealMiddlename) {
        return previewName.join(' ');
    }

    return name
};
