import { makeQuery } from 'shared/utilities';
import { IChatRoomModel } from 'shared/models';

type ResponseType = {
    pages: number;
    items: IChatRoomModel[];
};

export const chatMessagesReadQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/chat-messages/read', { json: { id } });
}
