import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { ModelCast } from "shared/casts";
import { IUserModel, UserModel } from "../UserModel";

import { Model } from '../Model';

export interface ITaskViewModel {
    id?: number;
    updatedAt?: string,
    user?: IUserModel
}

export class TaskViewModel extends Model implements ITaskViewModel {
    casts = {
        user: new ModelCast(UserModel),
    };

    id = 0;
    updatedAt = moment().format();
    user: UserModel = new UserModel();

    constructor(payload: ITaskViewModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            updatedAt: observable,
            user: observable,
            updatedAtMoment: computed,
            update: action
        });

        this.update(payload);
    }

    get updatedAtMoment() {
        if (!this.updatedAt) {
            return moment();
        }

        return moment(this.updatedAt);
    }
}
