import React from 'react';
import { observer, } from 'mobx-react';
import { Moment } from "moment";

import { CalendarItemModel } from "shared/models";

import { CalendarYearDay } from "../CalendarYearDay";

import './styles.scss';

type DayType = {
    id: string,
    moment: Moment,
    isOuter: boolean,
    isToday: boolean,
    calendarItems: CalendarItemModel[]
}
type PropsType = {
    item: {
        id: string,
        moment: Moment,
        days: DayType[]
    },
}

export const CalendarYearMonth = observer(({ item }: PropsType) => {
    return (
        <div className="calendar-year-month">
            <div className="calendar-year-month__title">
                {item.moment.format('MMMM')}
            </div>
            <div className="calendar-year-month__header">
                <div className="calendar-year-month__day">Пн</div>
                <div className="calendar-year-month__day">Вт</div>
                <div className="calendar-year-month__day">Ср</div>
                <div className="calendar-year-month__day">Чт</div>
                <div className="calendar-year-month__day">Пт</div>
                <div className="calendar-year-month__day">Сб</div>
                <div className="calendar-year-month__day">Вс</div>
            </div>
            <div className="calendar-year-month__days">
                {item.days.map(item => <CalendarYearDay key={item.id} item={item}/>)}
            </div>
        </div>
    );
});
