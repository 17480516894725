import { IUserNotificationModel } from 'shared/models';

import { makeQuery } from 'shared/utilities';


type ResponseType = {};

export const userNotificationsSaveQuery = async function (data: IUserNotificationModel) {
    return await makeQuery<ResponseType>('/user-notifications/save', { json: data });
}
