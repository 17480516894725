import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import {
    LayoutAside,
    LayoutBody,
    LayoutContent,
    LayoutContentBanner,
    LayoutContentFilters,
    LayoutContentPanel
} from 'shared/layout';
import {
    UiArticleCard,
    UiDataBoundary,
    UiFilter,
    UiGrid,
    UiIcon,
    UiLikeAction,
    UiPagination,
    UiRepostAction,
    UiSearchInput,
    UiTabs,
    UiTag
} from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useMedia, useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { AnnouncementTypeEnum } from "shared/enums";
import { ApplicationModule } from "shared/modules";
import { AnnouncementCategoryModel, AnnouncementModel } from "shared/models";
import { announcementCategoriesQuery, announcementsQuery } from "shared/queries";

import { AnnouncementCreate } from "./components/AnnouncementCreate";

import './index.scss';

export const AnnouncementsPage = observer(() => {
    ApplicationModule.setTitle('Объявления');
    const navigate = useNavigate();
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        pages: 1,
        announcements: [] as AnnouncementModel[],
        announcementCategories: [] as AnnouncementCategoryModel[],
    }));

    const urlParams = useUrlParams({
        page: 1,
        search: undefined as undefined | string,
        announcementCategoryId: undefined as undefined | number,
        typeId: undefined as undefined | string,
        sort: 'created_at',
    });

    useEffect(() => {
        (async () => {
            const { isSuccess, data } = await announcementCategoriesQuery();
            if (isSuccess && data) {
                store.set('announcementCategories', data.items.map(item => new AnnouncementCategoryModel(item)));
            }
        })();
    }, [store,]);

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            const { isSuccess, data } = await announcementsQuery({
                perPage: 24,
                ...urlParams
            });
            if (isSuccess && data) {
                store.set("pages", data.pages)
                store.set('announcements', data.items.map(item => new AnnouncementModel(item)));
            }
            store.set('isSuccess', isSuccess && !!data);
            store.set('isLoading', false);
        })();
    }, [store, urlParams]);

    const { value: columns } = useMedia({
        is320: 1,
        is768: 2,
        is1440: 3,
        is1920: 4
    });

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentBanner
                    image={require('./assets/banner.png')}
                    title={'Объявления Togas'}
                    description={'Публикуем информацию о всех корпоративных событиях, продаже товаров и услуг'}
                />
                <LayoutContentPanel>
                    <AnnouncementCreate/>
                    <UiSearchInput value={urlParams.search} onChange={({ value }) => {
                        navigate(ROUTES.ANNOUNCEMENTS(), {
                            search: value
                        }, {}, null);
                    }}/>
                </LayoutContentPanel>
                <LayoutContentFilters>
                    <div>
                        <UiTabs
                            items={[
                                { id: null, name: 'Все' },
                                ...AnnouncementTypeEnum.items
                            ]}
                            value={urlParams.typeId}
                            onChange={({ value }) => {
                                navigate(ROUTES.ANNOUNCEMENTS(), {
                                    ...urlParams,
                                    typeId: value
                                }, {}, null);
                            }}
                        />
                    </div>
                    <div>
                        <UiFilter
                            items={store.announcementCategories}
                            value={urlParams.announcementCategoryId}
                            onChange={({ value }) => {
                                navigate(ROUTES.ANNOUNCEMENTS(), {
                                    ...urlParams,
                                    announcementCategoryId: value
                                }, {}, null);
                            }}
                        />
                        <UiFilter
                            defaultItem={{
                                id: 'created_at',
                                name: 'По дате'
                            }}
                            value={urlParams.sort}
                            items={[{
                                id: 'name',
                                name: 'По названию'
                            }]}
                            onChange={({ value }) => {
                                navigate(ROUTES.ANNOUNCEMENTS(), {
                                    ...urlParams,
                                    sort: value
                                }, {}, null);
                            }}
                            label={(
                                <>
                                    <span>Сортировка</span>
                                    <UiIcon icon={ICONS.CHEVRON_DOWN} size={12} color={COLORS.BROWN_1}/>
                                </>
                            )}
                        />
                    </div>
                </LayoutContentFilters>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid columns={columns}>
                        {store.announcements.map(announcement => {
                            return (
                                <UiArticleCard
                                    key={announcement.id}
                                    href={ROUTES.ANNOUNCEMENT(announcement.id)}
                                    name={announcement.name}
                                    date={announcement.createdAt}
                                    image={announcement.imageThumbnail}
                                    description={announcement.previewText}
                                    tags={<>
                                        <UiTag
                                            value={announcement.tag}
                                            onClick={() => {
                                                navigate(ROUTES.ANNOUNCEMENTS(), {
                                                    ...urlParams,
                                                    typeId: announcement.typeId
                                                }, {}, null);
                                            }}
                                        />
                                        <UiTag
                                            value={announcement.announcementCategory}
                                            onClick={() => {
                                                navigate(ROUTES.ANNOUNCEMENTS(), {
                                                    ...urlParams,
                                                    announcementCategoryId: announcement.announcementCategoryId
                                                }, {}, null);
                                            }}
                                        />
                                    </>}
                                    actions={<>
                                        <UiLikeAction
                                            value={announcement.liked}
                                            count={announcement.likesCount}
                                            entityType={'Announcement'}
                                            entityId={announcement.id}
                                        />
                                        <UiRepostAction
                                            entityType={'Announcement'}
                                            entityId={announcement.id}
                                            count={announcement.chatMessageArticlesCount}
                                        />
                                    </>}
                                />
                            )
                        })}
                    </UiGrid>
                    {store.announcements.length === 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 64 }}>
                            <h2 className='typography'>По вашему запросу ничего не найдено</h2>
                        </div>
                    )}
                </UiDataBoundary>
                <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                    <UiPagination
                        page={urlParams.page}
                        pages={store.pages}
                        onChange={(data) => {
                            navigate(ROUTES.ANNOUNCEMENTS(), {
                                ...urlParams,
                                page: data.value
                            })
                        }}
                    />
                </UiGrid>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
});
