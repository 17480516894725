import React from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS } from 'shared/constants';
import { Router } from 'shared/utilities';

import { UiIcon } from '../UiIcon';
import { UiNavLink } from '../UiNavLink';

import './styles.scss';

interface Props {
    label: string;
    to?: string;
}

class UiBackButton extends React.Component<Props> {
    static defaultProps: Props = {
        label: 'Назад',
    };

    handleClick = () => {
        Router.history.goBack();
    };

    render() {
        const { label, to } = this.props;

        if (to) {
            return (
                <UiNavLink to={to} className='ui-back-button'>
                    <UiIcon icon={ICONS.CHEVRON_LEFT} size={20} color={COLORS.BROWN_1} />
                    <span>{label}</span>
                </UiNavLink>
            );
        }

        return (
            <div onClick={this.handleClick} className='ui-back-button'>
                <UiIcon icon={ICONS.CHEVRON_LEFT} size={20} color={COLORS.BROWN_1} />
                <span>{label}</span>
            </div>
        );
    }
}

export default observer(UiBackButton);
