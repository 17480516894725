import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { NewsModel } from "shared/models";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import { UiIcon } from '../UiIcon'
import { UiNavLink } from "../UiNavLink";

import './index.scss';

type PropsType = {
    news: NewsModel | null
}

export const UiAppendedNews = observer(({ news }: PropsType) => {
    if (!news) {
        return null;
    }

    return (
        <UiNavLink to={ROUTES.NEWS(news.id)} className='ui-appended-news'>
            <div className="ui-appended-news__name">{news.name}</div>
            {news.previewText && <div className="ui-appended-news__description">{news.previewText}</div>}
            <div className="ui-appended-news__footer">
                <UiIcon size={20} color={COLORS.BROWN_1} icon={ICONS.NEWS}/>
                <div className="ui-appended-news__type">Новость</div>
                <div className="ui-appended-news__date">от {moment(news.createdAt).format('DD.MM.YYYY')}</div>
            </div>
        </UiNavLink>
    )
});
