import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS } from 'shared/constants';
import { OnChangeHandlerType } from 'shared/types';

import { UiIcon } from '../UiIcon';

import './styles.scss';
import { Notifier } from "shared/utilities";

type PropsType = {
    onChange?: OnChangeHandlerType<File[]>;
    value?: File[];
    name?: string;
    style?: React.CSSProperties;
}

export const UiImagesUpload = observer((
    {
        onChange = () => {
        },
        value = [],
        name = '',
        style = {},
    }: PropsType
) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!event?.target?.files || event.target.files.length < 1) {
            return;
        }
        const images = Array.from(event.target.files).filter((file) => {
            if (file.size > 2550000) {
                Notifier.alert('Ошибка', 'Максимальный размер файла 2.5мб, файлы больше этого размера не будут загружены');
                return false;
            }
            return true;
        });
        onChange({
            name,
            value: [...value, ...images]
        })
    }

    const handleDelete = (index: number) => {
        onChange({
            name,
            value: value?.filter((_, i) => i !== index)
        });
    }

    const previews = useMemo(() => {
        return value.map(file => URL.createObjectURL(file))
    }, [value]);

    return (
        <div className='ui-images-upload' style={style}>
            {previews.map((preview, index) => (
                <div key={index} className='ui-images-upload-item' style={{ backgroundImage: `url(${preview})` }}>
                    <div className="ui-images-upload-item__delete" onClick={() => handleDelete(index)}>
                        <UiIcon icon={ICONS.CLOSE} size={12}/>
                    </div>
                </div>
            ))}
            <label className={'ui-images-upload-item ui-images-upload-item--add'}>
                <input type="file" multiple accept="image/*" onChange={handleChange}/>
                <div className="ui-images-upload-item__inner">
                    <div className="ui-images-upload-item__icon">
                        <UiIcon icon={ICONS.CAMERA} size={38} color={COLORS.GRAY_4}/>
                    </div>
                    <div className="ui-images-upload-item__label">Добавить</div>
                </div>
            </label>
        </div>
    )
})
