import { observer } from 'mobx-react';
import React from 'react';

import { COLORS, ICONS } from "shared/constants";
import { useMedia } from "shared/hooks";
import { UiIcon } from "shared/uikit";

import './styles.scss';

type PropsType = {
    onClick: () => void
}

export const UiModalClose = observer((
    {
        onClick = () => undefined
    }: PropsType
) => {

    const { isMobileOrTablet } = useMedia();

    return (
        <div className='ui-modal-close' onClick={onClick}>
            <UiIcon icon={ICONS.CLOSE} size={isMobileOrTablet ? 20 : 16} color={COLORS.WHITE}/>
        </div>
    )
});
