import { makeQuery } from 'shared/utilities';
import { IEducationLessonModel } from 'shared/models';

type RequestType = {
    id: number
}

type ResponseType = {
    item: IEducationLessonModel;
};

export const educationLessonsGetQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/education-lessons/get', { params });
}
