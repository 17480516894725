import React from 'react';
import { observer } from 'mobx-react';
import 'react-circular-progressbar/dist/styles.css';

import { UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { useMedia } from "shared/hooks";

import welcomeBackground from "./assets/welcome-background.jpg";
import welcomeCups from "./assets/welcome-cups.png";

import './styles.scss';

export const PEducationWelcome = observer(() => {

    const { isMobileOrTablet } = useMedia();
    return (
        <div className="p-education-welcome">
            <div className="p-education-welcome__container"
                 style={{ backgroundImage: `url(${welcomeBackground})` }}>
                <div className="p-education-welcome__inner">
                    <div className="p-education-welcome__title">
                        Начни обучение с welcome-тренинга!
                    </div>
                    <div className="p-education-welcome__description">
                        В коротком видео рассказали все самое важное на первом этапе
                    </div>
                </div>
                <div className="p-education-welcome__play">
                    <UiIcon size={isMobileOrTablet ? 22 : 40} icon={ICONS.PLAY} color={COLORS.WHITE}/>
                </div>
            </div>
            <div className="p-education-welcome__cups" style={{ backgroundImage: `url(${welcomeCups})` }}></div>
        </div>
    );
});

