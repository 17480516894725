import { makeObservable, observable } from 'mobx';

export default class<T> {
    isSuccess = true;
    isLoading = false;
    item: T;

    constructor() {
        makeObservable(this, {
            isSuccess: observable,
            isLoading: observable,
            item: observable,
        });
    }
}
