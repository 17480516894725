import { makeQuery } from 'shared/utilities';

import { IGalleryModel } from 'shared/models';

type ResponseType = {
    item: IGalleryModel;
};

export const galleriesGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/galleries/get', { params: { id } });
}
