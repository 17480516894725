import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: number,
    name: string,
    image?: string | null,
}

type ResponseType = {
};

export const chatRoomsUpdateQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/chat-rooms/update', { json: payload });
}
