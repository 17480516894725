import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutBody } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiButton, UiIcon } from 'shared/uikit';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { Resolution } from 'shared/utilities';
import { useNavigate } from "shared/hooks";
import { DocumentCategoryTypeEnum } from "shared/enums";

export const DocumentsPage = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        ApplicationModule.setTitle('Документы')
    }, []);

    return (
        <LayoutBody flexStart={false}>
            <div className='page-sections'>
                <div className='page-sections-item'>
                    <div className='page-sections-item__inner'>
                        <UiIcon size={96} icon={ICONS.REGULATIONS} color={COLORS.BROWN_1}/>
                        <div className='page-sections-item__name'>Регламенты и инструкции</div>
                        <UiButton
                            onClick={() => {
                                navigate(ROUTES.DOCUMENT_CATEGORIES(), { typeId: DocumentCategoryTypeEnum.Regulation })
                            }}
                            isLink={Resolution.isMobileOrTablet}
                            label='Перейти в раздел'
                        />
                    </div>
                </div>
                <div className='page-sections-item'>
                    <div className='page-sections-item__inner'>
                        <UiIcon size={96} icon={ICONS.REQUESTS} color={COLORS.BROWN_1}/>
                        <div className='page-sections-item__name'>Заявки</div>
                        <UiButton
                            onClick={() => {
                                navigate(ROUTES.REQUESTS())
                            }}
                            isLink={Resolution.isMobileOrTablet}
                            label='Перейти в раздел'
                        />
                    </div>
                </div>
                <div className='page-sections-item'>
                    <div className='page-sections-item__inner'>
                        <UiIcon size={96} icon={ICONS.REQUEST_FORMS} color={COLORS.BROWN_1}/>
                        <div className='page-sections-item__name'>Бланки заявлений</div>
                        <UiButton
                            onClick={() => {
                                navigate(ROUTES.DOCUMENT_CATEGORIES(), { typeId: DocumentCategoryTypeEnum.RequestForm })
                            }}
                            isLink={Resolution.isMobileOrTablet}
                            label='Перейти в раздел'
                        />
                    </div>
                </div>
            </div>
        </LayoutBody>
    )
});
