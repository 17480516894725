import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiModal, UiForm } from 'shared/uikit';

import './styles.scss';

type PropsType = {
    isOpened?: boolean;
    isLoading?: boolean;
    title?: string;
    description?: string;
    submitText?: string;
    onClear?: () => void;
    onClose?: () => void;
    onSubmit?: () => void;
    children?: React.ReactNode
}
export const CModalForm = observer((
    {
        isLoading = false,
        isOpened = false,
        title = '',
        description = '',
        submitText = 'Принять',
        onClear,
        onClose ,
        onSubmit,
        children
    }: PropsType
) => {
    return (
        <UiModal isOpened={isOpened} onClose={onClose} title={title} description={description}>
            <UiForm onSubmit={onSubmit}>
                {children}
                <div className='ui-modal__actions'>
                    <UiButton isSubmit isSmall label={submitText} isLoading={isLoading}/>
                    <UiButton isSmall isOutline label='Отмена' onClick={onClose}/>
                    {onClear && <UiButton isSmall isLink label='Сбросить заполнение' onClick={onClear}/>}
                </div>
            </UiForm>
        </UiModal>
    );
})

