import { useLocalObservable } from "mobx-react";
import { useEffect } from "react";
import lodash from "lodash";

type MediaResolveType<T = any> = {
    is320?: T,
    is768?: T,
    is1024?: T,
    is1280?: T,
    is1366?: T,
    is1440?: T,
    is1920?: T,
    is2560?: T,
    isMax?: T,
    isMobile?: T,
    isTablet?: T,
    isMobileOrTablet?: T,
    isDesktop?: T
}

type UseMediaWithoutValueReturnType = {
    is320: boolean,
    is768: boolean,
    is1024: boolean,
    is1280: boolean,
    is1366: boolean,
    is1440: boolean,
    is1920: boolean,
    is2560: boolean,
    isMobile: boolean,
    isTablet: boolean,
    isMobileOrTablet: boolean,
    isDesktop: boolean
}
type UseMediaWithValueReturnType<T> = {
    value: T,
    is320: boolean,
    is768: boolean,
    is1024: boolean,
    is1280: boolean,
    is1366: boolean,
    is1440: boolean,
    is1920: boolean,
    is2560: boolean,
    isMobile: boolean,
    isTablet: boolean,
    isMobileOrTablet: boolean,
    isDesktop: boolean
}

const mediaPoints = {
    is320: 0,
    is768: 768,
    is1024: 1024,
    is1280: 1280,
    is1366: 1366,
    is1440: 1440,
    is1920: 1920,
    is2560: 2560,
    isMax: 99999,
    isMobile: 0,
    isTablet: 768,
}

export function useMedia(): UseMediaWithoutValueReturnType;
export function useMedia <T>(resolve?: MediaResolveType<T>): UseMediaWithValueReturnType<T>
export function useMedia <T>(resolve?: MediaResolveType<T>): any {
    const store = useLocalObservable(() => ({
        windowWidth: window.innerWidth,
        update() {
            this.windowWidth = window.innerWidth;
        },
        media(min: number, max: number) {
            return min <= this.windowWidth && this.windowWidth < max
        }
    }));

    useEffect(() => {
        window.addEventListener('resize', store.update, false);
        return () => {
            window.removeEventListener('resize', store.update, false);
        }
    }, [store.update]);

    const result = {
        is320: store.media(mediaPoints.is320, mediaPoints.is768),
        is768: store.media(mediaPoints.is768, mediaPoints.is1024),
        is1024: store.media(mediaPoints.is1024, mediaPoints.is1280),
        is1280: store.media(mediaPoints.is1280, mediaPoints.is1366),
        is1366: store.media(mediaPoints.is1366, mediaPoints.is1440),
        is1440: store.media(mediaPoints.is1440, mediaPoints.is1920),
        is1920: store.media(mediaPoints.is1920, mediaPoints.is2560),
        is2560: store.media(mediaPoints.is2560, mediaPoints.isMax),
        isMobile: store.media(mediaPoints.is320, mediaPoints.is768),
        isTablet: store.media(mediaPoints.is768, mediaPoints.is1024),
        isMobileOrTablet: store.media(mediaPoints.is320, mediaPoints.is1024),
        isDesktop: store.media(mediaPoints.is1024, mediaPoints.isMax),
    };

    if (!resolve) {
        return result;
    }

    let value: T = Object.entries(resolve)[0][1];
    let mediaPointsKeys = Object.keys(mediaPoints);
    let mediaQueries = Object.entries(resolve).map(([key, value]) => {
        if (mediaPointsKeys.indexOf(key) === -1) {
            return null;
        }
        return {
            media: (mediaPoints as any)[key],
            value
        }
    });
    mediaQueries = lodash.sortBy(mediaQueries, 'media');
    mediaQueries.forEach((query) => {
        if (!query) {
            return;
        }
        if (query.media <= store.windowWidth) {
            value = query.value;
        }
    })

    return {
        value,
        ...result
    };
}
