import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import './styles.scss';
import { Storage } from "shared/utilities";
import { useStore } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

type PropsType = {
    className?: string;
    icon?: React.ReactNode | JSX.Element;
    aside?: React.ReactNode | string | number;
    label?: string | undefined | null;
    hasBorder?: boolean;
    style?: React.CSSProperties;
    collapsable?: string,
    count?: string | number
}

export const UiCardTitle = observer(({ className, aside, label, hasBorder, style, icon, collapsable, count }: PropsType) => {
    const store = useStore(() => ({
        isCollapsed: false,
        isLoading: !!collapsable
    }));

    useEffect(() => {
        (async () => {
            if (!collapsable) {
                return;
            }
            const isCollapsed = await Storage.get(`UiCard.${collapsable}`, false);
            store.set("isCollapsed", isCollapsed);
            store.set("isLoading", false);
        })();
    }, [store, collapsable]);

    const handleCollapse = () => {
        if (!collapsable) {
            return;
        }
        Storage.set(`UiCard.${collapsable}`, !store.isCollapsed);
        store.set("isCollapsed", !store.isCollapsed);
    }

    if (!label) {
        return null;
    }

    const classNames = classnames('ui-card-title', className, {
        'ui-card-title--border': hasBorder,
        'ui-card-title--collapsable': !!collapsable,
        'ui-card-title--collapsed': store.isCollapsed,
    });

    return (
        <div className={classNames} style={style || {}} onClick={handleCollapse}>
            {icon}
            <span>{label}</span>
            {aside && <div className='ui-card-title__aside'>{aside}</div>}
            {collapsable && (
                <div className='ui-card-title__aside'>
                    <UiIcon
                        size={16}
                        icon={store.isCollapsed ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}
                    />
                </div>
            )}
            {(store.isCollapsed && !!count) && (
                <div className="ui-card-title__count">{count}</div>
            )}
        </div>
    );
})
