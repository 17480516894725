import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { RequestCategoryModel, RequestTypeModel } from "shared/models";
import { COLORS, ICONS } from "shared/constants";
import { UiIcon } from "shared/uikit";
import { useStore } from "shared/hooks";

import { PRequestsCreate } from "../PRequestsCreate";

import './index.scss';

type PropsType = {
    requestCategory: RequestCategoryModel,
    requestType: RequestTypeModel,
    viewType: string
}

export const PRequestsType = observer(({ requestType, requestCategory, viewType = 'card' }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false
    }));
    const classNames = classnames('p-request-type', `p-request-type--${viewType}`)

    if(viewType === 'list') {
        return (
            <div>
                <div className={classNames} onClick={() => store.set('isOpened', true)}>
                    <div className="p-request-type__overlay" style={{ background: requestCategory.colorBackground }}/>
                    <div className="p-request-type__inner">
                        <div
                            className="p-request-type__marker"
                            style={{ backgroundColor: requestCategory.colorMarker }}
                        />
                        <div className="p-request-type__arrow">
                            <UiIcon icon={ICONS.ARROW_RIGHT} size={10} color={COLORS.WHITE}/>
                        </div>
                        <div className="p-request-type__name">{requestType.name}</div>
                        {!!requestType.hint && (
                            <>
                                <div className="p-request-type__icon">
                                    <UiIcon size={20} icon={ICONS.QUESTION_CIRCLE} color={COLORS.GRAY_4}/>
                                </div>
                                <div className="p-request-type__hint">
                                    <span>{requestType.hint}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <PRequestsCreate
                    requestCategory={requestCategory}
                    requestType={requestType}
                    isOpened={store.isOpened}
                    onClose={() => store.set("isOpened", false)}
                />
            </div>
        )
    }
    return (
        <div>
            <div className={classNames} onClick={() => store.set('isOpened', true)}>
                <div className="p-request-type__overlay" style={{ background: requestCategory.colorBackground }}/>
                <div className="p-request-type__inner">
                    <div className="p-request-type__name">{requestType.name}</div>
                    <div className="p-request-type__footer">
                        <div
                            className="p-request-type__marker"
                            style={{ backgroundColor: requestCategory.colorMarker }}
                        />
                        {!!requestType.hint && (
                            <>
                                <div className="p-request-type__icon">
                                    <UiIcon size={20} icon={ICONS.QUESTION_CIRCLE} color={COLORS.GRAY_4}/>
                                </div>
                                <div className="p-request-type__hint">
                                    <span>{requestType.hint}</span>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="p-request-type__arrow">
                        <UiIcon icon={ICONS.ARROW_RIGHT} size={10} color={COLORS.WHITE}/>
                    </div>
                </div>
            </div>
            <PRequestsCreate
                requestCategory={requestCategory}
                requestType={requestType}
                isOpened={store.isOpened}
                onClose={() => store.set("isOpened", false)}
            />
        </div>
    )
});
