import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';
import classnames from "classnames";

type PropsType = {
    label?: string;
    className?: string;
    isSubmitted?: boolean;
    errorMessage?: string | boolean | undefined;
    isError?: boolean;
    center?: boolean;
    style?: React.CSSProperties;
    children?: React.ReactNode,
    size?: 'normal' | 'large',
    isRequired?: boolean
}

export const UiFormControl = observer((
    {
        children = null,
        label = '',
        errorMessage = '',
        style = {},
        center = false,
        isSubmitted = true,
        isRequired = false,
        className = '',
        size = 'normal',
        isError
    }: PropsType
) => {
    const hasErrorMessage = !!errorMessage && isSubmitted;
    const classNames = classnames('ui-form-control', className, `ui-form-control--${size}`, {
        'ui-form-control--error': hasErrorMessage || isError,
    });
    return (
        <div className={classNames} style={style}>
            {!!label && (<div className='ui-form-control__label'>{label} {isRequired && (<span>*</span>)}</div>)}
            <div className='ui-form-control__inner'>{children}</div>
            {hasErrorMessage && (
                <div className='ui-form-control__error' style={center ? { textAlign: 'center' } : {}}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
})
