import { makeQuery } from 'shared/utilities';
import { ISurveyModel } from 'shared/models';

type RequestType = {
    surveyQuestionId: number,
    userSurveyQuestionAnswers: {
        surveyQuestionAnswerId: number;
        comment?: string
    }[]
};

type ResponseType = {
    item: ISurveyModel[];
};

export const surveysAnswerQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/surveys/answer', {
        json: payload
    });
}

