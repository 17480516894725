import { makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IUserSurveyQuestionAnswerModel {
    id?: number;
    userId?: number;
}

export class UserSurveyQuestionAnswerModel extends Model implements IUserSurveyQuestionAnswerModel {
    casts = {};

    id = 0;
    userId = 0;

    constructor(payload: IUserSurveyQuestionAnswerModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            userId: observable,
        });

        this.update(payload);
    }
}
