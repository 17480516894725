import { makeQuery } from 'shared/utilities';


type RequestType = {
    moduleId?: string
}

type ResponseType = {};

export const notificationsReadAllQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/notifications/read-all', { json: request });
}
