import React from 'react';
import { observer } from 'mobx-react';

import { UiIcon } from "shared/uikit";
import { IconType } from "shared/types";
import { COLORS } from "shared/constants";

import './styles.scss';

interface PropsType {
    icon?: IconType;
    label?: string;
}

export const UiIconBadge = observer(({icon, label}: PropsType) => {
    return (
        <div className='ui-icon-badge'>
            {icon &&  <UiIcon icon={icon} size={20} color={COLORS.BROWN_1}/>}
            {label && <span>{label}</span>}
        </div>
    )
})
