import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { FaqCategoryModel, FaqModel } from 'shared/models';
import { UiButton, UiDataBoundary, UiFilter, UiFormControl, UiModal, UiTextarea } from 'shared/uikit';
import { useNavigate, useStore, useUrlParams, useUserWidgets, useValidation } from "shared/hooks";
import { faqCategoriesQuery, faqQuery, faqResponsesCreateQuery } from "shared/queries";
import { ROUTES } from "shared/constants";

import FaqItem from './components/FaqItem';

import './styles.scss';

export const FaqPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        faqCategoryId: null as null | number,
    });

    const store = useStore(() => ({
        items: [] as FaqModel[],
        categories: [] as FaqCategoryModel[],
        isLoading: true,
        isSuccess: false,
        isOpenedCreate: false,
        isOpenedResult: false,
        isSubmitting: false,
        isSubmitted: false,
        text: ''
    }));

    const validation = useValidation(store, (rules) => ({
        text: rules.required()
    }));

    useEffect(() => {
        (async () => {
            const { isSuccess, data } = await faqQuery();
            if (isSuccess && data) {
                store.set('items', data.items.map(item => new FaqModel(item)));
            }
            store.set('isLoading', false)
            store.set('isSuccess', isSuccess && !!data);
        })();
    }, [store])

    useEffect(() => {
        (async () => {
            const { isSuccess, data } = await faqCategoriesQuery();
            if (isSuccess && data) {
                store.set('categories', data.items.map(item => new FaqCategoryModel(item)));
            }
        })();
    }, [store]);

    const handleSubmit = () => {
        if (store.isSubmitting) {
            return;
        }

        store.set('isSubmitted', true);

        if (!validation.isValid) {
            return;
        }

        faqResponsesCreateQuery({ text: store.text });
        store.set("isOpenedCreate", false);
        store.set("isOpenedResult", true);
    }

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={'Вопрос-ответ'} backTo={ROUTES.COMPANY()}>
                    <UiFilter
                        value={urlParams.faqCategoryId}
                        items={store.categories}
                        onChange={({ value }) => {
                            navigate(ROUTES.FAQ(), { faqCategoryId: value })
                        }}
                    />
                    <UiButton
                        isLink
                        label='Задать вопрос'
                        onClick={() => store.set("isOpenedCreate", true)}
                    />
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.items.filter((faq) => {
                        if (urlParams.faqCategoryId === null) {
                            return true;
                        }

                        return +urlParams.faqCategoryId === +faq.faqCategoryId
                    }).map(faq => (
                        <FaqItem key={faq.id} item={faq}/>
                    ))}
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
            <UiModal
                isOpened={store.isOpenedCreate}
                onClose={() => store.set("isOpenedCreate", false)}
                title='Остались вопросы?'
            >
                <div className="ui-modal__description">Задайте свой вопрос, и мы на него ответим</div>
                <UiFormControl errorMessage={validation.text.errorMessage} isSubmitted={store.isSubmitted}>
                    <UiTextarea
                        placeholder='Напишите ваш вопрос'
                        value={store.text}
                        name={'text'}
                        onChange={store.handleChange}
                    />
                </UiFormControl>
                <div className="ui-modal__actions">
                    <UiButton isSmall label='Отправить' onClick={handleSubmit}/>
                </div>
            </UiModal>
            <UiModal
                isOpened={store.isOpenedResult}
                onClose={() => store.set("isOpenedResult", false)}
                title='Спасибо за участие'
            >
                <div className="ui-modal__description">Мы скоро ответим на ваш вопрос</div>
            </UiModal>
        </LayoutBody>
    );
});

