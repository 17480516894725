import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useNavigate, useStore, useUrlParams } from 'shared/hooks';
import { OnChangeEventType } from 'shared/types';
import { ApplicationModule } from 'shared/modules';
import { LayoutBody, LayoutContent } from 'shared/layout';
import { ROUTES, SEARCH_TYPES } from 'shared/constants';
import { UiButton, UiInput, UiList, UiDataBoundary, UiTabs, UiPagination, UiForm } from 'shared/uikit';
import { SearchIndexModel } from "shared/models";
import { searchQuery } from "shared/queries";

import { SearchItem } from './components/SearchItem';

import './styles.scss';

export const SearchPage = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        items: [] as SearchIndexModel[],
        query: '',
        total: 0,
        isLoading: true,
        isInfiniteLoading: false,
        isSuccess: false,
        pages: 1
    }))
    const urlParams = useUrlParams({
        page: 1,
        query: '',
        typeId: null,
    });

    const fetchItems = useCallback(async () => {
        if (!store.isInfiniteLoading) {
            store.set('isLoading', true);
        }

        const response = await searchQuery(urlParams);
        if (response.isSuccess && response.data) {
            const items = response.data.items.map(item => new SearchIndexModel(item));

            store.set('items', store.isInfiniteLoading ? [...store.items, ...items] : items);
            store.set('pages', response.data.pages);
        }

        store.set('isSuccess', response.isSuccess && response.data);
        store.set('total', response.data?.total || 0);
        store.set('isInfiniteLoading', false);
        store.set('isLoading', false);
    }, [urlParams, store]);

    useEffect(() => {
        ApplicationModule.setTitle('Результаты поиска');
    });

    useEffect(() => {
        store.set('query', urlParams.query);
        if (!urlParams.query) {
            return;
        }
        fetchItems();
    }, [urlParams, fetchItems, store]);

    const handleSubmitForm = () => {
        navigate(ROUTES.SEARCH(), {
            typeId: urlParams.typeId,
            query: store.query,
            page: 1,
        });
    };

    const handleChangeTabs = ({ value }: OnChangeEventType) => {
        navigate(ROUTES.SEARCH(), {
            ...urlParams,
            typeId: value,
            page: 1,
        });
    };

    const handleChangePage = ({ value }: OnChangeEventType<number>, isInfinite: boolean) => {
        store.isInfiniteLoading = isInfinite;
        navigate(ROUTES.SEARCH(), {
            ...urlParams,
            page: value,
        });
    };

    return (
        <LayoutBody>
            <LayoutContent isFill>
                <UiForm className='search-bar' onSubmit={handleSubmitForm}>
                    <div className='search-bar__header'>
                        <UiInput
                            name='query'
                            placeholder='Поиск'
                            onChange={({ value }) => store.set("query", value)}
                            value={store.query}
                        />
                        <UiButton isSubmit label='Искать'/>
                    </div>
                    <div className='search-bar__body'>
                        <UiTabs
                            value={urlParams.typeId}
                            onChange={handleChangeTabs}
                            items={[
                                {id: null, name: 'Все'},
                                ...SEARCH_TYPES
                            ]}
                        />
                    </div>
                    {!store.isLoading && !!urlParams.query && (
                        <div className='search-bar__footer'>Результатов: {store.total}</div>
                    )}
                </UiForm>
                {!!urlParams.query && (
                    <>
                        <UiDataBoundary
                            isLoading={store.isLoading && !store.isInfiniteLoading}
                            isError={!store.isSuccess}
                            onAction={fetchItems}
                        >
                            <UiList items={store.items} component={SearchItem} className='search-results'/>
                        </UiDataBoundary>
                        <UiPagination page={urlParams.page} pages={store.pages} onChange={handleChangePage}/>
                    </>
                )}
            </LayoutContent>
        </LayoutBody>

    );
});
