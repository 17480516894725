import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { ThumbnailType } from "shared/types";

import './styles.scss';

type PropsType = {
    image?: string | null | ThumbnailType | undefined;
    label?: string;
    className?: string;
    style?: React.CSSProperties;
    size?: number;
    hasHighlight?: boolean;
    onClick?: (event: React.SyntheticEvent) => void;
    children?: React.ReactNode
}

const colors = [
    '#ff6384',
    '#36a2eb',
    '#ffce56',
    '#4bc0c0',
    '#9966ff',
    '#ff9f40',
    '#00bcd4',
    '#009688',
    '#8bc34a',
    '#f44336',
];

export const UiAvatar = observer((
    {
        image = null,
        label = '',
        className = '',
        style = {},
        size = 48,
        hasHighlight = false,
        onClick,
        children
    }: PropsType
) => {
    const classNames = useMemo(() => {
        return classnames('ui-avatar', className, {
            'ui-avatar--highlighted': hasHighlight,
        });
    }, [className, hasHighlight]);

    const fontSize = useMemo(() => {
        let multiplier = size < 27 ? 10 / 24 : 23 / 48;
        let fontSize = Math.ceil(size * multiplier);
        if (fontSize > 28) {
            fontSize = fontSize * 0.75;
        }
        return fontSize + 'px';
    }, [size]);

    const name = useMemo(() => {
        if (!!image || !label || !label.trim()) {
            return null;
        }
        let nameData = label.trim().split(' ');
        if (nameData.length > 1) {
            return nameData[0].substring(0, 1).toUpperCase() + nameData[1].substring(0, 1).toUpperCase();
        } else {
            return nameData[0].substring(0, 2).toUpperCase();
        }
    }, [image, label]);

    const color = useMemo(() => {
        if (!name || name.length < 1) {
            return colors[0];
        }
        return colors[(name.split('')[0].charCodeAt(0) + (label?.length || 0)) % colors.length];
    }, [name, label]);

    const backgroundImage = useMemo(() => {
        if (!image) {
            return '';
        }
        if(typeof image === 'string'){
            return `url(${image})`;
        }
        return `url(${image.webp}), url(${image.origin})`;
    }, [image]);

    const styles = useMemo(() => {
        return {
            width: size,
            height: size,
            minWidth: size,
            minHeight: size,
            backgroundImage,
            backgroundColor: backgroundImage ? '' : color,
            fontSize,
            ...style,
        };
    }, [size, style, fontSize, color, backgroundImage]);

    return (
        <div className={classNames} onClick={onClick}>
            <div className="ui-avatar__inner" style={styles}>
                {name && <div style={{ fontSize: fontSize, lineHeight: fontSize }}>{name}</div>}
                {children}
            </div>
        </div>
    );
})
