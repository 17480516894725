import React from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS } from 'shared/constants';
import { useNavigate } from "shared/hooks";

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    to?: string,
    onClick?: () => void,
    count?: number,
}

export const UiCommentAction = observer((
    {
        to,
        count = 0,
        onClick = () => {}
    }: PropsType
) => {
    const navigate = useNavigate();
    return (
        <div className='ui-comment-action' onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
            if(to) {
                navigate(to + '#comments', {}, {}, 60000);
            }
        }}>
            <UiIcon
                icon={ICONS.COMMENT}
                size={20}
                color={COLORS.GRAY_4}
            />
            <div className="ui-comment-action__count">{count}</div>
        </div>
    )
})
