import { IspringCourseModel } from 'shared/models';
import { StoreWithFetchItems } from 'shared/mixins/store';
import { computed, makeObservable } from "mobx";
import lodash from "lodash";

class Store extends StoreWithFetchItems<IspringCourseModel> {
    constructor() {
        super();

        makeObservable(this, {
            ispringCourseTypes: computed,
        });
    }

    get ispringCourseTypes() {
        return lodash.unionBy(this.items.map(item => ({
            id: item.type,
            name: item.type,
        })), 'id');
    }
}

export default new Store();
