import { makeQuery } from 'shared/utilities';
import { IAnnouncementModel } from 'shared/models';

type RequestType = {
    page?: number;
    perPage?: number;
    search?: string;
    announcementCategoryId?: number;
    typeId?: string;
    sort?: string;
}

type ResponseType = {
    pages: number;
    items: IAnnouncementModel[];
};

export const announcementsQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/announcements', { params });
}
