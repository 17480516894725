import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Notifier } from 'shared/utilities';
import { ModalForm } from 'shared/components';
import { UiFormControl, UiInput, UiTextarea, UiTmpFileUpload } from 'shared/uikit';
import { OnChangeHandlerType } from 'shared/types';
import { ApplicationModule } from 'shared/modules';
import { vacanciesRespondCreateQuery } from 'shared/queries';

import Store from './Store';

type Props = {
    isOpened: boolean;
    vacancyId: number;
    onClose: () => void;
};

export const VacancyRespondModal = observer(({ isOpened, onClose, vacancyId }: Props) => {
    useEffect(() => {
        if (!Store.form.values.username) {
            Store.form.set('username', ApplicationModule.user.name);
        }
        Store.form.set('vacancyId', vacancyId);
    }, [vacancyId]);

    const handleChangeForm: OnChangeHandlerType = data => {
        Store.form.set(data.name, data.value);
    };

    const handleSubmit = async () => {
        if (!Store.form.validate()) {
            return;
        }
        onClose();
        Notifier.alert(
            'Ваш отклик отправлен',
            'Как только мы ознакомимся с Вашей кандидатурой, специалист отдела по подбору персонала свяжется в Вами.'
        );
        vacanciesRespondCreateQuery(Store.form.values);
    };

    return (
        <ModalForm title='Откликнуться на вакансию' isOpened={isOpened} onClose={onClose} onSubmit={handleSubmit}>
            <UiFormControl
                label='Ваше имя'
                className='mb-20'
                errorMessage={Store.form.validations.username.errorMessage}
            >
                <UiInput
                    name='username'
                    value={Store.form.values.username}
                    onChange={handleChangeForm}
                    isValid={Store.form.validations.username.isValid}
                />
            </UiFormControl>
            <UiFormControl
                label='Сопроводительное письмо'
                className='mb-20'
                errorMessage={Store.form.validations.description.errorMessage}
            >
                <UiTextarea
                    placeholder='Напишите сопроводительный текст'
                    name='description'
                    value={Store.form.values.description}
                    onChange={handleChangeForm}
                    isValid={Store.form.validations.description.isValid}
                />
            </UiFormControl>
            <UiTmpFileUpload
                className='mb-20'
                name='tmpFileId'
                value={Store.form.values.tmpFileId}
                onChange={handleChangeForm}
            />
        </ModalForm>
    );
});
