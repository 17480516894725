import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { OnChangeHandlerType } from 'shared/types';
import { useStore } from "shared/hooks";
import { likesToggleQuery, likesUsersQuery } from "shared/queries";
import { UiAvatar, UiLoadingSpinner, UiModal, UiModalClose, UiModalTitle, UiNavLink } from "shared/uikit";
import { UserModel } from "shared/models";

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    color?: string;
    value?: number;
    name?: string;
    entityType?: string;
    entityId?: number;
    count?: number | null;
    onChange?: OnChangeHandlerType<number>;
}

export const UiLikeAction = observer((
    {
        color = COLORS.GRAY_4,
        value = 0,
        name = 'liked',
        entityType = 'News',
        entityId,
        count = null,
        onChange,
    }: PropsType
) => {
    const store = useStore(() => ({
        isLoading: false,
        isHovered: false,
        isPreviewLoading: false,
        isPreviewLoaded: false,
        isOpened: false,
        users: [] as UserModel[],
        count: null as null | number,
        value: 0
    }));

    const fetchItems = useCallback(async () => {
        store.set("isPreviewLoading", true);

        const { isSuccess, data } = await likesUsersQuery({
            entityId: entityId || 0,
            entityType,
        });

        if (isSuccess && data) {
            store.set("users", data.items.map(user => new UserModel(user)))
        }
        store.set("isPreviewLoaded", true);
    }, [store, entityType, entityId]);

    useEffect(() => {
        store.set("count", count)
    }, [count, store]);

    useEffect(() => {
        store.set("value", value)
    }, [value, store]);

    useEffect(() => {
        if (!store.isHovered || store.isPreviewLoaded || store.isPreviewLoading || !store.count) {
            return;
        }
        fetchItems();
    }, [fetchItems, store.isHovered, store.isPreviewLoaded, store.isPreviewLoading, entityType, entityId, store])

    const handleToggle = useCallback(async (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (store.isLoading) {
            return;
        }
        store.set("isLoading", true);

        const { isSuccess, data } = await likesToggleQuery({
            entityId: entityId || 0,
            entityType,
        });

        if (isSuccess && data) {
            store.set("value", data.liked);
            store.set("count", data.likesCount);
            onChange && onChange({ name, value: data.liked });
        }

        store.set("isLoading", false);
        fetchItems();
    }, [store, entityType, entityId, name, onChange, fetchItems]);

    const handleOpen = async (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        store.set("isOpened", true);
    }

    return (
        <div
            className='ui-like-action'
            onMouseOver={() => store.set('isHovered', true)}
            onMouseOut={() => store.set('isHovered', false)}
        >
            <div className="ui-like-action__inner" onClick={handleToggle}>
                <UiIcon
                    size={18}
                    color={store.value ? COLORS.BROWN_1 : color}
                    icon={store.value ? ICONS.HEART : ICONS.HEART_OUTLINE}
                />
                <div className="ui-like-action__count">{store.count}</div>
            </div>
            {!!store.count && (
                <div className="ui-like-action-preview" onClick={handleOpen}>
                    <div className="ui-like-action-preview__inner">
                        <div className="ui-like-action-preview__title">понравилось</div>
                        <div className="ui-like-action-preview__items">
                            {!store.isPreviewLoaded && (
                                <div className="ui-like-action-preview__loading">
                                    <UiLoadingSpinner style={{ width: 20, height: 20 }}/>
                                </div>
                            )}
                            {store.isPreviewLoaded && (
                                <div className="ui-like-action-preview__items">
                                    {store.users.length < 5 && store.users.map(user => (
                                        <UiAvatar
                                            key={user.id}
                                            label={user.name}
                                            image={user.imageAvatar}
                                            size={35}
                                        />
                                    ))}
                                    {store.users.length >= 5 && (
                                        <>
                                            {store.users.slice(0, 3).map(user => (
                                                <UiAvatar
                                                    key={user.id}
                                                    label={user.name}
                                                    image={user.imageAvatar}
                                                    size={35}
                                                />
                                            ))}
                                            <div className="ui-like-action-preview__counter">
                                                +{store.users.length - 3}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <UiModal isOpened={store.isOpened} onClose={() => store.set("isOpened", false)} styleBody={{ width: 600 }}>
                <UiModalTitle
                    content={(
                        <div className="ui-like-action-modal__header">
                            <UiIcon
                                size={20}
                                color={COLORS.BROWN_1}
                                icon={ICONS.HEART}
                            />
                            <span>{store.users.length}</span>
                        </div>
                    )}
                />
                <UiModalClose onClick={() => store.set("isOpened", false)}/>
                <div className="ui-like-action-modal__items">
                    {store.users.map(user => (
                        <UiNavLink to={ROUTES.USER(user.slug)} key={user.id} className="ui-like-action-modal-user">
                            <UiAvatar size={80} label={user.previewName} image={user.imageAvatar}/>
                            <div className="ui-like-action-modal-user__name">{user.previewName}</div>
                        </UiNavLink>
                    ))}
                </div>
            </UiModal>
        </div>
    )
})
