import { makeQuery } from 'shared/utilities';
import { IEssentialModel } from 'shared/models';

type ResponseType = {
    item: IEssentialModel;
    other: IEssentialModel[]
};

export const essentialsGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/essentials/get', { params: { id } });
}
