import { runInAction } from 'mobx';

import { ObjectType } from 'shared/types';

import Window from './Window';
import { QueryParams } from './QueryParams';
import Api from './Api';
import { Loading } from './Loading';
import { Pagination } from './Pagination';

const defaultParams = () => {
    return {};
};

interface ICustomLoaderPayload<IApiResponse> {
    endpoint: () => string;
    params?: () => ObjectType;
    onResponse: (data: IApiResponse) => void;
}

interface IItemsLoaderPayload<T> {
    model?: () => any;
    endpoint: () => string;
    params?: () => ObjectType;
    onRequest?: () => void;
    onResponse: (data: T[]) => void;
}

interface IItemLoaderPayload<T> {
    endpoint: () => string;
    params?: () => ObjectType;
    onRequest?: () => void;
    onResponse: (data: T) => void;
    onError?: () => void;
}

interface IInfiniteLoaderPayload<T> {
    model: () => T;
    params?: () => ObjectType;
    queryParams: () => QueryParams<any & { page: number; isInfinite: boolean }>;
    loading: () => Loading<any>;
    pagination: () => Pagination;
    endpoint: () => string;
    items: () => T[];
    onResponse: (items: T[]) => any;
}

class DataLoader {
    static custom = <IApiResponse>({
        params = defaultParams,
        endpoint,
        onResponse,
    }: ICustomLoaderPayload<IApiResponse>) => {
        return async () => {
            const response = await Api.get<IApiResponse>(endpoint(), params());
            if (!response.isSuccess) {
                return;
            }
            runInAction(() => {
                onResponse(response.data);
            });
        };
    };

    static items = <T>({
        params = defaultParams,
        model = () => null,
        endpoint,
        onResponse,
        onRequest = () => {},
    }: IItemsLoaderPayload<T>) => {
        return async () => {
            runInAction(() => {
                onRequest();
            });

            let Model: any = null;
            if (model()) {
                Model = model();
            }
            const response = await Api.get<{ items: typeof Model[] }>(endpoint(), params());
            if (!response.isSuccess || !response?.data?.items || !Array.isArray(response.data.items)) {
                return;
            }

            runInAction(() => {
                onResponse(model() === null ? response.data.items : response.data.items.map(item => new Model(item)));
            });
        };
    };

    static item = <T>({
        params = defaultParams,
        endpoint,
        onResponse,
        onError = () => {},
        onRequest = () => {},
    }: IItemLoaderPayload<any>) => {
        return async (id: string | number | null = null) => {
            runInAction(() => {
                onRequest();
            });

            const response = await Api.get<{ item: T }>(endpoint(), {
                id,
                ...params(),
            });
            if (!response.isSuccess || !response?.data?.item) {
                onError();
                return;
            }

            runInAction(() => {
                onResponse(response.data.item);
            });
        };
    };

    // isPush from queryParams.substribe => Router.history.listen
    static paginate = ({
        queryParams,
        params = defaultParams,
        items,
        onResponse,
        loading,
        endpoint,
        pagination,
        model,
    }: IInfiniteLoaderPayload<any>) => {
        return async (data = { isPush: true }) => {
            const isInfinite = data.isPush && queryParams().values.isInfinite;
            const loadingKey = isInfinite ? 'infinite' : 'items';

            loading().setLoading(loadingKey);
            if (!isInfinite) {
                Window.scrollTo();
            }

            const currentItems = [...items()];
            const response = await Api.get<{
                items: typeof currentItems;
                pages: number;
            }>(endpoint(), { ...params(), ...queryParams().values });
            if (!response.isSuccess || !response?.data?.items || !Array.isArray(response.data.items)) {
                loading().setLoading(loadingKey, false);
                return;
            }

            pagination().setPages(response.data.pages);

            const Model = model();
            const newItems = response.data.items.map(item => new Model(item));

            if (isInfinite) {
                Window.scrollSave();
                runInAction(() => {
                    onResponse([...currentItems, ...newItems]);
                });
                Window.scrollRestore();
            } else {
                runInAction(() => {
                    onResponse(newItems);
                });
            }
            loading().setLoading(loadingKey, false);
        };
    };
}

export default DataLoader;
