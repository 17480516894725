import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IUserPositionModel {
    id?: number;
    name?: string;
    isSalesManager?: boolean;
}

export class UserPositionModel extends Model implements IUserPositionModel {
    id = 0;
    name = '';
    isSalesManager = false;

    constructor(payload: IUserPositionModel = {}) {
        super();

        makeObservable(this, {
            name: observable,
            isSalesManager: observable,
            update: action,
        });

        this.update(payload);
    }
}
