import { makeQuery } from 'shared/utilities';
import { CalendarItemModel, ICalendarItemModel } from "shared/models";

type RequestType = CalendarItemModel;

type ResponseType = {
    item: ICalendarItemModel;
};

export const calendarItemSaveQuery = async function (data: RequestType) {
    return await makeQuery<ResponseType>('/calendar-item/save', { json: data });
}
