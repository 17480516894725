import { makeQuery } from 'shared/utilities';
import { ITaskModel } from "shared/models/TaskTracker";

type RequestType = ITaskModel;

type ResponseType = {
    item: ITaskModel
};

export const tasksUpdateQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks/update', {
        prefix: 'task-tracker',
        json: request
    });
}
