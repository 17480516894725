import { makeQuery } from 'shared/utilities';
import { IChatMessageModel } from 'shared/models';

type RequestType = {
    chatRoomId: number,
    id?: number,
    type?: 'after' | 'before'
};

type ResponseType = {
    pages: number;
    items: IChatMessageModel[];
};

export const chatMessagesQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/chat-messages', { params });
}
