import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiGrid } from 'shared/uikit';
import { ROUTES } from "shared/constants";
import { WImportantInformation } from "shared/widgets";
import { useMedia, useUserWidgets } from "shared/hooks";
import { WImprovementProposal } from "shared/widgets";

import { PCompanyCardLink } from "./components/PCompanyCardLink";
import { PCompanyLeaderGroups } from "./components/PCompanyLeaderGroups";
import { PCompanyBestGroups } from "./components/PCompanyBestGroups";
import { PCompanyBigCardLink } from "./components/PCompanyBigCardLink";

import './styles.scss';

export const CompanyPage = observer(() => {
    useEffect(() => {
        ApplicationModule.setTitle('Компания');
    }, []);

    const { value: media } = useMedia({
        is320: {
            cards: {
                columns: 1,
                gap: 16
            },
            leaders: {
                columns: 1,
                gap: 16
            },
            modules: {
                columns: 1,
                gap: 16
            }
        },
        is768: {
            cards: {
                columns: 2,
                gap: 16
            },
            leaders: {
                columns: 1,
                gap: 16
            },
            modules: {
                columns: 2,
                gap: 16
            }
        },
        is1024: {
            cards: {
                columns: 2,
                gap: 16
            },
            leaders: {
                columns: 1,
                gap: 16
            },
            modules: {
                columns: 2,
                gap: 16
            }
        },
        is1366: {
            cards: {
                columns: 4,
                gap: 16
            },
            leaders: {
                columns: 1,
                gap: 16
            },
            modules: {
                columns: 2,
                gap: 16
            }
        },
        is1920: {
            cards: {
                columns: 4,
                gap: 24
            },
            leaders: {
                columns: 2,
                gap: 24
            },
            modules: {
                columns: 2,
                gap: 24
            }
        },
    });

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={'Компания'} className='p-company__header'/>
                <UiGrid
                    className='p-company__section'
                    columns={media.cards.columns}
                    gap={media.cards.gap}
                >
                    <PCompanyCardLink
                        to={ROUTES.HISTORY()}
                        name='История'
                        image={require('./assets/history.png')}
                    />
                    <PCompanyCardLink
                        to={ROUTES.VALUES()}
                        name='Ценности'
                        image={require('./assets/value.png')}
                    />
                    <PCompanyCardLink
                        to={ROUTES.COMPANY_STRUCTURE()}
                        name='Структура'
                        image={require('./assets/structure.png')}
                    />
                    <PCompanyCardLink
                        to={ROUTES.OFFICES()}
                        name='Офисы'
                        image={require('./assets/offices.png')}
                    />
                </UiGrid>
                <WImportantInformation/>
                <UiGrid
                    className='p-company__section'
                    gap={media.leaders.gap}
                    columns={media.leaders.columns}
                >
                    <PCompanyLeaderGroups/>
                    <PCompanyBestGroups/>
                </UiGrid>
                <UiGrid
                    className='p-company__section'
                    columns={media.modules.columns}
                    gap={media.modules.gap}
                >
                    <PCompanyBigCardLink
                        name='Бронирование переговорок'
                        to={ROUTES.RESERVATIONS()}
                        image={require('./assets/reservations.png')}
                        label='Забронировать'
                    />
                    <PCompanyBigCardLink
                        name='Частые вопросы'
                        to={ROUTES.FAQ()}
                        image={require('./assets/faq.png')}
                        label='Перейти'
                    />
                </UiGrid>
                <WImprovementProposal isWide/>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    );
})

