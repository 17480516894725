import { makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IApplicationSettingsModel {
    homeBannerImage?: string | null;
    companyStructureImage?: string | null;
    widgetImprovementProposalTitle?: string | null;
    widgetImprovementProposalDescription?: string | null;
}

export class ApplicationSettingsModel extends Model implements IApplicationSettingsModel {
    homeBannerImage = null;
    companyStructureImage = null;
    widgetImprovementProposalTitle = null;
    widgetImprovementProposalDescription = null;

    constructor(payload: IApplicationSettingsModel = {}) {
        super();

        makeObservable(this, {
            homeBannerImage: observable,
            widgetImprovementProposalTitle: observable,
            widgetImprovementProposalDescription: observable,
            companyStructureImage: observable,
        });

        this.update(payload);
    }
}
