import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { NotificationModel } from "shared/models";
import { UiUserPreview } from "shared/uikit";
import { UiNavLink } from "shared/uikit";
import { ROUTES } from "shared/constants";

import './styles.scss';

type PropsType = {
    value: NotificationModel,
    isLight: boolean,
}

export const UiNotificationContent = observer(({ value: notification, isLight }: PropsType) => {
    const className = classnames('ui-notification-content', {
        'ui-notification-content--light': isLight,
        'ui-notification-content--comment': notification.typeFormatted === 'CommentNotification'
    });

    if (notification.typeFormatted === 'UsersBirthdaysNotification') {
        return (
            <div className={className}>
                <div>Поздравьте с праздником:</div>
                <div className='ui-notification-content__users'>
                    {notification.data.users.map(user => (
                        <UiNavLink to={ROUTES.USER(user.id)} className='ui-notification-content__user' key={user.id}>
                            <UiUserPreview value={user}/>
                        </UiNavLink>
                    ))}
                </div>
            </div>
        );
    }

    if (notification.typeFormatted === 'UnreadChatMessagesNotification') {
        return (
            <div className={className}>
                {notification.data.user.previewName}
            </div>
        );
    }

    if (notification.typeFormatted === 'DocumentNotification') {
        return (
            <div className={className}>
                «{notification.data.document.name}»
            </div>
        );
    }

    if (notification.typeFormatted === 'CommentNotification') {
        return (
            <div className={className} dangerouslySetInnerHTML={{__html: notification.data.comment.value}}>
            </div>
        );
    }

    return (
        <div className={className}>{notification.content.description}</div>
    );
})
