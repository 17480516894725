import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

interface Props {
    value: string;
    style?: React.CSSProperties;
}

const UiSalary = observer(({ value, style }: Props) => (
    <div className='ui-salary' style={style}>
        {value}
    </div>
));

export default UiSalary;
