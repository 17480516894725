import React from 'react';
import { observer } from 'mobx-react';

import { UiIcon, UiNavLink } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useMedia } from "shared/hooks";

import { LayoutMenuSecondaryNotifications } from "./components/LayoutMenuSecondaryNotifications";
import { LayoutMenuSecondaryChatMessages } from "./components/LayoutMenuSecondaryChatMessages";
import { CTaskTrackerNotifications } from "shared/components/TaskTracker";

import './styles.scss';

export const LayoutMenuSecondary = observer(() => {
    const { isMobileOrTablet } = useMedia();

    return (
        <div className='layout-menu-secondary'>
            <div className="layout-menu-secondary__inner">
                {isMobileOrTablet && (
                    <>
                        <UiNavLink to={ROUTES.HOME()} className="layout-menu-secondary-item">
                            <div className="layout-menu-secondary-item__inner">
                                <UiIcon icon={ICONS.HOME} size={22} color={COLORS.WHITE}/>
                            </div>
                        </UiNavLink>
                        <UiNavLink to={ROUTES.SEARCH()} className="layout-menu-secondary-item">
                            <div className="layout-menu-secondary-item__inner">
                                <UiIcon icon={ICONS.SEARCH} size={22} color={COLORS.WHITE}/>
                            </div>
                        </UiNavLink>
                    </>
                )}
                <LayoutMenuSecondaryNotifications/>
                <LayoutMenuSecondaryChatMessages/>
                <CTaskTrackerNotifications/>
                <UiNavLink title={'Заявки'} to={ROUTES.REQUESTS()} className="layout-menu-secondary-item">
                    <div className="layout-menu-secondary-item__inner">
                        <UiIcon icon={ICONS.REQUESTS} size={22} color={COLORS.WHITE}/>
                    </div>
                </UiNavLink>
            </div>
        </div>
    )
});
