import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Portal } from 'react-portal';

import { useMedia, useStore } from "shared/hooks";
import { UiModalClose } from "shared/uikit/UiModalClose";

import { UiModalTitle } from "../UiModalTitle";

import './styles.scss';

type PropsType = {
    isOpened?: boolean;
    isAside?: boolean;
    style?: React.CSSProperties;
    styleBody?: React.CSSProperties;
    title?: string | ReactNode;
    description?: ReactNode;
    onClose?: () => void;
    children?: ReactNode | ((isOpened: boolean) => ReactNode);
    isPortal?: boolean,
    className?: string;
}

export const UiModal = observer((
    {
        isPortal = true,
        isOpened = false,
        isAside = false,
        title = null,
        style = {},
        styleBody = {},
        description = '',
        onClose,
        className,
        children
    }: PropsType
) => {
    const store = useStore(() => ({
        inProgress: false,
        isOpened: false,
        scrollTop: 0,
        titleHeight: title ? 55 : 0
    }));

    const { isMobile } = useMedia();
    const refTitle = useRef<HTMLDivElement>(null);

    const calcTitleHeight = useCallback(() => {
        if (!refTitle.current) {
            return;
        }
        const { height } = refTitle.current.getBoundingClientRect();
        store.set('titleHeight', height);
    }, [store, refTitle]);

    useEffect(() => {
        calcTitleHeight();
        setTimeout(calcTitleHeight, 100);
        setTimeout(calcTitleHeight, 500);
        setTimeout(calcTitleHeight, 1000);

        window.addEventListener("resize", calcTitleHeight);

        return () => window.removeEventListener("resize", calcTitleHeight);
    }, [calcTitleHeight, title]);

    useEffect(() => {
        if (isOpened) {
            const scrollY = window.scrollY;
            document.body.classList.add('body--modal');
            window.scrollTo({ top: scrollY });
            store.set('inProgress', true);
            setTimeout(() => {
                store.set("inProgress", false);
                store.set("isOpened", true);
                calcTitleHeight();
                setTimeout(calcTitleHeight, 1);
                setTimeout(calcTitleHeight, 100);
            }, 1);
        } else {
            document.body.classList.remove('body--modal');
            store.set("inProgress", true)
            store.set("isOpened", false)
            setTimeout(() => store.set('inProgress', false), 300);
        }
    }, [isOpened, store, calcTitleHeight]);

    const classNames = useMemo(() => classnames('ui-modal', className, {
        'ui-modal--progress': store.inProgress,
        'ui-modal--opened': store.isOpened,
        'ui-modal--title': title,
        'ui-modal--without-title': !title,
        'ui-modal--aside': isAside,
    }), [store.inProgress, store.isOpened, title, isAside, className]);

    const contentMaxHeight = useMemo(() => {
        const sub = isMobile ? store.titleHeight + 104 : store.titleHeight;

        return isAside ? `calc(100vh - ${sub}px)` : 'auto'
    }, [isAside, isMobile, store.titleHeight]);

    const content = (
        <div className={classNames} style={style}>
            <div className='ui-modal__overlay' onClick={onClose}/>
            <div className='ui-modal__body' style={{ ...styleBody, paddingTop: store.titleHeight }}>
                {!!onClose && (
                    <UiModalClose onClick={onClose}/>
                )}
                {!!title && (
                    <div className="ui-modal__title" ref={refTitle}>
                {typeof title === 'string' ? <UiModalTitle title={title}/> : title}
            </div>
            )}
            <div
                className='ui-modal__content'
                style={{ height: contentMaxHeight }}
            >
                {description && <div className={'ui-modal__description'}>{description}</div>}
                {typeof children === 'function' ? children(isOpened) : children}
            </div>
        </div>
</div>
)
    ;
    if (!isPortal) {
        return content;
    }
    return (
        <Portal>
            {content}
        </Portal>
    );
});
