import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from "shared/hooks";
import { importantInformationQuery } from "shared/queries";
import { ImportantInformationModel } from "shared/models";
import { UiDataBoundary } from "shared/uikit";
import { ICONS } from "shared/constants";

import './styles.scss';

export const WImportantInformation = observer(() => {
    const store = useStore(() => ({
        items: [] as ImportantInformationModel[],
        isLoading: true,
        isSuccess: true,
    }));

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            const { isSuccess, data } = await importantInformationQuery();
            if (isSuccess && data) {
                store.set("items", data.items.map(item => new ImportantInformationModel(item)));
            }
            store.set("isSuccess", isSuccess && data);
            store.set('isLoading', false);
        })();
    }, [store]);

    return (
        <div className='w-important-information'>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess} style={{height: 70}}>
                <div className="w-important-information__logo">
                    {ICONS.LOGO({ size: 326, color: '#262625' })}
                </div>
                <div className="w-important-information__title">Важная информация</div>
                <div className="w-important-information__items">
                    {store.items.map(item => (
                        <a
                            className="w-important-information__item"
                            target='_blank'
                            rel='noreferrer'
                            key={item.id}
                            href={item.url}
                        >
                            {item.name}
                        </a>
                    ))}
                </div>
            </UiDataBoundary>
        </div>
    )
});
