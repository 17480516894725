import { useState } from "react";
import useResizeObserverExternal, { ObservedSize } from "use-resize-observer";

export const useResizeObserver: typeof useResizeObserverExternal = (opts) => {
    const [size, setSize] = useState<ObservedSize>({ width: undefined, height: undefined });
    const { ref } = useResizeObserverExternal({ ...opts, onResize: (size) => requestAnimationFrame(() => setSize(size)) });

    return {
        ref,
        ...size
    };
}
