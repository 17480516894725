import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { OnChangeHandlerType,  } from 'shared/types';

import './styles.scss';

type OptionType = {
    id: number | string | null,
    name: string | number
}

type PropsType = {
    items?: OptionType[];
    name?: string;
    value?: string | number | null;
    onChange?: OnChangeHandlerType<string | number | null>;
}

export const UiSelectInline = observer(({ items = [], name = 'ui-select-inline', value, onChange }: PropsType) => {
    const handleChange = (id: number | null | string) => {
        onChange && onChange({
            name,
            value: `${value || ''}` !== `${id || ''}` ? id : null
        });
    }
    return (
        <div className="ui-select-inline">
            {items.map(item => {
                const classNames = classnames('ui-select-inline__item', {
                    'ui-select-inline__item--selected': `${value || ''}` === `${item.id || ''}`
                });
                return (
                    <div key={item.id} className={classNames} onClick={() => item.id && handleChange(item.id)}>
                        {item.name}
                    </div>
                )
            })}
        </div>
    )
});
