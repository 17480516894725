import { makeQuery } from 'shared/utilities';
import { ISearchIndexModel } from 'shared/models';

type RequestType = {
    typeId: string | null;
    query: string;
    orderBy?: 'created_at' | 'name' | 'id';
    limit?: number;
    paginate?: 0 | 1;
    source?: 0 | 1;
}

type ResponseType = {
    pages: number;
    items: ISearchIndexModel[];
    total: number
};

export const searchQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/search', { params });
}
