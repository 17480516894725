import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutBody } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiNavLink } from 'shared/uikit';
import { ROUTES } from "shared/constants";

import { LibraryBooks } from "./components/LibraryBooks";

import { ASSETS } from './assets';

import './index.scss';

export const LibraryPage = observer(() => {
    useEffect(() => {
        ApplicationModule.setTitle('Библиотека знаний');
    }, []);

    return (
        <LayoutBody flexStart={false}>
            <div className="p-library">
                <div className="p-library-background">
                    <div className="p-library-background-top">
                        <div className="p-library-background-top__inner">
                            <div className="p-library-background__circle"/>
                            <div className="p-library-background__circle"/>
                            <div className="p-library-background__circle"/>
                            <LibraryBooks/>
                        </div>
                    </div>
                    <div className="p-library-background-bottom"></div>
                </div>
                <div className="p-library__body">
                    <div className="p-library__header">
                        <div className="p-library__title">Единая база знаний Togas</div>
                        <div className="p-library__description">Все полезные материалы, компании, собранные в одном
                            месте
                        </div>
                    </div>
                    <div className="p-library__items">
                        <UiNavLink to={ROUTES.BOOKS()} className="p-library-item">
                            <div className="p-library-item__preview">
                                <div className="p-library-animated-books">
                                    <div className="p-library-animated-books__square"/>
                                    <div className="p-library-animated-books__inner">
                                        <div className="p-library-animated-books__columns">
                                            <div className="p-library-animated-books__column">
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book1})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book2})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book3})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book4})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book5})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book6})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book7})` }}
                                                />
                                            </div>
                                            <div className="p-library-animated-books__column">
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book2})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book9})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book10})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book8})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book11})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book12})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book1})` }}
                                                />
                                            </div>
                                            <div className="p-library-animated-books__column">
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book9})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book13})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book14})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book15})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book16})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book17})` }}
                                                />
                                                <div
                                                    className="p-library-animated-books__item"
                                                    style={{ backgroundImage: `url(${ASSETS.book18})` }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-library-item__title">Книги</div>
                            <div className="p-library-item__description">Электронная и живая библиотеки Togas</div>
                        </UiNavLink>
                        <UiNavLink to={ROUTES.ARTICLES()} className="p-library-item">
                            <div className="p-library-item__preview">
                                <div className="p-library-animated-articles">
                                    <div className="p-library-animated-articles__circle"/>
                                    <div className="p-library-animated-articles__items">
                                        <div
                                            className="p-library-animated-articles__item"
                                            style={{ backgroundImage: `url(${ASSETS.card1})` }}
                                        />
                                        <div
                                            className="p-library-animated-articles__item"
                                            style={{ backgroundImage: `url(${ASSETS.card2})` }}
                                        />
                                        <div
                                            className="p-library-animated-articles__item"
                                            style={{ backgroundImage: `url(${ASSETS.card3})` }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-library-item__title">Статьи</div>
                            <div className="p-library-item__description">База статей компании</div>
                        </UiNavLink>
                    </div>
                </div>
            </div>
        </LayoutBody>
    )
});
