import { makeQuery } from 'shared/utilities';
import { IAnnouncementModel } from 'shared/models';

type ResponseType = {
    item: IAnnouncementModel;
};

export const announcementsGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/announcements/get', { params: {id} });
}
