import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

type PropsType = {
    children?: React.ReactNode
}

export const LayoutContentPanel = observer(({ children }: PropsType) => {
    return (
        <div className={'layout-content-panel'}>{children}</div>
    );
});
