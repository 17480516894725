import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: number | null
}

type ResponseType = {};

export const columnsDeleteQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/columns/delete', {
        prefix: 'task-tracker',
        json: request
    });
}
