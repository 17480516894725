import React, { CSSProperties, ReactNode } from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

type PropsType = {
    style?: CSSProperties,
    children?: ReactNode
}

export const UiWrap = observer(({style, children}: PropsType) => {
    return (
        <div className='ui-wrap' style={style}>
            {children}
        </div>
    )
})
