import { makeQuery } from 'shared/utilities';
import { ITaskModel } from "shared/models/TaskTracker";

type RequestType = {
    statusId?: string | null,
    spaceId?: number | null,
    ownerUserId?: number | null,
    responsibleUserId?: number | null,
    query?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    sortBy?: string | null,
    sortType?: string | null,
    page?: number | null,
}

type ResponseType = {
    items: ITaskModel[],
    pages: number
};

export const tasksQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks', {
        prefix: 'task-tracker',
        params: request
    });
}
