import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiDataBoundary } from "shared/uikit";
import { useStore } from "shared/hooks";
import { userPersonalFieldsQuery } from "shared/queries";
import { UserPersonalFieldModel } from "shared/models";

import { PProfileUserPersonalField } from "../PProfileUserPersonalField";

import './styles.scss';

export const PProfileUserPersonalFields = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        items: [] as UserPersonalFieldModel[],
        errorMessage: ''
    }))

    useEffect(() => {
        (async () => {
            store.set("isLoading", true);
            const { isSuccess, data, errorMessage } = await userPersonalFieldsQuery();
            if (isSuccess && data) {
                store.set("items", data.items.map(item => new UserPersonalFieldModel(item)));
            }
            store.set("isSuccess", isSuccess && data);
            store.set("errorMessage", errorMessage);
            store.set("isLoading", false);
        })();
    }, [store]);

    return (
        <div className="p-user-section">
            <div className="p-user-section__title">Личная информация</div>
            <UiDataBoundary isLoading={store.isLoading} errorMessage={store.errorMessage}>
                {store.items.map(userPersonalField => (
                    <PProfileUserPersonalField key={userPersonalField.id} item={userPersonalField}/>
                ))}
            </UiDataBoundary>
        </div>
    );
});
