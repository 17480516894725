import { makeObservable, observable } from 'mobx';
import { CafeMealModel } from 'shared/models';
import { Form } from 'shared/utilities';

export default new (class Store {
    isLoading = true;
    isSuccess = true;
    items: CafeMealModel[] = [];

    modal = {
        form: false,
        countdown: false,
    };

    form = new Form(
        {
            name: '',
            description: '',
            tmpFileId: null as number | null,
        },
        {
            name: Form.rules(Form.RULE.REQUIRED()),
            description: Form.rules(Form.RULE.REQUIRED()),
        }
    );

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            items: observable,
            modal: observable,
            form: observable,
        });
    }
})();
