import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { computed, makeObservable } from 'mobx';

import { OnChangeHandlerType } from 'shared/types';

import './styles.scss';

interface Props {
    onChange: OnChangeHandlerType<string>;
    value: string;
    name: string;
    isValid: boolean;
    isError?: boolean;
    placeholder: string;
    style: React.CSSProperties;
}

class UiTextarea extends React.Component<Props> {
    static defaultProps: Props = {
        onChange: () => {},
        value: '',
        name: '',
        isValid: true,
        placeholder: '',
        style: {},
    };

    constructor(props: Props) {
        super(props);

        makeObservable(this, {
            className: computed,
        });
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { onChange, name } = this.props;

        onChange({
            name,
            value: event.target.value,
        });
    };

    get className() {
        const { value, isValid, isError } = this.props;

        return classnames('ui-textarea', {
            'ui-textarea--notempty': !!value,
            'ui-textarea--error': !isValid || isError,
        });
    }

    render() {
        const { value, name, placeholder, style } = this.props;

        return (
            <div className={this.className} style={style}>
                <textarea
                    name={name}
                    className='ui-textarea__control'
                    value={value || ''}
                    onChange={this.handleChange}
                    data-cy={`ui-textarea-${name}`}
                />
                {!!placeholder && (
                    <div className='ui-textarea__placeholder'>
                        <span>{placeholder}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default observer(UiTextarea);
