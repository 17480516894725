import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { Api } from 'shared/utilities';
import { UiIcon } from 'shared/uikit';
import { COLORS, ICONS } from 'shared/constants';
import { OnChangeHandlerType } from 'shared/types';
import { ITmpFileModel } from 'shared/models';

import './styles.scss';

type PropsType = {
    onChange: OnChangeHandlerType<number | null>;
    value: number | null;
    name: string;
    className: string;
    type: 'image' | 'all'
    // children: React.ReactNode | ({isUploading, isEmpty})
}

class UiTmpFileUpload extends React.Component<PropsType> {
    static defaultProps: PropsType = {
        onChange: () => {
        },
        value: null,
        name: 'file',
        type: 'all',
        className: '',
    };

    ref = React.createRef<HTMLInputElement>();
    file: ITmpFileModel | null = null;
    progress = 0;

    constructor(props: PropsType) {
        super(props);

        makeObservable(this, {
            file: observable,
            progress: observable,
            setProgress: action,
            componentDidUpdate: action,
            isUploading: computed,
            accept: computed,
            view: computed,
            handleClear: action,
        });
    }

    componentDidUpdate = (prevProps: PropsType) => {
        if (prevProps.value !== null && this.props.value === null && this.file !== null) {
            this.file = null;
        }
    };

    handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange, name } = this.props;

        event.preventDefault();
        event.stopPropagation();
        if (!event?.target?.files || event.target.files.length < 1) {
            return;
        }

        const response = await Api.tmpFile(event.target.files[0], (progress: number) => {
            this.setProgress(progress);
        });

        if (!response.isSuccess) {
            this.setProgress(0);
            return;
        }

        runInAction(() => {
            this.file = response.data.item;
        });

        onChange({
            name,
            control: 'ui-tmp-file-upload',
            value: response.data.item.id as number,
            url: response.data.item.url,
        });

        setTimeout(() => {
            this.setProgress(0);
        }, 250);
    };

    setProgress = (value: number) => {
        this.progress = value;
    };

    handleClear = (event: React.SyntheticEvent) => {
        const { onChange, name } = this.props;
        event.preventDefault();
        event.stopPropagation();

        this.file = null;
        if (this.ref?.current?.value) {
            this.ref.current.value = '';
        }
        onChange({
            name,
            control: 'ui-tmp-file-upload',
            value: null,
        });
    };

    get isUploading() {
        return this.progress > 0 && this.progress < 100;
    }

    get accept() {
        if (this.props.type === 'image') {
            return 'image/*';
        }
        return '*';
    }

    get view() {
        if (this.isUploading) {
            return (
                <div className='ui-tmp-file-upload__view'>
                    <UiIcon icon={ICONS.ATTACHMENT} size={24} color={COLORS.GRAY_3}/>
                    <span>Загрузка... {this.progress}%</span>
                </div>
            );
        }

        if (this.file === null) {
            return (
                <div className='ui-tmp-file-upload__view'>
                    <UiIcon icon={ICONS.ATTACHMENT} size={24} color={COLORS.GRAY_3}/>
                    <span>Прикрепить файл</span>
                </div>
            );
        }

        return (
            <div className='ui-tmp-file-upload__view'>
                <UiIcon icon={ICONS.ATTACHMENT} size={24} color={COLORS.GRAY_3}/>
                <span>{this.file.name}</span>
                <div className='ui-tmp-file-upload__clear' onClick={this.handleClear}>
                    <UiIcon icon={ICONS.CLOSE} size={14} color={COLORS.GRAY_3}/>
                </div>
            </div>
        );
    }

    render() {
        const { className } = this.props;

        return (
            <label className={`ui-tmp-file-upload ${className}`}>
                {this.view}
                <div className='ui-tmp-file-upload__control'>
                    <input
                        ref={this.ref}
                        type='file'
                        accept={this.accept}
                        onChange={this.handleChange}
                    />
                </div>
            </label>
        );
    }
}

export default observer(UiTmpFileUpload);
