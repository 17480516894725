import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { useMedia, useNavigate, useUrlParams } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';

const items = [{
    id: null,
    icon: (<UiIcon size={24} icon={ICONS.TASK_TRACKER_BOARD}/>)
}, {
    id: 'list',
    icon: (<UiIcon size={24} icon={ICONS.TASK_TRACKER_LIST}/>)
}];
export const CFilterView = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        view: null
    });

    const { isMobileOrTablet, is1024 } = useMedia();

    useEffect(() => {
        if (isMobileOrTablet || is1024) {
            navigate(null, {
                ...urlParams,
                view: null,
                fSortType: null,
                fSortProperty: null,
            })
        }
    }, [isMobileOrTablet, is1024, navigate, urlParams]);
    const handleSelect = (view: string | null) => {
        navigate(null, {
            ...urlParams,
            view,
            fSortType: null,
            fSortProperty: null,
        });
    }

    return (
        <div className="c-tt-filter-view">
            {items.map(item => (
                <div
                    key={item.id}
                    className={classnames('c-tt-filter-view__item', {
                        'c-tt-filter-view__item--active': urlParams.view === item.id || (!item.id && urlParams.view === 'board')
                    })}
                    onClick={() => handleSelect(item.id)}
                >
                    {item.icon}
                </div>
            ))}
        </div>
    )
});
