import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { DocumentModel } from './DocumentModel';
import { Model } from './Model';

export interface IDocumentCategoryModel {
    id?: number;
    sort?: number;
    name?: string;
    typeId?: string;
    image?: string | null;
    documents?: DocumentModel[];
}

export class DocumentCategoryModel extends Model implements IDocumentCategoryModel {
    casts = {
        documents: new ModelArrayCast(DocumentModel),
    };

    id = 0;
    sort = 0;
    name = '';
    image = '';
    typeId = '';
    documents: DocumentModel[] = [];

    constructor(payload: IDocumentCategoryModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            typeId: observable,
            sort: observable,
            image: observable,
            documents: observable,
            update: action,
        });

        this.update(payload);
    }
}
