import { observer } from 'mobx-react';
import React from 'react';

import { useAsyncEffect, useStore } from "shared/hooks";
import { UserNotificationModel } from "shared/models";
import { userNotificationsGetQuery, userNotificationsSaveQuery } from "shared/queries";
import { UiCheckboxSwitch } from 'shared/uikit';

import './styles.scss';

const hintIcon: string = require('./assets/hint.svg');

export const PProfileNotifications = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        userNotification: new UserNotificationModel()
    }));

    useAsyncEffect(async () => {
        const { isSuccess, data } = await userNotificationsGetQuery();
        if (!isSuccess || !data) {
            return;
        }
        store.userNotification.update(data.item);
        store.set("isLoading", false);
    }, [store]);

    const handleChange = ({ value, name }: { value: number, name: string }) => {
        store.userNotification.update({
            [name]: value
        });
        userNotificationsSaveQuery(store.userNotification);
    }

    if (store.isLoading) {
        return null;
    }

    return (
        <div className="p-user-section p-user-section--aside">
            <div className="p-user-section__title">Уведомления</div>
            <div className="p-user-section-hint">
                <div className="p-user-section-hint__icon" style={{backgroundImage: `url(${hintIcon})`}}></div>
                <div className="p-user-section-hint__outer">
                    Вы можете настроить отображение уведомлений различных разделов. Для разделов: Документы, упоминания в комментариях и задачи из таск-трекера уведомления отключить нельзя
                </div>
            </div>
            <UiCheckboxSwitch
                value={store.userNotification.announcementCreated}
                label='Объявления'
                name='announcementCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.bookCreated}
                label='Книги'
                name='bookCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.educationLessonRecent}
                label='Ближайшие события'
                name='educationLessonRecent'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.educationLessonCreated}
                label='Обучение'
                name='educationLessonCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.articleCreated}
                label='Статьи'
                name='articleCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.vacancyCreated}
                label='Вакансии'
                name='vacancyCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.surveyCreated}
                label='Опросы'
                name='surveyCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.galleryCreated}
                label='Togas Life'
                name='galleryCreated'
                onChange={handleChange}
            />
            <UiCheckboxSwitch
                value={store.userNotification.usersBirthdays}
                label='Дни рождения'
                name='usersBirthdays'
                onChange={handleChange}
            />
        </div>
    );
});
