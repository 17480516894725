import { action, computed, makeObservable, observable } from 'mobx';

import { Model } from './Model';
import { ModelArrayCast } from "shared/casts";

interface IOfficeCorporateTransport {
    color?: string,
    name?: string,
    time?: string,
    description?: string,
    stations?: string,
}

interface IOfficePublicTransport {
    color?: string,
    name?: string,
    description?: string,
    stations?: string,
    coordinatesMap?: string,
    coordinatesMarkers?: string,
    coordinatesRoute?: string,
}

export interface IOfficeModel {
    id?: number;
    name?: string;
    address?: string;
    corporateTransportDescription?: string;
    corporateTransport?: IOfficeCorporateTransport[];
    publicTransport?: IOfficePublicTransport[];
}

export class OfficeCorporateTransportModel extends Model implements IOfficeCorporateTransport {
    color = '';
    name = '';
    time = '';
    description = '';
    stations = '';

    constructor(payload: IOfficeModel = {}) {
        super();

        makeObservable(this, {
            color: observable,
            name: observable,
            time: observable,
            description: observable,
            stations: observable,
            stationsData: computed,
            update: action,
        });

        this.update(payload);
    }

    get stationsData() {
        return this.stations.split("\n").map(item => item.trim());
    }
}

export class OfficePublicTransportModel extends Model implements IOfficePublicTransport {
    color = '';
    name = '';
    description = '';
    stations = '';
    coordinatesMap = '';
    coordinatesMarkers = '';
    coordinatesRoute = '';

    constructor(payload: IOfficeModel = {}) {
        super();

        makeObservable(this, {
            color: observable,
            name: observable,
            description: observable,
            stations: observable,
            coordinatesMap: observable,
            coordinatesMapData: computed,
            coordinatesMarkers: observable,
            coordinatesMarkersData: computed,
            coordinatesRoute: observable,
            coordinatesRouteData: computed,
            update: action,
        });

        this.update(payload);
    }

    get coordinatesMapData() {
        return this.coordinatesMap.split(',').map(item => {
            return +item.trim()
        }) as [number, number];
    }

    get coordinatesMarkersData() {
        return this.coordinatesMarkers.split("\n").map(item => item.split(',').map(coords => {
            return +coords.trim()
        }) as [number, number]).filter(item => item.length === 2);
    }

    get coordinatesRouteData() {
        return this.coordinatesRoute.split("\n").map(item => item.split(',').map(coords => {
            return +coords.trim()
        }) as [number, number]).filter(item => item.length === 2);
    }
}


export class OfficeModel extends Model implements IOfficeModel {
    casts = {
        corporateTransport: new ModelArrayCast(OfficeCorporateTransportModel),
        publicTransport: new ModelArrayCast(OfficePublicTransportModel),
    };

    id = 0;
    name = '';
    address = '';
    corporateTransportDescription = '';
    corporateTransport: OfficeCorporateTransportModel[] = [];
    publicTransport: OfficePublicTransportModel[] = [];
    image = '';

    constructor(payload: IOfficeModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            address: observable,
            corporateTransportDescription: observable,
            corporateTransport: observable,
            publicTransport: observable,
            image: observable,
            update: action,
        });

        this.update(payload);
    }
}

