import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UiButton, UiFormControl, UiInput, UiTextarea, UiTmpFileUpload } from 'shared/uikit';
import { useStore, useValidation } from "shared/hooks";
import { improvementProposalsCreateQuery } from "shared/queries";
import { CModalForm, CModalCountdown } from "shared/components";

import './styles.scss';
import { ApplicationModule } from "shared/modules";

type PropsType = {
    description?: string,
    isWide?: boolean
}

export const WImprovementProposal = observer((
    {
        description = 'Напиши нам, чтобы это не осталось просто идеей!',
        isWide = false
    }: PropsType
) => {
    const store = useStore(() => ({
        isOpened: false,
        isCountdown: false,
        isSubmitted: false,
    }));

    const form = useStore(() => ({
        name: '',
        description: '',
        tmpFileId: null
    }))

    const validation = useValidation(form, (rules) => ({
        name: rules.required(),
        description: rules.required(),
    }));

    const handleCountdownStart = () => {
        store.set('isSubmitted', true);
        if (!validation.isValid) {
            return;
        }
        store.set('isOpened', false);
        store.set('isCountdown', true);
    };

    const handleCountdownCancel = useCallback(() => {
        store.set('isOpened', true);
        store.set('isCountdown', false);
    }, [store]);

    const handleCountdownFinish = useCallback(async () => {
        store.set('isCountdown', false);
        await improvementProposalsCreateQuery(form);
        form.reset();
        store.reset();
    }, [store, form]);

    const handleOpen = useCallback(() => {
        store.set('isOpened', true);
    }, [store]);

    const handleClose = useCallback(() => {
        store.set('isOpened', false);
    }, [store]);

    const className = classnames('w-improvement-proposal', {
        'w-improvement-proposal--wide': isWide
    });

    return (
        <>
            <div className={className}>
                <div className="w-improvement-proposal__inner">
                    <div className="w-improvement-proposal__title">
                        {ApplicationModule.applicationSettings.widgetImprovementProposalTitle || 'Есть предложения по улучшению Компании и T-net?'}</div>
                    <div className="w-improvement-proposal__image"></div>
                    <div className="w-improvement-proposal__description">
                        {ApplicationModule.applicationSettings.widgetImprovementProposalDescription ||description}
                    </div>
                    <UiButton label='Предложить' onClick={handleOpen}/>
                </div>
            </div>
            <CModalForm
                onClose={handleClose}
                isOpened={store.isOpened}
                onSubmit={handleCountdownStart}
                title='Предложение по улучшению'
                description='Если вы хотите поделиться идей, которая улучшит совместную работу или вы нашли ошибку на сайте — напишите ваши предложения в форме и мы обязательно их рассмотрим.'
            >
                <UiFormControl
                    label='Краткое описание'
                    className='mb-20'
                    isSubmitted={store.isSubmitted}
                    errorMessage={validation.name.errorMessage}
                >
                    <UiInput
                        placeholder='Кратко опишите ваше предложение'
                        name='name'
                        onChange={form.handleChange}
                        value={form.name}
                        isError={store.isSubmitted && !validation.name.isValid}
                    />
                </UiFormControl>
                <UiFormControl
                    label='Детальное описание'
                    className='mb-20'
                    isSubmitted={store.isSubmitted}
                    errorMessage={validation.description.errorMessage}
                >
                    <UiTextarea
                        placeholder='Детально опишите ваше предложение'
                        name='description'
                        onChange={form.handleChange}
                        value={form.description}
                        isError={store.isSubmitted && !validation.description.isValid}
                    />
                </UiFormControl>
                <UiTmpFileUpload
                    className='mb-20'
                    name='tmpFileId'
                    value={form.tmpFileId}
                    onChange={form.handleChange}
                />
            </CModalForm>
            <CModalCountdown
                isOpened={store.isCountdown}
                onFinish={handleCountdownFinish}
                onCancel={handleCountdownCancel}
                title={'Ваше предложение почти отправлено'}
                description={
                    'Если вы передумали отправлять сообщение, вы можете сделать отмену. По окончании срока таймера сообщение отправится и отмену сделать будет невозможно. Закрывая это уведомление, сообщение отправится автоматически.'
                }
            />
        </>
    )
});
