import { action, computed, makeObservable, observable } from 'mobx';
import { Moment } from "moment";

import { Model } from './Model';

export interface IReservationPlaceIntervalModel {
    moment?: Moment;
    isSelected?: boolean;
    isInvolved?: boolean;
}

export class ReservationPlaceIntervalModel extends Model implements IReservationPlaceIntervalModel {

    moment: Moment;
    isSelected = false;
    isInvolved = false;

    constructor(payload: IReservationPlaceIntervalModel = {}) {
        super();

        makeObservable(this, {
            moment: observable,
            isSelected: observable,
            isInvolved: observable,
            time: computed,
            timeValue: computed,
            isEven: computed,
            update: action,
        });

        this.update(payload);
    }

    get time() {
        return this.moment.format('HH:mm');
    }

    get timeValue() {
        return this.moment.hours() * 60 + this.moment.minutes();
    }

    get isEven() {
        return this.moment.minutes() === 0;
    }
}
