import { makeQuery } from 'shared/utilities';
import { IDocumentCategoryModel } from 'shared/models';

type RequestType = {
    typeId?: string;
}

type ResponseType = {
    pages: number;
    items: IDocumentCategoryModel[];
};

export const documentCategoriesQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/document-categories', { params });
}
