import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';

import { VacancyModel } from 'shared/models';
import { ROUTES } from 'shared/constants';
import { UiAddress, UiButton, UiDate, UiDropdown, UiFavorite, UiNavLink } from 'shared/uikit';
import { OnChangeEventType } from 'shared/types';
import { VacancyRespondModal } from 'shared/components';
import { useAction } from 'shared/hooks';

import './styles.scss';
import { Resolution } from 'shared/utilities';

interface Props {
    item: VacancyModel;
}

export default observer(({ item }: Props) => {
    const store = useLocalObservable(() => ({
        isOpened: false,
    }));

    const handleUpdate = ({ name, value }: OnChangeEventType<number>) => {
        item.update({ [name]: value });
    };

    const handleShow = useAction((e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        store.isOpened = true;
    });

    const handleHide = useAction(() => {
        store.isOpened = false;
    });

    return (
        <>
            <div className='vacancy-item'>
                <div className='vacancy-item__title'>
                    <UiNavLink to={ROUTES.VACANCY(item.id)} className='vacancy-item__name'>
                        {item.name}
                    </UiNavLink>
                </div>
                <div className='vacancy-item__header'>
                    <UiDate value={item.createdAt} />
                    <UiAddress
                        city={item.vacancyRegion?.name}
                        address={item.addressPreview}
                        color={item.addressColor}
                    />
                </div>
                <div className='vacancy-item__body'>{item.previewText}</div>
                <div className='vacancy-item__footer'>
                    <UiButton className={'vacancy-item__respond'} isSmall label='Откликнуться' onClick={handleShow} />
                    <UiDropdown
                        className='vacancy-item-contacts'
                        control={<UiButton isSmall label='Показать контакты' isOutline isTranslucent />}
                        label={item.contactName}
                    >
                        {item.contactInfo && <div className='vacancy-item-contacts__info'>{item.contactInfo}</div>}
                        {item.contactEmail && (
                            <a href={`mailto:${item.contactEmail}`} className='vacancy-item-contacts__email'>
                                {item.contactEmail}
                            </a>
                        )}
                    </UiDropdown>
                    <UiFavorite
                        buttonProps={
                            Resolution.isMobile
                                ? {
                                      isSmall: true,
                                      isTranslucent: true,
                                      isOutline: true,
                                  }
                                : null
                        }
                        entityId={item.id}
                        entityType='Vacancy'
                        value={item.favorited}
                        onChange={handleUpdate}
                    />
                </div>
            </div>
            <VacancyRespondModal isOpened={store.isOpened} vacancyId={item.id} onClose={handleHide} />
        </>
    );
});
