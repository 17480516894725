import moment from "moment";
import React from 'react';
import { observer } from 'mobx-react';

import { useMedia } from "shared/hooks";
import { ApplicationModule } from "shared/modules";

import { PProfileImage } from "../PProfileImage";
import { PProfileISpring } from "../PProfileISpring";
import { PProfileOutlook } from "../PProfileOutlook";
import { PProfileWidgets } from "../PProfileWidgets";
import { PProfileNotifications } from "../PProfileNotifications";
import { PProfileUser } from "../PProfileUser";
import { PProfileUserResponsibilities } from "../PProfileUserResponsibilities";
import { PProfileUserPersonalFields } from "../PProfileUserPersonalFields";

export const PProfileSettings = observer(() => {
    const {
        isMobileOrTablet
    } = useMedia({
        is320: 1,
        is1024: 3
    });

    return (
        <div className="p-user">
            <div className="p-user__aside">
                <PProfileImage/>
                {!isMobileOrTablet && (
                    <>
                        <PProfileWidgets/>
                        <PProfileNotifications/>
                    </>
                )}
            </div>
            <div className="p-user__main">
                <PProfileUser/>
                {ApplicationModule.user.birthdayAt && (
                    <div className="p-user-section">
                        <div className="p-user-section__title">Дата рождения</div>
                        <div className="p-user-section__description">{moment(ApplicationModule.user.birthdayAt).format('D MMMM')}</div>
                    </div>
                )}
                <PProfileUserResponsibilities/>
                <PProfileUserPersonalFields/>
                <PProfileOutlook/>
                <PProfileISpring/>
                {isMobileOrTablet && (
                    <>
                        <PProfileWidgets/>
                        <PProfileNotifications/>
                    </>
                )}
            </div>
        </div>
    );
});
