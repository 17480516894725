type EnumValue = string | number | null;

export class Enum {
    static fromValue(value: EnumValue) {
        return new this(value);
    }

    value: EnumValue = '';

    constructor(value: EnumValue) {
        this.value = value;
    }

    is = (value: EnumValue) => this.value === value;
    in = (values: EnumValue[]) => values.indexOf(this.value) > -1;

    static items: {
        id: string,
        name: string
    }[] = [];
}
