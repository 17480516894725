import { action, computed, makeObservable, observable } from 'mobx';

import { Model } from './Model';
import { IUserUserPersonalFieldModel, UserUserPersonalFieldModel } from "./UserUserPersonalFieldModel";
import { ModelCast } from "shared/casts";

export interface IUserPersonalFieldModel {
    id?: number;
    name?: string;
    placeholder?: string;
    valueDefault?: string;
    userUserPersonalField?: IUserUserPersonalFieldModel,
    pivot?: {
        value: string,
        showValueDefault: number
    }
}

export class UserPersonalFieldModel extends Model implements IUserPersonalFieldModel {
    casts = {
        userUserPersonalField: new ModelCast(UserUserPersonalFieldModel)
    }
    id = 0;
    name = '';
    placeholder = '';
    valueDefault = '';
    userUserPersonalField = new UserUserPersonalFieldModel();
    pivot = {
        value: '',
        showValueDefault: 0
    };

    constructor(payload: IUserPersonalFieldModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            placeholder: observable,
            valueDefault: observable,
            pivot: observable,
            userUserPersonalField: observable,
            update: action,
            value: computed,
            isFilled: computed,
        });

        if (payload.userUserPersonalField === null) {
            delete payload['userUserPersonalField'];
        }
        this.update(payload);
    }

    get isFilled(){
        if (!!this.pivot.showValueDefault || !!this.userUserPersonalField.showValueDefault) {
            return true;
        }

        if (this.pivot?.value) {
            return true;
        }

        if (this.userUserPersonalField?.value) {
            return true;
        }

        return false;
    }

    get value() {
        if (!!this.pivot.showValueDefault || !!this.userUserPersonalField.showValueDefault) {
            return this.valueDefault;
        }

        if (this.pivot?.value) {
            return this.pivot.value;
        }

        if (this.userUserPersonalField?.value) {
            return this.userUserPersonalField.value;
        }

        return '';
    }
}
