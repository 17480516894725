import { observer } from 'mobx-react';
import React from 'react';
import { Mention, MentionsInput } from 'react-mentions'

import { COLORS, ICONS } from "shared/constants";
import { useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { ICommentMentionModel } from "shared/models/CommentMentionModel";
import { ApplicationModule } from "shared/modules";
import { usersQuery } from "shared/queries";
import { UiAvatar, UiEmojis, UiIcon } from "shared/uikit";
import { userPreviewName } from "shared/utilities";

import { Store } from "../../Store";

import './index.scss';

type PropsType = {
    id?: number | null,
    commentId: number | null,
    value?: string,
    onSubmit?: () => {}
}

export const CCommentSave = observer(({ id = null, commentId, onSubmit, value }: PropsType) => {
    const store = useStore(() => ({
        value: value || '',
        commentMentions: [] as ICommentMentionModel[],
        isLoading: false
    }))

    const handleSubmit = async () => {
        if (store.value.trim() === '') {
            return;
        }
        const commentMentions = store.commentMentions.filter(commentMention => {
            if (!commentMention?.userId || !commentMention?.userName) {
                return false;
            }
            return store.value.indexOf(`${commentMention.userId}`) > -1 && store.value.indexOf(commentMention.userName) > -1;
        });

        let value = `${store.value}`;
        commentMentions.forEach(commentMention => {
            if (commentMention.userName) {
                value = value.replace(commentMention.userName, userPreviewName(commentMention.userName));
            }
        })
        if (store.isLoading) {
            return;
        }
        store.set("isLoading", true)
        await Store.saveComment({
            id,
            value,
            commentId,
            commentMentions
        });
        onSubmit && onSubmit();
        store.set("value", '')
        store.set("isLoading", false)
    }

    return (
        <>
            <div className="c-comment-save">
                {!id && (
                    <div className="c-comment-save__image">
                        <UiAvatar size={48} image={ApplicationModule.user.imageAvatar}/>
                    </div>
                )}
                <div className="c-comment-save__inner">
                    <MentionsInput
                        className={'c-comment-save-control'}
                        value={store.value}
                        allowSpaceInQuery
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && e.ctrlKey) {
                                e.preventDefault();
                                e.stopPropagation();
                                store.set('value', store.value + "\n");
                                return false;
                            }
                            if (e.keyCode === 13 && !e.shiftKey) {
                                e.preventDefault();
                                e.stopPropagation();
                                handleSubmit();
                                return false;
                            }
                        }}
                        onChange={(e) => {
                            store.set("value", e.target.value);
                        }}
                        allowSuggestionsAboveCursor
                    >
                        <Mention
                            trigger="@"
                            data={async (query, callback) => {
                                const response = await usersQuery({
                                    query,
                                    limit: 8
                                });

                                if (!response.isSuccess || !response.data) {
                                    return [];
                                }

                                const data = response.data.items.map(item => {
                                    const user = new UserModel(item);
                                    return {
                                        id: user.id,
                                        display: user.name
                                    }
                                });

                                callback(data);
                                return data;
                            }}
                            markup={'<a target="_blank" href="/user/__id__">__display__</a>'}
                            onAdd={(userId, userName) => {
                                if (!store.commentMentions.find(commentMention => commentMention.userId === userId)) {
                                    store.set("commentMentions", [...store.commentMentions, { userId, userName }]);
                                }
                            }}
                            displayTransform={(_, display) => {
                                return userPreviewName(display);
                            }}
                        />
                    </MentionsInput>
                    <div className="c-comment-save__placeholder">
                        Напишите комментарий
                    </div>
                    <div className="c-comment-save__actions">
                        <UiEmojis name='value' onChange={store.handleChange} value={store.value}/>
                        <div className="c-comment-save__send" onClick={handleSubmit}>
                            <UiIcon icon={ICONS.SEND} color={COLORS.GRAY_4A} size={24}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
})

