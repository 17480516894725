import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

type PropsType = {
    className?: string;
    onSubmit?: () => void;
    children: React.ReactNode;
}

export const UiForm = observer(({className = '', onSubmit, children = null}: PropsType) => {
    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit && onSubmit();
    };

    return (
        <form className={classnames('ui-form', className)} onSubmit={handleSubmit}>
            {children}
        </form>
    );
})
