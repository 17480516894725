import { makeQuery } from 'shared/utilities';
import { IApplicationSettingsModel } from 'shared/models';

type ResponseType = {
    item: IApplicationSettingsModel[];
};

export const applicationSettingsQuery = async function () {
    return await makeQuery<ResponseType>('/application-settings');
}
