import { Location, UnregisterCallback } from 'history';
import qs from 'qs';
import lodash from 'lodash';
import { action, makeObservable, observable } from 'mobx';

import Router from './Router';
import { ObjectType } from 'shared/types';

type ObjectAlias = object;

interface ITypeMappers extends ObjectAlias {
    string: (value: any) => string;
    boolean: (value: any) => boolean;
    array: <T>(value: T) => T | null;
    object: <T>(value: T) => T | null;
    number: (value: any) => number | null;
}

const mappers: ITypeMappers = {
    string: (value: any) => {
        if (!value) {
            return null;
        }
        return value.toString ? value.toString() : value;
    },
    boolean: (value: any) => {
        if (['false', undefined, null, '', 0, '0', false].indexOf(value) > -1) {
            return false;
        }
        if (['true', 1, '1', true].indexOf(value) > -1) {
            return true;
        }
        return !!value;
    },
    array: value => (!value || !Array.isArray(value) ? null : value),
    object: (value: any) => (!value ? null : value),
    number: (value: any) => {
        if (!value) {
            return null;
        }
        let _value = +value;
        if (isNaN(_value)) {
            return null;
        }
        return _value;
    },
} as ITypeMappers;

type Handler<T> = (data: { values: T; action: string; isPush: boolean; isPop: boolean }) => void;

export class QueryParams<
    TQueryParamsValues extends object,
    TQueryParamsKeys extends object = {
        [Property in keyof TQueryParamsValues]: keyof TQueryParamsValues;
    },
    TQueryParamsMappers extends object = {
        [Property in keyof TQueryParamsValues]: (value: any) => any;
    }
> {
    static mapper = mappers;
    private unlisten: UnregisterCallback;
    private handler: Handler<TQueryParamsValues> = () => {};

    initialValues: TQueryParamsValues;
    mappers: TQueryParamsMappers;
    keys: TQueryParamsKeys;
    values: TQueryParamsValues;
    isSubscribed = false;

    constructor(initialValues: TQueryParamsValues, optionalValueMapper: ObjectType = {}) {
        this.initialValues = { ...initialValues };
        this.values = { ...initialValues };
        this.keys = Object.keys(initialValues).reduce((prev, current) => {
            return {
                ...prev,
                [current]: current,
            };
        }, {} as TQueryParamsKeys);
        this.mappers = Object.keys(initialValues).reduce((prev, current) => {
            let mapper;
            if (current in optionalValueMapper) {
                mapper = optionalValueMapper[current];
            } else {
                const value = initialValues[current as keyof TQueryParamsValues];
                const mapperKey = Array.isArray(value) ? 'array' : typeof value;
                mapper = (QueryParams.mapper as any)[mapperKey];
            }

            return {
                ...prev,
                [current]: mapper,
            };
        }, {} as TQueryParamsMappers);

        makeObservable(this, {
            initialValues: observable,
            values: observable,
            keys: observable,
            mappers: observable,
            handle: action,
        });
    }

    handle = (location: Location<any>, action: string, triggerHandler = true) => {
        const data = qs.parse(location.search.substr(1)) as TQueryParamsValues;
        this.values = { ...this.initialValues };

        for (let _property in data) {
            const property = lodash.camelCase(_property);
            const propertyValues = property as keyof TQueryParamsValues;
            const propertyMapper = property as keyof TQueryParamsMappers;

            if (this.values.hasOwnProperty(propertyValues) && this.mappers.hasOwnProperty(propertyMapper)) {
                this.values[propertyValues] = (this.mappers as any)[propertyMapper](data[_property]);
            }
        }

        if (triggerHandler) {
            // fix: иногда листенер срабатывал уже после изменения роута
            setTimeout(() => {
                this.isSubscribed &&
                    this.handler &&
                    this.handler({
                        values: this.values,
                        action,
                        isPush: action.toLowerCase() === 'push',
                        isPop: action.toLowerCase() === 'pop',
                    });
            }, 0);
        }
    };

    subscribe = (handler: Handler<TQueryParamsValues> = () => {}) => {
        this.isSubscribed = true;
        this.handler = handler;
        this.handle(Router.history.location, 'PUSH', false);
        this.unlisten = Router.history.listen(this.handle);
    };

    unsubscribe = () => {
        this.isSubscribed = false;
        this.unlisten && this.unlisten();
    };
}
