import { Cast } from "./Cast";

export class ModelArrayCast implements Cast {
    model: any;

    constructor(model: any) {
        this.model = model;
    }

    set = (currentValue: any, newValue: any) => {
        if (!Array.isArray(newValue)) {
            return currentValue;
        }
        return newValue.map((item) => {
            return new this.model(item);
        })
    }
}
