import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Swiper as SwiperType } from "swiper";
import classnames from "classnames";

import { LayoutBody } from 'shared/layout';
import { useMedia, useStore } from "shared/hooks";
import { HistoryItemModel } from "shared/models";
import { historyItemsQuery } from "shared/queries";
import { ApplicationModule } from "shared/modules";

import { Year } from "pages/history/components/Year";

import './index.scss';

const banner = require('./assets/banner.webp');

export const HistoryPage = observer(() => {
    const store = useStore(() => ({
        historyItems: [] as HistoryItemModel[],
        swiper: null as null | SwiperType,
        imagesSwiper: null as null | SwiperType,
        slide: 0,
        transition: false,
    }));

    const { isMobile } = useMedia();

    useEffect(() => {
        ApplicationModule.setTitle('История компании');
        (async () => {
            const { isSuccess, data } = await historyItemsQuery();
            if (isSuccess && data) {
                store.set("historyItems", data.items.map(item => new HistoryItemModel(item)));
            }
        })();
    }, [store])

    useEffect(() => {
        const wheelHandler = (e: WheelEvent) => {
            if (isMobile) {
                return;
            }
            if (store.transition) {
                e.preventDefault();
                return;
            }
            const isBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
            if (!store.swiper) {
                return;
            }
            if (isBottom && e.deltaY > 0) {
                store.set('transition', true);
                store.swiper.slideTo(store.slide + 1);
            }
            if (e.deltaY < 0 && store.slide > 0) {
                store.set('transition', true);
                store.swiper.slideTo(store.slide - 1);
                e.preventDefault();
            }

            setTimeout(() => {
                store.set('transition', false);
            }, 800);
        }
        window.addEventListener('wheel', wheelHandler, { passive: false });
        return () => {
            window.removeEventListener('wheel', wheelHandler);
        }
    }, [store, isMobile]);

    const yearSlide = store.historyItems[store.slide]?.yearSlide ?? 0;
    const colorSlide = store.historyItems[store.slide]?.colorSlide ?? '#fff';
    const backgroundThumbnail = store.historyItems[store.slide]?.backgroundThumbnail ?? '';
    const colorTimeline = store.historyItems[store.slide]?.colorTimeline ?? '#fff';
    const colorText = store.historyItems[store.slide]?.colorText ?? '#fff';
    const background = `${colorSlide}, url(${backgroundThumbnail})`;

    return (
        <LayoutBody className='p-history'>
            <div className="p-history__inner">
                <div className="p-history-banner" style={{ backgroundImage: `url(${banner})` }}>
                    <div className="p-history-banner__shadow"/>
                    <div className="p-history-banner__title">История компании Togas</div>
                </div>
                <div className="p-history-story">
                    <div key={store.slide} className="p-history-story__overlay" style={{background}}/>
                    <Year value={yearSlide}/>
                    <div
                        className="p-history-timeline"
                        style={{ color: colorTimeline, borderColor: colorTimeline }}
                    >
                        {store.historyItems.map((historyItem, index) => {
                            const classNames = classnames('p-history-timeline-year', {
                                'p-history-timeline-year--active': store.slide === index
                            });
                            return (
                                <div className={classNames} key={historyItem.id}>
                                    <div
                                        className="p-history-timeline-year__name"
                                        onClick={() => {
                                            store.swiper?.slideTo(index);
                                        }}
                                    >
                                        {historyItem.yearTimeline}
                                    </div>
                                    <div className="p-history-timeline-year__items">
                                        {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                                            <div key={item} className='p-history-timeline-year__item'>
                                                <div className="p-history-timeline-year__subitems">
                                                    {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                                                        <div key={item} className='p-history-timeline-year__subitem'/>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="p-history-body">
                        {!isMobile && (
                            <div className="p-history-images">
                                <Swiper
                                    spaceBetween={-1}
                                    slidesPerView={1}
                                    direction={'vertical'}
                                    speed={650}
                                    className="p-history-images__slider"
                                    onSwiper={(swiper) => {
                                        store.set("imagesSwiper", swiper);
                                    }}
                                >
                                    {store.historyItems.map(historyItem => (
                                        <SwiperSlide
                                            key={historyItem.year}
                                            className="p-history-images__slide"
                                            style={{
                                                backgroundImage: `url(${historyItem.image})`
                                            }}
                                        />
                                    ))}
                                </Swiper>
                            </div>
                        )}
                        {!isMobile && (
                            <div className="p-history-slider">
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    speed={1}
                                    noSwipingClass={'swiper-slide'}
                                    effect='fade'
                                    modules={[EffectFade]}
                                    onSwiper={(swiper) => {
                                        store.set("swiper", swiper);
                                        store.set("slide", 0);
                                    }}
                                    onSlideChange={(swiper) => {
                                        store.set("slide", swiper.activeIndex);
                                        store.imagesSwiper?.slideTo(swiper.activeIndex);
                                    }}
                                    resistanceRatio={0}
                                >
                                    {store.historyItems.map(historyItem => (
                                        <SwiperSlide key={historyItem.id}>
                                            <div className="p-history-slide" style={{ color: colorText }}>
                                                <div className="p-history-slide__inner">
                                                    <div className="p-history-slide__title">{historyItem.name}</div>
                                                    <div className="p-history-slide__description">
                                                        {historyItem.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        )}
                        {isMobile && store.historyItems.map(historyItem => (
                            <div
                                key={historyItem.id}
                                className="p-history-item"
                                style={{
                                    background: `${historyItem.colorSlide}, url(${historyItem.backgroundThumbnail})`,
                                    color: historyItem.colorText
                                }}
                            >
                                <div className="p-history-item__inner">
                                    <div
                                        className="p-history-item__image"
                                        style={{ backgroundImage: `url(${historyItem.image})` }}
                                    >
                                        <div className="p-history-item__year">{historyItem.yearSlide}-</div>
                                    </div>
                                    <div className="p-history-item__title">{historyItem.name}</div>
                                    <div className="p-history-item__description">
                                        {historyItem.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </LayoutBody>
    )
});
