import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { UiIcon, UiMenuButton, UiNavLink } from 'shared/uikit';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { getAdminPanelLink } from 'shared/utilities';
import { ApplicationModule, LayoutModule } from 'shared/modules';
import { PermissionEnum } from 'shared/enums';
import { useMedia } from "shared/hooks";

import './styles.scss';

export const LayoutMenuPrimary = observer(() => {
    const { isMobileOrTablet } = useMedia();

    const className = classnames('layout-menu-primary', {
        'layout-menu-primary--opened': LayoutModule.menu.isOpened,
    });

    useEffect(() => {
        if (isMobileOrTablet) {
            LayoutModule.menu.setOpened(false);
        }
    }, [isMobileOrTablet])

    const handleNavigate = () => {
        if (isMobileOrTablet) {
            LayoutModule.menu.setOpened(false);
        }
    };

    const handleToggle = () => {
        LayoutModule.menu.setOpened(!LayoutModule.menu.isOpened);
    }

    const items = [
        { isVisible: true, name: 'Главная', route: ROUTES.HOME(), icon: ICONS.HOME },
        { isVisible: true, name: 'Компания', route: ROUTES.COMPANY(), icon: ICONS.HIERARCHY },
        { isVisible: ApplicationModule.user.can(PermissionEnum.WebCalendarAndReservation), name: 'Календарь', route: ROUTES.CALENDAR(), icon: ICONS.CALENDAR },
        { isVisible: ApplicationModule.user.can(PermissionEnum.WebCafe), name: 'Столовая', route: ROUTES.CAFE(), icon: ICONS.DINER },
        { isVisible: ApplicationModule.user.can(PermissionEnum.WebCalendarAndReservation), name: 'Бронирование', route: ROUTES.RESERVATIONS(), icon: ICONS.RESERVATIONS },
        { isVisible: true, name: 'Документы', route: ROUTES.DOCUMENTS(), icon: ICONS.RULES },
        { isVisible: true, name: 'Библиотека знаний', route: ROUTES.LIBRARY(), icon: ICONS.LIBRARY },
        { isVisible: true, name: 'Обучение и адаптация', route: ROUTES.EDUCATION(), icon: ICONS.EDUCATION_COURSES },
        { isVisible: true, name: 'Вакансии', route: ROUTES.VACANCIES(), icon: ICONS.VACANCIES },
        { isVisible: true, name: 'Опросы', route: ROUTES.SURVEYS(), icon: ICONS.SURVEY },
        { isVisible: true, name: 'Задачи', route: ROUTES.TASK_TRACKER('personal'), icon: ICONS.TASK_TRACKER },
        { isVisible: true, name: 'Togas Life', route: ROUTES.LIFE(), icon: ICONS.LIFE },
    ];

    return (
        <div className={className}>
            <div className="layout-menu-primary__overlay" onClick={() => LayoutModule.menu.setOpened(false)}/>
            <div className="layout-menu-primary__inner">
                <div className='layout-menu-primary__header'>
                    <div className="layout-menu-primary__toggle" onClick={handleToggle}>
                        <UiMenuButton
                            color={COLORS.WHITE}
                            gap={2}
                            stroke={1.5}
                            width={12}
                            isActive={LayoutModule.menu.isOpened}
                        />
                    </div>
                    <div className="layout-menu-primary__logo"/>
                    <div className="layout-menu-primary__prompt">
                        {LayoutModule.menu.isOpened ? 'Закрыть меню' : 'Раскрыть меню'}
                    </div>
                </div>
                <div className='layout-menu-primary__items'>
                    {items.map(item => {
                        if (!item.isVisible) {
                            return null;
                        }
                        return (
                            <UiNavLink
                                key={item.route}
                                to={item.route}
                                className={({ isActive }) =>
                                    isActive ? 'layout-menu-primary-item layout-menu-primary--active' : 'layout-menu-primary-item'
                                }
                                onClick={handleNavigate}
                            >
                                <div className='layout-menu-primary-item__icon'>
                                    <UiIcon color={COLORS.BLACK} icon={item.icon} size={18}/>
                                </div>
                                <div className='layout-menu-primary-item__name'>{item.name}</div>
                            </UiNavLink>
                        );
                    })}
                    {ApplicationModule.user.can(PermissionEnum.AdminPanel) && (
                        <a
                            className='layout-menu-primary-item'
                            href={getAdminPanelLink()}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='layout-menu-primary-item__icon'>
                                <UiIcon color={COLORS.BLACK} icon={ICONS.LIBRARY}/>
                            </div>
                            <div className='layout-menu-primary-item__name'>Админ. панель</div>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
});
