import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { useOnClickOutside, useStore } from "shared/hooks";

import './styles.scss';

type PropsType = {
    control?: React.ReactNode;
    children?: React.ReactNode;
    items?: { id: string | number, name: string }[],
    className?: string,
    onSelect?: (id: string | number) => void,
    onToggle?: (isOpened: boolean) => void,
    width?: number,
    right?: number | string | undefined,
    left?: number | string | undefined,
    toTop?: boolean
    toRight?: boolean
}

export const UiDropdownMenu = observer((
    {
        items = [],
        control = null,
        children = null,
        width = 150,
        right = undefined,
        left = undefined,
        className = '',
        toTop = false,
        toRight = false,
        onToggle = () => {
        },
        onSelect = () => {
        }
    }: PropsType
) => {
    const ref = useRef(null);
    const store = useStore(() => ({
        isVisible: false,
    }));

    const handleToggle = useCallback((e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
        store.set('isVisible', !store.isVisible);
        onToggle(store.isVisible);
    }, [store, onToggle]);

    const handleHide = useCallback(() => {
        if (!store.isVisible) {
            return;
        }
        store.set('isVisible', false);
        onToggle(store.isVisible);
    }, [store, onToggle]);

    useOnClickOutside(ref, handleHide);

    const classNames = useMemo(() => classnames('ui-dropdown-menu', className, {
        'ui-dropdown-menu--visible': store.isVisible,
        'ui-dropdown-menu--to-top': toTop,
        'ui-dropdown-menu--to-right': toRight,
    }), [store.isVisible, className, toTop, toRight]);

    const handleSelect = (id: string | number) => (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        handleHide();
        onSelect && onSelect(id);
    }

    return (
        <div className={classNames} ref={ref}>
            <div className='ui-dropdown-menu__control' onClick={handleToggle}>
                {control || children}
            </div>
            {store.isVisible && (
                <div className='ui-dropdown-menu__outer' style={{ minWidth: width, right, left }}>
                    {items?.map(item => (
                        <div
                            key={item.id}
                            className="ui-dropdown-menu__item"
                            onClick={handleSelect(item.id)}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

