import { makeQuery } from 'shared/utilities';
import { IReservationModel } from 'shared/models';

type ResponseType = {
    items: IReservationModel[];
};

export const reservationsQuery = async function (date: string) {
    return await makeQuery<ResponseType>('/reservations', { params: { date } });
}
