import React, { useEffect } from 'react';
import { action } from "mobx";
import { observer, useLocalObservable } from 'mobx-react';

import { ICONS, COLORS } from 'shared/constants';

import { UiIcon } from '../UiIcon';

import './styles.scss';

type SlideType = {
    id: number | string,
    [key: string | number | symbol]: any;
}

interface Props {
    perView: number,
    slide: (item: any) => React.ReactNode,
    items: SlideType[]
}

export const UiSliderCasual = observer(({ perView = 1, slide, items }: Props) => {
    const store = useLocalObservable(() => ({
        slide: 0,
        items: [] as SlideType[],
        perView: 1,
        setSlide(slide: number) {
            if (slide < 0) {
                this.slide = this.items.length - 1;
            } else if (slide >= this.items.length) {
                this.slide = 0;
            } else {
                this.slide = slide;
            }
        },
        getSlideIndex(slide: number) {
            let _slide = slide;
            if (this.items.length < 1) {
                return _slide;
            }
            while (_slide >= this.items.length) {
                _slide -= this.items.length;
            }
            return _slide;
        },
        get slides() {
            if (this.items.length === 0) {
                return [] as SlideType[];
            }

            if (this.perView >= this.items.length) {
                return this.items as SlideType[];
            }

            const slides = [this.slide];
            let slide = this.slide;
            for (let i = 1; i < this.perView; i++) {
                slide += 1;
                slides.push(this.getSlideIndex(slide));
            }
            return slides.map(index => this.items[index]) as SlideType[];
        }
    }));

    useEffect(() => {
        action(() => {
            store.perView = perView;
            store.items = items;
        })()
    }, [perView, items, store]);

    return (
        <div className='ui-slider-casual'>
            {
                store.items.length > store.perView && (
                    <>
                        <div
                            className="ui-slider-casual__button ui-slider-casual__button--prev"
                            onClick={() => store.setSlide(store.slide - 1)}
                        >
                            <UiIcon size={16} icon={ICONS.ARROW_LEFT} color={COLORS.BLACK}/>
                        </div>
                        <div
                            className="ui-slider-casual__button ui-slider-casual__button--next"
                            onClick={() => store.setSlide(store.slide + 1)}
                        >
                            <UiIcon size={16} icon={ICONS.ARROW_RIGHT} color={COLORS.BLACK}/>
                        </div>
                    </>
                )
            }
            <div className="ui-slider-casual__items">
                {store.slides.map(item => (
                    <div key={item.id} className="ui-slider-casual__item">
                        {slide(item)}
                    </div>
                ))}
            </div>
        </div>
    )
});
