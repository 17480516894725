import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IUserUserPersonalFieldModel {
    id?: number;
    value?: string;
    userPersonalFieldId?: number;
    showValueDefault?: boolean;
}

export class UserUserPersonalFieldModel extends Model implements IUserUserPersonalFieldModel {
    id = 0;
    value = '';
    userPersonalFieldId = 0;
    showValueDefault = false;

    constructor(payload: IUserUserPersonalFieldModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            value: observable,
            userPersonalFieldId: observable,
            showValueDefault: observable,
            update: action,
        });

        this.update(payload);
    }
}
