import { isObservableArray } from "mobx";

export const isArray = (value: any) => {
    if (isObservableArray(value)) {
        return true;
    }
    if (Array.isArray(value)) {
        return true;
    }
    return false;
}
