import { Enum } from './Enum';

export class BookTypeEnum extends Enum {
    static Ebook = 'ebook';
    static Book = 'book';

    static items = [{
        id: BookTypeEnum.Ebook,
        name: 'Электронные'
    }, {
        id: BookTypeEnum.Book,
        name: 'Печатные'
    }];
}

