import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { useBroadcast, useMedia, useNavigate, useStore, useUrlParams } from "shared/hooks";
import { UiDataBoundary, UiIcon, UiModal, UiScrollbar } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { notificationsDeleteQuery, notificationsQuery, notificationsReadAllQuery } from "shared/queries";
import { NotificationModel } from "shared/models";
import { NotificationCreatedBroadcast } from "shared/broadcasts";

import { Item } from "./components/Item";

import './index.scss';

const emptyImage = require('./assets/empty.png');

export const CTaskTrackerNotifications = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        isLoading: false,
        notifications: [] as NotificationModel[]
    }));

    const urlParams = useUrlParams({
        taskTrackerNotifications: null as null | string
    });

    const { isMobileOrTablet } = useMedia();

    const handleToggle = (value: string | null) => {
        navigate(null, {
            ...urlParams,
            taskTrackerNotifications: value
        }, {
            replace: true
        });
    }

    const handleDelete = (notification: NotificationModel) => {
        notificationsDeleteQuery({
            id: [notification.id]
        });
        store.set("notifications", store.notifications.filter(n => n.id !== notification.id));
    }

    const handleDeleteAll = () => {
        notificationsDeleteQuery({
            id: store.notifications.map(n => n.id)
        });
        store.set("notifications", []);
    }

    useEffect(() => {
        (async () => {
            store.set("isLoading", true);
            const { isSuccess, data } = await notificationsQuery({
                moduleId: 'task_tracker',
                perPage: 1000
            });
            if (isSuccess && data) {
                store.set("notifications", data.items.map(item => new NotificationModel(item)));
            }
            store.set("isLoading", false);
        })();
    }, [store]);

    useEffect(() => {
        if (!urlParams.taskTrackerNotifications) {
            return;
        }
        (async () => {
            const readAt = moment().format();
            store.notifications.forEach(notification => notification.update({ readAt }))
            notificationsReadAllQuery({
                moduleId: 'task_tracker'
            })
        })();
    }, [store.notifications, urlParams.taskTrackerNotifications]);

    useBroadcast(new NotificationCreatedBroadcast((data) => {
        store.set("notifications", [new NotificationModel(data.notification), ...store.notifications]);
    }));

    const count = store.notifications.filter(notification => !notification.readAt).length;

    return (
        <>
            <div className="layout-menu-secondary-item">
                <div
                    className="layout-menu-secondary-item__inner"
                    onClick={() => handleToggle('show')}
                    title={'Уведомления по задачам'}
                >
                    <UiIcon icon={ICONS.TASK_TRACKER} size={24} color={COLORS.WHITE}/>
                    {count > 0 && (
                        <div className="layout-menu-secondary-item__count">{count}</div>
                    )}
                </div>
            </div>
            <UiModal
                isOpened={!!urlParams.taskTrackerNotifications}
                onClose={() => handleToggle(null)}
                styleBody={{
                    width: 900
                }}
                isAside
            >
                {!isMobileOrTablet && (
                    <div className="c-tt-notifications__close" onClick={() => handleToggle(null)}>
                        <UiIcon icon={ICONS.CLOSE} size={18} color='#fff'/>
                    </div>
                )}
                <div className="c-tt-notifications__header">
                    {!isMobileOrTablet && (
                        <div className="c-tt-notifications__icon">
                            <UiIcon icon={ICONS.NOTIFICATIONS} size={24} color='#999999'/>
                        </div>
                    )}
                    {isMobileOrTablet && (
                        <div className="c-tt-notifications__icon--close" onClick={() => handleToggle(null)}>
                            <UiIcon icon={ICONS.ARROW_LEFT} size={20} color='#000'/>
                        </div>
                    )}

                    <div className="c-tt-notifications__title">Уведомления</div>
                    <div className="c-tt-notifications__clear" onClick={handleDeleteAll}>
                        <UiIcon icon={ICONS.TRASH} size={20} color='#999999'/>
                        <span>Очистить все</span>
                    </div>
                </div>
                <UiDataBoundary isLoading={store.isLoading}>
                    {store.notifications.length > 0 && (
                        <UiScrollbar>
                            {store.notifications.map(notification => (
                                <Item
                                    key={notification.id}
                                    notification={notification}
                                    onRead={() => handleDelete(notification)}
                                />
                            ))}
                        </UiScrollbar>
                    )}
                    {store.notifications.length === 0 && (
                        <div className="c-tt-notifications__empty">
                            <img src={emptyImage} alt=""/>
                            <span>Список уведомлений пуст</span>
                        </div>
                    )}
                </UiDataBoundary>
            </UiModal>
        </>
    );
});
