import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

interface Props {
    aside?: React.ReactNode | string | number;
    label?: string | null | undefined;
    style?: React.CSSProperties;
}

class UiCardSubtitle extends React.Component<Props> {
    render() {
        const { label, aside, style } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div className='ui-card-subtitle' style={style}>
                <span>{label}</span>
                {aside && <div className='ui-card-subtitle__aside'>{aside}</div>}
            </div>
        );
    }
}

export default observer(UiCardSubtitle);
