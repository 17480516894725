import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { ThumbnailType } from "shared/types";

import './styles.scss';

type PropsType = {
    className?: string,
    value?: ThumbnailType | string,
    style?: React.CSSProperties,
    children?: React.ReactNode,
    fallback?: React.ReactNode,
}

export const UiImageThumbnail = observer((
    {
        className = '',
        value = null,
        style,
        children,
        fallback
    }: PropsType
) => {
    const classNames = classnames('ui-image-thumbnail', className);
    const classNamesDummy = classnames('ui-image-thumbnail', 'ui-image-thumbnail--dummy', className);

    if (typeof value === 'string' && value !== '') {
        return (
            <div className={classNames} style={{ backgroundImage: `url(${value})` }}>{children}</div>
        );
    }

    if (!value || (value && (!value.origin && !value.webp))) {
        if (fallback) {
            return (<>{fallback}</>);
        }
        return (
            <div className={classNamesDummy}>
                {children}
            </div>
        )
    }

    let urls = [];
    if (value.webp) {
        urls.push(value.webp)
    }
    if (value.origin) {
        urls.push(value.origin)
    }
    const backgroundImage = urls.map(url => `url(${url})`).join(',');
    return (
        <div className={classNames} style={{ ...style, backgroundImage }}>{children}</div>
    )
})
