import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UiLoadingSpinner } from 'shared/uikit';

import './styles.scss';

type PropsType = {
    className?: string;
    isLoading?: boolean;
    children?: React.ReactNode
}

export const UiCard = observer(({ className, isLoading = false, children }: PropsType) => {
    const classNames = classnames('ui-card', className);
    if (isLoading) {
        return (
            <div className={classNames}>
                <UiLoadingSpinner/>
            </div>
        );
    }

    return <div className={classNames}>{children}</div>;
})
