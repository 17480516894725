import classnames from "classnames";
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { useStore } from "shared/hooks";

import { SurveyModel, SurveyQuestionAnswerModel, SurveyQuestionModel } from 'shared/models';
import { UiButton, UiFormControl, UiTextarea } from "shared/uikit";

import './styles.scss';

interface Props {
    survey: SurveyModel;
    surveyQuestion: SurveyQuestionModel;
    surveyQuestionAnswer: SurveyQuestionAnswerModel;
    onAnswer?: (surveyQuestionId: number, id: number, comment?: string) => void
}

export const UiSurveyQuestionAnswer = observer((
    {
        surveyQuestion,
        surveyQuestionAnswer,
        onAnswer,
        survey
    }: Props
) => {
    const store = useStore(() => ({
        isOpened: false,
        comment: '',
        isSubmitted: false
    }));

    useEffect(() => {
        if (surveyQuestion.userSurveyQuestionAnswer && store.isOpened) {
            store.set('isOpened', false);
        }
    }, [store, surveyQuestion.userSurveyQuestionAnswer])

    const width = useMemo(() => {
        if (surveyQuestion.userSurveyQuestionAnswersCount === 0) {
            return 0;
        }
        if (survey.isActive && surveyQuestion.userSurveyQuestionAnswer.length === 0) {
            return 0;
        }
        const percent = survey.isGlobalAnswers
            ? surveyQuestionAnswer.userSurveyQuestionAnswersCount * 100 / survey.userSurveyQuestionAnswersCount
            : surveyQuestionAnswer.userSurveyQuestionAnswersCount * 100 / surveyQuestion.userSurveyQuestionAnswersCount;

        return Math.round(percent * 100) / 100;
    }, [
        surveyQuestion.userSurveyQuestionAnswersCount,
        surveyQuestionAnswer.userSurveyQuestionAnswersCount,
        surveyQuestion.userSurveyQuestionAnswer,
        survey.isActive,
        survey.isGlobalAnswers,
        survey.userSurveyQuestionAnswersCount,
    ]);

    const textValue = useMemo(() => {
        if (survey.isAbsoluteAnswers) {
            return surveyQuestionAnswer.userSurveyQuestionAnswersCount;
        }

        return `${width}%`
    }, [
        width,
        survey.isAbsoluteAnswers,
        surveyQuestionAnswer.userSurveyQuestionAnswersCount
    ])

    const className = classnames('ui-survey-question-answer', {
        'ui-survey-question-answer--completed': surveyQuestion.userSurveyQuestionAnswer.length > 0 || survey.isCompleted || !onAnswer,
        'ui-survey-question-answer--selected': surveyQuestionAnswer.isSelected
    });

    const handleClick = () => {
        if (surveyQuestion.userSurveyQuestionAnswer.length > 0 || survey.isCompleted || !onAnswer) {
            return;
        }
        if (surveyQuestion.isMultiple) {
            surveyQuestionAnswer.update({
                isSelected: !surveyQuestionAnswer.isSelected
            });
            return;
        }
        if (surveyQuestionAnswer.isExtended) {
            store.set('isOpened', true);
        } else {
            onAnswer && onAnswer(surveyQuestion.id, surveyQuestionAnswer.id);
        }
    }

    const handleSubmit = () => {
        store.set('isSubmitted', true);
        if (!store.comment) {
            return;
        }
        onAnswer && onAnswer(surveyQuestion.id, surveyQuestionAnswer.id, store.comment);
        store.set('isOpened', false);
        store.set('comment', '');
    }

    const isError = store.isSubmitted && !store.comment;
    return (
        <div className={className}>
            <div className="ui-survey-question-answer__inner" onClick={handleClick}>
                <div className="ui-survey-question-answer__bar" style={{ width: `${width}%` }}></div>
                <div className="ui-survey-question-answer__name">{surveyQuestionAnswer.name}</div>
                <div className="ui-survey-question-answer__percent">({textValue})</div>
            </div>
            {store.isOpened && !!surveyQuestionAnswer.isExtended && (
                <div className="ui-survey-question-answer-extended">
                    <UiFormControl errorMessage={isError ? 'Обязательное для заполнения поле' : ''}>
                        <UiTextarea
                            isValid={!isError}
                            placeholder='Введите сообщение'
                            onChange={({ value }) => store.set('comment', value)}
                            value={store.comment}
                        />
                    </UiFormControl>

                    <div
                        className="ui-survey-question-answer-extended__actions"
                        style={{ marginTop: isError ? 20 : 10 }}
                    >
                        <UiButton
                            label='Отменить'
                            isSmall
                            isOutline
                            onClick={() => store.set('isOpened', false)}
                        />
                        <UiButton
                            label='Ответить'
                            isSmall
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            )}
        </div>
    )
});
