import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { UiAvatar, UiButton, UiCard, UiCardFooter, UiCardTitle, UiDate, UiIcon, UiNavLink } from 'shared/uikit';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { useNavigate, useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { usersBirthdaysQuery } from "shared/queries";
import { CalendarItemTypeEnum } from "shared/enums";

const UserItem = observer(({ user }: { user: UserModel }) => {
    return (
        <UiNavLink to={ROUTES.USER(user.slug)} className='w-users-birthdays-item'>
            <div className="w-users-birthdays-item__avatar">
                <UiAvatar size={40} label={user.previewName} image={user.imageAvatar}/>
            </div>
            <div className="w-users-birthdays-item__inner">
                <div className="w-users-birthdays-item__info">
                    <div className="w-users-birthdays-item__name">{user.previewName}</div>
                    <div className="w-users-birthdays-item__position">{user.userPositionName}</div>
                </div>
                <UiDate value={user.birthdayAt} format={'D MMMM'}/>
            </div>
        </UiNavLink>
    )
});

export const WUsersBirthdaysWeek = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        items: [] as UserModel[],
        date: moment(),
        isWeekOpened: false,
        isMonthOpened: false,
        isLoading: true,
        isSuccess: false,
    }));

    const fetchItems = useCallback(async () => {
        store.set('isLoading', true);
        store.set('isSuccess', false);
        const response = await usersBirthdaysQuery();
        if (response.isSuccess && response.data) {
            store.set('items', response.data.items.map(item => new UserModel(item)));
            store.set('isSuccess', true);
        }
        store.set('isLoading', false);
    }, [store]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const usersSorted = useMemo(() => {
        return store.items.slice().sort((a, b) => {
            return +a.birthDayAtMoment.format('MDD') - +b.birthDayAtMoment.format('MDD');
        });
    }, [store.items]);

    const usersWeek = useMemo(() => {
        const start = +moment().startOf('week');
        const end = +moment().endOf('week');
        const year = +moment().year();

        return usersSorted
            .filter(user => {
                const date = user.birthDayAtMoment.clone().set('year', year);
                return date.isSameOrAfter(start) && date.isSameOrBefore(end)
            });
    }, [usersSorted]);

    const dateIndex = +store.date.format('MDD');
    const usersWeekSliced = usersWeek
        .filter(user => {
            if (store.isWeekOpened) {
                return true;
            }
            return +user.birthdayIndex >= dateIndex;
        })
        .slice(0, store.isWeekOpened ? 1000 : 3);

    return (
        <UiCard isLoading={store.isLoading} className='w-users-birthdays'>
            <UiCardTitle label='Дни рождения на этой неделе' collapsable={'w-users-birthdays'}/>
            <div className="w-users-birthdays__section">
                <div className='w-users-birthdays__items'>
                    {usersWeekSliced.map(user => <UserItem key={`week.${user.id}`} user={user}/>)}
                </div>
                <UiButton
                    isLink
                    isSmall
                    label={store.isWeekOpened ? 'Свернуть' : 'Развернуть'}
                    iconAfter={
                        <UiIcon
                            color={COLORS.BROWN_1}
                            size={14}
                            icon={store.isWeekOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                        />
                    }
                    onClick={() => store.set("isWeekOpened", !store.isWeekOpened)}
                />
            </div>
            <UiCardFooter>
                <UiButton
                    isLink
                    label='Подробнее'
                    iconAfter={<UiIcon color={COLORS.BROWN_1} size={11} icon={ICONS.ARROW_RIGHT}/>}
                    onClick={() => navigate(ROUTES.CALENDAR(), {
                        calendarItemType: CalendarItemTypeEnum.Birthday
                    })}
                />
            </UiCardFooter>
        </UiCard>
    );
});
