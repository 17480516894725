import { chatMessagesQuery, chatRoomsGetQuery } from "shared/queries";
import { ChatMessageModel, ChatRoomModel } from "shared/models";
import { ApplicationModule } from "shared/modules";

import { Store } from './store';

class _Service {
    fetchData = async (chatRoomId: number) => {
        Store.set("isLoading", true);
        await this.fetchChatRoom(chatRoomId);
        await this.fetchChatMessages(chatRoomId);
        Store.set("isLoading", false);
        Store.scrollbar?.scrollTo(0, 99999);
        setTimeout(() => Store.scrollbar?.scrollTo(0, 99999), 1);
        setTimeout(() => Store.scrollbar?.scrollTo(0, 99999), 100);
    }

    fetchChatRoom = async (id: number) => {
        const { isSuccess, data } = await chatRoomsGetQuery(id);
        if (isSuccess && data) {
            Store.set("chatRoom", new ChatRoomModel(data.item));
            ApplicationModule.setTitle(Store.chatRoom?.user?.previewName || 'Сообщения')
        }
    }

    fetchChatMessages = async (chatRoomId: number) => {
        const { isSuccess, data } = await chatMessagesQuery({ chatRoomId });
        if (isSuccess && data) {
            Store.set("chatMessages", data.items.map(item => new ChatMessageModel(item)));
        }
        Store.set("isSuccess", isSuccess && data);
    }
}

export const Service = new _Service();
