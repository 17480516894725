import { makeQuery } from 'shared/utilities';
import { IGalleryModel } from 'shared/models';

type RequestType = {
    page?: number,
    perPage?: number,
    tagId?: string,
    query?: string,
    isMain?: number,
};

type ResponseType = {
    items: IGalleryModel[];
    page: number;
    pages: number
};

export const galleriesQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/galleries', { params });
}
