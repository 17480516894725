import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiModal } from 'shared/uikit';
import { IWidgetProps } from 'shared/types';
import UiCountdown from 'shared/uikit/UiCountdown';

import './styles.scss';

interface Props extends IWidgetProps {
    isOpened: boolean;
    title?: string;
    description?: string;
    onCancel?: () => void;
    onFinish?: () => void;
}

class ModalCountdown extends React.Component<Props> {
    static defaultProps: Props = {
        isOpened: false,
        title: '',
        description: '',
        onCancel: () => {},
        onFinish: () => {},
    };

    render() {
        const { title, description, onCancel, onFinish, isOpened } = this.props;

        return (
            <UiModal isOpened={isOpened} onClose={onFinish} title={title} description={description}>
                <div className='ui-modal__actions'>
                    <UiButton isOutline isLarge label='Отмена' onClick={onCancel} />
                    {isOpened && <UiCountdown onFinish={onFinish} time={30} />}
                </div>
            </UiModal>
        );
    }
}

export default observer(ModalCountdown);
