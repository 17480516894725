import { makeQuery } from 'shared/utilities';
import { IBookModel } from 'shared/models';

type RequestType = {
    page?: number,
    id?: number,
    perPage?: number,
};

type ResponseType = {
    items: IBookModel[];
    page: number;
    pages: number
};

export const booksQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/books', { params });
}
