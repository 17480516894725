import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from "shared/hooks";
import { commentsQuery } from "shared/queries";
import { UiDataBoundary } from "shared/uikit";

import { CComment } from "./components/CComment";
import { CCommentSave } from "./components/CCommentSave";

import { Store } from "./Store";

import './index.scss';

type PropsType = {
    entityId: number,
    entityType: string
}

export const CComments = observer(({ entityId, entityType }: PropsType) => {
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        value: ''
    }));

    useEffect(() => {
        if (!entityId) {
            return
        }
        (async () => {
            Store.entityId = entityId;
            Store.entityType = entityType;

            store.set("isLoading", true);
            store.set("isSuccess", false);
            const { isSuccess, data } = await commentsQuery({ entityId, entityType });
            if (isSuccess && data) {
                Store.setComments(data.items)
            }
            store.set("isSuccess", isSuccess && !!data);
            store.set("isLoading", false);
            if (window.location.hash === '#comments') {
                document.getElementById("comments")?.scrollIntoView();
            }
        })();
    }, [store, entityType, entityId])

    return (
        <div className="c-comments" id='comments'>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="c-comments__inner">
                    <div className="c-comments__header">
                        <div className="c-comments__title">Комментарии</div>
                        {Store.comments.length > 0 && (
                            <div className="c-comments__count">{Store.comments.length}</div>
                        )}
                    </div>
                    <div className="c-comments__create">
                        <CCommentSave commentId={null}/>
                    </div>
                    {Store.rootComments.length > 0 && (
                        <div className="c-comments__items">
                            {Store.rootComments.map(comment => <CComment key={comment.id} comment={comment}/>)}
                        </div>
                    )}
                </div>
            </UiDataBoundary>
        </div>
    );
})

