import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { IDepartmentBranchModel, DepartmentBranchModel } from "./DepartmentBranchModel";
import { IDepartmentGroupModel, DepartmentGroupModel } from "./DepartmentGroupModel";
import { IUserModel, UserModel } from "./UserModel";

import { Model } from './Model';

export interface IDepartmentModel {
    id?: number;
    name?: string;
    description?: string;
    color?: string;
    isVisible?: boolean;
    departmentBranches?: IDepartmentBranchModel[];
    departmentGroups?: IDepartmentGroupModel[];
    management?: IUserModel[];
    users?: IUserModel[];
}

export class DepartmentModel extends Model implements IDepartmentModel {
    casts = {
        departmentBranches: new ModelArrayCast(DepartmentBranchModel),
        departmentGroups: new ModelArrayCast(DepartmentGroupModel),
        management: new ModelArrayCast(UserModel),
        users: new ModelArrayCast(UserModel),
    };

    id = 0;
    name = '';
    isVisible = true;
    description = '';
    color = '';
    departmentBranches: DepartmentBranchModel[] = [];
    departmentGroups: DepartmentGroupModel[] = [];
    management: UserModel[] = [];
    users: UserModel[] = [];

    constructor(payload: IDepartmentModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            isVisible: observable,
            description: observable,
            color: observable,
            departmentBranches: observable,
            departmentGroups: observable,
            management: observable,
            users: observable,
            update: action
        });

        this.update(payload);
    }
}
