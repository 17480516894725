import { action } from "mobx";
import { DependencyList, useCallback } from "react";

import { OnChangeHandlerType } from "shared/types";

export const useHandler = function <T = any>(handler: OnChangeHandlerType<T>, deps: DependencyList = []): OnChangeHandlerType<T> {
    // eslint-disable-next-line
    return useCallback(action(handler), deps);
}

