import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IWebResourceModel {
    id?: number;
    sort?: number;
    name?: string;
    image?: string;
    linkName?: string;
    linkHref?: string;
}

export class WebResourceModel extends Model implements IWebResourceModel {
    id = 0;
    sort = 0;
    name = '';
    image = '';
    linkName = '';
    linkHref = '';

    constructor(payload: IWebResourceModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            sort: observable,
            name: observable,
            image: observable,
            linkName: observable,
            linkHref: observable,
            update: action,
        });

        this.update(payload);
    }
}
