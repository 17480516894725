import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import './styles.scss';

interface Props {
    value: string | null;
    format: string;
}

class UiDate extends React.Component<Props> {
    static defaultProps = {
        value: null,
        format: 'DD MMMM YYYY',
    };

    render() {
        const { value, format } = this.props;

        if (!value) {
            return null;
        }

        return <div className='ui-date'>{moment(value as string).format(format)}</div>;
    }
}

export default observer(UiDate);
