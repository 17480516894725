import { makeQuery } from 'shared/utilities';
import { IEducationCardModel } from 'shared/models';

type ResponseType = {
    items: IEducationCardModel[];
};

export const educationCardsQuery = async function () {
    return await makeQuery<ResponseType>('/education-cards');
}
