import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { Draggable, DraggableProvided, Droppable } from "@hello-pangea/dnd";
import classnames from "classnames";

import { useStore, useUrlParams } from "shared/hooks";
import { ColumnModel, TaskModel } from "shared/models/TaskTracker";
import { CTask } from "shared/components/TaskTracker/CTask";
import { UiIcon, UiScrollbar } from "shared/uikit";
import { CContextMenu } from "shared/components/TaskTracker";
import { ICONS } from "shared/constants";
import { columnsSaveQuery } from "shared/queries/TaskTracker";

import './index.scss';

type PropsType = {
    canEdit?: boolean,
    column: ColumnModel,
    tasks: TaskModel[],
    onDeleted?: (column: ColumnModel) => void,
    index: number,
}

export const CColumn = observer((
    {
        column,
        index,
        tasks,
        canEdit = false,
        onDeleted = () => {
        },
    }: PropsType
) => {
    return (
        <Draggable
            index={index}
            isDragDisabled={!canEdit}
            draggableId={`column-${column.id}`}
            shouldRespectForcePress
        >
            {(draggable) => (
                <CColumnBody
                    canEdit={canEdit}
                    draggable={draggable}
                    column={column}
                    tasks={tasks}
                    onDelete={onDeleted}
                />
            )}
        </Draggable>
    );
});

type BodyPropsType = {
    canEdit?: boolean,
    column: ColumnModel,
    tasks: TaskModel[],
    onDelete?: (column: ColumnModel) => void,
    draggable: DraggableProvided
}

export const CColumnBody = observer((
    {
        canEdit = false,
        column,
        tasks,
        draggable,
        onDelete = () => {
        },
    }: BodyPropsType
) => {
    const ref = useRef<HTMLInputElement>(null);
    const store = useStore(() => ({
        isEditing: false,
        name: ''
    }));
    const urlParams = useUrlParams({
        view: 'board',
        fSortProperty: null as null | string,
        fSortType: null as null | string
    });

    const sortedTasks = tasks.slice()
        .sort((task1, task2) => {
            if (urlParams.fSortProperty && urlParams.fSortType && task1[urlParams.fSortProperty] !== undefined) {
                const string1 = `${task1[urlParams.fSortProperty]}`;
                const string2 = `${task2[urlParams.fSortProperty]}`;
                if (urlParams.fSortType === 'asc') {
                    return string1.localeCompare(string2)
                }
                return string2.localeCompare(string1)
            }
            return task1.sort - task2.sort;
        })
        .filter((_, index) => {
            if (!column.isCollapsed) {
                return true;
            }
            if (column.isCollapsed && index < 1) {
                return true;
            }
            return false;
        });

    const handleEdit = () => {
        store.set("name", column.name);
        store.set("isEditing", true);
        setTimeout(() => {
            ref.current?.setSelectionRange(0, 200);
        }, 10);
    }

    const handleSubmit = () => {
        if (!store.name) {
            store.set("isEditing", false);
            return;
        }

        column.update({
            name: store.name
        });
        store.set("isEditing", false);
        columnsSaveQuery(column);
    }

    return (
        <div
            ref={(ref) => draggable.innerRef(ref)}
            className={classnames('c-tt-column', `c-tt-column--${urlParams.view}`)}
            {...draggable.draggableProps}
        >
            {store.isEditing && (
                <input
                    className={'c-tt-column__edit'}
                    ref={ref}
                    type="text"
                    autoFocus
                    onBlur={handleSubmit}
                    onKeyUp={(e) => {
                        if (e.key.toLocaleLowerCase() === 'escape') {
                            store.set("isEditing", false);
                        }
                        if (e.key.toLocaleLowerCase() === 'enter') {
                            handleSubmit();
                        }
                    }}
                    value={store.name}
                    name={'name'}
                    onChange={(e) => {
                        store.set("name", e.target.value || '');
                    }}
                />
            )}
            {!store.isEditing && (
                <div className="c-tt-column__header">
                    <div
                        className="c-tt-column__name"
                        {...draggable.dragHandleProps}
                    >
                        {column.name}
                    </div>
                    <div className="c-tt-column__actions">
                        <div className="c-tt-column__count">{tasks.length}</div>
                        {(column.type.is('custom') && canEdit) && (
                            <CContextMenu
                                size={20}
                                items={[[{
                                    name: 'Переименовать',
                                    action: handleEdit
                                }], [{
                                    name: 'Удалить колонку',
                                    color: '#EB3B5A',
                                    icon: (<UiIcon icon={ICONS.TRASH} size={18} color={'#EB3B5A'}/>),
                                    action: () => onDelete(column)
                                }]]}
                            />
                        )}
                        <div
                            className={classnames('c-tt-column__collapse', {
                                'c-tt-column__collapse--collapsed': column.isCollapsed
                            })}
                            onClick={() => column.update({ isCollapsed: !column.isCollapsed })}
                        >
                            <UiIcon icon={ICONS.CHEVRON_UP} size={12} color={'#000'}/>
                        </div>
                    </div>
                </div>
            )}
            <UiScrollbar
                scrollbarGap={[4, 0]}
                updateKey={sortedTasks.map(task => task.id).join(',')}
            >
                <Droppable
                    droppableId={`column-${column.id}`}
                    type={'task'}
                >
                    {(droppable,) => (
                        <div
                            className={'c-tt-column__tasks'}
                            ref={droppable.innerRef}
                            {...droppable.droppableProps}
                        >
                            {sortedTasks.map((task, index) => (
                                <CTask
                                    key={`task-${task.id}`}
                                    index={index}
                                    task={task}
                                />
                            ))}
                            {droppable.placeholder}
                        </div>
                    )}
                </Droppable>
            </UiScrollbar>
        </div>
    );
});
