import React from 'react';
import { observer } from 'mobx-react';

import { UiIcon, UiNavLink } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    to: string
    name: string
    image: string
}

export const PCompanyCardLink = observer(({image, name, to}: PropsType) => {
    return (
        <UiNavLink to={to} className="p-company-card-link">
            <div className="p-company-card-link__name">{name}</div>
            <div className="p-company-card-link__icon">
                <UiIcon icon={ICONS.ARROW_RIGHT} size={16} color={COLORS.BLACK}/>
            </div>
            <div className="p-company-card-link__circle"/>
            <div className="p-company-card-link__image" style={{backgroundImage: `url(${image})`}}/>
        </UiNavLink>
    );
})

