import { action, computed, makeObservable, observable } from 'mobx';

import { ThumbnailType } from "shared/types";

import { Model } from './Model';


export interface IGalleryFileModel {
    id?: number;
    createdAt?: string;
    name?: string;
    file?: string;
    size?: string;
    extension?: string;
    thumbnail?: ThumbnailType;
    url?: string;
}

export class GalleryFileModel extends Model implements IGalleryFileModel {
    id = 0;
    createdAt = '';
    file = '';
    name = '';
    size = '';
    extension = '';
    thumbnail: ThumbnailType = null;
    url = '';

    constructor(payload: IGalleryFileModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            file: observable,
            size: observable,
            name: observable,
            createdAt: observable,
            extension: observable,
            thumbnail: observable,
            url: observable,
            isImage: computed,
            update: action,
        });

        this.update(payload);
    }

    get isImage() {
        return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(this.extension.toLowerCase());
    }
}
