import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { CContextMenu } from "shared/components/TaskTracker";
import { TaskTrackerColumnTypeEnum } from "shared/enums";
import { useUrlParams, useUser } from "shared/hooks";

import { BoardModel, ColumnModel, TaskModel } from "shared/models/TaskTracker";
import { tasksUpdateQuery } from "shared/queries/TaskTracker";

import { CTask } from "../CTask";

import './index.scss';

type PropsType = {
    boards: BoardModel[],
    columns: ColumnModel[],
    tasks: TaskModel[],
}

export const CInbox = observer(({ tasks, columns, boards }: PropsType) => {
    const user = useUser();
    const { fInvolving } = useUrlParams({
        fInvolving: null
    });

    let items = useMemo(() => {
        if (fInvolving === 'responsible') {
            return tasks.filter(task => task.responsibleUserId === user.id)
        }

        if (fInvolving === 'owner') {
            return tasks.filter(task => task.ownerUserId === user.id)
        }

        return tasks;
    }, [fInvolving, user, tasks]);

    if (items.length === 0) {
        return null;
    }

    return (
        <div className={'c-tt-inbox'}>
            <div className="c-tt-inbox__title">Поставленные задачи</div>
            <div className="c-tt-inbox__inner">
                <div className="c-tt-inbox__items">
                    {items.map(task => (
                        <CTask
                            type={'inbox'}
                            key={task.id}
                            task={task}
                            index={task.id}
                            style={{ minWidth: 270, width: 270 }}
                            header={(
                                <CContextMenu
                                    title={'Переместить на доску:'}
                                    items={[boards.filter(board => board.spaceId === task.spaceId).map(board => {
                                        return {
                                            name: board.name,
                                            action: () => {
                                                const column = columns.find((column) => {
                                                    return +column.boardId === +board.id && column.type.is(TaskTrackerColumnTypeEnum.New);
                                                })
                                                if (!column) {
                                                    return;
                                                }
                                                task.update({
                                                    columnId: column.id,
                                                    boardId: board.id,
                                                    column
                                                });
                                                tasksUpdateQuery({
                                                    id: task.id,
                                                    columnId: column.id,
                                                    boardId: board.id,
                                                })
                                            }
                                        }
                                    })]}
                                />
                            )}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});
