import { makeQuery } from 'shared/utilities';
import { ISpaceModel } from "shared/models/TaskTracker";

type ResponseType = {
    items: ISpaceModel[],
};

export const spacesQuery = async function () {
    return await makeQuery<ResponseType>('/spaces', {
        prefix: 'task-tracker',
    });
}
