import React from 'react';
import { observer } from 'mobx-react';

import { GalleryModel } from 'shared/models';
import { UiCommentAction, UiIcon, UiImageThumbnail, UiLikeAction, UiNavLink } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import './styles.scss';

type PropsType = {
    gallery: GalleryModel;
}

export const GalleryItem = observer(({ gallery }: PropsType) => {
    return (
        <UiNavLink to={ROUTES.GALLERY(gallery.id)} className='gallery-item'>
            <div className="gallery-item__preview">
                {gallery.preview.slice(0, 3).map((thumbnail, index) => (
                    <UiImageThumbnail
                        key={index}
                        className="gallery-item__image"
                        value={thumbnail}
                    />
                ))}
                <div className="gallery-item__icons">
                    {gallery.hasImages && (
                        <UiIcon icon={ICONS.IMAGES} size={16} color={COLORS.WHITE}/>
                    )}
                    {gallery.hasVideos && (
                        <UiIcon icon={ICONS.VIDEOS} size={16} color={COLORS.WHITE}/>
                    )}
                </div>
            </div>
            <div className="gallery-item__date">{gallery.createdAtMoment.format('D MMMM YYYY')}</div>
            <div className="gallery-item__name">{gallery.name}</div>
            <div className="gallery-item__footer">
                <UiLikeAction
                    count={gallery.likesCount}
                    value={gallery.liked}
                    entityId={gallery.id}
                    entityType={'Gallery'}
                    onChange={({ value }) => {
                        gallery.update({
                            liked: value
                        })
                    }}
                />
                <UiCommentAction to={ROUTES.GALLERY(gallery.id)} count={gallery.commentsCount}/>
            </div>
        </UiNavLink>
    )
});
