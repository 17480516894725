import { makeQuery } from 'shared/utilities';
import { ISpaceModel } from "shared/models/TaskTracker";

type ResponseType = {
    item: ISpaceModel
};

export const spacesSaveQuery = async function (request: ISpaceModel) {
    return await makeQuery<ResponseType>('/spaces/save', {
        prefix: 'task-tracker',
        json: request
    });
}
