import React from 'react';
import { observer } from 'mobx-react';

import { IspringCourseModel } from 'shared/models';

import './styles.scss';
import { UiDate, UiIcon, UiTag } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

interface Props {
    item: IspringCourseModel;
}

export const IspringCourseItem = observer(({ item }: Props) => {

    return (
        <a href={item.url} target='_blank' className='ispring-course-item' rel="noreferrer">
            <div
                className="ispring-course-item__image"
                style={item.previewImageUrl ? {backgroundImage: `url(${item.previewImageUrl})`} : {}}
            />
            <div className="ispring-course-item__header">
                <UiTag value={item.tag}/>
                {
                    !item.status.in(item.doneStatuses) && (
                        <UiDate value={item.dueDateAt}/>
                    )
                }
            </div>
            <div className="ispring-course-item__name">{item.name}</div>
            <div className="ispring-course-item__footer">
                {
                    item.status.in(item.doneStatuses) ? (
                        <div className="ispring-course-item-progress ispring-course-item-progress--done">
                            <div className="ispring-course-item-progress__inner">
                                <UiIcon size={16} icon={ICONS.COURSE_DONE} color={COLORS.GREEN}/>
                                <span>Курс пройден</span>
                            </div>
                            <div className="ispring-course-item-progress__bar"/>
                        </div>
                    ) : (
                        <div className="ispring-course-item__progress">
                            <div className="ispring-course-item-progress__inner">
                                <UiIcon size={16} icon={ICONS.COURSE} color={COLORS.BROWN_1}/>
                                <span>Пройдено на {item.progress}%</span>
                            </div>
                            <div className="ispring-course-item-progress__bar">
                                <span style={{width: `${item.progress}%`}}/>
                            </div>
                        </div>
                    )
                }
            </div>
        </a>
    );
});
