import React, { useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";
import { toJS } from "mobx";

import { CollectionItemIdType, CollectionItemType, OnChangeHandlerType } from 'shared/types';
import { useOnClickOutside, useStore } from "shared/hooks";
import { COLORS, ICONS } from "shared/constants";

import { UiScrollbar } from "../UiScrollbar";
import { UiIcon } from "../UiIcon";

import './styles.scss';

type PropsType = {
    placeholder?: string,
    isMultiple?: boolean,
    onChange?: OnChangeHandlerType<CollectionItemIdType | CollectionItemIdType[]>;
    value?: CollectionItemIdType | CollectionItemIdType[];
    items?: CollectionItemType[];
    name?: string;
}

export const UiSelectMultiple = observer((
    {
        onChange,
        isMultiple = false,
        value = null,
        name = '',
        placeholder = 'Список',
        items = [],
    }: PropsType
) => {
    const store = useStore(() => ({
        isOpened: false,
        search: ''
    }))

    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        store.set("isOpened", false);
    });

    const handleClick = (id: CollectionItemIdType) => {
        let newValue: CollectionItemIdType | CollectionItemIdType[] = toJS(value);
        if (isMultiple) {
            newValue = !Array.isArray(newValue) ? [] : newValue;
            if (newValue.indexOf(id) === -1) {
                newValue.push(id);
            } else {
                newValue = newValue.filter(val => val !== id);
                if (newValue.length === 0) {
                    newValue = null;
                }
            }
        } else {
            newValue = newValue === id ? null : id;
        }

        onChange && onChange({
            name,
            value: newValue
        })
    }

    const title = useMemo(() => {
        const jsValue = toJS(value);
        if (value === null) {
            return placeholder;
        }
        if (isMultiple && Array.isArray(jsValue)) {
            return (<>{placeholder} (<b>Выбрано: {jsValue.length}</b>)</>)
        }
        const valueObject = items?.find(item => item.id === value);
        if (valueObject) {
            return (<>{placeholder} (<b>Выбрано: {valueObject.name}</b>)</>)
        }

        return placeholder;
    }, [isMultiple, placeholder, value, items]);

    const isItemSelected = useCallback((id: CollectionItemIdType) => {
        let jsValue: CollectionItemIdType | CollectionItemIdType[] = toJS(value);
        if (isMultiple && Array.isArray(jsValue)) {
            return jsValue.indexOf(id) > -1;
        }
        return jsValue === id;
    }, [value, isMultiple]);

    const itemsFiltered = useMemo(() => {
        let result = items || [];
        result = result.slice();

        return result.slice().filter(item => item.name.toLowerCase().indexOf(store.search.toLowerCase()) > -1);
    }, [store.search, items])

    return (
        <div ref={ref} className={classnames('ui-select-multiple', {
            'ui-select-multiple--opened': store.isOpened
        })}>
            <div className="ui-select-multiple__filler"/>
            <div className="ui-select-multiple__inner">
                <div className="ui-select-multiple__header" onClick={() => store.toggle("isOpened")}>
                    <div className="ui-select-multiple__title">{title}</div>
                    <UiIcon
                        icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                        color={COLORS.GRAY_3}
                        size={13}
                    />
                </div>
                {store.isOpened && (
                    <div className="ui-select-multiple__body">
                        <div className="ui-select-multiple-search">
                            <div className="ui-select-multiple-search__icon">
                                <UiIcon
                                    icon={ICONS.SEARCH}
                                    color={COLORS.GRAY_3}
                                    size={16}
                                />
                            </div>
                            <input
                                placeholder='Поиск'
                                className='ui-select-multiple-search__control'
                                type="text"
                                value={store.search}
                                onChange={(e) => store.set("search", e.currentTarget.value)}
                            />
                        </div>
                        <div className="ui-select-multiple-items">
                            <UiScrollbar maxHeight={300}>
                                <div className="ui-select-multiple-items__inner">
                                    {itemsFiltered?.map(item => {
                                        const className = classnames('ui-select-multiple__item', {
                                            'ui-select-multiple__item--selected': isItemSelected(item.id)
                                        });
                                        return (
                                            <div
                                                key={item.id}
                                                className={className}
                                                onClick={() => handleClick(item.id)}
                                                title={item.name}
                                            >
                                                <span>{item.name}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </UiScrollbar>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
})
