import React from 'react';
import { observer } from 'mobx-react';
import 'react-circular-progressbar/dist/styles.css';

import './styles.scss';
import { UiButton, UiHtml } from "shared/uikit";
import { EducationLessonModel } from "shared/models";
import classnames from "classnames";

type PropsType = {
    educationLesson: EducationLessonModel,
    type?: string,
    onSelect: () => void
}
export const PEducationLesson = observer(({ educationLesson, onSelect, type = 'card' }: PropsType) => {
    const classNames = classnames('p-education-lesson', `p-education-lesson--${type}`);

    return (
        <div className={classNames}>
            <div className="p-education-lesson__header">
                <div className="p-education-lesson__tags">
                    {!!educationLesson.isOnline && (
                        <div className="p-education-lesson-tag">
                            <div className="p-education-lesson-tag__marker" style={{ background: '#0B5CFF' }}/>
                            <div className="p-education-lesson-tag__name">Онлайн</div>
                        </div>
                    )}
                    {!!educationLesson.isOffline && (
                        <div className="p-education-lesson-tag">
                            <div className="p-education-lesson-tag__marker" style={{ background: '#20BF6B' }}/>
                            <div className="p-education-lesson-tag__name">Очная</div>
                        </div>
                    )}
                    {!!educationLesson.isIspring && (
                        <div className="p-education-lesson-tag">
                            <div className="p-education-lesson-tag__marker" style={{ background: '#CF1259' }}/>
                            <div className="p-education-lesson-tag__name">ISpring</div>
                        </div>
                    )}
                    {educationLesson.tags.map(tag => (
                        <div key={tag.id} className="p-education-lesson-tag">
                            <div className="p-education-lesson-tag__name">{tag.name}</div>
                        </div>
                    ))}
                </div>
                <div
                    className="p-education-lesson__image"
                    style={{ backgroundImage: `url(${educationLesson.imageThumbnail})` }}
                />
            </div>
            <div className="p-education-lesson__name">{educationLesson.name}</div>
            <UiHtml
                className={'p-education-lesson__description typography typography--light'}
                value={educationLesson.descriptionPreview}
            />
            <div className="p-education-lesson__footer">
                <UiButton
                    onClick={onSelect}
                    label={'Записаться'}
                />
            </div>
        </div>
    );
});

