import { IChatMessageModel } from "shared/models";

import { BaseBroadcast } from "./BaseBroadcast";

type DataType = {
    item: IChatMessageModel
}

export class ChatMessageCreatedBroadcast extends BaseBroadcast {
    isPrivate = true;
    event = 'chat.message.created';
    callback: (data: DataType) => void;

    constructor(callback: (data: DataType) => void) {
        super();
        this.setCallback(callback);
    }
}
