import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";
import classnames from "classnames";
import { useLocation } from 'react-router-dom';

import { UiButton, UiCard, UiCardTitle, UiDataBoundary, UiIcon, UiNavLink } from 'shared/uikit';
import { useNavigate, useStore } from "shared/hooks";
import { EssentialModel } from "shared/models";
import { essentialsQuery } from "shared/queries";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import './styles.scss';

const icon = require('./assets/icon.svg');

export const WEssentials = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();

    const store = useStore(() => ({
        items: [] as EssentialModel[],
        isLoading: true,
        isSuccess: false,
        isOpened: false,
    }));

    const isEssentialPage = useMemo(() => {
        return location.pathname.includes('essentials');
    }, [location.pathname]);

    const fetchItems = useCallback(async () => {
        const response = await essentialsQuery({
            perPage: 5
        });
        if (response.isSuccess && response.data) {
            store.set('items', response.data.items.map(item => new EssentialModel(item)));
            store.set('isSuccess', true);
        } else {
            store.set("isSuccess", false);
        }
        store.set('isLoading', false);
    }, [store]);

    useEffect(() => {
        fetchItems()
    }, [fetchItems]);

    useEffect(() => {
        if (!isEssentialPage) {
            return;
        }
        setTimeout(() => {
            fetchItems();
        }, 500)
    }, [isEssentialPage, fetchItems]);

    if (!store.isLoading && store.items.length === 0) {
        return null;
    }

    return (
        <UiCard className='w-essentials'>
            <UiCardTitle
                label='Важная информация'
                className='w-essentials__title'
                icon={
                    <div className="w-essentials__icon" style={{ backgroundImage: `url(${icon})` }}/>
                }
            />
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="w-essentials__items">
                    {store.items.map(item => (
                        <UiNavLink
                            key={item.id}
                            className={classnames('w-essentials-item', {
                                'w-essentials-item--viewed': item.essentialViewsCount > 0
                            })}
                            to={ROUTES.ESSENTIAL(item.id)}
                        >
                            <div className="w-essentials-item__dot"/>
                            <div className="w-essentials-item__inner">
                                <div className="w-essentials-item__name">{item.name}</div>
                                <div className="w-essentials-item__date">
                                    {moment(item.createdAt).format('D MMMM YYYY')}
                                </div>
                            </div>
                        </UiNavLink>
                    ))}
                </div>
                <div className="w-essentials__more">
                    <UiButton
                        isLink
                        onClick={() => {
                            navigate(ROUTES.ESSENTIALS());
                        }}
                        iconAfter={<UiIcon size={11} icon={ICONS.ARROW_RIGHT} color={COLORS.BROWN_1}/>}
                        label={'Все объявления'}
                    />
                </div>
            </UiDataBoundary>
        </UiCard>
    );
});
