import { makeQuery } from 'shared/utilities';
import { IImportantInformationModel } from "shared/models";

type ResponseType = {
    items: IImportantInformationModel[]
};

export const importantInformationQuery = async function () {
    return await makeQuery<ResponseType>('/important-information');
}
