import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { booksQuery, tagsQuery } from "shared/queries";
import { useMedia, useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { BookModel, TagModel } from "shared/models";
import { UiDataBoundary, UiFilter, UiGrid, UiPagination, UiTabs, UiViewType } from "shared/uikit";
import { ApplicationModule } from "shared/modules";
import { ROUTES } from "shared/constants";
import { BookTypeEnum } from "shared/enums";

import { BookItemRow } from "./components/BookItemRow";
import { BookItemCard } from "./components/BookItemCard";

import './styles.scss';

export const BooksPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
        id: undefined as undefined | number,
        typeId: BookTypeEnum.Ebook as string,
        tagId: null as null | string,
    });

    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        pages: 1,
        viewType: 'card',
        books: [] as BookModel[],
        tags: [] as TagModel[],
    }));

    const { isMobile, value: columns } = useMedia({
        is320: 1,
        is1024: 3,
        is1440: 4,
        is2560: 6
    });

    useEffect(() => {
        ApplicationModule.setTitle('Книги');
        (async () => {
            const response = await tagsQuery({
                has: ['books']
            });
            if (response.isSuccess && response.data) {
                store.set('tags', response.data.items.map(item => new TagModel(item)));
            } else {
                store.set('isSuccess', false);
            }
        })();
    }, [store]);

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', true);
            const { data, isSuccess } = await booksQuery({
                ...urlParams,
            });
            if (isSuccess && data) {
                store.set('books', data.items.map(item => new BookModel(item)));
                store.set('pages', data.pages);
            } else {
                store.set('isSuccess', false);
            }
            store.set('isLoading', false);
        })();
    }, [store, urlParams]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Книги' backTo={ROUTES.LIBRARY()}>
                    <UiFilter
                        value={urlParams.tagId}
                        items={store.tags}
                        onChange={({ value }) => {
                            navigate(ROUTES.BOOKS(), { tagId: value }, { replace: true })
                        }}
                    />
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <div className="p-books__tabs">
                    <UiTabs
                        items={BookTypeEnum.items}
                        value={urlParams.typeId}
                        onChange={({ value }) => {
                            navigate(ROUTES.BOOKS(), {
                                ...urlParams,
                                typeId: value
                            }, { replace: true })
                        }}
                    />
                </div>
                <div className="p-books__description">
                    {urlParams.typeId === BookTypeEnum.Ebook && 'В данном разделе представлены электронные варианты книг, которые мы можете себе скачать'}
                    {urlParams.typeId === BookTypeEnum.Book && 'В этом разделе представлены книги, которые вы можете взять в живом исполнении в нашей корпоративной библиотеке. Библиотека с печатными изданиями располагается в центральном офисе в Красногорске, на 2 этаже, в интернет-магазине. За выдачу книг отвечает Горяинова Оксана, добавочный номер 1621.'}
                </div>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {(store.viewType === 'list' && !isMobile) &&
                        store.books.map(book => <BookItemRow key={book.id} book={book}/>)
                    }
                    {(store.viewType === 'card' || isMobile) && (
                        <UiGrid columns={columns} gap={[0, 40]}>
                            {store.books.map(book => <BookItemCard key={book.id} book={book}/>)}
                        </UiGrid>
                    )}
                    {store.books.length === 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h2 className='typography'>По вашему запросу ничего не найдено</h2>
                        </div>
                    )}
                </UiDataBoundary>
                <UiPagination
                    page={urlParams.page}
                    pages={store.pages}
                    onChange={(data) => {
                        navigate(ROUTES.BOOKS(), {
                            ...urlParams,
                            page: data.value
                        }, { replace: true })
                    }}
                />
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
});
