import { makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface ICommentMentionModel {
    id?: number;
    commentId?: number;
    userId?: number;
    userName?: string;
}

export class CommentMentionModel extends Model implements ICommentMentionModel {
    casts = {
    };

    id = 0;
    commentId = 0;
    userId = 0;
    userName = '';

    constructor(payload: ICommentMentionModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            commentId: observable,
            userId: observable,
            userName: observable,
        });

        this.update(payload);
    }
}
