import { observer } from 'mobx-react';
import { useMemo } from "react";

import { UserDashboardWidgetModel } from "shared/models";
import { currency, number } from "shared/utilities";

import './styles.scss';

type PropsType = {
    widget: UserDashboardWidgetModel
}

export const PPDWidgetGrid = observer(({ widget }: PropsType) => {
    const item = useMemo(() => {
        if (widget.items.length === 0) {
            return null;
        }

        return widget.items[0];
    }, [widget]);

    if (!item === null) {
        return null;
    }

    return (
        <div className={'ppd-widget ppd-widget--grid'}>
            <div className="ppd-widget__header">
                <div className="ppd-widget__name">{widget.name}</div>
            </div>
            <div className="ppd-widget-grid">
                {widget.items.map((item, index) => (
                    <div key={index} className={'ppd-widget-grid-cell'}>
                        <div className="ppd-widget-grid-cell__label">
                            <span>{item.valueHint}</span>
                        </div>
                        <div className="ppd-widget-grid-cell__value">{currency(number(item.value))}</div>
                    </div>
                ))}
            </div>
        </div>
    );
});
