import React from 'react';
import { observer } from 'mobx-react';

import { ITagModel } from 'shared/models';
import { OnChangeHandlerType } from 'shared/types';

import UiTag from '../UiTag';

import './styles.scss';

interface Props {
    items: ITagModel[];
    name: string;
    className: string;
    onClick?: OnChangeHandlerType<ITagModel>;
}

class UiTags extends React.Component<Props> {
    static defaultProps: Props = {
        items: [],
        name: 'tag_id',
        className: '',
    };

    render() {
        const { items, name, onClick, className } = this.props;
        if (items.length === 0) {
            return null;
        }
        return (
            <div className={`ui-tags ${className}`.trim()}>
                {items.map(item => (
                    <UiTag key={item.id} value={item} name={name} onClick={onClick} />
                ))}
            </div>
        );
    }
}

export default observer(UiTags);
