import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { useNavigate, useUrlParams } from "shared/hooks";

import { SpaceModel } from "shared/models/TaskTracker";

import './index.scss';

type PropsType = {
    spaces: SpaceModel[]
}

export const CFilterSpace = observer(({ spaces }: PropsType) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        fSpaceId: null
    });

    const handleSelect = (fSpaceId: number | null) => {
        navigate(null, {
            ...urlParams,
            fSpaceId
        });
    }

    if (spaces.length === 0) {
        return (
            <div className="c-tt-filter-space-empty">
                <div className="c-tt-filter-space-empty__title">
                    Подключенные пространства
                </div>
                <div className="c-tt-filter-space-empty__description">
                    Пока у вас нет подключенных пространств. Как только вас добавят в новое пространство, здесь отобразится его название.
                </div>
            </div>
        )
    }
    return (
        <div className="c-tt-filter-space">
            <div
                className={classnames("c-tt-filter-space__item", {
                    'c-tt-filter-space__item--active': null === urlParams.fSpaceId
                })}
                onClick={() => handleSelect(null)}
            >
                Все пространства
            </div>
            {spaces.map(space => (
                <div
                    key={space.id}
                    className={classnames("c-tt-filter-space__item", {
                        'c-tt-filter-space__item--active': space.id === urlParams.fSpaceId
                    })}
                    onClick={() => handleSelect(space.id)}
                >
                    {space.name}
                </div>
            ))}
        </div>
    )
});
