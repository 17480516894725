import React, { useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import classnames from "classnames";

import { ReservationModel } from "shared/models";
import { UiButton, UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { ApplicationModule } from "shared/modules";
import { useOnClickOutside } from "shared/hooks";

import './styles.scss';

type PropsType = {
    value: ReservationModel;
    onCancel?: (reservationId: number) => void,
    onEdit?: (reservationId: number) => void,
}

export const UiReservationPlaceReservation = observer(({ value: reservation, onCancel, onEdit }: PropsType) => {
    const store = useLocalObservable(() => ({
        isVisible: false,
        hide() {
            this.isVisible = false;
        },
        toggle() {
            this.isVisible = !this.isVisible;
        }
    }));
    const ref = useRef(null);

    const style = {
        height: reservation.intervals.length * 32,
    };

    const className = classnames('ui-reservation-place-reservation', {
        'ui-reservation-place-reservation--single': reservation.intervals.length === 1,
        'ui-reservation-place-reservation--current': reservation.user.id === ApplicationModule.user.id,
    });

    const handleCancel = () => {
        store.hide();
        onCancel && onCancel(reservation.id);
    }

    const handleEdit = () => {
        store.hide();
        onEdit && onEdit(reservation.id);
    }

    useOnClickOutside(ref, () => {
        setTimeout(store.hide, 200);
    });

    return (
        <div className={className} style={style} ref={ref}>
            <div className="ui-reservation-place-reservation__time">
                {reservation.time}
            </div>
            <div className="ui-reservation-place-reservation__user">
                {reservation.user.previewName}
            </div>
            <div className="ui-reservation-place-reservation__icon" onClick={store.toggle}>
                <UiIcon size={16} icon={ICONS.INFO} color={COLORS.GRAY_4}/>
            </div>
            {store.isVisible && (
                <div className="ui-reservation-place-reservation-info">
                    <div className="ui-reservation-place-reservation-info__close" onClick={store.hide}>
                        <UiIcon icon={ICONS.CLOSE} color={COLORS.BLACK} size={19}/>
                    </div>
                    <div className="ui-reservation-place-reservation-info__name">{reservation.name}</div>
                    <div className="ui-reservation-place-reservation-info__time">{reservation.time}</div>
                    <div className="ui-reservation-place-reservation-info__users">
                        {[reservation.user, ...reservation.users].map(user => (
                            <div key={user.id} className='ui-reservation-place-reservation-info__user'>{user.previewName}</div>
                        ))}
                    </div>
                    {reservation.description && (
                        <div className="ui-reservation-place-reservation-info__description">
                            {reservation.description}
                        </div>
                    )}
                    {reservation.user.id === ApplicationModule.user.id && (
                        <div className="ui-reservation-place-reservation-info__footer">
                            {onEdit && (
                                <UiButton
                                    isLink
                                    isSmall
                                    onClick={handleEdit}
                                    label='Редактировать'
                                />
                            )}
                            {onCancel && (
                                <UiButton
                                    isLink
                                    isSmall
                                    onClick={handleCancel}
                                    label='Отменить'
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
});
