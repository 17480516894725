import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { useNavigate, useUrlParams } from "shared/hooks";

import './index.scss';

const items = [
    { id: null, name: 'Все задачи' },
    { id: 'responsible', name: 'Делаю' },
    { id: 'user', name: 'Участвую' },
    { id: 'owner', name: 'Поручил' },
];

export const CFilterInvolving = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        fInvolving: null
    });

    return (
        <div className="c-tt-filter-involving">
            {items.map((item) => (
                <div
                    key={item.id}
                    className={classnames("c-tt-filter-involving__item", {
                        'c-tt-filter-involving__item--active': item.id === urlParams.fInvolving
                    })}
                    onClick={() => {
                        navigate(null, {
                            ...urlParams,
                            fInvolving: item.id
                        });
                    }}
                >
                    {item.name}
                </div>
            ))}
        </div>
    )
});

