import { makeQuery } from 'shared/utilities';
import { IUserModel } from "shared/models";

type RequestType = {
    login: string,
    password: string
};

type ResponseType = {
    item: IUserModel;
};

export const ispringConnectQuery = async function (data: RequestType) {
    return await makeQuery<ResponseType>('/ispring/connect', { json: data });
}
