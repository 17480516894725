import { createEnum } from './createEnum';

export const TaskTrackerTaskPriorityEnum = createEnum({
    Low: {
        id: 'low',
        name: 'Низкий приоритет',
        previewName: 'Низкий',
        color: '#45AAF2',
    },
    Normal: {
        id: 'normal',
        name: 'Обычный приоритет',
        previewName: 'Обычный',
        color: '#26DE81',
    },
    High: {
        id: 'high',
        name: 'Высокий приоритет',
        previewName: 'Высокий',
        color: '#FC5C65',
    },
} as const, {
    id: null,
    name: 'Без приоритета',
    previewName: 'Без приоритета',
    color: '#D4D7DD',
});

