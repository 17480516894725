import React from 'react';
import { observer } from 'mobx-react';

import { UserModel } from "shared/models";
import { UiUserBadge } from "shared/uikit";
import { useNavigate } from "shared/hooks";
import { ROUTES } from "shared/constants";

import icon from './assets/icon.svg';

import './styles.scss';

type PropsType = {
    user: UserModel
}
export const PProfileUnavailable = observer(({ user }: PropsType) => {
    const navigate = useNavigate();
    if (user.isAvailable) {
        return null;
    }

    return (
        <div className={'p-profile-unavailable'}>
            <div className="p-profile-unavailable__header">
                <div className="p-profile-unavailable__icon" style={{ backgroundImage: `url(${icon})` }}/>
                <div className="p-profile-unavailable__title">
                    {`Сотрудник отсутствует на рабочем месте с ${user.unavailableFromMoment.format('D MMMM')} до ${user.unavailableToMoment.format('D MMMM YYYY')}`}
                </div>
            </div>
            <div className="p-profile-unavailable-field">
                <div className="p-profile-unavailable-field__name">Причина отстсутствия:</div>
                <div className="p-profile-unavailable-field__value">{user.unavailableReason}</div>
            </div>
            {!!user.unavailableComment && (
                <div className="p-profile-unavailable-field">
                    <div className="p-profile-unavailable-field__name">Комментарий:</div>
                    <div className="p-profile-unavailable-field__value">{user.unavailableComment}</div>
                </div>
            )}
            {!!user.unavailableUser && (
                <div className="p-profile-unavailable-field">
                    <div className="p-profile-unavailable-field__name">Замещающие сотрудник:</div>
                    <UiUserBadge
                        value={user.unavailableUser}
                        onClick={() => {
                            if (user.unavailableUserId) {
                                navigate(ROUTES.USER(user.unavailableUserId))
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
});
