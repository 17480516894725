import React from 'react';
import { observer } from 'mobx-react';
import { ICONS } from 'shared/constants';

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    color?: string;
    address?: string;
    city?: string;
    style?: React.CSSProperties;
}

export const UiAddress = observer(({ city, color, address, style }: PropsType) => {
    if (!city && !color && !address) {
        return null;
    }

    return (
        <div className='ui-address' style={style}>
            {city ? `${city} ` : ''}
            {color && <UiIcon size={8} color={color} icon={ICONS.CIRCLE}/>}
            {address ? address : ''}
        </div>
    )
});
