export const API_ENDPOINTS = {
    USER_LOGIN: '/users/login/',
    USER_GET: '/users/get/',
    USER_UPDATE: '/users/update/',
    USER_VIEW_PENDING: '/user-views/pending',
    USER_VIEW_UPDATE: '/user-views/update',
    USER_BIRTHDAYS: '/users/birthdays',
    FEED: '/feed/',
    ANNOUNCEMENTS: '/announcements/',
    ANNOUNCEMENT_GET: '/announcements/get',
    ANNOUNCEMENT_WIDGET: '/announcements/widget',
    VACANCIES_GET: '/vacancies/get',
    VACANCIES_WIDGET: '/vacancies/other',
    TAGS: '/tags/',
    CAFE: '/cafe-meals/',
    CAFE_POPULAR: '/cafe-meals/popular/',
    CAFE_PROPOSAL_CREATE: '/cafe-proposals/create',
    LIKE_TOGGLE: '/likes/toggle/',
    FAVORITE_TOGGLE: '/favorites/toggle/',
    FAQ: '/faq',
    FAQ_CATEGORIES: '/faq-categories',
    ARTICLES: '/articles',
    ARTICLES_GET: '/articles/get',
    ARTICLES_WIDGET: '/articles/widget',
    NEWS_GET: '/news/get',
    NEWS_WIDGET: '/news/widget',
    REGULATIONS: '/regulations',
    REGULATION_CATEGORIES: '/regulation-categories',
    SALES_STATISTIC_TOP_SHOP: '/sales-statistics/top-shop',
    SALES_STATISTIC_TOP_SELLERS: '/sales-statistics/top-sellers',
    IMPROVEMENT_PROPOSAL_CREATE: '/improvement-proposals/create',
    IMPORTANT_INFORMATION: '/important-information',
};
