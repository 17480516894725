import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: number | null
};

type ResponseType = {};

export const tasksDeleteQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks/delete', {
        prefix: 'task-tracker',
        json: request
    });
}
