import React, { useCallback } from 'react';
import { NavLink, NavLinkProps } from "react-router-dom";
import { observer } from 'mobx-react';

type PropsType = NavLinkProps & React.RefAttributes<HTMLAnchorElement>

export const UiNavLink = observer(({ onClick, ...props }: PropsType) => {
    const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!e.currentTarget.contains(e.target as Node)) { //check its portal(modal/portal inside link)
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        window.scrollTo(0, 0)
        onClick && onClick(e);
    }, [ onClick ])
    return (
        <NavLink {...props} onClick={handleClick}/>
    );
})
