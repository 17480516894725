import { makeObservable, observable } from 'mobx';

import { VacancyModel, VacancyRegionModel } from 'shared/models';
import { StoreWithFetchItems } from 'shared/mixins/store';

class Store extends StoreWithFetchItems<VacancyModel> {
    vacancyRegions: VacancyRegionModel[] = [];

    constructor() {
        super();

        makeObservable(this, {
            vacancyRegions: observable,
        });
    }
}

export default new Store();
