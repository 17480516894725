import { action, makeObservable, runInAction } from 'mobx';
import { Api } from 'shared/utilities';
import { API_ENDPOINTS } from 'shared/constants';
import { CafeMealModel, ICafeMealModel } from 'shared/models';

import Store from './Store';
import { OnChangeHandlerType } from 'shared/types';

export default new (class Service {
    constructor() {
        makeObservable(this, {
            handleShow: action,
            handleClose: action,
            handleCancel: action,
            handleSubmit: action,
            handleChangeForm: action,
        });
    }

    getData = async () => {
        runInAction(() => {
            Store.isSuccess = true;
            Store.isLoading = true;
        });
        const response = await Api.get<{ items: ICafeMealModel[] }>(API_ENDPOINTS.CAFE_POPULAR);
        if (response.isSuccess && response.data) {
            runInAction(() => {
                Store.items = response.data.items.map(item => new CafeMealModel(item));
            });
        } else {
            runInAction(() => {
                Store.isSuccess = false;
            });
        }
        runInAction(() => {
            Store.isLoading = false;
        });
    };

    handleChangeForm: OnChangeHandlerType = data => {
        Store.form.set(data.name, data.value);
    };

    handleShow = () => {
        Store.modal.countdown = false;
        Store.modal.form = true;
    };

    handleClose = () => {
        Store.modal.countdown = false;
        Store.modal.form = false;
    };

    handleCancel = () => {
        Store.modal.countdown = false;
        Store.modal.form = true;
    };

    handleSubmit = () => {
        if (!Store.form.validate()) {
            return;
        }

        Store.modal.countdown = true;
        Store.modal.form = false;
    };

    handleConfirm = async () => {
        await Api.post(API_ENDPOINTS.CAFE_PROPOSAL_CREATE, Store.form.values);
        Store.form.reset();

        runInAction(() => {
            Store.modal.countdown = false;
            Store.modal.form = false;
        });
    };
})();
