import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { BoardModel, ColumnModel } from "shared/models/TaskTracker";
import { UiButton, UiForm, UiFormControl, UiInput, UiModal, UiModalTitle } from "shared/uikit";
import { useNavigate, useStore, useUrlParams, useValidation } from "shared/hooks";
import { boardsGetQuery, boardsSaveQuery } from "shared/queries/TaskTracker";
import { OnChangeHandlerType } from "shared/types";

import './index.scss';

type PropsType = {
    id: string | number | null,
    spaceId: string | number | null,
    onSaved: (board: BoardModel, columns: ColumnModel[]) => void
}

export const CBoardSave = observer(({ id, spaceId, onSaved }: PropsType) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({});
    const store = useStore(() => ({
        isOpened: false,
        isLoading: true,
        isSubmitted: false,
        isSubmitting: false,
        board: new BoardModel()
    }));

    useEffect(() => {
        if (!id) {
            return;
        }
        store.set("isLoading", true);

        if (id === 'new') {
            store.set("board", new BoardModel({
                spaceId: spaceId ? +spaceId : 0
            }));
            store.set("isLoading", false);
            return;
        }

        (async () => {
            const { isSuccess, data } = await boardsGetQuery({ id: +id as number });
            if (isSuccess && data) {
                store.set('board', new BoardModel(data.item));
            }
            store.set("isLoading", false);
        })();
    }, [store, id, spaceId]);

    const validation = useValidation(store.board, (rules) => ({
        name: rules.required(),
    }));

    const handleClose = () => {
        if (store.isSubmitting) {
            return;
        }
        navigate(null, {
            ...urlParams,
            saveBoardId: null,
            boardId: null
        });

        setTimeout(() => {
            store.set('board', new BoardModel());
            store.set('isSubmitted', false);
        }, 1000);
    }

    const handleSubmit = async () => {
        store.set("isSubmitted", true);

        if (store.isSubmitting || !validation.isValid) {
            return;
        }

        store.set("isSubmitting", true);

        const { isSuccess, data } = await boardsSaveQuery(store.board);
        if (isSuccess && data) {
            onSaved(new BoardModel(data.item), data.columns.map(column => new ColumnModel(column)))
        }
        store.set("isSubmitting", false);
        handleClose();
    }

    const handleChange: OnChangeHandlerType<any> = (data) => {
        store.board[data.name] = data.value;
    }

    return (
        <UiModal
            styleBody={{ width: 640 }}
            isOpened={id !== null && !store.isLoading}
            onClose={handleClose}
            isPortal={false}
        >
            <UiModalTitle
                title={id === 'new' ? 'Новая доска' : (id === null ? '' : 'Редактирование доски')}
            />
            <UiForm onSubmit={handleSubmit}>
                <UiFormControl
                    label={'Название'}
                    isSubmitted={store.isSubmitted}
                    errorMessage={validation.name.errorMessage}
                    isRequired
                >
                    <UiInput
                        placeholder={'Введите название доски'}
                        name={'name'}
                        value={store.board.name}
                        onChange={handleChange}
                    />
                </UiFormControl>
                <div className={'ui-modal__actions'}>
                    <UiButton
                        isOutline
                        onClick={handleClose}
                        label={'Отмена'}
                    />
                    <UiButton
                        isLoading={store.isSubmitting}
                        isSubmit
                        label={'Сохранить'}
                    />
                </div>
            </UiForm>
        </UiModal>
    )
});

