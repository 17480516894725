import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiInput, UiButton, UiFormControl, UiForm } from 'shared/uikit';
import { ApplicationModule } from 'shared/modules';
import { useNavigate, useValidation, useUrlParams, useStore } from "shared/hooks";
import { ROUTES } from "shared/constants";
import { usersLoginQuery } from "shared/queries";

import './styles.scss';

export const LoginPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        url: ROUTES.HOME()
    });
    const form = useStore(() => ({
        login: '',
        password: ''
    }))
    const store = useStore(() => ({
        isLoading: false,
        isSuccess: false,
        isSubmitted: false,
        errorMessage: '',
    }));

    const validation = useValidation(form, (rules) => ({
        login: rules.email(),
        password: rules.required()
    }))

    const handleSubmit = async () => {
        store.set("isSubmitted", true);
        if (!validation.isValid) {
            return;
        }
        store.set("isLoading", true);

        const { isSuccess, data, errorMessage } = await usersLoginQuery(form);
        store.set('errorMessage', errorMessage || '');
        store.set("isSuccess", isSuccess && data);
        if (isSuccess && data) {
            ApplicationModule.user.update(data.item);
            await ApplicationModule.setAccessToken(data.accessToken);
            navigate(urlParams.url);
            window.history.replaceState({
                ...window.history.state,
                idx: 0
            }, '', urlParams.url);
        }
        store.set("isLoading", false);
    }

    useEffect(() => {
        ApplicationModule.setTitle('Авторизация');
    });

    useEffect(() => {
        if (ApplicationModule.isAuthorized) {
            navigate(urlParams.url);
        }
    }, [navigate, urlParams.url]);

    return (
        <div className='page-login'>
            <div className='page-login-header'>
                <div className='page-login-header__logo'/>
                <div className='page-login-header__title'>Корпоративный портал</div>
            </div>
            <UiForm onSubmit={handleSubmit} className='page-login-form'>
                <div className='page-login-form__title'>Вход</div>
                <div className='page-login-form__subtitle'>Введите ваши логин и пароль</div>
                <UiFormControl
                    errorMessage={store.isSubmitted && validation.login.errorMessage}
                    isError={!!validation.login}
                    style={{ marginBottom: 30 }}
                >
                    <UiInput
                        placeholder='Ваш логин *'
                        isPlaceholderFluid
                        onChange={({ value }) => form.set("login", value)}
                        value={form.login}
                        isError={store.isSubmitted && !validation.login.isValid}
                    />
                </UiFormControl>
                <UiFormControl
                    errorMessage={store.isSubmitted && validation.password.errorMessage}
                    isError={!!validation.password}
                    style={{ marginBottom: 30 }}
                >
                    <UiInput
                        placeholder='Ваш пароль *'
                        isPlaceholderFluid
                        type={UiInput.TYPE.PASSWORD}
                        onChange={({ value }) => form.set("password", value)}
                        value={form.password}
                        isError={store.isSubmitted && !validation.password.isValid}
                    />
                </UiFormControl>
                <UiFormControl
                    errorMessage={store.errorMessage}
                    isError={!store.isSuccess}
                    style={{ marginBottom: 30 }}
                    center
                >
                    <UiButton style={{ width: '100%' }} isSubmit label='Войти' isLoading={store.isLoading}/>
                </UiFormControl>
            </UiForm>
            <div className='page-login__copyright'>© 2022 Togas House of Textiles. All Rights Reserved.</div>
        </div>
    );
})
