import { INotificationModel } from "shared/models";
import { BaseBroadcast } from "shared/broadcasts/BaseBroadcast";

type DataType = {
    notification: INotificationModel
}

export class NotificationCreatedBroadcast extends BaseBroadcast {
    isPrivate = true;
    event = 'notification.created';
    callback: (data: DataType) => void;

    constructor(callback: (data: DataType) => void) {
        super();
        this.setCallback(callback);
    }
}
