import React from 'react';
import { observer } from 'mobx-react';

import {
    UiModal,
    UiTabs,
    UiFormControl,
    UiInput,
    UiUsersSearch,
    UiForm,
    UiButton,
    UiTmpFileUpload
} from "shared/uikit";
import { ChatRoomTypeEnum } from "shared/enums";
import { useNavigate, useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { chatRoomsCreateQuery } from "shared/queries";
import { ROUTES } from "shared/constants";

import './styles.scss';

type PropsType = {
    isOpened?: boolean
    onClose?: () => void
}

const chatTypes = [
    { id: ChatRoomTypeEnum.Direct, name: 'Личный' },
    { id: ChatRoomTypeEnum.Group, name: 'Групповой' },
];

export const CmChatRoomCreate = observer((
    {
        isOpened = false,
        onClose = () => {
        }
    }: PropsType
) => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        isSubmitted: false,
        isSubmitting: false,
        typeId: ChatRoomTypeEnum.Direct,
        name: '',
        fileId: null as number | null,
        users: [] as UserModel[]
    }));

    const handleChangeType = ({ value }: { value: string }) => {
        store.set('typeId', value);
        store.set('users', []);
    }

    const handleChangeUsers = ({ value }: { value: UserModel[] }) => {
        if (store.typeId === ChatRoomTypeEnum.Direct) {
            store.set('users', value.slice(-1));
        } else {
            store.set('users', value);
        }
    }

    const handleSubmit = async () => {
        store.set("isSubmitted", true);

        if (store.isSubmitting) {
            return;
        }
        if (!store.name && store.typeId === ChatRoomTypeEnum.Group) {
            return;
        }
        if (store.users.length === 0) {
            return;
        }
        store.set("isSubmitting", true);
        const response = await chatRoomsCreateQuery({
            typeId: store.typeId,
            image: store.typeId === ChatRoomTypeEnum.Group ? store.fileId : null,
            name: store.name,
            userId: store.typeId === ChatRoomTypeEnum.Direct
                ? store.users.map(({ id }) => id)[0]
                : store.users.map(({ id }) => id)
        });

        if (response.data) {
            navigate(ROUTES.CHAT(response.data.item.id))
        }
        onClose();
        store.set("isSubmitting", false);
    }

    return (
        <UiModal
            isOpened={isOpened}
            title='Новый диалог'
            onClose={onClose}
        >
            <UiTabs
                items={chatTypes}
                onChange={handleChangeType}
                value={store.typeId}
                style={{ marginBottom: 16 }}
            />
            <UiForm onSubmit={handleSubmit}>
                {store.typeId === ChatRoomTypeEnum.Group && (
                    <>
                        <UiFormControl
                            style={{ marginBottom: 16 }}
                            isError={store.isSubmitted && !store.name}
                            label='Название'
                        >
                            <UiInput
                                isError={store.isSubmitted && !store.name}
                                placeholder='Введите название'
                                onChange={({ value }) => store.set('name', value)}
                                value={store.name}
                            />
                        </UiFormControl>
                        <UiFormControl
                            style={{ marginBottom: 16 }}
                            isError={store.isSubmitted && !store.name}
                            label={'Картинка для диалога'}
                        >
                            <UiTmpFileUpload
                                type={'image'}
                                onChange={({ value }) => store.set('fileId', value)}
                                value={store.fileId}
                            />
                        </UiFormControl>
                    </>

                )}
                <UiFormControl
                    style={{ marginBottom: 16 }}
                    isSubmitted={store.isSubmitted}
                    errorMessage={store.typeId === ChatRoomTypeEnum.Direct ? 'Выберите пользователя' : 'Выберите пользователей'}
                >
                    <UiUsersSearch
                        limit={100}
                        label={store.typeId === ChatRoomTypeEnum.Direct ? '' : 'Участники'}
                        onChange={handleChangeUsers}
                        value={store.users}
                    />
                </UiFormControl>
                <div className="ui-modal__actions">
                    <UiButton
                        isLoading={store.isSubmitting}
                        isSubmit
                        label={store.typeId === ChatRoomTypeEnum.Direct ? 'Написать' : 'Создать'}
                    />
                    <UiButton
                        label='Отмена'
                        onClick={onClose}
                        isOutline
                    />
                </div>
            </UiForm>
        </UiModal>
    )
});
