import React from 'react';
import ReactDOM from "react-dom/client";
import { configure } from 'mobx';
import 'moment/locale/ru';
import 'drag-drop-touch';

import { App } from './App';

import reportWebVitals from './reportWebVitals';

configure({
    useProxies: 'ifavailable',
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

reportWebVitals();
