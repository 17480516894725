import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { OnChangeHandlerType } from 'shared/types';

import './styles.scss';

interface Props {
    label?: string;
    value: number | boolean;
    name?: string;
    labelWidth?: number | string;
    onChange?: OnChangeHandlerType<number>;
    style?: React.CSSProperties;
}

export const UiCheckboxSwitch = observer((
    {
        label,
        value = 0,
        labelWidth = 'auto',
        name = 'ui-checkbox-switch',
        onChange,
        style = {},
    }: Props
) => {
    const handleClick = () => {
        onChange && onChange({
            control: 'ui-checkbox-switch',
            name,
            value: value ? 0 : 1,
        });
    };

    const className = classnames('ui-checkbox-switch', {
        'ui-checkbox-switch--checked': value
    });

    return (
        <div className={className} onClick={handleClick} style={style}>
            {label && <div className="ui-checkbox-switch__label" style={{width: labelWidth}}>{label}</div>}
            <div className="ui-checkbox-switch__control">
                <span></span>
            </div>
        </div>
    );
});
