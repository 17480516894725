import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, makeObservable, observable } from 'mobx';
import classnames from 'classnames';

import { UiIcon } from 'shared/uikit';
import { COLORS, ICONS } from 'shared/constants';
import { FaqModel } from 'shared/models';

import './styles.scss';

interface Props {
    item: FaqModel;
}

class FaqItem extends React.Component<Props> {
    isActive = false;

    constructor(props: Props) {
        super(props);
        makeObservable(this, {
            isActive: observable,
            className: computed,
            handleToggle: action,
        });
    }

    get className() {
        return classnames('faq-item', {
            'faq-item--active': this.isActive,
        });
    }

    handleToggle = () => {
        this.isActive = !this.isActive;
    };

    render() {
        const { item } = this.props;

        return (
            <div className={this.className} onClick={this.handleToggle}>
                <div className='faq-item__question'>
                    {item.question}
                    <div className='faq-item__icon'>
                        <UiIcon
                            size={16}
                            icon={this.isActive ? ICONS.MINUS : ICONS.PLUS}
                            color={this.isActive ? COLORS.WHITE : COLORS.BLACK}
                        />
                    </div>
                </div>
                <div className='faq-item__answer'>{item.answer}</div>
            </div>
        );
    }
}

export default observer(FaqItem);
