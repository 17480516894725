import { makeQuery } from 'shared/utilities';
import { IReservationModel } from 'shared/models';

type ResponseType = {
    item: IReservationModel;
};

export const reservationsGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/reservations/get', { params: {id} });
}
