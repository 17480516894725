import { makeQuery } from 'shared/utilities';
import { IRequestCategoryModel } from 'shared/models';

type ResponseType = {
    items: IRequestCategoryModel[];
};

export const requestCategoriesQuery = async function () {
    return await makeQuery<ResponseType>('/request-categories');
}
