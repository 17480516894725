import classnames from "classnames";
import lodash from "lodash";
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { ICONS } from "shared/constants";
import { useDebounce, useOnClickOutside, useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { usersQuery } from "shared/queries";

import { OnChangeHandlerType } from "shared/types";
import { UiAvatar, UiDataBoundary, UiIcon, UiScrollbar, UiUserBadge } from "shared/uikit";
import { isArray } from "shared/utilities";

import './styles.scss';

type ValueType = number[] | number | null;

type PropsType = {
    onChange?: OnChangeHandlerType<ValueType>;
    value?: ValueType;
    items?: UserModel[];
    placeholder?: string;
    isMultiple?: boolean,
    name?: string;
    style?: React.CSSProperties;
}

export const UiUserSelect = observer((
    {
        value,
        items,
        placeholder = 'Выберите пользователя',
        isMultiple,
        name = 'user_id',
        style,
        onChange = () => {
        }
    }: PropsType
) => {
    const store = useStore(() => ({
        query: '',
        isSearching: true,
        isFocused: false,
        isOpened: false,
        isLoading: true,
        items: items ? items : [],
        foundUsers: [] as UserModel[],
        selectedUsers: [] as UserModel[]
    }));

    const ref = useRef<HTMLDivElement>(null);
    const refInput = useRef<HTMLInputElement>(null);

    useOnClickOutside(ref, () => {
        store.set("isOpened", false);
    });

    useEffect(() => {
        (async () => {
            if (store.isOpened) {
                return;
            }
            if (!value) {
                store.set("selectedUsers", []);
                return;
            }
            if (isArray(value) && (value as number[]).length === 0) {
                store.set("selectedUsers", []);
                return;
            }

            store.set('isLoading', false);
            const { isSuccess, data } = await usersQuery({
                id: value
            });
            if (isSuccess && data) {
                store.set('selectedUsers', data.items.map((item) => new UserModel(item)));
            }
            store.set('isLoading', true);
        })();
    }, [store, value]);

    const searchUsers = useDebounce(async (query?: string | null) => {
        const { isSuccess, data } = await usersQuery({
            limit: 10,
            query: query || ''
        });
        if (isSuccess && data) {
            store.set('foundUsers', data.items.map((item) => new UserModel(item)));
        }
        store.set('isSearching', false);
    }, 1000);

    useEffect(() => {
        if (!!items && items.length > 0) {
            store.set('foundUsers', items.filter(item => {
                if (!store.query) {
                    return true;
                }
                return item.previewName.toLocaleLowerCase().includes(store.query.toLocaleLowerCase());
            }));
        } else {
            store.set('isSearching', !!store.query);
            if (!store.query) {
                return;
            }
            searchUsers(store.query);
        }
    }, [store, searchUsers, store.query, items]);

    const handleChange = (user: UserModel) => {
        if (isMultiple) {
            onChange({
                name,
                value: isArray(value)
                    ? lodash.uniq([...(value as number[]), user.id])
                    : [user.id]
            });
            store.set('selectedUsers', lodash.uniqBy([...store.selectedUsers, user], 'id'));
        } else {
            onChange({
                name,
                value: user.id
            });
            store.set('selectedUsers', [user])
        }
        store.set("query", '');
        store.set("foundUsers", []);
        store.set("isOpened", false);
    }

    const handleDelete = (user: UserModel) => {
        if (isMultiple) {
            onChange({
                name,
                value: isArray(value) ? (value as number[]).filter(v => +v !== +user.id) : []
            });
            store.set('selectedUsers', store.selectedUsers.filter(u => +u.id !== +user.id))
        } else {
            onChange({
                name,
                value: null
            });
            store.set('selectedUsers', [])
        }
    }

    const handleOpen = () => {
        store.set('isOpened', true);

        setTimeout(() => {
            refInput?.current?.focus();
        }, 10)
    }

    const classNames = classnames('ui-user-select', {
        'ui-user-select--opened': store.isOpened,
        'ui-user-select--selected': store.selectedUsers.length > 0 || store.isSearching
    });

    return (
        <div className={classNames} style={style} ref={ref}>
            <div className="ui-user-select__control" onClick={handleOpen}>
                {(store.selectedUsers.length === 0 && !store.isOpened) && (
                    <div className="ui-user-select__placeholder">
                        <UiIcon icon={ICONS.USERS_ADD} size={20} color={'#b3b3b3'}/>
                        <span>{placeholder}</span>
                    </div>
                )}
                {(store.selectedUsers.length > 0 || store.isOpened) && (
                    <div className="ui-user-select__users">
                        {store.selectedUsers.map(user => (
                            <UiUserBadge
                                isSmall
                                key={user.id}
                                value={user}
                                onDelete={() => handleDelete(user)}
                            />
                        ))}
                        <input
                            ref={refInput}
                            className='ui-user-select__query'
                            type="text"
                            placeholder={'Поиск по ФИО'}
                            value={store.query}
                            onChange={(e) => {
                                store.set('query', e.target.value || '');
                            }}
                        />
                    </div>
                )}
            </div>
            {store.isOpened && (
                <div className="ui-user-select__items">
                    <UiDataBoundary isLoading={store.isSearching}>
                        <UiScrollbar maxHeight={200}>
                            {store.foundUsers.map(user => (
                                <div
                                    key={user.id}
                                    className="ui-user-select-item"
                                    onClick={() => handleChange(user)}
                                >
                                    <UiAvatar size={24} label={user.previewName} image={user.imageAvatar}/>
                                    <span>{user.name}</span>
                                </div>
                            ))}
                        </UiScrollbar>
                    </UiDataBoundary>
                </div>
            )}
        </div>
    )
});
