import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ROUTES } from "shared/constants";
import { useMedia, useNavigate, useStore } from "shared/hooks";
import { EducationLessonModel } from "shared/models";
import { educationLessonsClosestQuery } from "shared/queries";

import { UiButton, UiDataBoundary, UiSlider } from "shared/uikit";

import './styles.scss';

export const PEducationEvents = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        isLoading: true,
        educationLessons: [] as EducationLessonModel[]
    }))

    useEffect(() => {
        (async () => {
            const { data, isSuccess } = await educationLessonsClosestQuery();
            if (data && isSuccess) {
                store.set("educationLessons", data.items.map((item) => new EducationLessonModel(item)));
            }
            store.set("isLoading", false);
        })();
    }, [store])

    const { value } = useMedia({
        is320: 1,
        is768: 2,
        is1024: 3,
        is1920: 4
    })

    if (!store.isLoading && store.educationLessons.length < 1) {
        return null;
    }
    return (
        <div className="p-education-section p-education-events">
            <div className="p-education-section__title">Ближайшие события</div>
            <div className="p-education-section__inner">
                <UiDataBoundary isLoading={store.isLoading}>
                    <UiSlider
                        key={value}
                        items={store.educationLessons}
                        perPage={value}
                        gap={24}
                        slide={(educationLesson: EducationLessonModel) => (
                            <div key={educationLesson.id} className="p-education-event">
                                <div
                                    className="p-education-event__image"
                                    style={{ backgroundImage: `url(${educationLesson.imageThumbnail})` }}
                                />
                                <div className="p-education-event__date">
                                    {
                                        educationLesson.eventAt
                                            ? educationLesson.eventAtMoment.format('DD.MM.YYYY')
                                            : (<>&nbsp;</>)
                                    }
                                </div>
                                <div className="p-education-event__name">
                                    {educationLesson.name}
                                </div>
                                <div className="p-education-event__button">
                                    <UiButton
                                        label={'Записаться'}
                                        isOutline
                                        onClick={() => {
                                            navigate(ROUTES.EDUCATION(), {
                                                educationLessonId: educationLesson.id
                                            }, {}, null);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    />
                </UiDataBoundary>
            </div>
        </div>
    );
});

