import React from 'react';
import { observer } from 'mobx-react';

import { NewsModel } from 'shared/models';
import { ROUTES } from 'shared/constants';
import { ApplicationModule } from "shared/modules";
import { useNavigate } from "shared/hooks";
import { UiNavLink } from "shared/uikit";

import './styles.scss';

type PropsType = {
    items: NewsModel[];
}

export const HomeBanner = observer(({ items }: PropsType) => {
    const navigate = useNavigate();

    const getBackgroundImage = (src: string | null = null) => {
        if (!src) {
            return '';
        }
        return `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 10%, rgba(0, 0, 0, 0) 30.53%), linear-gradient(0deg, rgba(0, 0, 0, 0.5) 26.32%, rgba(0, 0, 0, 0) 100%), url(${src})`;
    }

    const handleClick = (tagId: number | string) => (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(ROUTES.HOME(), { tagId });
    }

    return (
        <div className="home-banner">
            <div
                className="home-banner__inner"
                style={{ backgroundImage: getBackgroundImage(ApplicationModule.applicationSettings.homeBannerImage) }}
            >
                <div className="home-banner__title">Главные новости</div>
                <div className="home-banner__items">
                    {items.map(item => (
                        <UiNavLink key={item.id} to={ROUTES.NEWS(item.id)} className="home-banner-item">
                            <div className="home-banner-item__tags">
                                {item.tags.length > 0 ? item.tags.slice(0, 1).map(tag => (
                                    <div key={tag.id} className="home-banner-item__tag" onClick={handleClick(tag.id)}>
                                        #{tag.name}
                                    </div>
                                )) : (
                                    <div className="home-banner-item__tag">#Новости компании</div>
                                )}
                            </div>
                            <div className="home-banner-item__name">{item.name}</div>
                        </UiNavLink>
                    ))}
                </div>
            </div>
        </div>
    )
});
