import { JSX } from "@cypress/react";
import classnames from "classnames";
import { observer } from 'mobx-react';
import React, { CSSProperties, ReactNode } from 'react';

import { ICONS } from "shared/constants";
import { UiIcon } from "../UiIcon";

import './styles.scss';

interface Props {
    title?: string | JSX.Element | React.ReactNode;
    children?: ReactNode;
    style?: CSSProperties;
    content?: ReactNode;
    subtitle?: string;
    before?: string | JSX.Element | React.ReactNode
}

export const UiModalTitle = observer(({ title, before, subtitle, content, children, style }: Props) => {
    return (
        <div
            className={classnames('ui-modal-title', {
                'ui-modal-title--empty': !title
            })}
            style={style}
        >
            {!!before && (<>{before}</>)}
            <div className="ui-modal-inner">
                {title && (
                    <div className="ui-modal-title__title">{title}</div>
                )}
                {subtitle && (
                    <div className="ui-modal-title__subtitle">{subtitle}</div>
                )}
            </div>
            {content}
            {children && (
                <div className='ui-modal-title__content'>{children}</div>
            )}
        </div>
    )
});
