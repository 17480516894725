import React from 'react';
import { observer } from 'mobx-react';

import { DocumentModel } from 'shared/models';

import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";
import { Helpers, Storage } from "shared/utilities";

import './styles.scss';

type PropsType = {
    item: DocumentModel;
    viewType: string
}

export const UiDocument = observer(({ item, viewType }: PropsType) => {
    const handleClick = async () => {
        let recent = await Storage.get<number[]>('documents.recent', []);
        if (recent.indexOf(item.id) === -1) {
            recent = [item.id, ...recent];
            recent = recent.slice(0, 20);
        }
        await Storage.set('documents.recent', recent);
    }
    return (
        <a
            download
            href={item.fileUrl}
            target='_blank'
            className={`ui-document ui-document--${viewType}`}
            rel='noreferrer'
            onClick={handleClick}
        >
            <div className='ui-document__info'>
                <div className="ui-document__icon">
                    <UiIcon
                        style={{ justifyContent: 'flex-start' }}
                        size={viewType === 'card' ? 73 : 40}
                        icon={ICONS.FILE}
                        iconProps={{
                            colors: [item.fileColors.primary, item.fileColors.secondary],
                            text: item.fileExtention,
                        }}
                    />
                </div>
                <div className='ui-document__size'>({Helpers.getReadableFileSize(item.fileSize)})</div>
            </div>
            <div className="ui-document__body">
                <div className='ui-document__name'>{item.name}</div>
                <div className='ui-document__date'>{item.updatedAtMoment.format('DD MMMM Y')}</div>
            </div>
        </a>
    )
})
