import { action, makeObservable, observable } from 'mobx';

import { TagModel } from 'shared/models';
import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';
import { IspringCourseStatusIdEnum } from "shared/enums";
import { ThumbnailType } from "shared/types";

export interface IBookModel {
    id?: number;
    name?: string;
    author?: string;
    description?: string;
    file?: string;
    image?: string;
    typeId?: string;
    liked?: number;
    likesCount?: number;
    commentsCount?: number;
    imageThumbnail?: ThumbnailType,
    tags?: TagModel[];
}

export class BookModel extends Model implements IBookModel {
    casts = {
        tags: new ModelArrayCast(TagModel),
    };

    id = 0;
    createdAt = '';
    name = '';
    author = '';
    description = '';
    file = '';
    image = '';
    typeId = '';
    liked = 0;
    likesCount = 0;
    commentsCount = 0;
    imageThumbnail: ThumbnailType = {
        webp: null,
        origin: null
    }
    tags: TagModel[] = [];

    constructor(payload: IBookModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            name: observable,
            author: observable,
            description: observable,
            file: observable,
            image: observable,
            imageThumbnail: observable,
            typeId: observable,
            liked: observable,
            likesCount: observable,
            commentsCount: observable,
            tags: observable,
            update: action,
        });

        this.update(payload);
    }

    get type() {
        return IspringCourseStatusIdEnum.fromValue(this.statusId);
    }
}
