import React from 'react';
import { observer } from 'mobx-react';
import { Pie } from 'react-chartjs-2';
import hexToRgba from 'hex-to-rgba';

import './styles.scss';
import classnames from 'classnames';
import { runInAction } from 'mobx';

interface DatasetItem {
    value: number;
    name: string;
    isActive: boolean;
}

interface Props {
    dataset: DatasetItem[];
    label: string;
}

class ChartPie extends React.Component<Props> {
    static defaultProps: Props = {
        dataset: [],
        label: 'piechart',
    };
    _colors = ['#ff6384', '#36a2eb', '#ffce56', '#4bc0c0', '#9966ff', '#ff9f40', '#00bcd4', '#009688'];

    get dataset() {
        const { dataset } = this.props;

        return dataset.slice().sort((a, b) => b.value - a.value);
    }

    get percents() {
        const total = this.dataset.map(item => item.value).reduce((prev, current) => prev + current, 0);
        return this.dataset.map(item => ((item.value * 100) / total).toFixed(2));
    }

    get values() {
        const total = this.dataset.map(item => item.value).reduce((prev, current) => prev + current, 0);
        return this.dataset.filter(item => item.isActive).map(item => ((item.value * 100) / total).toFixed(2));
    }

    get colors() {
        const colors: string[] = [];
        this.dataset.forEach((item, index) => {
            if (item.isActive) {
                colors.push(this._colors[index]);
            }
        });

        return colors;
    }

    get backgroundColorAll() {
        return this._colors.map(color => hexToRgba(color, 0.3));
    }

    get borderColorAll() {
        return this._colors.map(color => hexToRgba(color));
    }

    get backgroundColor() {
        return this.colors.map(color => hexToRgba(color, 0.3));
    }

    get borderColor() {
        return this.colors.map(color => hexToRgba(color));
    }

    get data() {
        return {
            datasets: [
                {
                    data: this.values,
                    backgroundColor: this.backgroundColor,
                    borderColor: this.borderColor,
                    borderWidth: 1,
                },
            ],
        };
    }

    handleToggle = (item: DatasetItem) => () => {
        runInAction(() => {
            item.isActive = !item.isActive;
        });
    };

    getItemClassName = (isActive: boolean) => {
        return classnames('chart-pie-item', {
            'chart-pie-item--disabled': !isActive,
        });
    };

    render() {
        const { label } = this.props;

        return (
            <div className='chart-pie'>
                <div className='chart-pie__label'>{label}</div>
                <div className='chart-pie__body'>
                    <div className='chart-pie__chart'>
                        <Pie data={this.data} options={{ animation: { duration: 0 } }} />
                    </div>
                    <div className='chart-pie-info'>
                        <div className='chart-pie-info__label'>Результаты опроса</div>
                        <div className='chart-pie-items'>
                            {this.dataset.map((item, index) => (
                                <div
                                    key={item.name + item.value}
                                    className={this.getItemClassName(item.isActive)}
                                    onClick={this.handleToggle(item)}
                                >
                                    <div
                                        className='chart-pie-item__icon'
                                        style={{
                                            backgroundColor: this.backgroundColorAll[index],
                                            borderColor: this.borderColorAll[index],
                                        }}
                                    />
                                    <div className='chart-pie-item__name'>{`${this.percents[index]}% - ${item.name}`}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default observer(ChartPie);
