import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IReservationIntervalModel {
    id?: number;
    time?: string;
    key?: string;
    sort?: number;
}

export class ReservationIntervalModel extends Model implements IReservationIntervalModel {
    id = 0;
    time = '';
    key = '';
    sort = 0;

    constructor(payload: IReservationIntervalModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            time: observable,
            key: observable,
            sort: observable,
            update: action,
        });

        this.update(payload);
    }
}
