import { BaseBroadcast } from "./BaseBroadcast";

type DataType = {
    keys: number[],
    chatRoomId: number,
    userId: number,
}

export class ChatMessagesReadBroadcast extends BaseBroadcast {
    isPrivate = true;
    event = 'chat.messages.read';
    callback: (data: DataType) => void;

    constructor(callback: (data: DataType) => void) {
        super();
        this.setCallback(callback);
    }
}

