import { makeQuery } from 'shared/utilities';

type RequestType = {
    userId: number,
    spaceId: number
}

type ResponseType = {};

export const spaceUsersDeleteQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/space-users/delete', {
        prefix: 'task-tracker',
        json: request
    });
}
