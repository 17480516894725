import { makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IFaqModel {
    id?: number;
    faqCategoryId?: number;
    question?: string;
    answer?: string;
}

export class FaqModel extends Model implements IFaqModel {
    id = 0;
    faqCategoryId = 0;
    question = '';
    answer = '';

    constructor(payload: IFaqModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            faqCategoryId: observable,
            answer: observable,
            question: observable,
        });

        this.update(payload);
    }
}
