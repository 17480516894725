import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from "react-router-dom";

import { UiIcon } from 'shared/uikit';
import { useBroadcast, useDebounce, useNavigate, useStore } from "shared/hooks";
import { chatMessagesUnreadCountQuery } from "shared/queries";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { ChatMessageCreatedBroadcast, ChatMessagesReadBroadcast } from "shared/broadcasts";

import './styles.scss';

export const LayoutMenuSecondaryChatMessages = observer(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const store = useStore(() => ({
        unreadCount: 0,
    }));

    const fetchUnreadCount = useDebounce(async () => {
        const response = await chatMessagesUnreadCountQuery();
        if (response.isSuccess && response.data) {
            store.set('unreadCount', response.data.value);
        }
    }, 400);

    useEffect(() => {
        fetchUnreadCount();
    }, [ fetchUnreadCount ]);
    //
    // useEffect(() => {
    //     const interval = setInterval(fetchUnreadCount, 3000);
    //     return () => clearInterval(interval);
    // }, []);

    const handleClick = () => {
        navigate(ROUTES.CHATS());
    }

    const chatMessageCreatedBroadcast = useMemo(() => new ChatMessageCreatedBroadcast(({ item }) => {
        if (+location.pathname.replace('/chat/', '') !== +item.chatRoomId) {
            store.set('unreadCount', store.unreadCount + 1);
        }
        fetchUnreadCount();
    }), [ location.pathname, store.unreadCount, store ]); // eslint-disable-line
    useBroadcast(chatMessageCreatedBroadcast);

    const chatMessagesReadBroadcast = useMemo(
        () => new ChatMessagesReadBroadcast(fetchUnreadCount),
        [fetchUnreadCount]
    );
    useBroadcast(chatMessagesReadBroadcast);

    return (
        <div className="layout-menu-secondary-item">
            <div className="layout-menu-secondary-item__inner" onClick={handleClick}>
                <UiIcon icon={ICONS.MESSAGES} size={24} color={COLORS.WHITE}/>
                {!!store.unreadCount && (
                    <div className="layout-menu-secondary-item__count">{store.unreadCount}</div>
                )}
            </div>
        </div>
    )
});
