import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';

import {
    UiButton,
    UiCard,
    UiCardTitle,
    UiDataBoundary,
    UiIcon,
    UiNavLink,
    UiTags,
    UiWidgetArticle
} from 'shared/uikit';
import { useNavigate, useStore } from "shared/hooks";
import { AnnouncementCategoryModel, AnnouncementModel } from "shared/models";
import { announcementCategoriesQuery, announcementsQuery } from "shared/queries";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import './styles.scss';

export const WAnnouncements = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        announcements: [] as AnnouncementModel[],
        announcementCategories: [] as AnnouncementCategoryModel[],
        isLoading: true,
        isSuccess: false,
        swiper: null as null | SwiperClass
    }));

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', false);
            let response = await announcementCategoriesQuery();
            if (response.isSuccess && response.data) {
                store.set("announcementCategories", response.data.items.map(item => new AnnouncementCategoryModel(item)))
            } else {
                store.set("isSuccess", false)
                store.set("isLoading", false)
                return;
            }
            response = await announcementsQuery({ perPage: 6 });
            if (response.isSuccess && response.data) {
                store.set("announcements", response.data.items.map(item => new AnnouncementModel(item)))
            } else {
                store.set("isSuccess", false)
                store.set("isLoading", false)
                return;
            }
            store.set('isSuccess', true);
            store.set('isLoading', false);
        })();
    }, [store]);

    return (
        <UiCard className='w-announcements'>
            <UiCardTitle label='Объявления Togas' collapsable={'WAnnouncements'}/>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="w-announcements-slider">
                    <Swiper
                        spaceBetween={16}
                        slidesOffsetBefore={40}
                        slidesOffsetAfter={40}
                        slidesPerView={'auto'}
                        onSwiper={(swiper: SwiperClass) => {
                            store.set("swiper", swiper)
                        }}
                    >
                        {store.announcementCategories.map(announcementCategory => (
                            <SwiperSlide key={announcementCategory.id} className='w-announcements-slide'>
                                <UiNavLink
                                    to={ROUTES.ANNOUNCEMENTS() + `/?announcementCategoryId=${announcementCategory.id}`}
                                    className="w-announcements-slide__inner"
                                    style={{ backgroundColor: announcementCategory.color }}
                                >
                                    <div className="w-announcements-slide__name">{announcementCategory.name}</div>
                                    <div
                                        className="w-announcements-slide__image"
                                        style={{ backgroundImage: `url(${announcementCategory.image})` }}
                                    />
                                </UiNavLink>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div
                        onClick={() => {
                            store.swiper?.slidePrev();
                        }}
                        className="w-announcements-slider__arrow w-announcements-slider__arrow--left"
                    >
                        <UiIcon icon={ICONS.CHEVRON_LEFT} color={COLORS.BLACK} size={22}/>
                    </div>
                    <div
                        onClick={() => {
                            store.swiper?.slideNext();
                        }}
                        className="w-announcements-slider__arrow w-announcements-slider__arrow--right"
                    >
                        <UiIcon icon={ICONS.CHEVRON_RIGHT} color={COLORS.BLACK} size={22}/>
                    </div>
                </div>
                <div className="w-announcements__items">
                    {store.announcements.map(announcement => (
                        <UiWidgetArticle
                            key={announcement.id}
                            href={ROUTES.ANNOUNCEMENT(announcement.id)}
                            image={announcement.imageThumbnail}
                            name={announcement.name}
                            date={announcement.createdAt}
                            tags={<UiTags items={announcement.tags}/>}
                        />
                    ))}
                </div>
                <UiButton
                    isLink
                    label='Все объявления'
                    iconAfter={<UiIcon icon={ICONS.ARROW_RIGHT} size={12} color={COLORS.BROWN_1}/>}
                    onClick={() => {
                        navigate(ROUTES.ANNOUNCEMENTS());
                    }}
                />
            </UiDataBoundary>
        </UiCard>
    );
});
