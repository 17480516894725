import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { OfficeModel } from 'shared/models';
import { useStore } from "shared/hooks";
import { UiIcon, UiTabs, UiHtml, UiImageThumbnail } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

import { OfficeItemPublicTransport } from "./components/OfficeItemPublicTransport";
import { OfficeItemCorporateTransport } from "./components/OfficeItemCorporateTransport";

import './styles.scss';

type PropsType = {
    office: OfficeModel;
}

export const OfficeItem = observer(({ office }: PropsType) => {
    const store = useStore(() => ({
        tab: 'public'
    }));

    const tabs = useMemo(() => {
        const items = [];
        if (office.publicTransport.length > 0) {
            items.push({ id: 'public', name: 'Общественным транспортом' });
        }
        if (office.corporateTransport.length > 0) {
            items.push({ id: 'corporate', name: 'Корп. транспортом' });
        }
        return items;
    }, [office]);

    useEffect(() => {
        store.set('tab', tabs.length > 0 ? tabs[0].id : 'public');
    }, [tabs, store]);

    return (
        <div className='office-item'>
            <div className="office-item__name">{office.name}</div>
            <div className="office-item__inner">
                <UiImageThumbnail
                    className="office-item__image"
                    value={office.image}
                />
                <div className="office-item__body">
                    <div className="office-item-address">
                        <div className="office-item-address__label">
                            <UiIcon icon={ICONS.GEOMARKER} size={20} color={COLORS.BROWN_1}/>
                            <span>Адрес:</span>
                        </div>
                        <div className="office-item-address__value">{office.address}</div>
                    </div>
                    <div className="office-item__tabs">
                        <UiIcon icon={ICONS.ROUTE} size={20} color={COLORS.BROWN_1}/>
                        <UiTabs
                            value={store.tab}
                            onChange={store.handleChange}
                            name='tab'
                            items={tabs}
                        />
                    </div>
                    {store.tab === 'public' && office.publicTransport.map((item, index) => (
                        <OfficeItemPublicTransport key={index} publicTransport={item}/>
                    ))}
                    {store.tab === 'corporate' && (
                        <>
                            <UiHtml
                                className="office-item__description"
                                value={office.corporateTransportDescription}
                            />
                            {office.corporateTransport.map((item, index) => (
                                <OfficeItemCorporateTransport key={index} corporateTransport={item}/>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
});
