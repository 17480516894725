import React from 'react';
import { observer } from 'mobx-react';

import { UiButton } from "shared/uikit";
import { useNavigate } from "shared/hooks";

import './styles.scss';

type PropsType = {
    to: string
    label: string
    name: string
    image: string
}

export const PCompanyBigCardLink = observer(({image, name, to, label}: PropsType) => {
    const navigate = useNavigate();
    return (
        <div className="p-company-big-card-link">
            <div className="p-company-big-card-link__name">{name}</div>
            <div className="p-company-big-card-link__circle--3"/>
            <div className="p-company-big-card-link__circle--2"/>
            <div className="p-company-big-card-link__circle--1"/>
            <div className="p-company-big-card-link__button">
                <UiButton label={label} onClick={() => navigate(to)} isSecondary/>
            </div>
            <div className="p-company-big-card-link__image" style={{backgroundImage: `url(${image})`}}/>
        </div>
    );
})

