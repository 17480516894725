import lodash from "lodash";
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Portal } from 'react-portal';
import classnames from "classnames";
import { toJS } from "mobx";

import { useOnClickOutside, useStore } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';

type Item = {
    type?: 'warning',
    icon?: JSX.Element | React.ReactNode,
    name: string,
    isActive?: () => boolean,
    color?: string,
    action: () => void
}

type ItemsGroup = Item[];
type PropsType = {
    icon?: JSX.Element | React.ReactNode,
    size?: number,
    title?: string,
    placeholder?: string
    items?: ItemsGroup[]
}

export const CContextMenu = observer((
    {
        size = 24,
        icon = (<UiIcon icon={ICONS.TRIPLEDOTS} size={Math.floor(size * 0.6) - 2}/>),
        title,
        items = [],
        placeholder = 'Отсутсвуют доступные доски для перемещения'
    }: PropsType
) => {
    const store = useStore(() => ({
        style: {} as any,
        isOpened: false
    }));

    const refControl = useRef<HTMLDivElement>(null);
    const refItems = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        store.set("isOpened", false);
    }
    useOnClickOutside(refItems, handleClose);

    const handleWheel = (e: WheelEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    useEffect(() => {
        if (!refItems.current) {
            return;
        }
        window.addEventListener('wheel', handleWheel, { passive: false });
        return () => window.removeEventListener('wheel', handleWheel);
    }, [store.isOpened]);

    const handleOpen: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!refControl.current) {
            return;
        }
        const style: any = {};
        const windowWidth = document.body.getBoundingClientRect().width;
        const bounds = refControl.current.getBoundingClientRect();
        style.top = bounds.top + size + 2;
        if (windowWidth / 2 < bounds.left) {
            style.right = windowWidth - bounds.left - size;
        } else {
            style.left = bounds.left
        }
        store.set("style", { ...style });
        store.set("isOpened", true);
    }

    const count = !items ? 0 : lodash.sum(items.map(item => item.length));

    return (
        <div className="c-tt-context-menu">
            <div
                ref={refControl}
                className="c-tt-context-menu__control"
                style={{ width: size, height: size }}
                onClick={handleOpen}
            >
                {icon}
            </div>
            {store.isOpened && (
                <Portal>
                    <div
                        ref={refItems}
                        className="c-tt-context-menu-items"
                        style={toJS(store.style)}
                    >
                        {count === 0 && (
                            <div className="c-tt-context-menu__placeholder">{placeholder}</div>
                        )}
                        {(count > 0 && title) && (
                            <div className="c-tt-context-menu-items__title">{title}</div>
                        )}
                        {count > 0 && items.map((group, index) => {
                            const isShown = group.some(item => {
                                if (!item.isActive) {
                                    return true;
                                }
                                return item.isActive();
                            });
                            if (!isShown) {
                                return null;
                            }
                            return (
                                <div
                                    key={index}
                                    className="c-tt-context-menu-items__group"
                                >
                                    {group.map(item => (
                                        <div
                                            key={item.name}
                                            style={{
                                                color: item.color
                                            }}
                                            className={classnames('c-tt-context-menu-item', `c-tt-context-menu-item--${item.type || ''}`)}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                !!item.action && item.action();
                                                store.set("isOpened", false);
                                            }}
                                        >
                                            {item.icon}
                                            <span>{item.name}</span>
                                        </div>
                                    ))}
                                </div>
                            )
                        })}
                    </div>
                </Portal>
            )}
        </div>
    )
});

