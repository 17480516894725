import { Draggable } from "@hello-pangea/dnd";
import classnames from "classnames";
import hexToRgba from 'hex-to-rgba';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { ICONS, ROUTES } from "shared/constants";
import { useEvent, useNavigate, useUrlParams } from "shared/hooks";
import { TaskModel } from "shared/models/TaskTracker";
import { UiAvatar, UiIcon } from "shared/uikit";
import { Notifier } from "shared/utilities";

import { Store } from "pages/task-tracker/Store";

import './index.scss';

type PropsType = {
    task: TaskModel,
    type?: 'board' | 'inbox',
    isDragDisabled?: boolean,
    index: number,
    style?: React.CSSProperties,
    header?: React.ReactNode
}

export const CTask = observer((
    {
        task,
        index,
        type = 'board',
        style,
        header,
        isDragDisabled = false
    }: PropsType
) => {
    const urlParams = useUrlParams({
        view: 'board',
    });

    if (type === 'inbox') {
        return (
            <div style={style} className={classnames('c-tt-task')}>
                <CTaskBody header={header} task={task} type={type}/>
            </div>
        )
    }

    return (
        <Draggable
            index={index}
            draggableId={`task-${task.id}`}
            isDragDisabled={isDragDisabled}
        >
            {(draggable, snapshot) => (
                <div
                    ref={(ref) => draggable.innerRef(ref)}
                    className={classnames('c-tt-task', `c-tt-task--${urlParams.view}`, {
                        'c-tt-task--dragging': snapshot.isDragging
                    })}
                    {...(urlParams.view === 'list' ? {} : draggable.dragHandleProps)}
                    {...draggable.draggableProps}
                >
                    <CTaskBody dragHandleProps={draggable.dragHandleProps} task={task} header={header}/>
                </div>
            )}
        </Draggable>
    )
});

type PropsBodyType = {
    dragHandleProps?: any,
    task: TaskModel,
    type?: 'board' | 'inbox',
    header?: React.ReactNode
}
const CTaskBody = observer(({ task, header, dragHandleProps = {}, type = 'board' }: PropsBodyType) => {
    const [isSelected, setIsSelected] = useState(false);
    const ref = useRef<HTMLDivElement>(null)
    const navigate = useNavigate();
    const [gridTemplateColumns, setGridTemplateColumns] = useState<string | undefined>(undefined);
    const urlParams = useUrlParams({
        view: 'board',
    });

    useEvent('tt-grid-rows-template', setGridTemplateColumns);

    useEffect(() => {
        if (ref.current && window.location.hash === `#task-${task.id}`) {
            ref.current.scrollIntoView({
                behavior: "instant",
                block: "center"
            });
            setIsSelected(true);

            const interval1 = setTimeout(() => {
                window.history.replaceState(null, '', window.location.pathname);
            }, 1500);

            const interval2 = setTimeout(() => {
                setIsSelected(false)
            }, 4000);

            return () => {
                clearInterval(interval1);
                clearInterval(interval2);
            }
        }
    }, [ref, task.id]);

    if (urlParams.view === 'list' && type !== 'inbox') {
        return (
            <div
                ref={ref}
                className={classnames('c-tt-task__body', {
                    'c-tt-task__body--selected': isSelected
                })}
                style={{ gridTemplateColumns }}
            >
                <div
                    className="c-tt-task__name"
                    onClick={() => {
                        navigate(null, {
                            ...urlParams,
                            previewTaskId: task.id
                        }, {}, null)
                    }}
                >
                    <div className="c-tt-task__draghandle" {...dragHandleProps}>
                        <UiIcon size={15} icon={ICONS.DND_DOTS}/>
                    </div>
                    <div className="c-tt-task-priority__indicator" style={{ backgroundColor: task.priority.color }}/>
                    <span>{task.name}</span>
                </div>
                <div className="c-tt-task__field">{task.createdAtMoment.format('D MMMM, HH:mm')}</div>
                <div className="c-tt-task__field">
                    <div className="c-tt-task__date" style={{ backgroundColor: task.status.color }}>
                        {task.statusText}
                    </div>
                </div>
                {task.ownerUser && (
                    <div className="c-tt-task__field c-tt-task__field--user">
                        <UiAvatar
                            size={24}
                            image={task.ownerUser.imageAvatar}
                            label={task.ownerUser.name}
                        />
                        <span>{task.ownerUser.name}</span>
                    </div>
                )}
                <div className="c-tt-task__field c-tt-task__field--user">
                    {task.responsibleUser && (
                        <>
                            <UiAvatar
                                size={24}
                                image={task.responsibleUser.imageAvatar}
                                label={task.responsibleUser.name}
                            />
                            <span>{task.responsibleUser.name}</span>
                        </>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div
            ref={ref}
            className={classnames('c-tt-task__body', {
                'c-tt-task__body--selected': isSelected
            })}
            onClick={() => {
                navigate(null, {
                    ...urlParams,
                    previewTaskId: task.id
                }, {}, null)
            }}
        >
            <div className="c-tt-task__header">
                <div
                    className="c-tt-task-priority"
                    style={{ backgroundColor: hexToRgba(task.priority.color, 0.2) }}
                >
                    <div
                        className="c-tt-task-priority__indicator"
                        style={{ backgroundColor: task.priority.color }}
                    />
                    <div className="c-tt-task-priority__name">{task.priority.previewName}</div>
                </div>
                {!!task.space.id && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (!Store.spaces.find(space => space.id === task.spaceId)) {
                                Notifier.notice('У вас нет доступа к данному пространству');
                                return;
                            }

                            navigate(ROUTES.TASK_TRACKER(task.spaceId) + `#task-${task.id}`);
                        }}
                        className="c-tt-task-space" title={task.space.name}
                    >
                        <div className="c-tt-task-space__name">{task.space.name}</div>
                    </div>
                )}
                {header}
            </div>
            <div className="c-tt-task__name">
                {task.name}
            </div>
            <div className="c-tt-task__footer">
                <div className="c-tt-task__users">
                    {task.ownerUser && (
                        <UiAvatar
                            size={24}
                            image={task.ownerUser.imageAvatar}
                            label={task.ownerUser.name}
                        />
                    )}
                    {task.responsibleUser && (
                        <>
                            <UiIcon size={8} icon={ICONS.ARROW_RIGHT}/>
                            <UiAvatar
                                size={24}
                                image={task.responsibleUser.imageAvatar}
                                label={task.responsibleUser.name}
                            />
                        </>
                    )}
                </div>
                <div className="c-tt-task__date" style={{ backgroundColor: task.status.color }}>
                    {task.statusText}
                </div>
            </div>
        </div>
    )
});
