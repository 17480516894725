import { makeQuery } from 'shared/utilities';

type RequestType = {
    phone: string,
    email: string,
    description: string,
    files?: File[],
    topic: string | null,
    requestTypeId: number,
}

type ResponseType = {};

export const requestsCreateQuery = async function (
    {
        files = [],
        phone,
        email,
        description,
        topic,
        requestTypeId
    }: RequestType
) {
    const formData = new FormData();
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('description', description);
    formData.append('topic', topic || '');
    if (requestTypeId) {
        formData.append('request_type_id', requestTypeId.toString());
    }
    files.forEach((file) => formData.append('files[]', file));

    return await makeQuery<ResponseType>('/requests/create', { formData });
}
