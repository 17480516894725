import { makeQuery } from 'shared/utilities';
import { IArticleModel } from 'shared/models';

type ResponseType = {
    item: IArticleModel;
};

export const articlesGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/articles/get', { params: {id} });
}
