import { makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IImportantInformationModel {
    id?: number;
    name?: string;
    url?: string;
}

export class ImportantInformationModel extends Model implements IImportantInformationModel {
    id = 0;
    name = '';
    url = '';

    constructor(payload: IImportantInformationModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            url: observable,
        });

        this.update(payload);
    }
}
