import { makeQuery } from 'shared/utilities';
import { ICompanyBestGroupModel } from "shared/models";

type ResponseType = {
    items: ICompanyBestGroupModel[]
};

export const companyBestGroupsQuery = async function () {
    return await makeQuery<ResponseType>('/company-best-groups');
}
