import { makeQuery } from 'shared/utilities';
import { IOfficeModel } from 'shared/models';

type ResponseType = {
    items: IOfficeModel[];
};

export const officesQuery = async function () {
    return await makeQuery<ResponseType>('/offices');
}
