import React from 'react';
import { observer } from 'mobx-react';

import './styles.scss';

type PropsType = {
    children?: React.ReactNode
}
export const LayoutBodyHeader = observer(({ children }: PropsType) => {
    return <div className='layout-body-header'>{children}</div>;
});
