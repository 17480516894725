import { transformObjectKeys } from "shared/utilities";

export class BaseBroadcast {
    channel: string | null = null;
    isPrivate: boolean | null = true;
    callback: (data: any) => void;

    setCallback(callback: any){
        this.callback = (data: any) => callback(transformObjectKeys(data));
    }
}

