import qs from 'qs';

const parseValue = (item: number | null | string) => {
    if (typeof item === 'string' && !isNaN(Number(item))) {
        return +item;
    }
    if (item === 'false') {
        return false;
    }
    if (item === 'true') {
        return true;
    }
    if (item === 'null') {
        return null;
    }
    return item;
};

const parse = <T>(defaultParams: T, params: T): T => {
    const resultParams: T = { ...defaultParams };

    for (const paramKey in params) {
        let value = params[paramKey] as any | any[];
        if (Array.isArray(value)) {
            value = value.map(parseValue)
        } else {
            value = parseValue(value);
        }
        resultParams[paramKey] = value;
    }

    return resultParams;
};

export const parseUrlParams = <T>(urlParams: string, defaultUrlParams: T): T => {
    return parse<T>(
        defaultUrlParams,
        {
            ...defaultUrlParams,
            ...qs.parse(urlParams.substr(1)),
        }
    );
};
