import classnames from "classnames";
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useStore } from "shared/hooks";

import { SurveyModel, SurveyQuestionModel } from 'shared/models';
import { surveysAnswerQuery, surveysCancelQuery } from "shared/queries";
import { UiButton, UiDropdownMenu, UiIcon, UiLightbox, UiSurveyQuestionAnswer } from 'shared/uikit';
import { clipboardCopy, getUrl } from "shared/utilities";

import { SurveyItemDetail } from "./components/SurveyItemDetail";

import './styles.scss';

type PropsType = {
    survey: SurveyModel
}

export const CSurvey = observer(({ survey }: PropsType) => {
    const store = useStore(() => ({
        isVisible: false,
        lightboxIsOpened: false,
        lightboxMedia: '',
    }));

    const handleAnswer = async (surveyQuestionId: number, id: number, comment = '') => {
        const response = await surveysAnswerQuery({
            surveyQuestionId,
            userSurveyQuestionAnswers: [{
                surveyQuestionAnswerId: id,
                comment
            }]
        });
        if (!response.isSuccess || !response.data) {
            return;
        }
        survey.update(response.data.item);
    }

    const handleMultipleAnswers = async (surveyQuestion: SurveyQuestionModel) => {
        const response = await surveysAnswerQuery({
            surveyQuestionId: surveyQuestion.id,
            userSurveyQuestionAnswers: surveyQuestion.surveyQuestionAnswers
                .filter(surveyQuestionAnswer => surveyQuestionAnswer.isSelected)
                .map(surveyQuestionAnswer => {
                    return {
                        surveyQuestionAnswerId: surveyQuestionAnswer.id,
                    }
                })
        });
        if (!response.isSuccess || !response.data) {
            return;
        }
        survey.update(response.data.item);
    }

    const className = classnames('c-survey', {
        'c-survey--new': survey.isNew
    });

    const handleCancelQuestionAnswer = async (surveyQuestion: SurveyQuestionModel) => {
        surveyQuestion.update({
            'userSurveyQuestionAnswer': []
        });

        const response = await surveysCancelQuery({
            surveyQuestionId: surveyQuestion.id
        });

        if (response.isSuccess && response.data) {
            survey.update(response.data.item)
        }
    }

    const handleCancelSurvey = async (survey: SurveyModel) => {
        survey.surveyQuestions.forEach(surveyQuestion => {
            surveyQuestion.update({
                'userSurveyQuestionAnswer': null
            });
        })
        const response = await surveysCancelQuery({
            surveyId: survey.id
        });

        if (response.isSuccess && response.data) {
            survey.update(response.data.item)
        }
    }

    const handleSelect = (id: number | string) => {
        switch (id) {
            case 'copy':
                clipboardCopy(getUrl(ROUTES.SURVEY(survey.id)));
                break;
            case 'statistic':
                store.set('isVisible', true);
                break
        }
    }

    const options = useMemo(() => {
        const options = [{
            id: 'copy',
            name: 'Копировать ссылку'
        }]
        if (survey.isResultsPublic) {
            options.push({ id: 'statistic', name: 'Посмотреть статистику' })
        }
        return options;
    }, [survey.isResultsPublic]);

    return (
        <div className={className}>
            {store.isVisible && (
                <SurveyItemDetail surveyId={survey.id} onClose={() => store.set('isVisible', false)}/>
            )}
            <div className="c-survey__header">
                {survey.isNew && (
                    <div className="c-survey__badge">
                        <UiIcon icon={ICONS.FIRE} size={20} color={COLORS.WHITE}/>
                        <span>Новый</span>
                    </div>
                )}
                <div className="c-survey__type">
                    {survey.isAnonymous ? 'Анонимный опрос от ' : 'Публичный опрос от '}
                    {survey.createdAtMoment.format('D MMMM YYYY')}
                </div>
                <div className="c-survey__menu">
                    <UiDropdownMenu
                        right={-9}
                        onSelect={handleSelect}
                        control={<UiIcon icon={ICONS.TRIPLEDOTS} size={17}/>}
                        items={options}
                    />
                </div>
            </div>
            {survey.imageThumbnail && (
                <div
                    className='c-survey__image'
                    style={{ backgroundImage: `url(${survey.imageThumbnail?.webp}), url(${survey.imageThumbnail?.origin})` }}
                ></div>
            )}
            <div className="c-survey__questions">
                {survey.surveyQuestions.map(question => {
                    const imageIndex = question.image ? (+question.image - 1) : null;
                    const hasSelectedAnswers = question.surveyQuestionAnswers.some(surveyQuestionAnswer => surveyQuestionAnswer.isSelected);

                    const media = (imageIndex !== null ? (survey.imagesUrls[imageIndex] || null) : null) as string | null;
                    const isVideo = media ? ['mp4', 'webm', 'ogg'].some(format => media.toLowerCase().endsWith(format)) : false;

                    return (
                        <div key={question.id} className="c-survey-question">
                            {(media && !isVideo) && (
                                <img
                                    className="c-survey-question__image"
                                    src={media}
                                    alt=""
                                    onClick={() => {
                                        store.set('lightboxMedia', media);
                                        store.set("lightboxIsOpened", true);
                                    }}
                                />
                            )}
                            {(media && isVideo) && (
                                <div
                                    className="c-survey-question-video"
                                    onClick={() => {
                                        store.set('lightboxMedia', media);
                                        store.set("lightboxIsOpened", true);
                                    }}
                                >
                                    <video src={media}/>

                                    <div className="c-survey-question-video__overlay">
                                        <UiIcon icon={ICONS.PLAY} size={20} color={COLORS.WHITE}/>
                                    </div>
                                </div>
                            )}
                            <div className="c-survey-question__inner">
                                <div className="c-survey-question__header">
                                    <div className="c-survey-question__name">{question.name}</div>
                                    {question.userSurveyQuestionAnswer.length > 0 && survey.isActive && (
                                        <div className="c-survey-question__cancel">
                                            <UiButton
                                                isLink
                                                label='Отменить выбор'
                                                onClick={() => handleCancelQuestionAnswer(question)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="c-survey-question__answers">
                                    {question.surveyQuestionAnswers.map(answer => (
                                        <UiSurveyQuestionAnswer
                                            key={answer.id}
                                            survey={survey}
                                            surveyQuestion={question}
                                            surveyQuestionAnswer={answer}
                                            onAnswer={handleAnswer}
                                        />
                                    ))}
                                </div>
                                {!!(question.isMultiple && hasSelectedAnswers) && (
                                    <div className="c-survey-question__apply">
                                        <UiButton
                                            isLink
                                            label='Ответить'
                                            onClick={() => handleMultipleAnswers(question)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="c-survey__footer">
                <div className="c-survey__count">
                    Всего проголосовало: <span>{survey.userSurveyQuestionAnswers.length} человек(а)</span>
                </div>
                <div className="c-survey__cancel">
                    {
                        survey.isActive &&
                        survey.surveyQuestions.length > 1 &&
                        survey.surveyQuestions.some(surveyQuestions => !!surveyQuestions.userSurveyQuestionAnswer) && (
                            <UiButton
                                isTiny
                                label='Отменить всё'
                                onClick={() => handleCancelSurvey(survey)}
                            />
                        )
                    }
                </div>
            </div>
            <UiLightbox
                items={[store.lightboxMedia]}
                isOpened={store.lightboxIsOpened}
                onClose={() => store.set("lightboxIsOpened", false)}
            />
        </div>
    )
});
