export const SEARCH_TYPES = [
    { id: 'news', name: 'Новости' },
    { id: 'announcement', name: 'Объявления' },
    { id: 'vacancy', name: 'Вакансии' },
    { id: 'article', name: 'Статьи' },
    { id: 'user', name: 'Сотрудники' },
    { id: 'document', name: 'Документы' },
    { id: 'department', name: 'Департаменты' },
    { id: 'departmentbranch', name: 'Отделы' },
];
