import { makeQuery } from 'shared/utilities';
import { IUserPersonalFieldModel } from 'shared/models';

type ResponseType = {
    items: IUserPersonalFieldModel[];
};

export const userPersonalFieldsQuery = async function () {
    return await makeQuery<ResponseType>('/user-personal-fields');
}
