import { makeQuery } from 'shared/utilities';
import { IBoardModel } from "shared/models/TaskTracker";

type RequestType = {
    id: number | null
}

type ResponseType = {
    item: IBoardModel,
};

export const boardsGetQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/boards/get', {
        prefix: 'task-tracker',
        params: request
    });
}
