import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import lodash from "lodash";

import { LayoutBody, LayoutContentTitle, LayoutContent } from 'shared/layout';
import { useBroadcast, useStore } from "shared/hooks";
import { ChatRoomModel } from "shared/models";
import { chatRoomsQuery } from "shared/queries";
import { UiButton, UiDataBoundary, UiGrid } from "shared/uikit";
import { ApplicationModule } from "shared/modules";
import { ChatMessageCreatedBroadcast, ChatRoomSavedBroadcast } from "shared/broadcasts";
import { CmChatRoomCreate } from "shared/components";

import { ChatRoomItem } from "./components/ChatRoomItem";

export const ChatsPage = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: true,
        modal: false,
        items: [] as ChatRoomModel[]
    }));

    const fetchItems = useCallback(async () => {
        const { isSuccess, data } = await chatRoomsQuery();
        if (isSuccess && data) {
            store.set("items", data.items.map(item => new ChatRoomModel(item)));
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store]);

    const chatRooms = store.items.slice()
        .filter(item => item.chatRoomUser?.isActive)
        .sort((a, b) => {
            return b.sort - a.sort;
        });

    const chatRoomById = useMemo(() => {
        return lodash.keyBy(chatRooms, 'id');
    }, [chatRooms])

    const chatMessageCreatedBroadcast = useMemo(() => new ChatMessageCreatedBroadcast(({ item }) => {
        if (!chatRoomById[item.chatRoomId]) {
            fetchItems();
            return;
        }
        chatRoomById[item.chatRoomId].chatMessageLast?.update(item);
        if (item.user?.id !== ApplicationModule.user.id) {
            chatRoomById[item.chatRoomId].update({
                chatMessagesUnreadCount: chatRoomById[item.chatRoomId].chatMessagesUnreadCount + 1
            });
        }
    }), [fetchItems, chatRoomById]);
    useBroadcast(chatMessageCreatedBroadcast);
    const chatRoomCreatedBroadcast = useMemo(
        () => new ChatRoomSavedBroadcast(fetchItems),
        [fetchItems]
    );
    useBroadcast(chatRoomCreatedBroadcast);

    useEffect(() => {
        ApplicationModule.setTitle('Сообщения')
        fetchItems();
    }, [fetchItems]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Сообщения' widgets={false}>
                    <UiButton
                        isLink
                        label='Новый диалог'
                        onClick={() => store.set("modal", true)}
                    />
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading}>
                    {store.items.length > 0 && (
                        <UiGrid style={{ display: 'block', maxWidth: 680 }}>
                            {chatRooms.map((item) => <ChatRoomItem key={item.id} item={item}/>)}
                        </UiGrid>
                    )}
                    {store.items.length === 0 && (
                        <div className='typography'>
                            <h2>У вас пока нет сообщений</h2>
                            <p>Чтобы начать отправлять сообщения, воспользуйтесь <UiButton
                                isLink
                                label='созданием диалога'
                                onClick={() => store.set("modal", true)}
                            />
                            </p>
                        </div>
                    )}
                </UiDataBoundary>
                <CmChatRoomCreate
                    isOpened={store.modal}
                    onClose={() => store.set("modal", false)}
                />
            </LayoutContent>
        </LayoutBody>
    );
});
