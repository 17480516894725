import { makeQuery } from 'shared/utilities';
import { IChatRoomModel } from 'shared/models';

type RequestType = {
    id: number,
    isBlocked?: number,
    isActive?: number,
    receiveNotifications?: number,
    isFixed?: number,
};

type ResponseType = {
    item: IChatRoomModel;
};

export const chatRoomUsersUpdateQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/chat-room-users/update', { json: payload });
}
