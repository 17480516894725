import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IValuesItemModel {
    id?: number;
    sort?: number,
    name?: string,
    description?: string,
    items?: string,
}

export class ValuesItemModel extends Model implements IValuesItemModel {
    id = 0;
    sort = 0;
    name = '';
    description = '';
    items = '';

    constructor(payload: IValuesItemModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            description: observable,
            items: observable,
            update: action,
        });

        this.update(payload);
    }
}
