import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useBroadcast, useDebounce, useNavigate, useStore, useUrlParams } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import { notificationsUnreadCountQuery } from "shared/queries";
import { NotificationCreatedBroadcast, NotificationUnreadCountBroadcast } from "shared/broadcasts";

import './index.scss';

export const CNotificationsAction = observer(() => {
    const store = useStore(() => ({
        count: 0
    }));
    const navigate = useNavigate();
    const urlParams = useUrlParams({});

    const fetchCount = useDebounce(async () => {
        const { data } = await notificationsUnreadCountQuery({ moduleId: 'task_tracker' })
        store.set("count", data?.count || 0);
    }, 1000);

    useEffect(() => {
        fetchCount();
    }, [fetchCount]);

    useBroadcast(new NotificationUnreadCountBroadcast(() => {
        fetchCount();
    }));

    useBroadcast(new NotificationCreatedBroadcast(() => {
        store.set("count", store.count + 1);
    }));

    return (
        <div className={'c-notifications-action'} onClick={() => navigate(null, {
            ...urlParams,
            taskTrackerNotifications: 'show'
        })}>
            <UiIcon size={24} icon={ICONS.NOTIFICATIONS} color={'#999999'}/>
            {store.count > 0 && (
                <div className="c-notifications-action__count">{store.count}</div>
            )}
        </div>
    )
});



