import { makeQuery } from 'shared/utilities';
import { INewsModel } from 'shared/models';

type ResponseType = {
    items: INewsModel[];
};

export const newsTopQuery = async function () {
    return await makeQuery<ResponseType>('/news/top');
}
