export const COLORS = {
    BLACK: '#000000',
    WHITE: '#ffffff',
    BROWN_1: '#907C4B',
    GRAY_1: '#1c1c1b',
    GRAY_2: '#717171',
    GRAY_3: '#A4A4A4',
    GRAY_4: '#C4C4C4',
    GRAY_4A: '#B3B3B3',
    GRAY_5: '#F2F2F2',
    GRAY_5A: '#999999',
    GRAY_BACKGROUND: '#F8F8F8',
    GOLD: '#FFDA2D',
    SILVER: '#ADB3B7',
    BRONZE: '#D58D54',
    RED_1: '#DD0000',
    GREEN: '#51BC2B',
};
