import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiForm, UiFormControl, UiModal, UiUsersSearch } from "shared/uikit";
import { useRouteParams, useStore } from "shared/hooks";
import { UserModel } from "shared/models";
import { chatRoomsInviteQuery } from "shared/queries";
import { Service } from "pages/chat/service";

type PropsType = {
    children?: React.ReactNode,
    className?: string
}
export const ChatRoomInvite = observer((
    {
        children,
        className
    }: PropsType
) => {
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        isOpened: false,
        isSubmitting: false,
        users: [] as UserModel[],
    }));

    const handleSubmit = async () => {
        if (store.users.length === 0) {
            store.set("isOpened", false);
            return;
        }
        store.set("isSubmitting", true);
        await chatRoomsInviteQuery({
            id: routeParams.id,
            userId: store.users.map(({ id }) => id)
        });
        await Service.fetchChatRoom(routeParams.id);
        store.set("isOpened", false);
        store.set("isSubmitting", false);
    }

    return (
        <>
            <div className={className} onClick={() => store.set("isOpened", true)}>
                {children}
            </div>
            <UiModal
                onClose={() => store.set("isOpened", false)}
                isOpened={store.isOpened}
                title='Пригласить новых участников'
            >
                <UiForm onSubmit={handleSubmit}>
                    <UiFormControl
                        style={{ marginBottom: 16 }}
                    >
                        <UiUsersSearch
                            limit={100}
                            label={'Участники'}
                            onChange={({ value }) => store.set("users", value)}
                            value={store.users}
                        />
                    </UiFormControl>
                    <div className="ui-modal__actions">
                        <UiButton
                            isLoading={store.isSubmitting}
                            isSubmit
                            label={'Добавить'}
                        />
                        <UiButton
                            label='Отмена'
                            onClick={() => store.set("isOpened", false)}
                            isOutline
                        />
                    </div>
                </UiForm>
            </UiModal>
        </>
    );
});
