import * as Bowser from 'bowser';

export default new (class Browser {
    os = 'windows';
    browser = 'chrome';
    constructor() {
        const data = Bowser.parse(window.navigator.userAgent);
        this.os = data?.os?.name?.toLowerCase() || 'windows';
        this.browser = data?.browser?.name?.toLowerCase() || 'chrome';
    }

    isIos = () => {
        return this.os === 'ios';
    };

    isChrome = () => {
        return this.browser === 'chrome';
    };
})();
