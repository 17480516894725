import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';

type ResponseType = {
    pages: number;
    item: IUserModel;
};

export const usersProfileQuery = async function (accessToken: string | null = null) {
    const config = accessToken ? {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    } : {};
    return await makeQuery<ResponseType>('/users/profile', config);
}
