import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import classnames from "classnames";

import { OnChangeHandlerType } from "shared/types";
import { COLORS, ICONS } from "shared/constants";

import { UiIcon } from "../UiIcon";

import './styles.scss';

interface Props {
    type: 'week' | 'month' | 'year',
    name?: string,
    value: string | null;
    onChange: OnChangeHandlerType<string>
}

export const UiDatePagination = observer(({ value, type, onChange, name = 'date' }: Props) => {
    const valueMoment = useMemo(() => moment(value), [value]);
    let text = '';
    switch (type) {
        case "week":
            const from = valueMoment.clone().startOf('week').format('D');
            const monthFrom = valueMoment.format('D MMMM').split(' ')[1];
            const to = valueMoment.clone().endOf('week').format('D');
            const monthTo = valueMoment.clone().endOf('week').format('D MMMM').split(' ')[1];
            const year = valueMoment.format('Y');
            text = `${from} ${monthFrom} - ${to} ${monthTo} ${year}`
            break;
        case "month":
            text = valueMoment.format('MMMM Y')
            break;
        case "year":
            text = valueMoment.format('Y');
            break;
    }

    const today = useMemo(() => moment(), []);
    const isToday = useMemo(() => today.format('Y-MM-DD') === valueMoment.format('Y-MM-DD'), [today, valueMoment]);

    const handleChange = (modifier = 1) => {
        onChange({
            control: 'ui-date-paginator',
            name,
            value: valueMoment.clone().add(modifier * 1, type).format('Y-MM-DD')
        });
    }

    const handleToday = useCallback(() => {
        if (isToday) {
            return;
        }

        onChange({
            control: 'ui-date-paginator',
            name,
            value: today.format('Y-MM-DD')
        });
    }, [isToday, onChange, name, today])

    const className = useMemo(() => classnames("ui-date-pagination", {
        'ui-date-pagination--today': isToday,
    }), [isToday]);

    return (
        <div className={className}>
            <div className="ui-date-pagination__today" onClick={handleToday}>
                Cегодня
            </div>
            <div className="ui-date-pagination__inner">
                <div className="ui-date-pagination__button" onClick={() => handleChange(-1)}>
                    <UiIcon color={COLORS.BLACK} size={16} icon={ICONS.CHEVRON_LEFT}/>
                </div>
                <div className="ui-date-pagination__text">
                    {text}
                </div>
                <div className="ui-date-pagination__button" onClick={() => handleChange()}>
                    <UiIcon color={COLORS.BLACK} size={16} icon={ICONS.CHEVRON_RIGHT}/>
                </div>
            </div>
        </div>
    );
});
