import React from 'react';
import { observer } from 'mobx-react';

import { BookModel } from 'shared/models';
import { UiIcon, UiImageThumbnail, UiLikeAction, UiModal } from "shared/uikit";
import { useMedia } from "shared/hooks";
import { BookTypeEnum } from "shared/enums";
import { CComments } from "shared/components";
import { ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    book: BookModel;
    isOpened: boolean;
    onClose: () => void
}

export const BookItemModal = observer(({ book, isOpened, onClose }: PropsType) => {
    const { isMobile } = useMedia();
    return (
        <UiModal isOpened={isOpened} styleBody={{ maxWidth: 860 }} title={isMobile ? 'Комментарии к книге' : null}>
            <div className='ui-modal-title__close' onClick={onClose}>
                <UiIcon icon={ICONS.CLOSE} size={19}/>
            </div>
            <div className='book-item-modal'>
                <div className="book-item-modal__preview">
                    <UiImageThumbnail
                        className="book-item-modal__image"
                        value={book.imageThumbnail}
                    />
                </div>
                <div className="book-item-modal__inner">
                    <div className="book-item-modal__name">{book.name}</div>
                    <div className="book-item-modal__author">{book.author}</div>
                    {book.typeId === BookTypeEnum.Ebook && (
                        <a href={book.file} target='_blank' className="book-item-modal__link" rel='noreferrer'>
                            Скачать
                        </a>
                    )}
                    <div className="book-item-modal__actions">
                        <UiLikeAction
                            value={book.liked}
                            count={book.likesCount}
                            entityId={book.id}
                            entityType='Book'
                            onChange={({ value }) => {
                                book.update({
                                    liked: value
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
            {isOpened && (
                <CComments
                    entityId={book.id}
                    entityType={'Book'}
                />
            )}
        </UiModal>
    )
});
