import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { useBroadcast, useMedia, useNavigate, useRouteParams } from "shared/hooks";
import { ChatMessageModel } from "shared/models";
import { chatMessagesReadQuery } from "shared/queries";
import {
    ChatMessageCreatedBroadcast,
    ChatMessageDeletedBroadcast,
    ChatMessagesReadBroadcast,
    ChatRoomSavedBroadcast
} from "shared/broadcasts";
import { UiButton, UiIcon } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { ApplicationModule } from "shared/modules";

import { ChatBottomBar } from "./components/ChatBottomBar";
import { ChatRooms } from "./components/ChatRooms";
import { ChatInfo } from "./components/ChatInfo";
import { ChatMessages } from "./components/ChatMessages";
import { ChatAside } from "./components/ChatAside";

import { Store } from './store';
import { Service } from './service';

import './styles.scss';

export const ChatPage = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();
    const navigate = useNavigate();

    useEffect(() => ApplicationModule.setTitle('Загрузка...'), []);

    useEffect(() => {
        Service.fetchData(routeParams.id);
    }, [routeParams.id]);

    useBroadcast(new ChatMessagesReadBroadcast(({ keys = [] }) => {
        keys.forEach(key => {
            if (Store.chatMessageByKey[key]) {
                Store.chatMessageByKey[key].update({
                    readAt: 'now'
                });
            }
        })
    }));

    const {isMobile} = useMedia();

    useBroadcast(new ChatMessageDeletedBroadcast(({ id }) => {
        Store.set("chatMessages", Store.chatMessages.filter(chatMessage => +chatMessage.id !== +id));
    }));

    useBroadcast(new ChatMessageCreatedBroadcast(({ item }) => {
        if (+item.chatRoomId !== +routeParams.id) {
            return;
        }
        if (item.key && Store.chatMessageByKey[item.key]) {
            Store.chatMessageByKey[item.key].update(item);
        } else {
            // let needScrollToBottom = false;
            // if (Store.scrollbar) {
                // const { clientHeight, scrollHeight, scrollTop } = Store.scrollbar.getValues();
                // const treshhold = 30;
                // needScrollToBottom = clientHeight + scrollTop + treshhold >= scrollHeight;
            // }
            const chatMessage = new ChatMessageModel({
                ...item,
                readAt: 'now'
            });
            Store.set("chatMessages", [...Store.chatMessages, chatMessage]);
            setTimeout(() => Store.scrollbar?.scrollTo(0, 99999), 1);
            chatMessagesReadQuery(chatMessage.id);
        }
    }));

    useBroadcast(new ChatRoomSavedBroadcast(({ id }) => {
        if (+id === +routeParams.id) {
            Service.fetchChatRoom(id);
        }
    }));

    return (
        <LayoutBody>
            <LayoutContent style={{ padding: 0 }}>
                <div className="chat">
                    <div className="chat__rooms">
                        <ChatRooms/>
                    </div>
                    <div className="chat__inner">
                        <div className="chat__header">
                            <ChatInfo chatRoom={Store.chatRoom}/>
                            <div className="chat__back">
                                <UiButton
                                    label={isMobile ? null : 'Назад к диалогам'}
                                    onClick={() => navigate(ROUTES.CHATS())}
                                    isLink
                                    iconBefore={<UiIcon color={COLORS.BROWN_1} icon={ICONS.CHEVRON_LEFT}/>}
                                />
                            </div>
                        </div>
                        <ChatMessages/>
                        <ChatBottomBar/>
                    </div>
                </div>
            </LayoutContent>
            <LayoutAside tabs={false} header={false}>
                <ChatAside/>
            </LayoutAside>
        </LayoutBody>
    );
});
