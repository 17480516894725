import { ROUTES } from "shared/constants";
import { ApplicationModule } from "shared/modules";

const hasReports = () => {
    // if is production
    if (window.location.host.indexOf('tnet') > -1) {
        return false;
    }

    if (ApplicationModule.user.userPosition?.isSalesManager && !!ApplicationModule.user.saleStoreExternalId) {
        return true;
    }
    if (ApplicationModule.user.saleSeller) {
        return true;
    }

    if (!!ApplicationModule.user.saleRegionsCount || !!ApplicationModule.user.saleStoresCount) {
        return true;
    }

    return false;
}

const hasDashboards = () => {
    // if is production
    // if (window.location.host.indexOf('tnet') > -1) {
    //     return false;
    // }

    return true;
}

export const tabs = () => {
    const items = [
        { id: ROUTES.PROFILE(), name: 'Настройки профиля' },
    ];

    if (hasReports()) {
        items.push({ id: ROUTES.PROFILE_REPORTS(), name: 'Отчёты' },);
    }

    if (hasDashboards()) {
        items.push({ id: ROUTES.PROFILE_DASHBOARD(), name: 'Дашборды' });
    }

    return items;
}
