import { action, set } from "mobx";

const presets = {
    default: {
        set: action(set)
    },
    form: {
        set: action(set),
        isLoading: false,
        isSubmitted: false,
        errorMessage: '',
    }
}

type PresetsType = typeof presets;

export function useObservablePreset(type: 'form'): PresetsType['form']
export function useObservablePreset(type: 'default'): PresetsType['default']
export function useObservablePreset(type: keyof PresetsType) {
    return {
        ...presets[type]
    };
}
