import React from 'react';
import { observer } from 'mobx-react';

import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';
import { Notifier } from "shared/utilities";
import { CTaskFile } from "shared/components/TaskTracker/CTaskFile";

type PropsType = {
    isEditable?: boolean,
    files?: string[],
    newFiles?: File[],
    onChangeFiles?: (files: string[]) => void,
    onChangeNewFiles?: (File: File[]) => void,
}

export const CTaskFiles = observer((
    {
        files = [],
        newFiles = [],
        onChangeFiles = () => {},
        onChangeNewFiles = () => {},
    }: PropsType
) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!event?.target?.files || event.target.files.length < 1) {
            return;
        }
        const files = Array.from(event.target.files).filter((file) => {
            if (file.size > 10550000) {
                Notifier.alert('Ошибка', 'Максимальный размер файла 8мб, файлы больше этого размера не будут загружены');
                return false;
            }
            return true;
        });
        onChangeNewFiles([...newFiles, ...files]);
    }

    return (
        <div className='c-tt-task-files'>
            <label className="c-tt-task-files__upload">
                <input type="file" multiple onChange={handleChange}/>
                <UiIcon icon={ICONS.ATTACHMENT} size={15} color={'#999999'}/>
                <div className={'c-tt-task-files__description'}>
                    Прикрепить файл
                    <span>(до 10мб)</span>
                </div>
            </label>
            {(!!newFiles?.length || !!files?.length) && (
                <div className="c-tt-task-files__files">
                    {files?.map((file, index) => (
                        <CTaskFile
                            key={file}
                            url={file}
                            name={file}
                            onDelete={() => {
                                onChangeFiles(files?.filter((_, i) => index !== i));
                            }}
                        />
                    ))}
                    {newFiles?.map((file, index) => (
                        <CTaskFile
                            key={file.name + index}
                            url={URL.createObjectURL(file)}
                            name={file.name}
                            onDelete={() => {
                                onChangeNewFiles(newFiles?.filter((_, i) => index !== i));
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    )
});
