import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { UiCheckbox } from 'shared/uikit';
import { OnChangeHandlerType } from 'shared/types';

import { UiDropdown } from '../UiDropdown';
import { UiScrollbar } from "../UiScrollbar";
import UiNotificationCount from '../UiNotificationCount';

import './styles.scss';

type PropsType = {
    label?: string;
    placeholder?: string;
    style?: React.CSSProperties;
    icon?: React.ReactNode;
    name?: string;
    value?: Array<string | number>;
    items?: Array<{ id: string | number; name: string; nameFormatted?: string }>;
    onChange?: OnChangeHandlerType<Array<string | number>>;
}

export const UiDropdownPicker = observer((
    {
        label = '',
        placeholder = '',
        style = {},
        icon = null,
        name = '',
        value = [],
        items = [],
        onChange,
    }:PropsType
) => {

    const className = classnames('ui-dropdown-picker', {
        'ui-dropdown-picker--empty': value.length === 0,
    });

    const handleChangeCheckbox = (itemId: string | number, checkboxValue: number) => {
        if (checkboxValue === 1) {
            onChange && onChange({
                control: 'ui-dropdown-picker',
                name,
                value: [...value, itemId],
            });
        } else {
            onChange && onChange({
                control: 'ui-dropdown-picker',
                name,
                value: value.filter(id => id !== itemId),
            });
        }
    };

    return (
        <div className={className} style={style}>
            <UiDropdown
                label={placeholder}
                control={
                    <div className='ui-dropdown-picker__control'>
                        {icon && icon}
                        <div className='ui-dropdown-picker__label'>{label}</div>
                        {value.length > 0 && <UiNotificationCount value={value.length}/>}
                    </div>
                }
            >
                <UiScrollbar>
                    {items.map(item => (
                        <UiCheckbox
                            key={item.id}
                            label={item.nameFormatted ? item.nameFormatted : item.name}
                            value={value.indexOf(item.id) > -1 ? 1 : 0}
                            onChange={({ value }) => handleChangeCheckbox(item.id, value)}
                        />
                    ))}
                </UiScrollbar>
            </UiDropdown>
        </div>
    )
});
