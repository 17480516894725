import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import classnames from 'classnames';

import { Browser, Cookie, Notifier, wait } from 'shared/utilities';
import { ROUTES } from 'shared/constants';
import { ApplicationModule } from 'shared/modules';
import { UiLoadingSpinner } from 'shared/uikit';
import { userPersonalFieldsQuery, usersPingQuery } from "shared/queries";
import { useNavigate, useStore } from "shared/hooks";
import { Layout } from "shared/layout";
import { UserPersonalFieldModel } from "shared/models";

import { AnnouncementPage } from 'pages/announcement';
import { AnnouncementsPage } from 'pages/announcements';
import { ArticlePage } from 'pages/article';
import { ArticlesPage } from 'pages/articles';
import { BooksPage } from 'pages/books';
import { CafePage } from 'pages/cafe';
import { CalendarPage } from 'pages/calendar';
import { ChatPage } from 'pages/chat';
import { ChatsPage } from 'pages/chats';
import { CompanyPage } from 'pages/company';
import { CompanyStructurePage } from 'pages/company-structure';
import { DepartmentUnitPage } from 'pages/department-unit';
import { DocumentCategoriesPage } from 'pages/document-categories';
import { DocumentCategoryPage } from 'pages/document-category';
import { DocumentsPage } from 'pages/documents';
import { EducationLessonsPage } from 'pages/education-lessons';
import { EducationPage } from 'pages/education';
import { EssentialPage } from 'pages/essential';
import { EssentialsPage } from 'pages/essentials';
import { FaqPage } from 'pages/faq';
import { GalleryPage } from 'pages/gallery';
import { HistoryPage } from 'pages/history';
import { HomePage } from 'pages/home';
import { InDevelopmentPage } from 'pages/in-development';
import { IspringCoursesPage } from 'pages/ispring-courses';
import { LibraryPage } from 'pages/library';
import { LifePage } from 'pages/life';
import { LoginPage } from 'pages/login';
import { NewsPage } from 'pages/news';
import { NotfoundPage } from 'pages/notfound';
import { NotificationsPage } from 'pages/notifications';
import { OfficesPage } from 'pages/offices';
import { ProfilePage } from 'pages/profile';
import { ProfileReportsPage } from 'pages/profile-reports';
import { ProfileDashboardsPage } from 'pages/profile-dashboard';
import { RequestsPage } from 'pages/requests';
import { ReservationsPage } from 'pages/reservations';
import { SearchPage } from 'pages/search';
import { SurveyPage } from 'pages/survey';
import { SurveysPage } from 'pages/surveys';
import { TaskTrackerArchivePage } from 'pages/task-tracker-archive';
import { TaskTrackerPage } from 'pages/task-tracker';
import { TestPage } from 'pages/test';
import { UserPage } from 'pages/user';
import { VacanciesPage } from 'pages/vacancies';
import { VacancyPage } from 'pages/vacancy';
import { ValuesPage } from 'pages/values';

import './styles/.global.scss';

const RequireAuth = observer(({ children }: { children: React.ReactNode }) => {
    if (!ApplicationModule.isAuthorized) {
        return (
            <Navigate to={ROUTES.LOGIN() + `?url=${window.location.pathname}${window.location.search}`} replace/>
        );
    }
    return (
        <>{children}</>
    );
});

const AppRoutes = observer(() => {
    const navigate = useNavigate();

    const checkUserPersonalFieldsIsFilled = useCallback(async () => {
        const isNotifiedToday = Cookie.get('check_user_personal_fields_is_filled');
        if (isNotifiedToday) {
            return;
        }
        Cookie.set('check_user_personal_fields_is_filled', '1', { expires: 1 });
        const { data, isSuccess } = await userPersonalFieldsQuery();
        if (isSuccess && data) {
            const userPersonalFields = data.items.map(item => new UserPersonalFieldModel(item));
            const isFilled = userPersonalFields.every(item => item.isFilled);
            if (!isFilled) {
                const isConfirmed = await Notifier.confirm(
                    'Личная информация',
                    'У вас заполнены не все поля в личном кабинете.',
                    'Перейти'
                );
                if (isConfirmed) {
                    navigate(ROUTES.PROFILE());
                }
            }
        }
    }, [navigate]);

    useEffect(() => {
        if (ApplicationModule.isAuthorized) {
            checkUserPersonalFieldsIsFilled();
        }
    }, [checkUserPersonalFieldsIsFilled]);

    return (
        <Routes>
            <Route path={ROUTES.LOGIN()} element={<LoginPage/>}/>
            <Route path={ROUTES.ROOT()} element={<Navigate to={ROUTES.HOME()}/>}/>
            <Route path='*' element={
                <RequireAuth>
                    <Layout>
                        <Routes>
                            <Route path={ROUTES.ANNOUNCEMENT()} element={<AnnouncementPage/>}/>
                            <Route path={ROUTES.ANNOUNCEMENTS()} element={<AnnouncementsPage/>}/>
                            <Route path={ROUTES.ARTICLE()} element={<ArticlePage/>}/>
                            <Route path={ROUTES.ARTICLES()} element={<ArticlesPage/>}/>
                            <Route path={ROUTES.BOOKS()} element={<BooksPage/>}/>
                            <Route path={ROUTES.CAFE()} element={<CafePage/>}/>
                            <Route path={ROUTES.CALENDAR()} element={<CalendarPage/>}/>
                            <Route path={ROUTES.CHAT()} element={<ChatPage/>}/>
                            <Route path={ROUTES.CHATS()} element={<ChatsPage/>}/>
                            <Route path={ROUTES.COMPANY()} element={<CompanyPage/>}/>
                            <Route path={ROUTES.COMPANY_STRUCTURE()} element={<CompanyStructurePage/>}/>
                            <Route path={ROUTES.DEPARTMENT()} element={<DepartmentUnitPage/>}/>
                            <Route path={ROUTES.DEPARTMENT_BRANCH()} element={<DepartmentUnitPage/>}/>
                            <Route path={ROUTES.DOCUMENT_CATEGORIES()} element={<DocumentCategoriesPage/>}/>
                            <Route path={ROUTES.DOCUMENT_CATEGORY()} element={<DocumentCategoryPage/>}/>
                            <Route path={ROUTES.DOCUMENTS()} element={<DocumentsPage/>}/>
                            <Route path={ROUTES.EDUCATION()} element={<EducationPage/>}/>
                            <Route path={ROUTES.EDUCATION_LESSONS()} element={<EducationLessonsPage/>}/>
                            <Route path={ROUTES.ESSENTIAL()} element={<EssentialPage/>}/>
                            <Route path={ROUTES.ESSENTIALS()} element={<EssentialsPage/>}/>
                            <Route path={ROUTES.FAQ()} element={<FaqPage/>}/>
                            <Route path={ROUTES.GALLERY()} element={<GalleryPage/>}/>
                            <Route path={ROUTES.HISTORY()} element={<HistoryPage/>}/>
                            <Route path={ROUTES.HOME()} element={<HomePage/>}/>
                            <Route path={ROUTES.IN_DEVELOPMENT()} element={<InDevelopmentPage/>}/>
                            <Route path={ROUTES.ISPRING_COURSES()} element={<IspringCoursesPage/>}/>
                            <Route path={ROUTES.LIBRARY()} element={<LibraryPage/>}/>
                            <Route path={ROUTES.LIFE()} element={<LifePage/>}/>
                            <Route path={ROUTES.NEWS()} element={<NewsPage/>}/>
                            <Route path={ROUTES.NOTIFICATIONS()} element={<NotificationsPage/>}/>
                            <Route path={ROUTES.OFFICES()} element={<OfficesPage/>}/>
                            <Route path={ROUTES.PROFILE()} element={<ProfilePage/>}/>
                            <Route path={ROUTES.PROFILE_REPORTS()} element={<ProfileReportsPage/>}/>
                            <Route path={ROUTES.PROFILE_DASHBOARD()} element={<ProfileDashboardsPage/>}/>
                            <Route path={ROUTES.REQUESTS()} element={<RequestsPage/>}/>
                            <Route path={ROUTES.RESERVATIONS()} element={<ReservationsPage/>}/>
                            <Route path={ROUTES.SEARCH()} element={<SearchPage/>}/>
                            <Route path={ROUTES.SURVEY()} element={<SurveyPage/>}/>
                            <Route path={ROUTES.SURVEYS()} element={<SurveysPage/>}/>
                            <Route path={ROUTES.TASK_TRACKER()} element={<TaskTrackerPage/>}/>
                            <Route path={ROUTES.TASK_TRACKER_ARCHIVE()} element={<TaskTrackerArchivePage/>}/>
                            <Route path={ROUTES.TEST()} element={<TestPage/>}/>
                            <Route path={ROUTES.TEST_ID()} element={<TestPage/>}/>
                            <Route path={ROUTES.USER()} element={<UserPage/>}/>
                            <Route path={ROUTES.VACANCIES()} element={<VacanciesPage/>}/>
                            <Route path={ROUTES.VACANCY()} element={<VacancyPage/>}/>
                            <Route path={ROUTES.VALUES()} element={<ValuesPage/>}/>
                            <Route path={'*'} element={<NotfoundPage/>}/>
                        </Routes>
                    </Layout>
                </RequireAuth>
            }/>
        </Routes>
    );
});


export const App = observer(() => {
    const store = useStore(() => ({
        isLoading: true
    }));

    const initialize = useCallback(async () => {
        await ApplicationModule.initialize();
        await usersPingQuery();
        await wait(350);
        store.set("isLoading", false);
    }, [store]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    useEffect(() => {
        const interval = setInterval(usersPingQuery, 180000);
        return () => clearInterval(interval);
    }, []);

    const className = classnames('app', {
        // css анимации сломаны на iOS + Google chrome
        // https://github.com/michalsnik/aos/issues/690,
        'app--ios-chrome': Browser.isIos() && Browser.isChrome(),
    });

    if (store.isLoading) {
        return (
            <div className={className}>
                <div className='app-loading'>
                    <div className='app-loading__spinner'>
                        <UiLoadingSpinner height={240}/>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={className}>
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </div>
    );
});
