import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { ReservationModel } from "shared/models";

import './styles.scss';

interface Props {
    value: ReservationModel;
    onClear?: () => void
}

export const UiReservationPlaceSelection = observer(({ value: reservation, onClear }: Props) => {
    const style = {
        height: reservation.intervals.length * 32,
    };

    const className = classnames('ui-reservation-place-selection', {
        'ui-reservation-place-selection--single': reservation.intervals.length === 1,
    });

    return (
        <div className={className} style={style}>
            <div className="ui-reservation-place-selection__time">
                {reservation.time}
            </div>
            <div className="ui-reservation-place-selection__icon" onClick={onClear}>
                <UiIcon size={16} icon={ICONS.CLOSE} color={COLORS.WHITE}/>
            </div>
        </div>
    )
});
