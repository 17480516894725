import { action, runInAction } from 'mobx';

import { ObjectType, QueryType } from 'shared/types';
import { StoreWithFetchItem } from 'shared/mixins/store';

export default <S extends StoreWithFetchItem<any>>(query: QueryType, Store: S, Model: any) => {
    return action(async (params: ObjectType) => {
        Store.isLoading = true;
        Store.isSuccess = true;

        const { isSuccess, data } = await query(params);
        runInAction(() => {
            if (isSuccess && data) {
                Store.item = new Model(data.item);
            }
            Store.isLoading = false;
        });
    });
};
