import React, { useRef } from 'react';
import { observer } from 'mobx-react';

import { COLORS, ICONS } from "shared/constants";
import { useMedia, useOnClickOutside, useStore } from "shared/hooks";

import { UiIcon } from '../UiIcon';

import './styles.scss';

type ValueType = null | string | number | undefined;
type PropsType = {
    value: ValueType,
    defaultItem?: {
        id: number | string | null,
        name: string
    } | null,
    label?: string | React.ReactNode,
    items?: {
        id: ValueType,
        name: string
    }[],
    onChange: (data: { value: ValueType, name: string }) => void
}
export const UiFilter = observer((
    {
        value,
        label,
        defaultItem = {
            id: null,
            name: 'Все'
        },
        items = [],
        onChange
    }: PropsType
) => {
    const store = useStore(() => ({
        isOpened: false,
    }));
    const { isMobile } = useMedia();
    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        store.set("isOpened", false);
    });

    const handleToggle = () => {
        if (isMobile) {
            return;
        }
        store.set("isOpened", !store.isOpened)
    }

    const handleChange = (value: ValueType) => {
        store.set("isOpened", false);
        onChange({
            value: value,
            name: 'ui-filter'
        });
    }

    return (
        <div className='ui-filter' ref={ref}>
            <div className="ui-filter__inner" onClick={handleToggle}>
                <div className="ui-filter__label">
                    {!!label && label}
                    {!label && (
                        <>
                            <UiIcon icon={ICONS.FILTER2} size={16} color={COLORS.BROWN_1}/>
                            <span>Фильтры</span>
                        </>
                    )}
                </div>
                <select
                    value={value ? value : ''}
                    onChange={(e) => {
                        const value = e.target.value ? e.target.value : null;
                        handleChange(value);
                    }}
                >
                    {defaultItem && (
                        <option value={defaultItem.id ?? ''}>{defaultItem.name}</option>
                    )}
                    {items?.map(item => {
                        return (
                            <option key={item.id} value={item.id as string}>{item.name}</option>
                        )
                    })}
                </select>
            </div>
            {store.isOpened && (
                <div className="ui-filter__items">
                    {defaultItem && (
                        <div className="ui-filter-item" onClick={() => handleChange(null)}>
                            <div className="ui-filter-item__name">{defaultItem.name}</div>
                            {(value === defaultItem.id || !value) && (
                                <div className="ui-filter-item__icon">
                                    <UiIcon size={13} icon={ICONS.CHECK_CIRCLE}/>
                                </div>
                            )}
                        </div>
                    )}
                    {items?.map(item => {
                        return (
                            <div
                                key={item.id}
                                className="ui-filter-item"
                                onClick={() => handleChange(item.id)}
                            >
                                <div className="ui-filter-item__name">{item.name}</div>
                                {item.id === value && (
                                    <div className="ui-filter-item__icon">
                                        <UiIcon size={13} icon={ICONS.CHECK_CIRCLE}/>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
})
