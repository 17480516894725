import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiDataBoundary, UiFilter, UiGrid, UiIcon, UiLightbox } from 'shared/uikit';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { GalleryModel } from 'shared/models';
import { useMedia, useRouteParams, useStore, useUserWidgets } from "shared/hooks";
import { galleriesGetQuery } from "shared/queries";
import { CComments } from "shared/components";

import { GalleryFileCard } from "./components/GalleryFileCard";
import { GalleryFileTile } from "./components/GalleryFileTile";
import { GalleryFileTableRow } from "./components/GalleryFileTableRow";
import { GalleryFileGridCell } from "./components/GalleryFileGridCell";

import './styles.scss';

const views = [{
    id: 'cards',
    name: 'Крупная плитка'
}, {
    id: 'tiles',
    name: 'Мелкая плитка'
}, {
    id: 'table',
    name: 'Таблица'
}, {
    id: 'grid',
    name: 'Коллаж'
}]

export const GalleryPage = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        gallery: new GalleryModel(),
        lightboxIsOpened: false,
        lightboxIndex: 0,
        tab: 'all',
        view: 'grid',
        isSuccess: true,
        isLoading: true,
    }));

    const fetchGallery = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await galleriesGetQuery(routeParams.id);
        if (isSuccess && data) {
            store.set('gallery', new GalleryModel(data.item));
            ApplicationModule.setTitle(data.item.name);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store, routeParams.id]);

    useEffect(() => {
        ApplicationModule.setTitle('...');
    }, [])

    useEffect(() => {
        fetchGallery();
    }, [fetchGallery]);

    const { value: columns } = useMedia({
        is320: { tiles: 2, cards: 1 },
        is768: { tiles: 4, cards: 2 },
        is1024: { tiles: 6, cards: 3 },
        is1440: { tiles: 8, cards: 4 },
        is1920: { tiles: 10, cards: 4 },
        is2560: { tiles: 14, cards: 6 },
    });

    const handleOpenLightbox = (index: number) => () => {
        store.set('lightboxIndex', index);
        store.set('lightboxIsOpened', true);
    }

    const galleryFiles = store.gallery.galleryFiles.filter((galleryFile) => {
        if (store.tab === 'all') {
            return true;
        }
        if (store.tab === 'images') {
            return galleryFile.isImage;
        }
        if (store.tab === 'videos') {
            return !galleryFile.isImage;
        }
        return true;
    })

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={store.gallery.name || '...'} backTo={ROUTES.LIFE()}/>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess} onAction={fetchGallery}>
                    <div className="p-gallery__header">
                        <div className="p-gallery-tabs">
                            <div
                                className={classnames('p-gallery-tabs__item', { 'p-gallery-tabs__item--active': store.tab === 'all' })}
                                onClick={() => store.set("tab", 'all')}
                            >
                                <UiIcon icon={ICONS.IMAGES_AND_VIDEOS} size={32} color={COLORS.BLACK}/>
                                <span>Все файлы</span>
                            </div>
                            <div
                                className={classnames('p-gallery-tabs__item', { 'p-gallery-tabs__item--active': store.tab === 'images' })}
                                onClick={() => store.set("tab", 'images')}
                            >
                                <UiIcon icon={ICONS.IMAGES} size={24} color={COLORS.BLACK}/>
                                <span>Фото</span>
                            </div>
                            <div
                                className={classnames('p-gallery-tabs__item', { 'p-gallery-tabs__item--active': store.tab === 'videos' })}
                                onClick={() => store.set("tab", 'videos')}
                            >
                                <UiIcon icon={ICONS.VIDEOS} size={24} color={COLORS.BLACK}/>
                                <span>Видео</span>
                            </div>
                        </div>
                        <div className="p-gallery__filter">
                            <UiFilter
                                defaultItem={null}
                                items={views}
                                value={store.view}
                                onChange={({ value }) => store.set("view", value)}
                                label={<>
                                    <span>Вид</span>
                                    <UiIcon icon={ICONS.CHEVRON_DOWN} size={12} color={COLORS.BROWN_1}/>
                                </>}
                            />
                        </div>
                    </div>
                    <div className="p-gallery__items">
                        {store.view === 'cards' && (
                            <UiGrid columns={columns.cards} gap={16}>
                                {galleryFiles.map((galleryFile, index) => (
                                    <GalleryFileCard
                                        key={galleryFile.id}
                                        onClick={handleOpenLightbox(index)}
                                        galleryFile={galleryFile}
                                    />
                                ))}
                            </UiGrid>
                        )}
                        {store.view === 'tiles' && (
                            <UiGrid columns={columns.tiles} gap={16}>
                                {galleryFiles.map((galleryFile, index) => (
                                    <GalleryFileTile
                                        key={galleryFile.id}
                                        onClick={handleOpenLightbox(index)}
                                        galleryFile={galleryFile}
                                    />
                                ))}
                            </UiGrid>
                        )}
                        {store.view === 'table' && (
                            <table className='p-gallery__table'>
                                <tbody>
                                    {galleryFiles.map((galleryFile, index) => (
                                        <GalleryFileTableRow
                                            key={galleryFile.id}
                                            onClick={handleOpenLightbox(index)}
                                            galleryFile={galleryFile}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {store.view === 'grid' && (
                            <div className='p-gallery__grid'>
                                {galleryFiles.map((galleryFile, index) => (
                                    <GalleryFileGridCell
                                        key={galleryFile.id}
                                        onClick={handleOpenLightbox(index)}
                                        galleryFile={galleryFile}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </UiDataBoundary>
                <CComments entityId={store.gallery.id} entityType={'Gallery'}/>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
            <UiLightbox
                isOpened={store.lightboxIsOpened}
                index={store.lightboxIndex}
                items={galleryFiles.map(galleryFile => galleryFile.url)}
                onClose={() => store.set("lightboxIsOpened", false)}
            />
        </LayoutBody>
    );
})
