import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UserModel } from "shared/models";
import { UiAvatar, UiIcon, UiNavLink } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import './styles.scss';

type PropsType = {
    user: UserModel;
    isFramed?: boolean
    isManagement?: boolean,
}

export const UiUser = observer(({ user, isManagement = false, isFramed = false }: PropsType) => {
    const classNames = classnames('ui-user', {
        'ui-user--framed': isFramed
    });

    return (
        <UiNavLink to={ROUTES.USER(user.slug)} className={classNames} title={user.name}>
            <div className="ui-user__avatar">
                <UiAvatar
                    label={user.previewName}
                    image={user.imageAvatar}
                    size={50}
                    hasHighlight={!isFramed && isManagement}
                />
                <div className="ui-user__availability">
                    <UiIcon size={16} icon={user.isAvailable ? ICONS.AVAILABLE : ICONS.UNAVAILABLE}/>
                </div>
            </div>
            <div className="ui-user__inner">
                <div className="ui-user__header">
                    <div className='ui-user__name'>{user.previewName}</div>
                    {isManagement && (
                        <UiIcon size={21} color={COLORS.BROWN_1} icon={ICONS.EYE}/>
                    )}
                </div>
                {user.userPositionName && (
                    <div className='ui-user__position'>{user.userPositionName}</div>
                )}
            </div>
        </UiNavLink>
    );
});
