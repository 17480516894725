import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { ModelArrayCast, ModelCast } from "shared/casts";

import { IUserModel, UserModel } from "./UserModel";
import { IChatMessageAttachmentModel, ChatMessageAttachmentModel } from "./ChatMessageAttachmentModel";
import { Model } from './Model';
import { ChatMessageArticleModel, IChatMessageArticleModel } from "shared/models/ChatMessageArticleModel";

export interface IChatMessageModel {
    id?: number;
    chatRoomId: number;
    chatMessageId?: number | null;
    userId?: number;
    key?: string;
    content?: string | number;
    readAt?: string;
    createdAt?: string;
    deletedAt?: string | null;
    user?: IUserModel;
    chatMessage?: IChatMessageModel | null;
    chatMessageArticle?: IChatMessageArticleModel | null;
    chatMessageAttachments?: IChatMessageAttachmentModel[];
}

export class ChatMessageModel extends Model implements IChatMessageModel {
    casts = {
        user: new ModelCast(UserModel),
        chatMessage: new ModelCast(ChatMessageModel),
        chatMessageAttachments: new ModelArrayCast(ChatMessageAttachmentModel),
        chatMessageArticle: new ModelCast(ChatMessageArticleModel),
    }

    id = 0;
    content = '';
    createdAt = '';
    readAt = '';
    deletedAt = null;
    key = '';
    chatMessageId = null;
    chatMessage = null as ChatMessageModel | null;
    chatRoomId = 0;
    userId = 0;
    user: UserModel = new UserModel();
    chatMessageArticle: ChatMessageArticleModel | null = null;
    chatMessageAttachments: ChatMessageAttachmentModel[] = [];

    constructor(payload: IChatMessageModel = { chatRoomId: 0 }) {
        super();

        makeObservable(this, {
            id: observable,
            user: observable,
            key: observable,
            chatRoomId: observable,
            userId: observable,
            content: observable,
            readAt: observable,
            createdAt: observable,
            deletedAt: observable,
            chatMessageId: observable,
            chatMessage: observable,
            chatMessageAttachments: observable,
            status: computed,
            createdAtMoment: computed,
            update: action,
        });

        this.update(payload);
    }

    get createdAtMoment() {
        return moment(this.createdAt);
    }

    get status() {
        if (!this.id) {
            return 'pending';
        }

        if (this.readAt) {
            return 'read';
        }

        return 'sent';
    }
}
