import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentBanner, LayoutContentFilters } from 'shared/layout';
import { surveysQuery } from "shared/queries";
import { useMedia, useNavigate, useRouteParams, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { SurveyModel } from "shared/models";
import {
    UiArticleCard,
    UiDataBoundary,
    UiFilter,
    UiGrid,
    UiIcon,
    UiLikeAction,
    UiPagination,
    UiRepostAction,
    UiTabs,
    UiTags
} from "shared/uikit";
import { ApplicationModule } from "shared/modules";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { SurveyStatusEnum } from "shared/enums";

import './styles.scss';

// const types = [{
//     id: 'finished',
//     name: 'Пройденые',
// }, {
//     id: 'unfinished',
//     name: 'Не пройденые',
// }];

export const SurveysPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
        isAnonymous: null as null | string,
        statusId: null as null | string,
        orderBy: 'created_at'
    });
    const routeParams = useRouteParams<{ id?: string }>();

    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        surveys: [] as SurveyModel[],
    }));

    const fetchSurveys = useCallback(async () => {
        store.set('isLoading', true);
        store.set('isSuccess', true);
        const response = await surveysQuery({
            ...urlParams,
            ...routeParams
        });
        if (response.isSuccess && response.data) {
            store.set('surveys', response.data.items.map(item => new SurveyModel(item)));
        } else {
            store.set('isSuccess', false);
        }
        store.set('isLoading', false);

    }, [store, routeParams, urlParams]);

    useEffect(() => {
        ApplicationModule.setTitle('Опросы');
    }, []);

    useEffect(() => {
        fetchSurveys();
    }, [fetchSurveys]);

    const { value: columns } = useMedia({
        is320: 1,
        is768: 2,
        is1366: 2,
        is1440: 3,
        is1920: 4,
        is2560: 5
    });

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentBanner
                    image={require('./assets/banner.png')}
                    title={'Опросы Togas'}
                    description={'Мнение, взгляды сотрудников на актуальные темы жизни компании '}
                />
                <LayoutContentFilters>
                    <div>
                        <UiTabs
                            items={[
                                { id: null, name: 'Все' },
                                { id: 0, name: 'Публичные' },
                                { id: 1, name: 'Анонимные' },
                            ]}
                            value={urlParams.isAnonymous}
                            onChange={({ value }) => {
                                navigate(ROUTES.SURVEYS(), {
                                    ...urlParams,
                                    isAnonymous: value
                                }, {}, null);
                            }}
                        />
                    </div>
                    <div>
                        <UiFilter
                            items={SurveyStatusEnum.items}
                            value={urlParams.statusId}
                            onChange={({ value }) => {
                                navigate(ROUTES.SURVEYS(), {
                                    ...urlParams,
                                    statusId: value
                                }, {}, null);
                            }}
                        />
                        <UiFilter
                            defaultItem={{
                                id: 'created_at',
                                name: 'По дате'
                            }}
                            value={urlParams.orderBy}
                            items={[{
                                id: 'name',
                                name: 'По названию'
                            }]}
                            onChange={({ value }) => {
                                navigate(ROUTES.SURVEYS(), {
                                    ...urlParams,
                                    orderBy: value
                                }, {}, null);
                            }}
                            label={(
                                <>
                                    <span>Сортировка</span>
                                    <UiIcon icon={ICONS.CHEVRON_DOWN} size={12} color={COLORS.BROWN_1}/>
                                </>
                            )}
                        />
                    </div>
                </LayoutContentFilters>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.surveys.length > 0 && (
                        <UiGrid columns={columns}>
                            {store.surveys.map(survey => (
                                <UiArticleCard
                                    style={{ minHeight: 340 }}
                                    key={survey.id}
                                    date={survey.createdAt}
                                    name={survey.name}
                                    image={survey.image}
                                    href={ROUTES.SURVEY(survey.id)}
                                    tags={<UiTags items={survey.tags}/>}
                                    actions={<>
                                        <UiLikeAction
                                            value={survey.liked}
                                            count={survey.likesCount}
                                            entityType={'Survey'}
                                            entityId={survey.id}
                                        />
                                        <UiRepostAction
                                            count={survey.chatMessageArticlesCount}
                                            entityType={'Survey'}
                                            entityId={survey.id}
                                        />
                                    </>}
                                />
                            ))}
                        </UiGrid>
                    )}
                    {store.surveys.length === 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h2 className='typography'>По вашему запросу ничего не найдено</h2>
                        </div>
                    )}
                </UiDataBoundary>
                <UiPagination/>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    )
});
