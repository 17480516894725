import React from 'react';
import { observer } from 'mobx-react';

import { CafeCategoryModel } from 'shared/models';
import { useMedia } from "shared/hooks";

import { CafeMeal } from '../CafeMeal';

import './styles.scss';

type PropsType = {
    item: CafeCategoryModel;
}

export const CafeCategory = observer(({ item }: PropsType) => {
    const { isMobile } = useMedia();

    return (
        <div className='cafe-category'>
            <div className="cafe-category__inner">
                <div className="cafe-category__name">{item.name}</div>
                {isMobile && item.previewImage && (
                    <img alt='' className="cafe-category__image" src={item.previewImage}/>
                )}
                <div className="cafe-category__items">
                    {item.cafeMeals.map(item => (
                        <CafeMeal key={item.id} cafeMeal={item}/>
                    ))}
                </div>
            </div>
            {!isMobile && item.previewImage && (
                <img alt='' className="cafe-category__image" src={item.previewImage}/>
            )}
        </div>
    );
})
