import React from 'react';
import { observer } from 'mobx-react';

import { UiAvatar, UiMenuButton, UiNavLink } from 'shared/uikit';
import { ApplicationModule, LayoutModule } from 'shared/modules';
import { ROUTES } from 'shared/constants';
import { useNavigate } from "shared/hooks";

import './styles.scss';

export const LayoutHeaderMobile = observer(() => {
    const navigate = useNavigate();
    return (
        <div className='layout-header-mobile'>
            <UiMenuButton
                onClick={() => LayoutModule.menu.setOpened(!LayoutModule.menu.isOpened)}
                isActive={LayoutModule.menu.isOpened}
            />
            <UiNavLink to={ROUTES.HOME()} className='layout-header-mobile__logo' />
            <UiAvatar
                onClick={() => navigate(ROUTES.PROFILE())}
                size={32}
                label={ApplicationModule.user.previewName}
                image={ApplicationModule.user.imageAvatar}
            />
        </div>
    );
})
