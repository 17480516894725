import { BaseBroadcast } from "shared/broadcasts/BaseBroadcast";

type DataType = {
    id: number,
    recentActivityAt: string
}

export class UserRecentActivityUpdatedBroadcast extends BaseBroadcast {
    isPrivate = false;
    channel = 'global';
    event = 'user.recent.activity.updated';
    callback: (data: DataType) => void;

    constructor(callback: (data: DataType) => void) {
        super();
        this.setCallback(callback);
    }
}
