class Helpers {
    getReadableFileSize = (fileSizeInBytes: number) => {
        let i = -1;
        const byteUnits = [' Кб', ' Мб', ' Гб', ' Тб', 'Пб', 'Эб', 'Зб', 'Йб'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    };

    mutateHexColor = (hex: string, amt: number) => {
        let usePound = false;
        let col = hex;
        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }

        let num = parseInt(col, 16);

        let r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let b = ((num >> 8) & 0x00ff) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        let g = (num & 0x0000ff) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    };

    hexToRgbA = (hex: string, opacity = 1) => {
        let c;

        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            const r = ((c as any) >> 16) & 255;
            const g = ((c as any) >> 8) & 255;
            const b = (c as any) & 255;

            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        }

        return 'rgba(0, 0, 0, 1)';
    };
}

export default new Helpers();
