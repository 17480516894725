import React, { useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import lodash from "lodash";
import classnames from "classnames";

import { SpaceModel } from "shared/models/TaskTracker";
import { UiButton, UiIcon, UiScrollbar } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useNavigate, useOnClickOutside, useStore, useUrlParams } from "shared/hooks";
import { CContextMenu } from "shared/components/TaskTracker";
import { ApplicationModule } from "shared/modules";
import { PermissionEnum } from "shared/enums";

import './index.scss';

type PropsType = {
    value: number | string,
    items: SpaceModel[],
}

export const CSpaceSelect = observer(({ value, items }: PropsType) => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const store = useStore(() => ({
        isOpened: false,
    }));
    const urlParams = useUrlParams({});

    const isPersonal = useMemo(() => value === 'personal', [value]);

    useOnClickOutside(ref, () => {
        setTimeout(() => {
            store.set("isOpened", false);
        }, 250);
    })

    useEffect(() => {
        store.set("isOpened", false);
    }, [store, value]);

    const itemById = useMemo(() => lodash.keyBy(items, 'id'), [items]);

    const text = useMemo(() => {
        if (isPersonal) {
            return 'Моё пространство';
        }
        if (itemById[value]) {
            return itemById[value].name;
        }
        return 'Загрузка...';
    }, [itemById, value, isPersonal]);

    return (
        <div className={'c-tt-space-select'} ref={ref}>
            <div
                className="c-tt-space-select__control"
                onClick={() => store.set("isOpened", !store.isOpened)}
            >
                <span>{text}</span> <UiIcon icon={ICONS.CHEVRON_DOWN} size={18}/>
            </div>
            {store.isOpened && (
                <div className="c-tt-space-select__outer">
                    {ApplicationModule.user.can(PermissionEnum.AdminPanel) && (
                        <UiButton
                            onClick={() => navigate(null, { ...urlParams, saveSpaceId: 'new' })}
                            isSmall
                            label={'Добавить новое пространство'}
                            iconAfter={<UiIcon icon={ICONS.PLUS} size={12} color={COLORS.WHITE}/>}
                        />
                    )}
                    <div
                        className={classnames('c-tt-space-select-personal', {
                            'c-tt-space-select-personal--selected': isPersonal
                        })}
                        onClick={() => navigate(ROUTES.TASK_TRACKER('personal'), { ...urlParams })}
                    >
                        <span>Моё пространство</span>
                        {!isPersonal && (
                            <UiIcon icon={ICONS.CHECKLIST} size={19}/>
                        )}
                        {isPersonal && (
                            <UiIcon icon={ICONS.CHECK_CIRCLE} size={16}/>
                        )}
                    </div>
                    <div className="c-tt-space-select__items">
                        <UiScrollbar maxHeight={300}>
                            {items.map(space => {
                                return (
                                    <div
                                        key={space.id}
                                        onClick={() => navigate(ROUTES.TASK_TRACKER(space.id), { ...urlParams })}
                                        className={classnames('c-tt-space-select-item', {
                                            'c-tt-space-select-item--selected': !isPersonal && +space.id === +value
                                        })}
                                    >
                                        <div className="c-tt-space-select-item__name">{space.name}</div>
                                        <div className="c-tt-space-select-item__selected">
                                            <UiIcon icon={ICONS.CHECK_CIRCLE} size={12}/>
                                        </div>
                                        {space.canEdit(ApplicationModule.user) && (
                                            <div className="c-tt-space-select-item__actions">
                                                <div
                                                    className="c-tt-space-select-item__add"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        navigate(null, {
                                                            ...urlParams, saveBoardId: 'new',
                                                            spaceId: space.id
                                                        })
                                                    }}
                                                >
                                                    <UiIcon icon={ICONS.PLUS} size={14}/>
                                                </div>
                                                <CContextMenu
                                                    items={[[{
                                                        name: 'Редактировать',
                                                        action: () => {
                                                            navigate(null, { ...urlParams, saveSpaceId: space.id })
                                                        }
                                                    }]]}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </UiScrollbar>
                    </div>
                </div>
            )}
        </div>
    )
});

