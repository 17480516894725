import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Moment } from "moment/moment";

import { UiButton, UiCard, UiCardTitle, UiIcon, UiDataBoundary, UiCardSubtitle } from 'shared/uikit';
import { ICONS, ROUTES } from 'shared/constants';
import { useNavigate, useStore } from "shared/hooks";
import { CafeCategoryModel } from "shared/models";
import { cafeMealsQuery } from "shared/queries";

import './styles.scss';

export const WCafePreview = observer(() => {
    const navigate = useNavigate();

    const store = useStore(() => ({
        items: [] as CafeCategoryModel[],
        isLoading: true,
        isSuccess: false,
        isOpened: false,
    }));

    const fetchItems = useCallback(async () => {
        store.set('isLoading', true);
        store.set('isSuccess', false);
        const response = await cafeMealsQuery();
        if (response.isSuccess && response.data) {
            store.set('items', response.data.items.map(item => new CafeCategoryModel(item)));
            store.set('isSuccess', true);
        }
        store.set('isLoading', false);
    }, [store]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    let rendered = 0;
    let renderedMax = store.isOpened ? 999 : 11;

    const date = useMemo(() => {
        let date: Moment | null = null;
        store.items.forEach(cafeCategory => {
            cafeCategory.cafeMeals.forEach(cafeMeal => {
                if (!date || date.isBefore(cafeMeal.updatedAtMoment)) {
                    date = cafeMeal.updatedAtMoment.clone();
                }
            })
        })
        return date as Moment | null;
    }, [store.items]);

    return (
        <UiCard className='w-cafe-preview'>
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <UiCardTitle label='Столовая' collapsable={'WCafePreview'}/>
                {date && (
                    <UiCardSubtitle label={`Меню на ${date.format('D MMMM YYYY')}`}/>
                )}
                {store.items.map(cafeCategory => {
                    if (
                        cafeCategory.name.indexOf('Завтрак') > -1
                        && !store.isOpened
                        && store.items.length > 1
                    ) {
                        return null;
                    }
                    rendered++;
                    if (rendered >= renderedMax) {
                        return null;
                    }
                    return (
                        <div className='w-cafe-preview-category' key={cafeCategory.id}>
                            <div className='w-cafe-preview-category__header'>
                                <span>{cafeCategory.name}</span>
                                <span>Цена</span>
                            </div>
                            {cafeCategory.cafeMeals.map(cafeMeal => {
                                rendered++;
                                if (rendered >= renderedMax) {
                                    return null;
                                }
                                return (
                                    <div key={cafeMeal.id} className='w-cafe-preview-item'>
                                        <div className="w-cafe-preview-item__name">{cafeMeal.name}</div>
                                        <div className="w-cafe-preview-item__dots"></div>
                                        <div className="w-cafe-preview-item__price">{cafeMeal.price}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
                <div className="w-cafe-preview__footer">
                    <div className="w-cafe-preview__toggle">
                        <UiButton
                            iconAfter={<UiIcon icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}/>}
                            label={store.isOpened ? 'Свернуть' : 'Развернуть'}
                            isLink
                            onClick={() => store.set("isOpened", !store.isOpened)}
                            isTiny
                        />
                    </div>
                    <UiButton
                        label='Подробнее'
                        isLink
                        onClick={() => navigate(ROUTES.CAFE())}
                    />
                </div>
            </UiDataBoundary>
        </UiCard>
    );
});
