import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import {
    UiAddress,
    UiBackButton,
    UiButton,
    UiDataBoundary,
    UiDate,
    UiDropdown,
    UiFavorite,
    UiGoogleMap,
    UiHtml,
    UiGrid,
} from 'shared/uikit';
import { useAction, useReaction, useRouteParams, useUserWidgets } from 'shared/hooks';
import { OnChangeEventType } from 'shared/types';
import { ROUTES, COLORS } from 'shared/constants';
import { VacancyRespondModal } from 'shared/components';

import Service from './Service';
import Store from './Store';

import './styles.scss';

export const VacancyPage = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();

    useReaction(
        () => Store.item.name,
        () => {
            ApplicationModule.setTitle(Store.item.name || 'Вакансии');
        }
    );

    useEffect(() => {
        (async function () {
            await Service.fetchItem(routeParams);
            window.scrollTo(0, 0);
        })();
    }, [routeParams]);

    const handleUpdate = ({ name, value }: OnChangeEventType<number>) => {
        Store.item.update({ [name]: value });
    };

    const handleModalShow = useAction(() => {
        Store.modalIsOpened = true;
    });

    const handleModalHide = useAction(() => {
        Store.modalIsOpened = false;
    });

    return (
        <LayoutBody title='Вакансии'>
            <LayoutContent className='vacancy-page'>
                <UiDataBoundary isLoading={Store.isLoading} isError={!Store.isSuccess}>
                    <UiGrid columns={1} style={{maxWidth: 800}}>
                        <div className='detail-page__back'>
                            <UiBackButton to={ROUTES.VACANCIES()} label='Назад к вакансиям'/>
                        </div>
                        <div className='typography'>
                            <h1 className='vacancy-page__title'>{Store.item.name}</h1>
                            <div className='vacancy-page__header'>
                                <UiDate value={Store.item.createdAt}/>
                                <UiAddress
                                    color={Store.item.addressColor}
                                    address={Store.item.addressPreview}
                                    city={Store.item.vacancyRegion?.name}
                                />
                            </div>
                            <div className='vacancy-page-actions'>
                                <UiButton
                                    className='vacancy-page-actions__button--wide'
                                    isSmall
                                    label='Откликнуться'
                                    onClick={handleModalShow}
                                />
                                <UiDropdown
                                    className='vacancy-page-actions__button'
                                    control={<UiButton isSmall label='Показать контакты' isOutline isTranslucent/>}
                                    label={Store.item.contactName}
                                >
                                    {Store.item.contactInfo && (
                                        <div className='vacancy-page-contacts__info'>{Store.item.contactInfo}</div>
                                    )}
                                    {Store.item.contactEmail && (
                                        <a
                                            href={`mailto:${Store.item.contactEmail}`}
                                            className='vacancy-page-contacts__email'
                                        >
                                            {Store.item.contactEmail}
                                        </a>
                                    )}
                                </UiDropdown>
                                <UiFavorite
                                    buttonProps={{
                                        isSmall: true,
                                        isTranslucent: true,
                                        isOutline: true,
                                    }}
                                    color={COLORS.GRAY_3}
                                    entityId={Store.item.id}
                                    entityType='Vacancy'
                                    value={Store.item.favorited}
                                    onChange={handleUpdate}
                                />
                            </div>
                            <UiHtml value={Store.item.detailText}/>
                            {(Store.item.contactName || Store.item.contactEmail || Store.item.contactInfo) && (
                                <div className='vacancy-page-contacts'>
                                    <h3>Контактная информация</h3>
                                    {Store.item.contactName && (
                                        <div className='vacancy-page-contacts__name'>{Store.item.contactName}</div>
                                    )}
                                    {Store.item.contactInfo && (
                                        <div className='vacancy-page-contacts__info'>{Store.item.contactInfo}</div>
                                    )}
                                    {Store.item.contactEmail && (
                                        <a
                                            href={`mailto:${Store.item.contactEmail}`}
                                            className='vacancy-page-contacts__email'
                                        >
                                            {Store.item.contactEmail}
                                        </a>
                                    )}
                                </div>
                            )}
                            <div className='vacancy-page-actions'>
                                <UiButton
                                    className='vacancy-page-actions__button'
                                    isSmall
                                    label='Откликнуться'
                                    onClick={handleModalShow}
                                />
                                <UiFavorite
                                    buttonProps={{
                                        isSmall: true,
                                        isTranslucent: true,
                                        isOutline: true,
                                    }}
                                    color={COLORS.GRAY_3}
                                    entityId={Store.item.id}
                                    entityType='Vacancy'
                                    value={Store.item.favorited}
                                    onChange={handleUpdate}
                                />
                            </div>
                            {(Store.item.addressPreview || Store.item.vacancyRegion) && (
                                <>
                                    <h3>Адрес</h3>
                                    <UiAddress
                                        address={Store.item.addressPreview}
                                        color={Store.item.addressColor}
                                        city={Store.item.vacancyRegion?.name}
                                    />
                                </>
                            )}
                            {Store.item?.addressLatLng && (
                                <UiGoogleMap center={Store.item.addressLatLng} markers={[Store.item.addressLatLng]}/>
                            )}
                        </div>
                    </UiGrid>
                </UiDataBoundary>
                <VacancyRespondModal
                    isOpened={Store.modalIsOpened}
                    vacancyId={routeParams.id}
                    onClose={handleModalHide}
                />
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    );
});
