import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { action } from "mobx";
import moment, { Moment } from "moment";
import classnames from "classnames";

import { UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { OnChangeHandlerType } from "shared/types";

import './styles.scss';

type PropsType = {
    value?: string | null;
    name?: string;
    format?: string;
    style?: React.CSSProperties;
    onChange?: OnChangeHandlerType<string>;
}

export const UiCalendar = observer(({ value, onChange, name = 'date', format = 'Y-MM-DD', style }: PropsType) => {
    const store = useLocalObservable(() => ({
        value: moment(),
        month: moment(),
        today: moment(),
        get days() {
            let date = this.month.clone().startOf('month').startOf('week');
            const to = this.month.clone().endOf('month').endOf('week');

            const days = [];
            while (date.isBefore(to)) {
                days.push(date.clone());
                date = date.add(1, 'days');
            }
            return days;
        }
    }));

    useEffect(() => {
        const momentValue = value ? moment(value) : moment();
        action(() => {
            store.value = momentValue;
            store.month = momentValue.clone();
        })();
    }, [value, store])

    const handleClick = (date: Moment) => {
        onChange && onChange({
            name,
            value: store.value.clone()
                .set('year', date.year())
                .set('month', date.month())
                .set('date', date.date())
                .format(format)
        });
    }

    const handleMonthSub = () => {
        action(() => {
            store.month = store.month.subtract(1, 'month').clone()
        })();
    }

    const handleMonthAdd = () => {
        action(() => {
            store.month = store.month.add(1, 'month').clone()
        })();
    }

    return (
        <div className='ui-calendar' style={style}>
            <div className="ui-calendar__header">
                <div className="ui-calendar__button" onClick={handleMonthSub}>
                    <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.CHEVRON_LEFT}/>
                </div>
                <div className="ui-calendar__title">{store.month.format('MMMM YYYY')}</div>
                <div className="ui-calendar__button" onClick={handleMonthAdd}>
                    <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.CHEVRON_RIGHT}/>
                </div>
            </div>
            <div className="ui-calendar__content">
                <div className="ui-calendar__week">
                    <span>Пн</span>
                    <span>Вт</span>
                    <span>Ср</span>
                    <span>Чт</span>
                    <span>Пт</span>
                    <span>Сб</span>
                    <span>Вс</span>
                </div>
                {store.days.map(day => {
                    const className = classnames('ui-calendar__day', {
                        'ui-calendar__day--active': day.month() === store.value.month() && day.date() === store.value.date(),
                        'ui-calendar__day--today': day.month() === store.today.month() && day.date() === store.today.date(),
                        'ui-calendar__day--weekend': day.day() === 6 || day.day() === 0,
                        'ui-calendar__day--outer': day.month() !== store.month.month(),
                    });
                    return (
                        <div className={className} key={day.format('Y-M-D')} onClick={() => handleClick(day)}>
                            <span>{day.format('D')}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})
