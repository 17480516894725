import { makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';
import { SurveyQuestionAnswerModel } from "./SurveyQuestionAnswerModel";
import { IUserSurveyQuestionAnswerModel, UserSurveyQuestionAnswerModel } from "./UserSurveyQuestionAnswerModel";

export interface ISurveyQuestionModel {
    id?: number;
    name?: string;
    image?: number | null;
    surveyId?: number;
    isMultiple?: number;
    surveyQuestionAnswers?: SurveyQuestionAnswerModel[],
    userSurveyQuestionAnswer?: IUserSurveyQuestionAnswerModel[];
    userSurveyQuestionAnswersCount?: number,
}

export class SurveyQuestionModel extends Model implements ISurveyQuestionModel {
    casts = {
        surveyQuestionAnswers: new ModelArrayCast(SurveyQuestionAnswerModel),
        userSurveyQuestionAnswer: new ModelArrayCast(UserSurveyQuestionAnswerModel)
    };

    id = 0;
    name = '';
    surveyId = 0;
    isMultiple = 0;
    image: number | null = null;
    surveyQuestionAnswers: SurveyQuestionAnswerModel[] = [];
    userSurveyQuestionAnswer: UserSurveyQuestionAnswerModel[] = [];
    userSurveyQuestionAnswersCount: 0

    constructor(payload: ISurveyQuestionModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            image: observable,
            isMultiple: observable,
            surveyQuestionAnswers: observable,
            userSurveyQuestionAnswer: observable,
            userSurveyQuestionAnswersCount: observable,
        });

        this.update(payload);
    }
}
