import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import './styles.scss';

interface Props {
    isActive?: boolean;
    className?: string;
    onClick?: () => void;
    children?: React.ReactNode
}

class UiCardItem extends React.Component<Props> {
    render() {
        const { children, className, isActive, onClick } = this.props;

        const cardItemClassName = classnames('ui-card-item', className, {
            [`${className}--active`]: isActive,
        });

        return (
            <div className={cardItemClassName} onClick={onClick}>
                {children}
            </div>
        );
    }
}

export default observer(UiCardItem);
