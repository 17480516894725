import { makeQuery } from 'shared/utilities';
import { IArticleModel } from 'shared/models';

type RequestType = {
    excludeId: number;
}

type ResponseType = {
    items: IArticleModel[];
};

export const articlesOtherQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/articles/other', { params });
}
