import { makeQuery } from 'shared/utilities';

type RequestType = {
    action?: 'restore' | 'delete',
    id?: number[]
}

type ResponseType = {};

export const tasksBatchQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks/batch', {
        prefix: 'task-tracker',
        json: request
    });
}
