import { makeQuery } from 'shared/utilities';
import { IEducationLessonModel } from 'shared/models';

type RequestType = {
    typeId?: string | null;
    tagId?: string | number | null
}

type ResponseType = {
    items: IEducationLessonModel[];
};

export const educationLessonsQuery = async function (params: RequestType = {}) {
    return await makeQuery<ResponseType>('/education-lessons', { params });
}
