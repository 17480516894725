import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { getBackendUrl, getBackendHost, getBroadcastPort } from "shared/utilities";

type BroadcastType = {
    event: string,
    isPrivate?: boolean | null | undefined,
    channel?: string | null | undefined,
    callback: (data: any) => void
}

class BroadcastModuleClass {
    client: Echo | null = null;
    userId: number = 0;
    channel: string = '';
    accessToken: string | null = null;

    disconnect = () => {
        if (this.client) {
            this.client.leave(this.channel);
            this.client.disconnect();
            this.client = null;
        }
    }

    connect = (userId: number, accessToken: string | null) => {
        this.disconnect();
        this.userId = userId;
        this.channel = `user.${userId}`;
        this.accessToken = accessToken;

        this.client = new Echo({
            client: new Pusher('LgvHFvn1A9yVhVsDH8LgtJVvLpfk', {
                wsHost: getBackendHost(),
                wsPort: getBroadcastPort(),
                forceTLS: false,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
                authEndpoint: getBackendUrl('/api/v1/broadcasting/auth'),
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + this.accessToken
                    }
                }
            }),
            broadcaster: 'pusher',
            encrypted: true,
        });
    }

    subscribe = (broadcast: BroadcastType) => {
        if (broadcast.isPrivate) {
            this.client?.private(broadcast.channel || this.channel).listen(`.${broadcast.event}`, broadcast.callback);
        } else {
            this.client?.channel(broadcast.channel || this.channel).listen(`.${broadcast.event}`, broadcast.callback);
        }
    }

    unsubscribe = (broadcast: BroadcastType) => {
        if (broadcast.isPrivate) {
            this.client?.private(broadcast.channel || this.channel).stopListening(`.${broadcast.event}`, broadcast.callback);
        } else {
            this.client?.channel(broadcast.channel || this.channel).stopListening(`.${broadcast.event}`, broadcast.callback);
        }
    }
}

export const BroadcastModule = new BroadcastModuleClass();
