import { makeObservable, observable } from 'mobx';
import { Form } from 'shared/utilities';

export default new (class Store {
    form = new Form(
        {
            username: '',
            description: '',
            vacancyId: 0,
            tmpFileId: null as number | null,
        },
        {
            username: Form.rules(Form.RULE.REQUIRED()),
            description: Form.rules(Form.RULE.REQUIRED()),
        }
    );

    constructor() {
        makeObservable(this, {
            form: observable,
        });
    }
})();
