import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import lodash from 'lodash';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useMedia, useNavigate, useRouteParams, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { DocumentCategoryModel, DocumentModel } from "shared/models";
import { documentCategoriesGetQuery, documentsQuery } from "shared/queries";
import { UiDataBoundary, UiDocument, UiGrid, UiViewType } from "shared/uikit";

import './styles.scss';

export const DocumentCategoryPage = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
    });
    const store = useStore(() => ({
        isLoading: false,
        isSuccess: false,
        item: null as null | DocumentCategoryModel,
        items: [] as DocumentModel[],
        viewType: 'card'
    }));

    const fetchItem = useCallback(async () => {
        const { isSuccess, data } = await documentCategoriesGetQuery(routeParams.id);
        if (isSuccess && data) {
            store.set("item", new DocumentCategoryModel(data.item));
        }
    }, [store, routeParams]);

    const fetchItems = useCallback(async () => {
        store.set("isLoading", true);
        const { isSuccess, data } = await documentsQuery({
            ...urlParams,
            documentCategoryId: routeParams.id
        });
        if (isSuccess && data) {
            store.set("items", data.items.map(item => new DocumentModel(item)));
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
    }, [store, urlParams, routeParams.id]);

    const { value: columns, isMobile } = useMedia({
        is320: 1,
        is768: 2,
        is1024: 3,
        is1280: 4,
    });

    useEffect(() => {
        fetchItem();
    }, [fetchItem]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    useEffect(() => {
        if (isMobile) {
            store.set("viewType", 'card');
        }
    }, [isMobile, store])

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle
                    title={store.item?.name || '...'}
                    backTo={() => {
                        navigate(-1);
                    }}
                >
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.viewType === 'card' && (
                        <UiGrid columns={1} style={{maxWidth: 1152}}>
                            {lodash.chunk(store.items, columns).map((row, index) => (
                                <div key={index} className='document-category-page__row'>
                                    <UiGrid style={{maxWidth: 1152}} columns={columns} gap={[26, 0]}>
                                        {row.map(item => (
                                            <UiDocument
                                                key={item.id}
                                                item={item}
                                                viewType={store.viewType}
                                            />
                                        ))}
                                    </UiGrid>
                                </div>
                            ))}
                        </UiGrid>
                    )}
                    {store.viewType === 'list' && (
                        <UiGrid columns={1} style={{maxWidth: 1152}}>
                            {store.items.map((item) => (
                                <UiDocument
                                    key={item.id}
                                    item={item}
                                    viewType={store.viewType}
                                />
                            ))}
                        </UiGrid>
                    )}

                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
})
