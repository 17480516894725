import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { galleriesQuery, tagsQuery } from "shared/queries";
import { useMedia, useNavigate, useStore, useUrlParams } from "shared/hooks";
import { GalleryModel, TagModel } from "shared/models";
import { UiDataBoundary, UiGrid, UiPagination, UiSearchInput, UiTagsSelect } from "shared/uikit";
import { ROUTES } from "shared/constants";
import { CGallerySlider } from "shared/components";
import { WAnnouncements, WSocialNetworks } from "shared/widgets";

import { GalleryItem } from "./components/GalleryItem";

import './styles.scss';

export const LifePage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
        query: undefined as undefined | string,
        tagId: undefined as undefined | string,
    });

    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        pages: 1,
        galleries: [] as GalleryModel[],
        galleriesMain: [] as GalleryModel[],
        tags: [] as TagModel[],
    }));

    const { value: columns } = useMedia({
        is320: 1,
        is1024: 2,
        is1280: 3,
        is1920: 4,
        is2560: 5
    });

    useEffect(() => {
        (async () => {
            const response = await tagsQuery({
                has: ['galleries']
            });
            if (response.isSuccess && response.data) {
                store.set('tags', response.data.items.map(item => new TagModel(item)));
            } else {
                store.set('isSuccess', false);
            }
            store.set('isLoading', false);
        })();
    }, [store]);

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', true);
            const { data, isSuccess } = await galleriesQuery({
                ...urlParams,
            });
            if (isSuccess && data) {
                store.set('galleries', data.items.map(item => new GalleryModel(item)));
                store.set('pages', data.pages);
            } else {
                store.set('isSuccess', false);
            }
            store.set('isLoading', false);
        })();
    }, [store, urlParams]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Togas Life'/>
                <CGallerySlider/>
                <div className="p-life__subtitle">Медиа-галерея</div>
                <div className="p-life__search">
                    <UiSearchInput
                        placeholder='Поиск'
                        style={{
                            maxWidth: 550
                        }}
                        value={urlParams.query}
                        onChange={({ value }) => {
                            navigate(ROUTES.LIFE(), {
                                ...urlParams,
                                page: 1,
                                query: value
                            }, {}, null)
                        }}
                    />
                </div>
                {store.tags.length > 0 && (
                    <div className="p-life__tags">
                        <UiTagsSelect
                            items={store.tags}
                            value={urlParams.tagId}
                            onChange={({ value }) => {
                                navigate(ROUTES.LIFE(), { tagId: value }, {}, null);
                            }}
                        />
                    </div>
                )}
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <UiGrid columns={columns} gap={[32, 54]} style={{marginBottom: 24}}>
                        {store.galleries.map(gallery => <GalleryItem key={gallery.id} gallery={gallery}/>)}
                    </UiGrid>
                    {store.galleries.length === 0 && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <h2 className='typography'>По вашему запросу ничего не найдено</h2>
                        </div>
                    )}
                </UiDataBoundary>
                <UiPagination
                    page={urlParams.page}
                    pages={store.pages}
                    onChange={(data) => {
                        navigate(ROUTES.LIFE(), {
                            ...urlParams,
                            page: data.value
                        }, {}, null)
                    }}
                />
            </LayoutContent>
            <LayoutAside widgets={[{
                id: 'WSocialNetworks',
                name: 'Наши социальные сети',
                Widget: WSocialNetworks
            }, {
                id: 'WAnnouncements',
                name: 'Объявления Togas',
                Widget: WAnnouncements
            }]}/>
        </LayoutBody>
    )
});
