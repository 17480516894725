import { action, makeObservable, observable, runInAction } from 'mobx';
import validator from 'validator';
import { ObjectType } from 'shared/types';

interface IValidator {
    (value: any): boolean;
}

class FormRule {
    validate: IValidator;
    errorMessage = '';

    constructor(errorMessage: string, validate: IValidator) {
        this.errorMessage = errorMessage;
        this.validate = validate;
    }
}

class FormRules {
    rules: FormRule[] = [];
    isValid = true;
    errorMessage = '';

    constructor(rules: FormRule[] = []) {
        this.rules = rules;

        makeObservable(this, {
            rules: observable,
            errorMessage: observable,
        });
    }

    validate = (value: any) => {
        this.errorMessage = '';
        this.isValid = true;

        this.rules.forEach(rule => {
            if (!rule.validate(value)) {
                this.isValid = false;
                this.errorMessage = rule.errorMessage;
            }
        });

        return {
            isValid: this.isValid,
            errorMessage: this.errorMessage,
        };
    };
}

class Form<DefaultValues extends ObjectType, Validations extends ObjectType> {
    static rules = (...rules: FormRule[]) => new FormRules(rules);
    static RULE = {
        REQUIRED: (errorMessage = 'Поле обязательно для ввода') =>
            new FormRule(errorMessage, value => {
                return !!value;
            }),
        EMAIL: (errorMessage = 'Неправильный формат email') =>
            new FormRule(errorMessage, value => {
                return validator.isEmail(value);
            }),
    };

    private isValidatedBefore = false;
    private defaultValues: DefaultValues;
    isLoading = false;
    errorMessage = '';
    isValid = false;
    values: DefaultValues;
    validations: Validations;

    constructor(values: DefaultValues, validation: Validations = {} as Validations) {
        this.defaultValues = { ...values };
        this.values = { ...values };
        this.validations = validation || {};

        makeObservable(this, {
            values: observable,
            validations: observable,
            isLoading: observable,
            errorMessage: observable,
            isValid: observable,
            setLoading: action,
            set: action,
            reset: action,
            validate: action,
            setErrorMessage: action,
        });
    }

    setLoading = (isLoading: boolean = true) => {
        this.isLoading = isLoading;
        return this;
    };

    setErrorMessage = (errorMessage: string = '') => {
        this.errorMessage = errorMessage;
        return this;
    };

    set = (key: string, value: any) => {
        (this.values as ObjectType)[key] = value;

        if (this.isValidatedBefore) {
            this.validate();
        }
    };

    private _validate = () => {
        if (!this.validations) {
            return true;
        }

        runInAction(() => {
            this.isValid = true;
            this.errorMessage = '';
            for (const [key, validationRules] of Object.entries(this.validations)) {
                if (this.validations.hasOwnProperty(key) && this.values.hasOwnProperty(key)) {
                    const validation = (validationRules as FormRules).validate(this.values[key]);
                    if (!validation.isValid) {
                        this.isValid = false;
                    }
                }
            }
        });

        return this.isValid;
    };

    validate = () => {
        this.isValidatedBefore = true;
        this.setErrorMessage();
        return this._validate();
    };

    reset = () => {
        this.values = { ...this.defaultValues };
    };
}

export default Form;
