import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from "shared/hooks";

import './index.scss';

type PropsType = {
    value: number
}

export const Year = observer(({ value }: PropsType) => {
    const store = useStore(() => ({
        value: 0,
        speed: 60,
        velocity: 1.2,
        timeout: 0
    }))

    const animate = (newValue: number) => {
        clearTimeout(store.timeout);
        if (+store.value === +newValue) {
            return;
        }

        const mod = newValue > store.value ? 1 : -1;
        store.set("value", +store.value + +mod);
        store.set("speed", store.speed * store.velocity);
        const newVelocity = store.velocity * 0.985;
        store.set("velocity",newVelocity > 1.005 ? newVelocity : 1.005);
        store.set("timeout", setTimeout(() => animate(newValue), store.speed));
    }

    useEffect(() => {
        clearTimeout(store.timeout);
        if (store.value === 0) {
            store.set("value", value);
        } else {
            store.set("velocity", 1.2);
            store.set("speed", 200 / Math.abs(value - store.value))
            animate(+value);
        }
    // eslint-disable-next-line
    }, [value, store]);

    return (
        <div className='p-history-year'>
            {store.value}-
        </div>
    );
});

