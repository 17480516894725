import { makeQuery } from 'shared/utilities';
import { CollectionItemType } from "shared/types";

type ResponseType = {
    isManager: boolean,
    saleProducts: CollectionItemType<{productCategoryId: number}>[],
    saleProductCategories: CollectionItemType[],
    saleRegions: CollectionItemType[],
    saleStores: CollectionItemType<{ saleRegionId: number }>[],
    saleSellers: CollectionItemType<{ saleStoreId: number }>[],
};

export const salesFiltersQuery = async function () {
    return await makeQuery<ResponseType>('/sales/filters');
}
