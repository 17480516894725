import React, { useEffect } from 'react';
import { observer } from 'mobx-react';


import {
    UiButton,
    UiForm,
    UiFormControl,
    UiGrid,
    UiImagesUpload,
    UiInput,
    UiModal, UiModalClose,
    UiModalTitle,
    UiSelectInline,
    UiTextarea
} from 'shared/uikit';
import { useStore, useValidation } from "shared/hooks";
import { AnnouncementCategoryModel } from "shared/models";
import { announcementCategoriesQuery, announcementsCreateQuery } from "shared/queries";
import { Notifier } from "shared/utilities";

import './styles.scss';

export const AnnouncementCreate = observer(() => {
    const store = useStore(() => ({
        isOpened: false,
        isSubmitting: false,
        isSubmitted: false,
        announcementCategories: [] as AnnouncementCategoryModel[]
    }));

    const form = useStore(() => ({
        name: '',
        detailText: '',
        announcementCategoryId: null as null | number,
        images: [] as File[]
    }));

    useEffect(() => {
        (async () => {
            const { data, isSuccess } = await announcementCategoriesQuery();
            if (data && isSuccess) {
                store.set("announcementCategories", data.items.map(item => new AnnouncementCategoryModel(item)));
                form.set('announcementCategoryId', store.announcementCategories[0]?.id || null);
            }
        })();
    }, [store, form]);

    const validation = useValidation(form, (rules) => ({
        name: rules.required(),
        detailText: rules.required(),
    }));

    const handleSubmit = async () => {
        store.set("isSubmitted", true);

        if (store.isSubmitting) {
            return;
        }
        store.set("isSubmitting", true);
        await announcementsCreateQuery(form);
        store.set("isSubmitting", false);
        handleClose();
        Notifier.alert('Размещение объявления', 'Ваше объявление отправлено на рассмотрение');
    }

    const handleClose = () => {
        if (store.isSubmitting) {
            return;
        }
        store.set('isOpened', false);
    }

    return (
        <>
            <UiButton
                className={'announcement-create__button'}
                label={'Разместить объявление'}
                onClick={() => store.set("isOpened", true)}
            />
            <UiModal isOpened={store.isOpened} onClose={handleClose}>
                <UiModalTitle title='Разместить новое объявление'/>
                <UiForm onSubmit={handleSubmit}>
                    <UiGrid columns={1} gap={24}>
                        <UiFormControl
                            label={'Название'}
                            isRequired
                            errorMessage={validation.name.errorMessage}
                            isSubmitted={store.isSubmitted}
                        >
                            <UiInput
                                value={form.name}
                                name='name'
                                onChange={form.handleChange}
                                placeholder='Введите название объявления'
                            />
                        </UiFormControl>
                        <UiSelectInline
                            items={store.announcementCategories}
                            value={form.announcementCategoryId}
                            name={'announcementCategoryId'}
                            onChange={form.handleChange}
                        />
                        <UiFormControl
                            label={'Описание'}
                            isRequired
                            errorMessage={validation.detailText.errorMessage}
                            isSubmitted={store.isSubmitted}
                        >
                            <UiTextarea
                                placeholder='Введите описание'
                                value={form.detailText}
                                name={'detailText'}
                                onChange={form.handleChange}
                            />
                        </UiFormControl>
                        <UiImagesUpload name='images' onChange={form.handleChange} value={form.images}/>
                        <div className="ui-modal__actions">
                            <UiButton label={'Разместить'} isSubmit isLoading={store.isSubmitting}/>
                            <UiButton isOutline label={'Отмена'} onClick={handleClose}/>
                        </div>
                    </UiGrid>
                </UiForm>
            </UiModal>
        </>
    );
})

