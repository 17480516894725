import { action, makeObservable, observable } from 'mobx';

const w320 = 0;
const w728 = 728;
const w1024 = 1024;
const w1280 = 1280;
const w1366 = 1366;
const w1440 = 1440;
const w1920 = 1920;
const w2560 = 2560;
const wMax = 999999;

export default new (class Resolution {
    private isActive(from: number, to: number = wMax) {
        return from <= this.width && this.width < to;
    }

    get isMobileOrTablet() {
        return this.isActive(w320, w1024);
    }

    get isComputer() {
        return this.isActive(w1024);
    }

    get isMobile() {
        return this.isActive(w320, w728);
    }

    get isTablet() {
        return this.isActive(w728, w1024);
    }

    get isLaptop() {
        return this.isActive(w1024, w1920);
    }

    get isDesktop() {
        return this.isActive(w1920);
    }

    get is320() {
        return this.isActive(w320, w728);
    }

    get is728() {
        return this.isActive(w728, w1024);
    }

    get is1024() {
        return this.isActive(w1024, w1280);
    }

    get is1280() {
        return this.isActive(w1280, w1366);
    }

    get is1366() {
        return this.isActive(w1366, w1440);
    }

    get is1440() {
        return this.isActive(w1440, w1920);
    }

    get is1920() {
        return this.isActive(w1920, w2560);
    }

    get is2560() {
        return this.isActive(w2560);
    }

    width = window.innerWidth;

    constructor() {
        makeObservable(this, {
            width: observable,
            handleWindowResize: action,
        });

        window.removeEventListener('resize', this.handleWindowResize, false);
        window.addEventListener('resize', this.handleWindowResize, false);
    }

    handleWindowResize = () => {
        this.width = window.innerWidth;
    };
})();
