import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { COLORS, ICONS } from 'shared/constants';
import { OnChangeHandlerType } from 'shared/types';

import { UiIcon } from '../UiIcon';

import './styles.scss';

interface Props {
    label: string;
    style: React.CSSProperties;
    value: number;
    name: string;
    onChange: OnChangeHandlerType<number>;
}

class UiCheckbox extends React.Component<Props> {
    static defaultProps: Props = {
        label: '',
        style: {},
        value: 0,
        name: 'ui-checkbox',
        onChange: () => {},
    };

    handleClick = () => {
        const { value, onChange, name } = this.props;

        onChange({
            control: 'ui-checkbox',
            name,
            value: value === 0 ? 1 : 0,
        });
    };

    render() {
        const { label, style, value } = this.props;

        const className = classnames('ui-checkbox', {
            'ui-checkbox--active': value,
        });

        return (
            <div className={className} style={style} onClick={this.handleClick}>
                {value === 1 ? (
                    <UiIcon size={15} icon={ICONS.CHECKBOX_CHECKED} color={COLORS.BLACK} />
                ) : (
                    <UiIcon size={15} icon={ICONS.CHECKBOX_UNCHECKED} color={COLORS.GRAY_3} />
                )}
                {!! label && (
                    <span>{label}</span>
                )}
            </div>
        );
    }
}

export default observer(UiCheckbox);
