import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import lodash from "lodash";

import { ChatRoomModel } from "shared/models";
import { ROUTES } from "shared/constants";
import { UiAvatar, UiNavLink } from "shared/uikit";
import { useBroadcast, useRouteParams, useStore } from "shared/hooks";
import { chatRoomsQuery } from "shared/queries";
import { ChatMessageCreatedBroadcast, ChatMessagesReadBroadcast, ChatRoomSavedBroadcast } from "shared/broadcasts";
import { ApplicationModule } from "shared/modules";
import { CmChatRoomCreate } from "shared/components";

import './styles.scss';

export const ChatRooms = observer(() => {
    const routeParams = useRouteParams<{id: number}>();

    const store = useStore(() => ({
        modal: false,
        chatRooms: [] as ChatRoomModel[],
    }));

    const fetchChatRooms = useCallback(async () => {
        const { isSuccess, data } = await chatRoomsQuery();
        if (isSuccess && data) {
            store.set("chatRooms", data.items.map(item => new ChatRoomModel(item)));
        }
    }, [store]);

    useEffect(() => {
        fetchChatRooms();
    }, [fetchChatRooms]);

    const chatRoomById = useMemo(() => {
        return lodash.keyBy(store.chatRooms, 'id');
    }, [store.chatRooms])

    const chatMessageCreatedBroadcast = useMemo(() => new ChatMessageCreatedBroadcast(({ item }) => {
        if (!chatRoomById[item.chatRoomId]) {
            fetchChatRooms();
            return;
        }
        if (
            item.userId !== ApplicationModule.user.id &&
            +routeParams.id !== +item.chatRoomId &&
            chatRoomById[item.chatRoomId]
        ) {
            chatRoomById[item.chatRoomId].update({
                chatMessagesUnreadCount: chatRoomById[item.chatRoomId].chatMessagesUnreadCount + 1
            });
        }
    }), [fetchChatRooms, chatRoomById, routeParams.id]); // eslint-disable-line
    useBroadcast(chatMessageCreatedBroadcast);

    const chatMessagesReadBroadcast = useMemo(() => new ChatMessagesReadBroadcast(({ userId, chatRoomId }) => {
        if (userId === ApplicationModule.user.id && chatRoomById[chatRoomId]) {
            chatRoomById[chatRoomId].update({
                chatMessagesUnreadCount: 0
            });
        }
    }), [fetchChatRooms, chatRoomById]); // eslint-disable-line
    useBroadcast(chatMessagesReadBroadcast);

    const chatRoomCreatedBroadcast = useMemo(
        () => new ChatRoomSavedBroadcast(fetchChatRooms),
        [fetchChatRooms]
    );
    useBroadcast(chatRoomCreatedBroadcast);

    return (
        <div className="chat-rooms">
            {store.chatRooms.map(item => {
                if (!item.user) {
                    return null;
                }
                return (
                    <UiNavLink key={item.id} className='chat-rooms-item' to={ROUTES.CHAT(item.id)} title={item.previewName}>
                        <UiAvatar size={48} label={item.previewName} image={item.previewImage}/>
                        {item.chatMessagesUnreadCount > 0 && (
                            <div className="chat-rooms-item__unread-count">
                                {item.chatMessagesUnreadCount}
                            </div>
                        )}
                    </UiNavLink>
                )
            })}
            <div
                className='chat-rooms-new'
                onClick={() => store.set("modal", true)}
            >
                +
            </div>
            <CmChatRoomCreate
                isOpened={store.modal}
                onClose={() => store.set("modal", false)}
            />
        </div>
    );
});
