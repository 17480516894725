import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';

type RequestType = {
    login: string
    password: string;
}

type ResponseType = {
    accessToken: string;
    item: IUserModel;
};

export const usersLoginQuery = async function (data: RequestType) {
    return await makeQuery<ResponseType>('/users/login', { json: data });
}
