import { computed, makeObservable, observable } from 'mobx';
import moment from "moment";
import { ModelArrayCast, ModelCast } from "shared/casts";
import { ICONS, ROUTES } from "shared/constants";

import {
    AnnouncementModel,
    ArticleModel,
    BookModel,
    CommentModel,
    DocumentModel,
    EducationLessonModel,
    GalleryModel,
    NewsModel,
    SurveyModel,
    UserModel,
    VacancyModel
} from 'shared/models';
import { TaskModel } from "shared/models/TaskTracker";

import { Model } from './Model';

interface INotificationDataModel {
    count?: number,
    users?: UserModel[],
    user?: UserModel,
    document?: DocumentModel,
    comment?: CommentModel,
    task?: TaskModel,
    book?: BookModel,
    announcement?: AnnouncementModel,
    vacancy?: VacancyModel,
    article?: ArticleModel,
    educationLesson?: EducationLessonModel,
    survey?: SurveyModel,
    gallery?: GalleryModel,
    news?: NewsModel,
}

class NotificationDataModel extends Model implements INotificationDataModel {
    casts = {
        users: new ModelArrayCast(UserModel),
        user: new ModelCast(UserModel),
        document: new ModelCast(DocumentModel),
        comment: new ModelCast(CommentModel),
        task: new ModelCast(TaskModel),
        book: new ModelCast(BookModel),
        announcement: new ModelCast(AnnouncementModel),
        vacancy: new ModelCast(VacancyModel),
        article: new ModelCast(ArticleModel),
        educationLesson: new ModelCast(EducationLessonModel),
        survey: new ModelCast(SurveyModel),
        gallery: new ModelCast(GalleryModel),
        news: new ModelCast(NewsModel),
    };

    count: number = 0;
    url: string = '';
    users: UserModel[] = [];
    user: UserModel = new UserModel();
    task: TaskModel = new TaskModel();
    document: DocumentModel = new DocumentModel();
    comment: CommentModel = new CommentModel();
    book: BookModel = new BookModel();
    announcement: AnnouncementModel = new AnnouncementModel();
    vacancy: VacancyModel = new VacancyModel();
    article: ArticleModel = new ArticleModel();
    educationLesson: EducationLessonModel = new EducationLessonModel();
    survey: SurveyModel = new SurveyModel();
    gallery: GalleryModel = new GalleryModel();
    news: NewsModel = new NewsModel();

    constructor(payload: INotificationDataModel = {}) {
        super();

        makeObservable(this, {
            users: observable,
            user: observable,
            task: observable,
            count: observable,
            document: observable,
            comment: observable,
            book: observable,
            announcement: observable,
            vacancy: observable,
            article: observable,
            educationLesson: observable,
            survey: observable,
            gallery: observable,
            news: observable,
        });

        this.update(payload);
    }
}

export interface INotificationModel {
    id?: string;
    createdAt?: string;
    readAt?: string | null;
    deletedAt?: string | null;
    type?: string;
    data?: NotificationDataModel,
}

export class NotificationModel extends Model implements INotificationModel {
    casts = {
        data: new ModelCast(NotificationDataModel),
    };

    id = '';
    createdAt = '';
    readAt = null;
    deletedAt = null;
    type = '';
    data = new NotificationDataModel();

    constructor(payload: INotificationModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            readAt: observable,
            deletedAt: observable,
            data: observable,
            typeFormatted: computed,
            isUnread: computed,
            isDeleted: computed,
            createdAtMoment: computed,
            content: computed,
        });

        this.update(payload);
    }

    get content(): { name: string, action: string, description: string, url: string, icon: typeof ICONS.NEWS } {
        switch (this.typeFormatted) {
            case 'UnreadChatMessagesNotification':
                return {
                    name: this.data.count === 1
                        ? `У вас 1 непрочитанное сообщение!`
                        : `У вас ${this.data.count} непрочитанных сообщения!`,
                    action: 'Ответить',
                    description: '',
                    url: ROUTES.CALENDAR('?calendarItemType=birthday'),
                    icon: ICONS.COMMENT
                };
            case 'UsersBirthdaysNotification':
                return {
                    name: `День Рождения! (${this.data.users.length})`,
                    action: 'Перейти в календарь',
                    description: '',
                    url: '#',
                    icon: ICONS.NOTIFICATIONS_BIRTHDAY
                };
            case 'DocumentNotification':
                return {
                    name: `Новый документ`,
                    action: 'Перейти',
                    description: '',
                    url: ROUTES.DOCUMENT_CATEGORY(this.data.document.documentCategoryId),
                    icon: ICONS.DOCUMENT
                };
            case 'CommentNotification':
                return {
                    name: `Вас упомянули в комментариях`,
                    action: 'Перейти',
                    description: '',
                    url: this.data.comment.url,
                    icon: ICONS.COMMENT
                };
            case 'BookCreatedNotification':
                return {
                    name: 'Новая книга',
                    action: 'Перейти',
                    description: this.data.book.name,
                    url: ROUTES.BOOKS() + `?id=${this.data.book.id}&typeId=${this.data.book.typeId}`,
                    icon: ICONS.LIBRARY
                };
            case 'ArticleCreatedNotification':
                return {
                    name: 'Новая статья',
                    action: 'Перейти',
                    description: this.data.article.name,
                    url: ROUTES.ARTICLE(this.data.article.id),
                    icon: ICONS.ARTICLES
                };
            case 'VacancyCreatedNotification':
                return {
                    name: 'Новая вакансия',
                    action: 'Перейти',
                    description: this.data.vacancy.name,
                    url: ROUTES.VACANCY(this.data.vacancy.id),
                    icon: ICONS.VACANCIES
                };
            case 'SurveyCreatedNotification':
                return {
                    name: 'Новый опрос',
                    action: 'Перейти',
                    description: this.data.survey.name,
                    url: ROUTES.SURVEY(this.data.survey.id),
                    icon: ICONS.SURVEY
                };
            case 'GalleryCreatedNotification':
                return {
                    name: 'Новый альбом в галерее Togas Life',
                    action: 'Перейти',
                    description: this.data.gallery.name,
                    url: ROUTES.GALLERY(this.data.gallery.id),
                    icon: ICONS.IMAGES_AND_VIDEOS
                };
            case 'AnnouncementCreatedNotification':
                return {
                    name: 'Новое объявление',
                    action: 'Перейти',
                    description: this.data.announcement.name,
                    url: ROUTES.ANNOUNCEMENT(this.data.announcement.id),
                    icon: ICONS.ANNOUNCEMENT
                };
            case 'EducationLessonCreatedNotification':
                let educationLessonCreatedNotificationName = `Добавлено новое мероприятие`;
                if (this.data.educationLesson.name.toLowerCase().indexOf('курс английского') > -1) {
                    educationLessonCreatedNotificationName = `Добавлен новый курс английского`;
                } else if (this.data.educationLesson.typeId === 'training') {
                    educationLessonCreatedNotificationName = `Добавлен новый тренинг`;
                } else if (this.data.educationLesson.typeId === 'game') {
                    educationLessonCreatedNotificationName = `Добавлена новая игра`;
                }

                return {
                    name: educationLessonCreatedNotificationName,
                    action: 'Записаться',
                    description: this.data.educationLesson.name,
                    url: ROUTES.EDUCATION() + `?educationLessonId=${this.data.educationLesson.id}`,
                    icon: ICONS.EDUCATION
                };
            case 'EducationLessonRecentNotification':
                let educationLessonRecentNotificationName = `Мероприятие добавлено в "Ближайшие события"`;
                if (this.data.educationLesson.name.toLowerCase().indexOf('курс английского') > -1) {
                    educationLessonRecentNotificationName = `Курс английского добавлен в "Ближайшие события"`;
                } else if (this.data.educationLesson.typeId === 'training') {
                    educationLessonRecentNotificationName = `Тренинг добавлен в "Ближайшие события"`;
                } else if (this.data.educationLesson.typeId === 'game') {
                    educationLessonRecentNotificationName = `Игра добавлена в "Ближайшие события"`;
                }

                return {
                    name: educationLessonRecentNotificationName,
                    action: 'Записаться',
                    description: this.data.educationLesson.name,
                    url: ROUTES.EDUCATION() + `?educationLessonId=${this.data.educationLesson.id}`,
                    icon: ICONS.EDUCATION
                };
        }
        return {
            name: 'Уведомление',
            action: 'Перейти',
            description: '',
            url: '#',
            icon: ICONS.ANNOUNCEMENT
        };
    }

    get createdAtMoment() {
        if (!this.createdAt) {
            return moment();
        }
        return moment(this.createdAt);
    }

    createdAtFormatted = () => {
        if (!this.createdAt) {
            return '';
        }

        const duration = moment.duration(moment().diff(this.createdAtMoment));
        const hoursFromNow = duration.asHours();

        if (hoursFromNow < 12) {
            return this.createdAtMoment.fromNow();
        }

        return this.createdAtMoment.format('D MMMM, HH:mm');
    }

    get typeFormatted() {
        const data = this.type.split('\\');
        return data[data.length - 1];
    }

    get isUnread() {
        return !this.readAt;
    }

    get isDeleted() {
        return !!this.deletedAt;
    }
}
