import lodash from "lodash";
import { observer } from 'mobx-react';
import { ICONS } from "shared/constants";

import { UserDashboardWidgetModel } from "shared/models";
import { UiIcon } from "shared/uikit";
import { currency } from "shared/utilities";

import './styles.scss';

type PropsType = {
    widget: UserDashboardWidgetModel
}

export const PPDWidgetBonuses = observer(({ widget }: PropsType) => {
    const bonus = widget.items.reduce((value, item) => {
        return value + (item.bonus?.value || 0);
    }, 0);

    const storeById = lodash.keyBy(widget.stores, 'id');

    return (
        <div className={'ppd-widget ppd-widget--bonuses'}>
            <div className="ppd-widget__header">
                <div className="ppd-widget__name">{widget.name}</div>
            </div>
            <div className="ppd-widget__items">
                {widget.items.map((item, index) => (
                    <div key={index} className={'ppd-widget-item'}>
                        <div className={'ppd-widget-item__icon'}>
                            <UiIcon icon={ICONS.CHECK} size={14} color={'#20bf6b'}/>
                        </div>
                        <div className="ppd-widget-item__name">{storeById[item.storeId as number]?.name || ''}</div>
                        <div className="ppd-widget-item__inner">
                            <div className="ppd-widget-item__description">{storeById[item.storeId as number]?.description || ''}</div>
                            <div className="ppd-widget-item__bonus">
                                <UiIcon icon={ICONS.CHECK} size={14} color={'#20bf6b'}/>
                                <span>{currency(item.bonus?.value || 0)}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="ppd-widget-bonus">
                <div className="ppd-widget-bonus__name">Бонусы</div>
                <div className="ppd-widget-bonus__value">{currency(bonus)}</div>
            </div>
        </div>
    );
});
