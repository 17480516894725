import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { UiButton, UiDataBoundary, UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { useStore } from "shared/hooks";
import { IspringCourseModel } from "shared/models";
import { ispringCoursesQuery } from "shared/queries";

import './styles.scss';

export const PEducationISpring = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        ispringCourses: [] as IspringCourseModel[]
    }))

    useEffect(() => {
        (async () => {
            const { isSuccess, data } = await ispringCoursesQuery();
            if (isSuccess && data) {
                store.set("ispringCourses", data.items.map(item => new IspringCourseModel(item)))
            }
            store.set("isLoading", false);
        })();
    }, [store])

    return (
        <div className="p-education-ispring">
            <div className="p-education-ispring__header">
                <div className="p-education-ispring__title">Назначенные программы</div>
                <UiButton
                    isSmall
                    label={'Перейти в ispring'}
                    onClick={() => {
                        if (window) {
                            window.open('https://togas.ispringlearn.ru', '_blank')?.focus();
                        }
                    }}
                    iconAfter={<UiIcon icon={ICONS.ARROW_RIGHT} size={10} color={COLORS.WHITE}/>}
                />
            </div>
            <UiDataBoundary isLoading={store.isLoading}>
                <div className="p-education-ispring__inner">
                    {store.ispringCourses.map(ispringCourse => (
                        <a
                            key={ispringCourse.id}
                            href={ispringCourse.url}
                            target={'_blank'}
                            rel='noreferrer'
                            className="p-education-ispring-item"
                        >
                            <div
                                style={
                                    ispringCourse.previewImageUrl
                                        ? { backgroundImage: `url(${ispringCourse.previewImageUrl})` }
                                        : {}
                                }
                                className="p-education-ispring-item__image"

                            />
                            <div className="p-education-ispring-item__name">{ispringCourse.name}</div>
                            <div className="p-education-ispring-item__type">{ispringCourse.type}</div>
                            <div className="p-education-ispring-item__progress">
                                <CircularProgressbar
                                    value={ispringCourse.progress}
                                    counterClockwise
                                    styles={{
                                        path: {
                                            stroke: ispringCourse.progress > 80 ? '#20BF6B' : '#EB3B5A'
                                        }
                                    }}
                                />
                                <div className="p-education-ispring-item__percentage">{ispringCourse.progress}%</div>
                            </div>
                        </a>
                    ))}
                </div>
            </UiDataBoundary>
        </div>
    );
});

