import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import lodash from 'lodash';

import { COLORS, ICONS } from 'shared/constants';
import { OnChangeHandlerType, QueryResponseType } from 'shared/types';
import { useOnClickOutside, useStore } from "shared/hooks";

import { UiScrollbar } from "../UiScrollbar";
import { UiIcon } from '../UiIcon';

import './styles.scss';

type SelectItemType = {
    id: any;
    name: string | number;
    color?: string,
};

type PropsType = {
    onChange?: OnChangeHandlerType<string | number | null>;
    value?: string | number | null;
    query?: () => Promise<QueryResponseType<{ items: any[] }>>;
    items?: SelectItemType[];
    placeholder?: string;
    name?: string;
    className?: string;
    style?: React.CSSProperties;
    withSearch?: boolean,
    light?: {
        icon: React.ReactNode;
        label: string,
    };
}

export const UiSelect = observer((
    {
        onChange,
        value: propsValue = null,
        name = '',
        light,
        query,
        placeholder = '',
        className = '',
        withSearch = false,
        items: propsItems = [],
        style = {},
    }: PropsType
) => {
    const NULL_ITEM_ID = '_null_';
    const store = useStore(() => ({
        queryItems: [] as SelectItemType[],
        isOpened: false,
        isSuccess: true,
        isLoading: false,
        search: '',
    }));

    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        setTimeout(() => store.set('isOpened', false), 200);
    });

    const toggle = () => {
        store.set("isOpened", !store.isOpened);
    }

    const fetchItems = useCallback(async () => {
        if (!query) {
            return;
        }
        store.set('isSuccess', true);
        store.set('isLoading', true);
        const { isSuccess, data } = await query();
        if (data && isSuccess) {
            store.set('queryItems', data.items);
        } else {
            store.set('isSuccess', false);
        }
        store.set('isLoading', false);
    }, [store, query]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems])

    const getChangedValue = (value: any) => {
        if (value === NULL_ITEM_ID) {
            return null;
        }

        return value;
    };

    const handleChangeNative = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange && onChange({
            name,
            control: 'ui-select',
            value: getChangedValue(event.target.value),
        });
    };

    const handleChangeCustom = (value: any) => (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        store.set('isOpened', false);

        onChange && onChange({
            name,
            control: 'ui-select',
            value: getChangedValue(value),
        });
    };

    const items = useMemo(() => {
        return [...propsItems, ...store.queryItems]
    }, [propsItems, store.queryItems]);

    const itemsFiltered = useMemo(() => {
        if (!store.search) {
            return [...propsItems, ...store.queryItems]
        }
        return [...propsItems, ...store.queryItems].filter((item) => {
            return item.name.toString().toLocaleLowerCase().indexOf(store.search.toLowerCase()) > -1;
        })
    }, [propsItems, store.queryItems, store.search]);

    const itemById = useMemo(() => lodash.keyBy(items, 'id'), [items]);
    const defaultItem = useMemo(() => {
        const defaultItem = items.find(item => item.id === null);
        if (!defaultItem) {
            return null;
        }
        return defaultItem;
    }, [items]);
    const value = useMemo(() => propsValue === null ? NULL_ITEM_ID : propsValue, [propsValue]);
    const selectedItem = useMemo(() => items.find(item => item.id === propsValue), [propsValue, items]);
    const textValue = useMemo(() => {
        if (propsValue === null && defaultItem !== null) {
            return defaultItem.name;
        }
        if (propsValue === '' || propsValue === null) {
            return placeholder;
        }
        return itemById[propsValue] ? itemById[propsValue].name : placeholder;
    }, [placeholder, propsValue, defaultItem, itemById]);

    const classNames = classnames('ui-select', className, {
        'ui-select--opened': store.isOpened,
        'ui-select--placeholder': textValue === placeholder,
    });

    const template = useMemo(() => {
        if (light) {
            return (
                <div className='ui-select-template-light'>
                    <div className="ui-select-template-light__icon">{light.icon}</div>
                    <div className="ui-select-template-light__label">{light.label}</div>
                    <div className="ui-select-template-light__value">{textValue}</div>
                    <UiIcon size={16} icon={ICONS.CHEVRON_DOWN} color={COLORS.BROWN_1}/>
                </div>
            );
        }
        return (
            <div className='ui-select-template'>
                {selectedItem?.color && (<i style={{ backgroundColor: selectedItem.color }}/>)}
                <span>{textValue}</span>
                <UiIcon size={13} icon={ICONS.CHEVRON_DOWN} color={COLORS.GRAY_3}/>
            </div>
        );
    }, [light, textValue, selectedItem])

    if (query && store.isLoading) {
        return (
            <div className={classNames} style={style} ref={ref}>
                <div className='ui-select__inner'>
                    <div className='ui-select__value'><span>Загрузка...</span></div>
                </div>
            </div>
        );
    }

    if (query && !store.isSuccess) {
        return (
            <div className={classNames} style={style} ref={ref}>
                <div className='ui-select__inner' onClick={fetchItems}>
                    <div className='ui-select__value'><span>Ошибка загрузки, нажмите, чтобы обновить</span></div>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames} style={style} ref={ref}>
            <div className='ui-select__inner'>
                <select
                    className='ui-select__control'
                    onChange={handleChangeNative}
                    name={name}
                    value={value}
                >
                    {items.map(item => (
                        <option key={item.id} value={item.id !== null ? item.id : NULL_ITEM_ID}>
                            {item.name}
                        </option>
                    ))}
                </select>
                <div className="ui-select__preview" onClick={toggle}>
                    {template}
                </div>
                <div className='ui-select__items'>
                    {withSearch && (
                        <div className="ui-select__search">
                            <input
                                type="text"
                                placeholder={'Поиск'}
                                value={store.search}
                                onChange={e => {
                                    store.set('search', e.target.value || '');
                                }}
                            />
                        </div>
                    )}
                    <UiScrollbar maxHeight={235}>
                        {itemsFiltered.map(item => (
                            <div
                                key={item.id}
                                className={classnames('ui-select__item', {
                                    'ui-select__item--selected': item.id === value,
                                })}
                                onClick={handleChangeCustom(item.id)}
                            >
                                {item.color && (<i style={{ backgroundColor: item.color }}/>)}
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </UiScrollbar>
                </div>
            </div>
        </div>
    );
})
