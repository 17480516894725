import { makeQuery } from 'shared/utilities';
import { IBoardModel, IColumnModel, ISpaceModel } from "shared/models/TaskTracker";

type ResponseType = {
    item: IBoardModel,
    columns: IColumnModel[],
};

export const boardsSaveQuery = async function (request: ISpaceModel) {
    return await makeQuery<ResponseType>('/boards/save', {
        prefix: 'task-tracker',
        json: request
    });
}
