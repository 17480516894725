import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { COLORS } from 'shared/constants';
import { ObjectType, IconType } from 'shared/types';

import './styles.scss';

type PropsType = {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
    icon?: IconType;
    onClick?: () => void;
    iconProps?: ObjectType;
}

export const UiIcon = observer((
    {
        size = 16,
        color = COLORS.BLACK,
        style = {},
        icon = () => null,
        onClick,
        iconProps = {}
    }: PropsType
) => {
    const iconNode = useMemo(
        () => icon ? icon({ size, color, ...iconProps }) : null,
        [icon, size, color, iconProps]
    );

    if (!iconNode) {
        return null;
    }

    return (
        <div
            className='ui-icon'
            data-test-id='ui-icon'
            style={{ minWidth: size, minHeight: size, width: size, height: size, ...style }}
            onClick={onClick}
        >
            {iconNode}
        </div>
    );
})
