import React from 'react';
import { observer } from 'mobx-react';

import { LayoutModule } from "shared/modules";
import { ROUTES } from "shared/constants";
import { UiNavLink } from "shared/uikit";

import { LayoutHeaderSearch } from "./components/LayoutHeaderSearch";
import { LayoutHeaderUser } from "./components/LayoutHeaderUser";

import './styles.scss';

export const LayoutHeader = observer(() => {
    return (
        <div className='layout-header'>
            {!LayoutModule.menu.isOpened && <UiNavLink to={ROUTES.HOME()} className="layout-header__logo"/>}
            <LayoutHeaderSearch/>
            <LayoutHeaderUser/>
        </div>
    )
});
