import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast } from "shared/casts";

import { FaqModel } from './FaqModel';
import { Model } from './Model';

export interface IFaqCategoryModel {
    id?: number;
    name?: string;
    faqItems?: FaqModel[];
}

export class FaqCategoryModel extends Model implements IFaqCategoryModel {
    casts = {
        faqItems: new ModelArrayCast(FaqModel),
    };

    id = 0;
    name = '';
    faqItems: FaqModel[] = [];

    constructor(payload: IFaqCategoryModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            faqItems: observable,
            update: action,
        });

        this.update(payload);
    }
}
