import { ObjectType } from 'shared/types';
import { runInAction, toJS } from 'mobx';

type ModelCastsType = {
    [key: string]: {
        set: (currentValue:any, newValue: any) => any,
        get?: (currentValue:any) => any,
    }
}

abstract class Model {
    [key: string]: any;

    casts?: ModelCastsType = {};

    update = (payload: ObjectType = {}) => {
        runInAction(() => {
            if (payload === null) {
                return;
            }
            Object.keys(this).forEach(key => {
                if (typeof this[key] === 'function' || !payload.hasOwnProperty(key)) {
                    return;
                }
                if (this.casts?.hasOwnProperty(key) && payload[key] !== null) {
                    this[key] = this.casts[key].set(this[key], payload[key]);
                    return;
                }
                this[key] = payload[key];
            });
        })
    };

    toObject = () => {
        return toJS(this);
    };
}

export { Model };
