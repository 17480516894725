import { action, makeObservable, observable } from 'mobx';

import { ThumbnailType } from 'shared/types';
import { TagModel } from 'shared/models';
import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';

export interface IArticleModel {
    id?: number;
    createdAt?: string;
    name?: string;
    previewImage?: string;
    detailImage?: string;
    previewText?: string;
    detailText?: string;
    previewImageThumbnail?: ThumbnailType;
    toc?: string;
    liked?: number;
    likesCount?: number;
    commentsCount?: number;
    tags?: TagModel[];
}

export class ArticleModel extends Model implements IArticleModel {
    casts = {
        tags: new ModelArrayCast(TagModel),
    };

    id = 0;
    createdAt = '';
    name = '';
    previewImage = '';
    detailImage = '';
    previewText = '';
    detailText = '';
    previewImageThumbnail = null;
    toc = '';
    liked = 0;
    likesCount = 0;
    commentsCount = 0;
    userId = 0;
    tags: TagModel[] = [];

    constructor(payload: IArticleModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            name: observable,
            previewImage: observable,
            detailImage: observable,
            previewText: observable,
            detailText: observable,
            previewImageThumbnail: observable,
            userId: observable,
            tags: observable,
            liked: observable,
            likesCount: observable,
            commentsCount: observable,
            update: action,
        });

        this.update(payload);
    }
}
