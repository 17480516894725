import React from 'react';
import { observer } from 'mobx-react';

interface Props {
    component: any;
    className: string;
    items: any[];
}

const DefaultComponent = ({ item }: { item: any }) => <div>{item.id}</div>;

class UiList extends React.Component<Props> {
    static defaultProps: Props = {
        component: DefaultComponent,
        items: [],
        className: '',
    };

    render() {
        const { items, className, component: Component } = this.props;

        if (!Component || items.length === 0) {
            return null;
        }

        const renderedItems = items.map(item => <Component key={item.id} item={item} />);

        if (className) {
            return <div className={className}>{renderedItems}</div>;
        }

        return renderedItems;
    }
}

export default observer(UiList);
