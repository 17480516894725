import { makeObservable, observable } from 'mobx';

export default class<T> {
    isSuccess = true;
    isLoading = false;
    errorCode = 0;
    errorMessage = '';
    isInfiniteLoading = false;
    pages = 0;
    total = 0;
    items: T[] = [];

    constructor() {
        makeObservable(this, {
            isSuccess: observable,
            isLoading: observable,
            isInfiniteLoading: observable,
            pages: observable,
            total: observable,
            items: observable,
        });
    }
}
