import * as localforage from 'localforage';

localforage.config({
    driver: localforage.LOCALSTORAGE,
});

export default new (class Storage {
    get = async <T>(key: string, defaultValue: T): Promise<T> => {
        try {
            const value = await localforage.getItem(key);
            if (value === null && defaultValue !== null) {
                return defaultValue;
            }
            return value as T;
        } catch (error) {
            return defaultValue;
        }
    };

    set = async (key: string, value: any) => {
        try {
            await localforage.setItem(key, value);
            return true;
        } catch (error) {
            return false;
        }
    };
})();
