import React from 'react';
import { observer } from 'mobx-react';
import { COLORS, ICONS } from 'shared/constants';

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    label?: string;
    style?: React.CSSProperties;
}

export const UiAuthor = observer(({ label = '', style, }: PropsType) => {
    return (
        <div className='ui-author' style={style}>
            <UiIcon size={15} icon={ICONS.USER} color={COLORS.BROWN_1}/>
            <span>{label}</span>
        </div>
    );
});
