import { IUserDashboardModel } from 'shared/models';
import { makeQuery } from 'shared/utilities';

type ResponseType = {
    item: IUserDashboardModel;
};

export const userDashboardsGetQuery = async function () {
    return await makeQuery<ResponseType>('/user-dashboards/get');
}
