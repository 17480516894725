import React from 'react';
import { observer } from 'mobx-react';
import { NotificationModel } from "shared/models";

import { UiIcon } from "shared/uikit/UiIcon";
import { UiAvatar } from "shared/uikit/UiAvatar";
import { ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    value: NotificationModel,
    isLight: boolean,
}

export const UiNotificationIcon = observer(({ value: notification, isLight }: PropsType) => {
    const size = isLight ? 30 : 35;
    if (notification.typeFormatted === 'UnreadChatMessagesNotification') {
        return <UiAvatar size={size} label={notification.data.user.previewName} image={notification.data.user.image}/>;
    }
    return <UiIcon size={size} icon={notification.content.icon}/>;
})
