import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiModal } from 'shared/uikit';
import { IWidgetProps } from 'shared/types';

import './styles.scss';

interface Props extends IWidgetProps {
    isOpened: boolean;
    title?: string;
    description?: string;
    submitText?: string;
    onClear?: () => void;
    onClose?: () => void;
    onSubmit?: () => void;
    children?: React.ReactNode
}

class ModalForm extends React.Component<Props> {
    static defaultProps: Props = {
        isOpened: false,
        title: '',
        description: '',
        submitText: 'Отправить',
        onClose: () => {},
        onSubmit: () => {},
    };

    render() {
        const { title, description, onClear, onClose, onSubmit, children, submitText, isOpened } = this.props;

        return (
            <UiModal isOpened={isOpened} onClose={onClose} title={title} description={description}>
                {children}
                <div className='ui-modal__actions'>
                    <UiButton isSmall label={submitText} onClick={onSubmit} />
                    <UiButton isSmall isOutline label='Отмена' onClick={onClose} />
                    {onClear && <UiButton isSmall isLink label='Сбросить заполнение' onClick={onClear} />}
                </div>
            </UiModal>
        );
    }
}

export default observer(ModalForm);
