import { Enum } from './Enum';

export class AnnouncementTypeEnum extends Enum {
    static Corporate = 'corporate';
    static Personal = 'personal';

    static items = [{
        id: AnnouncementTypeEnum.Corporate,
        name: 'Корпоративные'
    }, {
        id: AnnouncementTypeEnum.Personal,
        name: 'От коллег'
    }];
}

