import React from 'react';
import { observer } from 'mobx-react';

import { UiAvatar, UiDropdownMenu } from "shared/uikit";
import { useNavigate } from "shared/hooks";
import { ROUTES } from "shared/constants";
import { ApplicationModule } from "shared/modules";

import './styles.scss';

const items = [
    { id: 'profile', name: 'Профиль' },
    { id: 'logout', name: 'Выйти' }
];
export const LayoutHeaderUser = observer(() => {
    const navigate = useNavigate();

    const handleSelect = (id: string | number) => {
        switch (id) {
            case 'profile':
                navigate(ROUTES.PROFILE());
                break;
            case 'logout':
                ApplicationModule.setAccessToken(null);
                navigate(ROUTES.LOGIN());
                break;
        }
    }

    return (
        <div className='layout-header-user'>
            <UiDropdownMenu items={items} right={8} onSelect={handleSelect}>
                <div className="layout-header-user__inner">
                    <div className="layout-header-user__name">{ApplicationModule.user.previewName}</div>
                    <UiAvatar
                        size={32}
                        label={ApplicationModule.user.previewName}
                        image={ApplicationModule.user.imageAvatar}
                    />
                </div>
            </UiDropdownMenu>
        </div>
    )
});
