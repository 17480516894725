import { makeQuery } from 'shared/utilities';
import { ITagModel } from 'shared/models';

type RequestType = {
    has: string[]
}

type ResponseType = {
    items: ITagModel[];
};

export const tagsQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/tags', { params });
}
