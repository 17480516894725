import { makeQuery } from 'shared/utilities';
import { IBoardModel, IColumnModel, ISpaceModel, ITaskModel } from "shared/models/TaskTracker";


type ResponseType = {
    spaces: ISpaceModel[],
    tasks: ITaskModel[],
    boards: IBoardModel[],
    columns: IColumnModel[],
};

export const spacesPersonalQuery = async function () {
    return await makeQuery<ResponseType>('/spaces/personal', {
        prefix: 'task-tracker',
    });
}
