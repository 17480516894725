import React from 'react';
import { observer } from 'mobx-react';

import { OfficePublicTransportModel } from 'shared/models';
import { useStore } from "shared/hooks";
import { UiIcon, UiHtml, UiMap } from "shared/uikit";
import { ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    publicTransport: OfficePublicTransportModel;
}

export const OfficeItemPublicTransport = observer(({ publicTransport }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false
    }))

    return (
        <div className='office-item-public-transport'>
            <div className="office-item-public-transport__header" onClick={() => store.toggle('isOpened')}>
                <div className="office-item-public-transport__chevron">
                    <UiIcon icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN} size={14}/>
                </div>
                {publicTransport.color && (
                    <div className="office-item-public-transport__circle">
                        <UiIcon icon={ICONS.CIRCLE} color={publicTransport.color} size={10}/>
                    </div>
                )}
                <div className="office-item-public-transport__name">
                    {publicTransport.name}
                </div>
            </div>
            {store.isOpened && (
                <>
                    <UiHtml className="office-item-public-transport__description" value={publicTransport.description}/>
                    {publicTransport.coordinatesMapData.length === 2 && (
                        <UiMap
                            className='office-item-public-transport__map'
                            center={publicTransport.coordinatesMapData}
                            markers={publicTransport.coordinatesMarkersData}
                            routes={publicTransport.coordinatesRoute ? [publicTransport.coordinatesRouteData] : []}
                        />
                    )}
                </>
            )}
        </div>
    )
});
