import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";
import moment from "moment";
import Picker, { IEmojiData } from 'emoji-picker-react';

import { UiIcon } from "shared/uikit";
import { ICONS, COLORS } from "shared/constants";
import { useOnClickOutside, useRouteParams, useStore } from "shared/hooks";
import { ChatMessageModel, UserModel } from "shared/models";
import { chatMessageAttachmentsCreateQuery, chatMessagesCreateQuery } from "shared/queries";
import { ApplicationModule } from "shared/modules";

import { Store } from '../../store';

import './styles.scss';

export const ChatBottomBar = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        isUploading: false,
        uploadProgress: 0,
        emojisIsOpened: false,
    }));

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        Store.set("input", inputRef.current);
    }, [inputRef]);

    const emojisRef = useRef(null);
    useOnClickOutside(emojisRef, () => {
        store.set('emojisIsOpened', false);
    })

    const handleChangeMessage = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        Store.set("message", e.currentTarget.value)
    }, []);

    const handleSend = useCallback(async (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() !== 'enter') {
            return;
        }
        if (!Store.message) {
            return;
        }

        const chatMessage = new ChatMessageModel({
            content: Store.message
                .replace('<', '&lt;')
                .replace('>', '&gt;')
                .replace('"', '&quot;'),
            chatRoomId: routeParams.id,
            key: `${Date.now()}-${routeParams.id}-${ApplicationModule.user.id}`,
            createdAt: moment().format(),
            userId: ApplicationModule.user.id,
            user: new UserModel({ ...ApplicationModule.user }),
            chatMessageId: Store.chatMessageReply?.id || null,
            // preview replied message
            chatMessage: Store.chatMessageReply ? {...Store.chatMessageReply} : null
        });
        Store.set("message", '');
        Store.set("chatMessageReply", null);
        Store.set("chatMessages", [...Store.chatMessages, chatMessage]);
        setTimeout(() => Store.scrollbar?.scrollTo(0, 9999), 10);
        await chatMessagesCreateQuery(chatMessage);
    }, [routeParams.id]);

    const handleUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        store.set('isUploading', true);
        if (!event?.target || !event?.target?.files || event.target.files.length < 1) {
            return;
        }
        await chatMessageAttachmentsCreateQuery(routeParams.id, Array.from(event.target.files), (progress) => {
            store.set("uploadProgress", progress);
        });
        event.target.value = '';
        store.set('isUploading', false);
        store.set('uploadProgress', 0);
    }, [store, routeParams.id]);

    const handleSelectEmoji = useCallback((_event: React.MouseEvent, { emoji }: IEmojiData) => {
        Store.set("message", Store.message + emoji);
        inputRef.current?.focus();
    }, []);

    const handleToggleEmojis = useCallback(() => {
        store.set("emojisIsOpened", !store.emojisIsOpened);
    }, [store]);

    const handleClearReply = useCallback(() => {
        Store.set("chatMessageReply", null);
    }, []);

    return (
        <div className="chat-bottom-bar">
            {Store.chatMessageReply && (
                <div className='chat-bottom-bar-reply'>
                    <div className="chat-bottom-bar-reply__inner">
                        <div className="chat-bottom-bar-reply__name">{Store.chatMessageReply.user.previewName}</div>
                        <div className="chat-bottom-bar-reply__content">{Store.chatMessageReply.content}</div>
                    </div>
                    <div className="chat-bottom-bar-reply__close" onClick={handleClearReply}>
                        <UiIcon icon={ICONS.CLOSE} size={20} color={COLORS.GRAY_3}/>
                    </div>
                </div>
            )}
            <label
                className={classnames('chat-bottom-bar-attachment', {
                    'chat-bottom-bar-attachment--uploading': store.isUploading
                })}
            >
                <div className="chat-bottom-bar-attachment__control">
                    <input type="file" onChange={handleUpload}/>
                </div>
                {store.isUploading && (
                    <div
                        className="chat-bottom-bar-attachment__loading"
                        style={{ width: `${store.uploadProgress}%` }}
                    ></div>
                )}
                <div className="chat-bottom-bar-attachment__icon">
                    <UiIcon size={24} color={COLORS.GRAY_3} icon={ICONS.CHAT_ATTACHMENT}/>
                </div>
            </label>
            <input
                ref={inputRef}
                type='text'
                className="chat-bottom-bar__input" placeholder='Введите сообщение'
                onChange={handleChangeMessage}
                onKeyPress={handleSend}
                value={Store.message}
            />
            <div className='chat-bottom-bar-emojis' ref={emojisRef}>
                <div className="chat-bottom-bar-emojis__inner" onClick={handleToggleEmojis}>
                    <UiIcon size={24} color={COLORS.GRAY_3} icon={ICONS.CHAT_EMOJI}/>
                </div>
                {store.emojisIsOpened && (
                    <div className={'chat-bottom-bar-emojis__outer'}>
                        <Picker
                            disableSearchBar
                            onEmojiClick={handleSelectEmoji}
                            native={true}
                            groupNames={{
                                smileys_people: 'Люди и смайлики',
                                animals_nature: 'Животные и природа',
                                food_drink: 'Еда и напитки',
                                travel_places: 'Путешествия',
                                activities: 'Активности',
                                objects: 'Вещи',
                                symbols: 'Символика',
                                flags: 'Флаги',
                                recently_used: 'Часто используемые',
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});
