import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { COLORS } from "shared/constants";

import './styles.scss';

type PropsType = {
    width?: number,
    color?: string,
    gap?: number,
    isActive?: boolean,
    stroke?: number,
    onClick?: () => void
}

export const UiMenuButton = observer((
    {
        width = 20,
        color = COLORS.BLACK,
        gap = 5,
        isActive = false,
        stroke = 2,
        onClick,
    }: PropsType
) => {
    const className = classnames('ui-menu-button', {
        'ui-menu-button--active': isActive
    });
    return (
        <div className={className} style={{ width }} onClick={onClick}>
            <span style={{
                borderBottomColor: color,
                borderBottomWidth: stroke,
                borderBottomStyle: 'solid',
                marginBottom: gap
            }}/>
            <span style={{
                borderBottomColor: color,
                borderBottomWidth: stroke,
                borderBottomStyle: 'solid',
                marginBottom: gap
            }}/>
            <span style={{ borderBottomColor: color, borderBottomWidth: stroke, borderBottomStyle: 'solid' }}/>
        </div>
    )
});
