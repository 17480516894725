import { observer } from 'mobx-react';
import React from 'react';
import { ROUTES } from "shared/constants";
import { BookTypeEnum } from "shared/enums";
import { useNavigate, useStore, useUrlParams } from "shared/hooks";

import { BookModel } from 'shared/models';
import { UiCommentAction, UiImageThumbnail, UiLikeAction, UiTags } from "shared/uikit";

import { BookItemModal } from "../BookItemModal";

import './styles.scss';

type PropsType = {
    book: BookModel;
}

export const BookItemCard = observer(({ book }: PropsType) => {
    const navigate = useNavigate();
    const { id } = useUrlParams({
        id: null
    });
    const store = useStore(() => ({
        isOpened: id === book.id
    }));
    return (
        <div className='book-item-card'>
            <div className="book-item-card__preview">
                <UiImageThumbnail
                    className="book-item-card__image"
                    value={book.imageThumbnail}
                />
            </div>
            <div className="book-item-card__inner">
                <div className="book-item-card__name">{book.name}</div>
                <div className="book-item-card__author">{book.author}</div>
                {book.typeId === BookTypeEnum.Ebook && (
                    <a href={book.file} target='_blank' className="book-item-card__link" rel='noreferrer'>
                        Скачать
                    </a>
                )}
            </div>
            <div className="book-item-card__footer">
                <div className="book-item-card__tags">
                    <UiTags items={book.tags} onClick={({ value }) => {
                        navigate(ROUTES.BOOKS(), {
                            tagId: value.id
                        })
                    }}/>
                </div>
                <div className="book-item-card__actions">
                    <UiLikeAction
                        value={book.liked}
                        count={book.likesCount}
                        entityId={book.id}
                        entityType='Book'
                        onChange={({ value }) => {
                            book.update({
                                liked: value
                            })
                        }}
                    />
                    <UiCommentAction count={book.commentsCount} onClick={() => store.set("isOpened", true)}/>
                </div>
            </div>
            <BookItemModal book={book} isOpened={store.isOpened} onClose={() => store.set("isOpened", false)}/>
        </div>
    )
});
