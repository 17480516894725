import { makeAutoObservable } from 'mobx';
import { ReactNode } from "react";

export default new (class Notifier {
    data = {
        isOpened: false,
        type: 'alert' as ('alert' | 'confirm' | 'notice'),
        title: '',
        buttonLabel: 'Принять',
        message: '' as ReactNode,
        resolve: (value?: unknown) => {
            return value;
        },
    };

    _notice = {
        isOpened: false,
        message: ''
    }

    constructor() {
        makeAutoObservable(this);
    }

    alert = async (title = '', message = '', buttonLabel = 'Принять') => {
        this.data.isOpened = true;
        this.data.type = 'alert';
        this.data.title = title;
        this.data.message = message;
        this.data.buttonLabel = buttonLabel;
        return new Promise(resolve => {
            this.data.resolve = resolve;
        });
    };

    confirm = async (title = '', message: ReactNode = '', buttonLabel = 'Подтвердить') => {
        this.data.isOpened = true;
        this.data.type = 'confirm';
        this.data.title = title;
        this.data.message = message;
        this.data.buttonLabel = buttonLabel;
        return new Promise(resolve => {
            this.data.resolve = resolve;
        });
    };

    notice = async (message = '', time = 1600) => {
        this._notice.message = message;
        this._notice.isOpened = true;

        setTimeout(() => {
            this._notice.isOpened = false;
        }, time);
    };
})();
