import { BaseBroadcast } from "shared/broadcasts/BaseBroadcast";

type DataType = {
    count: number
}

export class NotificationUnreadCountBroadcast extends BaseBroadcast {
    isPrivate = true;
    event = 'notification.unread.count';
    callback: (data: DataType) => void;

    constructor(callback: (data: DataType) => void) {
        super();
        this.setCallback(callback);
    }
}
