import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useStore, useUserWidgets } from "shared/hooks";
import { ApplicationModule } from "shared/modules";
import { usersUpdateQuery } from "shared/queries";

import { ProfilePageWidgetsItem } from "./components/ProfilePageWidgetsItem";

import './styles.scss';

const exclude = ['WImprovementProposal', 'WEssentials'];
export const PProfileWidgets = observer(() => {
    const store = useStore(() => ({
        items: [] as {
            id: string,
            name: string,
            value: number
        }[]
    }));

    let userWidgets = useUserWidgets({
        withDisabled: true,
        exclude
    });

    useEffect(() => {
        store.set("items", userWidgets);
    }, [userWidgets, store])

    const handleDrag = useCallback((dragIndex: number, hoverIndex: number) => {
        const items = store.items.slice();
        const item = items.splice(dragIndex, 1)[0];
        items.splice(hoverIndex, 0, item);
        store.set('items', items);
    }, [store])

    const handleDrop = useCallback(() => {
        const widgets = store.items.map(({ id, value }) => ({ id, value }));
        ApplicationModule.user.update({ widgets });
        usersUpdateQuery({ widgets });
    }, [store])

    const handleChange = useCallback(() => {
        const widgets = store.items.map(({ id, value }) => ({ id, value }));
        ApplicationModule.user.update({ widgets });
        usersUpdateQuery({ widgets });
    }, [store])

    return (
        <div className='p-user-section p-user-section--aside'>
            <div className="p-user-section__title">Виджеты главной страницы</div>
            <DndProvider backend={HTML5Backend}>
                {store.items.map((item, index) => (
                    <ProfilePageWidgetsItem
                        key={item.id}
                        index={index}
                        item={item}
                        onDrag={handleDrag}
                        onDrop={handleDrop}
                        onChange={handleChange}
                    />
                ))}
            </DndProvider>
        </div>
    );
});
