import React from 'react';
import { observer } from 'mobx-react';

import { ChatMessageAttachmentModel } from "shared/models";
import {
    UiAvatar,
    UiButton,
    UiIcon,
    UiFileIcon,
    UiTabs,
    UiDataBoundary,
    UiGrid,
    UiUserStatus, UiNavLink,
} from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useRouteParams, useStore } from "shared/hooks";
import { chatMessageAttachmentsQuery, chatRoomUsersUpdateQuery } from "shared/queries";
import { ChatRoomTypeEnum } from "shared/enums";
import { ApplicationModule } from "shared/modules";

import { ChatRoomInvite } from "../ChatRoomInvite";
import { ChatRoomEdit } from "../ChatRoomEdit";

import { Store } from '../../store';

import './styles.scss';

const tabs = [
    { id: 'image', name: 'Медиафайлы' },
    { id: 'file', name: 'Файлы' },
    { id: 'url', name: 'Ссылки' },
];

export const ChatAside = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        isOpened: false,
        inviteModalIsOpened: false,
        tabId: 'images',
        chatMessageAttachments: [] as ChatMessageAttachmentModel[],
        isLoading: false,
        isSuccess: true
    }));

    const handleChangeTab = async (tabId: string) => {
        if (store.isOpened && store.tabId === tabId) {
            return;
        }
        store.set("isOpened", true);
        store.set("tabId", tabId);
        store.set("isLoading", true);
        const { isSuccess, data } = await chatMessageAttachmentsQuery({
            chatRoomId: routeParams.id,
            typeId: tabId
        });
        if (isSuccess && data) {
            store.set("chatMessageAttachments", data.items.map(item => new ChatMessageAttachmentModel(item)))
        }
        store.set("isLoading", false);
        store.set("isSuccess", isSuccess && data);
    }

    const handleToggleNotifications = () => {
        if (Store.chatRoom?.chatRoomUser) {
            const payload = {
                id:  Store.chatRoom.chatRoomUser.id,
                receiveNotifications: Store.chatRoom.chatRoomUser.receiveNotifications ? 0 : 1
            }
            Store.chatRoom.chatRoomUser.update(payload);
            chatRoomUsersUpdateQuery(payload);
        }
    };

    if (store.isOpened) {
        return (
            <div className="chat-aside">
                <div className="chat-aside__header">
                    <div className="chat-aside__title">
                        Вложения
                    </div>
                    <UiButton
                        label='Назад'
                        isLink
                        isSmall
                        onClick={() => store.set("isOpened", false)}
                        iconBefore={<UiIcon color={COLORS.BROWN_1} icon={ICONS.CHEVRON_LEFT} size={16}/>}
                    />
                </div>
                <div className="chat-aside__tabs">
                    <UiTabs items={tabs} value={store.tabId} onChange={({ value }) => handleChangeTab(value)}/>
                </div>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.tabId === 'image' && (
                        <UiGrid gap={[8, 0]} columns={3}>
                            {store.chatMessageAttachments.map(item => (
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    key={item.id}
                                    href={item.content}
                                    className="chat-aside-attachment-image"
                                    style={{ backgroundImage: `url(${item.content})` }}
                                />
                            ))}
                        </UiGrid>
                    )}
                    {store.tabId === 'file' && store.chatMessageAttachments.map(item => (
                        <a
                            rel='noopener noreferrer'
                            target='_blank'
                            key={item.id}
                            href={item.content}
                            className="chat-aside-attachment-file"
                            style={{ backgroundImage: `url(${item.content})` }}
                        >
                            <div className="chat-aside-attachment-file__icon">
                                <UiFileIcon size={30} extension={item.extension}/>
                                {/*<UiIcon size={16} color={COLORS.BLACK} icon={ICONS.FILE}/>*/}
                            </div>
                            <div className="chat-aside-attachment-file__inner">
                                <div className="chat-aside-attachment-file__name">{item.description}</div>
                                <div className="chat-aside-attachment-file__date">
                                    {item.createdAtMoment.format('D.MM.Y HH:mm')}
                                </div>
                            </div>
                        </a>
                    ))}
                    {store.tabId === 'url' && store.chatMessageAttachments.map(item => (
                        <a
                            rel='noopener noreferrer'
                            target='_blank'
                            key={item.id}
                            href={item.content}
                            className="chat-aside-attachment-file"
                            style={{ backgroundImage: `url(${item.content})` }}
                            title={item.content}
                        >
                            <div className="chat-aside-attachment-url__icon">
                                <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.CHAT_ATTACHMENT_URL}/>
                            </div>
                            <div className="chat-aside-attachment-url__inner">
                                <div className="chat-aside-attachment-url__name">{item.description}</div>
                                <div className="chat-aside-attachment-url__hostname">{item.hostname}</div>
                            </div>
                        </a>
                    ))}
                </UiDataBoundary>
            </div>
        )
    }

    return (
        <div className="chat-aside">
            {Store.chatRoom && (
                <UiNavLink to={ROUTES.USER(Store.chatRoom.user?.slug)} className="chat-aside-info">
                    <div className="chat-aside-info__avatar">
                        <UiAvatar label={Store.chatRoom.previewName} image={Store.chatRoom.previewImage}/>
                    </div>
                    <div className="chat-aside-info__name">
                        {Store.chatRoom.previewName}
                    </div>
                </UiNavLink>
            )}
            <div className="chat-aside-section">
                <div className="chat-aside-section__header">
                    <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.CHAT_ATTACHMENT}/>
                    <span>Вложения</span>
                </div>
                {tabs.map(tab => (
                    <div
                        key={tab.id}
                        className="chat-aside-section__item"
                        onClick={() => handleChangeTab(tab.id)}
                    >
                        {tab.name}
                    </div>
                ))}
            </div>
            <div className="chat-aside-section">
                <div className="chat-aside-section__header">
                    <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.SETTINGS}/>
                    <span>Настройки</span>
                </div>
                {Store.chatRoom?.type.is(ChatRoomTypeEnum.Group) && Store.chatRoom?.userId === ApplicationModule.user.id && (
                    <ChatRoomEdit className='chat-aside-section__item'>
                        Редактировать диалог
                    </ChatRoomEdit>
                )}
                <div className='chat-aside-section__item' onClick={handleToggleNotifications}>
                    {
                        Store.chatRoom?.chatRoomUser?.receiveNotifications
                            ? 'Выключить уведомления'
                            : 'Включить уведомления'
                    }
                </div>
            </div>
            {Store.chatRoom && Store.chatRoom.type.is(ChatRoomTypeEnum.Group) && (
                <div className="chat-aside-section">
                    <div className="chat-aside-section__header">
                        <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.USER}/>
                        <span>Участники</span>
                        <ChatRoomInvite className="chat-aside-section__action">
                            <UiIcon size={16} color={COLORS.BLACK} icon={ICONS.USERS_ADD}/>
                        </ChatRoomInvite>
                    </div>
                    <div className="chat-aside-section__items">
                        {Store.chatRoom.users.map(user => (
                            <UiNavLink to={ROUTES.USER(user.slug)} key={user.id} className="chat-aside-user">
                                <div className="chat-aside-user__avatar">
                                    <UiAvatar size={40} label={user.previewName} image={user.imageAvatar}/>
                                </div>
                                <div className="chat-aside-user__inner">
                                    <div className="chat-aside-user__name">{user.previewName}</div>
                                    <UiUserStatus className="chat-aside-user__status" value={user}/>
                                </div>
                            </UiNavLink>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});
