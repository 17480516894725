import { action, computed, makeObservable, observable } from 'mobx';

import { Model } from '../Model';
import { TaskTrackerColumnTypeEnum } from "shared/enums";

export interface IColumnModel {
    id?: number | string;
    boardId?: number | null;
    typeId?: string;
    sort?: number;
    name?: string;
}

export class ColumnModel extends Model implements IColumnModel {
    casts = {};

    id = 0;
    boardId = 0;
    typeId = 'new';
    name = '';
    sort = 0;
    isCollapsed = false

    constructor(payload: IColumnModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            boardId: observable,
            sort: observable,
            typeId: observable,
            name: observable,
            isCollapsed: observable,
            type: computed,
            update: action
        });

        this.update(payload);
    }

    get type(){
        return TaskTrackerColumnTypeEnum.from(this.typeId);
    }
}
