import { makeQuery } from 'shared/utilities';
import { IWebResourceModel } from 'shared/models';

type ResponseType = {
    pages: number;
    items: IWebResourceModel[];
};

export const webResourcesQuery = async function () {
    return await makeQuery<ResponseType>('/web-resources');
}
