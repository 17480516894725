import React, { useCallback, useEffect, useMemo } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import moment from "moment";
import { set } from "mobx";

import { ApplicationModule } from 'shared/modules';
import { LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { COLORS, ICONS, ROUTES } from 'shared/constants';
import { ReservationModel, ReservationPlaceModel } from 'shared/models';
import { useMedia, useNavigate, useUrlParams, useValidation } from "shared/hooks";
import { OnChangeEventType, OnChangeHandlerType } from "shared/types";
import { PermissionEnum } from "shared/enums";
import {
    UiButton,
    UiCalendar,
    UiDataBoundary,
    UiDatePicker,
    UiForm,
    UiFormControl,
    UiIcon,
    UiInput,
    UiModal,
    UiReservationPlace, UiSelect,
    UiSliderCasual,
    UiTextarea,
    UiTimePicker,
    UiUserBadge,
    UiUsersSearch
} from 'shared/uikit';
import {
    reservationPlacesQuery,
    reservationsCreateQuery,
    reservationsDeleteQuery,
    reservationsGetQuery,
    reservationsUpdateQuery
} from "shared/queries";

import './styles.scss';

export const ReservationsPage = observer(() => {
    const store = useLocalObservable(() => ({
        set,
        reservation: new ReservationModel(),
        reservationPlaces: [] as ReservationPlaceModel[],
        errorMessage: '',
        modalIsVisible: false,
        modalIsLoading: false,
        isSubmitted: false,
        isUsersPick: false,
        isLoading: false,
    }));
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        date: moment().format('YYYY-MM-DD')
    });
    const { value: perView } = useMedia({
        isMobile: 1,
        isTablet: 2,
        is1024: 1,
        is1280: 2,
        is1440: 3,
    });

    useEffect(() => {
        if (!ApplicationModule.user.can(PermissionEnum.WebCalendarAndReservation)) {
            navigate(ROUTES.HOME());
            return;
        }
        ApplicationModule.setTitle('Бронирование переговорных комнат');
    }, [navigate]);

    const handleDateChange = (data: OnChangeEventType<string>) => {
        navigate(ROUTES.RESERVATIONS(), {
            date: data.value
        });
    }

    const fetchReservationPlaces = useCallback(async () => {
        const response = await reservationPlacesQuery(urlParams.date);
        if (response.isSuccess && response.data) {
            store.set(store, 'reservationPlaces', response.data.items.map(item => new ReservationPlaceModel(item)))
        }
    }, [store, urlParams.date]);

    useEffect(() => {
        store.set(store, 'reservation', new ReservationModel());
        fetchReservationPlaces();
    }, [store, urlParams.date, fetchReservationPlaces]);

    const selectedReservationPlace = useMemo(() => {
        if (!store.reservation.reservationPlaceId) {
            return new ReservationPlaceModel();
        }
        const reservationPlace = store.reservationPlaces.find(item => item.id === store.reservation.reservationPlaceId);
        return reservationPlace || new ReservationPlaceModel();
    }, [store.reservationPlaces, store.reservation.reservationPlaceId]);

    const validation = useValidation(store.reservation, validators => ({
        name: validators.required(),
        startAt: (value: string) => ({
            isValid: !!value && store.reservation.startAtMoment.isBefore(store.reservation.endAtMoment),
            errorMessage: 'Некорректное время'
        }),
    }));

    const handleSubmit = async () => {
        store.set(store, 'isSubmitted', true);
        if (!validation.isValid) {
            return;
        }

        store.set(store, 'isLoading', true);
        const response = store.reservation.id
            ? await reservationsUpdateQuery(store.reservation)
            : await reservationsCreateQuery(store.reservation);
        if (!response.isSuccess) {
            store.set(store, {
                errorMessage: response.errorMessage,
                isLoading: false
            });
            return;
        }
        await fetchReservationPlaces();
        store.set(store, {
            modalIsVisible: false,
            isLoading: false,
            isSubmitted: false,
            errorMessage: '',
        });
        setTimeout(() => {
            if (store) {
                store.set(store, 'reservation', new ReservationModel())
            }
        }, 1500);
    }

    const handleChange: OnChangeHandlerType = ({ name, value }) => {
        store.reservation.update({
            [name]: value
        });
    }

    const handleDelete = async (id: number) => {
        await reservationsDeleteQuery(id);
        await fetchReservationPlaces();
    }

    const handleEdit = async (id: number) => {
        store.set(store, 'modalIsLoading', true);
        store.set(store, 'modalIsVisible', true);
        store.set(store, 'errorMessage', '');
        const { isSuccess, data } = await reservationsGetQuery(id);
        if (isSuccess && data) {
            store.set(store, 'reservation', new ReservationModel(data.item));
        } else {
            store.set(store, 'modalIsVisible', false);
        }
        store.set(store, 'modalIsLoading', false);
    }

    const handleModalClose = () => {
        store.set(store, 'modalIsVisible', false);
        setTimeout(() => {
            store.reservation.update({
                startAt: '',
                endAt: '',
            });
        }, 280);
    }

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={'Бронирование'}/>
                <div className="page-reservations__wrap">
                    <div className="page-reservations__aside">
                        <UiCalendar
                            value={urlParams.date}
                            onChange={handleDateChange}
                        />
                    </div>
                    <div className="page-reservations__content">
                        <UiSliderCasual
                            perView={perView}
                            items={store.reservationPlaces}
                            slide={item => (
                                <UiReservationPlace
                                    reservationPlace={item}
                                    reservation={store.reservation}
                                    date={urlParams.date}
                                    onDelete={handleDelete}
                                    onEdit={handleEdit}
                                    onSelected={() => {
                                        store.set(store, 'modalIsVisible', true);
                                        store.set(store, 'errorMessage', '');
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <UiModal
                    isOpened={store.modalIsVisible}
                    title={selectedReservationPlace.name}
                    onClose={handleModalClose}
                >
                    <UiDataBoundary isLoading={store.modalIsLoading}>
                        <UiForm
                            onSubmit={handleSubmit}
                        >
                            {store.isUsersPick && (
                                <>
                                    <UiUsersSearch
                                        onChange={handleChange}
                                        name='users'
                                        value={store.reservation.users}
                                    />
                                </>
                            )}
                            {!store.isUsersPick && (
                                <>
                                    <div className="page-reservations-modal__time">{store.reservation.time}</div>
                                    <div className="page-reservations-modal__field">
                                        <UiSelect
                                            placeholder='Введите описание'
                                            name='reservationPlaceId'
                                            onChange={handleChange}
                                            value={store.reservation.reservationPlaceId}
                                            items={store.reservationPlaces}
                                        />
                                    </div>
                                    <div className="page-reservations-modal__field">
                                        <UiFormControl
                                            errorMessage={store.isSubmitted && validation.name.errorMessage}
                                            style={{ marginBottom: 30 }}
                                        >
                                            <UiInput
                                                placeholder='Добавьте название *'
                                                name='name'
                                                isPlaceholderFluid
                                                onChange={handleChange}
                                                value={store.reservation.name}
                                                isError={store.isSubmitted && !validation.name.isValid}
                                            />
                                        </UiFormControl>
                                    </div>
                                    <div className="page-reservations-modal__field">
                                        <UiTextarea
                                            placeholder='Введите описание'
                                            name='description'
                                            onChange={handleChange}
                                            value={store.reservation.description}
                                        />
                                    </div>
                                    <div
                                        className="page-reservations-modal__field page-reservations-modal__field--time"
                                    >
                                        <UiFormControl
                                            label={'Дата'}
                                        >
                                            <UiDatePicker
                                                onChange={(data) => {
                                                    const date = moment(data.value);
                                                    const endAtMoment = store.reservation.endAtMoment.clone()
                                                        .set('date', date.date())
                                                        .set('month', date.month())
                                                        .set('year', date.year());
                                                    if (store.reservation.endAtMoment.hours() === 0) {
                                                        endAtMoment.add('day', 1);
                                                    }
                                                    store.reservation.update({
                                                        startAt: store.reservation.startAtMoment.clone()
                                                            .set('date', date.date())
                                                            .set('month', date.month())
                                                            .set('year', date.year())
                                                            .format(),
                                                        endAt: endAtMoment.format()
                                                    });
                                                }}
                                                value={store.reservation.startAt}
                                            />
                                        </UiFormControl>
                                        <UiFormControl
                                            label={'От'}
                                            errorMessage={validation.startAt.errorMessage}
                                        >
                                            <UiTimePicker
                                                isReadOnly
                                                onChange={(data) => {
                                                    const startAtMoment = store.reservation.startAtMoment.clone()
                                                        .set('hours', data.hours)
                                                        .set('minutes', data.minutes);
                                                    const diff = startAtMoment.diff(store.reservation.startAtMoment, 'minutes');
                                                    const endAtMoment = store.reservation.endAtMoment
                                                        .clone()
                                                        .add('minutes', diff);
                                                    const midnight = startAtMoment.clone()
                                                        .add('day', 1)
                                                        .set('hours', 0)
                                                        .set('minutes', 0);
                                                    store.reservation.update({
                                                        startAt: startAtMoment.format(),
                                                        endAt: endAtMoment.isBefore(midnight)
                                                            ? endAtMoment.format()
                                                            : midnight.format()
                                                    });
                                                }}
                                                value={store.reservation.startAtMoment.format('HH:mm')}
                                            />
                                        </UiFormControl>
                                        <UiFormControl
                                            label={'До'}
                                            errorMessage={validation.startAt.errorMessage}
                                        >
                                            <UiTimePicker
                                                isReadOnly
                                                onChange={(data) => {
                                                    const moment = store.reservation.startAtMoment.clone()
                                                        .set('hours', data.hours)
                                                        .set('minutes', data.minutes);
                                                    if (data.hours === 0 && data.minutes === 0) {
                                                        //is midnight
                                                        moment.add('day', 1);
                                                    }
                                                    store.reservation.update({
                                                        endAt: moment.format()
                                                    })
                                                }}
                                                items={(items) => [...items, '00:00']}
                                                value={store.reservation.endAtMoment.format('HH:mm')}
                                            />
                                        </UiFormControl>

                                    </div>
                                    <div className="page-reservations-modal__users">
                                        {store.reservation.users.map(user => (
                                            <UiUserBadge
                                                key={user.id}
                                                width={144}
                                                value={user}
                                                isSmall
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                            <div className="page-reservations-modal__actions">
                                {store.isUsersPick && (
                                    <UiButton
                                        isLink
                                        isSmall
                                        label='Назад'
                                        onClick={() => store.set(store, 'isUsersPick', false)}
                                    />
                                )}
                                {!store.isUsersPick && store.reservation.users.length === 0 && (
                                    <UiButton
                                        isLink
                                        isSmall
                                        label='Добавить участников'
                                        onClick={() => store.set(store, 'isUsersPick', true)}
                                        iconAfter={
                                            <UiIcon icon={ICONS.PLUS} size={12} color={COLORS.BROWN_1}/>
                                        }
                                    />
                                )}
                                {!store.isUsersPick && store.reservation.users.length > 0 && (
                                    <UiButton
                                        isLink
                                        isSmall
                                        label='Редактировать'
                                        onClick={() => store.set(store, 'isUsersPick', true)}
                                    />
                                )}

                            </div>
                            <div className="ui-modal__actions">
                                <UiFormControl errorMessage={store.errorMessage} center size={'large'}>
                                    <UiButton
                                        isLoading={store.isLoading}
                                        isSubmit
                                        style={{ width: 250 }}
                                        label='Забронировать'
                                        onClick={() => store.set(store, 'isUsersPick', false)}
                                    />
                                </UiFormControl>
                                <UiButton
                                    label='Отмена'
                                    onClick={handleModalClose}
                                    isOutline
                                />
                            </div>
                        </UiForm>
                    </UiDataBoundary>
                </UiModal>
            </LayoutContent>
        </LayoutBody>
    );
});
