import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { SpaceModel } from "shared/models/TaskTracker";
import { UiButton, UiDataBoundary, UiForm, UiFormControl, UiInput, UiModal, UiModalTitle } from "shared/uikit";
import { useNavigate, useStore, useUrlParams, useValidation } from "shared/hooks";
import { spacesGetQuery, spacesSaveQuery } from "shared/queries/TaskTracker";
import { OnChangeHandlerType } from "shared/types";

import './index.scss';

type PropsType = {
    id: string | number | null
    onSaved: (item: SpaceModel) => void
}

export const CSpaceSave = observer(({ id, onSaved }: PropsType) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({});
    const store = useStore(() => ({
        isOpened: false,
        isLoading: true,
        isSubmitted: false,
        isSubmitting: false,
        space: new SpaceModel()
    }));

    useEffect(() => {
        if (!id) {
            return;
        }
        store.set("isLoading", true);

        if (id === 'new') {
            store.set("space", new SpaceModel());
            store.set("isLoading", false);
            return;
        }

        (async () => {
            const { isSuccess, data } = await spacesGetQuery({ id: +id as number });
            if (isSuccess && data) {
                store.set('space', new SpaceModel(data.item));
            }
            store.set("isLoading", false);
        })();
    }, [store, id]);

    const validation = useValidation(store.space, (rules) => ({
        name: rules.required(),
    }));

    const handleClose = () => {
        if (store.isSubmitting) {
            return;
        }
        navigate(null, {
            ...urlParams,
            saveSpaceId: null,
            boardId: null
        });

        setTimeout(() => {
            store.set('space', new SpaceModel());
            store.set('isSubmitted', false);
        }, 1000);
    }

    const handleSubmit = async () => {
        store.set("isSubmitted", true);

        if (store.isSubmitting || !validation.isValid) {
            return;
        }

        store.set("isSubmitting", true);

        const { isSuccess, data } = await spacesSaveQuery(store.space);
        if (isSuccess && data) {
            onSaved(new SpaceModel(data.item))
        }
        store.set("isSubmitting", false);
        handleClose();
    }

    const handleChange: OnChangeHandlerType<any> = (data) => {
        store.space[data.name] = data.value;
    }

    return (
        <UiModal
            styleBody={{ width: 640 }}
            isOpened={id !== null && !store.isLoading}
            onClose={handleClose}
            isPortal={false}
        >
            <UiModalTitle
                title={id === 'new' ? 'Новое пронстранство' : (id === null ? '' : 'Редактирование пространства')}
            />
            <UiDataBoundary isLoading={store.isLoading}>
                <UiForm onSubmit={handleSubmit}>
                    <UiFormControl
                        label={'Название'}
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.name.errorMessage}
                        isRequired
                    >
                        <UiInput
                            placeholder={'Введите название простанства'}
                            name={'name'}
                            value={store.space.name}
                            onChange={handleChange}
                        />
                    </UiFormControl>
                    <div className={'ui-modal__actions'}>
                        <UiButton
                            isOutline
                            onClick={handleClose}
                            label={'Отмена'}
                        />
                        <UiButton
                            isLoading={store.isSubmitting}
                            isSubmit
                            label={'Сохранить'}
                        />
                    </div>
                </UiForm>
            </UiDataBoundary>
        </UiModal>
    )
});

