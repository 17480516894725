import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { OnChangeHandlerType } from 'shared/types';
import { ITagModel } from "shared/models";

import './styles.scss';

type PropsType = {
    items?: ITagModel[];
    name?: string;
    value?: string | number | null;
    onChange?: OnChangeHandlerType<string | number | null>;
}

export const UiTagsSelect = observer(({ items = [], name = 'ui-tags-select', value, onChange }: PropsType) => {
    const handleChange = (id: number | null | string) => {
        onChange && onChange({
            name,
            value: `${value || ''}` !== `${id || ''}` ? id : null
        });
    }
    return (
        <div className="ui-tags-select">
            {items.map(item => {
                const classNames = classnames('ui-tags-select__item', {
                    'ui-tags-select__item--active': `${value || ''}` === `${item.id || ''}`
                });
                return (
                    <div key={item.id} className={classNames} onClick={() => item.id && handleChange(item.id)}>
                        {item.name}
                    </div>
                )
            })}
        </div>
    )
});
