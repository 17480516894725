import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import Lottie from "lottie-react";

import loader from './assets/loader.json';

import './styles.scss';

type PropsType = {
    height?: number,
    style?: CSSProperties
}
export const UiLoadingSpinner = observer(({ style, height = 90 }: PropsType) => {
    return (
        <div
            className='ui-loading-spinner'
            style={style}
        >
            <Lottie style={{height}} animationData={loader}></Lottie>
        </div>
    );
});
