import { action, computed, makeObservable, observable } from 'mobx';

import { NullableType } from 'shared/types';
import { ModelCast } from "shared/casts";

import { Model } from './Model';
import { VacancyRegionModel } from './VacancyRegionModel';

export interface IVacancyModel {
    id?: number;
    createdAt?: string;
    name?: string;
    previewText?: string;
    detailText?: string;
    liked?: number;
    favorited?: number;
    vacancyRegionId?: number;
    salaryFrom?: NullableType<number>;
    salaryTo?: NullableType<number>;
    contactName?: string;
    contactEmail?: string;
    contactInfo?: string;
    addressColor?: string;
    addressPreview?: string;
    vacancyRegion?: VacancyRegionModel;
}

export class VacancyModel extends Model implements IVacancyModel {
    casts = {
        vacancyRegion: new ModelCast(VacancyRegionModel),
    };

    id = 0;
    createdAt = '';
    name = '';
    previewText = '';
    detailText = '';
    departmentId = 0;
    addressLatLng = {
        lat: 0,
        lng: 0,
    };
    liked = 0;
    favorited = 0;
    vacancyRegionId = 0;
    salaryFrom = null;
    salaryTo = null;
    contactName = '';
    contactEmail = '';
    contactInfo = '';
    addressColor = '';
    addressPreview = '';

    vacancyRegion = new VacancyRegionModel();

    constructor(payload: IVacancyModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            name: observable,
            previewText: observable,
            detailText: observable,
            departmentId: observable,
            salaryFrom: observable,
            salaryTo: observable,
            contactName: observable,
            contactEmail: observable,
            contactInfo: observable,
            addressColor: observable,
            addressPreview: observable,
            vacancyRegion: observable,
            liked: observable,
            favorited: observable,
            update: action,
            hasContacts: computed,
        });

        this.update(payload);
    }

    get salaryFormatted() {
        switch (true) {
            case typeof this.salaryFrom === 'number' && typeof this.salaryTo === 'number':
                return `${this.salaryFrom} – ${this.salaryTo} ₽`;
            case typeof this.salaryFrom === 'number':
                return `от ${this.salaryTo} ₽`;
            case typeof this.salaryTo === 'number':
                return `до ${this.salaryTo} ₽`;
        }
        return 'Не указано';
    }

    get hasContacts() {
        return this.contactName || this.contactEmail || this.contactInfo;
    }
}
