import type { Identifier, XYCoord } from 'dnd-core'
import React, { useRef } from 'react';
import { runInAction } from "mobx";
import { observer } from 'mobx-react';
import { useDrag, useDrop } from 'react-dnd'

import { UiCheckboxSwitch, UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";

import './styles.scss';

type PropsType = {
    index: number,
    onDrag: (dragIndex: number, hoverIndex: number) => void,
    onDrop: () => void,
    onChange: () => void,
    item: {
        id: string,
        name: string,
        value: number,
    }
}

type DragItemType = {
    index: number
    id: string
    type: string
}

export const ProfilePageWidgetsItem = observer(({ item, index, onDrag, onDrop, onChange }: PropsType) => {
    const handleChange = ({ value }: { value: number }) => {
        runInAction(() => {
            item.value = value;
            onChange();
        })
    }

    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<DragItemType, void, { handlerId: Identifier | null }>({
        accept: 'item',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItemType, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            onDrag(dragIndex, hoverIndex);
            item.index = hoverIndex
        },
        drop() {
            onDrop();
        }
    })

    const [{ opacity }, drag, preview] = useDrag({
        type: 'item',
        item: () => {
            return { id: item.id, index }
        },
        collect: (monitor: any) => ({
            opacity: monitor.isDragging() ? 0.2 : 1,
        }),
    })

    drop(ref);
    return (
        <div ref={ref} className="p-profile-widgets-item" style={{ opacity }}>
            <div ref={preview} className="p-profile-widgets-item__inner">
                <div className="p-profile-widgets-item__index">{index + 1}</div>
                <div ref={drag} className="p-profile-widgets-item__dnd" data-handler-id={handlerId}>
                    <UiIcon icon={ICONS.DND_DOTS} size={16} color={COLORS.GRAY_1}/>
                </div>
                <div className="p-profile-widgets-item__name">{item.name}</div>
                <div className="p-profile-widgets-item__control">
                    <UiCheckboxSwitch value={item.value} onChange={handleChange}/>
                </div>
            </div>
        </div>
    );
});
