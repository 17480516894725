import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { SearchIndexModel } from 'shared/models';

import { UiButton, UiDate, UiFileIcon, UiHtml, UiTag, UiNavLink } from 'shared/uikit';
import { useNavigate, useUrlParams } from 'shared/hooks';
import { ROUTES } from 'shared/constants';
import { chatRoomsCreateQuery } from "shared/queries";

import './styles.scss';

interface Props {
    item: SearchIndexModel;
}

export const SearchItem = observer(({ item }: Props) => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        query: '',
    });

    const handleTagClick = useCallback(() => {
        navigate(ROUTES.SEARCH(), {
            typeId: item.typeId,
            query: urlParams.query,
        });
    }, [item.typeId, navigate, urlParams.query]);

    const handleChatRoomCreate = useCallback(async (userId: number) => {
        const { isSuccess, data } = await chatRoomsCreateQuery({
            userId,
            typeId: 'direct'
        });
        if (isSuccess && data) {
            navigate(ROUTES.CHAT(data.item.id));
        }
    }, [navigate]);

    if (item.typeId === 'user') {
        return (
            <div  className='search-item'>
                <div className='search-item__inner'>
                    <div className='search-item__header'>
                        <UiTag value={item.tag} onClick={handleTagClick}/>
                    </div>
                    <UiNavLink className='search-item__title' to={item.href}>{item.name}</UiNavLink>
                    {item.previewText && (
                        <UiHtml className='search-item__description' value={item.previewText}></UiHtml>
                    )}
                    <div className="search-item__footer">
                        <UiButton
                            isLink
                            isSmall
                            label='Перейти в чат'
                            onClick={() => handleChatRoomCreate(item.indexableId)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if (item.typeId === 'document') {
        const extension = item.href.split('.').reverse()[0].toLowerCase();
        return (
            <a href={item.href} target="_blank" rel='noreferrer' download={`doc.${extension}`} className='search-item'>
                <div className='search-item__inner'>
                    <div className='search-item__header'>
                        <UiDate value={item.createdAt}/>
                    </div>
                    <div className='search-item__title'>{item.name}</div>
                </div>
                <div className="search-item__preview">
                    <UiFileIcon extension={extension} size={99}/>
                </div>
            </a>
        );
    }

    if (item.typeId === 'department') {
        return (
            <div  className='search-item'>
                <div className='search-item__inner'>
                    <UiNavLink className='search-item__title' to={item.href}>{item.name}</UiNavLink>
                    {item.previewText && (
                        <UiHtml className='search-item__description' value={item.previewText}></UiHtml>
                    )}
                </div>
            </div>
        );
    }

    return (
        <UiNavLink to={item.href} className='search-item'>
            <div className='search-item__inner'>
                <div className='search-item__header'>
                    <UiTag value={item.tag} onClick={handleTagClick}/>
                    <UiDate value={item.createdAt}/>
                </div>
                <div className='search-item__title'>{item.name}</div>
                {item.previewText && <div className='search-item__description'>{item.previewText}</div>}
            </div>
            {item.previewImageUrl && (
                <div className='search-item__preview' style={{ backgroundImage: `url(${item.previewImageUrl})` }}/>
            )}
        </UiNavLink>
    );
});
