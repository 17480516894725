import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useDebounce, useNavigate, useStore, useUrlParams } from "shared/hooks";

import {
    UiButton,
    UiDataBoundary,
    UiDatePicker,
    UiFormControl,
    UiIcon,
    UiInput,
    UiPagination,
    UiSelect,
    UiUserSelect
} from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import { tasksBatchQuery, tasksFiltersQuery, tasksQuery } from "shared/queries/TaskTracker";
import { TaskTrackerTaskStatusEnum } from "shared/enums";
import { SpaceModel, TaskModel } from "shared/models/TaskTracker";
import { CListHeaderArchive, CTaskPreview } from "shared/components/TaskTracker";
import { CTaskArchive } from "shared/components/TaskTracker/CTaskArchive";

import './index.scss';
import { Notifier } from "shared/utilities";

export const TaskTrackerArchivePage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        page: 1,
        previewTaskId: null as number | null,
        fromSpaceId: null as number | null,
        spaceId: null as number | null,
        ownerUserId: null as number | null,
        responsibleUserId: null as number | null,
        query: null as string | null,
        sortBy: null as string | null,
        sortType: null as string | null,
        dateFrom: null as string | null,
        dateTo: null as string | null
    });

    const store = useStore(() => ({
        isLoading: false,
        isOpened: false,
        pages: 0,
        spaces: [] as SpaceModel[],
        tasks: [] as TaskModel[],
        query: urlParams.query || ''
    }));

    const applyQuery = useDebounce(() => {
        navigate(null, {
            ...urlParams,
            query: store.query
        })
    }, 1500)

    const fetchTasks = useCallback(async () => {
        const { isSuccess, data } = await tasksQuery({
            statusId: TaskTrackerTaskStatusEnum.Archive.id as string,
            spaceId: urlParams.spaceId,
            ownerUserId: urlParams.ownerUserId,
            responsibleUserId: urlParams.responsibleUserId,
            query: urlParams.query,
            dateFrom: urlParams.dateFrom,
            dateTo: urlParams.dateTo,
            sortBy: urlParams.sortBy,
            sortType: urlParams.sortType,
            page: urlParams.page,
        });
        if (isSuccess && data) {
            store.set("tasks", data.items.map(task => new TaskModel(task)));
            store.set("pages", data.pages);
        }
        store.set("isLoading", false);
    }, [
        store,
        urlParams.spaceId,
        urlParams.ownerUserId,
        urlParams.responsibleUserId,
        urlParams.query,
        urlParams.dateFrom,
        urlParams.dateTo,
        urlParams.sortBy,
        urlParams.sortType,
        urlParams.page,
    ]);

    useEffect(() => {
        (async () => {
            const { isSuccess, data } = await tasksFiltersQuery();
            if (isSuccess && data) {
                store.set("spaces", data.spaces.map(space => new SpaceModel(space)));
            }
        })();
    }, [store]);

    useEffect(() => {
        store.set("isLoading", true);

        fetchTasks()
    }, [store, fetchTasks]);

    const handleChange = ({ name, value }: { name: string, value: any }) => {
        navigate(null, {
            ...urlParams,
            [name]: value
        })
    };

    const hasResetButton = () => {
        return urlParams.spaceId ||
            urlParams.ownerUserId ||
            urlParams.responsibleUserId ||
            urlParams.query ||
            urlParams.dateFrom ||
            urlParams.dateTo ||
            urlParams.sortBy ||
            urlParams.sortType;
    }

    const handleReset = () => {
        navigate(null, {
            page: 1,
            previewTaskId: null,
            fromSpaceId: null,
            spaceId: null,
            ownerUserId: null,
            responsibleUserId: null,
            query: null,
            sortBy: null,
            sortType: null,
            dateFrom: null,
            dateTo: null,
        })
    }

    const isAnySelected = store.tasks.some(task => task.isSelected);

    const handleRestoreSelected = async () => {
        const selected = store.tasks.filter(task => task.isSelected);
        const result = await Notifier.confirm(
            'Восстановление из архива',
            'Подтвердите восстановление выбранных задач'
        );
        if (!result) {
            return;
        }
        store.set('isLoading', true);
        await tasksBatchQuery({
            action: 'restore',
            id: selected.map(task => task.id)
        });
        fetchTasks();
    }

    const handleDeleteSelected = async () => {
        const selected = store.tasks.filter(task => task.isSelected);
        const result = await Notifier.confirm(
            'Удаление',
            'Подтвердите удаление выбранных задач'
        );
        if (!result) {
            return;
        }
        store.set('isLoading', true);
        await tasksBatchQuery({
            action: 'delete',
            id: selected.map(task => task.id)
        });
        fetchTasks();
    }

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle
                    title={'Архив задач'}
                    backTo={() => {
                        navigate(ROUTES.TASK_TRACKER(urlParams.fromSpaceId || 'personal'));
                    }}
                />
                <div className="p-task-tracker-archive">
                    <div className="p-task-tracker-archive-filter">
                        <div className="p-task-tracker-archive-filter__header">
                            <div className="p-task-tracker-archive-filter__title">Фильтр</div>
                            {hasResetButton() && (
                                <UiButton
                                    isLink
                                    label={'Сбросить'}
                                    iconAfter={<UiIcon icon={ICONS.CLOSE} size={13}/>}
                                    onClick={handleReset}
                                />
                            )}
                        </div>
                        <div className="p-task-tracker-archive-filter__main">
                            <div className="p-task-tracker-archive-filter__space">
                                <UiFormControl label={'Пространство'}>
                                    <UiSelect
                                        items={[
                                            { id: null, name: 'Все пространства' },
                                            ...store.spaces
                                        ]}
                                        name={'spaceId'}
                                        value={urlParams.spaceId}
                                        onChange={handleChange}
                                    />
                                </UiFormControl>
                            </div>
                            <div className="p-task-tracker-archive-filter__query">
                                <UiInput
                                    icon={<UiIcon size={20} icon={ICONS.SEARCH} color={COLORS.BLACK}/>}
                                    placeholder={'Поиск по названию'}
                                    value={store.query}
                                    onChange={({ value }) => {
                                        store.set("query", value);
                                        store.set("isLoading", true);
                                        applyQuery()
                                    }}
                                />
                            </div>
                        </div>
                        {store.isOpened && (
                            <div className="p-task-tracker-archive-filter__additional">
                                <div className="p-task-tracker-archive-filter__group">
                                    <UiFormControl label={'От'}>
                                        <UiDatePicker
                                            name={'dateFrom'}
                                            value={urlParams.dateFrom}
                                            onChange={handleChange}
                                        />
                                    </UiFormControl>
                                    <UiFormControl label={'До'}>
                                        <UiDatePicker
                                            name={'dateTo'}
                                            value={urlParams.dateTo}
                                            onChange={handleChange}
                                        />
                                    </UiFormControl>
                                </div>
                                <div className="p-task-tracker-archive-filter__group">
                                    <UiFormControl label={'Ответственный'}>
                                        <UiUserSelect
                                            name={'responsibleUserId'}
                                            isMultiple={false}
                                            value={urlParams.responsibleUserId}
                                            onChange={handleChange}
                                        />
                                    </UiFormControl>
                                    <UiFormControl label={'Исполнитель'}>
                                        <UiUserSelect
                                            name={'ownerUserId'}
                                            isMultiple={false}
                                            value={urlParams.ownerUserId}
                                            onChange={handleChange}
                                        />
                                    </UiFormControl>
                                </div>
                            </div>
                        )}
                        <div className="p-task-tracker-archive-filter__footer">
                            <UiButton
                                isLink
                                label={store.isOpened ? 'Свернуть' : 'Показать больше'}
                                iconAfter={
                                    <UiIcon
                                        icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
                                        size={13}
                                    />
                                }
                                onClick={() => {
                                    store.set("isOpened", !store.isOpened)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classnames("p-task-tracker-archive__outer", {
                    'p-task-tracker-archive__outer--items': !!store.tasks.length && !store.isLoading,
                })}>
                    <div className="p-task-tracker-archive__inner">
                        {(!!store.tasks.length && !store.isLoading) && (
                            <CListHeaderArchive
                                tasks={store.tasks.filter(task => task.status.is(TaskTrackerTaskStatusEnum.Archive))}
                            />
                        )}
                        <UiDataBoundary isLoading={store.isLoading}>
                            {!store.tasks.length && (
                                <div className="p-task-tracker-archive__empty">
                                    <img src={require('./assets/empty.png')} alt=""/>
                                    <span>Задачи не найдены</span>
                                </div>
                            )}
                            {!!store.tasks.length && store.tasks.filter(task => task.status.is(TaskTrackerTaskStatusEnum.Archive)).map(task => (
                                <CTaskArchive
                                    key={task.id}
                                    task={task}
                                    onUpdate={(task) => {
                                        store.set("tasks", [...store.tasks.filter(b => +task.id !== +b.id), task]);
                                    }}
                                    onDelete={(task) => {
                                        store.set("tasks", store.tasks.filter(b => +task.id !== +b.id));
                                    }}
                                />
                            ))}
                            {isAnySelected && (
                                <div className={'p-task-tracker-archive__actions'}>
                                    <UiButton
                                        label={'Вернуть на доску'}
                                        isOutline
                                        onClick={handleRestoreSelected}
                                    />
                                    <UiButton
                                        label={'Удалить выбранные'}
                                        isDanger
                                        onClick={handleDeleteSelected}
                                        iconBefore={
                                            <UiIcon icon={ICONS.TRASH} size={14}/>
                                        }
                                    />
                                </div>
                            )}
                        </UiDataBoundary>
                        <UiPagination
                            page={urlParams.page}
                            pages={store.pages}
                            onChange={(data) => {
                                navigate(null, {
                                    ...urlParams,
                                    page: data.value
                                })
                            }}
                        />
                    </div>
                </div>
                <CTaskPreview
                    id={urlParams.previewTaskId}
                    onUpdate={(task) => {
                        store.set("tasks", [...store.tasks.filter(b => +task.id !== +b.id), task]);
                    }}
                    onDelete={(task) => {
                        store.set("tasks", store.tasks.filter(b => +task.id !== +b.id));
                    }}
                />
            </LayoutContent>
        </LayoutBody>
    );
});


