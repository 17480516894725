import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { ModelArrayCast, ModelCast } from "shared/casts";

import { Model } from './Model';
import { UserModel } from "./UserModel";
import { ReservationIntervalModel } from "./ReservationIntervalModel";
import { ReservationPlaceModel } from "./ReservationPlaceModel";

export interface IReservationModel {
    id?: number;
    name?: string;
    description?: string;
    startAt?: string;
    endAt?: string;
    reservationPlaceId?: number;
    user?: UserModel;
    users?: UserModel[];
    reservationIntervals?: ReservationIntervalModel[];
    reservationPlace?: ReservationPlaceModel | null;
}

export class ReservationModel extends Model implements IReservationModel {
    casts = {
        user: new ModelCast(UserModel),
        users: new ModelArrayCast(UserModel),
        reservationIntervals: new ModelArrayCast(ReservationIntervalModel),
        reservationPlace: new ModelCast(ReservationPlaceModel),
    };

    id = 0;
    name = '';
    description = '';
    reservationPlaceId = 0;
    startAt = '';
    endAt = '';
    user = new UserModel();
    users: UserModel[] = [];
    reservationIntervals: ReservationIntervalModel[] = [];
    reservationPlace = new ReservationPlaceModel();

    constructor(payload: IReservationModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            description: observable,
            reservationPlaceId: observable,
            user: observable,
            startAt: observable,
            endAt: observable,
            startAtMoment: computed,
            startAtTime: computed,
            endAtMoment: computed,
            intervals: computed,
            users: observable,
            reservationIntervals: observable,
            reservationPlace: observable,
            update: action,
            time: computed
        });

        this.update(payload);
    }

    get startAtMoment() {
        return moment(this.startAt);
    }

    get startAtTime() {
        return this.startAtMoment.format('HH:mm');
    }

    get endAtMoment() {
        return moment(this.endAt);
    }

    get endAtTime() {
        return this.endAtMoment.format('HH:mm');
    }

    get intervals() {
        const intervals = [];

        const current = this.startAtMoment.clone();
        while (current.isBefore(this.endAtMoment)) {
            intervals.push(current.format('HH:mm'));
            current.add(30, 'minutes');
        }

        return intervals;
    }

    get time() {
        return `${this.startAtMoment.format('D MMMM')} ${this.startAtTime}-${this.endAtTime}`
    }
}
