import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { NotificationModel } from "shared/models";
import { ICONS } from "shared/constants";
import { UiButton } from "shared/uikit";
import { useNavigate } from "shared/hooks";

import { UiIcon } from "../UiIcon";

import { UiNotificationIcon } from "./components/UiNotificationIcon";
import { UiNotificationContent } from "./components/UiNotificationContent";

import './styles.scss';


type PropsType = {
    value: NotificationModel,
    isLight?: boolean,
    onDelete?: (notification: NotificationModel) => void
}

export const UiNotification = observer(({ value: notification, isLight = false, onDelete }: PropsType) => {
    const navigate = useNavigate();
    const className = classnames('ui-notification', {
        'ui-notification--light': isLight,
        'ui-notification--unread': notification.isUnread,
    });

    return (
        <div className={className}>
            <div className="ui-notification__icon">
                <UiNotificationIcon value={notification} isLight={isLight}/>
            </div>
            <div className="ui-notification__header">
                <div className="ui-notification__date">{notification.createdAtFormatted()}</div>
                {!isLight && (
                    <div className="ui-notification__close" onClick={() => onDelete && onDelete(notification)}>
                        <UiIcon icon={ICONS.CLOSE} size={isLight ? 19 : 20}/>
                    </div>
                )}
                <div className="ui-notification__name">
                    {notification.content.name}
                </div>
            </div>
            <div className="ui-notification__content">
                <UiNotificationContent value={notification} isLight={isLight}/>
            </div>
            {notification.content.action && (
                <div className="ui-notification__footer">
                    <UiButton isLink label={notification.content.action} onClick={() => {
                        navigate(notification.content.url);
                    }}/>
                </div>
            )}
        </div>
    )
})
