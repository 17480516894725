import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { COLORS, ICONS } from "shared/constants";
import { useStore, useUrlParams } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ColumnModel } from "shared/models/TaskTracker";
import { columnsSaveQuery } from "shared/queries/TaskTracker";

import './index.scss';

type PropsType = {
    boardId: number,
    onCreated: (column: ColumnModel) => void
}
export const CColumnCreate = observer(({ boardId, onCreated }: PropsType) => {
    const ref = useRef<HTMLInputElement>(null);
    const store = useStore(() => ({
        boardId,
        name: '',
        isCreating: false,
        isSubmitting: false,
    }));

    const urlParams = useUrlParams({
        view: 'board'
    });

    const handleCreate = () => {
        store.set("name", 'Новая колонка');
        store.set("isCreating", true);
        setTimeout(() => {
            ref.current?.setSelectionRange(0, 200);
        }, 10);
    }

    const handleSubmit = async () => {
        if (store.isSubmitting) {
            return;
        }

        if (!store.name.trim()) {
            store.set("isCreating", false);
            return;
        }

        store.set("isSubmitting", true);

        const { isSuccess, data } = await columnsSaveQuery({
            boardId,
            sort: parseInt(`${Date.now() / 1000}`),
            name: store.name,
        });
        if (isSuccess && data) {
            onCreated(new ColumnModel(data.item))
        }
        store.set("isSubmitting", false);
        store.set("isCreating", false);
    }

    if (store.isCreating) {
        return (
            <input
                className={'c-tt-column-create-input'}
                ref={ref}
                type="text"
                autoFocus
                onBlur={handleSubmit}
                onKeyUp={(e) => {
                    if (e.key.toLocaleLowerCase() === 'escape') {
                        store.set("isCreating", false);
                    }
                    if (e.key.toLocaleLowerCase() === 'enter') {
                        handleSubmit();
                    }
                }}
                value={store.name}
                name={'name'}
                onChange={(e) => {
                    store.set("name", e.target.value || '');
                }}
            />
        )
    }

    return (
        <div
            key={`c-tt-column-create-button--${urlParams.view}`}
            className={classnames("c-tt-column-create-button", `c-tt-column-create-button--${urlParams.view}`)}
            onClick={handleCreate}
        >
            <span>Добавить колонку</span>
            <div className="c-tt-column-create-button__icon">
                <UiIcon icon={ICONS.PLUS} size={14} color={COLORS.BLACK}/>
            </div>
        </div>
    )
});

