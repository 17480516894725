import { action, computed, makeObservable, observable } from 'mobx';
import moment from "moment";

import { ModelCast } from "shared/casts";
import { ThumbnailType } from "shared/types";

import { UserModel } from "./UserModel";
import { Model } from './Model';

export interface IChatMessageAttachmentModel {
    id?: number;
    chatMessageId?: number;
    extension?: string;
    createdAt?: string;
    typeId?: 'image' | 'file' | 'url';
    content?: string | number;
    description?: string | number;
    contentThumbnail?: ThumbnailType;
}

export class ChatMessageAttachmentModel extends Model implements IChatMessageAttachmentModel {
    casts = {
        user: new ModelCast(UserModel)
    }

    id = 0;
    chatMessageId = 0;
    typeId: 'image' | 'file' | 'url' = 'image';
    extension = '';
    content = '';
    createdAt = '';
    description = '';
    contentThumbnail = null as null | ThumbnailType;

    constructor(payload: IChatMessageAttachmentModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            chatMessageId: observable,
            typeId: observable,
            content: observable,
            contentThumbnail: observable,
            createdAt: observable,
            extension: observable,
            description: observable,
            createdAtMoment: computed,
            hostname: computed,
            update: action,
        });

        this.update(payload);
    }

    get createdAtMoment() {
        return moment(this.createdAt);
    }

    get hostname() {
        if (this.typeId !== 'url') {
            return '';
        }
        const { hostname } = new URL(this.content);
        return hostname;
    }
}
