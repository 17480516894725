import { makeQuery } from 'shared/utilities';
import { ICommentModel } from 'shared/models';

type PayloadType = {
    text?: string,
};

type ResponseType = {
    item: ICommentModel;
};

export const faqResponsesCreateQuery = async function (payload: PayloadType) {
    return await makeQuery<ResponseType>('/faq-responses/create', { json: payload });
}
