import { makeAutoObservable } from "mobx";
import moment from "moment";

import { CalendarItemModel, ReservationModel, ReservationPlaceModel } from 'shared/models';
import {
    calendarItemSaveQuery,
    calendarItemGetQuery,
    calendarItemQuery,
    reservationPlacesQuery,
    reservationsQuery,
} from "shared/queries";
import { Notifier } from "shared/utilities";

import { Store } from './Store';
export const Service = new class {
    constructor() {
        makeAutoObservable(this);
    }

    getReservationPlaces = async (date: string) => {
        const response = await reservationPlacesQuery(date);
        if (response.isSuccess && response.data) {
            Store.set(Store, 'reservationPlaces', response.data.items.map(item => new ReservationPlaceModel(item)));
        }
    };

    getReservations = async (date: string) => {
        const response = await reservationsQuery(date);
        if (response.isSuccess && response.data) {
            Store.set(Store, 'reservations', response.data.items.map(item => new ReservationModel(item)));
        }
    };

    getCalendarItems = async () => {
        Store.set(Store, '_calendarItems', []);
        Store.set(Store, 'isLoading', true);
        const response = await calendarItemQuery({
            dateFrom: Store.dateFrom.format('Y-MM-DD'),
            dateTo: Store.dateTo.format('Y-MM-DD'),
        });

        if (response.isSuccess && response.data) {
            Store.set(Store, '_calendarItems', response.data.items.map(item => new CalendarItemModel(item)));
        }
        Store.set(Store, 'isLoading', false);
    }

    getCalendarItem = async ({ id, changeKey }: {id: string, changeKey: string}) => {
        if (!changeKey) {
            return;
        }
        Store.set(Store, 'calendarItem', null);
        Store.set(Store.modals.preview, 'isOpened', true);
        const response = await calendarItemGetQuery({ id, changeKey });

        if (response.isSuccess && response.data) {
            Store.set(Store, 'calendarItem', new CalendarItemModel(response.data.item));
        }
    }

    saveCalendarItem = async () => {
        if (!Store.calendarItem) {
            return;
        }
        Store.set(Store.modals.edit, 'isLoading', true);
        const response = await calendarItemSaveQuery(Store.calendarItem);

        if (!response.isSuccess || !response.data) {
            Notifier.alert('Ошибка', response.errorMessage);
            Store.set(Store.modals.edit, 'isLoading', false);
            return;
        }
        Store.set(Store, 'calendarItem', new CalendarItemModel(response.data.item));
        const items = [...Store.calendarItems].filter(item => item.id !== response.data?.item.id);
        items.push(new CalendarItemModel(response.data.item));
        Store.set(Store, '_calendarItems', items);
        Store.set(Store.modals.edit, 'isOpened', false);
        Store.set(Store.modals.preview, 'isOpened', true);
        Store.set(Store.modals.edit, 'isLoading', false);
    }

    showEditModal = (date: string, time = '08:00') => {
        const day = moment(date).format('Y-M-D')
        const datetime = moment(`${day} ${time}`);
        const payload = {
            start: datetime.clone().format(),
            end: datetime.clone().add(1, 'hour').format(),
            reservation: null
        };

        if (!Store.calendarItem || Store.calendarItem.id.length > 0) {
            Store.set(Store, 'calendarItem', new CalendarItemModel(payload));
        } else {
            Store.calendarItem.update(payload);
        }
        Store.set(Store.modals.edit, 'isOpened', true);
    }
};
