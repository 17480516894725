import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { ReservationModel } from "shared/models";

import './styles.scss';

type PropsType = {
    item?: ReservationModel;
    onClick?: () => void;
}

export const UiReservation = observer(({ item: reservation, onClick }: PropsType) => {
    if (!reservation) {
        return null;
    }
    const classNames = useMemo(() => classnames('ui-reservation', {
        'ui-reservation--clickable': !!onClick
    }), [onClick]);

    return (
        <div className={classNames} onClick={onClick}>
            <div
                className="ui-reservation__image"
                style={{backgroundImage: `url(${reservation.reservationPlace.previewImageUrl})`}}
            />
            <div className="ui-reservation__header">
                <div className="ui-reservation__title">{reservation.name || 'Бронирование'}</div>
                <div className="ui-reservation__time">{reservation.time}</div>
            </div>
            <div className="ui-reservation__date">
                {`${reservation.startAtMoment.format('D MMMM Y')}, ${reservation.reservationPlace.name}`}
            </div>
        </div>
    );
});
