import { action, computed, makeObservable, observable } from 'mobx';

import { IspringCourseStatusIdEnum } from "shared/enums";

import { TagModel } from "./TagModel";
import { Model } from './Model';

export interface IIspringCourseModel {
    id?: number;
    name?: string;
    url?: string;
    previewImageUrl?: string;
    type?: string;
    dueDateAt?: string;
    progress?: number;
    statusId?: string;
}

export class IspringCourseModel extends Model implements IspringCourseModel {
    id = 0;
    name = '';
    url = '';
    previewImageUrl = '';
    type = '';
    dueDateAt = '';
    progress = 0;
    statusId = IspringCourseStatusIdEnum.NotStarted;

    constructor(payload: IIspringCourseModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            url: observable,
            previewImageUrl: observable,
            type: observable,
            dueDateAt: observable,
            progress: observable,
            statusId: observable,
            status: computed,
            doneStatuses: computed,
            tag: computed,
            update: action,
        });

        this.update(payload);
    }

    get status() {
        return IspringCourseStatusIdEnum.fromValue(this.statusId);
    }

    get tag() {
        return new TagModel({
            name: this.type
        });
    }

    get doneStatuses() {
        return [
            IspringCourseStatusIdEnum.Complete,
            IspringCourseStatusIdEnum.Passed,
        ];
    }
}
