import React, { useCallback, useMemo, useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { set } from 'mobx';
import classnames from 'classnames';

import { ICONS } from 'shared/constants';
import { useOnClickOutside } from "shared/hooks";

import { UiIcon } from '../UiIcon';

import './styles.scss';

type PropsType = {
    control?: React.ReactNode;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    hasCloseButton?: boolean;
    width?: number | string;
    label?: string;
    className?: string;
}

export const UiDropdown = observer((
    {
        style = {},
        width = 292,
        control = null,
        hasCloseButton = true,
        label = '',
        className = '',
        children
    }: PropsType
) => {
    const ref = useRef(null);
    const store = useLocalObservable(() => ({
        isVisible: false,
        set: set
    }));

    const handleToggle = useCallback(() => {
        store.set(store, 'isVisible', !store.isVisible);
    }, [store]);

    const handleHide = useCallback(() => {
        store.set(store, 'isVisible', false);
    }, [store]);

    useOnClickOutside(ref, handleHide);

    const classNames = useMemo(() => classnames('ui-dropdown', className, {
        'ui-dropdown--active': store.isVisible,
    }), [store.isVisible, className]);

    return (
        <div className={classNames} style={style} ref={ref}>
            <div className='ui-dropdown__control' onClick={handleToggle}>
                {control}
            </div>
            <div className='ui-dropdown__outer' style={{ width }}>
                <div className='ui-dropdown__overlay' onClick={handleHide}/>
                <div className='ui-dropdown__popup' style={{ width }}>
                    {hasCloseButton && (
                        <div className='ui-dropdown__close' onClick={handleHide}>
                            <UiIcon icon={ICONS.CLOSE} size={16}/>
                        </div>
                    )}
                    {label && (
                        <div className='ui-dropdown__label'>{label}</div>
                    )}
                    <div className='ui-dropdown__body'>{children}</div>
                </div>
            </div>
        </div>
    );
});

