import { makeAutoObservable, set } from "mobx";
import lodash from "lodash";

import { ChatMessageModel, ChatRoomModel } from "shared/models";

import { ChatMessagesGroupedItemType } from "./types/ChatMessagesGroupedItemType";

class _Store {
    isLoading = false;
    isSuccess = true;
    message = '';
    hasLastMessage = false;
    scrollbar = null as HTMLDivElement | null;
    input = null as HTMLInputElement | null;
    chatRoom = null as ChatRoomModel | null;
    chatMessages = [] as ChatMessageModel[];
    chatMessageReply = null as ChatMessageModel | null;

    constructor() {
        makeAutoObservable(this);
    }

    set = <R>(key: keyof _Store, value: R): R => {
        set(this, key, value);
        return value;
    }

    get chatMessageByKey() {
        return lodash.keyBy<ChatMessageModel>(this.chatMessages, 'key');
    }

    get chatMessageFirst() {
        if (this.chatMessagesSorted.length === 0) {
            return null;
        }
        return this.chatMessagesSorted[0];
    }

    get chatMessageLast() {
        if (this.chatMessagesSorted.length === 0) {
            return null;
        }
        return this.chatMessagesSorted[this.chatMessagesSorted.length - 1];
    }

    get chatMessagesSorted() {
        return this.chatMessages
            .slice()
            .filter(item => item.deletedAt === null)
            .sort((a, b) => {
                return a.createdAtMoment.unix() - b.createdAtMoment.unix();
            });
    };

    get chatMessageGrouped(): ChatMessagesGroupedItemType[] {
        if (this.chatMessagesSorted.length === 0) {
            return [];
        }
        const result: any = [];

        let chatMessages = {
            user: this.chatMessagesSorted[0].user,
            chatMessages: [] as ChatMessageModel[]
        }

        let group = {
            date: this.chatMessagesSorted[0].createdAtMoment.format('D.MM.Y'),
            users: [] as (typeof chatMessages)[]
        }

        this.chatMessagesSorted.forEach((chatMessage) => {
            const currentDate = chatMessage.createdAtMoment.format('D.MM.Y');
            if (currentDate !== group.date) {
                group.users.push({ ...chatMessages });
                result.push({ ...group });
                group = {
                    date: currentDate,
                    users: []
                }
                chatMessages = {
                    user: chatMessage.user,
                    chatMessages: []
                }
            }
            if (chatMessages.user.id !== chatMessage.user.id) {
                group.users.push({ ...chatMessages });
                chatMessages = {
                    user: chatMessage.user,
                    chatMessages: []
                }
            }
            chatMessages.chatMessages.push(chatMessage);
        });
        group.users.push({ ...chatMessages });
        result.push({ ...group });
        return result;
    }
}

export const Store = new _Store();
