import { makeAutoObservable, runInAction } from 'mobx';

import { ObjectType } from 'shared/types';
import { VacancyModel, VacancyRegionModel } from 'shared/models';
import { fetchItems } from 'shared/mixins/service';
import { vacanciesQuery, vacanciesRegionsQuery } from 'shared/queries';

import Store from './Store';

class Service {
    constructor() {
        makeAutoObservable(this);
    }

    fetchItems = fetchItems(vacanciesQuery, Store, VacancyModel, {
        prefetchCallback: async (urlParams: ObjectType) => {
            const { isSuccess, data } = await vacanciesRegionsQuery(urlParams);
            if (isSuccess && data) {
                runInAction(() => {
                    Store.vacancyRegions = data.items.map(item => new VacancyRegionModel(item));
                });
            }
        },
    });
}

export default new Service();
