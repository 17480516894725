import classnames from "classnames";
import { observer } from 'mobx-react';
import moment from "moment";

import { tabs } from "pages/profile/utilities/tabs";
import React, { useEffect } from 'react';

import { COLORS, ICONS, ROUTES } from "shared/constants";
import { useAsyncEffect, useMedia, useNavigate, useStore } from "shared/hooks";
import { LayoutBody, LayoutContent } from 'shared/layout';
import { UserDashboardModel } from "shared/models";
import { UserDashboardContentItemModel } from "shared/models/UserDashboardModel";
import { ApplicationModule } from 'shared/modules';
import { userDashboardsGetQuery } from "shared/queries";
import { UiButton, UiIcon, UiTabs } from "shared/uikit";

import { PPDBonuses } from "./components/PPDBonuses";
import { PPDWidget } from "./components/PPDWidget";

import './styles.scss';

export const ProfileDashboardsPage = observer(() => {
    const navigate = useNavigate();
    const { is320, is768, is1024 } = useMedia()

    const store = useStore(() => ({
        userDashboard: new UserDashboardModel(),
        contentTab: 'month',
        view: 'card'
    }));

    useEffect(() => {
        ApplicationModule.setTitle('Личный кабинет');
    });

    useEffect(() => {
        if (is320 || is768) {
            store.set("view", 'card');
        }
    }, [is768, is320, store]);

    useAsyncEffect(async () => {
        const response = await userDashboardsGetQuery();

        if (response.isSuccess && response.data) {
            store.set('userDashboard', new UserDashboardModel(response.data.item));
            if (store.userDashboard.content.month) {
                store.set("contentTab", "month");
            } else if (store.userDashboard.content.quarter) {
                store.set("contentTab", "quarter");
            } else if (store.userDashboard.content.year) {
                store.set("contentTab", "year");
            } else if (store.userDashboard.content.additional) {
                store.set("contentTab", "additional");
            }
        }
    }, []);

    const content: UserDashboardContentItemModel | null = store.userDashboard.content[store.contentTab] || null;

    const coloredValue = () => {
        const date = moment();
        const day = date.date();
        const month = date.month();
        if (store.contentTab === 'month') {
            return day > (date.daysInMonth() / 2);
        }

        if (store.contentTab === 'quarter') {
            const quarterMonth = month % 3;
            const progress = quarterMonth + day / date.daysInMonth();
            return progress > 1.5;
        }

        if (store.contentTab === 'year') {
            return moment().dayOfYear() > 182;
        }

        return false;
    }

    return (
        <>
            <div className="p-profile-header">
                <div className="p-profile-header__upper">
                    <div className="p-profile-header__user">{ApplicationModule.user.previewName}</div>
                    <div className="p-profile-header__logout">
                        <UiButton
                            label='Выйти'
                            isLink
                            onClick={() => {
                                ApplicationModule.setAccessToken(null);
                                navigate(ROUTES.LOGIN());
                            }}
                        />
                    </div>
                </div>
                {tabs().length > 0 && (
                    <div className="p-profile-header__tabs">
                        <UiTabs
                            onChange={(data) => {
                                navigate(data.value);
                            }}
                            value={ROUTES.PROFILE_DASHBOARD()}
                            items={tabs()}
                        />
                    </div>
                )}
            </div>
            <LayoutBody title='Личный кабинет'>
                <LayoutContent className={'ppd--layout p-profile__layout-content'}>
                    {content && (
                        <div className={'ppd'}>
                            {(is320 || is768 || is1024) && (
                                <UiTabs
                                    className={'ppd__tabs'}
                                    onChange={store.handleChange}
                                    name={'contentTab'}
                                    items={store.userDashboard.content.tabs}
                                    value={store.contentTab}
                                />
                            )}
                            <PPDBonuses tab={store.contentTab} bonuses={content?.bonuses}/>
                            <div className={classnames("ppd-widgets", `ppd-widgets--${store.view}`, {
                                "ppd-widgets--colored-value": coloredValue()
                            })}>
                                <div className="ppd-widgets__header">
                                    {(!is320 && !is768 && !is1024) && (
                                        <UiTabs
                                            className={'ppd__tabs'}
                                            onChange={store.handleChange}
                                            name={'contentTab'}
                                            items={store.userDashboard.content.tabs}
                                            value={store.contentTab}
                                        />
                                    )}
                                    <div className="ppd-widgets__updated">
                                        <UiIcon size={16} color={COLORS.GRAY_4A} icon={ICONS.ALARM}/>
                                        <span>Данные актуальны на {moment(store.userDashboard.updatedAt || store.userDashboard.createdAt).format('HH:mm, DD MMMM')}</span>
                                    </div>
                                    {store.userDashboard.content.hasTableView && (
                                        <div className="ppd-widgets-view">
                                            <div className="ppd-widgets-view__item" onClick={() => store.set("view", 'card')}>
                                                <UiIcon
                                                    icon={ICONS.VIEW_TYPE_GRID}
                                                    size={24}
                                                    color={store.view === 'card' ? COLORS.GRAY_1 : COLORS.GRAY_4A}
                                                />
                                            </div>
                                            <div className="ppd-widgets-view__item" onClick={() => store.set("view", 'table')}>
                                                <UiIcon
                                                    icon={ICONS.VIEW_TYPE_LIST2}
                                                    size={24}
                                                    color={store.view === 'table' ? COLORS.GRAY_1 : COLORS.GRAY_4A}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="ppd-widgets__items ppd-widgets__items--card">
                                    {content.widgets?.map((widget, index) => (
                                        <PPDWidget key={widget.name + index} widget={widget} view={'card'}/>
                                    ))}
                                </div>
                                <div className="ppd-widgets__items ppd-widgets__items--table">
                                    {content.widgets?.map((widget, index) => (
                                        <PPDWidget key={widget.name + index} widget={widget} view={'table'}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </LayoutContent>
            </LayoutBody>
        </>
    );
});
