import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from "shared/hooks";
import { CompanyLeaderGroupModel } from "shared/models";
import { companyLeaderGroupsQuery } from "shared/queries";
import { UiAvatar, UiDataBoundary, UiIcon, UiGrid, UiNavLink } from "shared/uikit";
import { ICONS, ROUTES } from "shared/constants";

import './styles.scss';

export const PCompanyLeaderGroups = observer(() => {
    const store = useStore(() => ({
        isLoading: false,
        isSuccess: false,
        items: [] as CompanyLeaderGroupModel[]
    }));

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            const { isSuccess, data } = await companyLeaderGroupsQuery();
            if (isSuccess && data) {
                store.set("items", data.items.map(item => new CompanyLeaderGroupModel(item)));
            }
            store.set("isSuccess", isSuccess && data);
            store.set('isLoading', false);
        })();
    }, [store]);

    return (
        <div className="p-company-leaders">
            <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                <div className="p-company-leaders__title">Лидеры по продажам</div>
                {store.items.map(item => (
                    <div key={item.id} className="p-company-leaders-group">
                        <div className="p-company-leaders-group__name">{item.name}</div>
                        <UiGrid columns={2} gap={16}>
                            {item.users.map(user => (
                                <UiNavLink
                                    key={user.id}
                                    to={ROUTES.USER(user.slug)}
                                    className="p-company-leaders-group-user"
                                >
                                    <div className="p-company-leaders-group-user__avatar">
                                        <UiAvatar size={40} label={user.previewName} image={user.imageAvatar}/>
                                        <div className="p-company-leaders-group-user__icon">
                                            <UiIcon size={22} icon={ICONS.CUP}/>
                                        </div>
                                    </div>
                                    <div className="p-company-leaders-group-user__name">
                                        {user.previewName}
                                    </div>
                                </UiNavLink>
                            ))}
                        </UiGrid>
                    </div>
                ))}
            </UiDataBoundary>
        </div>
    );
})

