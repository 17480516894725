import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { ApplicationModule } from 'shared/modules';
import { UiDataBoundary, UiIcon, UiNotification, UiPagination, UiSelect } from 'shared/uikit';
import { ICONS, ROUTES } from 'shared/constants';
import { useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { NotificationModel } from "shared/models";
import { notificationsDeleteQuery, notificationsQuery, notificationsReadQuery } from "shared/queries";

import './styles.scss';

const filters = [{
    id: 'all',
    name: 'Все уведомления',
}, {
    id: 'unread',
    name: 'Не прочитанные',
}];

export const NotificationsPage = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        items: [] as NotificationModel[],
        isLoading: true,
        isInfiniteLoading: false,
        isSuccess: false,
        pages: 1
    }));

    const urlParams = useUrlParams({
        filter: 'all',
        page: 1
    });

    const readItems = useCallback(async () => {
        const id: string[] = [];
        store.items.forEach(item => {
            id.push(item.id);
            item.update({
                readAt: moment().format()
            });
        });
        await notificationsReadQuery({id});
    }, [store]);

    const fetchItems = useCallback(async () => {
        if (!store.isInfiniteLoading) {
            store.set('isLoading', true);
        }

        const response = await notificationsQuery(urlParams);
        if (response.isSuccess && response.data) {
            const items = response.data.items.map(item => new NotificationModel(item));

            store.set('items', store.isInfiniteLoading ? [...store.items, ...items] : items);
            store.set('pages', response.data.pages);

            setTimeout(readItems, 1000);
        }

        store.set('isSuccess', response.isSuccess && response.data);
        store.set('isInfiniteLoading', false);
        store.set('isLoading', false);
    }, [urlParams, store, readItems]);

    useEffect(() => {
        ApplicationModule.setTitle('Уведомления');
    }, []);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    const handleChangePagination = ({ value }: { value: number }, isInfinite: boolean) => {
        if (isInfinite) {
            store.set('isInfiniteLoading', true);
        }
        navigate(ROUTES.NOTIFICATIONS(), {
            ...urlParams,
            page: value
        });
    }

    const handleDelete = async (notification: NotificationModel) => {
        notification.update({
            deletedAt: moment().format()
        })
        await notificationsDeleteQuery({
            id: notification.id
        });
    }

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Уведомления'/>
                <UiSelect
                    className='display-none--computer'
                    items={filters}
                    value={urlParams.filter}
                    onChange={({ value }) => navigate(ROUTES.NOTIFICATIONS(), { filter: value })}
                />
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.items.map(item => item.isDeleted ? null : (
                        <UiNotification key={item.id} value={item} onDelete={handleDelete}/>
                    ))}
                    {store.items.length === 0 && (
                        <div className='notifications-page__empty'>
                            <UiIcon icon={ICONS.NOTIFICATIONS_EMPTY} size={103}/>
                            <h2 className='typography'>У вас пока что нет уведомлений</h2>
                        </div>
                    )}
                </UiDataBoundary>
                <UiPagination
                    page={urlParams.page}
                    pages={store.pages}
                    onChange={handleChangePagination}
                />
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>

    )
});
