import { action, makeObservable, observable } from 'mobx';

import { IUserModel, UserModel } from 'shared/models';
import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';

export interface ICompanyLeaderGroupModel {
    id?: number;
    name?: string;
    users?: IUserModel[];
}

export class CompanyLeaderGroupModel extends Model implements ICompanyLeaderGroupModel {
    casts = {
        users: new ModelArrayCast(UserModel),
    };

    id = 0;
    name = '';
    users: UserModel[] = [];

    constructor(payload: ICompanyLeaderGroupModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            users: observable,
            update: action,
        });

        this.update(payload);
    }
}
