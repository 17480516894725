import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import leaflet from 'leaflet';
import { MapContainer, TileLayer, Polyline, Marker } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './styles.scss';

const icon = new leaflet.Icon({
    iconUrl: require('./assets/icon.svg'),
    iconSize: [48, 48],
    iconAnchor: [24, 43]
});

type PropsType = {
    center: [number, number],
    markers?: [number, number][],
    routes?: [number, number][][],
    zoom?: number,
    className?: string,
    style?: CSSProperties
}

export const UiMap = observer((
    {
        center,
        markers,
        routes,
        className,
        zoom = 15,
        style,
    }: PropsType
) => {
    const classNames = classnames('ui-map', className)
    return (
        <MapContainer
            attributionControl={false}
            className={classNames}
            center={center}
            zoom={zoom}
            style={style}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            {markers && markers?.map(marker => (
                <Marker position={marker} icon={icon}/>
            ))}
            {routes && routes?.map(route => <Polyline positions={route}/>)}
        </MapContainer>
    )
})
