import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useDebounce, useNavigate, useStore, useUrlParams } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';
import classnames from "classnames";

export const CFilterSearch = observer(() => {
    const urlParams = useUrlParams({
        fSearch: null
    });
    const store = useStore(() => ({
        search: urlParams.fSearch || '',
        isFocused: false
    }));
    const navigate = useNavigate();

    const apply = useDebounce(() => {
        navigate(null, {
            ...urlParams,
            fSearch: store.search || null
        })
    }, 400);

    useEffect(apply, [store.search, apply])

    return (
        <div className={classnames('c-tt-filter-search', {
            'c-tt-filter-search--focused': store.isFocused
        })}>
            <UiIcon size={20} icon={ICONS.SEARCH}/>
            <input
                type="text"
                placeholder='Поиск по задачам'
                value={store.search || ''}
                name={'search'}
                onFocus={() => store.set("isFocused", true)}
                onBlur={() => store.set("isFocused", false)}
                onChange={(e) => {
                    store.set("search", e.target.value || '');
                }}
            />
        </div>
    )
});

