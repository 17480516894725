import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import lodash from "lodash";
import { runInAction } from "mobx";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import classnames from "classnames";

import { BoardModel, ColumnModel, SpaceModel, TaskModel } from "shared/models/TaskTracker";
import {
    columnsDeleteQuery,
    columnsOrderQuery,
    tasksPersonalMoveQuery,
    tasksUpsertQuery
} from "shared/queries/TaskTracker";
import { CColumnCreate, CContextMenu, CFilterSpace, CListHeader } from "shared/components/TaskTracker";
import { useNavigate, useRouteParams, useUrlParams } from "shared/hooks";
import { Notifier, rearrange } from "shared/utilities";
import { UiButton, UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { TaskTrackerColumnTypeEnum, TaskTrackerTaskStatusEnum } from "shared/enums";

import { CColumn } from "../CColumn";

import './index.scss';

type PropsType = {
    canEdit?: boolean,
    board: BoardModel,
    columns: ColumnModel[],
    spaces?: SpaceModel[],
    tasks: TaskModel[],
    onColumnsChange?: (columns: ColumnModel[]) => void
}

export const CBoard = observer((
    {
        canEdit = false,
        board,
        tasks: _tasks,
        columns,
        spaces = [],
        onColumnsChange = () => {
        },
    }: PropsType
) => {
    const navigate = useNavigate();
    const routeParams = useRouteParams<{ id: number | string }>();
    const urlParams = useUrlParams({
        view: 'board',
        fSortProperty: null as null | string
    });
    const isSortEnabled = !!urlParams.fSortProperty;
    const isPersonal = routeParams.id === 'personal';

    const tasks = _tasks.filter(task => {
        return !task.status.is(TaskTrackerTaskStatusEnum.Archive.id);
    });
    const taskById = useMemo(() => lodash.keyBy(tasks, 'id'), [tasks]);
    const tasksByColumnId = lodash.groupBy(tasks, (task) => {
        if (isPersonal) {
            if (task.column.typeId === TaskTrackerColumnTypeEnum.Custom.id) {
                return TaskTrackerColumnTypeEnum.Progress.id;
            }
            return task.column.typeId;
        }

        return task.columnId;
    });
    const boardColumns = columns.slice()
        .filter(column => +column.boardId === +board.id)
        .sort((a, b) => a.sort - b.sort)

    const updateColumnsOrder = (columns: ColumnModel[]) => {
        columns.forEach((column, sort) => column.update({ sort: sort * 1000 }));

        columnsOrderQuery({
            items: columns.map(({ id, sort }) => ({ id, sort }))
        })

        return columns;
    }

    const updateTasks = (tasks: TaskModel[]) => {
        tasksUpsertQuery({
            items: tasks.map(({ id, sort, columnId, statusId }) => ({
                id,
                sort,
                columnId,
                statusId
            }))
        })

        return tasks;
    }

    const handleDragEnd = (dropResult: DropResult) => {
        runInAction(() => {
            if (!dropResult.destination) {
                return;
            }

            if (dropResult.type === 'column') {
                updateColumnsOrder(
                    rearrange(boardColumns, dropResult.source.index, dropResult.destination.index)
                );
            }

            if (dropResult.type === 'task') {
                let columnId = +dropResult.destination.droppableId.replace('column-', '');
                const column = columns.find(column => +column.id === +columnId);
                let tasks: TaskModel[] = (tasksByColumnId[columnId] ?? []).sort((a, b) => a.sort - b.sort);
                if (dropResult.source.droppableId === dropResult.destination.droppableId) {
                    // rearrange
                    tasks = rearrange(tasks, dropResult.source.index, dropResult.destination.index)
                } else {
                    // change column
                    let taskId = +dropResult.draggableId.replace('task-', '');
                    const task = taskById[taskId];
                    if (column?.type.is(TaskTrackerColumnTypeEnum.Done)) {
                        task.update({
                            statusId: TaskTrackerTaskStatusEnum.Done.id
                        });
                    }
                    tasks.splice(dropResult.destination.index, 0, task);
                    tasks.forEach((task) => task.update({ columnId }));
                }
                if (!isSortEnabled) {
                    tasks.forEach((task, sort) => task.update({ sort: sort * 1000 }));
                }
                updateTasks(tasks);
            }
        });
    }

    const handleDragPersonal = (dropResult: DropResult) => {
        runInAction(() => {
            if (!dropResult.destination) {
                return;
            }

            if (dropResult.type === 'task') {
                let columnTypeId = dropResult.destination.droppableId.replace('column-', '');
                const tasks = (tasksByColumnId[columnTypeId] ?? []).sort((a, b) => a.sort - b.sort);
                if (dropResult.source.droppableId === dropResult.destination.droppableId) { //move
                    rearrange(tasks, dropResult.source.index, dropResult.destination.index)
                        .forEach((task, sort) => task.update({ sort: sort * 1000 }));
                } else {
                    //switch column
                    let taskId = +dropResult.draggableId.replace('task-', '');
                    const task = taskById[taskId];
                    if (columnTypeId === 'done') {
                        task.update({
                            statusId: TaskTrackerTaskStatusEnum.Done.id
                        })
                    }
                    tasks.splice(dropResult.destination.index, 0, task);
                    tasks.forEach((task, sort) => {
                        task.column.update({
                            typeId: columnTypeId
                        });
                        task.update({ sort: sort * 1000 })
                    });
                    tasksPersonalMoveQuery({
                        id: taskId,
                        statusId: task.statusId,
                        columnTypeId,
                    });
                }
            }
        });
    }

    const handleDeleteColumn = async (column: ColumnModel) => {
        const result = await Notifier.confirm(
            `Вы точно хотите удалить колонку «${column.name}»?`,
            <>Отменить это действие буде невозможно! <br/>Восстановить данные не получится!</>
        );
        if (!result) {
            return;
        }
        const baseColumn = boardColumns.find(column => column.type.is(TaskTrackerColumnTypeEnum.New));
        if (!baseColumn) {
            return;
        }
        columnsDeleteQuery({
            id: column.id
        });
        (tasksByColumnId[column.id] || []).forEach(task => task.update({ columnId: baseColumn.id }));
        onColumnsChange(columns.filter(c => c.id !== column.id));
    }

    if (!board) {
        return null;
    }

    return (
        <div className={classnames('c-tt-board', `c-tt-board--${urlParams.view}`)}>
            <div className="c-tt-board__header">
                <div className="c-tt-board__name">{board.name}</div>
                <div className="c-tt-board__actions">
                    <UiButton
                        onClick={() => {
                            navigate(null, {
                                ...urlParams,
                                saveTaskId: 'new',
                                boardId: isPersonal ? null : board.id
                            })
                        }}
                        isTiny
                        label={'Создать задачу'}
                        iconAfter={<UiIcon icon={ICONS.PLUS} size={12} color={COLORS.WHITE}/>}
                    />
                    {canEdit && (
                        <CContextMenu
                            size={32}
                            items={[[{
                                name: 'Редактировать',
                                action: () => {
                                    navigate(null, { ...urlParams, saveBoardId: board.id })
                                }
                            }]]}
                        />
                    )}
                    {!isPersonal && (
                        <div
                            className={classnames('c-tt-board__collapse', {
                                'c-tt-board__collapse--collapsed': board.isCollapsed
                            })}
                            onClick={() => board.update({ isCollapsed: !board.isCollapsed })}
                        >
                            <UiIcon icon={ICONS.CHEVRON_UP} size={12} color={'#000'}/>
                        </div>
                    )}
                </div>
            </div>
            {isPersonal && <CFilterSpace spaces={spaces}/>}
            {!board.isCollapsed && (
                <>
                    {urlParams.view === 'list' && <CListHeader/>}
                    <DragDropContext onDragEnd={isPersonal ? handleDragPersonal : handleDragEnd}>
                        <Droppable
                            droppableId="board"
                            direction={urlParams.view === 'board' ? 'horizontal' : 'vertical'}
                            type={'column'}
                        >
                            {(droppable, snapshot) => (
                                <div
                                    ref={droppable.innerRef} {...droppable.droppableProps}
                                    className={classnames('c-tt-board__columns', {
                                        'c-tt-board__columns--dropping': snapshot.isDraggingOver
                                    })}
                                >
                                    {boardColumns.map((column, index) => {
                                        if (isPersonal) {
                                            return (
                                                <CColumn
                                                    key={`column-${column.id}`}
                                                    canEdit={false}
                                                    index={index}
                                                    column={column}
                                                    tasks={tasksByColumnId[column.id] ?? []}
                                                />
                                            );
                                        }
                                        return (
                                            <CColumn
                                                key={`column-${column.id}`}
                                                index={index}
                                                canEdit={canEdit}
                                                column={column}
                                                onDeleted={handleDeleteColumn}
                                                tasks={tasksByColumnId[column.id] ?? []}
                                            />
                                        );
                                    })}
                                    {droppable.placeholder}
                                    {canEdit && (
                                        <CColumnCreate
                                            boardId={board.id}
                                            onCreated={(column) => {
                                                onColumnsChange([...columns, column]);
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            )}
        </div>
    )
});

