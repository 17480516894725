import React from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { ThumbnailType } from "shared/types";

import { UiNavLink } from "../UiNavLink";
import { UiImageThumbnail } from "../UiImageThumbnail";

import './styles.scss';

type PropsType = {
    href?: string;
    image?: ThumbnailType | string | null;
    date?: string;
    name?: string;
    description?: string;
    tags?: React.ReactNode;
    actions?: React.ReactNode;
    style?: React.CSSProperties;
}

export const UiArticleCard = observer((
    {
        href = '#',
        image,
        date,
        name,
        description,
        tags,
        actions,
        style,
    }: PropsType
) => {
    return (
        <UiNavLink to={href} className='ui-article-card' style={style}>
            <UiImageThumbnail className='ui-article-card__image' value={image}/>

            {date && (
                <div className="ui-article-card__date">{moment(date).format('D MMMM YYYY')}</div>
            )}
            {name && (
                <div className="ui-article-card__name">{name}</div>
            )}
            {description && (
                <div className="ui-article-card__description">{description}</div>
            )}
            <div className="ui-article-card__footer">
                <div className="ui-article-card__tags">{tags}</div>
                <div className="ui-article-card__actions">{actions}</div>
            </div>
        </UiNavLink>
    )
});
