import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { ResizableBox } from "react-resizable";
import classnames from "classnames";

import { useEvent, useMedia, useNavigate, useStore, useUrlParams } from "shared/hooks";
import { UiIcon } from "shared/uikit";
import { ICONS } from "shared/constants";

import './index.scss';

export const CListHeader = observer(() => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const store = useStore(() => ({
        isResizing: false,
        width: 300,
        maxWidth: 10000,
        minWidth: 300,
    }))
    const trigger = useEvent('tt-grid-rows-template');
    const urlParams = useUrlParams({
        fSortProperty: null,
        fSortType: null
    });

    const getMaxWidth = useCallback(() => {
        if (!ref || !ref.current) {
            return 2000;
        }
        const bounds = ref.current.getBoundingClientRect();
        return bounds.width - 130 - 140 - 160 - 160 - 24 * 5;
    }, [ref]);

    useEffect(() => {
        store.set("width", getMaxWidth());
        store.set("maxWidth", getMaxWidth());
    }, [store, getMaxWidth]);

    useEffect(() => {
        const handleResize = () => {
            const maxWidth = getMaxWidth();
            store.set("maxWidth", maxWidth);
            if (maxWidth < store.width) {
                store.set("width", maxWidth < store.minWidth ? store.minWidth : maxWidth);
            }
        }

        window.addEventListener('resize', handleResize, true);
        return () => window.removeEventListener('resize', handleResize);
    }, [getMaxWidth, store]);

    const { is1440, is1920, is2560 } = useMedia()
    const hasResize = useMemo(() => is1440 || is1920 || is2560, [is1440, is1920, is2560]);

    const handleSort = (property: string) => () => {
        if (!urlParams.fSortType || property !== urlParams.fSortProperty) {
            navigate(null, {
                ...urlParams,
                fSortProperty: property,
                fSortType: 'asc'
            }, {}, null)
            return;
        }
        if (urlParams.fSortType === 'asc') {
            navigate(null, {
                ...urlParams,
                fSortProperty: property,
                fSortType: 'desc'
            }, {}, null)
            return;
        }
        navigate(null, {
            ...urlParams,
            fSortProperty: null,
            fSortType: null
        }, {}, null);
        return;
    }

    const isSortAsc = urlParams.fSortType === 'asc';

    const gridTemplateColumns = useMemo(() => {
        if (hasResize) {
            return `${store.width}px 140px 140px 160px 160px`;
        }
        return undefined;
    }, [hasResize, store.width]);

    useEffect(() => {
        if (store.isResizing) {
            return;
        }
        trigger(gridTemplateColumns);
    }, [store.isResizing, trigger, gridTemplateColumns]);

    return (
        <div
            ref={ref}
            className="c-tt-list-header"
            style={{ gridTemplateColumns }}
        >
            {hasResize && (
                <ResizableBox
                    className={classnames('c-tt-list-header-item', {
                        'react-resizable--resizing': store.isResizing
                    })}
                    width={store.width}
                    resizeHandles={['e']}
                    height={48}
                    minConstraints={[store.minWidth, 48]}
                    maxConstraints={[store.maxWidth, 48]}
                    onResizeStart={() => {
                        store.set('isResizing', true);
                    }}
                    onResizeStop={() => {
                        store.set('isResizing', false);
                    }}
                    onResize={(_, data) => {
                        store.set("width", data.size.width);
                    }}
                >
                    <>
                        <span onClick={handleSort('name')}>Название</span>
                        {(urlParams.fSortProperty === 'name') && (
                            <UiIcon
                                color={'#373736'}
                                size={10}
                                icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}
                            />
                        )}
                    </>
                </ResizableBox>
            )}
            {!hasResize && (
                <div className="c-tt-list-header-item">
                    <span onClick={handleSort('name')}>Название</span>
                    {(urlParams.fSortProperty === 'name') && (
                        <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                    )}
                </div>
            )}
            <div className="c-tt-list-header-item">
                <span onClick={handleSort('createdAt')}>Дата создания</span>
                {(urlParams.fSortProperty === 'createdAt') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-item">
                <span onClick={handleSort('expiredAt')}>Крайний срок</span>
                {(urlParams.fSortProperty === 'expiredAt') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-item">
                <span onClick={handleSort('ownerUserId')}>Постановщик</span>
                {(urlParams.fSortProperty === 'ownerUserId') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
            <div className="c-tt-list-header-item">
                <span onClick={handleSort('responsibleUserId')}>Ответственный</span>
                {(urlParams.fSortProperty === 'responsibleUserId') && (
                    <UiIcon color={'#373736'} size={10} icon={isSortAsc ? ICONS.CHEVRON_DOWN : ICONS.CHEVRON_UP}/>
                )}
            </div>
        </div>
    )
});

