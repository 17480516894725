import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import lodash from "lodash";

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { UiDataBoundary, UiGrid, UiPagination, UiViewType } from 'shared/uikit';
import { useMedia, useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { EssentialModel } from "shared/models";
import { essentialsQuery } from "shared/queries";
import { ROUTES } from "shared/constants";

import { ArticleItem } from './components/ArticleItem';

import './styles.scss';

export const EssentialsPage = observer(() => {
    const navigate = useNavigate();

    const { value: columns, isMobile } = useMedia({
        is320: 1,
        is768: 2,
        is1280: 3,
        is1440: 4
    });

    const urlParams = useUrlParams({
        page: 1,
    });

    const store = useStore(() => ({
        pages: 0,
        viewType: 'card',
        isLoading: true,
        isSuccess: true,
        isInfinite: false,
        items: [] as EssentialModel[],
    }));


    const fetchItems = useCallback(async () => {
        store.set("isLoading", !store.isInfinite);
        const { isSuccess, data } = await essentialsQuery(urlParams);
        if (isSuccess && data) {
            store.set(
                "items",
                [...(store.isInfinite ? store.items : []), ...data.items.map(item => new EssentialModel(item))]
            );
            store.set("pages", data.pages);
        }
        store.set("isSuccess", isSuccess && data);
        store.set("isLoading", false);
        store.set("isInfinite", false);
    }, [store, urlParams]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    useEffect(() => {
        if (isMobile) {
            store.set("viewType", 'card');
        }
    }, [isMobile, store]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title='Важная информация'>
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    {store.viewType === 'card' && (
                        <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                            {lodash.chunk(store.items, columns).map((chunk, index) => (
                                <div key={index} className='essentials-page__row'>
                                    <UiGrid columns={columns} style={{ maxWidth: 1152 }}>
                                        {chunk.map(essential => (
                                            <ArticleItem key={essential.id} item={essential} viewType={store.viewType}/>
                                        ))}
                                    </UiGrid>
                                </div>
                            ))}
                        </UiGrid>
                    )}
                    {store.viewType === 'list' && (
                        <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                            {store.items.map(essential => (
                                <ArticleItem key={essential.id} item={essential} viewType={store.viewType}/>
                            ))}
                        </UiGrid>
                    )}
                </UiDataBoundary>
                <UiGrid columns={1} style={{ maxWidth: 1152 }}>
                    <UiPagination
                        page={urlParams.page}
                        pages={store.pages}
                        onChange={(data, isInfinite) => {
                            store.set("isInfinite", isInfinite);
                            navigate(ROUTES.ESSENTIALS(), {
                                ...urlParams,
                                page: data.value
                            })
                        }}
                    />
                </UiGrid>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    )
});
