import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';
import { NullableType } from "shared/types";

type ResponseType = {
    item: IUserModel;
};

type ProgressCallbackType = NullableType<(progress: number) => void>

export const usersImageUploadQuery = async function (file: Blob, onUploadProgress: ProgressCallbackType = null) {
    const formData = new FormData();
    formData.append('file', file);
    return await makeQuery<ResponseType>('/users/image-upload', { formData, onUploadProgress });
}
