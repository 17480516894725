import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { OnChangeHandlerType } from 'shared/types';

import UiNotificationCount from '../UiNotificationCount';

import './styles.scss';

interface Option {
    id: number | string;
    name: number | string;
    count?: number | string;
}

interface Props {
    items: Option[];
    value: number | string | null;
    name: string;
    onChange: OnChangeHandlerType<string | number>;
}

class UiPageSelector extends React.Component<Props> {
    static defaultProps: Props = {
        items: [],
        value: null,
        name: 'tab',
        onChange: () => {},
    };

    getItemClassName(isActive: boolean) {
        return classnames('ui-page-selector__item', {
            'ui-page-selector__item--active': isActive,
        });
    }

    handleClick = (value: string | number) => () => {
        const { onChange, name } = this.props;

        onChange &&
            onChange({
                value,
                name,
            });
    };

    render() {
        const { items, value, name } = this.props;

        return (
            <div className='ui-page-selector' data-name={name}>
                {items.map(option => (
                    <div
                        key={option.id}
                        className={this.getItemClassName(option.id === value)}
                        onClick={this.handleClick(option.id)}
                    >
                        <span>{option.name}</span>
                        {!!option.count && <UiNotificationCount value={+option.count} />}
                    </div>
                ))}
            </div>
        );
    }
}

export default observer(UiPageSelector);
