import { makeQuery } from 'shared/utilities';
import { IChatMessageModel } from "shared/models";

type RequestType = {
    key: string,
    chatRoomId: number,
    content: number | string,
    newsId?: number | null,
    surveyId?: number | null,
};

type ResponseType = {
    item: IChatMessageModel
};

export const chatMessagesCreateQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/chat-messages/create', { json: payload });
}
