import React from 'react';
import { observer } from 'mobx-react';

import { RequestCategoryModel } from "shared/models";
import { UiGrid } from "shared/uikit";
import { useMedia } from "shared/hooks";

import { PRequestsType } from "../PRequestsType";

import './index.scss';

type PropsType = {
    requestCategory: RequestCategoryModel,
    viewType?: string
}

export const PRequestsCategory = observer(({ requestCategory, viewType = 'card' }: PropsType) => {
    const { value: columns } = useMedia({
        is320: { card: 1, list: 1 },
        is768: { card: 2, list: 1 },
        is1024: { card: 3, list: 1 },
    })
    return (
        <div className={'p-requests-category'}>
            {viewType === 'list' && (
                <div className="p-requests-category__name">{requestCategory.name}</div>
            )}
            <UiGrid columns={viewType === 'card' ? columns.card : columns.list} gap={16}>
                {requestCategory.requestTypes.map((requestType) => (
                    <PRequestsType
                        key={requestType.id + viewType}
                        requestCategory={requestCategory}
                        requestType={requestType}
                        viewType={viewType}
                    />
                ))}
            </UiGrid>
        </div>
    )
});
