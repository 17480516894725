import { makeQuery } from 'shared/utilities';
import { ITaskModel } from "shared/models/TaskTracker";

type RequestType = {
    id: number,
    columnTypeId: string,
    statusId: string
}

type ResponseType = {
    item: ITaskModel
};

export const tasksPersonalMoveQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/tasks/personal-move', {
        prefix: 'task-tracker',
        json: request
    });
}
