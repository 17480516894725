import { makeQuery } from 'shared/utilities';
import { ICommentModel } from 'shared/models';

type PayloadType = {
    entityType?: string,
    entityId?: number,
    commentId?: number | null,
    id?: number | null,
    value?: string,
    url?: string,
};

type ResponseType = {
    item: ICommentModel;
};

export const commentsSaveQuery = async function (payload: PayloadType) {
    return await makeQuery<ResponseType>('/comments/save', { json: payload });
}
