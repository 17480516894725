import React from 'react';
import { observer } from 'mobx-react';

import { DepartmentModel } from 'shared/models';

import './styles.scss';

interface Props {
    className: string;
    style: React.CSSProperties;
    value?: DepartmentModel | null;
}

class UiDepartment extends React.Component<Props> {
    static defaultProps: Props = {
        className: '',
        style: {},
    };

    render() {
        const { value, className, style } = this.props;

        if (!value) {
            return null;
        }

        return (
            <div className={`ui-department ${className}`.trim()} style={style}>
                <span>{value.name}</span>
                <i style={{ backgroundColor: value.color }}></i>
            </div>
        );
    }
}

export default observer(UiDepartment);
