import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface IEssentialModel {
    id?: number;
    createdAt?: string;
    name?: string;
    previewImage?: string;
    previewImageThumbnail?: string;
    detailImage?: string;
    detailImageThumbnail?: string;
    previewText?: string;
    essentialViewsCount?: number;
    detailText?: string;
}

export class EssentialModel extends Model implements IEssentialModel {
    casts = {}
    id = 0;
    essentialViewsCount = 0;
    createdAt = '';
    name = '';
    previewImage = '';
    previewImageThumbnail = '';
    detailImage = '';
    detailImageThumbnail = '';
    previewText = '';
    detailText = '';

    constructor(payload: IEssentialModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            name: observable,
            previewImage: observable,
            essentialViewsCount: observable,
            previewImageThumbnail: observable,
            detailImage: observable,
            detailImageThumbnail: observable,
            previewText: observable,
            detailText: observable,
            update: action,
        });

        this.update(payload);
    }
}
