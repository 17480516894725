import { makeQuery } from 'shared/utilities';
import { ICommentModel } from 'shared/models';


type ResponseType = {
    item: ICommentModel;
};

export const commentsDeleteQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/comments/delete', { json: { id } });
}
