import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { ChatRoomModel } from "shared/models";
import { UiAvatar, UiHtml, UiUserStatus, UiIcon, UiDropdownMenu, UiNavLink } from "shared/uikit";
import { ROUTES, ICONS } from "shared/constants";

import './styles.scss';
import { chatRoomUsersUpdateQuery } from "shared/queries";
import { ChatRoomTypeEnum } from "shared/enums";

type PropsType = {
    item: ChatRoomModel
}

export const ChatRoomItem = observer(({ item }: PropsType) => {

    const handleSelect = useCallback((id: string | number) => {
        switch (id) {
            case 'fix':
                if (item.chatRoomUser) {
                    const payload = {
                        id: item.chatRoomUser.id,
                        isFixed: item.chatRoomUser.isFixed ? 0 : 1
                    };
                    item.chatRoomUser?.update(payload);
                    chatRoomUsersUpdateQuery(payload);
                }
                break;
            case 'deactivate':
                if (item.chatRoomUser) {
                    const payload = {
                        id: item.chatRoomUser.id,
                        isActive: 0
                    };
                    item.chatRoomUser?.update(payload);
                    chatRoomUsersUpdateQuery(payload);
                }
                break;
        }
    }, [item.chatRoomUser])

    if (!item.user) {
        return null;
    }

    const className = classnames('chat-room-item', {
        'chat-room-item--unread': item.chatMessagesUnreadCount > 0
    });

    return (
        <UiNavLink to={ROUTES.CHAT(item.id)} key={item.id} className={className}>
            <div className="chat-room-item__avatar">
                <UiAvatar label={item.previewName} image={item.previewImage}/>
                <UiUserStatus value={item.user}>
                    {({ isOnline }) => {
                        return isOnline ? <div className='chat-room-item__online'></div> : <></>
                    }}
                </UiUserStatus>
            </div>
            <div className="chat-room-item__inner">
                {item.type.is(ChatRoomTypeEnum.Direct) && (
                    <UiUserStatus className="chat-room-item__status" value={item.user}/>
                )}
                {item.type.is(ChatRoomTypeEnum.Group) && (
                    <div className="chat-room-item__status">{item.usersCounterText}</div>
                )}
                <div className="chat-room-item__name">{item.previewName}</div>
                {item.chatMessageLast && (
                    <UiHtml
                        className="chat-room-item__message"
                        value={item.chatMessageLast.chatMessageAttachments.length > 0
                            ? 'Пользователь прикрепил файл'
                            : item.chatMessageLast.content
                        }
                    />
                )}
            </div>
            <div className="chat-room-item__aside">
                {item.chatMessageLast && (
                    <div className="chat-room-item__datetime">
                        {item.chatMessageLast.createdAtMoment.format('HH:mm')}
                    </div>
                )}
                <div className="chat-room-item__actions">
                    <UiDropdownMenu
                        left={-12} toTop toRight
                        control={<UiIcon icon={ICONS.TRIPLEDOTS} size={13}/>}
                        onSelect={handleSelect}
                        items={[{
                            id: 'fix',
                            name: item.chatRoomUser?.isFixed ? 'Отменить закрепление чата' : 'Закрепить чат'
                        }, {
                            id: 'deactivate',
                            name: 'Удалить чат'
                        }]}
                    />
                </div>
                {item.chatMessagesUnreadCount > 0 && (
                    <div className="chat-room-item__unread-count">
                        {item.chatMessagesUnreadCount}
                    </div>
                )}
            </div>
        </UiNavLink>
    );
});
