import { makeQuery } from 'shared/utilities';
import { ISearchIndexModel } from 'shared/models';

type RequestType = {
    query: string;
}

type ResponseType = {
    items: ISearchIndexModel[];
};

export const searchHintsQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/search/hints', { params });
}
