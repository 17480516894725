import { makeQuery } from 'shared/utilities';
import { IAnnouncementCategoryModel } from 'shared/models';

type ResponseType = {
    items: IAnnouncementCategoryModel[];
};

export const announcementCategoriesQuery = async function () {
    return await makeQuery<ResponseType>('/announcement-categories');
}
