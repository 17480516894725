import classnames from "classnames";
import { observer } from 'mobx-react';
import React from 'react';
import { useMedia } from "shared/hooks";
import { ApplicationModule } from "shared/modules";

import { UiAvatar, UiDataBoundary, UiScrollbar } from "shared/uikit";

import { Store } from '../../store';

import { ChatMessage } from "../ChatMessage";

import './styles.scss';

export const ChatMessages = observer(() => {
    const {is320} = useMedia();
    return (
        <div className="chat-messages">
            <UiScrollbar
                customRef={(ref) => {
                    Store.set("scrollbar", ref.current);
                }}
                maxHeight={'calc(100vh - 112px - 120px)'}
                style={{ paddingTop: 17, paddingBottom: 17 }}
            >
                <UiDataBoundary isLoading={Store.isLoading} isError={!Store.isSuccess}>
                    {Store.chatMessageGrouped.map(dateGroup => (
                        <div key={dateGroup.date} className="chat-block-date">
                            <div className="chat-block-date__header">{dateGroup.date}</div>
                            <div className="chat-block-date__inner">
                                {dateGroup.users.map((chatMessagesGroup, index) => (
                                    <div
                                        data-user={chatMessagesGroup.user.id === ApplicationModule.user.id ? 'me' : 'other'}
                                        key={index}
                                        className={classnames('chat-block-user', {
                                            'chat-block-user--me': chatMessagesGroup.user.id === ApplicationModule.user.id
                                        })}
                                    >
                                        <div className="chat-block-user__aside">
                                            <UiAvatar
                                                size={is320 ? 20 : 32}
                                                label={chatMessagesGroup.user.previewName}
                                                image={chatMessagesGroup.user.image}
                                            />
                                        </div>
                                        <div className="chat-block-user__messages">
                                            {chatMessagesGroup.chatMessages.map(chatMessage => (
                                                <ChatMessage key={chatMessage.key} chatMessage={chatMessage}/>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </UiDataBoundary>
            </UiScrollbar>
        </div>
    );
});
