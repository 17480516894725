import { makeQuery } from 'shared/utilities';
import { IChatRoomModel } from 'shared/models';

type RequestType = {
    userId: number | number[],
    name?: string,
    typeId?: string,
    image?: number | null
}

type ResponseType = {
    item: IChatRoomModel;
};

export const chatRoomsCreateQuery = async function (payload: RequestType) {
    return await makeQuery<ResponseType>('/chat-rooms/create', { json: payload });
}
