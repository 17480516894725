import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { UiGrid, UiIcon, UiNavLink } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { useMedia, useStore } from "shared/hooks";
import { EducationCardModel } from "shared/models";
import { educationCardsQuery } from "shared/queries";

import './styles.scss';

export const PEducationCards = observer(() => {
    const store = useStore(() => ({
        isLoading: true,
        educationCards: [] as EducationCardModel[]
    }));

    useEffect(() => {
        (async () => {
            const { data, isSuccess } = await educationCardsQuery();
            if (data && isSuccess) {
                store.set("educationCards", data.items.map(item => new EducationCardModel(item)))
            }
            store.set("isLoading", false);
        })();
    }, [store]);

    const {isMobile} = useMedia()

    if (!store.isLoading && store.educationCards.length === 0) {
        return null;
    }

    return (
        <div className="p-education-section">
            <div className="p-education-section__title">Обучение</div>
            <div className="p-education-section__inner">
                <UiGrid columns={isMobile ? 1 : 3} gap={isMobile ? 8 : 24}>
                    {store.educationCards.map(educationCard => {
                        return (
                            <UiNavLink
                                key={educationCard.id}
                                to={educationCard.url}
                                className="p-education-card"
                                style={{ background: educationCard.color }}
                            >
                                <div className="p-education-card__inner">
                                    <div className="p-education-card__name">{educationCard.name}</div>
                                    <div className="p-education-card__details">
                                        <span>{educationCard.details}</span>
                                        <UiIcon icon={ICONS.ARROW_RIGHT} size={10} color={COLORS.WHITE}/>
                                    </div>
                                </div>
                                <div
                                    className="p-education-card__image"
                                    style={{ backgroundImage: `url(${educationCard.icon})` }}
                                />
                            </UiNavLink>
                        )
                    })}
                </UiGrid>
            </div>
        </div>
    );
});

