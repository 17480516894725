import { makeQuery } from 'shared/utilities';
import { ISpaceModel } from "shared/models/TaskTracker";

type ResponseType = {
    spaces: ISpaceModel[]
};

export const tasksFiltersQuery = async function () {
    return await makeQuery<ResponseType>('/tasks/filters', {
        prefix: 'task-tracker',
    });
}
