import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UiIcon } from "shared/uikit";
import { COLORS, ICONS } from "shared/constants";
import { ApplicationModule, LayoutModule } from "shared/modules";
import { useNavigate } from "shared/hooks";

import './styles.scss';

type PropsType = {
    children?: React.ReactNode;
    className?: string;
    title?: string;
    subtitle?: string,
    backTo?: string | [string, object] | (() => void);
    widgets?: boolean
}

export const LayoutContentTitle = observer((
    {
        title,
        className,
        widgets = true,
        children,
        backTo,
        subtitle
    }: PropsType
) => {
    const navigate = useNavigate();

    useEffect(() => {
        ApplicationModule.setTitle(title);
    }, [title]);

    const handleClick = useCallback(() => {
        if (typeof backTo === 'string') {
            navigate(backTo);
            return;
        }
        if (Array.isArray(backTo)) {
            navigate(backTo[0], backTo[1] || {});
            return;
        }
        if (typeof backTo === 'function') {
            backTo();
            return;
        }
        if (window.history.state?.idx > 0) {
            navigate(-1);
            return;
        }
    }, [navigate, backTo]);

    return (
        <div className={classnames('layout-content-title', className)}>
            {backTo && (
                <div onClick={handleClick} className="layout-content-title__back">
                    <UiIcon size={16} icon={ICONS.ARROW_LEFT} color={COLORS.GRAY_1}/>
                </div>
            )}
            <div className="layout-content-title__inner">
                <div className="layout-content-title__title">
                    {title}
                </div>
                {subtitle && (
                    <div className="layout-content-title__subtitle">
                        {subtitle}
                    </div>
                )}
            </div>

            {children && (
                <div className="layout-content-title__actions">
                    {children}
                </div>
            )}
            {widgets && (
                <div
                    className="layout-content-title__widgets"
                    onClick={() => LayoutModule.aside.setOpened(true)}
                >
                    <UiIcon icon={ICONS.WIDGETS} size={20} color={COLORS.GRAY_3}/>
                </div>
            )}
        </div>
    );
});
