import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutBody, LayoutContent } from 'shared/layout';
import { UiIcon, UiLightbox } from "shared/uikit";
import { useMedia, useStore } from "shared/hooks";

import { ICONS } from "shared/constants";
import { ApplicationModule } from "shared/modules";
import { ValuesItemModel } from "shared/models";
import { valuesItemsQuery } from "shared/queries/valuesItemsQuery";

import './index.scss';

const imgPlay = require('./assets/play.png');
const imgBanner = require('./assets/banner.webp');
const video = require('./assets/video.mp4');
const icon1 = require('./assets/icon1.png');
const icon2 = require('./assets/icon2.png');
const icon3 = require('./assets/icon3.png');
const icon4 = require('./assets/icon4.png');
const icon5 = require('./assets/icon5.png');
const lama = require('./assets/lama.png');
const quote = require('./assets/quote.svg');
const footer = require('./assets/footer.webp');
const footerMobile = require('./assets/footerMobile.webp');
const footerTablet = require('./assets/footerTablet.webp');

export const ValuesPage = observer(() => {
    const store = useStore(() => ({
        isOpened: false,
        valuesItems: [] as ValuesItemModel[]
    }));

    useEffect(() => {
        ApplicationModule.setTitle('Ценности компании');
        (async () => {
            const { isSuccess, data } = await valuesItemsQuery();
            if (isSuccess && data) {
                store.set("valuesItems", data.items.map(item => new ValuesItemModel(item)));
            }
        })();
    }, [store])

    const {isMobile, isTablet} = useMedia();

    return (
        <LayoutBody className='p-values'>
            <LayoutContent>
                <div className="p-values-banner" style={{ backgroundImage: `url(${imgBanner})` }}>
                    <div className="p-values-banner__title">Наши ценности за 2 минуты</div>
                    <div
                        className="p-values-banner__play"
                        onClick={() => store.set("isOpened", true)}
                        style={{ backgroundImage: `url(${imgPlay})` }}
                    />
                </div>
                <div className="p-values__title">От ценностей к богатству</div>
                <div className="p-values__subtitle">From values to valuables</div>
                <div className="p-values__description">
                    Наше развитие и наши достижения основываются на ценностях, которые вот уже почти 100 лет помогают
                    нам оставаться лидерами индустрии. Мы верим в то, что только единое понимание нашего пути на всех
                    уровнях каждым сотрудником Togas, может привести всех нас к выдающимся результатам, создавая основу
                    наших общих успехов и благосостояния.
                </div>
                <div className="p-values-preview">
                    <div className="p-values-preview__title">Вот они:</div>
                    <div className="p-values-preview__items">
                        <div className="p-values-preview-item">
                            <div className="p-values-preview-item__icon" style={{ backgroundImage: `url(${icon1})` }}/>
                            <div className="p-values-preview-item__name">
                                Клиентоцентризм. <br/>
                                Одержимость клиентом
                            </div>
                        </div>
                        <div className="p-values-preview-item">
                            <div className="p-values-preview-item__icon" style={{ backgroundImage: `url(${icon2})` }}/>
                            <div className="p-values-preview-item__name">
                                Безграничное доверие
                            </div>
                        </div>
                        <div className="p-values-preview-item">
                            <div className="p-values-preview-item__icon" style={{ backgroundImage: `url(${icon3})` }}/>
                            <div className="p-values-preview-item__name">
                                Грандиозные результаты
                            </div>
                        </div>
                        <div className="p-values-preview-item">
                            <div className="p-values-preview-item__icon" style={{ backgroundImage: `url(${icon4})` }}/>
                            <div className="p-values-preview-item__name">
                                Революционные инновации
                            </div>
                        </div>
                        <div className="p-values-preview-item">
                            <div className="p-values-preview-item__icon" style={{ backgroundImage: `url(${icon5})` }}/>
                            <div className="p-values-preview-item__name">
                                Глобальное мышление
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-values-quote">
                    <div className="p-values-quote__logo">
                        <UiIcon icon={ICONS.LOGO} size={1800} color={'#F0EDE6'}/>
                    </div>
                    <div className="p-values-quote__inner">
                        <div className="p-values-quote__icon" style={{ backgroundImage: `url(${quote})` }}/>
                        <div className="p-values-quote__image" style={{ backgroundImage: `url(${lama})` }}/>
                        <div className="p-values-quote__text">
                            Будь готов изменить свои цели,<br/>
                            но никогда не изменяй свои ценности
                        </div>
                        <div className="p-values-quote__author">Далай-лама XIV</div>
                    </div>
                </div>
                <div className="p-values__items">
                    {store.valuesItems.map(valueItem => (
                        <div className="p-values-item" key={valueItem.id}>
                            <div className="p-values-item__name">{valueItem.name}</div>
                            <div className="p-values-item__description">{valueItem.description}</div>
                            <ul className="p-values-item__items">
                                {valueItem.items.split("\n").map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className="p-values-footer">
                    {isMobile && <img src={footerMobile} alt=""/>}
                    {isTablet && <img src={footerTablet} alt=""/>}
                    {!(isMobile || isTablet) && <img src={footer} alt=""/>}
                </div>
            </LayoutContent>
            <UiLightbox
                isOpened={store.isOpened}
                items={[video]}
                onClose={() => store.set("isOpened", false)}
            />
        </LayoutBody>
    )
});
