import { makeQuery } from 'shared/utilities';
import { ISearchIndexModel } from 'shared/models';

type ResponseType = {
    items: ISearchIndexModel[];
};

export const ispringCoursesQuery = async function () {
    return await makeQuery<ResponseType>('/ispring/courses');
}
