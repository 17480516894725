import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { ICONS } from 'shared/constants';
import { ObjectType } from 'shared/types';
import { Helpers } from "shared/utilities";

import { UiIcon } from "../UiIcon";

import './styles.scss';

type PropsType = {
    extension?: string;
    size?: number;
}

const colors: ObjectType = {
    default: '#717171',
    csv: '#007c00',
    doc: '#0167b3',
    docx: '#0167b3',
    jpeg: '#f5ca2c',
    jpg: '#f5ca2c',
    pdf: '#cb4124',
    ppt: '#E2574C',
    pptx: '#E2574C',
    xls: '#1d6e42',
    xlsx: '#1d6e42',
};

export const UiFileIcon = observer((
    {
        extension = 'txt',
        size = 20,
    }: PropsType
) => {

    const primary = useMemo(() => {
        return colors[extension] ? colors[extension] : colors.default;
    }, [extension])

    const secondary = useMemo(() => {
        return Helpers.mutateHexColor(primary, 20);
    }, [primary]);

    return (
        <UiIcon
            style={{ justifyContent: 'flex-start' }}
            size={size}
            icon={ICONS.FILE}
            iconProps={{
                colors: [primary, secondary],
                text: extension,
            }}
        />
    );
})
