import React, { ReactNode, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { set } from 'mobx';

import { ObjectType, QueryResponseType } from "shared/types";

type FormType = ObjectType & {
    isLoading: boolean,
    isSubmitted: boolean,
    errorMessage: string,
    data: ObjectType,
    set: typeof set
};

type ValidationType = ObjectType & {
    isValid: boolean,
};

type ActionType = (...args: any[]) => Promise<QueryResponseType>;

interface Props {
    form?: FormType;
    validation?: ValidationType;
    onSubmit?: () => void;
    onResponse?: (response?: any) => void;
    action?: ActionType;
    className?: string;
    children?: ReactNode
}

export const UiFormHook = observer((
    {
        className = '',
        onSubmit,
        onResponse,
        children,
        form,
        validation,
        action
    }: Props
) => {
    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        form?.set(form, 'isSubmitted', true);

        if (validation && !validation.isValid) {
            return;
        }

        form?.set(form, 'isLoading', true);

        onSubmit && onSubmit();

        if (action) {
            const response = await action(form ? form.data : {});
            if (!response.isSuccess || !response.data) {
                form?.set(form, 'errorMessage', response.errorMessage);
            } else {
                onResponse && onResponse(response);
            }
        }

        form?.set(form, 'isLoading', false);
    };

    return (
        <form className={className} onSubmit={handleSubmit}>
            {children}
        </form>
    );
})
