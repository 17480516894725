import { action, makeObservable, observable } from 'mobx';

import { ModelArrayCast, ModelCast } from "shared/casts";

import { DepartmentModel, IDepartmentModel } from "./DepartmentModel";
import { IUserModel, UserModel } from "./UserModel";
import { Model } from './Model';

export interface IDepartmentBranchModel {
    id?: number;
    departmentId?: number;
    name?: string;
    description?: string;
    management?: IUserModel[];
    users?: IUserModel[];
    isVisible?: boolean;
    department?: IDepartmentModel | null;
}

export class DepartmentBranchModel extends Model implements IDepartmentBranchModel {
    casts = {
        management: new ModelArrayCast(UserModel),
        users: new ModelArrayCast(UserModel),
        department: new ModelCast(DepartmentModel)
    };

    id = 0;
    departmentId = 0;
    name = '';
    description = '';
    isVisible = true;
    management: UserModel[] = [];
    users: UserModel[] = [];
    department: DepartmentModel | null = null;

    constructor(payload: IDepartmentBranchModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            departmentId: observable,
            name: observable,
            isVisible: observable,
            description: observable,
            management: observable,
            department: observable,
            users: observable,
            update: action,
        });

        this.update(payload);
    }
}
