import { action, makeObservable, observable } from 'mobx';

import { Model } from './Model';

export interface ITagModel {
    id?: number | string;
    name?: string;
}

export class TagModel extends Model implements ITagModel {
    id: number | string = 0;
    name = '';

    constructor(payload: ITagModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            update: action,
        });

        this.update(payload);
    }
}
