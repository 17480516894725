import { action, makeObservable, observable } from 'mobx';

import { ModelCast } from "shared/casts";
import { Model } from './Model';
import { INewsModel, NewsModel } from "shared/models/NewsModel";
import { ISurveyModel, SurveyModel } from "shared/models/SurveyModel";
import { AnnouncementModel, IAnnouncementModel } from "shared/models/AnnouncementModel";

export interface IChatMessageArticleModel {
    id?: number;
    news?: INewsModel | null,
    survey?: ISurveyModel | null,
    announcement?: IAnnouncementModel | null,

}

export class ChatMessageArticleModel extends Model implements IChatMessageArticleModel {
    casts = {
        news: new ModelCast(NewsModel),
        survey: new ModelCast(SurveyModel),
        announcement: new ModelCast(AnnouncementModel),
    }

    id = 0;
    news: NewsModel | null = null;
    survey: SurveyModel | null = null;
    announcement: AnnouncementModel | null = null;

    constructor(payload: IChatMessageArticleModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            news: observable,
            survey: observable,
            announcement: observable,
            update: action,
        });

        this.update(payload);
    }
}
