import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useMedia, useNavigate, useStore, useUrlParams, useUserWidgets } from "shared/hooks";
import { WSocialNetworks } from "shared/widgets";
import { UiFilter, UiGrid, UiTabs, UiViewType } from "shared/uikit";
import { EducationLessonModel, TagModel } from "shared/models";
import { educationLessonsQuery, tagsQuery } from "shared/queries";
import { CEducationLesson } from "shared/components";
import { ROUTES } from "shared/constants";

import { PEducationLesson } from "./components/PEducationLesson";

import './styles.scss';

const TABS = [
    { id: null, name: 'Все' },
    { id: 'online', name: 'Онлайн' },
    { id: 'offline', name: 'Очные' },
    { id: 'ispring', name: 'iSpring' }
];
export const EducationLessonsPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        typeId: null as null | string,
        tagId: null as null | number | string,
        tab: null as null | string,
        educationLessonId: null as null | number,
    });
    const store = useStore(() => ({
        isLoading: true,
        educationLessons: [] as EducationLessonModel[],
        tags: [] as TagModel[],
        viewType: 'card'
    }));

    useEffect(() => {
        store.set("isLoading", true);
        (async () => {
            const { data, isSuccess } = await educationLessonsQuery({
                ...urlParams
            });

            if (isSuccess && data) {
                store.set("educationLessons", data.items.map(item => new EducationLessonModel(item)))
            }

            store.set("isLoading", false);
        })();
    }, [store, urlParams]);

    useEffect(() => {
        (async () => {
            const response = await tagsQuery({ has: ['education_lessons'] });
            if (!response.isSuccess || !response.data) {
                return;
            }
            store.set('tags', response.data.items.map(item => new TagModel(item)));
        })();
    }, [store]);

    const educationLessons = store.educationLessons.slice().filter((educationLesson) => {
        if (urlParams.tab === 'online' && !educationLesson.isOnline) {
            return false;
        }
        if (urlParams.tab === 'offline' && !educationLesson.isOffline) {
            return false;
        }
        if (urlParams.tab === 'ispring' && !educationLesson.isIspring) {
            return false;
        }
        return true;
    });

    const { value: columns } = useMedia({
        is320: { card: 1, list: 1 },
        is1024: { card: 2, list: 1 },
    })

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle
                    title={urlParams.typeId === 'game' ? 'Игры' : 'Тренинги'}
                    subtitle={'Собрали все корпоративные тренинги в одном месте'}
                    backTo={ROUTES.EDUCATION()}
                >
                    <UiFilter
                        items={store.tags}
                        value={urlParams.tagId}
                        onChange={({ value }) => {
                            navigate(ROUTES.EDUCATION_LESSONS(), {
                                ...urlParams,
                                tagId: value
                            }, { replace: true })
                        }}
                    />
                    <UiViewType value={store.viewType} onChange={({ value }) => store.set("viewType", value)}/>
                </LayoutContentTitle>
                <div className="p-education-lessons__tabs">
                    <UiTabs
                        items={TABS}
                        value={urlParams.tab}
                        onChange={({ value }) => {
                            navigate(ROUTES.EDUCATION_LESSONS(), {
                                ...urlParams,
                                tab: value
                            }, { replace: true })
                        }}
                    />
                </div>
                <UiGrid columns={store.viewType === 'card' ? columns.card : columns.list} gap={24}>
                    {educationLessons.map(educationLesson => (
                        <PEducationLesson
                            educationLesson={educationLesson}
                            type={store.viewType}
                            onSelect={() => {
                                navigate(ROUTES.EDUCATION_LESSONS(), {
                                    ...urlParams,
                                    educationLessonId: educationLesson.id
                                }, {
                                    replace: true
                                })
                            }}
                        />
                    ))}
                </UiGrid>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}>
                <WSocialNetworks/>
            </LayoutAside>
            <CEducationLesson
                onClose={() => {
                    navigate(ROUTES.EDUCATION_LESSONS(), {
                        ...urlParams,
                        educationLessonId: null
                    }, {
                        replace: true
                    })
                }}
                id={urlParams.educationLessonId}
                isOpened={!!urlParams?.educationLessonId}
            />
        </LayoutBody>
    );
});

