import { makeQuery } from 'shared/utilities';
import { IChatRoomModel } from 'shared/models';

type ResponseType = {
    item: IChatRoomModel;
};

export const chatRoomsGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/chat-rooms/get', {params: {id}});
}
