import { makeQuery } from 'shared/utilities';
import { IDepartmentModel } from 'shared/models';

type RequestType = {
    id: number | string
}

type ResponseType = {
    item: IDepartmentModel;
};

export const departmentsGetQuery = async function (params: RequestType) {
    return await makeQuery<ResponseType>('/departments/get', { params });
}
