import { action, makeObservable, observable, runInAction, makeAutoObservable } from 'mobx';

import { Storage } from 'shared/utilities';

class LayoutMenu {
    inProgress = false;
    isOpened = false;
    isInitialized = false;

    constructor() {
        makeObservable(this, {
            isOpened: observable,
            isInitialized: observable,
            inProgress: observable,
            toggle: action,
            show: action,
            hide: action,
        });

        runInAction(async () => {
            this.isOpened = await Storage.get('LayoutMenu.isOpened', false);

            setTimeout(() => {
                runInAction(() => {
                    this.isInitialized = true;
                });
            }, 200);
        });
    }

    private set = (value: boolean) => {
        this.isOpened = value;
        Storage.set('LayoutMenu.isOpened', value);
    };

    toggle = () => {
        this.inProgress = true;
        setTimeout(() => {
            runInAction(() => {
                this.set(!this.isOpened);

                setTimeout(() => {
                    runInAction(() => {
                        this.inProgress = false;
                    });
                }, 401);
            });
        }, 1);
    };

    show = () => {
        this.set(true);
    };

    hide = () => {
        this.set(false);
    };
}

export default new (class Store {
    menu = new LayoutMenu();
    notifications = {
        isOpened: false
    }

    constructor() {
        makeAutoObservable(this);
    }
})();
