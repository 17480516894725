import React, { useEffect, useMemo } from 'react';
import { set } from "mobx";
import { observer, useLocalObservable } from 'mobx-react';
import lodash from 'lodash';
import moment, { Moment } from 'moment';

import { ReservationModel, ReservationPlaceModel, ReservationPlaceIntervalModel } from "shared/models";
import { ICONS, COLORS, DATE_FORMAT } from "shared/constants";

import { UiIcon } from '../UiIcon';

import { UiReservationPlaceInterval } from "./components/UiReservationPlaceInterval";
import { UiReservationPlaceReservation } from "./components/UiReservationPlaceReservation";
import { UiReservationPlaceSelection } from "./components/UiReservationPlaceSelection";

import './styles.scss';

type PropsType = {
    reservationPlace: ReservationPlaceModel;
    reservation: ReservationModel;
    onDelete?: (id: number) => any;
    onEdit?: (id: number) => any;
    date: string;
    onSelected?: () => void
}

export const UiReservationPlace = observer((
    {
        reservationPlace,
        reservation,
        date,
        onDelete,
        onEdit,
        onSelected
    }: PropsType
) => {
    const store = useLocalObservable(() => ({
        set: set,
        intervals: [] as ReservationPlaceIntervalModel[],
        startMoment: null as Moment | null,
        hoverMoment: null as Moment | null,
    }));

    useEffect(() => {
        if (!reservation.startAt) {
            store.set(store, 'startMoment', null);
        }
    }, [store, reservation.startAt])

    useEffect(() => {
        const intervals: ReservationPlaceIntervalModel[] = [];

        let current = moment(date).set('hours', 8).set('minutes', 0);
        const to = current.clone().set('hours', 23).set('minutes', 59);

        while (current.isBefore(to)) {
            intervals.push(new ReservationPlaceIntervalModel({
                moment: current.clone()
            }));
            current.add(30, 'minutes');
        }
        store.set(store, 'intervals', intervals);
    }, [store, reservationPlace, reservation, date]);

    const reservationByTime = useMemo(() => {
        return lodash.keyBy(reservationPlace.reservations, reservation => reservation.startAtTime);
    }, [reservationPlace]);

    const reservedTimes = useMemo(() => {
        let intervals = [...reservationPlace.reservations.map(reservation => reservation.intervals)];

        if (reservationPlace.id === reservation.reservationPlaceId) {
            intervals.push(reservation.intervals);
        }
        return lodash.flatten(intervals);
    }, [reservationPlace, reservation.intervals, reservation.reservationPlaceId]);

    const involvedTimes = useMemo(() => {
        const times: string[] = [];
        if (!store.startMoment || reservationPlace.id !== reservation.reservationPlaceId) {
            return times;
        }
        const current = store.startMoment.clone();
        times.push(current.format(DATE_FORMAT.TIME));
        if (!store.hoverMoment) {
            return times;
        }
        while (current.isSameOrBefore(store.hoverMoment)) {
            const time = current.format(DATE_FORMAT.TIME);
            if (reservedTimes.indexOf(time) > -1) {
                return times;
            }
            times.push(time);
            current.add(30, 'minutes');
        }

        return times;
    }, [store.startMoment, store.hoverMoment, reservationPlace.id, reservation.reservationPlaceId, reservedTimes]);

    return (
        <div className='ui-reservation-place'>
            {reservationPlace.previewImageUrl && (
                <div
                    className="ui-reservation-place__image"
                    style={{ backgroundImage: `url(${reservationPlace.previewImageUrl})` }}>
                </div>
            )}
            {!reservationPlace.previewImageUrl && (
                <div className="ui-reservation-place__image">
                    <UiIcon size={64} color={COLORS.GRAY_3} icon={ICONS.SETTINGS}/>
                </div>
            )}
            <div className="ui-reservation-place__header">
                <div className="ui-reservation-place__name">{reservationPlace.name}</div>
                <div className="ui-reservation-place__description">{reservationPlace.description}</div>
            </div>
            <div className="ui-reservation-place__inner">
                {store.intervals.map((item) => {
                    if (reservationByTime[item.time]) {
                        return (
                            <UiReservationPlaceReservation
                                key={item.time}
                                value={reservationByTime[item.time]}
                                onCancel={onDelete}
                                onEdit={onEdit}
                            />
                        )
                    }

                    if (
                        reservation.startAt &&
                        reservation.endAt &&
                        reservation.startAtTime === item.time &&
                        reservation.reservationPlaceId === reservationPlace.id
                    ) {
                        return (
                            <UiReservationPlaceSelection
                                key={item.time}
                                value={reservation}
                                onClear={() => {
                                    store.set(store, 'startMoment', null);
                                    reservation.update({
                                        startAt: store.startMoment?.format(),
                                        endAt: store.hoverMoment?.clone().add(30, 'minutes').format(),
                                    });
                                }}
                            />
                        )
                    }

                    if (reservedTimes.indexOf(item.time) > -1) {
                        return null;
                    }

                    const isInvolved = involvedTimes.indexOf(item.time) > -1;
                    return (
                        <UiReservationPlaceInterval
                            key={item.time}
                            label={item.time}
                            isEven={item.isEven}
                            isInvolved={isInvolved}
                            onClick={() => {
                                if (!isInvolved) {
                                    store.set(store, 'startMoment', item.moment.clone());
                                    reservation.update({
                                        reservationPlaceId: reservationPlace.id,
                                        reservationPlace: reservationPlace,
                                        startAt: '',
                                        endAt: '',
                                    });
                                    return;
                                }
                                reservation.update({
                                    startAt: store.startMoment?.format(),
                                    endAt: store.hoverMoment?.clone().add(30, 'minutes').format(),
                                });
                                onSelected && onSelected();
                            }}
                            onMouseEnter={() => {
                                store.set(store, 'hoverMoment', item.moment.clone());
                            }}
                        />
                    );
                })}
            </div>
        </div>
    )
});

