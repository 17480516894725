export const currency = (value: number, sign = '₽') => {
    const price = Math.round(value * 100) / 100;
    let formatted = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(price);

    formatted = `${formatted}`.replace(',00', '').replace('₽', '').trim();

    if (sign) {
        formatted = `${formatted}\u00A0${sign}`;
    }

    return formatted;
}
