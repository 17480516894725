import { makeQuery } from 'shared/utilities';
import { IBoardModel, IColumnModel, ISpaceModel, ITaskModel } from "shared/models/TaskTracker";
import { IUserModel } from "shared/models";

type RequestType = {
    id: number | null
}

type ResponseType = {
    item: ISpaceModel,
    spaces: ISpaceModel[],
    boards: IBoardModel[],
    columns: IColumnModel[],
    tasks: ITaskModel[],
    users: IUserModel[],
    owners: IUserModel[],
};

export const spacesGetQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/spaces/get', {
        prefix: 'task-tracker',
        params: request
    });
}
