import React from 'react';
import { observer } from 'mobx-react';

import { ApplicationModule } from 'shared/modules';
import { UiButton, UiForm, UiFormControl, UiInput } from 'shared/uikit';
import { ispringConnectQuery, ispringDisconnectQuery } from "shared/queries";
import { useAction, useStore, useValidation } from "shared/hooks";

import './styles.scss';

export const PProfileISpring = observer(() => {
    const store = useStore(() => ({
        isLoading: false,
        isSubmitted: false,
        errorMessage: '',
    }))

    const form = useStore(() => ({
        login: '',
        password: '',
    }));

    const validation = useValidation(form, (rules) => ({
        login: rules.email(),
        password: rules.required()
    }))

    const handleSubmit = async () => {
        store.set("isSubmitted", true);
        store.set("errorMessage", '');
        if (!validation.isValid) {
            return;
        }

        store.set("isLoading", true);
        const { isSuccess, errorMessage, data } = await ispringConnectQuery(form);
        if (isSuccess && data) {
            ApplicationModule.user.update({
                ispringLogin: data.item.ispringLogin
            });
        } else {
            store.set('errorMessage', errorMessage)
        }
        store.set("isLoading", false);
    };

    const handleDisconnect = useAction(() => {
        ApplicationModule.user.update({
            ispringLogin: null,
        });
        ispringDisconnectQuery();
    });

    if (ApplicationModule.user.ispringLogin) {
        return (
            <div className='p-user-section'>
                <div className='p-user-section__title'>Интеграция с iSpring: {ApplicationModule.user.ispringLogin}</div>
                <UiButton label='Отключить' onClick={handleDisconnect} style={{ width: 196 }}/>
            </div>
        )
    }

    return (
        <UiForm className='p-user-section' onSubmit={handleSubmit}>
            <div className="p-user-section__title">Интеграция с iSpring</div>
            <div className="p-profile-form__inner">
                <div className="p-profile-form__field">
                    <UiFormControl
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.login.errorMessage}
                    >
                        <UiInput
                            name='login'
                            placeholder='Ваш логин ISpring'
                            isPlaceholderFluid
                            onChange={form.handleChange}
                            value={form.login}
                            isError={store.isSubmitted && !validation.login.isValid}
                        />
                    </UiFormControl>
                </div>
                <div className="p-profile-form__field">
                    <UiFormControl
                        isSubmitted={store.isSubmitted}
                        errorMessage={validation.password.errorMessage}
                    >
                        <UiInput
                            name='password'
                            placeholder='Ваш пароль ISpring'
                            isPlaceholderFluid
                            type={UiInput.TYPE.PASSWORD}
                            onChange={form.handleChange}
                            value={form.password}
                            isError={store.isSubmitted && !validation.password.isValid}
                        />
                    </UiFormControl>
                </div>
                <div className="p-profile-form__button">
                    <UiFormControl
                        isSubmitted={store.isSubmitted}
                        errorMessage={store.errorMessage}
                    >
                        <UiButton
                            style={{ width: '100%' }}
                            isSubmit
                            label='Подключить'
                            isLoading={store.isLoading}
                        />
                    </UiFormControl>
                </div>
            </div>
        </UiForm>
    );
});
