import { computed, makeObservable, observable } from 'mobx';

import { TagModel } from 'shared/models';
import { ModelArrayCast } from "shared/casts";

import { Model } from './Model';

export interface IVacancyRegionModel {
    id?: number;
    name?: string;
    vacanciesCount?: number;
}

export class VacancyRegionModel extends Model implements IVacancyRegionModel {
    casts = {
        tags: new ModelArrayCast(TagModel),
    };

    id = 0;
    name = '';
    vacanciesCount = 0;

    constructor(payload: IVacancyRegionModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            name: observable,
            nameFormatted: computed,
        });

        this.update(payload);
    }

    get nameFormatted() {
        return `${this.name} (${this.vacanciesCount})`;
    }
}
