import { makeQuery } from 'shared/utilities';
import { IChatRoomModel } from 'shared/models';

type ResponseType = {
    items: IChatRoomModel[];
};

export const chatRoomsQuery = async function () {
    return await makeQuery<ResponseType>('/chat-rooms');
}
