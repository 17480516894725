import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';

type RequestType = {
    query?: string;
    id?: number | number[],
    limit?: number;
}

type ResponseType = {
    pages: number;
    items: IUserModel[];
};

export const usersQuery = async function (params?: RequestType) {
    return await makeQuery<ResponseType>('/users', { params });
}
