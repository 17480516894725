import { Enum } from './Enum';

export class CalendarItemEventTypeEnum extends Enum {
    static Meeting = 'Встреча';
    static Reminder = 'Напоминание';
    static Task = 'Задача';
    static Education = 'Обучение';

    static items = [{
        id: CalendarItemEventTypeEnum.Meeting,
        name: 'Встреча'
    }, {
        id: CalendarItemEventTypeEnum.Reminder,
        name: 'Напоминание'
    }, {
        id: CalendarItemEventTypeEnum.Task,
        name: 'Задача'
    }, {
        id: CalendarItemEventTypeEnum.Education,
        name: 'Обучение'
    }];
}

