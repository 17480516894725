import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from "mobx";
import classnames from 'classnames';

import { UiButton, UiDataBoundary, UiIcon, UiNotification, UiScrollbar, UiTabs } from 'shared/uikit';
import { LayoutStore } from 'shared/layout';
import { useBroadcast, useDebounce, useMedia, useNavigate, useOnClickOutside, useStore } from "shared/hooks";
import { notificationsQuery, notificationsReadQuery, notificationsUnreadCountQuery } from "shared/queries";
import { NotificationModel } from "shared/models";
import { COLORS, ICONS, ROUTES } from "shared/constants";
import { NotificationCreatedBroadcast, NotificationUnreadCountBroadcast } from "shared/broadcasts";

import './styles.scss';

export const LayoutMenuSecondaryNotifications = observer(() => {
    const navigate = useNavigate();
    const ref = useRef(null);
    const { isMobileOrTablet } = useMedia();
    const store = useStore(() => ({
        isOpened: false,
        isSuccess: true,
        isLoading: true,
        unreadCount: 0,
        items: [] as any[],
        filter: 'unread'
    }));

    const className = classnames('layout-notifications', {
        'layout-notifications--opened': store.isOpened,
    });

    const readNotifications = useCallback(async (items: NotificationModel[]) => {
        if (items.length === 0) {
            return;
        }
        if (store.isOpened) {
            await notificationsReadQuery({
                id: items.map(({ id }) => id || '')
            });
        }
    }, [store.isOpened]);

    const fetchItems = useCallback(async () => {
        store.set('isSuccess', true);
        store.set('isLoading', true);
        const response = await notificationsQuery({
            filter: store.filter,
            perPage: store.filter === 'all' ? 10 : 20
        });
        if (response.isSuccess && response.data) {
            store.set('items', response.data.items.map(item => new NotificationModel(item)));
        } else {
            store.set('isSuccess', false);
        }
        store.set('isLoading', false);
        readNotifications(store.items);
    }, [store, readNotifications]);


    const fetchUnreadCount = useDebounce(async () => {
        const response = await notificationsUnreadCountQuery();
        if (response.isSuccess && response.data) {
            store.set('unreadCount', response.data.count);
        }
    }, 400);

    const handleToggle = () => {
        if (isMobileOrTablet) {
            navigate(ROUTES.NOTIFICATIONS());
            return;
        }
        store.set("isOpened", !store.isOpened);
        readNotifications(store.items);
    }

    useEffect(() => {
        if (!isMobileOrTablet) {
            store.set('isOpened', false);
        }
    }, [isMobileOrTablet, store])

    useEffect(() => {
        fetchItems();
    }, [store.filter, fetchItems])

    useEffect(() => {
        fetchUnreadCount();
    }, [fetchUnreadCount]);

    useOnClickOutside(ref, () => {
        store.set("isOpened", false);
    });

    useBroadcast(new NotificationCreatedBroadcast(() => {
        fetchUnreadCount();
    }));

    useBroadcast(new NotificationUnreadCountBroadcast(() => {
        fetchUnreadCount();
    }));

    return (
        <div className="layout-menu-secondary-item" ref={ref}>
            <div className="layout-menu-secondary-item__inner" onClick={handleToggle}>
                <UiIcon icon={ICONS.NOTIFICATIONS} size={24} color={COLORS.WHITE}/>
                {!!store.unreadCount && (
                    <div className="layout-menu-secondary-item__count">{store.unreadCount}</div>
                )}
            </div>
            <div className={className}>
                <div className="layout-notifications__header">Уведомления</div>
                <div className="layout-notifications__tabs">
                    <UiTabs
                        items={[
                            { id: 'unread', name: 'Непрочитанные' },
                            { id: 'all', name: 'Последние' }
                        ]}
                        value={store.filter}
                        onChange={({ value }) => store.set('filter', value)}
                    />
                </div>
                <UiDataBoundary
                    style={{ height: 70 }}
                    isLoading={store.isLoading}
                    isError={!store.isSuccess}
                >
                    <UiScrollbar maxHeight={`calc(100vh - 200px)`}>
                        <div className="layout-notifications__items">
                            {store.items.map((item) => <UiNotification key={item.id} value={item} isLight={true}/>)}
                            {store.items.length === 0 && (
                                <UiIcon size={70} icon={ICONS.NOTIFICATIONS_EMPTY}/>
                            )}
                        </div>
                    </UiScrollbar>
                </UiDataBoundary>
                <div className="layout-notifications__footer">
                    <UiButton
                        isLink
                        label='Смотреть все'
                        onClick={() => {
                            runInAction(() => {
                                LayoutStore.notifications.isOpened = false;
                            });
                            navigate(ROUTES.NOTIFICATIONS())
                        }}
                    />
                </div>
            </div>
        </div>
    )
});
