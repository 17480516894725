import { ColumnModel } from "shared/models/TaskTracker";
import { TaskTrackerColumnTypeEnum } from "shared/enums";

export const personalColumns = [
    new ColumnModel({
        id: TaskTrackerColumnTypeEnum.New.id,
        sort: 0,
        name: TaskTrackerColumnTypeEnum.New.name,
        typeId: TaskTrackerColumnTypeEnum.Personal.id,
    }),
    new ColumnModel({
        id: TaskTrackerColumnTypeEnum.Progress.id,
        sort: 1000,
        name: TaskTrackerColumnTypeEnum.Progress.name,
        typeId: TaskTrackerColumnTypeEnum.Personal.id,
    }), new ColumnModel({
        id: TaskTrackerColumnTypeEnum.Done.id,
        sort: 2000,
        name: TaskTrackerColumnTypeEnum.Done.name,
        typeId: TaskTrackerColumnTypeEnum.Personal.id,
    })
]
