import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { Wrapper } from '@googlemaps/react-wrapper';

import { useAction } from 'shared/hooks';

import './styles.scss';

interface Props {
    style?: React.CSSProperties;
    zoom?: number | undefined;
    center: google.maps.LatLngLiteral;
    markers?: google.maps.LatLngLiteral[];
}

const UiGoogleMap = observer(
    ({
        markers = [],
        center = {
            lat: 59,
            lng: 88,
        },
        zoom = 14,
    }: Props) => {
        const store = useLocalObservable<{
            zoom: number | undefined;
            center: google.maps.LatLngLiteral | undefined;
        }>(() => ({
            zoom: zoom,
            center: center,
        }));

        const onIdle = useAction((map: google.maps.Map) => {
            store.zoom = map.getZoom();
            store.center = map.getCenter()?.toJSON();
        });

        return (
            <div className='ui-google-map'>
                <Wrapper apiKey='AIzaSyAKUqHZ4PApW-up2EksYJYI-Gz-cfIpGdY'>
                    <Map
                        center={center}
                        zoom={zoom}
                        style={{ flexGrow: '1', height: '300' }}
                        onIdle={onIdle}
                        zoomControl={false}
                        mapTypeControl={false}
                        scaleControl={false}
                        streetViewControl={false}
                        rotateControl={false}
                        fullscreenControl={false}
                    >
                        {markers?.map((position, index) => (
                            <Marker key={index} position={position} />
                        ))}
                    </Map>
                </Wrapper>
            </div>
        );
    }
);

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode
}

const Map: React.FC<MapProps> = ({ onIdle, children, style, ...options }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    React.useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach(eventName => google.maps.event.clearListeners(map, eventName));

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, [map, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { map } as any);
                }
            })}
        </>
    );
};

const Marker = (options: google.maps.MarkerOptions) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};

export default UiGoogleMap;
