import * as history from 'history';
import qs from 'qs';
import lodash from 'lodash';

import { ObjectType } from 'shared/types';

class _Router {
    history: history.History;
    handlers: ObjectType = {};

    constructor() {
        this.history = history.createBrowserHistory();
    }

    navigate = (route: string, queryParams: ObjectType = {}, keepQueryCurrentParams = false) => {
        let queryParamsSnakeCase: ObjectType = {};
        for (let property in queryParams) {
            queryParamsSnakeCase[lodash.snakeCase(property)] = queryParams[property];
        }

        let params: ObjectType = {};
        if (keepQueryCurrentParams) {
            params = qs.parse(this.history.location.search.substr(1));
            params = {
                ...params,
                ...queryParamsSnakeCase,
            };
        } else {
            params = { ...queryParamsSnakeCase };
        }

        this?.history?.push(
            Object.keys(params).length === 0
                ? route
                : route +
                      '?' +
                      qs.stringify(params, {
                          skipNulls: true,
                          filter: function (key, value) {
                              if (key.toLowerCase().indexOf('_id') > -1 && +value === -1) {
                                  return;
                              }
                              return value;
                          },
                      })
        );
    };
}

const Router = new _Router();

export default Router;
