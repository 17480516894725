import { makeQuery } from 'shared/utilities';

type RequestType = {
    id: string,
    changeKey: string
};

type ResponseType = {};

export const calendarItemDeleteQuery = async function (data: RequestType) {
    return await makeQuery<ResponseType>('/calendar-item/delete', { json: data });
}
