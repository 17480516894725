import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UserModel } from "shared/models";
import { useStore } from "shared/hooks";

import { UiAvatar } from "../UiAvatar";

import './styles.scss';

type PropsType = {
    value: UserModel[],
}

export const UiUsersStack = observer(({ value = [] }: PropsType
) => {
    const store = useStore(() => ({
        isOpened: false
    }))

    const className = classnames('ui-users-stack', {
        'ui-users-stack--opened': store.isOpened
    });

    const handleToggle = () => {
        store.set('isOpened', !store.isOpened);
    }

    const users = store.isOpened ? [...value] : value.slice(0, 4);

    return (
        <div className={className} onClick={handleToggle}>
            {users.map(user => <UiAvatar key={user.id} label={user.previewName} size={store.isOpened ? 32 : 24} image={user.imageAvatar}/>)}
            {(value.length > 4 && !store.isOpened) && (
                <div className="ui-users-stack__more">+{value.length - 4}</div>
            )}
        </div>
    );
});
