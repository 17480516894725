import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { UiLoadingSpinner } from '../UiLoadingSpinner';
import { UiButton } from '../UiButton';

import './styles.scss';

type PropsType = {
    isError?: boolean;
    isLoading?: boolean;
    errorMessage?: string;
    className?: string;
    actionText?: string;
    onAction?: () => void;
    style?: React.CSSProperties,
    children?: React.ReactNode
}
export const UiDataBoundary = observer((
    {
        isError = false,
        isLoading = false,
        className = '',
        actionText = 'Обновить',
        errorMessage = 'Что-то пошло не так. \nНе удалось загрузить данные.',
        style = {},
        onAction,
        children
    }: PropsType
) => {
    const styles = useMemo(() => {
        if (style?.height) {
            return {
                padding: 0,
                minHeight: 0,
                ...style
            }
        }
        return style;
    }, [style]);

    if (!isError && !isLoading) {
        return className ? <div className={className}>{children}</div> : <>{children}</>;
    }

    return (
        <div className='ui-data-boundary' style={styles}>
            {isLoading && (
                <div className='ui-data-boundary__loading'>
                    <UiLoadingSpinner/>
                </div>
            )}
            {!isLoading && isError && (
                <>
                    <div className='ui-data-boundary__error'>{errorMessage}</div>
                    {onAction && <UiButton isSmall label={actionText} onClick={onAction}/>}
                </>
            )}
        </div>
    );
});
