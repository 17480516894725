import { makeQuery } from 'shared/utilities';
import { IUserModel } from "shared/models";

type RequestType = {
    spaceId: number,
    items: {
        userId: number,
        spaceId: number
    }[]
}

type ResponseType = {
    items: IUserModel[]
};

export const spaceOwnersSaveQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/space-owners/save', {
        prefix: 'task-tracker',
        json: request
    });
}
