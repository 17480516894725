import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import { UiNavLink } from "../UiNavLink";
import { UiImageThumbnail } from "../UiImageThumbnail";

import './styles.scss';


type PropsType = {
    href?:string,
    image?: string,
    date?: string,
    name?: string,
    tags?: ReactNode,
    actions?: ReactNode,
    children?: ReactNode
}

export const UiWidgetArticle = observer(({ image, date, name, tags, actions, href = '#' }: PropsType) => {
    return (
        <UiNavLink to={href} className="ui-widget-article">
            <UiImageThumbnail className="ui-widget-article__image" value={image}/>
            <div className="ui-widget-article__inner">
                {date && (
                    <div className="ui-widget-article__date">{moment(date).format('D MMMM YYYY')}</div>
                )}
                {name && (
                    <div className="ui-widget-article__name">{name}</div>
                )}
                <div className="ui-widget-article__footer">
                    {!!tags && (
                        <div className="ui-widget-article__tags">{tags}</div>
                    )}
                    {!!actions && (
                        <div className="ui-widget-article__actions">{actions}</div>
                    )}
                </div>
            </div>
        </UiNavLink>
    )
})
