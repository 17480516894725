import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import moment from "moment";

import {
    UiButton,
    UiCheckboxSwitch,
    UiDatePicker,
    UiForm,
    UiFormControl,
    UiGrid,
    UiModal, UiModalClose,
    UiModalTitle,
    UiSelect,
    UiTextarea,
    UiUserSelect
} from "shared/uikit";
import { useStore, useValidation } from "shared/hooks";
import { ApplicationModule } from "shared/modules";
import { usersUpdateQuery } from "shared/queries";
import { Notifier } from "shared/utilities";

import './styles.scss';
import classnames from "classnames";

export const PProfileAvailability = observer(() => {
    const ref = useRef<HTMLDivElement>(null);
    const store = useStore(() => ({
        isOpened: false,
        isSubmitted: false,
        isSubmitting: false,
        notification: false,
    }));

    const form = useStore(() => ({
        unavailableFrom: null as null | string,
        unavailableTo: null as null | string,
        unavailableComment: null as null | string,
        unavailableReason: null as null | string,
        unavailableUserId: null as null | number,
    }));

    const validation = useValidation(form, (rules) => ({
        unavailableReason: rules.required(),
        unavailableFrom: (value: string) => ({
            isValid: !!value && moment(form.unavailableFrom).isBefore(moment(form.unavailableTo)),
            errorMessage: 'Некорректное время'
        }),
        unavailableTo: (value: string) => ({
            isValid: !!value && moment(form.unavailableFrom).isBefore(moment(form.unavailableTo)),
            errorMessage: 'Некорректное время'
        }),
    }));

    const handleSubmit = async () => {
        store.set("isSubmitted", true);
        if (!validation.isValid || store.isSubmitting) {
            return;
        }
        store.set("isSubmitting", true);
        ApplicationModule.user.update(form);
        store.set('isOpened', false);
        store.set("isSubmitting", false);
        store.set("notification", true);
        const { data, isSuccess } = await usersUpdateQuery(form)
        if (data && isSuccess) {
            ApplicationModule.user.update(data.item);
        }

        setTimeout(() => store.set("notification", false), 700)
    }

    const handleCancel = async () => {
        const isConfirmed = await Notifier.confirm(
            'Вы точно хотите изменить статус присутствия на рабочем месте?',
            'Если вы еще не находитесь на вашем рабочем месте, лучше не менять статус.',
            'Изменить'
        );
        if (!isConfirmed) {
            return;
        }
        form.set('unavailableFrom', null);
        form.set('unavailableTo', null);
        form.set('unavailableComment', null);
        form.set('unavailableReason', null);
        form.set('unavailableUserId', null);
        store.set("notification", true);
        ApplicationModule.user.update(form);
        await usersUpdateQuery(form)
        setTimeout(() => store.set("notification", false), 700)
    }

    const handleOpen = () => {
        form.set('unavailableFrom', ApplicationModule.user.unavailableFrom);
        form.set('unavailableTo', ApplicationModule.user.unavailableTo);
        form.set('unavailableComment', ApplicationModule.user.unavailableComment);
        form.set('unavailableReason', ApplicationModule.user.unavailableReason);
        form.set('unavailableUserId', ApplicationModule.user.unavailableUserId);
        store.set("isOpened", true);
    }

    return (
        <div className={'p-profile-availability'} ref={ref}>
            <div className="p-profile-availability__inner">
                <span>
                    Отсутствую на рабочем месте
                </span>
                <UiCheckboxSwitch
                    value={store.isOpened || !ApplicationModule.user.isAvailable}
                    onChange={data => {
                        if (data.value) {
                            handleOpen();
                        } else {
                            handleCancel();
                        }
                    }}
                />
            </div>
            <div className={classnames('p-profile-availability__notification', {
                'p-profile-availability__notification--visible': store.notification
            })}>
                Статус присутствия на рабочем месте изменен
            </div>
            <UiModal
                isAside
                isOpened={store.isOpened}
                onClose={() => store.set('isOpened', false)}
                styleBody={{ width: '1000px' }}
                className={'p-profile-availability-modal'}
            >
                <UiModalClose onClick={() => store.set('isOpened', false)}/>
                <UiModalTitle title={'Информация об отсутствии'}/>
                <UiForm onSubmit={handleSubmit}>
                    <UiGrid gap={16} columns={1}>
                        <UiGrid columns={'2fr 1fr 1fr'} gap={16}>
                            <UiFormControl
                                isRequired
                                label={'Причина отсутствия'}
                                errorMessage={store.isSubmitted && validation.unavailableReason.errorMessage}
                            >
                                <UiSelect
                                    items={[
                                        { id: 'Обучение', name: 'Обучение' },
                                        { id: 'Отпуск', name: 'Отпуск' },
                                        { id: 'Командировка', name: 'Командировка' },
                                        { id: 'Больничный', name: 'Больничный' },
                                        { id: 'Декретный отпуск', name: 'Декретный отпуск' },
                                    ]}
                                    placeholder={'Впишите причину отсутствия'}
                                    value={form.unavailableReason || ''}
                                    name={'unavailableReason'}
                                    onChange={form.handleChange}
                                />
                            </UiFormControl>
                            <UiFormControl
                                isRequired
                                label={'Отсутствие от'}
                                errorMessage={store.isSubmitted && validation.unavailableFrom.errorMessage}
                            >
                                <UiDatePicker
                                    value={form.unavailableFrom}
                                    name={'unavailableFrom'}
                                    onChange={form.handleChange}
                                />
                            </UiFormControl>
                            <UiFormControl
                                isRequired
                                label={'Отсутствие до'}
                                errorMessage={store.isSubmitted && validation.unavailableTo.errorMessage}
                            >
                                <UiDatePicker
                                    value={form.unavailableTo}
                                    name={'unavailableTo'}
                                    onChange={form.handleChange}
                                />
                            </UiFormControl>
                        </UiGrid>
                        <UiTextarea
                            placeholder={'Добавьте комментарий'}
                            value={form.unavailableComment || ''}
                            name={'unavailableComment'}
                            onChange={form.handleChange}
                        />
                        <UiFormControl label={'Замещающий сотрудник'}>
                            <UiUserSelect
                                placeholder={'Выберите замещающего сотрудника'}
                                value={form.unavailableUserId}
                                name={'unavailableUserId'}
                                onChange={form.handleChange}
                            />
                        </UiFormControl>
                    </UiGrid>
                    <div className='ui-modal__actions'>
                        <UiButton label={'Сохранить'} isSubmit isLoading={store.isSubmitting}/>
                        <UiButton
                            label={'Отмена'}
                            isOutline
                            isLoading={store.isSubmitting}
                            onClick={() => store.set('isOpened', false)}
                        />
                    </div>
                </UiForm>
            </UiModal>
        </div>
    );
});
