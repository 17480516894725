import { makeQuery } from 'shared/utilities';
import { ISurveyModel } from 'shared/models';

type ResponseType = {
    item: ISurveyModel;
};

export const surveysGetQuery = async function (id: number) {
    return await makeQuery<ResponseType>('/surveys/get', { params: { id } });
}
