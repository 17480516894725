import { makeObservable, observable } from 'mobx';

import { Model } from '../Model';
import { ModelArrayCast } from "shared/casts";
import { ISpaceOwnerModel, SpaceOwnerModel } from "shared/models/TaskTracker/SpaceOwnerModel";
import { UserModel } from "shared/models";

export interface ISpaceModel {
    id?: number;
    sort?: number;
    name?: string;
    spaceOwners?: ISpaceOwnerModel[];
}

export class SpaceModel extends Model implements ISpaceModel {
    casts = {
        spaceOwners: new ModelArrayCast(SpaceOwnerModel)
    }
    id = 0;
    name = '';
    sort = 0;
    spaceOwners: SpaceOwnerModel[] = [];

    constructor(payload: ISpaceModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            sort: observable,
            name: observable,
            spaceOwners: observable,
        });

        this.update(payload);
    }

    canEdit = (user: UserModel) => {
        return user.isAdmin || this.spaceOwners.some(spaceOwner => +spaceOwner.userId === +user.id);
    }
}
