import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';

type RequestType = {
    id?: number | string
}

type ResponseType = {
    pages: number;
    item: IUserModel;
};

export const usersGetQuery = async function (request: RequestType) {
    return await makeQuery<ResponseType>('/users/get', {
        params: request
    });
}
