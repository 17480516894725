import { action, makeObservable, observable } from 'mobx';

import { IUserModel, UserModel } from 'shared/models';
import { ModelArrayCast, ModelCast } from "shared/casts";
import { CommentMentionModel, ICommentMentionModel } from "shared/models/CommentMentionModel";

import { Model } from './Model';

export interface ICommentModel {
    id?: number | null;
    createdAt?: string;
    updatedAt?: string;
    value?: string;
    url?: string;
    commentId?: number | null;
    commentMentions?: ICommentMentionModel[],
    user?: IUserModel;
}

export class CommentModel extends Model implements ICommentModel {
    casts = {
        user: new ModelCast(UserModel),
        commentMentions: new ModelArrayCast(CommentMentionModel),
    };

    id = 0;
    createdAt = '';
    updatedAt = '';
    value = '';
    url = '';
    commentId: number | null = null;
    user = new UserModel();
    commentMentions: CommentMentionModel[] = [];

    constructor(payload: ICommentModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            updatedAt: observable,
            value: observable,
            commentId: observable,
            url: observable,
            user: observable,
            commentMentions: observable,
            update: action,
        });

        this.update(payload);
    }
}
