import React from 'react';
import { observer } from 'mobx-react';

import { UiButton, UiModal, UiCountdown } from 'shared/uikit';

import './styles.scss';

type PropsType = {
    isOpened: boolean;
    title?: string;
    description?: string;
    onCancel?: () => void;
    onFinish?: () => void;
}

export const CModalCountdown = observer((
    {
        isOpened = false,
        title = '',
        description = '',
        onCancel,
        onFinish,
    }: PropsType
) => {
    return (
        <UiModal isOpened={isOpened} onClose={onFinish} title={title} description={description}>
            <div className='ui-modal__actions'>
                <UiButton isOutline isLarge label='Отмена' onClick={onCancel}/>
                {isOpened && <UiCountdown onFinish={onFinish} time={10}/>}
            </div>
        </UiModal>
    );
});
