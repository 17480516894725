import validator from "validator";
import { ObjectType } from "shared/types";

type ConstraintsPrototypeType<T, R> = (validators: T) => R;
type ValidationResult = { errorMessage: string, isValid: boolean };
type ValidationRule = (value: any) => ValidationResult;

const validators: Record<string, (errorMessage?: string) => ValidationRule> = {
    email: (errorMessage = 'Неправильный формат email') => (value: any) => ({
        isValid: validator.isEmail(value),
        errorMessage
    }),
    phone: (errorMessage = 'Неправильный формат email') => (value: any) => ({
        isValid: validator.isEmail(value),
        errorMessage
    }),
    required: (errorMessage = 'Поле обязательно для ввода') => (value: any) => ({
        isValid: !!value,
        errorMessage,
    }),
}

const validate = (value: any, rules: ValidationRule[] | ValidationRule): ValidationResult => {
    let _rules = Array.isArray(rules) ? rules : [rules];
    for (let i = 0; i < _rules.length; i++) {
        const result = _rules[i](value);
        if (!result.isValid) {
            return result;
        }
    }
    return {
        isValid: true,
        errorMessage: ''
    };
}

export const useValidation = <EntryType extends ObjectType,
    ConstraintsReturnType extends ObjectType = {
        [Property in keyof EntryType]: ValidationRule[] | ValidationRule;
    },
    ReturnType extends ObjectType = {
        [Property in keyof EntryType]: ValidationResult;
    } & {
        isValid: boolean,
    }>
(entry: EntryType, constraints: ConstraintsPrototypeType<typeof validators, ConstraintsReturnType>): ReturnType => {
    const result: ObjectType = {
        isValid: true
    };

    const rules = constraints(validators);

    Object.entries<ValidationRule[] | ValidationRule>(rules).forEach(([key, rules]) => {
        const validated = validate(entry[key], rules);
        if (!validated.isValid) {
            result.isValid = false;
        }
        result[key] = validated;
    });

    return result as ReturnType;
}
