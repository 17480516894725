import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Swiper as SwiperClass } from 'swiper';
import classnames from "classnames";

import { galleriesQuery } from "shared/queries";
import { useMedia, useNavigate, useStore } from "shared/hooks";
import { GalleryModel } from "shared/models";
import { UiDataBoundary, UiIcon, UiImageThumbnail } from "shared/uikit";
import { COLORS, ICONS, ROUTES } from "shared/constants";

import './styles.scss';

export const CGallerySlider = observer(() => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        isLoading: true,
        isSuccess: false,
        pages: 1,
        galleries: [] as GalleryModel[],
        slideTotal: 0,
        slideIndex: 0,
        swiper: null as null | SwiperClass
    }));

    useEffect(() => {
        (async () => {
            store.set('isLoading', true);
            store.set('isSuccess', true);
            const { data, isSuccess } = await galleriesQuery({
                isMain: 1
            });
            if (isSuccess && data) {
                store.set('galleries', data.items.map(item => new GalleryModel(item)));
                store.set('pages', data.pages);
            } else {
                store.set('isSuccess', false);
            }
            store.set('isLoading', false);
        })();
    }, [store]);

    const { isMobileOrTablet } = useMedia();


    const slides = store.galleries.filter(gallery => gallery.image);

    useEffect(() => {
        store.swiper?.update()
    }, [isMobileOrTablet, store]);

    const swiperProps = useMemo(() => {
        if (isMobileOrTablet) {
            return {
                loop: true,
                spaceBetween: 0,
                slidesPerView: 1,
                onSlideChange: (swiper: SwiperClass) => {
                    store.set("slideIndex", swiper.realIndex)
                },
                onSwiper: (swiper: SwiperClass) => {
                    store.set("swiper", swiper)
                },
            }
        }

        return {
            effect: 'coverflow' as 'coverflow',
            loop: true,
            coverflowEffect: {
                rotate: 40,
                modifier: 1,
                depth: 90,
                stretch: 0,
                scale: 0.9,
                slideShadows: false,
            },
            loopedSlidesLimit: false,
            spaceBetween: -250,
            loopedSlides: 5,
            slidesPerView: 3,
            onSlideChange: (swiper: SwiperClass) => {
                store.set("slideIndex", swiper.realIndex)
            },
            onSwiper: (swiper: SwiperClass) => {
                store.set("swiper", swiper)
            },
            modules: [EffectCoverflow]
        }
    }, [isMobileOrTablet, store]);

    return (
        <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
            {slides.length > 0 && (
                <div className="c-gallery-slider">
                    <div className="c-gallery-slider__title">Яркие события Togas</div>
                    <div className="c-gallery-slider__inner">
                        <Swiper {...swiperProps}>
                            {slides.map(gallery => {
                                const color = gallery.previewColor || '#223c50';
                                return (
                                    <SwiperSlide key={gallery.id} className='c-gallery-slider-slide'>
                                        <UiImageThumbnail
                                            className='c-gallery-slider-slide__inner'
                                            value={gallery.imageThumbnail}
                                            style={{
                                                boxShadow: `350px 0 219px -54px ${color} inset`,
                                            }}
                                        >
                                            {gallery.name &&
                                                <div className="c-gallery-slider-slide__name">{gallery.name}</div>}
                                            {gallery.description && (
                                                <div
                                                    className="c-gallery-slider-slide__description">{gallery.description}</div>
                                            )}
                                            <div
                                                className="c-gallery-slider-slide__link"
                                                onClick={() => {
                                                    navigate(ROUTES.GALLERY(gallery.id))
                                                }}
                                            >
                                                <span>Смотреть</span>
                                                <UiIcon icon={ICONS.ARROW_RIGHT} size={15} color={COLORS.WHITE}/>
                                            </div>
                                        </UiImageThumbnail>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>

                    </div>
                    <div className="c-gallery-slider-dots">
                        {slides.map((_, index) => {
                            const className = classnames('c-gallery-slider-dots__item', {
                                'c-gallery-slider-dots__item--active': index === store.slideIndex
                            });
                            return <div key={index} className={className} onClick={() => {
                                store.swiper?.slideTo(index);
                            }}/>;
                        })}
                    </div>
                    <div
                        className="c-gallery-slider__arrow c-gallery-slider__arrow--left"
                        onClick={() => {
                            store.swiper?.slidePrev();
                        }}
                    >
                        <UiIcon icon={ICONS.CHEVRON_LEFT} size={22} color={COLORS.BLACK}/>
                    </div>
                    <div
                        className="c-gallery-slider__arrow c-gallery-slider__arrow--right"
                        onClick={() => {
                            store.swiper?.slideNext();
                        }}
                    >
                        <UiIcon icon={ICONS.CHEVRON_RIGHT} size={22} color={COLORS.BLACK}/>
                    </div>
                </div>
            )}
        </UiDataBoundary>
    )
});
