import { makeObservable, observable, computed } from 'mobx';
import moment from "moment";

import { ThumbnailType } from 'shared/types';
import { SurveyModel, TagModel, UserModel } from 'shared/models';
import { ModelArrayCast, ModelCast } from "shared/casts";

import { Model } from './Model';

export interface INewsModel {
    id?: number;
    publishedAt?: string;
    createdAt?: string;
    name?: string;
    previewImage?: string;
    previewImageThumbnail?: ThumbnailType;
    detailImage?: string;
    previewText?: string;
    detailText?: string;
    userId?: number;
    liked?: number;
    likesCount?: number;
    commentsCount?: number;
    chatMessageArticlesCount?: number;
    favorited?: number;
    user?: UserModel | null;
    tags?: TagModel[];
    survey?: SurveyModel | null;
    news?: NewsModel | null;
}

export class NewsModel extends Model implements INewsModel {
    casts = {
        tags: new ModelArrayCast(TagModel),
        user: new ModelCast(UserModel),
        survey: new ModelCast(SurveyModel),
        news: new ModelCast(NewsModel),
    };

    id = 0;
    publishedAt = '';
    createdAt = '';
    name = '';
    subtitle = '';
    previewImage = '';
    previewImageThumbnail = null;
    detailImage = '';
    previewText = '';
    detailText = '';
    liked = 0;
    likesCount = 0;
    commentsCount = 0;
    chatMessageArticlesCount = 0;
    favorited = 0;
    userId = 0;
    user: UserModel | null = null;
    news: NewsModel | null = null;
    survey: SurveyModel | null = null;
    tags: TagModel[] = [];

    constructor(payload: INewsModel = {}) {
        super();

        makeObservable(this, {
            id: observable,
            createdAt: observable,
            createdAtMoment: computed,
            publishedAt: observable,
            publishedAtMoment: computed,
            name: observable,
            subtitle: observable,
            previewImage: observable,
            previewImageThumbnail: observable,
            detailImage: observable,
            previewText: observable,
            detailText: observable,
            userId: observable,
            tags: observable,
            liked: observable,
            likesCount: observable,
            commentsCount: observable,
            chatMessageArticlesCount: observable,
            favorited: observable,
            user: observable,
            news: observable,
            survey: observable,
        });
        this.update(payload);
    }

    get publishedAtMoment() {
        if (!this.publishedAt) {
            return moment();
        }
        return moment(this.publishedAt);
    }

    get createdAtMoment() {
        if (!this.createdAt) {
            return moment();
        }
        return moment(this.createdAt);
    }
}
