import { observer } from 'mobx-react';
import React from 'react';
import { ICONS, ROUTES } from "shared/constants";
import { useNavigate } from "shared/hooks";
import { NotificationModel } from "shared/models";
import { tasksProposeAcceptQuery, tasksProposeRejectQuery } from "shared/queries/TaskTracker";

import { UiAvatar, UiIcon } from "shared/uikit";

import './index.scss';

type PropsType = {
    notification: NotificationModel,
    onRead?: () => void
}

const notificationTypes: Record<string, (notification: NotificationModel) => {
    icon: string,
    title: string | React.ReactNode,
    description: string,
}> = {
    TaskCreated: () => ({
        icon: require('./assets/star.svg').default,
        title: 'Добавлена новая задача',
        description: 'Приступите к выполнению.'
    }),
    TaskDone: () => ({
        icon: require('./assets/star.svg').default,
        title: 'Задача завершена',
        description: ''
    }),
    TaskAlmostExpired: () => ({
        icon: require('./assets/alarm-yellow.svg').default,
        title: 'Задача почти просрочена',
        description: 'Успейте выполнить задачу в срок.'
    }),
    TaskExpired: () => ({
        icon: require('./assets/alarm-red.svg').default,
        title: 'Задача просрочена',
        description: 'Договоритесь о переносе сроков'
    }),
    CommentCreated: () => ({
        icon: require('./assets/comment.svg').default,
        title: 'Новый комментарий к задаче',
        description: 'Посмотрите комментарии.'
    }),
    TaskExpiredAtUpdated: () => ({
        icon: require('./assets/calendar.svg').default,
        title: 'Изменен дедлайн задачи',
        description: 'Ознакомьтесь с новыми сроками'
    }),
    TaskExpiredAtPropose: (notification: NotificationModel) => ({
        icon: require('./assets/calendar.svg').default,
        title: `Запрос на изменение сроков задачи [№${notification.data.task.id}]`,
        description: ''
    }),
}

export const Item = observer((
    {
        notification,
        onRead = () => undefined
    }: PropsType
) => {
    const navigate = useNavigate();
    const itemFunction = notificationTypes[notification.typeFormatted] || null;

    const handleOpen = () => {
        navigate(ROUTES.TASK_TRACKER(notification.data.task.spaceId), {
            previewTaskId: notification.data.task.id
        });
    }

    const handleProposeAccept = async () => {
        const { isSuccess, data } = await tasksProposeAcceptQuery({
            id: notification.data.task.id
        });
        if (isSuccess && data) {
            onRead();
        }
    }

    const handleProposeReject = async () => {
        const { isSuccess, data } = await tasksProposeRejectQuery({
            id: notification.data.task.id
        });
        if (isSuccess && data) {
            onRead();
        }
    }

    if (!itemFunction) {
        return null;
    }

    const item = itemFunction(notification);

    const { task } = notification.data;

    if (!task) {
        return null;
    }

    return (
        <div className="c-tt-notifications-item">
            {task?.ownerUser && (
                <div className="c-tt-notifications-item__avatar">
                    <UiAvatar
                        size={40}
                        onClick={() => {
                            navigate(ROUTES.USER(task.ownerUser.id));
                        }}
                        image={task.ownerUser.image}
                        label={task.ownerUser.previewName}
                    />
                </div>
            )}
            <div className="c-tt-notifications-item__body">
                <div className="c-tt-notifications-item__header">
                    {!!item.icon && (
                        <div
                            className="c-tt-notifications-item__icon"
                            style={{ backgroundImage: `url(${item.icon})` }}
                        />
                    )}
                    <div className="c-tt-notifications-item__title">{item.title}</div>
                    {task?.ownerUser && (
                        <div
                            className="c-tt-notifications-item__user"
                            onClick={() => {
                                navigate(ROUTES.USER(task.ownerUser.id));
                            }}
                        >
                            {task.ownerUser.previewName}
                        </div>
                    )}
                    <div className="c-tt-notifications-item__date">{notification.createdAtFormatted()}</div>
                    <div className="c-tt-notifications-item__close" onClick={onRead}>
                        <UiIcon size={12} color='#666666' icon={ICONS.CLOSE}/>
                    </div>
                </div>
                <div className="c-tt-notifications-item__content">
                    {notification.typeFormatted === 'TaskExpiredAtPropose' && (
                        <div className="c-tt-notifications-item__inner">
                            {task.expiredAtProposeUser.previewName} хочет изменить сроки задачи <span className="c-tt-notifications-item__name"
                                                                                                      onClick={handleOpen}>[№{task.id}] {task.name}</span>
                        </div>
                    )}
                    {!!item.description && (
                        <div className="c-tt-notifications-item__description">{item.description}</div>
                    )}
                </div>
                {!!task.expiredAtPropose && (
                    <div className="c-tt-notifications-item-propose">
                        <div className="c-tt-notifications-item-propose__timestamps">
                            <div>
                                <div className="c-tt-notifications-item-propose-row">
                                    <div className="c-tt-notifications-item-propose-row__label">Текущий срок:</div>
                                    <div className="c-tt-notifications-item-propose-row__value">{task.expiredAtMoment.format('D MMMM YYYY, H:mm')}</div>
                                </div>
                                <div className="c-tt-notifications-item-propose-row">
                                    <div className="c-tt-notifications-item-propose-row__label">Новый срок:</div>
                                    <div className="c-tt-notifications-item-propose-row__value">{task.expiredAtProposeMoment.format('D MMMM YYYY, H:mm')}</div>
                                </div>
                            </div>
                            <div
                                style={{
                                    backgroundImage: `url(${require('./assets/propose-line.png')})`
                                }}
                                className="c-tt-notifications-item-propose__icon"
                            />
                        </div>
                        <div className="c-tt-notifications-item-propose-row">
                            <div className="c-tt-notifications-item-propose-row__label">Комментарий:</div>
                            <div className="c-tt-notifications-item-propose-row__value">{task.expiredAtProposeComment}</div>
                        </div>
                        <div className="c-tt-notifications-item-propose__actions">
                            <div className="c-tt-notifications-item-propose__action" onClick={handleProposeAccept}>
                                Подтвердить
                            </div>
                            <div className="c-tt-notifications-item-propose__action c-tt-notifications-item-propose__action--reject" onClick={handleProposeReject}>
                                Отказать
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
