import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentBanner, LayoutContentTitle } from 'shared/layout';
import { UiDataBoundary } from 'shared/uikit';
import { useRouteParams, useStore, useUserWidgets } from "shared/hooks";
import { CSurvey } from "shared/components";
import { SurveyModel } from "shared/models";
import { surveysGetQuery } from "shared/queries";
import { ROUTES } from "shared/constants";

import './styles.scss';

export const SurveyPage = observer(() => {
    const routeParams = useRouteParams<{ id: number }>();
    const store = useStore(() => ({
        isVisible: false,
        isLoading: true,
        isSuccess: false,
        survey: new SurveyModel(),
        title: '...'
    }));

    useEffect(() => {
        (async () => {
            store.set("isLoading", true);
            store.set("isSuccess", false);
            const { isSuccess, data } = await surveysGetQuery(routeParams.id);
            if (isSuccess && data) {
                store.set("survey", new SurveyModel(data.item));
            }
            store.set("isLoading", false);
            store.set("isSuccess", isSuccess && !!data);
            store.set("title", store.survey.name)
        })();
    }, [store, routeParams]);

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle title={store.title} backTo={ROUTES.SURVEYS()}/>
                <UiDataBoundary isLoading={store.isLoading} isError={!store.isSuccess}>
                    <CSurvey survey={store.survey}/>
                    {!store.survey.isAnswered && (
                        <LayoutContentBanner
                            style={{
                                margin: 0,
                                borderRadius: 4,
                            }}
                            image={require('./assets/banner.png')}
                            title={'Ответьте на все вопросы'}
                            description={'Что бы пройти опрос полностью, ответьте на все вопросы'}
                            isFlat
                        />
                    )}
                    {store.survey.isAnswered && (
                        <LayoutContentBanner
                            style={{
                                margin: 0,
                                borderRadius: 4
                            }}
                            image={require('./assets/banner-done.png')}
                            title={'Спасибо за ответы'}
                            description={'Каждый ответ важен для нас, ведь он помогает делать нашу команду лучше и эффективнее'}
                            isFlat
                        />
                    )}
                </UiDataBoundary>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}/>
        </LayoutBody>
    )
});
