import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import { UserModel } from "shared/models";
import { UiAvatar, UiIcon } from "shared/uikit";

import { ICONS, COLORS } from "shared/constants";

import './styles.scss';

interface Props {
    width?: number,
    isSmall?: boolean
    isLight?: boolean
    value?: UserModel;
    style?: React.CSSProperties,
    onDelete?: () => void;
    onClick?: () => void;
}

export const UiUserBadge = observer((
    {
        value: user,
        width,
        style = {},
        isSmall = false,
        isLight = false,
        onDelete,
        onClick,
    }: Props
) => {
    if (!user) {
        return null;
    }

    const className = classnames('ui-user-badge', {
        'ui-user-badge--small': isSmall,
        'ui-user-badge--light': isLight,
        'ui-user-badge--clickable': onClick,
        'ui-user-badge--ondelete': onDelete,
    });

    const styles = {
        ...style,
        width
    };

    return (
        <div className={className} style={styles} onClick={onClick}>
            <UiAvatar
                label={user.previewName}
                image={user.image}
                size={isSmall ? 32 : isLight ? 24 : 45}
            />
            {
                onDelete && (
                    <div className='ui-user-badge__delete' onClick={onDelete}>
                        <UiIcon icon={ICONS.CLOSE} size={16} color={COLORS.WHITE}/>
                    </div>
                )
            }
            <span>{user.previewName}</span>
        </div>
    );
});
