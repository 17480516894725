import React from 'react';
import { observer } from 'mobx-react';
import classnames from "classnames";

import './styles.scss';

type PropsType = {
    className?: string;
    title?: string;
    description?: string;
    image?: string;
    style?: React.CSSProperties
    isFlat?: boolean
}

export const LayoutContentBanner = observer((
    {
        className,
        title,
        description,
        image,
        style,
        isFlat = false
    }: PropsType
) => {
    const classNames = classnames('layout-content-banner', className, {
        'layout-content-banner--flat': isFlat
    });

    return (
        <div className={classNames} style={style}>
            {image && (
                <div className="layout-content-banner__preview">
                    {classNames && (
                        <div className="layout-content-banner__circle"/>
                    )}
                    <div
                        className="layout-content-banner__image"
                        style={{ backgroundImage: `url(${image})` }}
                    ></div>
                </div>
            )}
            <div className="layout-content-banner__inner">
                <div className="layout-content-banner__title">
                    {title}
                </div>
                <div className="layout-content-banner__description">{description}</div>
            </div>
        </div>
    );
});
