import React from 'react';
import { observer } from 'mobx-react';

import { BookModel } from 'shared/models';
import { UiCommentAction, UiImageThumbnail, UiLikeAction, UiTags } from "shared/uikit";
import { useNavigate, useStore } from "shared/hooks";
import { ROUTES } from "shared/constants";
import { BookTypeEnum } from "shared/enums";

import { BookItemModal } from "../BookItemModal";

import './styles.scss';

type PropsType = {
    book: BookModel;
}

export const BookItemRow = observer(({ book }: PropsType) => {
    const navigate = useNavigate();
    const store = useStore(() => ({
        isOpened: false
    }));

    return (
        <div className='book-item-row'>
            <div className="book-item-row__inner">
                <div className="book-item-row__name">{book.name}</div>
                <div className="book-item-row__author">{book.author}</div>
                <div className="book-item-row__description">{book.description}</div>
                {book.typeId === BookTypeEnum.Ebook && (
                    <a href={book.file} target='_blank' className="book-item-row__link" rel='noreferrer'>
                        Скачать
                    </a>
                )}
                <div className="book-item-row__footer">
                    <div className="book-item-row__actions">
                        <UiLikeAction
                            value={book.liked}
                            count={book.likesCount}
                            entityId={book.id}
                            entityType='Book'
                            onChange={({ value }) => {
                                book.update({
                                    liked: value
                                })
                            }}
                        />
                        <UiCommentAction
                            count={book.commentsCount}
                            onClick={() => store.set("isOpened", true)}
                        />
                    </div>
                    <UiTags items={book.tags} onClick={({ value }) => {
                        navigate(ROUTES.BOOKS(), {
                            tagId: value.id
                        })
                    }}/>
                </div>
            </div>
            <div className="book-item-row__preview">
                <UiImageThumbnail
                    className="book-item-row__image"
                    value={book.imageThumbnail}
                />
            </div>
            <BookItemModal
                book={book}
                isOpened={store.isOpened}
                onClose={() => store.set("isOpened", false)}
            />
        </div>
    )
});
