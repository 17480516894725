import { observer } from 'mobx-react';
import React from 'react';

import { ROUTES } from 'shared/constants';
import { useMedia, useNavigate, useResizeObserver } from "shared/hooks";
import { NewsModel } from 'shared/models';
import { UiAppendedSurvey, UiCommentAction, UiImageThumbnail, UiLikeAction, UiNavLink, UiRepostAction, UiTags } from "shared/uikit";

import './styles.scss';

type PropsType = {
    news: NewsModel;
}

export const NewsItem = observer(({ news }: PropsType) => {
    const navigate = useNavigate();

    const { isMobile } = useMedia();
    const { ref, height = 0, width = 0 } = useResizeObserver<HTMLDivElement>();
    const bodyHeight = 128;
    const descriptionRows = Math.floor((bodyHeight - height - 10) / 24);
    const previewTextLength = descriptionRows * width / 9;
    let previewText = (news.previewText || '');

    if (previewText.length > previewTextLength) {
        previewText = previewText.slice(0, previewTextLength).trim() + '...';
    }

    return (
        <div className='news-item'>
            <UiNavLink to={ROUTES.NEWS(news.id)} className='news-item__container'>
                <div className="news-item__inner">
                    <div className="news-item__body">
                        <div ref={ref} className="news-item__name">{news.name}</div>
                        <div className="news-item__description">{isMobile ? news.previewText : previewText}</div>
                    </div>
                    {isMobile && (
                        <UiImageThumbnail
                            className='news-item__image'
                            value={news.previewImageThumbnail}
                        />
                    )}
                    <div className="news-item__footer">
                        <div className="news-item__actions">
                            <UiLikeAction
                                count={news.likesCount}
                                value={news.liked}
                                entityId={news.id}
                                entityType='News'
                                onChange={({ value }) => news.update({ liked: value })}
                            />
                            <UiCommentAction to={ROUTES.NEWS(news.id)} count={news.commentsCount}/>
                            <UiRepostAction
                                entityType={'News'}
                                entityId={news.id}
                                count={news.chatMessageArticlesCount}
                            />
                        </div>
                        <div className="news-item__date">{news.publishedAtMoment.format('DD MMMM Y')}</div>
                        <div className="news-item__tags">
                            <UiTags
                                items={news.tags}
                                onClick={({ value }) => {
                                    navigate(ROUTES.HOME(), {
                                        tagId: value.id
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <UiImageThumbnail
                        className='news-item__image'
                        value={news.previewImageThumbnail}
                    />
                )}
            </UiNavLink>
            <UiAppendedSurvey survey={news.survey} style={{ marginBottom: 0 }}/>
        </div>

    );
});
