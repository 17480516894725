import React from 'react';
import { observer } from 'mobx-react';

import { LayoutAside, LayoutBody, LayoutContent, LayoutContentTitle } from 'shared/layout';
import { useNavigate, useUrlParams, useUserWidgets } from "shared/hooks";
import { WSocialNetworks } from "shared/widgets";

import { PEducationCards } from "pages/education/components/PEducationCards";
import { PEducationISpring } from "pages/education/components/PEducationISpring";
import { PEducationEvents } from "pages/education/components/PEducationEvents";
import { PEducationLinks } from "pages/education/components/PEducationLinks";
import { PEducationWelcome } from "pages/education/components/PEducationWelcome";
import { CEducationLesson } from "shared/components";
import { ROUTES } from "shared/constants";

import './styles.scss';

export const EducationPage = observer(() => {
    const navigate = useNavigate();
    const urlParams = useUrlParams({
        educationLessonId: null as null | number,
    });

    return (
        <LayoutBody>
            <LayoutContent>
                <LayoutContentTitle
                    widgets={false}
                    title={'Обучение и адаптация'}
                    subtitle={'В этом разделе собрали все обучающие материалы, которые помогут быстрее освоить новые направления работы'}
                />
                <PEducationWelcome/>
                <PEducationLinks/>
                <PEducationCards/>
                <PEducationISpring/>
                <PEducationEvents/>
            </LayoutContent>
            <LayoutAside widgets={useUserWidgets()}>
                <WSocialNetworks/>
            </LayoutAside>
            <CEducationLesson
                onClose={() => {
                    navigate(ROUTES.EDUCATION(), {
                        ...urlParams,
                        educationLessonId: null
                    }, {}, null)
                }}
                id={urlParams.educationLessonId}
                isOpened={!!urlParams?.educationLessonId}
            />
        </LayoutBody>
    );
});

