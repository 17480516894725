import { useState } from "react";

import { ApplicationModule } from "shared/modules";

import { useReaction } from "./useReaction";

export const useUser = () => {
    const [user, setUser] = useState(ApplicationModule.user);

    useReaction(() => [ApplicationModule.user, ApplicationModule.user.id], () => {
        setUser(ApplicationModule.user);
    });

    return user;
}
