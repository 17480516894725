import { makeQuery } from 'shared/utilities';
import { IUserModel } from 'shared/models';

type ResponseType = {
    item: IUserModel;
};

export const usersUpdateQuery = async function (payload: IUserModel) {
    return await makeQuery<ResponseType>('/users/update', { json: payload });
}
